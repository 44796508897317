import { Component, OnInit } from '@angular/core';
import { WebsitePoliciesService } from '../services/website-policies.service';
import { TranslateService } from '../translate.service';
import { CommonService } from '../services/common.service';
import { WebistePoliciesTypeEnum } from '../Enum/jankayan.enum';
import { PageTitleModel } from '../Model/department-model.model';
import { LoadingService } from '../services/loading.service';

@Component({
  selector: 'app-website-policies',
  templateUrl: './website-policies.component.html',
  styleUrls: ['./website-policies.component.css'],
  providers: [WebsitePoliciesService,LoadingService]
})
export class WebsitePoliciesComponent implements OnInit {
  
  pageTitle: PageTitleModel;
  entryTypes: any[];
  categoryEnm = WebistePoliciesTypeEnum
  constructor(private _webPolicies: WebsitePoliciesService, public translate: TranslateService, private _commonService: CommonService, private loadingService: LoadingService) { }

  ngOnInit() {
    this.getCategoryDetail();
    this.getEntryTypeListByCategory();
  }

  
  getCategoryDetail() {
    this.loadingService.show();
    this._webPolicies.GetJankalyanCategoryDetail(Number(this.categoryEnm.WebsitePolicyCode)).subscribe(res => {
      if (res.IsSuccess) {
        let cat = res.Data;
        if (cat) {
          this.pageTitle = new PageTitleModel();
          this.pageTitle.NameEnglish = (cat.SubMenuNameEnglish || cat.Name);
          this.pageTitle.NameHindi = (cat.SubMenuNameHindi || cat.NameHindi);
          this.loadingService.hide();
          // this._commonService.SetWebTitle(this.translate.currentLang == 'hi' ? this.pageTitle.NameHindi : this.pageTitle.NameEnglish);
        }
      }
    }, error => {
      this.loadingService.hide();
      console.error(error);
    });
  }

  getEntryTypeListByCategory() {
    this.loadingService.show();
    this.entryTypes = undefined;
    this._webPolicies.GetJankalyanEntryTypeListByCategory(Number(this.categoryEnm.WebsitePolicyCode)).subscribe((data:any) => {
      if (data.IsSuccess) {
        this.entryTypes = data.Data;
        this.loadingService.hide();
      } else {
        this.entryTypes = [];
      }
    }, error => {
      this.entryTypes = []
      this.loadingService.hide();
      console.error(error.message);
    });
  }

}
