import { AfterViewInit, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
// import Map from "@arcgis/core/Map";
// import MapView from "@arcgis/core/views/MapView";
// import TileLayer from "@arcgis/core/layers/TileLayer";
// import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
// import BasemapToggle from "@arcgis/core/widgets/BasemapToggle";
// import LayerList from "@arcgis/core/widgets/LayerList";
// import Home from "@arcgis/core/widgets/Home";
// import Search from "@arcgis/core/widgets/Search";
// import ScaleBar from "@arcgis/core/widgets/ScaleBar";
// import Basemap from "@arcgis/core/Basemap";
// import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
// import Graphic from "@arcgis/core/Graphic";
// import Polygon from "@arcgis/core/geometry/Polygon";
// import urlUtils from "@arcgis/core/core/urlUtils";
// import UniqueValueRenderer from "@arcgis/core/renderers/UniqueValueRenderer";
// import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol";
// import SimpleLineSymbol from "@arcgis/core/symbols/SimpleLineSymbol";
// import TextSymbol from "@arcgis/core/symbols/TextSymbol";
// import UniqueValueInfo from "@arcgis/core/renderers/support/UniqueValueInfo";
// import FeatureFilter from "@arcgis/core/layers/support/FeatureFilter";
// import FeatureLayerView from "@arcgis/core/views/layers/FeatureLayerView";
// import View from "@arcgis/core/views/View";
// import  HitTestResult from "@arcgis/core/views/View";
// import  MediaHit from "@arcgis/core/views/View";
// import  ViewHit  from "@arcgis/core/views/View";
@Component({
  selector: "app-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.css"],
})
export class MapComponent implements OnInit, AfterViewInit {
  type: any;
  constructor(private _route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this._route.params.subscribe((params) => {
      if (params["type"]) {
        this.type = params["type"];
      }
    });
  }

  ngAfterViewInit(): void {
    // this.initializeMap();
  }

  // JS

  // initializeMap(): void {
  //   urlUtils.addProxyRule({
  //     urlPrefix: "https://gistest1.rajasthan.gov.in/",
  //     proxyUrl: "https://gistest1.rajasthan.gov.in/proxy/proxy.ashx",
  //   });

  //   const customBaseLayer = new TileLayer({
  //     url: "https://gistest1.rajasthan.gov.in/rajasthan/rest/services/Common/BaseMap/MapServer",
  //   });

  //   const customBasemap = new Basemap({
  //     baseLayers: [customBaseLayer],
  //     title: "Custom Basemap",
  //     id: "customBasemap",
  //   });

  //   const map = new Map({
  //     basemap: customBasemap,
  //   });

  //   const view = new MapView({
  //     container: "viewDiv",
  //     map: map,
  //     center: [75.778885, 26.92207],
  //     zoom: 1,
  //   });

  //   const toggle = new BasemapToggle({
  //     view: view,
  //     nextBasemap: "satellite",
  //   });

  //   view.ui.add(toggle, "bottom-left");

  //   const template = {
  //     title: "District Layer Details",
  //     content: [
  //       {
  //         type: "fields",
  //         fieldInfos: [
  //           { fieldName: "DISTRICT_NAME_EN", label: "DISTRICT NAME" },
  //           { fieldName: "DIVISION_NAME_EN", label: "DIVISION NAME" },
  //           { fieldName: "TOTAL_POPULATION", label: "TOTAL POPULATION" },
  //         ],
  //       },
  //     ],
  //   };

  //   const labelingInfo = [
  //     {
  //       labelExpressionInfo: {
  //         expression: "$feature.DISTRICT_NAME_EN",
  //       },
  //       symbol: new TextSymbol({
  //         color: "white",
  //         haloColor: "black",
  //         haloSize: "1px",
  //         font: {
  //           size: 12,
  //           weight: "bold", // Use valid literals like "bold", "normal", etc.
  //         },
  //       }),
  //     },
  //   ];

  //   // Create the default symbol
  //   const defaultSymbol = new SimpleFillSymbol({
  //     color: [240, 227, 67, 0.2],
  //     outline: new SimpleLineSymbol({
  //       color: [0, 0, 0, 1],
  //       width: 0.5,
  //     }),
  //   });

  //   // Create the renderer
  //   const renderer = new UniqueValueRenderer({
  //     field: "DISTRICT_CODE",
  //     defaultSymbol: defaultSymbol,
  //     uniqueValueInfos: [],
  //   });

  //   const districtURLs = {
  //     Ajmer: "https://ajmer.rajasthan.gov.in/",
  //     Alwar: "https://alwar.rajasthan.gov.in/",
  //     Banswara: "https://banswara.rajasthan.gov.in/",
  //     Baran: "https://baran.rajasthan.gov.in/",
  //     Barmer: "https://barmer.rajasthan.gov.in/",
  //     Bharatpur: "https://bharatpur.rajasthan.gov.in/",
  //     Bhilwara: "https://bhilwara.rajasthan.gov.in/",
  //     Bikaner: "https://bikaner.rajasthan.gov.in/",
  //     Bundi: "https://bundi.rajasthan.gov.in/",
  //     Chittaurgarh: "https://chittorgarh.rajasthan.gov.in/",
  //     Churu: "https://churu.rajasthan.gov.in/",
  //     Dausa: "https://dausa.rajasthan.gov.in/",
  //     Dhaulpur: "https://dholpur.rajasthan.gov.in/",
  //     Dungarpur: "https://dungarpur.rajasthan.gov.in/",
  //     Ganganagar: "https://sriganganagar.rajasthan.gov.in/",
  //     Hanumangarh: "https://hanumangarh.rajasthan.gov.in/",
  //     Jaipur: "https://jaipur.rajasthan.gov.in/",
  //     Jaisalmer: "https://jaisalmer.rajasthan.gov.in/",
  //     Jalore: "https://jalore.rajasthan.gov.in/",
  //     Jhalawar: "https://jhalawar.rajasthan.gov.in/",
  //     Jhunjhunu: "https://jhunjhunu.rajasthan.gov.in/",
  //     Jodhpur: "https://jodhpur.rajasthan.gov.in/",
  //     Karauli: "https://karauli.rajasthan.gov.in/",
  //     Kota: "https://kota.rajasthan.gov.in/",
  //     Nagaur: "https://nagaur.rajasthan.gov.in/",
  //     Pali: "https://pali.rajasthan.gov.in/",
  //     Pratapgarh: "https://pratapgarh.rajasthan.gov.in/",
  //     Rajsamand: "https://rajsamand.rajasthan.gov.in/",
  //     "Sawai Madhopur": "https://sawaimadhopur.rajasthan.gov.in/",
  //     Sikar: "https://sikar.rajasthan.gov.in/",
  //     Sirohi: "https://sirohi.rajasthan.gov.in/",
  //     Tonk: "https://tonk.rajasthan.gov.in/",
  //     Udaipur: "https://udaipur.rajasthan.gov.in/",
  //   };

  //   const districtColors = {
  //     "099": [255, 0, 0, 0.6], // Red
  //     "100": [0, 255, 0, 0.6], // Green
  //     "101": [0, 0, 255, 0.6], // Blue
  //     "102": [255, 255, 0, 0.6], // Yellow
  //     "103": [255, 165, 0, 0.6], // Orange
  //     "104": [128, 0, 128, 0.6], // Purple
  //     "105": [0, 255, 255, 0.6], // Cyan
  //     "106": [255, 192, 203, 0.6], // Pink
  //     "107": [128, 128, 128, 0.6], // Gray
  //     "108": [0, 128, 0, 0.6], // Dark Green
  //     "109": [128, 128, 0, 0.6], // Olive
  //     "110": [0, 0, 128, 0.6], // Navy
  //     "111": [255, 69, 0, 0.6], // Orange Red
  //     "112": [154, 205, 50, 0.6], // Yellow Green
  //     "113": [75, 0, 130, 0.6], // Indigo
  //     "114": [0, 206, 209, 0.6], // Dark Turquoise
  //     "115": [255, 105, 180, 0.6], // Hot Pink
  //     "116": [105, 105, 105, 0.6], // Dim Gray
  //     "117": [34, 139, 34, 0.6], // Forest Green
  //     "118": [255, 215, 0, 0.6], // Gold
  //     "119": [199, 21, 133, 0.6], // Medium Violet Red
  //     "120": [72, 61, 139, 0.6], // Dark Slate Blue
  //     "121": [95, 158, 160, 0.6], // Cadet Blue
  //     "122": [233, 150, 122, 0.6], // Dark Salmon
  //     "123": [218, 112, 214, 0.6], // Orchid
  //     "124": [112, 128, 144, 0.6], // Slate Gray
  //     "125": [0, 100, 0, 0.6], // Dark Green
  //     "126": [184, 134, 11, 0.6], // Dark Goldenrod
  //     "127": [107, 142, 35, 0.6], // Olive Drab
  //     "128": [46, 139, 87, 0.6], // Sea Green
  //     "129": [210, 105, 30, 0.6], // Chocolate
  //     "130": [139, 0, 0, 0.6], // Dark Red
  //     "131": [148, 0, 211, 0.6], // Dark Violet
  //   };

  //   // Example code
  //   Object.keys(districtColors).forEach((code) => {
  //     const uniqueValueInfo = new UniqueValueInfo({
  //       value: code,
  //       label: `District ${code}`, // Add a label for each unique value
  //       symbol: new SimpleFillSymbol({
  //         color: districtColors[code],
  //         outline: {
  //           color: [0, 0, 0, 1],
  //           width: 0.5,
  //         },
  //       }),
  //     });

  //     renderer.uniqueValueInfos.push(uniqueValueInfo);
  //   });

  //   const DistrictLayer = new FeatureLayer({
  //     url: "https://gistest1.rajasthan.gov.in/rajasthan/rest/services/Common/ADMIN/MapServer/3",
  //     renderer: renderer,
  //     labelingInfo: labelingInfo,
  //   });

  //   map.add(DistrictLayer);

  //   const maskLayer = new GraphicsLayer();
  //   const selectedDistrictLayer = new GraphicsLayer();

  //   map.add(maskLayer, 0);
  //   map.add(selectedDistrictLayer);

  //   const fillSymbol = new SimpleFillSymbol({
  //     color: [0, 0, 0, 0.5],
  //     outline: null, // Outline can be set to null or defined explicitly
  //   });

  //   DistrictLayer.when(() => {
  //     DistrictLayer.queryExtent().then((result) => {
  //       const districtExtent = result.extent;
  //       const maskPolygon = this.createMaskPolygon(view.extent, districtExtent);

  //       const maskGraphic = new Graphic({
  //         geometry: maskPolygon,
  //         symbol: fillSymbol,
  //       });

  //       maskLayer.add(maskGraphic);
  //     });
  //   });

  //   const layerListItem = document.getElementById("layer");

  //   let isLayerListVisible = false;
  //   let layerList = null;

  //   layerListItem.addEventListener("click", () => {
  //     if (isLayerListVisible) {
  //       if (layerList) {
  //         view.ui.remove(layerList);
  //         layerList = null;
  //       }
  //       isLayerListVisible = false;
  //       layerListItem.classList.remove("highlight");
  //     } else {
  //       if (!layerList) {
  //         layerList = new LayerList({ view: view });
  //         view.ui.add(layerList, "top-right");
  //       }
  //       isLayerListVisible = true;
  //       layerListItem.classList.add("highlight");
  //     }
  //   });

  //   const homeBtn = new Home({ view: view });
  //   const scaleBar = new ScaleBar({ view: view, unit: "dual" });

  //   view.ui.add(scaleBar, { position: "bottom-right" });
  //   view.ui.add(homeBtn, "top-left");

  //   const searchWidget = new Search({ view: view });
  //   view.ui.add(searchWidget, { position: "top-left" });

  //   view.on("pointer-move", (event) => {
  //     const point = view.toMap({ x: event.x, y: event.y });
  //     document.getElementById("coord")!.innerHTML = `${point.latitude.toFixed(
  //       5
  //     )},${point.longitude.toFixed(5)}`;
  //   });

  //   view.whenLayerView(DistrictLayer).then((layerView: FeatureLayerView) => {
  //     view.on("click", (event) => {
  //       view.hitTest(event).then((response) => {
  //         // Filter out results to get only the feature layer hits
  //         console.log(response,"response")
  //         // const graphic = response.results
  //         //   .filter(result => result.graphic && result.graphic.layer === DistrictLayer)
  //         //   .map(result => result.graphic)[0];
    
  //         // if (graphic) {
  //         //   const districtName = graphic.attributes.DISTRICT_NAME_EN;
  //         //   layerView.filter = new FeatureFilter({
  //         //       where: `DISTRICT_NAME_EN = '${districtName}'`,
  //         //      });
  //         //   const url = districtURLs[districtName];
  //         //   window.open(url, "_blank");
  //         //   selectedDistrictLayer.removeAll();
  //         // } else {
  //         //   layerView.filter = null;
  //         //   selectedDistrictLayer.removeAll();
  //         // }
  //       });
  //     });
  //   });

  //   // new FeatureFilter({
  //   //   where: `DISTRICT_NAME_EN = '${districtName}'`,
  //   // });
    
  // }

  // createMaskPolygon(fullExtent, excludeExtent): Polygon {
  //   const maskPolygon = new Polygon({
  //     spatialReference: fullExtent.spatialReference,
  //     rings: [
  //       [
  //         [fullExtent.xmin, fullExtent.ymin],
  //         [fullExtent.xmin, fullExtent.ymax],
  //         [fullExtent.xmax, fullExtent.ymax],
  //         [fullExtent.xmax, fullExtent.ymin],
  //         [fullExtent.xmin, fullExtent.ymin],
  //       ],
  //     ],
  //   });

  //   maskPolygon.addRing(excludeExtent.rings[0]);
  //   return maskPolygon;
  // }
}
