import { IndexModel } from "./awards-request-model.model";

export class FrontUploaderDataRequestModel extends IndexModel {
    DepartmentCode: number | null;
    CategoryCode: number | null;
    OfficeCode: number | null;
    UploaderType: string;
    IsMRCRelated: boolean;
    DistrictCode: number = 0;
    ActivityCode:number;
    constructor() {
        super();
        this.IsMRCRelated = true;
        this.DepartmentCode = 0;
        this.IsMRCRelated = false;
    }
}

export class FrontUploaderDataResponseModel {
    Id: number;
    Subject: string;
    Address: string;
    VideoPath: string;
    Images: string[];
    URL: string;
    UploaderType: string;
    Date: Date | null;
    DisplayOrder: number | null;
    DepartmentCode: number;
    DepartmentTitle: string;
    DepartmentTitleHindi: string;
    CategoryCode: number | null;
    CategoryName: string;
    CategoryNameHindi: string;
    OfficeCode: number | null;
    OfficeName: string;
    OfficeNameHindi: string;
    ModifiedDate: Date;
    TotalRows: number | null;
}
