import { Component, Inject, OnInit } from "@angular/core";
import { environment } from "../../environments/environment";
import {
  DepartmentCodeEnum,
  PressReleaseCMOPRCategoryEnum,
  PressReleaseCategoryTypeEnum,
} from "../Enum/app-enums";
import {
  CategoryTypeEnum,
  EntryTypeEnum,
  JankalyanCategoryEnum,
  PressReleaseSubGeneralCatTypeEnum,
  SubCategoryCodeTypeEnum,
  VIPCategoryEnum,
  VIPPersonCategoryEnum,
} from "../Enum/jankayan.enum";
import {
  InAugrationListModel,
  PressReleaseFrontSearchModel,
} from "../Model/press-release-model.model";
import { NewsService } from "../services/news.services";
import { TranslateService } from "../translate.service";
import { EventService } from "./../services/events.services";
import { CMProfileModel, CMProfileViewModel } from "../Model/cm-profile.model";
import { FormerCMModel, FormerCMViewModel } from "../Model/former-cm";
import { CMprofileViewModel } from "../Model/cmprofile-view-model";
import { LookupEnum } from "../Enum/lookup-type.enum";
import { AchievementService } from "../services/Achievement.services";
import { ActivatedRoute, Router } from "@angular/router";
import { DepartmentProfileFilterModel } from "../Model/department-model.model";
import { DepartmentService } from "../services/department.service";
import { JankalyanEntryTypeEnum } from "../Enum/achievement-category.enum";
import { DomSanitizer } from "@angular/platform-browser";
import {
  UpcomingCMEvtModal,
  UpcomingCMEvtViewModal,
} from "../Model/cm-upcoming-events.model";
import { animate, style, transition, trigger } from "@angular/animations";

@Component({
  selector: "app-program",
  templateUrl: "./program.component.html",
  styleUrls: ["./program.component.css"],
  providers: [EventService, NewsService, AchievementService, DepartmentService],
  animations: [
    trigger("counterAnimation", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate("2s", style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class ProgramComponent implements OnInit {
  imageUrl = environment.imageUrl + "Program/Image/Thumbnail/Fr_";
  imageMainurl = environment.imageUrl + "Program/ProgramMainImage/";
  filterModel = new PressReleaseFrontSearchModel();
  filterModelForPR = new PressReleaseFrontSearchModel();
  generalModel: InAugrationListModel[];
  generalModelForPR: InAugrationListModel[];
  megaEvent: InAugrationListModel[];
  CMProfileModel = new CMProfileModel();
  CMProfileModelViewModel: any;
  FormerCMModel = new FormerCMModel();
  FormerCMViewModel: FormerCMViewModel[];
  IsLoading = true;
  totalRecords = 0;
  categoryCode: number = 0;
  DepartmentCodeEnum: DepartmentCodeEnum;
  pressReleaseCMOPRCategoryEnum = PressReleaseCMOPRCategoryEnum;
  showTwitter = true;
  showFb = false;
  CMRelifeData: any = [];
  visionMissionData: any = [];
  CMUpcomingEvtModal = new UpcomingCMEvtModal();
  CMUpcomingEvtViewModal: UpcomingCMEvtViewModal[];
  currentNumber: number = 0;
  finalNumber: number = 32.58;

  constructor(
    @Inject("BASE_URL") public baseUrl: string,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private _achievementService: AchievementService,
    private readonly departmentProfile: DepartmentService,
    public sanitizer: DomSanitizer,
    private newsService: NewsService
  ) { }

  ngOnInit() {
    this.route.fragment.subscribe((fragment) => {
      this.scrollToElement(fragment);
    });
    this.GetPressReleaseData();
    this.GetMegaEventData();
    this.GetCMProfile();
    this.GetFormerCM();
    this.getCMReliefData();
    this.GetPRData();
    this.getVisionMissionDetail();
    this.GetUpcomingCMEvents();
    this.GetCMISData();
    setTimeout(() => {
      this.startCounterAnimation();
    }, 2000);
  }

  startCounterAnimation() {
    this.currentNumber = 0;
    this.finalNumber = 32.58;
    const interval = setInterval(() => {
      if (this.currentNumber < this.finalNumber) {
        this.currentNumber++;
      } else {
        clearInterval(interval);
        this.currentNumber = this.finalNumber;
      }
    }, 50);
  }

  GetCMISData() {
    let data = {
      secretkey: "3083470572888207",
    };

    this._achievementService
      .GetCMISAuthenticationKey(data)
      .subscribe((res: any) => {
        console.log(res);
      });
  }

  scrollToElement(fragment: string): void {
    const element = document.querySelector("#" + fragment);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  setLang(lang: string) {
    this.translate.use(lang);
  }

  getVisionMissionDetail() {
    let filterModel = new DepartmentProfileFilterModel();
    filterModel.IndexModel.PageSize = 1;
    filterModel.DepartmentCode = Number(DepartmentCodeEnum.CmoWebsiteCode);
    filterModel.JankalyanCategoryCode = Number(
      JankalyanEntryTypeEnum.JankalyanCategoryCode
    );
    filterModel.DepartmentCode = Number(DepartmentCodeEnum.CmoWebsiteCode);
    filterModel.EntryTypeCode = Number(
      JankalyanEntryTypeEnum.CMVisionAndMissionCode
    );
    this.departmentProfile.GetList(filterModel).subscribe(
      (data) => {
        if (data.IsSuccess) {
          if (data && data.Data && data.Data.Data[0]) {
            this.visionMissionData = data.Data.Data[0];
          }
          if (this.visionMissionData) {
            this.visionMissionData.Details = (
              this.sanitizer.bypassSecurityTrustHtml(
                this.visionMissionData.Details
              ) as any
            ).changingThisBreaksApplicationSecurity;
            this.visionMissionData.DetailsInHindi = (
              this.sanitizer.bypassSecurityTrustHtml(
                this.visionMissionData.DetailsInHindi
              ) as any
            ).changingThisBreaksApplicationSecurity;
          }
        }
      },
      (error) => {
        console.error(error.message);
      }
    );
  }

  //Janklayan API
  GetPressReleaseData() {
    this.filterModel.IsBase64File = false;
    this.filterModel.CategoryCode =
      PressReleaseCategoryTypeEnum.PressReleaseCode;
    this.filterModel.VIPCategoryCode = VIPCategoryEnum.HCMCode;
    // this.filterModel.VIPPersonCode = VIPPersonCategoryEnum.ShriAshokGehlotCode;
    this.filterModel.VIPPersonCode =
      VIPPersonCategoryEnum.ShriBhajanLalSharmaCode;
    this.filterModel.SubCat_GECatTypeCode =
      PressReleaseSubGeneralCatTypeEnum.CMOProgramCode;
    this.filterModel.OrderBy = "PressreleaseDate";
    this.filterModel.DepartmentCode = DepartmentCodeEnum.CmoWebsiteCode;
    this.filterModel.PressReleaseLevelCode =
      LookupEnum.PressReleaseLevelCode_For_State;

    this.newsService.GetPressReleaseList(this.filterModel).subscribe(
      (response) => {
        if (response.IsSuccess) {
          this.generalModel = response.Data.Data as InAugrationListModel[];
          this.generalModel.map((item) => {
            if (item.PressReleaseDateHindi) {
              item.PressReleaseDateHindi = item.PressReleaseDateHindi.split(
                "-"
              ) as any;
            }
          });
          this.totalRecords = response.Data.TotalRecords;
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  //Janklayan API
  GetMegaEventData() {
    this.filterModel.IsBase64File = false;
    this.filterModel.CategoryCode =
      PressReleaseCategoryTypeEnum.PressReleaseCode;
    this.filterModel.VIPCategoryCode = VIPCategoryEnum.HCMCode;
    // this.filterModel.VIPPersonCode = VIPPersonCategoryEnum.ShriAshokGehlotCode;
    this.filterModel.VIPPersonCode =
      VIPPersonCategoryEnum.ShriBhajanLalSharmaCode;
    this.filterModel.SubCat_GECatTypeCode =
      PressReleaseSubGeneralCatTypeEnum.CMOProgramCode;
    this.filterModel.CMOPRCategoryCode =
      this.pressReleaseCMOPRCategoryEnum.MegaEventCode;
    this.filterModel.OrderBy = "PressreleaseDate";
    this.filterModel.DepartmentCode = DepartmentCodeEnum.CmoWebsiteCode;
    this.filterModel.PressReleaseLevelCode =
      LookupEnum.PressReleaseLevelCode_For_State;

    this.newsService.GetPressReleaseList(this.filterModel).subscribe(
      (response) => {
        if (response.IsSuccess) {
          this.megaEvent = response.Data.Data as InAugrationListModel[];
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  GetCMProfile() {
    this.CMProfileModel.IndexModel.PageSize = 1;
    this.CMProfileModel.DepartmentCode = DepartmentCodeEnum.CmoWebsiteCode;
    this.CMProfileModel.JankalyanCategoryCode =
      JankalyanCategoryEnum.CMProfileCode;
    this.CMProfileModel.EntryTypeCode = EntryTypeEnum.CMProfileCode;

    this.newsService
      .GetCMProfile(this.CMProfileModel)
      .subscribe((response: any) => {
        if (response.IsSuccess) {
          this.CMProfileModelViewModel = response.Data.Data[0];
          // console.log(this.CMProfileModelViewModel, "response")
        }
      });
  }

  GetFormerCM() {
    this.FormerCMModel.IndexModel.PageSize = 101;
    this.FormerCMModel.DepartmentCode = DepartmentCodeEnum.CmoWebsiteCode;
    this.FormerCMModel.JankalyanCategoryCode =
      JankalyanCategoryEnum.GOVTrackRecordCode;
    this.FormerCMModel.EntryTypeCode = EntryTypeEnum.GOVTrackRecordCode;
    this.FormerCMModel.CategoryCode = CategoryTypeEnum.FormerCMCategoryCode;
    this.FormerCMModel.SubCategoryCode =
      SubCategoryCodeTypeEnum.FormerCMSubCategoryCode;
    this.FormerCMModel.IndexModel.OrderBy = "Priority";
    this.FormerCMModel.IndexModel.OrderByAsc = 1;

    this.newsService
      .GovFormerCM(this.FormerCMModel)
      .subscribe((response: any) => {
        if (response.IsSuccess) {
          this.FormerCMViewModel = response.Data.Data as FormerCMViewModel[];
        }
      });
  }

  GetUpcomingCMEvents() {
    this.CMUpcomingEvtModal.IndexModel.OrderBy = "AchievementDate";
    this.CMUpcomingEvtModal.IndexModel.OrderByAsc = 1;
    this.CMUpcomingEvtModal.IndexModel.PageSize = 10000;
    this.CMUpcomingEvtModal.IsCMUpcomingEvt = true;
    this.CMUpcomingEvtModal.DepartmentCode = DepartmentCodeEnum.CmoWebsiteCode;
    this.CMUpcomingEvtModal.CategoryCode =
      CategoryTypeEnum.UpcomingCMEvtCategoryCode;

    this.newsService
      .GetUpcomingCMEvents(this.CMUpcomingEvtModal)
      .subscribe((response: any) => {
        if (response.IsSuccess) {
          this.CMUpcomingEvtViewModal = response.Data
            .Data as UpcomingCMEvtViewModal[];
        }
      });
  }

  onNavigate(item: any) {
    if (item.Url) window.open(item.Url, "_blank");
  }

  getCMReliefData() {
    this._achievementService.GetCMRelef().subscribe(
      (res: any) => {
        if (res.length) {
          // this.CMRelifeData = res;
          res.forEach((item, index) => {
            if (index % 2 === 0) {
              this.CMRelifeData.push([item]);
            } else {
              this.CMRelifeData[this.CMRelifeData.length - 1].push(item);
            }
          });
          console.log(this.CMRelifeData, "this.CMRelifeData");
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  GetPRData() {
    // debugger;
    // this.filterModelForPR = undefined;
    // this.filterModelForPR.Page = 1;
    // this.filterModelForPR.CMOPRCategoryCode = undefined
    this.filterModelForPR.PageSize = 9;
    this.filterModelForPR.IsBase64File = false;
    this.filterModelForPR.OrderBy = "PressreleaseDate";
    this.filterModelForPR.VIPCategoryCode = VIPCategoryEnum.HCMCode;
    this.filterModelForPR.PressReleaseLevelCode =
      LookupEnum.PressReleaseLevelCode_For_State;
    if (
      this.filterModelForPR.DepartmentCode == 0 &&
      this.filterModelForPR.CategoryCode == 0 &&
      this.filterModelForPR.SubCategoryCode == 0
    ) {
      this.filterModelForPR.CategoryCode =
        PressReleaseCategoryTypeEnum.PressReleaseCode;
      this.filterModelForPR.VIPPersonCode =
        VIPPersonCategoryEnum.ShriBhajanLalSharmaCode;
      this.filterModelForPR.DepartmentCode = DepartmentCodeEnum.CmoWebsiteCode;
    }
    this.IsLoading = true;
    this.newsService.GetPressReleaseList(this.filterModelForPR).subscribe(
      (response) => {
        if (response.IsSuccess) {
          this.IsLoading = response.Data.Data.length >= 0 ? false : true;
          this.generalModelForPR = response.Data.Data as InAugrationListModel[];
          console.log(this.generalModelForPR, "generalModelForPR");
          // this.totalRecords = response.Data.TotalRecords;
        } else {
          this.IsLoading = false;
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  showSocial(type: any) {
    this.showTwitter = type == "twitter" ? true : false;
    this.showFb = type == "fb" ? true : false;
  }
}

export class ProgramList {
  ProgramId: number;
  ProgramCategoryId: number;
  ProgramSubCategoryId: number;
  ProgramDate: string;
  ProgramDistrictId: number;
  ProgramLocationId: number;
  ProgramAddress: string;
  ProgramAddressHindi: string;
  ProgramLanguage: string;
  ProgramSubject: string;
  ProgramSubjectHindi: string;
  ProgramSpeech: string;
  ProgramSpeechHindi: string;
  Keywords: string;
  KeywordsHindi: string;
  SpeechPdf: string;
  Pdf: string;
  IsActive: boolean;
  IsDeleted: boolean;
  ProgramStateId: number;
  ProgramTime: string;
  UserId: string;
  IsShowLatest: string;
  KeyImage: string;
  Foldername: string;
  PopupImages: string;
  PdfEnglish: string;
  Status: string;
  District: string;
  ProgramCategory: string;
  ProgramCategoryhindi: string;
  ProgramSubCategoryhindi: string;
  ProgramSubCategory: string;
  ProgramImage: string;
  ProgramDateHindi: string;
  ProgramMainImage: string;
}
