import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormatter'
})
export class NumberFormatterPipe implements PipeTransform {

  transform(value: string | number): string {
    let numericValue: number;

    if (typeof value === 'string') {
      // Remove the currency symbol (₹) and commas
      const cleanedValue = value.replace(/[^0-9.-]+/g, '');
      numericValue = parseFloat(cleanedValue);
    } else {
      numericValue = value;
    }

    if (isNaN(numericValue)) {
      return value.toString();
    }

    if (numericValue >= 1e7) {
      return (numericValue / 1e7).toFixed(2) + ' Cr';
    } else if (numericValue >= 1e5) {
      return (numericValue / 1e5).toFixed(2) + ' Lac';
    } else if (numericValue >= 1e3) {
      return (numericValue / 1e3).toFixed(2) + ' K';
    } else {
      return numericValue.toString();
    }
  }

}
