import { Component, OnInit } from '@angular/core';
import { MediaService } from '../services/media.services';
import { PagerService } from '../pager.service';
import { TranslateService } from '../translate.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PageTitleModel } from '../Model/department-model.model';
import { FrontUploaderDataRequestModel, FrontUploaderDataResponseModel } from '../Model/photo-video-uploader.model';
import { DepartmentCodeEnum } from '../Enum/app-enums';
import { VideoUploadCategoryEnum } from '../Enum/jankayan.enum';

@Component({
  selector: 'app-photo-video-uploader-gallery-section',
  templateUrl: './photo-video-uploader-gallery-section.component.html',
  styleUrls: ['./photo-video-uploader-gallery-section.component.css']
})
export class PhotoVideoUploaderGallerySectionComponent implements OnInit {

  model: FrontUploaderDataResponseModel[];
  indexModel = new FrontUploaderDataRequestModel();
  totalRecords = 0;
  departmentCode: number = 0;
  catCode: number = 0;

  constructor(
    private mediaService: MediaService,
    public translate: TranslateService
  ) {
    this.departmentCode = DepartmentCodeEnum.CmoWebsiteCode;
    this.catCode = VideoUploadCategoryEnum.CMEventsCode;
  }

  ngOnInit() {
    this.getListData();
  }

  getListData() {
    this.indexModel.OrderBy = "ModifiedDate";
    this.indexModel.OrderByAsc = 0;
    this.indexModel.UploaderType = "Photo";
    this.indexModel.DepartmentCode = this.departmentCode;
    this.indexModel.CategoryCode = this.catCode;
    this.mediaService.GetUploaderDataForFront(this.indexModel).subscribe((response) => {
      if (response.IsSuccess) {
        this.model = response.Data.Data as FrontUploaderDataResponseModel[];
      } else {
        this.model = []
      }
    }, (error) => {
      this.model = []
    });
  }


}
