import { EventModel } from './../event-listing/event-listing.component';
import { BaseService } from './../services/baseservice';
import { Dictionary } from './../common/dictionary';
import { Injectable } from '@angular/core';
import { AppSettings } from './../common/AppSettings';
import { Observable } from 'rxjs';
import { ProgramList } from '../program/program.component';
import { PopupImageModel } from '../home/home.component';
import { BannerImageList } from '../main/main.component';
import { CalendarEvent } from 'calendar-utils';
import { PressReleaseModel } from '../home-press-release/home-press-release.component';
import { keyinitiativesModel } from '../keyinitiatives/keyinitiatives.component';
import { ProgramModel } from '../search/search.component';
import { EventRequestModel, EventLatestRequestModel, ProgramMegaEventRequestModel } from '../Model/event.model';
import { QuickLinkRequestModel } from '../Model/quick-link-request-model.model';
import {  FilterProgramRequestModel } from '../Model/filter-program-model.model';
import { PressReleaseRequestModel } from '../Model/press-release-model.model';
import { ProgramDetailRequestModel } from '../Model/program-detail-model.model';
import { AchievementAllSearchModel, IndexModel } from '../Model/awards-request-model.model';

@Injectable()
export class EventService {
  public constructor(private httpService: BaseService) {

  }


  GetProgramEventListingForFrontLatest(model:EventRequestModel): Observable<EventModel[]> {
    return this.httpService.post(AppSettings.API_ENDPOINT + AppSettings.API_PROGRAM_LIST_Post, model);
  }


  GetProgramDetailByIdLatest(model: ProgramDetailRequestModel) {

    return this.httpService.post(AppSettings.API_ENDPOINT + AppSettings.API_PROGRAM_DETAILS_POST, model);
  }

  GetProgram(): Observable<ProgramList[]> {
    return this.httpService.get(AppSettings.API_ENDPOINT + AppSettings.API_ALLPROGRAM_LIST_GET);
  }

  GetBannerList(): Observable<BannerImageList[]> {
    return this.httpService.get(AppSettings.API_ENDPOINT + AppSettings.API_PROGRAM_BANNER_IMAGE_LIST_GET);
  }

  GetPopUpList(): Observable<PopupImageModel[]> {
    return this.httpService.get(AppSettings.API_ENDPOINT + AppSettings.API_PROGRAM_POPUP_IMAGE_LIST_GET);
  }


  GetCalendarProgram(): Observable<CalendarEvent[]> {
    return this.httpService.get(AppSettings.API_ENDPOINT + AppSettings.API_ALLCALENDAREVENTSPROGRAM_LIST_GET);
  }


  // GetPressReleaseList(): Observable<PressReleaseModel[]> {
  //   return this.httpService.get(AppSettings.API_ENDPOINT + AppSettings.API_PROGRAM_PRESSRELEASE_LIST_GET);
  // }

  GetkeyinitiativesList(): Observable<keyinitiativesModel[]> {
    return this.httpService.get(AppSettings.API_ENDPOINT + AppSettings.API_KEYINITIATIVES_LIST_GET);
  }


  PressReleaseByIdLatest(modelRequest:PressReleaseRequestModel) {

    return this.httpService.post(AppSettings.API_ENDPOINT + AppSettings.API_PROGRAM_PRESSRELEASEBYID_LIST_POST, modelRequest);
  }

  GetBase64StringbyPath(pdffilePath: string) {
    const params = new Dictionary<string>();
    params.Add('pdffilePath', pdffilePath);
    return this.httpService.get(AppSettings.API_ENDPOINT + AppSettings.API_Base64_STRING_GET, params);
  }

  Base64StringBYPDFHindiPath(pdffilePath: string) {
    const params = new Dictionary<string>();
    params.Add('pdffilePath', pdffilePath);
    return this.httpService.get(AppSettings.API_ENDPOINT + AppSettings.API_Base64_STRING_GET, params);
  }



  GetLatestProgramEventListPost(model:EventLatestRequestModel): Observable<EventModel[]> {
    return this.httpService.post(AppSettings.API_ENDPOINT + AppSettings.API_LATESTPROGRAM_LIST_POST, model);
  }



  GetfilterProgramListLatest(modelRequest: FilterProgramRequestModel): Observable<ProgramModel[]> {

    return this.httpService.post(AppSettings.API_ENDPOINT + AppSettings.API_FILTERPROGRAM_LIST_POST, modelRequest);
  }


  getQuickLinksListLatest(modelRequest:QuickLinkRequestModel) {

    if (modelRequest.records > 0) {
      return this.httpService.post(AppSettings.API_ENDPOINT + AppSettings.API_QuickLinks_POST, modelRequest);
    }
    else {
      modelRequest.records = 0;
      return this.httpService.post(AppSettings.API_ENDPOINT + AppSettings.API_QuickLinks_POST, modelRequest);

    }

  }
  GetFrontWebsiteUpdateInfo() {

    return this.httpService.get(AppSettings.API_ENDPOINT + AppSettings.API_GetFrontWebsiteUpdateInfo);
  }

  GetProgramMegaEventListLatest(modelRequest:ProgramMegaEventRequestModel) {

    return this.httpService.post(AppSettings.API_ENDPOINT + AppSettings.API_PROGRAM_MegaEvent_LIST_POST, modelRequest);
  }

  GetLatestMegaEventProgramList(page: number, text: string, date: string, id: string) {
    const params = new Dictionary<string>();
    params.Add('page', page.toString());
    params.Add('text', text);
    params.Add('date', date);
    params.Add('id', id);
    return this.httpService.get(AppSettings.API_ENDPOINT + AppSettings.API_LatestMegaEventProgran_List_Get, params);
  }

  GetPostersListDataFromJankalyan(model: IndexModel):any {
    return this.httpService.post(AppSettings.API_ENDPOINT_Jankalyan + AppSettings.jankalyanApi.Program.GetPostersListurl, model);
  }



}

