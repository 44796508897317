import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DepartmentPageDetailsService } from '../services/department-page-details.service';
import { DepartmentPageViewModel } from '../Model/department-view.model';
import { PageTitleModel } from '../Model/common.model';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService } from '../services/common.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateService } from '../translate.service';
import { DepartmentCodeEnum } from '../Enum/app-enums';

@Component({
  selector: 'app-department-page-detail',
  templateUrl: './department-page-detail.component.html',
  styleUrls: ['./department-page-detail.component.css']
})
export class DepartmentPageDetailComponent implements OnInit {
  pageCode:any;
  model: DepartmentPageViewModel | undefined;
  pageTitle: PageTitleModel | undefined;
  submenuCode:any;
  constructor(private departmentService : DepartmentPageDetailsService, public sanitizer: DomSanitizer, public readonly _commonService: CommonService, private _route: ActivatedRoute, public readonly translate: TranslateService,
  ) {
    this._route.paramMap.subscribe((params: ParamMap) => {
      this.pageTitle = undefined;
      this.model = undefined;

      let _pageCode = params.get("pageCode");
      this.pageCode = Number(_pageCode || 0);

      let _submenuCode = params.get("submenuCode");
      this.submenuCode = Number(_submenuCode || 0);
    });
   }

  ngOnInit() {
    this._route.params.subscribe(params => {
      this.pageCode = Number(params['pageCode']);
      this.GetDepartmentPageDetail();
    });
  }
  GetDepartmentPageDetail() {
    this.departmentService.GetDepartmentPageDetail(DepartmentCodeEnum.CmoWebsiteCode, 0, this.pageCode).subscribe((res: any) => {
      if (res.IsSuccess) {
        this.model = res.Data;
        console.log(this.model,"DepartmentPageDetailComponent")
        if (this.model) {  
          // if (this.submenuCode == 0) {
            this.pageTitle = new PageTitleModel();
            this.pageTitle.NameEnglish = this.model.SubjectEng;
            this.pageTitle.NameHindi = this.model.SubjectHindi;
            // this._commonService.SetWebTitle(this.translate.currentLang == 'hi' ? this.pageTitle.NameHindi : this.pageTitle.NameEnglish);
          // }
          this.model.DescriptionEng = this.sanitizer.bypassSecurityTrustHtml(this.model.DescriptionEng)
          this.model.DescriptionHindi = this.sanitizer.bypassSecurityTrustHtml(this.model.DescriptionHindi)
        }
      }
    }, (error: any) => {
      console.error(error);
    });
  }
}
