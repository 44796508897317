<!-- <div class="bg-scroll pos-rel breadcrumbs-page-img">
  <div class="container">
    <h1>{{'Mega_Event'| translate}}</h1>
  </div>
</div> -->
<div class="breadcrumbs-page-img">
  <div class="banner-hight-2">
    <h1>{{'Mega_Event'| translate}}</h1>
    <div class="inner-page-banner">
      <img src="assets/images/cmo-banner.png" alt="banner" class="cmo-banner">
    </div>
  </div>
</div>
<main id="main">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
      <li class="breadcrumb-item"><a href="javascript:void(0)">{{'Media_CornerSubNav'| translate}}</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{'Mega_Event'| translate}}</li>
    </ol>
  </nav>
  <nav class="navbar justify-content-between m-1">
    <a class="navbar-brand"></a>
    <div class="form-inline">
      <div class="text-start">
        <label>From Date</label>
        <input class="form-control mr-sm-2" type="date" name="" id="" [(ngModel)]="fromDate">
      </div>
      <div class="text-start">
        <label>To Date</label>
        <input class="form-control mr-sm-2" type="date" name="" id="" [(ngModel)]="toDate">
      </div>
      <div class="text-start">
        <label>Search</label>
        <input class="form-control mr-sm-2" [(ngModel)]="searchText" type="search" placeholder="Search" aria-label="Search">
      </div>
      <div class="search-btn">
        <label>&nbsp;</label> 
        <button class="btn btn-outline-primary mr-sm-2" type="submit" (click)="onSearch()"><i class="fa fa-search"></i></button>
      </div>
    </div>
  </nav>
  <section class="program_list">
    <p *ngIf="IsLoading" class="text-center"><b>Loading...</b></p>
    <div class="container" *ngIf="!IsLoading">
      <div class="row mb-4">
        <!-- <div class="card-deck w-100"> -->
          <div class='col-sm-6 col-lg-3 program_list_box' *ngFor="let megaItems of generalModel let i = index">
            <div class="card wow"
              [ngClass]="[i%3 <1 ? 'fadeInLeft' : i%3 <2? 'fadeInUp':i%3 <3? 'fadeInRight':'fadeInLeft' ]">


              <div class="program-img-bx">
                <a class="" routerLink="/mega-event-detail/{{megaItems.Id}}">
                  <img src="{{megaItems.HomePageImageUrl}}" onError="this.src='../../assets/images/program-no-img.png'"
                    alt="" class="img-fluid">
                  <!--<ng-container *ngIf="megaItems.ProgramMainImage!=''; else images">
                    <img src="{{this.mainImageUrl}}{{megaItems.ProgramMainImage}}" onError="this.src='../../assets/images/gallery-no-img.png'" alt="" class="img-fluid">
                  </ng-container>
                  <ng-template #images>
                    <img src="{{this.imageUrl}}{{megaItems.ImageName}}" onError="this.src='../../assets/images/gallery-no-img.png'" alt="" class="img-fluid">
                  </ng-template>-->
                </a>
              </div>
              <div class="card-body">

                <!--<h5 class="card-title"><a routerLink="//mega-event-detail/{{megaItems.ProgramId}}">{{this.translate.currlang == 'hi' ? megaItems.ProgramSubCategoryHindi : megaItems.ProgramSubCategory}}</a></h5>-->
                <a routerLink="//mega-event-detail/{{megaItems.Id}}">
                  <p class="card-text"
                    *ngIf="(this.translate.currlang == 'hi' && megaItems.Description.length<=50 )||(this.translate.currlang != 'hi' && megaItems.Description.length<=50)">

                    {{this.translate.currlang == 'hi' ? megaItems.Description : megaItems.Description}}

                  </p>
                  <p class="card-text"
                    *ngIf="(this.translate.currlang == 'hi' && megaItems.Description.length>50 )||(this.translate.currlang != 'hi' && megaItems.Description.length>50)">

                    {{(this.translate.currlang == 'hi' ? megaItems.Description : megaItems.Description)|slice:0:50}}...

                  </p>
                </a>
                <a class="program_btn_more" routerLink="//mega-event-detail/{{megaItems.Id}}">{{'Read_More'| translate}}
                </a>
              </div>


              <div class="card-footer">
                <small class="text-muted"><i class="fa fa-calendar"></i> {{this.translate.currlang == 'hi' ?
                  megaItems.PressReleaseDateHindi : megaItems.PressreleaseDate | date : 'd MMM, y'}}</small>
              </div>

            </div>
          </div>
        <!-- </div> -->

        <select name="example_length " aria-controls="example " (change)="onPageSize($event)" [(ngModel)]="pageSize" *ngIf="totalRecords > 8"
          class="form-control-sm ng-pristine ng-valid ng-touched" style="width: 70px;">
          <option [value]="totalRecords"> All </option>
          <option value="8">8</option>
          <option value="16">16</option>
          <option value="32">32</option>
          <option value="64">64</option>
          <option value="124">124</option>
        </select>


        <nav aria-label="Page navigation example" class="w-100 text-center mt-4" *ngIf="totalRecords > pageSize">
          <ul class="pagination d-inline-flex" *ngIf="pager.pages && pager.pages.length">
            <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
              <a class="page-link" (click)="GetPressReleaseMegaEventData(1,pageSize)">{{'First'| translate}}</a>
            </li>
            <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
              <a class="page-link" (click)="GetPressReleaseMegaEventData(pager.currentPage - 1,pageSize)">{{'Previous'|
                translate}}</a>
            </li>
            <li class="page-item" *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
              <a class="page-link" (click)="GetPressReleaseMegaEventData(page,pageSize)">{{page}}</a>
            </li>
            <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
              <a class="page-link" (click)="GetPressReleaseMegaEventData(pager.currentPage + 1,pageSize)">{{'Next'|
                translate}}</a>
            </li>
            <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
              <a class="page-link" (click)="GetPressReleaseMegaEventData(pager.totalPages,pageSize)">{{'Last'| translate}}</a>
            </li>
          </ul>
        </nav>
      </div>
      <!-- <h3 *ngIf="!totalRecords" class="text-center">Record Not Found</h3> -->
      <div class="row" *ngIf="generalModel && generalModel.length == 0">
        <div class="no-record col-md-12 justify-content-center">
          {{'No_Record_Found'| translate}}
        </div>
      </div>
    </div>
  </section>
</main>