<section class="inner-page-banner">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="page-title">
          {{title}}</div>
      </div>
    </div>
  </div>
</section>
<section class="form-page inner-page">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="alert alert-danger" role="alert" [hidden]="!isError">
          <h4 class="alert-heading">Error</h4>
          <p>Something went wrong, Please try after sometime</p>

        </div>
       
        <div class="form-bx">
          <div class="col-sm-12 text-right">
            <a  href="javascript:void(0)" (click)="changeLanguage()">{{buttonText}}</a>
          </div>
          <form #newsLetterForm="ngForm">
            <div class="form-group">
              <label for="FullName" [hidden]="!msgEnglish">Name <span style="color: Red;">*</span></label>
              <label for="FullName" [hidden]="msgEnglish">नाम <span style="color: Red;">*</span></label>
              <input type="text" name="FullName" class="form-control" [(ngModel)]="newsLetterModel.Name" pattern="^[a-zA-Z0-9' ]*$" maxlength="100" required>
              <div *ngIf="formErrors.FullName">
                <p class="text-danger"><small>{{ formErrors.FullName }}</small></p>
              </div>
            </div>
            <div class="form-group">
              <label for="Email" [hidden]="!msgEnglish">Email <span style="color: Red;">*</span></label>
              <label for="Email" [hidden]="msgEnglish">ईमेल <span style="color: Red;">*</span></label>
              <input type="email" name="Email" [(ngModel)]="newsLetterModel.Email" class="form-control" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$" maxlength="100" required>
              <div *ngIf="formErrors.Email">
                <p class="text-danger"><small>{{ formErrors.Email }}</small></p>
              </div>
            </div>
            <div class="form-group">
              <label for="Phone" [hidden]="!msgEnglish">Phone No.</label>
              <label for="Phone" [hidden]="msgEnglish">फोन न.</label>
              <input type="text" name="Phone" class="form-control" [(ngModel)]="newsLetterModel.Phone" pattern="^[0-9]*$" minlength="10" maxlength="50">
              <div *ngIf="formErrors.Phone">
                <p class="text-danger"><small>{{ formErrors.Phone }}</small></p>
              </div>
            </div>
            <div class="form-group">
              <label for="Designation" [hidden]="!msgEnglish">Designation</label>
              <label for="Designation" [hidden]="msgEnglish">पद</label>
              <input type="text" name="Designation" class="form-control" [(ngModel)]="newsLetterModel.Designation" maxlength="100">
              <div *ngIf="formErrors.Designation">
                <p class="text-danger"><small>{{ formErrors.Designation }}</small></p>
              </div>
            </div>
            <div class="form-group">

              <label for="Organization" [hidden]="!msgEnglish">Organization</label>
              <label for="Organization" [hidden]="msgEnglish">संस्था</label>
              <input type="text" name="Organization" class="form-control" [(ngModel)]="newsLetterModel.Organization" maxlength="150">
              <div *ngIf="formErrors.Organization">
                <p class="text-danger"><small>{{ formErrors.Organization }}</small></p>
              </div>
            </div>
            <div class="form-group">
              <label for="Address" [hidden]="!msgEnglish">Address</label>
              <label for="Address" [hidden]="msgEnglish">पता</label>
              <textarea class="form-control" name="Address" rows="3" [(ngModel)]="newsLetterModel.Address" maxlength="1000"></textarea>
              <div *ngIf="formErrors.Address">
                <p class="text-danger"><small>{{ formErrors.Address }}</small></p>
              </div>
            </div>
            <div class="form-group">
              <label for="Comment" [hidden]="!msgEnglish">Comment</label>
              <label for="Comment" [hidden]="msgEnglish">विचार</label>
              <textarea class="form-control" name="Comment" rows="3" [(ngModel)]="newsLetterModel.Comment"></textarea>
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1" [hidden]="!msgEnglish">Do you want to recieve news letter for the following group </label>
              <label for="exampleInputEmail1" [hidden]="msgEnglish">क्या आप निचे दिए गये ग्रुप  से सम्बंधित ईमेल प्राप्त  करना चाहते हे  !</label>
              <br>
              <div class="form-check-inline">
                <input type="checkbox" class="form-check-input" (change)="mangeGroup('1',$event.target.checked)" group="NewsLetterGroup" value="1">
                <label class="form-check-label" [hidden]="!msgEnglish">Event</label>
                <label class="form-check-label" [hidden]="msgEnglish">कार्यक्रम</label>
              </div>
              <div class="form-check-inline">
                <input type="checkbox" class="form-check-input" (change)="mangeGroup('5',$event.target.checked)" group="NewsLetterGroup" value="5">
                <label class="form-check-label" [hidden]="!msgEnglish">Press Release</label>
                <label class="form-check-label" [hidden]="msgEnglish">प्रेस विज्ञप्ति</label>
              </div>
              <div class="form-check-inline">
                <input type="checkbox" class="form-check-input" (change)="mangeGroup('7',$event.target.checked)" group="NewsLetterGroup" value="7">
                <label class="form-check-label" [hidden]="!msgEnglish">Video</label>
                <label class="form-check-label" [hidden]="msgEnglish">विडियो</label>
              </div>
              <div class="form-check-inline">
                <input type="checkbox" class="form-check-input" (change)="mangeGroup('8',$event.target.checked)" group="NewsLetterGroup" value="8">
                <label class="form-check-label" [hidden]="!msgEnglish">Photo</label>
                <label class="form-check-label" [hidden]="msgEnglish">फोटो</label>
              </div>
              <div class="form-check-inline">
                <input type="checkbox" class="form-check-input" (change)="mangeGroup('2',$event.target.checked)" group="NewsLetterGroup" value="2">
                <label class="form-check-label" [hidden]="!msgEnglish">speech</label>
                <label class="form-check-label" [hidden]="msgEnglish">भाषण</label>
              </div>
              <div class="form-check-inline">
                <input type="checkbox" class="form-check-input" (change)="mangeGroup('4',$event.target.checked)" group="NewsLetterGroup" value="4">
                <label class="form-check-label" [hidden]="!msgEnglish">Message</label>
                <label class="form-check-label" [hidden]="msgEnglish">संदेश</label>
              </div>
              <div class="form-check-inline">
                <input type="checkbox" class="form-check-input" (change)="mangeGroup('9',$event.target.checked)" group="NewsLetterGroup" value="9">
                <label class="form-check-label" [hidden]="!msgEnglish">Important initiative</label>
                <label class="form-check-label" [hidden]="msgEnglish">महत्तवपूर्ण पहल</label>
              </div>

              <div class="form-check-inline">
                <input type="checkbox" class="form-check-input" (change)="mangeGroup('10',$event.target.checked)" group="NewsLetterGroup" value="10">
                <label class="form-check-label" [hidden]="!msgEnglish">Other</label>
                <label class="form-check-label" [hidden]="msgEnglish">अन्य</label>
              </div>
            </div>
            <button class="btn btn-primary" (click)="onSubmit()">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

