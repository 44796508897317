
<div class="bg-scroll pos-rel breadcrumbs-page-img">
  <div class="container">
    <h1>{{'Chief_SecretarySubNav'| translate}}</h1>  
  </div>
</div>
<main id="main">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
      <li class="breadcrumb-item"><a href="javascript:void(0)"> {{'STATE_PROFILEFooter'| translate}} </a></li>
      <li class="breadcrumb-item active" aria-current="page">{{'Chief_SecretarySubNav'| translate}} </li>
    </ol>
  </nav>
  <section class="program_list">
    <div class="container">
      <div class="row">
        <div class="col-xl-4">
          <div class="profile_box wow fadeInLeft" id="sidemenu">
            <img src="assets/img/cs.png" alt="" class="img-fluid" />
            <div class="name text-center">Shri Devendra Bhushan Gupta</div>
            <div class="chief text-center">
              Chief Secretary,<br />
              Government of Rajasthan
            </div>
          </div>
        </div>
        <div class="col-xl-8">
          <div class="profile_detail wow fadeInRight">
            <p><strong>Identity No : &nbsp;</strong>024000</p>
            <p><strong>Service/Cadre/Allotment Year : &nbsp;</strong>​IAS/Rajasthan/1983 </p>
            <p><strong>Source of Recruitment: &nbsp;</strong>RR</p>
            <p><strong>Date of Birth	: &nbsp;</strong>​11/09/1960</p>
            <p><strong>Home Town	: &nbsp;</strong>​Jaipur, Rajasthan</p>
            <p><strong>Indian Languages Known	: &nbsp;</strong>​Hindi, English</p>
            <p><strong>Educational Qualification	: &nbsp;</strong>M.A.(Economics), M.B.A. , B.A. (Hons.)(Economics)​​</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
