<div [ngClass]="!isCodeOfConduct ? ' breadcrumbs-page-img' : 'bg-scroll pos-rel breadcrumbs-page-img '">
  <div class="banner-hight-2">
    <h1>{{'Key_Officials'| translate}}</h1>
    <div class="inner-page-banner">
      <img src="assets/images/cmo-banner.png" alt="banner" class="cmo-banner">
    </div>
  </div>
</div>
<main id="main">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
      <li class="breadcrumb-item"><a href="javascript:void(0)">{{'CM_OfficeNav'| translate}} </a></li>
      <li class="breadcrumb-item active" aria-current="page">{{'Key_Officials'| translate}}</li>
    </ol>
  </nav>
  <section class="program_list">
    <div class="container">

      <div class="row">
        <div class="table-responsive">
          <table class="table table-striped table-bordered table_blue" cellspacing="0" cellpadding="0">

            <thead>
              <tr>
                <th>{{'KeyOfficialSNo'| translate}}</th>
                <th>{{'NameofOfficer'| translate}}</th>
                <th>{{'Designation'| translate}}</th>
                <th>{{'IPNo'| translate}}</th>
                <th *ngIf="isMobileExist">{{'Contact'| translate}}</th>
                <th *ngIf="isEmailExist">{{'E-Mail'| translate}}</th>
              </tr>
            </thead>
            <tbody>
            <tbody>
              <ng-container *ngFor="let item of model; let i = index">
                <tr>
                  <td> {{ (indexModel.Page - 1) * indexModel.PageSize + (i + 1) }} </td>
                  <td> {{ this.translate.currlang == "en" ? item.OfficerName : item.OfficerNameInHindi }} </td>
                  <td> {{ this.translate.currlang == "en" ? item.DesignationName : item.DesignationNameHindi == "NULL"
                    ? "--" : item.DesignationNameHindi }} </td>
                  <td>
                    {{ this.translate.currlang == "en" ? item.Place : item.PlaceInHindi }}
                    {{ this.translate.currlang == "en" ? item.Address : item.AddressInHindi }}
                  </td>
                  <td *ngIf="isMobileExist"> <a href="tel:{{ item.MobileNo }}"> {{ item.MobileNo }} </a> </td>
                  <td *ngIf="isEmailExist">  {{ item.Email | emailFormat}}  </td>
                </tr>
              </ng-container>
            </tbody>

          </table>
        </div>
      </div>

    </div>
  </section>
</main>