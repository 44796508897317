import { EventService } from './../services/events.services';
import { Component, Inject, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { TranslateService } from '../translate.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  providers: [EventService]
})
export class HomeComponent implements OnInit {
  popupImageList: PopupImageModel[];
  imageUrlTemp =  environment.imageUrl + 'Program/KeyInitiativesimage/'; /*'http://192.168.7.72:8094/Program/KeyInitiativesimage/';*/
  constructor(@Inject('BASE_URL') public baseUrl: string,
    private translate: TranslateService, private eventService: EventService) {
    this.popupImageList = [];
  }

  setLang(lang: string) {
    this.translate.use(lang);
  }

  ngOnInit() {
    this.getImageData();
  }
  getImageData() {
    this.eventService.GetPopUpList().subscribe(success => {
      if (success !== undefined && success !== null) {
        this.popupImageList = success;
      }
    }, error => {
      console.error(error);
    });
  }
}
export class PopupImageModel {
  ProgramId: number;
  PopupImages: string;
}
