<div class="breadcrumbs-page-img">
    <div class="banner-hight-2">
        <h1>{{'FormerCM_txt'| translate}}</h1>
        <div class="inner-page-banner">
            <img src="assets/images/cmo-banner.png" alt="banner" class="cmo-banner">
        </div>
    </div>
</div>

<main id="main">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
            <!-- <li class="breadcrumb-item"><a href="javascript:void(0)">{{'Media_CornerSubNav'| translate}}</a></li> -->
            <li class="breadcrumb-item active" aria-current="page">{{'FormerCM_txt'| translate}}</li>
        </ol>
    </nav>

    <section>
        <div class="container">
            <div class="row mt-5 mb-5">
                <div class="col-lg-3 col-md-6 col-sm-6 col-12 mt-2  mb-2 form-cm-text"
                    *ngFor="let item of FormerCMViewModel;index as i ">
                    <a href="{{item.Url}}" target="_blank" style="text-decoration: none; color: black;">
                        <div style="background-image: url(assets/images/banner-2.png); background-size: 100% 100%; border-radius: 15px; padding: 14px;">
                            <img src="{{item?.ImagePath}}" alt="banner" class="cmo-governor">
                            <p style="text-align: center; padding-top: 25px; font-weight: 600; font-size: 16px;">
                                {{this.translate.currlang == 'hi' ? item.SubjectHindi : item.Achievement}}
                            </p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </section>

</main>