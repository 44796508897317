import { Component, OnInit, Inject } from '@angular/core';
import { EventService } from '../services/events.services';
import { TranslateService } from '../translate.service';
import { environment } from '../../environments/environment';
import { QuickLinkRequestModel } from '../Model/quick-link-request-model.model'
import { CommonService } from '../services/common.service';
@Component({
  selector: 'app-quick-link',
  templateUrl: './quick-link.component.html',
  providers: [EventService]

})
export class QuickLinkComponent implements OnInit {
  quickLinkList: QuickLinkModel[];
  imageUrlTemp = environment.imageUrl + 'Program/QuickLinksIcon/';
  modelRequest: QuickLinkRequestModel;
  // isCodeOfConduct: boolean = true;
  isCodeOfConduct: boolean = false;
  constructor(@Inject('BASE_URL') public baseUrl: string,
    private translate: TranslateService, 
    private eventService: EventService,
    public _commonService: CommonService) {
    this.isCodeOfConduct = this._commonService.GetIsCodeOfConduct();
    this.quickLinkList = [];
    this.modelRequest = new QuickLinkRequestModel();
  }

  setLang(lang: string) {
    this.translate.use(lang);
  }

  ngOnInit() {
    //this.getQuickLinks();
  }

  getQuickLinks() {
    this.modelRequest.records = 5;
    this.eventService.getQuickLinksListLatest(this.modelRequest).subscribe(responce => {
      
      if (responce != undefined) {
        this.quickLinkList = <QuickLinkModel[]>responce;
      }
    });
  }

   validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

}

export class QuickLinkModel {
  QuickLinkId: number;
  Title: string;
  TitleHindi: string;
  Icon: string;
  QuickLinkUrl: string;
}
