<ul class="wow fadeInRight" *ngIf="speechprograms.length!=0">

  <li *ngFor="let program of speechprograms">
    <div class="press_releases_bx d-flex">
      <div class="date align-self-center">
        {{program.ProgramDate | date : 'dd'}} <br>
        <div style="font-size:13px;">{{program.ProgramDate| date : 'MMMM'}}</div>
      </div>
      <div class="detail align-self-center"> <a routerLink="/speechdetail/{{program.ProgramId}}">{{this.translate.currlang == 'hi' ? program.ProgramSubjectHindi : program.ProgramSubject}} </a> <span><i class="ion-ios-location-outline"></i> {{this.translate.currlang == 'hi' ? program.ProgramAddressHindi : program.ProgramAddress}}</span> <span> <i class="fa fa-calendar" aria-hidden="true"></i> &nbsp;{{program.ProgramDate }}</span> </div>
    </div>
  </li>

</ul>
<div class="col-lg-12 text-right pt-2 wow fadeInUp" *ngIf="speechprograms.length>0"> <a routerLink="/speech-list" class="more_btn">View more <i class="fa fa-angle-right" aria-hidden="true"></i></a> </div>

