
import { Data } from './../services/data.services';
import { StateministerService } from './../services/stateminister.service';
import { Component, Inject, OnInit } from '@angular/core';
import { PagerService } from '../pager.service';
import { environment } from '../../environments/environment';
import { TranslateService } from '../translate.service';


@Component({
  selector: 'app-cabinateminister',
  templateUrl: './cabinateminister.component.html',
  styleUrls: ['./cabinateminister.component.css']
})
export class CabinateministerComponent implements OnInit {

  public cabinetMinisters: MinisterModel[];

  imageUrl = environment.imageUrl + 'Program/ProfileImage/';
  constructor(@Inject('BASE_URL') public baseUrl: string,
    public translate: TranslateService, private stateMinisterService: StateministerService) {
    this.cabinetMinisters = [];
  }
  ngOnInit() {
    this.getData();
  }

  setLang(lang: string) {
    this.translate.use(lang);
  }
  public getData() {
    this.stateMinisterService.GetCabinetMinisterList().subscribe(success => {
      this.cabinetMinisters = success;
    }, error => {
      console.error(error);
    });

  }
}

export class MinisterModel {
  SNo: string
  CabinetId: Number
  CabinetName: string;
  CabinetNameHindi: string;
  CabinetDepartment: string;
  CabinetDepartmentHindi: string;
  CabinetAddress: string;
  CabinetAddressHindi: string;
  CabinetRoom: string;
  CabinetRoomHindi: string;
  PhoneOffice: string;
  PhoneResidence: string;
  PhoneAssembly: string;
  CabinetOrder: string;
  CabinetTitleId: string;
  CabinetType: string;
  IsActive: string;
  IsDeleted: string;
  Url: string;
  Status: string;
  MinisterType: string;
  Title: string;
  TitleHindi: string;
}
