import { SafeResourceUrl } from "@angular/platform-browser";

export class AwardsRequestModel {
  page: number
  pageSize: number;
  excludeId: number;
}

export class ActiveAwardsRequestModel {
  id: number;
}

export class ActiveLatestAwardsRequestModel {
  excludeId: number = 0;
}


export class AchievementAllSearchModel {
  AdmDepartmentCode: number | string = 0;
  DepartmentCode: number | string = 0;
  CategoryCode: number | string = 0;
  SubCategoryCode: number | string = 0;
  ToDate: string = "";
  FromDate: string = "";
  SearchKeyword: string = "";
  IsURLRequired: boolean = false;
  IsImageRequired: boolean = false;
  IsPDFRequired: boolean = false;
  IsActive: boolean | null = true;
  IsVisibleInHome: boolean | null = null;
  IsVisibleInFront: boolean | null = null;
  IndexModel = new IndexModel();
  IsBase64File: boolean = false;

}

export class IndexModel {
  Page: number;
  PageSize: number;
  Search: string;
  OrderBy: string;
  OrderByAsc: number;
  IsPostBack: boolean;
  AdvanceSearchModel?: { [index: string]: any } = {}; //any;


  constructor() {
    this.PageSize = 10;
    this.IsPostBack = false;
    this.OrderByAsc = 0;
    this.Search = null;
    this.OrderBy = null;
    this.Page = 1;
  }
}

export class AchievementViewModel {
  Id: number;
  Achievement: string;
  AchievementHindi: string;
  AchievementCategoryCode: number;
  DepartmentCode: number;
  Description: string;
  DescriptionHindi: string;
  Priority: number;
  PdfFIleName: string;
  Url: string;
  AchievementDate: Date;
  AchievementDateHindi: string;
  CreatedBy: number;
  ModifiedBy: number;
  CreatedDate: Date;
  ModifiedDate: Date;
  IsActive: boolean;
  IsDeleted: boolean;
  IsVisible: boolean;
  AchievementSubCategoryCode: number;
  ImagePath: string;
  ThumbnailPath: string;
  AchievementCategory: string;
  AchievementCategoryHindi: string;
  AchievementCategoryIsVisible: boolean;
  AchievementCategoryImagePath: string;
  AchievementSubCategory: string;
  AchievementSubCategoryHindi: string;
  AchievementSubCategoryImagePath: string;
  Department: string;
  DepartmentHindi: string;
  ImageFiles: string[];
  SafeUrl: SafeResourceUrl;
  SubjectHindi: string;
  EditorDetail: string;
  EditorDetailEnglish: string;
}
export class MediaControlModel {
  Item: any;
  IsAudio: boolean;
}

export class AchievementCatgoryModel {
  CategoryCode: number | string;
  Title: string;
  TitleHindi: string;
  ImagePath: string;
  SubMenuNameEnglish: string;
  SubMenuNameHindi: string;
}
export class AchievementSubCategoryModel {
  SubCategoryCode: number;
  Title: string;
  TitleHindi: string;
  ImagePath: string;
  Description: string;
}

export class DepartmentSubMenuDetailModel {
  DisplayNameEnglish: string;
  DisplayNameHindi: string;
  IconImage: string;
  PDFAttachment: string;
  RedirectionURL: string;
}
