<div class="breadcrumbs-page-img">
    <div class="banner-hight-2">
        <h1>{{'WebsitePolicyt_txt'|translate}}</h1>
        <div class="inner-page-banner">
            <img src="assets/images/cmo-banner.png" alt="banner" class="cmo-banner">
        </div>
    </div>
</div>

<app-loader></app-loader>

<main id="main">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
            <!-- <li class="breadcrumb-item"><a href="javascrtipt:void(0)"> Citizen Corner</a></li> -->
            <li class="breadcrumb-item active" aria-current="page">
                {{'WebsitePolicyt_txt'|translate}}
            </li>
        </ol>
    </nav>
    <section class="inner-page page-about page-editor-entry-design">
        <div class="container">
            <div class="row">
                <div *ngIf="entryTypes && entryTypes.length>0" class="col-lg-12">
                    <ul class="quick-link site-map-link page-sitemap-list p-5 page-link-listing" style="list-style: none;">
                        <li *ngFor="let item of entryTypes">
                            <a
                                routerLink="/jankalyan-category-and-entry-type/0/{{item.JankalyanCategoryCode}}/{{item.Code}}">
                                {{ translate.currlang!='hi' ? item?.SubMenuNameEnglish : item?.SubMenuNameHindi }}
                            </a>
                        </li>
                    </ul>
                </div>
                <div *ngIf="entryTypes && entryTypes.length==0" class="col-lg-12">
                    <h3 class=" text-center">{{'No_Record_Found'|translate}}</h3>
                </div>
                <!-- <app-mini-loader *ngIf="!entryTypes"></app-mini-loader>
                <app-no-records [showImage]="true" *ngIf="entryTypes && entryTypes.length==0"></app-no-records> -->
            </div>
        </div>
    </section>
</main>