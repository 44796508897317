
<div class="bg-scroll pos-rel breadcrumbs-page-img">
  <div class="container">
   
    <h1>{{'Deputy_Chief_MinisterFooter'| translate}}</h1>
   
  </div>
</div>
<main id="main">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
      <li class="breadcrumb-item"><a href="javascript:void(0)">{{'STATE_PROFILEFooter'| translate}}</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{'Deputy_Chief_MinisterFooter'| translate}}</li>
    </ol>
  </nav>
  <section class="program_list">
    <div class="container">
      <div class="row">
        <div class="col-xl-4">
          <div class="profile_box wow fadeInLeft" id="sidemenu">
            <img src="assets/img/deputy_cm.png" alt="" class="img-fluid" />
            <div class="name text-center">Sh. Sachin Pilot</div>
            <div class="chief text-center">Deputy Chief Minister</div>
          </div>
        </div>
        <div class="col-xl-8">
          <div class="profile_detail">
            <p class="wow fadeInUp"><strong>Sh. Sachin Pilot</strong> is the son of Late Sh. Rajesh Pilot. Sh. Pilot was born on September 7, 1977. He is an alumnus of St. Stephen’s College (University of Delhi), where he pursued a Bachelors degree (Hons) in English Literature. After graduating, he worked at the Delhi Bureau of the BBC, and subsequently went on to work for the General Motors Corporation. Sh. Pilot completed his MBA Degree at the Wharton Business School (University of Pennsylvania, U.S.A), where he specialized in multinational management and finance.</p>
            <p class="wow fadeInUp">At 26 years of age, he was the youngest MP when he first got elected to the 14th Lok Sabha from the Dausa Parliamentary Constituency of Rajasthan in the year 2004. He has served as a Member of the Parliament’s Standing Committee on Home Affairs, Consultative Committee in the Ministry of Civil Aviation and also Budget Estimates Committee of Parliament.</p>
            <p class="wow fadeInUp">​In May 09, he was re-elected to Lok Sabha from Ajmer Parliamentary Constituency of Rajasthan and he became Minister of State (MoS) in the Ministry of Communication & Information Technology and in 2012 he became Minister of State (Independent charge) of Ministry of Corporate Affairs. During this time, he was the youngest minister in the cabinet. Presently he is serving as President, Rajasthan Pradesh Congress Committee.</p>
            <p class="wow fadeInUp">As an elected MP and a Minister of the cabinet, Sh. Pilot focused his energies on enabling people to avail better social and economic opportunities. He has emphasized job creation, and improvement in the quality and quantity of health and education services. Realizing the importance of connectivity and information, he has worked hard to bring rural communities closer to the rest of India and the world, by expanding the physical, IT, and telecom infrastructure in their region.</p>
            <p class="wow fadeInUp">Sh. Pilot travels extensively in India, especially to remote and interior areas of the country. He takes a keen interest on issues that affect the farming community and the youth. Sh. Pilot believes that India must train and educate its youth if we want to real the demographic dividend arising from a disproportionately younger population. He encourages the youth to take an active part in public life, and shoulder greater social and political responsibility.</p>
            <p class="wow fadeInUp">In recognition of his professional accomplishments and commitment to society, Sh. Pilot was selected as one of the Young Global Leaders by the World Economic Forum in 2008. Sh. Pilot is fond of flying and received his private pilot’s license (PPL) from NY, USA in 1995. He is a keen sportsman and has represented Delhi State in a number of National Shooting Championships. He has also been commissioned as Lieutenant in the Territorial Army.</p>
            <p class="wow fadeInUp"><strong>Sh. Sachin Pilot is also the President of the Rajasthan Pradesh Congress Committee.</strong> </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
