<!-- <div>
    <div class="container">
        <h1> {{'Home_RECENT_NEWS'| translate}}</h1>

    </div>
</div> -->
<div class="breadcrumbs-page-img">
    <div class="banner-hight-2">
      <h1>{{'Home_RECENT_NEWS'| translate}}</h1>
      <div class="inner-page-banner">
        <img src="assets/images/cmo-banner.png" alt="banner" class="cmo-banner">
      </div>
    </div>
  </div>
<main id="main">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
            <li class="breadcrumb-item active" aria-current="page">{{'Feedback_txt'| translate}}</li>
        </ol>
    </nav>
    <section class="inner-page pt-0" *ngIf="showfeedback">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-4 px-3 px-md-5 py-5"></div>
                <div class="col-lg-4 px-3 px-md-5 py-5">
                    <div class="form-apply-online-1">
                        <h1 class="title text-center">
                            <img src="assets/images/default-icon.png" alt="CMO" class="img-fluid"
                                style="max-width: 40px;"> {{'Feedback_txt' | translate }}
                        </h1>
                        <form [formGroup]="FormGroup">
                            <div class="form-apply-online mt-4">
                                <div class="form-group">
                                    <label>{{'Name_txt'|translate}} <span style="color:red">*</span></label>
                                    <input type="text" required [(ngModel)]="suggestionFeedbackModel.Name"
                                        maxlength="250" placeholder="{{'Name_txt'|translate}}" class="form-control"
                                        formControlName="Name"
                                        [ngClass]="{ 'is-invalid': FormGroup.controls.Name.errors && FormGroup.controls.Name.touched}">
                                    <div class="invalid-feedback"
                                        *ngIf="(FormGroup.get('Name')?.hasError('required') && FormGroup.get('Name')?.touched)">
                                        {{translate.currlang !='en'? 'कृपया '+('Name_txt'|translate) +' लिखें' :
                                        'Please Enter '+ ('Name_txt'|translate)}} </div>

                                </div>

                                <!-- <div class="form-group">
                                    <label>{{'MobileNo_txt'|translate}}<span style="color:red">*</span></label>
                                    <input type="text" required [(ngModel)]="suggestionFeedbackModel.Mobile"
                                        maxlength="15" placeholder="{{'MobileNo_txt'|translate}}" class="form-control"
                                        formControlName="Mobile" (keypress)="_commonService.numberOnly($event);"
                                        [ngClass]="{ 'is-invalid': FormGroup.controls.Mobile.errors && FormGroup.controls.Mobile.touched}">
                                    <div class="invalid-feedback"
                                        *ngIf="(FormGroup.get('Mobile')?.hasError('required') && FormGroup.get('Mobile')?.touched)">
                                        {{this.translate.currlang !='en'? 'कृपया '+('MobileNo_txt'|translate) +' लिखें'
                                        :
                                        'Please Enter '+ ('MobileNo_txt'|translate)}} </div>
                                    <div class="invalid-feedback"
                                        *ngIf="!FormGroup.get('Mobile')?.hasError('required') && ((FormGroup.get('Mobile')?.hasError('maxlength') || FormGroup.get('Mobile')?.hasError('minlength')) && FormGroup.get('Mobile')?.touched) || (FormGroup.get('Mobile')?.hasError('maxlength') || FormGroup.get('Mobile')?.hasError('minlength'))">
                                        {{this.translate.currlang !='en'? 'कृपया वैध '+('MobileNo_txt'|translate) +'
                                        दर्ज करें' : 'Please Enter valid '+ ('MobileNo_txt'|translate)}} </div>

                                </div> -->

                                <div class="form-group">
                                    <label>{{'txtEmail'|translate}}</label>
                                    <input type="text" [(ngModel)]="suggestionFeedbackModel.Email"
                                        placeholder="{{'txtEmail'|translate}}" class="form-control"
                                        formControlName="Email" [ngClass]="{ 'is-invalid': FormGroup.controls.Email.errors && FormGroup.controls.Email.touched}">
                                        <!-- <div class="invalid-feedback"
                                        *ngIf="(FormGroup.get('Email')?.hasError('required') && FormGroup.get('Email')?.touched)">
                                        {{translate.currlang !='en'? 'कृपया '+('txtEmail'|translate) +' लिखें' :
                                        'Please Enter '+ ('txtEmail'|translate)}} </div> -->
                                        <div class="invalid-feedback"
                                        *ngIf="(FormGroup.get('Email')?.hasError('email') && FormGroup.get('Email')?.touched)">
                                        {{translate.currlang !='en'? 'कृपया वैध '+('txtEmail'|translate) +'
                                        दर्ज करें' : 'Please Enter valid '+ ('txtEmail'|translate)}} </div>
                                </div>

                                <div class="form-group">
                                    <label>{{'Sectors_txt'|translate}}</label>
                                    <select [(ngModel)]="suggestionFeedbackModel.SectorCode" class="form-control"
                                        formControlName="SectorCode" [ngClass]="{ 'is-invalid': FormGroup.controls.SectorCode.errors && FormGroup.controls.SectorCode.touched}">
                                        <option value="" selected disabled>{{'Sectors_txt'|translate}}</option>
                                        <option [value]="item.Value" *ngFor="let item of sectorsData">{{translate.currlang !='en'? item.TextInHindi : item.Text2}}</option>
                                    </select>
                                        <!-- <div class="invalid-feedback"
                                        *ngIf="(FormGroup.get('SectorCode')?.hasError('required') && FormGroup.get('SectorCode')?.touched)">
                                        {{translate.currlang !='en'? 'कृपया '+('Sectors_txt'|translate) +' लिखें' :
                                        'Please Enter '+ ('Sectors_txt'|translate)}} </div> -->
                                        <div class="invalid-feedback"
                                        *ngIf="(FormGroup.get('SectorCode')?.hasError('required') && FormGroup.get('SectorCode')?.touched)">
                                        {{translate.currlang !='en'? 'कृपया '+('Sectors_txt'|translate) +' चयन कीजिए' :
                                        'Please Select '+ ('Sectors_txt'|translate)}} </div>
                                </div>

                                <!-- <div class="form-group">
                                    <label>{{'District_txt'|translate}} <span style="color:red">*</span></label>
                                    <select class="form-control" [(ngModel)]="suggestionFeedbackModel.DistrictCode"
                                        [ngClass]="{ 'is-invalid': FormGroup?.controls?.District?.errors && FormGroup?.controls?.District?.touched}"
                                        formControlName="District"
                                        (change)="getFilterdDDL('ddlAssemblyConstituency',suggestionFeedbackModel.DistrictCode,'ddlDistrict')">
                                        <option selected value="">--{{'District_txt'|translate}}--</option>
                                        <option *ngFor="let item of dDLList?.ddlDistrict" [ngValue]="item.Value"> {{item.Text}}
                                        </option>
                                    </select>
                                    <div class="invalid-feedback"
                                        *ngIf="(FormGroup.get('District')?.hasError('required') && FormGroup.get('District')?.touched)">
                                        {{this._translate.currlang !='en'? 'कृपया '+('District_txt'|translate) +' चयन करें' :
                                        'Please Select '+ ('District_txt'|translate)}} </div>
                                </div> -->

                                <div class="form-group">
                                    <label>{{'Feedback_txt'|translate}} <span style="color:red">*</span></label>
                                    <textarea type="text" required [(ngModel)]="suggestionFeedbackModel.Feedback"
                                        placeholder="{{'Feedback_txt'|translate}}" class="form-control"
                                        formControlName="Feedback"
                                        [ngClass]="{ 'is-invalid': FormGroup.controls.Feedback.errors && FormGroup.controls.Feedback.touched}">
                                    </textarea>
                                    <div class="invalid-feedback"
                                        *ngIf="(FormGroup.get('Feedback')?.hasError('required') && FormGroup.get('Feedback')?.touched)">
                                        {{translate.currlang !='en'? 'कृपया '+('Feedback_txt'|translate) +'
                                        लिखें'
                                        : 'Please Enter '+ ('Feedback_txt'|translate)}} </div>
                                    <div class="invalid-feedback"
                                        *ngIf="!FormGroup.get('Feedback')?.hasError('required') && FormGroup.get('Feedback')?.touched && FormGroup.get('Feedback')?.invalid">
                                        {{translate.currlang !='en'? 'कृपया वैध '+('Feedback_txt'|translate) +'
                                        दर्ज करें' : 'Please Enter valid '+ ('Feedback_txt'|translate)}} </div>
                                </div>

                                <div class="form-group">
                                    <label>{{'Attach_pdf_txt'|translate}}</label>
                                    <input type="file" class="form-control" style="border: 0" formControlName="pdf" [ngClass]="{ 'is-invalid': FormGroup.controls.pdf.errors && FormGroup.controls.pdf.touched}" (change)="onFileSelected($event)" accept="application/pdf">
                                        <!-- <div class="invalid-feedback"
                                        *ngIf="(FormGroup.get('pdf')?.hasError('required') && FormGroup.get('pdf')?.touched)">
                                        {{translate.currlang !='en'? 'कृपया '+('Sectors_txt'|translate) +' लिखें' :
                                        'Please Enter '+ ('Sectors_txt'|translate)}} </div> -->
                                        <!-- <div class="invalid-feedback"
                                        *ngIf="(FormGroup.get('pdf')?.hasError('required') && FormGroup.get('pdf')?.touched)">
                                        {{translate.currlang !='en'? 'कृपया '+('Attach_pdf_txt'|translate) +' लिखें' :
                                        'Please Enter '+ ('Attach_pdf_txt'|translate)}} </div> -->
                                </div>

                                <div class="form-apply-online-btn mt-4"> <a href="javascript:void(0)"
                                        (click)="SaveClick()"
                                        class="btn btn-default p-2 blue-banner w-100">{{'Submit_txt' |
                                        translate}}</a> </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </section>

    <section class="inner-page pt-0" *ngIf="showRegistration">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-4 px-3 px-md-5 py-5"></div>
                <div class="col-lg-4 px-3 px-md-5 py-5">
                    <div class="form-apply-online-1">
                        <h1 class="title text-center"><img src="assets/images/default-icon.png" alt="CMO"
                                class="img-fluid" style="max-width: 40px;"> {{'Suggestions_txt'|translate}} </h1>
                        <ng-container>
                            <form [formGroup]="signUpformGroup">
                                <div class="form-apply-online">
                                    <div class="form-group form-w-icon position-relative">
                                        <input type="text" required [(ngModel)]="signUpModel.Name" maxlength="250"
                                            placeholder="{{'Name_txt'|translate}}" class="form-control"
                                            formControlName="Name"
                                            [ngClass]="{ 'is-invalid': signUpformGroup.controls.Name.errors && signUpformGroup.controls.Name.touched}">
                                        <i class="bi bi-person"></i>
                                        <div class="invalid-feedback"
                                            *ngIf="(signUpformGroup.get('Name')?.hasError('required') && signUpformGroup.get('Name')?.touched)">
                                            {{translate.currlang =='en'? 'Please Enter '+ ('Name_txt'|translate) :
                                            'कृपया '+('Name_txt'|translate) +' लिखें'}} </div>
                                            
                                    </div>
                                    <div class="form-group form-w-icon position-relative">
                                        <input type="text" required [(ngModel)]="signUpModel.Mobile" maxlength="10"
                                            placeholder="{{'MobileNo_txt'|translate}}" class="form-control"
                                            formControlName="Mobile" (keypress)="numberOnly($event)"
                                            (keyup.enter)="onEnterPress()"
                                            [ngClass]="{ 'is-invalid': signUpformGroup.controls.Mobile.errors && signUpformGroup.controls.Mobile.touched}">
                                        <i class="bi bi-telephone"></i>
                                        <div class="invalid-feedback"
                                            *ngIf="(signUpformGroup.get('Mobile')?.hasError('required') && signUpformGroup.get('Mobile')?.touched)">
                                            {{translate.currlang =='en'? 'Please Enter '+ ('MobileNo_txt'|translate) :
                                            'कृपया '+('MobileNo_txt'|translate) +' लिखें'}} </div>
                                        <div class="invalid-feedback"
                                            *ngIf="!signUpformGroup.get('Mobile')?.hasError('required') && ((signUpformGroup.get('Mobile')?.hasError('maxlength') || signUpformGroup.get('Mobile')?.hasError('minlength')) && signUpformGroup.get('Mobile')?.touched) || (signUpformGroup.get('Mobile')?.hasError('maxlength') || signUpformGroup.get('Mobile')?.hasError('minlength'))">
                                            {{translate.currlang =='en'? 'Please Enter valid '+ ('MobileNo_txt'|translate) : 'कृपया वैध '+('MobileNo_txt'|translate) +' दर्जज़ करें'}} </div>
                                    </div>


                                    <div class="row row-2">
                                        <!-- <div class="captcha-text col-md-6">
                                            <div class="captcha-text1 " *ngFor="let captcha of captchaText">
                                                <b>{{captcha}}</b>
                                            </div>
                                        </div>
                                        <div class="captcha-regenerate3 col-md-6">
                                            <div class="row">
                                                <div class="captcha-regenerate2 col-md-6">
                                                    <input type="text" formControlName="CaptchaEntered"
                                                        [ngClass]="{ 'is-invalid': signUpformGroup.controls.CaptchaEntered.errors && signUpformGroup.controls.CaptchaEntered.touched}"
                                                        class="form-control" maxlength="5"
                                                        oninput="this.value=this.value.toUpperCase()">
                                                </div>
                                                <div class="invalid-feedback"
                                                    *ngIf="(signUpformGroup.get('CaptchaEntered')?.hasError('required') && signUpformGroup.get('CaptchaEntered')?.touched)">
                                                    {{this.translate.currentLang !='en'? 'कृपया '+('Captcha_txt'|translate)
                                                    +'
                                                    लिखें' :
                                                    'Please Enter '+ ('Captcha_txt'|translate)}} </div>
                                                <div class="captcha-regenerate col-md-6">
                                                    <button (click)="generateCaptcha();">Regenerate</button>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="form-apply-online-btn mt-4 text-center w-100">
                                            <button (click)="onGenerateOTP()" [disabled]="isButtonDisabled"
                                                class="btn blue-banner w-100 ">{{'GenerateOTP_txt' |
                                                translate}}</button>
                                                <!-- <a href="javascript:void(0)"
                                                (click)="onGenerateOTP()"
                                                [disabled]="isButtonDisabled"
                                                class="btn btn-default btn-g-1 captcha-btn">{{'GenerateOTP_txt' |
                                                translate}}</a> -->
                                        </div>
                                        <div class="crl"></div>
                                    </div>
                                </div>
                                <div class="crl"></div>
                            </form>
                            <div class="crl"></div>
                        </ng-container>
                        <div class="crl"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>

<!-- Modal Button -->
<button type="button" id="modalBtn" class="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#OTPReg">
    Launch demo modal
  </button>
  
<!-- OTP form start -->
<div class="modal fade modal-dsign-sm modal-new-theme-design modal-send-otp"  id="OTPReg"  tabindex="-1"
    role="dialog" aria-labelledby="OTP-Reg" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{'Registration_txt'|translate}}</h5>
                <!-- <button type="button" id="closeBtn" class="close" data-bs-dismiss="modal" aria-label="Close"> <span
                    aria-hidden="true">&times;</span> </button> -->

                <button type="button" id="closeBtn" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="modal-send-otp-img text-center">
                    <img src="assets/images/mobile-otp.png" alt="Rajasthan State portal" />
                </div>
                <h5 class="text-center my-3">{{signUpModel.Name}} <strong>{{signUpModel.Mobile}}</strong> </h5>
                <div class="form-group otp-control d-flex position-relative mb-3 flex-wrap">
                    <input type="text" placeholder="{{'EnterOTP_txt' | translate}}" class="form-control"
                        [formControl]="OTPControl" [ngClass]="{ 'is-invalid': OTPControl.errors && OTPControl.touched}"
                         (keypress)="numberOnly($event)">

                    <div class="invalid-feedback" *ngIf="OTPControl?.hasError('required') && OTPControl.touched">
                        {{translate.currlang !='en'? 'कृपया '+('OTP_txt'|translate) +' लिखें' : 'Please Enter '+('OTP_txt'|translate)}} </div>
                    
                    <div class="invalid-feedback" *ngIf="!FormGroup.get('OTPControl')?.hasError('required') && ((FormGroup.get('OTPControl')?.hasError('maxlength') || FormGroup.get('OTPControl')?.hasError('minlength')) && FormGroup.get('OTPControl')?.touched) || (FormGroup.get('OTPControl')?.hasError('maxlength') || FormGroup.get('OTPControl')?.hasError('minlength'))">
                        {{translate.currlang !='en'? 'कृपया '+('OTP_txt'|translate) +' लिखें' : 'Please Enter '+ ('OTP_txt'|translate)}} </div>
                </div>

                <div class="crl"></div>
                <div class="form-apply-online-btn col-md-12 border-0">
                    <button (click)="onVerifyOTP()" class="btn btn-success w-100 mb-1">
                        <i class="fa fa-check mr-1"></i> {{'VerifyOTP_txt' |
                        translate}}</button>

                    <button type="button" (click)="onResendOTP()" class="btn btn-default w-100 mt-1"
                        [disabled]="isResendOTP">
                        <i class="fa fa-undo mr-1"></i>
                        {{'ResendOTP_txt' |translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- OTP form end -->

<app-loader></app-loader>