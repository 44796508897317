import { MediaService } from './../services/media.services';
import { Component, Inject, OnInit, AfterContentChecked } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '../translate.service';

@Component({
  selector: 'app-messagelist',
  templateUrl: './messageList.componemt.html',
  providers: [MediaService]
})

export class MessageListComponent implements OnInit, AfterContentChecked {

  list: MessageListModel[];
  isEnglish: boolean;
  constructor(@Inject('BASE_URL') public baseUrl: string, private translate: TranslateService,
    private mediaService: MediaService) {
    this.list = [];

  }
  ngOnInit() {
    this.getdata();
  }

  ngAfterContentChecked() {
    this.isEnglish = this.translate.currlang === 'en' ? true : false;

  }

  public getdata() {
    this.mediaService.GetMessageList().subscribe(success => {
      if (success !== undefined && success !== null) {
        this.list = success;
      }
    }, error => {
      console.error(error);
    });
  }

}
class MessageListModel {

  ProgramId: number;
  ProgramDate: string;
  ProgramAddress: string;
  ProgramAddressHindi: string;
  ProgramSubject: string;
  ProgramSubjectHindi: string;
  Pdf: string;
  PdfEnglish: string;
}
