<!-- <nav class="breadcrumb_nav" aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/home">{{'Nav_Home'|translate}}</a></li>
        <li class="breadcrumb-item active" aria-current="page">Page Not Found</li>
    </ol>
</nav> -->


<section class="inner-page pb-5 pt-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="page-not-found text-center">
                    <div class="page-not-found-img mb-4"> <img src="assets/images/page-not-found.png" alt="CMO"></div>
                    <h1>OPPS!</h1>
                    <p>The page you are looking for was not found.</p>
                    <!-- <a href="#" class="btn btn-round btn-theme btn-yellow mt-3" style="color: #2630b9 !important">Go Back</a> -->
                </div>
            </div>
        </div>
    </div>
</section>