<section id="about" class="wow fadeInUp cmo-message">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 content">
        <div class="d-flex">
          <div class="cmo-img align-self-center"><img class="img-fluid" src="assets/img/cmo_message.png" alt=""></div>
          <div class="msg_text align-self-center" *ngFor="let item of cmoMeassages; let i= index">

            <h3>"{{this.translate.currlang == 'hi' ? item.MessageHindi:item.Message}}"</h3>
            <!--<h3>"So, this is a call to make Rajasthan Smart, Sustainable & Progressive & contribution of every single person counts in our effort for a Developed Rajasthan. "</h3>-->
            <div class="name">Bhajanlal Sharma, <span>Chief Minister of Rajasthan</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>