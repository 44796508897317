import { Component, OnInit, Inject } from '@angular/core';
import { ContactService } from '../services/contact.service';
import { TranslateService } from '../translate.service';
import { environment } from '../../environments/environment';
import { AddressType } from "../Enum/app-enums";


@Component({
  selector: 'app-home-contact',
  templateUrl: './home-contact.component.html',
  styleUrls: ['./home-contact.component.css'],
  providers: [ContactService]
})
export class HomeContactComponent implements OnInit {
  contactList: ContactModel[];
  public AddressTypeEnum = AddressType;
  public AddressTypeEmail = AddressType.Email;
  public AddressTypeTelephone = AddressType.Telephone;
  public AddressTypeOffice = AddressType.Office;
  constructor(@Inject('BASE_URL') public baseUrl: string,
    private translate: TranslateService, private contactService: ContactService) {
    this.contactList = [];
  }

  setLang(lang: string) {
    this.translate.use(lang);
  }

  ngOnInit() {
    //this.getContacts();
  }

  getContacts() {
    this.contactService.GetContactForFront().subscribe(responce => {
      
      if (responce != undefined) {
        this.contactList = <ContactModel[]>responce;
      }
    });
  }

}

export class ContactModel {
  Id: number;
  IconClass: string;
  Title: string;
  TitleHindi: string;
  Description: string;
  DescriptionHindi: string;
  AdressType: string;
  IsActive: string;
  CreateDate: Date;
  ModifyDate: Date
}
