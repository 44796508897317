import { HttpClient,HttpHeaders,HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './baseservice';
import { AppSettings } from '../common/AppSettings';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PhoneBoothService {

  constructor(private httpService: BaseService,private http: HttpClient) {}

  uploadFile(file: File): Observable<any> {

    const formData = new FormData();
    formData.append('file', file);

    const headers = new HttpHeaders({ 'enctype': 'multipart/form-data' });

    return this.http.post(`${AppSettings.API_ENDPOINT_PHONE_BOOTH}${AppSettings.API_PHONEBOOTH_ADD_FACE}`, formData, { headers: headers });

  }
  
  getEvents(){
    return this.httpService.get(`${AppSettings.API_ENDPOINT_PHONE_BOOTH}${AppSettings.API_PHONEBOOTH_LIST_EVETS}`);
  }

  getImagesByEvent(eventName: string){
    return this.httpService.get(`${AppSettings.API_ENDPOINT_PHONE_BOOTH}${AppSettings.API_PHONEBOOTH_IMAGES_BY_EVENTS}${eventName}`);
  }

  addFace(model:any){
    const headers = new HttpHeaders({ 'enctype': 'multipart/form-data' });

    return this.http.post(`${AppSettings.API_ENDPOINT_PHONE_BOOTH}${AppSettings.API_PHONEBOOTH_ADD_FACE}`,model, { headers: headers });
  }

  searchFace(model:any){
    const headers = new HttpHeaders({ 'enctype': 'multipart/form-data' });

    return this.http.post(`${AppSettings.API_ENDPOINT_PHONE_BOOTH}${AppSettings.API_PHONEBOOTH_SEARCH_FACE}`,model, { headers: headers });
  }

  deleteEvents(eventName: string){
    return this.httpService.get(`${AppSettings.API_ENDPOINT_PHONE_BOOTH}${AppSettings.API_PHONEBOOTH_DELETE_EVENT}${eventName}`);
  }
}
