<div class="breadcrumbs-page-img">
    <div class="banner-hight-2">
        <h1>{{'Home_OUR_SPEECH'| translate}}</h1>
        <div class="inner-page-banner">
            <img src="assets/images/cmo-banner.png" alt="banner" class="cmo-banner">
        </div>
    </div>
</div>
<main id="main">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0)">{{'Media_CornerSubNav'| translate}}</a></li>
            <li class="breadcrumb-item active" aria-current="page">Profile</li>
        </ol>
    </nav>

    <!-- Old Governor -->
    <!-- <section class="profile-block">
        <div class="container">
            <div class="profile-block1" style="  background: url(assets/images/banner-2.png) no-repeat; background-size: 100% 100%; border-radius: 15px; margin: 60px 15px; padding: 40px;">
                <div class="row ">
                    <div class="col-md-6 profile-first" style=" border-right: 1px solid #AEAEAE;">
                        <img src=" assets/images/governor.png" alt="banner" class="cmo-governor" style="  width: 200px; float: left; margin-right: 20px; border-radius: 15px; border: 5px solid #fff;">
                        <div class="profile-in" style="  float: left; padding: 15px 0px;">
                            <h2 style="  padding-bottom: 0px; font-weight: 600; margin-bottom: 10px; font-size: 30px;">Shri Kalraj Mishra</h2>
                            <p style="  padding-bottom: 0px; margin-bottom: 0px; line-height: 30px; font-size: 18px;">
                                01st July, 1941<br>
                                Home Town: Lucknow, Uttar Pradesh.<br>
                                Educational Qualification: MA <br>
                                www.kalrajmishra.com
                            </p>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="profile-in-2" style="  padding: 15px 0px; padding-left: 70px; font-size: 16px;">
                            <h3 style="font-size: 26px; font-weight: 600;">Contacts Details</h3>
                            <p>
                                <strong> GOVERNOR’S SECRETARIAT, RAJ BHAWAN JAIPUR EPABX</strong>
                                <br><br>
                                0141-2228716-19, 2228722, 2228611, 2228612
                                FAX No<br>
                                0141-2221156<br>
                                <br><br>
                                Social Media Twitter: @rajbhavanjaipur<br>
                                Facebook: @RajBhavanJaipur
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row gov-profile" style="margin: 0px; padding: 0px;">
                <div class="col-md-12">
                    <h3 style="font-weight:600; font-size: 30px; ">Personal life </h3>
                    <p>Shri Mishra was born on 1 July 1941 in a middle-class family at Malikpur, Ghazipur, Uttar
                        Pradesh. His father, Shri. Ramagya Mishra, was a teacher. Shri. Mishra obtained his M.A. degree
                        from Mahatma Gandhi Kashi Vidyapith, Varanasi. Shri. Kalraj Misha is married to Satyawati Mishra
                        and they have two sons and a daughter.
                    </p>
                </div>
                <div class="col-md-6">
                    <div class="gov-1"
                        style="background: #f8f8f8; padding:25px; border-radius: 15px; margin-bottom: 20px;line-height: 25px;">
                        <h4 style="font-weight:600; font-size:24px; padding-bottom:10px; margin-bottom: 0px;"> Executive
                            & Legislative Positions </h4>
                        Governor of Himachal Pradesh 22 July-2019-08 September-2019<br>
                        Chairperson, Standing Committee on Defence.<br>
                        Chairperson, Joint Committee on Offices of Profit.<br>
                        Member, Standing Committee of Defence, September-2017-August-2018.<br>
                        Union Cabinet Minister of Micro, Small and Medium Enterprises (MSME).May-2014-
                        September-2017.<br>
                        Elected to 16th Lok Sabha-2014-2019.<br>
                        Member, Uttar Pradesh Legislative Assembly-2012-2014.<br>
                        Chairman, Committee on Subordinate Legislation (RS): 2010-2012.<br>
                        Panel of Vice-Chairmen, Rajya Sabha: 2008-2011.<br>
                        Cabinet Minister, Govt. of Uttar Pradesh: 1997-2000.<br>
                        Member of Uttar Pradesh Legislative Council: 1986-2001 (Three Terms).<br>
                        Member Rajya Sabha: 1978-1984,2001-2012 (Three Terms).
                        <h4
                            style="font-weight:600; font-size:24px; padding-bottom:0px; padding-top:20px; margin-bottom: 0px;">
                            Other</h4>
                        <ul style=" list-style: auto; padding: 0px 15px; line-height: 25px;">
                            <li>Convener of Youth Organization "Samuprna Kranti" Movement.</li>
                            <li> National Convener, Yuva Sangathan Samanvaya Samiti-August 1979</li>
                            <li>Member, Indian Goodwill Delegation to Russia, North Korea, Japan, China and Hong
                                Kong-March 1979.</li>
                            <li> Member, Parliamentary Delegation to participate in the 59th Session of the U.N. General
                                Assembly-2004.</li>
                            <li>Visited Dallas (U.S.A.) as the Chief Guest at the International Hindi Convention.</li>
                            <li> Chairman, Legislative Harmony Committee, Uttar Pradesh Legislative Council.</li>
                            <li> Convener of a Sub-Committee on “Uttaranchal Nirman Samiti” of the Council of Ministers
                                (Government of Uttar Pradesh).</li>
                        </ul>
                        <h4
                            style="font-weight:600; font-size:24px; padding-bottom:0px; padding-top:20px; margin-bottom: 0px;">
                            At Present</h4>

                        <p> Assumed the office of the Governor of Rajasthan on 9th September, 2019.<br>
                            Chairperson of Rajya Sainik Kalyan Board, Rajasthan.<br>
                            Chairperson of Management Committee of Amalgamated Fund for the Benefit of Ex- Servicemen of
                            Rajasthan.<br>
                            Chairperson of West Zone Cultural Centre, Udaipur.<br>
                            Patron, Rajasthan State Bharat Scouts & Guides<br>
                            President, Indian Red Cross Society, Rajasthan .
                        </p>
                    </div>


                </div>
                <div class="col-md-6">
                    <div class="gov-1"
                        style="background: #f8f8f8; padding:25px; border-radius: 15px; margin-bottom: 20px;line-height: 25px;">
                        <h4 style="font-weight:600; font-size:24px; padding-bottom:10px; margin-bottom: 0px;"> Political
                            Assignments</h4>
                        <p> In 1963 became RSS Pracharak in Gorakhpur.<br>
                            Organising Secretary of Bhartiya Jana Sangh-Azamgarh Centre-1968<br>
                            Complete Charge of Political Workings in Azamgarh.<br>
                            In 1972, has contributed in Gujrat Legislative Assembly Elections.<br>
                            In 1977, became Election Coordinator of Eastern Uttar Pradesh.<br>
                            In 1979, elected as National President,All India Janta Yuva Morcha.<br>
                            In 1979, Convener, "National Youth Co-ordination Committee".<br>
                            In 1980-1991, General Secretary (Organization), BJP, Uttar Pradesh.<br>
                            In 1982-83, General Secretary, "Loktantrik Morcha".<br>
                            State President of BJP, Uttar Pradesh (1991, 1993, 1995, 2000).<br>
                            Has served as in-charge, B.J.P. of U.P., Delhi and Rajasthan from 2003 to 2004 and Bihar in
                            2007, Himachal Pradesh from 2010 -2012, Haryana from 2018-2019 and also was the All India
                            Vice
                            President of B.J.P.
                            Held the portfolios of Public Works, Medical Education and Tourism in Government of Uttar
                            Pradesh.<br>
                            As the Minister of Public Works and Tourism, Government of Uttar Pradesh, formulated the
                            Road
                            Development Policy in Uttar Pradesh. For the first time got the link roads constructed in
                            maximum number of villages under the “Deen Dayal Link Road Scheme” and set a record by
                            constructing more than 120 bridges. Also brought about road revolution by raising the road
                            levels, improving and widening the highways at a large scale and by carrying out
                            “Pothole-Free-Road” Campaign.
                            In the field of Tourism, Uttar Pradesh received the "Best Performing State in the Country"
                            award
                            for the first time from the Prime Minister.<br>
                            Played a major role in the formation of Uttarakhand.<br>
                            In year 2012, contested in Vidhan Sabha elections from Lucknow East assembly constituency
                            and
                            won the seat.<br>
                            Won the Parliament Elections 2014 from Deoria constituency and thereafter was appointed as a
                            Union Cabinet Minister.<br>
                            National President, All India Janta Yuva Morcha.<br>
                            Elected National Vice-President, Bharatiya Janata Party-16 April 2010

                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section> -->

    <!-- New Governor -->
    <section class="profile-block">
        <div class="container">
            <div class="profile-block1" style="  background: url(assets/images/banner-2.png) no-repeat; background-size: 100% 100%; border-radius: 15px; margin: 60px 15px; padding: 40px;">
                <div class="row ">
                    <div class="col-md-6 profile-first" style=" border-right: 1px solid #AEAEAE;">
                        <img src="assets/images/HaribhauKisanraoBagde.jpg" alt="banner" class="cmo-governor" style="  width: 200px; float: left; margin-right: 20px; border-radius: 15px; border: 5px solid #fff;">
                        <div class="profile-in" style="padding: 15px 0px;">
                            <h2 style="  padding-bottom: 0px; font-weight: 600; margin-bottom: 10px; font-size: 30px;">Haribhau Kisanrao <br><span>Bagade</span></h2>
                            <p style="  padding-bottom: 0px; margin-bottom: 0px; line-height: 30px; font-size: 18px;">
                                17 August 1945<br>
                                Home Town: Phulambri, Aurangabad district, Maharashtra.<br>
                            </p>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="profile-in-2" style="  padding: 15px 0px; padding-left: 70px; font-size: 16px;">
                            <h3 style="font-size: 26px; font-weight: 600;">Contacts Details</h3>
                            <p>
                                <strong> GOVERNOR’S SECRETARIAT, RAJ BHAWAN JAIPUR EPABX</strong>
                                <br><br>
                                0141-2228716-19, 2228722, 2228611, 2228612
                                FAX No<br>
                                0141-2221156<br>
                                <br><br>
                                Social Media Twitter: @rajbhavanjaipur<br>
                                Facebook: @RajBhavanJaipur
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row gov-profile" style="margin: 0px; padding: 0px;">
                <div class="col-md-6">
                    <div class="gov-1"
                        style="background: #f8f8f8; padding:25px; border-radius: 15px; margin-bottom: 20px;line-height: 25px;">
                        <h4 style="font-weight:600; font-size:24px; padding-bottom:10px; margin-bottom: 0px;"> Executive
                            & Legislative Positions </h4>
                        Governor of Rajasthan from 31st July, 2024<br>
                        <h4
                            style="font-weight:600; font-size:24px; padding-bottom:0px; padding-top:20px; margin-bottom: 0px;">
                            At Present</h4>

                        <p> Assumed the office of the Governor of Rajasthan on 31st July, 2024.<br>
                            Chairperson of Rajya Sainik Board, Rajasthan.<br>
                            Chairperson of Management Committee of Amalgamated Fund for the Benefit of Ex- Servicemen of
                            Rajasthan.<br>
                            Chairperson of West Zone Cultural Centre, Udaipur.<br>
                            Patron, Scouts & Guides, Rajasthan.<br>
                            President, Indian Red Cross Society, Rajasthan .
                        </p>
                    </div>


                </div>
                <div class="col-md-6">
                    <div class="gov-1"
                        style="background: #f8f8f8; padding:25px; border-radius: 15px; margin-bottom: 20px;line-height: 25px;">
                        <h4 style="font-weight:600; font-size:24px; padding-bottom:10px; margin-bottom: 0px;"> Chancellor of following Universities</h4>
                        <p> University of Rajasthan, Jaipur.<br>
                            Jai Narain Vyas University Jodhpur.<br>
                            Mohan Lal Sukhadia University, Udaipur.<br>
                            Maharshi Dayanand Saraswati University, Ajmer.<br>
                            University of Kota, Kota.<br>
                            Maharaja Ganga Singh University, Bikaner.<br>
                            Jagadguru Ramandacharya Rajasthan Sanskrit University, Jaipur.<br>
                            Vardhman Mahaveer Open University, Kota.<br>
                            Dr.Sarvapalli Radhakrishnn Rajasthan Ayurved University, Jodhpur.<br>
                            Rajasthan Technical University, Kota.<br>
                            Rajasthan University of Health Sciences, Jaipur.<br>
                            Swami Keshvanand Rajasthan Agriculture University, Bikaner.<br>
                            Maharana Pratap University of Agriculture & Technology,Udaipur.<br>
                            Rajasthan University of Veterinary and Animal Sciences, Bikaner.<br>
                            Sardar Patel University of Police, Security and Criminal Justice,Jodhpur.<br>
                            Govind Guru Tribal University, Banswara.<br>
                            Pt. Deendayal Upadhyay Shekhawati University, Sikar.<br>
                            Maharaja Surajmal Brij University, Bharatpur.<br>
                            Rajrishi Bharathari Matsya University, Alwar.<br>
                            Rajasthan Sports University, Jhunjhunu.<br>
                            SKN Agriculture University, Jobner.<br>
                            Agriculture University, Kota.<br>
                            Agriculture University, Jodhpur.<br>
                            The Rajasthan ILD Skills University, Jaipur.<br>
                            Bikaner Technical University, Bikaner.<br>
                            Haridev Joshi University of Journalism and Mass Communication, Jaipur.<br>
                            Dr. Bhimrao Ambedkar Law University, Jaipur.<br>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="profile-block-2">
        <div class="container">
            <div class="row">
                <div class="col-md-6 bg-first profile-first">
                    <div class="profile-block-in-2">
                        <img src=" assets/images/governor.png" alt="banner" class="cmo-governor">
                        <div class="profile-in">
                            <h2>Shri Kalraj Mishra</h2>
                            <p>
                                01st July, 1941<br>
                                Home Town: Lucknow, Uttar Pradesh.<br>
                                Educational Qualification: MA <br>
                                www.kalrajmishra.com<br>
                                <strong> Assembly Constituency:</strong> Sawai Madhopur
                            </p>
                        </div>
                        <div class="crl"></div>
                    </div>
                </div>
                <div class="col-md-6 bg-first profile-first">
                    <div class="profile-block-in-2">
                        <img src=" assets/images/governor.png" alt="banner" class="cmo-governor">
                        <div class="profile-in">
                            <h2>Shri Kalraj Mishra</h2>
                            <p>
                                01st July, 1941<br>
                                Home Town: Lucknow, Uttar Pradesh.<br>
                                Educational Qualification: MA <br>
                                www.kalrajmishra.com<br>
                                <strong> Assembly Constituency:</strong> Sawai Madhopur
                            </p>
                        </div>
                        <div class="crl"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="profile-block-2">
        <div class="container">
            <div class="row" style=" margin: 0px; padding: 0px;">
                <div class="col-md-12 bg-first profile-first" style="border-right: 0px solid #AEAEAE;">
                    <div class="profile-block-in-2" style="background: url(assets/images/banner-2.png) no-repeat;background-size: 100% 100%;border-radius: 15px;padding: 40px;margin-bottom: 30px;">
                        <img src="https://jankalyanfile.rajasthan.gov.in//Content/UploadFolder/DepartmentMaster/409/2024/Jan/30409/4094001559c-1561-4a80-a9fd-c450c56df0c3.jpg" alt="banner" class="cmo-governor" style="  width: 200px; float: left; margin-right: 20px; border-radius: 15px; border: 5px solid #fff;">
                        <div class="profile-in" style="  float: left; padding: 15px 0px;">
                            <h2 style="  padding-bottom: 0px; font-weight: 600; margin-bottom: 10px; font-size: 30px;">Shri Sudhansh Pant</h2>
                            <p style="  padding-bottom: 0px; margin-bottom: 0px; line-height: 30px; font-size: 18px;">
                                Date of Birth: 14/02/1967<br>
                                Home Town: LUCKNOW<br>
                                Educational Qualification: B.TECH. (HONS.)(ELECTRONICS & ELECTRICAL COMMUNICATION)
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 profile-table">
                    <table style=" font-family: arial, sans-serif;border-collapse: collapse;width: 100%;">
                        <tbody class="profile-tbody" style=" background: #000;color: #fff;border: 1px solid #d3d3d3;">
                            <tr>
                                <td align="center" rowspan="2" style="border: 1px solid #fff;text-align: left;padding: 8px;">SNO</td>
                                <td align="center" rowspan="2" style="border: 1px solid #fff;text-align: left;padding: 8px;">POST HELD BY OFFICER</td>
                                <td align="center" colspan="2" style="border: 1px solid #fff;text-align: left;padding: 8px;">Date</td>
                            </tr>
                            <tr class="HeaderStyle" valign="top">
                                <th scope="col" style="border: 1px solid #fff;text-align: left;padding: 8px;color: white !important; background: #000 !important; ">Order<br>Joining</th>
                                <th scope="col" style="border: 1px solid #fff;text-align: left;padding: 8px;color: white !important; background: #000 !important;">Relieving</th>
                            </tr>
                        </tbody>

                        <tr class="HeaderStyle1" valign="top"
                            style="padding: 2px 2px 2px 5px; border-left: 0px solid rgb(82, 82, 82); color: rgb(68, 56, 42);">
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center">1&nbsp;<b>.</b></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span id="gv_Shistory_lbl_m_0">CHIEF
                                    SECRETARY, RAJASTHAN, JAIPUR</span></td>
                            <td align="center" style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span
                                    id="gv_Shistory_lbl_mst1_0" class="di1">31/12/2023</span><br><span
                                    id="gv_Shistory_lbl_mst_0" class="di1">--/--/----</span></td>
                            <td align="center" style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span
                                    id="gv_Shistory_lbl_msterid_0" class="di1"></span></td>
                        </tr>
                        <tr class="HeaderStyle1" valign="top"
                            style="padding: 2px 2px 2px 5px; border-left: 0px solid rgb(82, 82, 82); color: rgb(68, 56, 42);">
                            <td align="center" style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; ">2&nbsp;<b>.</b></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span id="gv_Shistory_lbl_m_1">SECRETARY,
                                    DEPARTMENT OF HEALTH AND FAMILY WELFARE,
                                    MINISTRY OF HEALTH AND FAMILY WELFARE, NEW DELHI</span></td>
                            <td align="center" style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span
                                    id="gv_Shistory_lbl_mst1_1" class="di1">13/06/2023</span><br><span
                                    id="gv_Shistory_lbl_mst_1" class="di1">01/08/2023</span></td>
                            <td align="center" style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span
                                    id="gv_Shistory_lbl_msterid_1" class="di1"></span></td>
                        </tr>
                        <tr class="HeaderStyle1" valign="top">
                            <td align="center" style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; ">3&nbsp;<b>.</b></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span id="gv_Shistory_lbl_m_2">OFFICER
                                    ON SPECIAL DUTY, DEPARTMENT OF HEALTH AND FAMILY WELFARE, MINISTRY OF HEALTH AND
                                    FAMILY WELFARE, NEW DELHI</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_mst1_2"
                                    class="di1">13/06/2023</span><br><span id="gv_Shistory_lbl_mst_2"
                                    class="di1">13/06/2023</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_msterid_2"
                                    class="di1">31/07/2023</span></td>
                        </tr>
                        <tr class="HeaderStyle1" valign="top">
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center">4&nbsp;<b>.</b></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span id="gv_Shistory_lbl_m_3">SECRETARY, MINISTRY OF PORTS, SHIPPING AND
                                    WATERWAYS, GOVERNMENT OF INDIA, NEW DELHI</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span class="di1">19/10/2022</span><br><span
                                    id="gv_Shistory_lbl_mst_3" class="di1">01/01/2023</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_msterid_3"
                                    class="di1">13/06/2023</span></td>
                        </tr>
                        <tr class="HeaderStyle1" valign="top">
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center">5&nbsp;<b>.</b></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span id="gv_Shistory_lbl_m_4">OFFICER ON SPECIAL DUTY, MINISTRY OF PORTS,
                                    SHIPPING AND WATERWAYS, GOVERNMENT OF INDIA, NEW DELHI</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_mst1_4"
                                    class="di1">01/10/2022</span><br><span id="gv_Shistory_lbl_mst_4"
                                    class="di1">--/--/----</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_msterid_4"
                                    class="di1">31/12/2022</span></td>
                        </tr>
                        <tr class="HeaderStyle1" valign="top">
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center">6&nbsp;<b>.</b></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span id="gv_Shistory_lbl_m_5">DIRECTOR GENERAL, HCM, RIPA AND EX-OFFICIO
                                    ADDITIONAL CHIEF SECRETARY, TRAINING, RAJASTHAN, JAIPUR</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_mst1_5"
                                    class="di1">31/07/2022</span><br><span id="gv_Shistory_lbl_mst_5"
                                    class="di1">02/08/2022</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_msterid_5"
                                    class="di1">30/11/2022</span></td>
                        </tr>
                        <tr class="HeaderStyle1" valign="top">
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center">7&nbsp;<b>.</b></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span id="gv_Shistory_lbl_m_6">CHAIRMAN, RAJASTHAN STATE POLLUTION CONTROL
                                    BOARD, JAIPUR</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_mst1_6"
                                    class="di1">13/04/2022</span><br><span id="gv_Shistory_lbl_mst_6"
                                    class="di1">--/--/----</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_msterid_6" class="di1"></span>
                            </td>
                        </tr>
                        <tr class="HeaderStyle1" valign="top">
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center">8&nbsp;<b>.</b></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span id="gv_Shistory_lbl_m_7">ADDITIONAL CHIEF SECRETARY, PUBLIC HEALTH
                                    ENGINEERING AND GROUND WATER DEPARTMENT, RAJASTHAN, JAIPUR</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_mst1_7"
                                    class="di1">04/01/2021</span><br><span id="gv_Shistory_lbl_mst_7"
                                    class="di1">15/01/2021</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_msterid_7"
                                    class="di1">18/04/2022</span></td>
                        </tr>
                        <tr class="HeaderStyle1" valign="top">
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center">9&nbsp;<b>.</b></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span id="gv_Shistory_lbl_m_8">UNDER THE MINISTRY OF HEALTH AND FAMILY WELFARE,
                                    GOVERNMENT OF INDIA, NEW DELHI (TEMPORARY BASIS FOR 2 MONTH)</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_mst1_8" class="di1">27/03/2020</span><br><span
                                    id="gv_Shistory_lbl_mst_8" class="di1">27/03/2020</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_msterid_8" class="di1">14/01/2021</span>
                            </td>
                        </tr>
                        <tr valign="top">
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center">10&nbsp;<b>.</b></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span id="gv_Shistory_lbl_m_9">PRINCIPAL SECRETARY TO GOVERNMENT, FOREST &amp;
                                    ENVIRONMENT DEPARTMENT, RAJASTHAN, JAIPUR</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_mst1_9" class="di1">09/02/2020</span><br><span
                                    id="gv_Shistory_lbl_mst_9" class="di1">12/02/2020</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_msterid_9" class="di1">26/03/2020</span>
                            </td>
                        </tr>
                        <tr valign="top">
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center">11&nbsp;<b>.</b></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span id="gv_Shistory_lbl_m_10">APO</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_mst1_10" class="di1">27/12/2019</span><br><span
                                    id="gv_Shistory_lbl_mst_10" class="di1">27/12/2019</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_msterid_10" class="di1">11/02/2020</span>
                            </td>
                        </tr>
                        <tr valign="top">
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center">12&nbsp;<b>.</b></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span id="gv_Shistory_lbl_m_11">JOINT SECRETARY, DEPARTMENT OF HEALTH &amp; FAMILY
                                    WELFARE, GOI, NEW DELHI</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_mst1_11" class="di1">07/03/2018</span><br><span
                                    id="gv_Shistory_lbl_mst_11" class="di1">14/03/2018</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_msterid_11" class="di1">26/12/2019</span>
                            </td>
                        </tr>
                        <tr valign="top">
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center">13&nbsp;<b>.</b></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span id="gv_Shistory_lbl_m_12">JOINT SECRETARY, DEPARTMENT OF PHARMACEUTICALS, GOI,
                                    NEW DELHI</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_mst1_12" class="di1">23/09/2014</span><br><span
                                    id="gv_Shistory_lbl_mst_12" class="di1">27/09/2014</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_msterid_12" class="di1">13/03/2018</span>
                            </td>
                        </tr>
                        <tr valign="top">
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center">14&nbsp;<b>.</b></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span id="gv_Shistory_lbl_m_13">SECRETARY TO GOVERNMENT, LOCAL SELF GOVERNMENT,
                                    RAJASTHAN, JAIPUR</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_mst1_13" class="di1">06/06/2014</span><br><span
                                    id="gv_Shistory_lbl_mst_13" class="di1">07/06/2014</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_msterid_13" class="di1">27/09/2014</span>
                            </td>
                        </tr>
                        <tr valign="top">
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center">15&nbsp;<b>.</b></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span id="gv_Shistory_lbl_m_14">COMMISSIONER, AGRICULTURE DEPARTMENT, RAJASTHAN,
                                    JAIPUR</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_mst1_14" class="di1">04/01/2014</span><br><span
                                    id="gv_Shistory_lbl_mst_14" class="di1">08/01/2014</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_msterid_14" class="di1">07/06/2014</span>
                            </td>
                        </tr>
                        <tr valign="top">
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center">16&nbsp;<b>.</b></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span id="gv_Shistory_lbl_m_15">SECRETARY TO GOVERNMENT, MINES AND PETROLEUM
                                    DEPARTMENT, RAJASTHAN, JAIPUR</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_mst1_15" class="di1">29/02/2012</span><br><span
                                    id="gv_Shistory_lbl_mst_15" class="di1">01/03/2012</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_msterid_15" class="di1">06/01/2014</span>
                            </td>
                        </tr>
                        <tr valign="top">
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center">17&nbsp;<b>.</b></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span id="gv_Shistory_lbl_m_16">C.M.D., JAIPUR VIDHYUT VITRAN NIGAM LIMITED, JAIPUR
                                    &amp; CHAIRMAN, VIDHUYT VITRAN NIGAM LIMITED, AJMER, JODHPUR</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_mst1_16" class="di1">10/05/2011</span><br><span
                                    id="gv_Shistory_lbl_mst_16" class="di1">16/05/2011</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_msterid_16" class="di1">01/03/2012</span>
                            </td>
                        </tr>
                        <tr valign="top">
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center">18&nbsp;<b>.</b></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span id="gv_Shistory_lbl_m_17">COMMISSIONER, JAIPUR DEVELOPMENT AUTHORITY,
                                    JAIPUR</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_mst1_17" class="di1">13/06/2009</span><br><span
                                    id="gv_Shistory_lbl_mst_17" class="di1">15/06/2009</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_msterid_17" class="di1">15/05/2011</span>
                            </td>
                        </tr>
                        <tr valign="top">
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center">19&nbsp;<b>.</b></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span id="gv_Shistory_lbl_m_18">CHAIRMAN AND MANAGING DIRECTOR, RAJASTHAN RAJYA
                                    VIDHYUT PRASARAN NIGAM LIMITED, JAIPUR</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_mst1_18" class="di1">15/01/2009</span><br><span
                                    id="gv_Shistory_lbl_mst_18" class="di1">17/01/2009</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_msterid_18" class="di1">15/06/2009</span>
                            </td>
                        </tr>
                        <tr valign="top">
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center">20&nbsp;<b>.</b></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span id="gv_Shistory_lbl_m_19">REGISTRAR, COOPERATIVE SOCIETIES AND COMMISSIONER,
                                    MID-DAY MEAL, RAJASTHAN, JAIPUR (ADDL. CHARGE ADMINISTRATOR &amp; M.D.,
                                    RAJFED)</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_mst1_19" class="di1">05/04/2007</span><br><span
                                    id="gv_Shistory_lbl_mst_19" class="di1">07/04/2007</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_msterid_19" class="di1">19/01/2009</span>
                            </td>
                        </tr>
                        <tr valign="top">
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center">21&nbsp;<b>.</b></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span id="gv_Shistory_lbl_m_20">REGISTRAR, COOP. SOCIETIES &amp; EX-OFF. SPL. SECY.
                                    TO GOVT. AND ADMINISTRATOR &amp; M.D., RAJFED, JPR (ADDL. CHARGE COMMR., MID-DAY
                                    MEAL)</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_mst1_20" class="di1">20/10/2006</span><br><span
                                    id="gv_Shistory_lbl_mst_20" class="di1">26/10/2006</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_msterid_20" class="di1">06/04/2007</span>
                            </td>
                        </tr>
                        <tr valign="top">
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center">22&nbsp;<b>.</b></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span id="gv_Shistory_lbl_m_21">REGISTRAR, COOPERATIVE SOCIETIES &amp; EX-OFF.
                                    SPECIAL SECRETARY TO GOVT., RAJ., JAIPUR (ADDL. CHARGE COMMISSIONER, MID-DAY
                                    MEAL,RAJ.)</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_mst1_21" class="di1">--/--/----</span><br><span
                                    id="gv_Shistory_lbl_mst_21" class="di1">05/09/2006</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_msterid_21" class="di1">25/10/2006</span>
                            </td>
                        </tr>
                        <tr valign="top">
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center">23&nbsp;<b>.</b></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span id="gv_Shistory_lbl_m_22">REGISTRAR, COOPERATIVE SOCIETIES, RAJASTHAN, JAIPUR
                                    (ADDL.CHARGE COMMISSIONER, MID-DAY MEAL)</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_mst1_22" class="di1">16/12/2005</span><br><span
                                    id="gv_Shistory_lbl_mst_22" class="di1">17/12/2005</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_msterid_22" class="di1">04/09/2006</span>
                            </td>
                        </tr>
                        <tr valign="top">
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center">24&nbsp;<b>.</b></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span id="gv_Shistory_lbl_m_23">REGISTRAR, COOPERATIVE SOCIETIES, RAJASTHAN,
                                    JAIPUR</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_mst1_23" class="di1">23/06/2005</span><br><span
                                    id="gv_Shistory_lbl_mst_23" class="di1">25/06/2005</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_msterid_23" class="di1">16/12/2005</span>
                            </td>
                        </tr>
                        <tr valign="top">
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center">25&nbsp;<b>.</b></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span id="gv_Shistory_lbl_m_24">COLLECTOR &amp; DISTRICT MAGISTRATE, JAIPUR</span>
                            </td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_mst1_24" class="di1">08/08/2002</span><br><span
                                    id="gv_Shistory_lbl_mst_24" class="di1">10/08/2002</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_msterid_24" class="di1">25/06/2005</span>
                            </td>
                        </tr>
                        <tr valign="top">
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center">26&nbsp;<b>.</b></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span id="gv_Shistory_lbl_m_25">COLLECTOR &amp; DISTRICT MAGISTRATE, BHILWARA</span>
                            </td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_mst1_25" class="di1">14/08/2001</span><br><span
                                    id="gv_Shistory_lbl_mst_25" class="di1">15/08/2001</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_msterid_25" class="di1">10/08/2002</span>
                            </td>
                        </tr>
                        <tr valign="top">
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center">27&nbsp;<b>.</b></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span id="gv_Shistory_lbl_m_26">CHIEF EXECUTIVE &amp; DIRECTOR, RAJASTHAN ENERGY
                                    DEVELOPMENT AUTHORITY, JAIPUR, MANAGING DIRECTOR, R.U.D.A. &amp; M.D.,
                                    R.S.P.C.L., JAIPUR</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_mst1_26" class="di1">12/07/2000</span><br><span
                                    id="gv_Shistory_lbl_mst_26" class="di1">25/10/2000</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_msterid_26" class="di1">15/08/2001</span>
                            </td>
                        </tr>
                        <tr valign="top">
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center">28&nbsp;<b>.</b></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span id="gv_Shistory_lbl_m_27">CHIEF EXECUTIVE &amp; DIRECTOR, RAJASTHAN ENERGY
                                    DEVELOPMENT AUTHORITY, JAIPUR</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_mst1_27" class="di1">12/07/2000</span><br><span
                                    id="gv_Shistory_lbl_mst_27" class="di1">17/07/2000</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_msterid_27" class="di1">24/10/2000</span>
                            </td>
                        </tr>
                        <tr valign="top">
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center">29&nbsp;<b>.</b></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span id="gv_Shistory_lbl_m_28">COLLECTOR &amp; DISTRICT MAGISTRATE,
                                    JHUNJHUNU</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_mst1_28" class="di1">12/01/1999</span><br><span
                                    id="gv_Shistory_lbl_mst_28" class="di1">22/01/1999</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_msterid_28" class="di1">15/07/2000</span>
                            </td>
                        </tr>
                        <tr valign="top">
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center">30&nbsp;<b>.</b></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span id="gv_Shistory_lbl_m_29">COLLECTOR &amp; DISTRICT MAGISTRATE,
                                    JAISALMER</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_mst1_29" class="di1">15/04/1997</span><br><span
                                    id="gv_Shistory_lbl_mst_29" class="di1">23/04/1997</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_msterid_29" class="di1">20/01/1999</span>
                            </td>
                        </tr>
                        <tr valign="top">
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center">31&nbsp;<b>.</b></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span id="gv_Shistory_lbl_m_30">DEPUTY SECRETARY TO GOVERNMENT, DEPARTMENT OF
                                    PERSONNEL (A-1), RAJASTHAN, JAIPUR</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_mst1_30" class="di1">02/08/1996</span><br><span
                                    id="gv_Shistory_lbl_mst_30" class="di1">07/08/1996</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_msterid_30" class="di1">19/04/1997</span>
                            </td>
                        </tr>
                        <tr valign="top">
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center">32&nbsp;<b>.</b></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span id="gv_Shistory_lbl_m_31">ADDL. COLLECTOR (DEV.) &amp; EX-OFF PROJECT
                                    DIRECTOR, D.R.D.A., JAIPUR</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_mst1_31" class="di1">29/07/1995</span><br><span
                                    id="gv_Shistory_lbl_mst_31" class="di1">11/08/1995</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_msterid_31" class="di1">03/08/1996</span>
                            </td>
                        </tr>
                        <tr valign="top">
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center">33&nbsp;<b>.</b></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span id="gv_Shistory_lbl_m_32">SUB-DIVISIONAL OFFICER, MOUNT ABU (SIROHI)</span>
                            </td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_mst1_32" class="di1">--/--/----</span><br><span
                                    id="gv_Shistory_lbl_mst_32" class="di1">05/05/1994</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_msterid_32" class="di1">09/08/1995</span>
                            </td>
                        </tr>
                        <tr valign="top">
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center">34&nbsp;<b>.</b></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span id="gv_Shistory_lbl_m_33">SUB-DIVISIONAL OFFICER, JAIPUR</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_mst1_33" class="di1">--/--/----</span><br><span
                                    id="gv_Shistory_lbl_mst_33" class="di1">28/08/1993</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_msterid_33" class="di1">04/05/1994</span>
                            </td>
                        </tr>
                        <tr valign="top">
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center">35&nbsp;<b>.</b></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span id="gv_Shistory_lbl_m_34">UNDER DISTRICT TRAINING AT JODHPUR</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_mst1_34" class="di1">--/--/----</span><br><span
                                    id="gv_Shistory_lbl_mst_34" class="di1">01/07/1992</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_msterid_34" class="di1">30/05/1993</span>
                            </td>
                        </tr>
                        <tr valign="top">
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center">36&nbsp;<b>.</b></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; "><span id="gv_Shistory_lbl_m_35">UNDER TRAINING AT LBSNAA</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_mst1_35" class="di1">--/--/----</span><br><span
                                    id="gv_Shistory_lbl_mst_35" class="di1">15/09/1991</span></td>
                            <td style="border: 1px solid #d3d3d3;text-align: left;padding: 8px;padding-left: 2px; line-height: 18px; " align="center"><span id="gv_Shistory_lbl_msterid_35" class="di1">30/06/1992</span>
                            </td>
                        </tr>

                    </table>



                </div>

            </div>
        </div>
    </section>

    <section>
        <div class="container">
            <div class="row mt-5">
                <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div style="background-image: url(assets/images/banner-2.png); background-size: 100% 100%; border-radius: 15px; padding: 15px; ">
                        <div class="row g-5 text-lg-start text-center">
                            <div class="col-lg-auto">
                                <img src="assets/images/imga1.jfif" alt="banner" class="cmo-governor" style="  width: 150px; border-radius: 15px; border: 5px solid #fff;">
                            </div>
                            <div class="col-lg">
                                <div class="profile-in">
                                    <h2 style="margin: 0px;  padding-bottom: 0px; font-weight: 600; font-size: 30px;">Smt. Diya Kumari</h2>
                                    <p style="margin: 0px; font-weight: 700;">Deputy Chief Minister</p>
                                    <p style="  padding-bottom: 0px; margin-bottom: 0px; line-height: 18px; font-size: 15px;">
                                        Finance Department<br>
                                        Tourism Department<br>
                                        Department of Art<br>
                                        Literature<br>
                                        Culture and Archeology<br>
                                        PWD<br>
                                        Women & Child Development Department<br>
                                        Child Empowerment Department<br>
                                    </p>
                                    <p><span style="font-weight: 700;">Assembly Constituency</span> : Vidhyadhar Nagar</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 col-12 mt-lg-0 mt-3">
                    <div style="background-image: url(assets/images/banner-2.png); background-size: 100% 100%; border-radius: 15px; padding: 15px; height: 100%;">
                        <div class="row g-5 text-lg-start text-center">
                            <div class="col-lg-auto">
                                <img src="assets/images/Dr.-prem-CHand-Bairwa.jpg" alt="banner" class="cmo-governor" style="  width: 159px; border-radius: 15px; border: 5px solid #fff;">
                            </div>
                            <div class="col-lg">
                                <div class="profile-in">
                                    <h2 style="margin: 0px;  padding-bottom: 0px; font-weight: 600; font-size: 30px;">	Dr. Prem Chand Bairwa</h2>
                                    <p style="margin: 0px; font-weight: 700;">Deputy Chief Minister</p>
                                    <p style="  padding-bottom: 0px; margin-bottom: 0px; line-height: 18px; font-size: 15px;">
                                        Technical Education Department<br>
                                        Higher Education Department<br>
                                        Ayurveda<br>
                                        Yoga and Naturopathy<br>
                                        Unani<br>
                                        Siddha and Homeopathy (AYUSH) Department<br>
                                        Transport and Road Safety Department<br>
                                    </p>
                                    <p><span style="font-weight: 700;">Assembly Constituency</span> : Dudu (SC)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section>
        <div class="container">
            <div class="row mt-5 mb-5">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div style="background-image: url(assets/images/banner-2.png); background-size: 100% 100%; border-radius: 15px; padding: 15px; ">
                        <div class="row g-5 text-lg-start text-center">
                            <div class="col-lg-auto">
                                <img src="https://jankalyanfile.rajasthan.gov.in//Content/UploadFolder/DepartmentMaster/409/2024/Jun/30409/40947bd8a67-702a-4df3-b1cb-886f97aa2606.jpeg" alt="banner" class="cmo-governor" style="width: 357px; border-radius: 15px; border: 5px solid #fff;">
                            </div>
                            <div class="col-lg">
                                <div>
                                    <h2 style="margin: 0px; padding-bottom: 0px; font-weight: 600; font-size: 30px; ">Shri Bhajanlal Sharma</h2>
                                    <p style="margin: 0px; font-size: 20px; font-weight: 700;">Chief Minister</p>
                                    <p style="padding-bottom: 0px; margin-bottom: 0px; line-height: 30px; font-size: 18px;">
                                        <span style="font-weight: 600;">Date of Birth:</span> 15-Dec-1966<br>
                                        <span style="font-weight: 600;">Home Town:</span> Bharatpur<br>
                                        <span style="font-weight: 600;">Educational Qualification:</span> M.A.(Political Science)<br>
                                        <span style="font-weight: 600;">Father's Name:</span> Shri Kishan Swaroop Sharma<br>
                                        <span style="font-weight: 600;">Mother's Name:</span> Smt. Gomti Devi<br>
                                        <span style="font-weight: 600;">Marital Status:</span> Married<br>
                                        <span style="font-weight: 600;">Spouse Name:</span> Smt. Geeta Sharma<br>
                                        <span style="font-weight: 600;">Number of Children:</span> 2 sons<br>
                                        <span style="font-weight: 600;">Member Category:</span> General<br>
                                    </p>
                                    <p style="font-size: 18px;"><span style="font-weight: 700; font-size: 18px;">Assembly Constituency</span> : Sanganer</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>