import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";

import { FormBuilder, Validators } from "@angular/forms";
import { PhoneBoothService } from "../services/phone-booth.service";
import { TranslateService } from "../translate.service";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { HttpClient } from '@angular/common/http';

@Component({
  selector: "app-phone-booth",
  templateUrl: "./phone-booth.component.html",
  styleUrls: ["./phone-booth.component.css"],
})
export class PhoneBoothComponent implements OnInit {
  @ViewChild('video') videoElement!: ElementRef<HTMLVideoElement>;
  @ViewChild('canvas') canvasElement!: ElementRef<HTMLCanvasElement>;
  capturedImage: string | null = null;
  searchResult = false
  imageIndex:any;

  constructor(
    private fb: FormBuilder,
    private phoneBoothService: PhoneBoothService,
    public translate: TranslateService,
    public sanitizer: DomSanitizer,
    private http: HttpClient
  ) { }



  events: string[] = [];
  images: string[] = []; // Array to store image URLs
  selectedEvent: string | null = null;
  // List of allowed file types
  allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"];
  // Max file size in bytes (200MB)
  maxFileSize = 200 * 1024 * 1024;
  selectedFile: any;
  fileError = "";
  isfileValid = true;
  PDFFile: SafeResourceUrl;
  file: any = [];
  files: any = [];
  serachFaceFile: any;
  imageUrls: any = [];
  searchedImages: any = [];
  uploadForm = this.fb.group({
    uploadType: ["upload"],
    eventName: ["", Validators.required],
  });
  eventForm = this.fb.group({
    eventName: ["", Validators.required],
    images: [null],
  });

  ngOnInit(): void {
    this.getAllEvents();
    this.startCamera();
  }

  getAllEvents() {
    this.phoneBoothService.getEvents().subscribe(
      (data) => {
        this.events = data.events;
      },
      (error) => {
        console.error("Error fetching events:", error);
      }
    );
  }

  onEventSelect(eventName: string): void {
    this.selectedEvent = eventName;
    this.phoneBoothService.getImagesByEvent(eventName).subscribe(
      (data) => {
        this.images = data.matched_images;
        // Transform paths into URLs
        // const baseURL = 'http://localhost:8000/images/';
        // this.images = data.matched_images.map((path: string) => {
        //   const filename = path.split('\\').pop(); // Get the filename from the path
        //   return `${baseURL}${filename}`; // Construct URL with base URL
        // });
        // console.log(this.images,"this.imagesthis.images")
        // this.imageUrls = data.matched_images.map(path => {
        //   // Convert local paths to accessible URLs, for example:
        //   return path.replace("C:\\Users\\Admin\\uploaded_images", "/assets/images");
        // });
      },
      (error) => {
        console.error("Error fetching images:", error);
      }
    );
  }

  onFileSelect(event: any) {
    this.isfileValid = true;
    const fileInput = event.target as HTMLInputElement;
    const fileList: FileList = fileInput.files;
    Array.from(fileList).forEach((file: File) => {
      // Validate file type
      if (!this.allowedFileTypes.includes(file.type)) {
        this.fileError = `Invalid file type for ${file.name}. Allowed types: JPG, JPEG, PNG.\n`;
        this.isfileValid = false;
        return;
      }
      this.serachFaceFile = event.target.files[0];
      if (this.serachFaceFile) {
        console.log("this.serachFaceFile selected:", this.serachFaceFile);
      }
    });
  }

  useCamera() {
    console.log("Using camera to capture an image");
  }

  onSubmit2() {
    if (this.isfileValid == false) return
    console.log("Form submitted:", this.uploadForm.value);
    // Process form data here, e.g., upload to the server
    console.log(this.uploadForm.value);
    this.uploadForm.markAllAsTouched();
    if (this.uploadForm.valid) {
      const formData = new FormData();
      formData.append("event_name", this.uploadForm.value.eventName);

      if (this.capturedImage) {
        // Convert Base64 to Blob
        const blob = this.dataURItoBlob(this.capturedImage);
        const fileName = 'captured-image.png'; // Optional: Name the file
        formData.append("file", blob, fileName);
      } else if (this.serachFaceFile) {
        // If a file is selected via input
        formData.append("file", this.serachFaceFile);
      }

      this.phoneBoothService.searchFace(formData).subscribe((res: any) => {
        this.searchedImages = res.matched_images
        if (res.matched_images.length) this.searchResult = true
        console.log(res, "RES");
      });
    }
  }

  dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const byteArray = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      byteArray[i] = byteString.charCodeAt(i);
    }
    return new Blob([byteArray], { type: mimeString });
  }


  onFileSelected(event: any) {
    const fileInput = event.target as HTMLInputElement;
    const fileList: FileList = fileInput.files;

    if (fileList && fileList.length > 0) {
      // Clear previous data
      this.selectedFile = [];
      this.file = [];
      this.fileError = "";
      this.isfileValid = true;

      Array.from(fileList).forEach((file: File) => {
        // Validate file type
        if (!this.allowedFileTypes.includes(file.type)) {
          this.fileError += `Invalid file type for ${file.name}. Allowed types: JPG, JPEG, PNG.\n`;
          this.isfileValid = false;
          return;
        }

        // Validate file size
        if (file.size > this.maxFileSize) {
          this.fileError += `File size of ${file.name} exceeds the 5MB limit.\n`;
          this.isfileValid = false;
          return;
        }

        // Store valid file names
        this.selectedFile.push(file.name);

        // Read file as base64
        const reader = new FileReader();
        reader.onload = (loadEvent: any) => {
          let base64Data = loadEvent.target.result as string;

          // Remove the data URL prefix
          const base64Index = base64Data.indexOf("base64,") + "base64,".length;
          base64Data = base64Data.substring(base64Index);

          this.PDFFile = this.sanitizer.bypassSecurityTrustResourceUrl(
            loadEvent.target.result
          );
          this.file.push(base64Data);
        };
        reader.readAsDataURL(file);
      });
    }
  }


  startCamera() {
    navigator.mediaDevices.getUserMedia({ video: true })
      .then((stream) => {
        console.log('Camera stream:', stream);
        const video = this.videoElement.nativeElement;
        video.srcObject = stream;

        video.play().then(() => {
          console.log('Video is playing.');
        }).catch((err) => {
          console.error('Error playing video:', err);
        });

        // Monitor stream state
        const tracks = stream.getVideoTracks();
        tracks.forEach(track => console.log('Track settings:', track.getSettings()));
      })
      .catch((err) => {
        console.error('Error accessing the camera:', err);
        // alert('Error accessing the camera. Please check permissions and try again.');
      });
  }


  captureImage() {
    const video = this.videoElement.nativeElement;
    const canvas = this.canvasElement.nativeElement;
    const context = canvas.getContext('2d');

    if (context) {
      // Set canvas size to match the video element
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      // Draw video frame onto the canvas
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Convert canvas to image data
      this.capturedImage = canvas.toDataURL('image/png');
    }
  }

  fileName(image: any) {
    let img = image.replace(/\\/g, '/');
    //return 'http://10.70.236.10/face_detection/'+ img; //stazing
    return 'https://momentswithcm.rajasthan.gov.in/' + img; //live
  }

  downloadImages() {
    debugger
    this.searchedImages.forEach((url, index) => {
      //url = 'http://10.70.236.10/face_detection/'+ url.replace(/\\/g, '/'); //stazing
      url = 'https://momentswithcm.rajasthan.gov.in/' + url.replace(/\\/g, '/'); //live

      this.http.get(url, { responseType: 'blob' }).subscribe((blob) => {
        const imageName = url.substring(url.lastIndexOf('/') + 1);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = imageName; // You can customize the file name
        link.click();
        window.URL.revokeObjectURL(link.href);
      });
    });
  }

  downloadImage(imageUrl: string) {
    debugger
    //imageUrl = 'http://10.70.236.10/face_detection/'+ imageUrl.replace(/\\/g, '/'); //stazing
    imageUrl = 'https://momentswithcm.rajasthan.gov.in/' + imageUrl.replace(/\\/g, '/'); //live

    this.http.get(imageUrl, { responseType: 'blob' }).subscribe({
      next: (blob) => {
        if (blob.type.startsWith('image')) {
          const imageName = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = imageName; // Unique filename
          link.click();
          window.URL.revokeObjectURL(link.href);
          console.log('Download successful');
        } else {
          console.error('Blob received is not an image:', blob.type);
        }
      },
      error: (err) => {
        console.error('HTTP error during download:', err);
      },
    });
  }
}
