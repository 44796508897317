import { MediaService } from './../services/media.services';
import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { TranslateService } from '../translate.service';

@Component({
  selector: 'app-home-video',
  templateUrl: './home-video.component.html',
  styleUrls: ['./home-video.component.css'],
  providers: [MediaService]
})

export class HomeVideoComponent implements OnInit {
  vidPrograms: VideoModel[];
  display = 'none'; // default Variable
  Url: any;
  imageUrl: string = environment.imageUrl + 'Program/Video/VideoImages/App_';
  videoUrl: string = environment.imageUrl + 'Program/Video/';
  ngclass = 'mat-video-responsive';
  title = '';
  width = 600;
  height = 337.5;
  currentTime = 0;
  autoplay = false;
  preload = true;
  loop = false;
  quality = true;
  download = true;
  fullscreen = true;
  showFrameByFrame = false;
  keyboard = true;
  color = 'primary';
  spinner = 'spin';
  poster = '';
  overlay = null;
  muted = false;

  constructor(public http: HttpClient, @Inject('BASE_URL') public baseUrl: string,
  public translate: TranslateService, private mediaService: MediaService) {
    this.vidPrograms = [];
  }

  ngOnInit() {
    //this.getData();
  }
  setLang(lang: string) {
    this.translate.use(lang);
  }

  public getData() {
    this.mediaService.GetProgramVideoListForFront().subscribe(success => {
      if (success !== undefined && success !== null) {
        this.vidPrograms = success;
      }
    });
  }
  openModalDialog(d: string) {
    this.display = 'block';
    this.Url = d.replace('.flv', '.ogv');
    // Set block css
  }

  closeModalDialog() {
    // set none css after close dialog
    this.display = 'none';
  }

}

interface VideoModel {
  ProgramId: number;
  ProgramCategoryId: number;
  ProgramSubCategoryId: number;
  ProgramDate: string;
  ProgramDistrictId: number;
  ProgramLocationId: number;
  ProgramAddress: string;
  ProgramAddressHindi: string;
  ProgramLanguage: string;
  ProgramSubject: string;
  ProgramSubjectHindi: string;
  ProgramSpeech: string;
  ProgramSpeechHindi: string;
  Keywords: string;
  KeywordsHindi: string;
  SpeechPdf: string;
  Pdf: string;
  IsActive: boolean;
  Isdeleted: boolean;
  ProgramStateId: number;
  programTime: string;
  UserId: string;
  IsShowLatest: string;
  KeyImage: string;
  FolderName: string;
  PopupImages: string;
  PdfEnglish: string;
  Status: string;
  District: string;
  ProgramCategory: string;
  ProgramCategoryhindi: string;
  ProgramSubCategoryHindi: string;
  ProgramSubCategory: string;
  Video: string;
  VideoImage: string;
}

