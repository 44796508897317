<section class="cm-interact-blog">
  <div class="container">
    <div class="row">
      <!-- <div class="col-md-12">
        <h2 class="page-title">{{'Interact_with_CM_txt' | translate}}</h2>
        <div class="line"><img src="assets/images/line.png" alt="banner" class="line-m"> </div>
      </div>

      <div class="col-md-6 interact4">
        <div class="interact interact2"><img src="assets/images/cm-i1.png" alt="banner" style="cursor: pointer;"
            class="interact-m wow fadeInLeft" [routerLink]="['/suggestion-feedback']" routerLinkActive="router-link-active" ></div>
      </div>

      <div class="col-md-6 ">
        <div class="interact interact3">
          <a (click)="onOpen()" target="_blank" style="cursor: pointer;">
            <img src="assets/images/cm-i2.png" alt="banner"
              class="interact-m wow fadeInRight"> 
          </a>
          </div>
      </div> -->

      <div class="col-md-3"></div>
      <div class="col-md-9">

        <div class="interact-box">
          <div class="interact-b1"> <img src="assets/images/my-gov.png" alt="banner" class="interact-m2"></div>
          <div class="interact-2">
            <div class="interact-1">{{'Interact_CM' | translate}} </div>
            <div class="thoughts" [routerLink]="['/suggestion-feedback']" routerLinkActive="router-link-active"
              style="cursor: pointer;"> {{'Share_Idea_txt' | translate}},<br>
              {{'Insight_thoughts_txt' | translate}}
            </div>
          </div>


          <div class="interact-3">
            <a [routerLink]="['/writetocm']" routerLinkActive="router-link-active" style="cursor: pointer;">
              <img src="assets/images/icon3.png" alt="banner" class="int-m2"> {{'WriteToCm_txt' | translate}} </a>
          </div>
          <div class="crl"></div>
        </div>
        <div class="crl"></div>
      </div>


    </div>
  </div>
</section>

<div class="websitelinks">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h2 class="page-title">{{'Our_Government_txt' | translate}}</h2>
        <div class="line"><img src="assets/images/line.png" alt="banner" class="line-m"> </div>
      </div>
      <div class="col col-full" *ngFor="let item of importantLinks">
        <div class="page-footer-link-1">
          <div class="page-footer-link-2"><a target="_blank" [href]="safeUrl(item?.Url)">
              {{this.translate.currlang == 'hi' ? item?.SubjectHindi : item?.Achievement}}</a></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="subscriber">
  <div class="container">
    <div class="row">
      <div class="col-md-4 text-right mobile-center">
        <label>Subscribe to our newsletter</label>
      </div>
      <div class="col-md-8">
        <div class="row">
          <div class="col-md-7">
            <input type="text" name="Newsletter" placeholder="Enter your email address" class=" form-control">
          </div>
          <div class="col-md-3">
            <input type="submit" name="submit" value="Subscribe" class="subscribenow">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="footer-links links-2">
  <div class="container">
    <div class="row">
      <!-- <div class="col-md-12 link-1">
        <ul class="justify-content-center">
          <ng-container *ngFor="let item of externalLinks">
            <li *ngIf="(item && item?.AchievementHindi?.length > 0) || item?.DescriptionHindi?.length > 0">
              <a target="_blank" [href]="safeUrl(item?.Url)">
                {{this.translate.currlang == 'hi' ? item?.AchievementHindi : item?.DescriptionHindi}}
              </a>
            </li>
          </ng-container>
        </ul>
      </div> -->
      <div class="col-md-12 link-1">
        <div class="footer-ext-links">
          <a
            routerLink="/jankalyan-category-and-entry-type/0/{{janCatEnum.WebsitePolicyCode}}/{{jankalyanEntryTypeEnum.TermsConditionsCode}}">
            {{ "TermsCondition_txt" | translate }}
          </a>
          <a routerLink="/pages/sitemap">
            {{ "Sitemap_txt" | translate }}
          </a>
          <a
            routerLink="/jankalyan-category-and-entry-type/0/{{janCatEnum.GIGW_GuidelinesCode}}/{{jankalyanEntryTypeEnum.ScreenReaderAccessCode}}">
            {{ "ScreenReaderAccess_txt" | translate }}
          </a>
          <a
            routerLink="/jankalyan-category-and-entry-type/0/{{janCatEnum.GIGW_GuidelinesCode}}/{{jankalyanEntryTypeEnum.AccessibilityStatementCode}}">
            {{ "AccessibilityStatement_txt" | translate }}
          </a>
          <a routerLink="/pages/website-policies">
            {{ "WebsitePolicyt_txt" | translate }}
          </a>
        </div>
      </div>
      <div class="col-md-12 cm-address">
        <h4>{{'Banner_Chief_Minister_Office' | translate}}</h4>
        <div class="cmo-add-text"> {{'Address_cm' | translate}}</div>
      </div>


      <!-- OLD Links -->

      <!-- <div class="col-md-3">
        <h4> {{'State_GovernmentSubNav'| translate}}</h4>
        <ul>
          <li><a href="https://rajasthan.gov.in/pages/sm/department-page/142643/866" rel="noopener noreferrer"
              target="_blank" target="_blank">{{'NavGovernor'| translate}} </a></li>
          <li><a href="https://rajasthan.gov.in/pages/sm/department-page/142677/876" target="_blank"
              rel="noopener noreferrer">{{'Chief_Minister'| translate}} </a></li>
          <li><a href="https://rajasthan.gov.in/pages/sm/department-page/371534/2402" target="_blank"
              rel="noopener noreferrer">{{'Deputy_Chief_MinisterFooter'| translate}} </a></li>
          <li><a href="https://rajasthan.gov.in/pages/sm/assembly-constituency/354894/0/1" rel="noopener noreferrer"
              target="_blank">{{'Cabinet_MinistersSubNav'| translate}}</a></li>
          <li><a href="https://rajasthan.gov.in/pages/sm/assembly-constituency/372005/0/457" rel="noopener noreferrer"
              target="_blank">{{'State_MinistersIndependantChargeSubNav'| translate}}</a></li>
          <li><a href="https://rajasthan.gov.in/pages/sm/assembly-constituency/354895/0/2" rel="noopener noreferrer"
              target="_blank">{{'State_MinistersSubNav'| translate}}</a></li>
          <li><a href="https://rajasthan.gov.in/pages/sm/department-page/142678/877" rel="noopener noreferrer"
              target="_blank">{{'Chief_SecretarySubNav'| translate}}</a></li>

        </ul>
      </div>
      <div class="col-md-2">

        <h4> {{'Citizen_CornerNav'| translate}}</h4>
        <ul>
          <li><a rel="noopener noreferrer" target="_blank"
              href="http://cmrelief.rajasthan.gov.in/">{{'CM_Relief_FundSubNav'| translate}}</a></li>
          <li><a rel="noopener noreferrer" target="_blank"
              href="http://rti.rajasthan.gov.in/">{{'Right_To_InformationSubNav'| translate}}</a></li>
          <li><a rel="noopener noreferrer" target="_blank"
              href="http://sampark.rajasthan.gov.in/">{{'Rajasthan_SamparkSubNav'| translate}}</a></li>
          <li><a rel="noopener noreferrer" target="_blank"
              href="https://jankalyan.rajasthan.gov.in/">{{'Rajasthan_JankalyanSubNav'| translate}}</a></li>
        </ul>
      </div>
      <div class="col-md-2">

        <h4> {{'Media_CornerSubNav'| translate}}</h4>
        <ul>
          <li><a routerLink="/program-list">{{'ProgrammesSubNav'| translate}}</a></li>
          <li><a routerLink="/awards">{{'NavAwards'| translate}}</a></li>
          <li><a routerLink="/pressrelease-list">{{'Press_ReleasesSubNav'| translate}}</a></li>
          <li><a routerLink="/calendar">{{'CalendarSubNav'| translate}}</a></li>
          <li><a routerLink="/gallery-list">{{'PhotosSubNav'| translate}} </a></li>
          <li><a routerLink="/mega-event-list">{{'Mega_Event'| translate}}</a></li>
        </ul>
      </div> -->
      <!-- <div class="col-md-3 footer_social-block">
        <ul class="footer_social">
          <li><a href="{{dptModel.FacebookURL ? dptModel.FacebookURL : 'https://www.facebook.com/RajCMO/' }}"
              rel="noopener noreferrer" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i> </a></li>
          <li class="svg-img-icon"><a
              href="{{dptModel.TwitterURL ? dptModel.TwitterURL : 'https://twitter.com/RajCMO'}}"
              rel="noopener noreferrer" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
      <!-- <path
                  d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
              </svg>
            </a></li>

          <li><a href="{{dptModel.InstagramURL ? dptModel.InstagramURL : 'https://www.instagram.com/rajcmo/?hl=en' }}"
              target="_blank"> <i class="fa fa-instagram" aria-hidden="true"></i></a></li>
          <li><a href="{{dptModel.YoutubeURL ? dptModel.YoutubeURL : 'https://www.youtube.com/@cmoraj' }}"
              target="_blank"><i class="fa fa-youtube-play" aria-hidden="true"></i></a></li>

        </ul>
        <div *ngIf="footerFixIcons && footerFixIcons.length>0" class="footer-add-image">
          <ng-container *ngFor="let item of footerFixIcons">
            <a style="margin-right: 15px;" href="{{item.Url || item.YoutubeURL}}" target="_blank">
              <img src="{{item.ImagePath}}"
                alt="{{translate.currentLang=='hi' ? item?.AchievementHindi : item?.DescriptionHindi}}"
                title="{{translate.currentLang=='hi' ? item?.AchievementHindi : item?.DescriptionHindi}}" />
            </a>
          </ng-container>
        </div>
      </div> -->




    </div>
  </div>
</div>


<!--==========================
  Footer
============================-->

<footer id="footer">
  <div class="container">
    <div class="row">
      <div class="col-xl-12 col-lg-12 footer_visitor text-center">
        <div *ngIf="nodalOfficerDetail && (nodalOfficerDetail?.DetailsInHindi || nodalOfficerDetail?.Details)"
          class=" footer-nodal-officer">
          <strong class="mr-2">{{"NavNodal_officer" | translate}}
            :</strong>
          <p [innerHTML]="translate.currlang == 'hi' ? (nodalOfficerDetail?.DetailsInHindi ? nodalOfficerDetail?.DetailsInHindi : nodalOfficerDetail?.Details)
             : (nodalOfficerDetail?.Details? nodalOfficerDetail?.Details:nodalOfficerDetail?.DetailsInHindi )">
          </p>
        </div>

        {{'Visitor_count_txt'|translate}} : <!--<strong class="mr-3"></strong>-->
        <!-- Start of WebFreeCounter Code -->
        <!-- <img style="height: 15px;"
          src="https://counter5.optistats.ovh/private/freecounterstat.php?c=kzk6rjphn5ruhtc1nr6pxc5ddlbszjf5" border="0"
          alt="visitor counter"> | -->
        <!-- End of WebFreeCounter Code -->
         {{visitorCount}}
        {{"Last_update_txt"| translate }} :


        <strong *ngIf="(this.translate.currlang == 'en')">{{ getFormattedDate(this.dptModel.LastUpdateDate, 'en')
          }}</strong>
        <strong *ngIf="(this.translate.currlang == 'hi')">{{this.dptModel.LastUpdateDateHindi}}</strong>
      </div>
      <!--<div class="copyright col-xl-6">2019 &copy; Copyright <strong>Government of Rajasthan</strong>. All Rights Reserved </div>-->
    </div>
  </div>
</footer>