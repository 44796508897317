import { BaseService } from './../services/baseservice';
import { Dictionary } from './../common/dictionary';
import { Injectable } from '@angular/core';
import { AppSettings } from './../common/AppSettings';
import { Observable } from 'rxjs';
import { ProgramImageRequestModel } from '../Model/filter-program-model.model';
import { GalleryDetailRequestModel, ProgramGalleryListRequestModel } from '../Model/gallery-detail-model.model';
import { IndexModel } from '../Model/awards-request-model.model';
import { FrontUploaderDataRequestModel } from '../Model/photo-video-uploader.model';


@Injectable()
export class MediaService {
  public constructor(private httpService: BaseService) {

  }



  GalleryListLatest(model: ProgramGalleryListRequestModel) {

    return this.httpService.post(AppSettings.API_ENDPOINT + AppSettings.API_GALLERY_LIST_POST, model);
  }



  GalleryDetailsByIdLatest(modelRequest: GalleryDetailRequestModel) {

    return this.httpService.post(AppSettings.API_ENDPOINT + AppSettings.API_GALLERY_DETAILS_POST, modelRequest);
  }



  GetProgramImageListForFrontLatest(modelRequest: ProgramImageRequestModel) {

    return this.httpService.post(AppSettings.API_ENDPOINT + AppSettings.API_GALLERY_PROGRAM_IMAGE_LIST_POST, modelRequest);
  }

  GetProgramSpeechListForFront() {
    return this.httpService.get(AppSettings.API_ENDPOINT + AppSettings.API_SPEECH_LIST_GET);
  }
  GetProgramSpeechListForFrontTop2() {
    return this.httpService.get(AppSettings.API_ENDPOINT + AppSettings.API_SPEECH_LIST_TOP2_GET);
  }
  GetProgramVideoListForFront() {
    return this.httpService.get(AppSettings.API_ENDPOINT + AppSettings.API_VIDEO_LIST_GET);
  }
  GetProgramAllVideoListForFront(page: number) {
    const params = new Dictionary<string>();
    params.Add('page', page.toString());
    return this.httpService.get(AppSettings.API_ENDPOINT + AppSettings.API_GET_ALL_VIDEO_LIST, params);
  }
  GetProgramMessageListForFront(page: number, pageSize: number = 10, ExcludeProgramId: number = 0) {
    const params = new Dictionary<string>();
    params.Add('page', page.toString());
    params.Add('ExcludeProgramId', ExcludeProgramId.toString());
    params.Add('pageSize', pageSize.toString());

    return this.httpService.get(AppSettings.API_ENDPOINT + AppSettings.API_MESSAGE_LIST_FRONT_GET, params);
  }

  GetMessageList() {
    return this.httpService.get(AppSettings.API_ENDPOINT + AppSettings.API_MESSAGE_LIST_GET);
  }

  GetMessageDetail(id: string) {
    const params = new Dictionary<string>();
    params.Add('id', id);
    return this.httpService.get(AppSettings.API_ENDPOINT + AppSettings.API_MESSAGE_DETAILS_LIST_GET, params);
  }

  GetProgramspeechlistingForInnerPage(page: number) {
    const params = new Dictionary<string>();
    params.Add('page', page.toString());
    return this.httpService.get(AppSettings.API_ENDPOINT + AppSettings.API_SPEECH_LISTING_GET, params);
  }

  GetSpeechProgramDetail(id: string) {
    const params = new Dictionary<string>();
    params.Add('id', id);
    return this.httpService.get(AppSettings.API_ENDPOINT + AppSettings.API_SPEECH_DETAILS_GET, params);
  }


  GetVideoListByProgramIdLatest(modelRequest: GalleryDetailRequestModel) {

    return this.httpService.post(AppSettings.API_ENDPOINT + AppSettings.API_GET_VIDEO_LIST_By_ProgramIdLatest, modelRequest);
  }

  GetPostersListDataFromJankalyan(model: IndexModel): any {
    return this.httpService.post(AppSettings.API_ENDPOINT_Jankalyan + AppSettings.jankalyanApi.Program.GetPostersListurl, model);
  }

  GetAcievementCatDetail(id: number): any {
    var param = new Dictionary<any>();
    param.Add('id', id);
    return this.httpService.post(AppSettings.API_ENDPOINT_Jankalyan + AppSettings.jankalyanApi.Program.GetAchievementCatDetailUrl, param);
  }

  GetAcievementSubCatDetail(id: number): any {
    var param = new Dictionary<any>();
    param.Add('id', id);
    return this.httpService.post(AppSettings.API_ENDPOINT_Jankalyan + AppSettings.jankalyanApi.Program.GetAchievementSubCatDetailUrl, param);
  }

  GetSubmenuDetail(id: number): any {
    const param = new Dictionary<any>();
    param.Add("id", id);
    return this.httpService.get(AppSettings.API_ENDPOINT_Jankalyan + AppSettings.jankalyanApi.Program.GetSubmenuDetailUrl, param);
  }

  GetUploaderDataForFront(model: FrontUploaderDataRequestModel): any {
    return this.httpService.post(AppSettings.API_ENDPOINT_Jankalyan + AppSettings.jankalyanApi.PhotoVideoUploader.GetUploaderDataForFront, model);
  }

}
