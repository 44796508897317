<div>

    <!-- <div [ngClass]="!isCodeOfConduct ? 'bg-scroll pos-rel breadcrumbs-page-img' : 'bg-scroll pos-rel'">
        <div class="container">
            <h1>{{'Nav_Quotes'| translate}}</h1>
        </div>
    </div> -->
    <div class="breadcrumbs-page-img">
        <div class="banner-hight-2">
          <h1>{{'Nav_Quotes'| translate}}</h1>
          <div class="inner-page-banner">
            <img src="assets/images/cmo-banner.png" alt="banner" class="cmo-banner">
          </div>
        </div>
      </div>
    <main id="main" style="background: #fff0ed;">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)">{{'Media_CornerSubNav'| translate}}</a></li>
                <li class="breadcrumb-item active" aria-current="page">{{'Nav_Quotes'| translate}}</li>
            </ol>
        </nav>

        <section class="program_list">
            <div class="container">

                <div class="row">
                    <!-- <div class="card-deck" style="width: 100%;"> -->
                        <div class="col-sm-6 col-lg-3 program_list_box" *ngFor="let item of quotesModel; let i = index">

                            <div class="card wow"
                                [ngClass]="[i%3 <1 ? 'fadeInLeft' : i%3 <2? 'fadeInUp':i%3 <3? 'fadeInRight':'fadeInLeft' ]">

                                <div class="program-img-bx ">

                                    <!--<a href="javascript:void(0)" data-bs-toggle="modal" [attr.data-bs-target]="'#Gal_'+ awardItem.AwardId">-->
                                    <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#quotes" (click)="image=item.ImagePath">
                                        <img src="{{item.ImagePath}}"
                                            onError="this.src='../../assets/images/program-no-img.png'"
                                            class="card-img-top" alt="">
                                    </a>

                                </div>

                                <div class="card-body">

                                    <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#quotes" (click)="image=item.ImagePath">
                                        <p class="card-text"
                                            *ngIf="(this.translate.currlang == 'hi' && item.AchievementHindi.length<=50 )||(this.translate.currlang != 'hi' && item.Achievement.length<=50)">

                                            {{this.translate.currlang == 'hi' ? item.AchievementHindi :
                                            item.Achievement}}

                                        </p>
                                        <p class="card-text"
                                            *ngIf="(this.translate.currlang == 'hi' && item.AchievementHindi.length>50 )||(this.translate.currlang != 'hi' && item.Achievement.length>50)">

                                            {{(this.translate.currlang == 'hi' ? item.AchievementHindi :
                                            item.Achievement)}}

                                        </p>
                                    </a>
                                    <!-- <a class="program_btn_more" routerLink="/quotesdetail/{{item.Id}}">{{'Read_More'|
                                        translate}} </a> -->
                                </div>


                                <div class="card-footer">
                                    <small class="text-muted"><i class="fa fa-calendar"></i> {{this.translate.currlang
                                        == 'hi' ?
                                        item.AchievementDateHindi : item.AchievementDate | date : 'd MMM,
                                        y'}}</small>
                                </div>
                            </div>
                        </div>
                    <!-- </div> -->

                    <nav aria-label="Page navigation example" class="w-100 text-center mt-4"
                        *ngIf="totalRecords > pageSize">

                        <ul class="pagination d-inline-flex" *ngIf="pager.pages && pager.pages.length">
                            <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                                <a class="page-link" (click)="getQuotesData(1)">{{'First'| translate}}</a>
                            </li>
                            <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                                <a class="page-link" (click)="getQuotesData(pager.currentPage - 1)">{{'Previous'|
                                    translate}}</a>
                            </li>
                            <li class="page-item" *ngFor="let page of pager.pages"
                                [ngClass]="{active:pager.currentPage === page}">
                                <a class="page-link" (click)="getQuotesData(page)">{{page}}</a>
                            </li>
                            <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                                <a class="page-link" (click)="getQuotesData(pager.currentPage + 1)">{{'Next'|
                                    translate}}</a>
                            </li>
                            <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                                <a class="page-link" (click)="getQuotesData(pager.totalPages)">{{'Last'|
                                    translate}}</a>
                            </li>
                        </ul>
                    </nav>
                    <div class="col-lg-12" *ngIf="quotesModel && quotesModel?.length == 0">
                        <h3 class=" text-center">{{'No_Record_Found'|translate}}</h3>
                    </div>
                </div>
            </div>

        </section>

        <!-- <section>
            <iframe width="100%" height="600" style="border: 0px;" [src]="rajAdvtSideBarUrl + '/' + 0 | safeUrl"></iframe>
        </section> -->

    </main>
</div>

<div class="modal fade" id="quotes" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel"></h5>
          <button type="button" class="close close-in" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="text-2">
            <img src="{{image}}" onError="this.src='../../assets/images/program-no-img.png'" class="card-img-top" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>