import { Injectable, Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeUrl'
})
export class SafeUrlPipeService {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url: string) {
    if (url)
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
      else{
        return '';
      }
  }
}
