<section id="contact" class="wow fadeInUp d-none">
  <div class="container">
    <div class="section-header">
      <h2>{{'Nav_Contact_Us'| translate}}</h2>

    </div>

    <div class="row contact-info">
      <!-- <div class="col-md-4" *ngFor="let item of contactList; let i= index">
        <div class="contact-address" *ngIf="item.AdressType== AddressTypeEnum[0]">
          <i class="ion-ios-location-outline"></i>
          <h3>{{this.translate.currlang == 'hi' ? item.TitleHindi:item.Title}}</h3>
          <address>
            {{this.translate.currlang == 'hi' ? item.DescriptionHindi:item.Description}}

          </address>
        </div>
        <div class="contact-phone" *ngIf="item.AdressType==AddressTypeEnum[1]">
          <i class="ion-ios-telephone-outline"></i>
          <h3>{{this.translate.currlang == 'hi' ? item.TitleHindi:item.Title}}</h3>
          <p>
            <a href="tel:{{this.translate.currlang == 'hi' ? item.DescriptionHindi:item.Description}}">
              {{this.translate.currlang == 'hi' ? item.DescriptionHindi:item.Description}}
            </a>
          </p>

        </div>

        <div class="contact-email" *ngIf="item.AdressType==AddressTypeEnum[2]">
          <i class="ion-ios-email-outline"></i>
          <h3>{{this.translate.currlang == 'hi' ? item.TitleHindi:item.Title}}</h3>
          <p><a href="mailto:{{this.translate.currlang == 'hi' ? item.DescriptionHindi:item.Description}}">{{this.translate.currlang == 'hi' ? item.DescriptionHindi:item.Description}}</a></p>

        </div>
      </div> -->

      <div class="col-md-4">
        <div class="contact-address">
          <i class="ion-ios-location-outline"></i>
          <h3>Address</h3>
          <address>
            Chief Minister Office , Secretariat, Jaipur-302005,
            Rajasthan, India
          </address>
        </div>
      </div>

      <div class="col-md-4">
        <div class="contact-phone">
          <i class="ion-ios-telephone-outline"></i>
          <h3>Telephone</h3>
          <p>
            <a>
              +91-141-2227656,2227647
            </a>
          </p>

        </div>
      </div>

      <div class="col-md-4">
        <div class="contact-email">
          <i class="ion-ios-email-outline"></i>
          <h3>Email</h3>
          <p>cmrajasthan@nic.in</p>

        </div>
      </div>


      <!--<div class="col-md-4">
        <div class="contact-phone">
          <i class="ion-ios-telephone-outline"></i>
          <h3>Contact Number</h3>
          <p>
            <a href="tel:+91-141-2921063">
              +91-141-2921063
            </a>
          </p>
        </div>
      </div>

      <div class="col-md-4">
        <div class="contact-email">
          <i class="ion-ios-email-outline"></i>
          <h3>Email</h3>
          <p><a href="mailto:info@example.com">info.cmo@rajasthan.gov.in</a></p>
        </div>
      </div>-->

    </div>
  </div>

</section>

<section class="wow fadeInUp social-engagements d-none">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="section-header">
          <h2 class="m-0 p-0">Social Engagements</h2>
        </div>
      </div>
      <div class="col-md-4 mb-3 mb-md-0">
        <a href="https://www.instagram.com/rajcmo" target="_blank" class="social-engagements-items">
          <img src="assets/img/page-insta.png" alt="">
        </a>
      </div>
      <div class="col-md-4 mb-3 mb-md-0">
        <a href="https://www.facebook.com/RajCMO/" target="_blank" class="social-engagements-items">
          <img src="assets/img/page-fb.png" alt="">
        </a>
      </div>
      <div class="col-md-4">
        <a href="https://twitter.com/rajcmo" target="_blank" class="social-engagements-items">
          <img src="assets/img/page-twitter.png" alt="">
        </a>
      </div>
    </div>
  </div>
</section>

<section class="new-contact-design d-none">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="section-header">
          <h2 class="m-0 p-0">{{'Nav_Contact_Us'| translate}}</h2>
        </div>
      </div>
      <div class="col-md-4 mb-3 mb-md-0">
        <div class="new-contact-items">
          <div class="new-contact-items-icon"><i class="ion-ios-location-outline"></i></div>
          <a target="_blank"
            href="https://www.google.com/maps/dir/26.7976704,75.8644736/Chief+Minister+Office+,+Secretariat,+Jaipur-/@26.8510732,75.7898835,13z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x396db40da50ad24f:0x9bc24ffec973ce4e!2m2!1d75.8011308!2d26.9046891?entry=ttu">
            Chief Minister Office , Secretariat, Jaipur-302005,
            <br /> Rajasthan, India</a>
        </div>
      </div>
      <div class="col-md-4 mb-3 mb-md-0">
        <div class="new-contact-items">
          <div class="new-contact-items-icon"><i class="ion-ios-telephone-outline"></i></div>
          <a href="tel:+91-141-2227656">+91-141-2227656</a><br />
          <a href="tel:+91-141-2227647">+91-141-2227647</a>
        </div>
      </div>
      <div class="col-md-4">
        <div class="new-contact-items">
          <div class="new-contact-items-icon"><i class="ion-ios-email-outline"></i></div>
          <a href="mailto:cmrajasthan@nic.in">cmrajasthan@nic.in</a>
        </div>
      </div>
    </div>
  </div>
</section>