<section class="top-news">
  <div class="container">
    <div class="row">
      <div class="col-sm-6">
        <h2>To inspire humanity</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus odio diam</p>
      </div>
      <div class="col-sm-6">
        <h2>To inspire humanity</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus odio diam</p>
      </div>
    </div>
  </div>
</section>
