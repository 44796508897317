import { EventService } from './../services/events.services';
import { Component, OnInit, Inject } from '@angular/core';
import { TranslateService } from '../translate.service';
import { PagerService } from '../pager.service';
// import { DatepickerOptions } from 'ng2-datepicker';
import { environment } from '../../environments/environment';
import { FilterProgramRequestModel } from '../Model/filter-program-model.model';
import { InAugrationListModel, PressReleaseFrontSearchModel } from '../Model/press-release-model.model';
import { DepartmentCodeEnum, PressReleaseCategoryTypeEnum } from '../Enum/app-enums';
import { PressReleaseSubCategoryEnum, PressReleaseSubGeneralCatTypeEnum, VIPCategoryEnum, VIPPersonCategoryEnum } from '../Enum/jankayan.enum';
import { NewsService } from '../services/news.services';
import { DDLModel } from '../Model/common.model';
import { CommonService } from '../services/common.service';
import { AppSettings } from '../common/AppSettings';

@Component({
  selector: 'app-Search',
  templateUrl: './search.component.html',
  providers: [EventService, NewsService]
})
export class SearchComponent implements OnInit {
  public Messageprograms: ProgramModel[];
  pager: any = {};
  pagedMessageItems: InAugrationListModel[];
  totalPage: number;
  currentPage: number;
  inputData: SearchModel;
  filteredData: SearchModel;
  isShow = false;
  pageSize = 9;
  modelRequest: FilterProgramRequestModel;
  // options: DatepickerOptions = {
  //   minYear: 2018,
  //   maxYear: 2030,
  //   maxDate: new Date(Date.now()),
  //   minDate: environment.defaultDate,
  // }

  filterModel = new PressReleaseFrontSearchModel();
  generalModel: InAugrationListModel[];
  IsLoading = true;
  totalRecords = 0;
  categoryCode: number = 0;
  DepartmentCodeEnum: DepartmentCodeEnum;
  VIPCategoryEnum: VIPCategoryEnum;
  VIPPersonCategoryEnum: VIPPersonCategoryEnum;
  PressReleaseCategoryTypeEnum: PressReleaseCategoryTypeEnum;
  dDLList: DDLModel = new DDLModel();

  constructor(@Inject('BASE_URL')
  public baseUrl: string,
    private pagerService: PagerService,
    public translate: TranslateService,
    private eventService: EventService,
    private newsService: NewsService,
    private commonService: CommonService) {
    this.pagedMessageItems = [];
    this.currentPage = 1;
    this.inputData = new SearchModel();
    this.filteredData = new SearchModel();
    this.modelRequest = new FilterProgramRequestModel();
    this.Messageprograms = [];
    this.inputData.FromDate = environment.defaultDate;
    this.inputData.ToDate = new Date();
    this.filterModel.GroupType = "Event";
    this.filterModel.FromDate = new Date();
    this.filterModel.ToDate = new Date();
    this.filterModel.PressReleaseLevelCode = 0;
  }

  ngOnInit() {
    this.GetDDLList();
    this.GetPressReleaseData(this.currentPage);
  }

  filterData() {
    if (this.inputData.FromDate.getTime() <= this.inputData.ToDate.getTime()) {
      this.GetPressReleaseData(1);
    }
    else {
      alert('To Date hould be greater or equal to from date')
    }
  }

  setPage(page: number) {
    window.scroll(0, 0);
    this.currentPage = page;
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    // get pager object from service
    this.pager = this.pagerService.getPager(Number(this.generalModel[0].TotalRecords), page);
    // get current page of items
    this.pagedMessageItems = this.generalModel;
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  //Janklayan API
  GetPressReleaseData(page: number) {
    this.filterModel.Page = page;
    this.filterModel.IsBase64File = false;
    this.filterModel.CategoryCode = this.filterModel.GroupType == "All" ? 0 : PressReleaseCategoryTypeEnum.PressReleaseCode;
    this.filterModel.VIPCategoryCode = VIPCategoryEnum.HCMCode;
    // this.filterModel.VIPPersonCode = VIPPersonCategoryEnum.ShriAshokGehlotCode;
    this.filterModel.VIPPersonCode = VIPPersonCategoryEnum.ShriBhajanLalSharmaCode;
    this.filterModel.SubCat_GECatTypeCode = this.filterModel.GroupType == "Event" ? this.filterModel.SubCat_GECatTypeCode = PressReleaseSubGeneralCatTypeEnum.CMOProgramCode : 0;
    this.filterModel.SubCategoryCode = this.filterModel.GroupType == "Message" ? this.filterModel.SubCategoryCode = PressReleaseSubCategoryEnum.DayMessageCode : 0;
    this.filterModel.OrderBy = "PressreleaseDate";
    this.IsLoading = true;
    this.filterModel.DepartmentCode = DepartmentCodeEnum.CmoWebsiteCode;
    this.newsService.GetPressReleaseList(this.filterModel).subscribe((response) => {
      if (response.IsSuccess) {
        this.generalModel = response.Data.Data as InAugrationListModel[];
        this.generalModel.map((item) => {
          if (item.PressReleaseDateHindi) {
            item.PressReleaseDateHindi = item.PressReleaseDateHindi.split('-') as any
          }
        })
        this.totalRecords = response.Data.TotalRecords;
        if (this.totalRecords)
          this.setPage(page);
      }
      this.IsLoading = false;
    }, (error) => {
      console.error(error);
    });
  }

  GetDDLList() {
    this.commonService.GetAllDDL(AppSettings.PressReleaseDDLKey).subscribe((data) => {
      if (data.IsSuccess) {
        this.dDLList = data.Data as DDLModel;
      }
    }, (error) => {
      console.error(error.message);
    });
  }
}

class SearchModel {
  SearchText = '';
  FromDate: Date;
  ToDate: Date;
  GroupType = '';
}

export class ProgramModel {
  ProgramId: number;
  ProgramCategoryId: number;
  ProgramSubCategoryId: number;
  ProgramDate: string;
  ProgramDateHindi: string;
  ProgramDistrictId: number;
  ProgramLocationId: number;
  ProgramAddress: string;
  ProgramAddressHindi: string;
  ProgramLanguage: string;
  ProgramSubject: string;
  ProgramSubjectHindi: string;
  ProgramSpeech: string;
  ProgramSpeechHindi: string;
  Keywords: string;
  KeywordsHindi: string;
  SpeechPdf: string;
  Pdf: string;
  IsActive: boolean;
  IsDeleted: boolean;
  ProgramStateId: number;
  ProgramTime: string;
  UserId: string;
  IsShowLatest: string;
  KeyImage: string;
  FolderName: string;
  PopupImages: string;
  PdfEnglish: string;
  Status: string;
  Times: string;
  Type: string;
  ProgramSubCategory: string;
  ProgramSubCategoryhindi: string;
  FrontRedirectPageName: string;
  TotalMessages: number;

}

