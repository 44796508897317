import { NewsService } from './../services/news.services';
import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PagerService } from '../pager.service';
import { TranslateService } from '../translate.service';
import { IndexModel } from '../Model/awards-request-model.model';
import { DepartmentCodeEnum, PressReleaseCategoryTypeEnum } from '../Enum/app-enums';
import { VIPCategoryEnum, VIPPersonCategoryEnum } from '../Enum/jankayan.enum';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { PressReleaseFrontSearchModel } from '../Model/press-release-model.model';
import { CommonService } from '../services/common.service';
import { LookupEnum } from '../Enum/lookup-type.enum';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-recentnews-list',
  templateUrl: './recentnews-list.component.html',
  styleUrls: ['./recentnews-list.component.css'],
  providers: [NewsService,DatePipe]
})
export class RecentnewsListComponent implements OnInit {
  pager: any = {};
  pagedSpeechesItems: InAugrationListModel[];
  generalModel: InAugrationListModel[];
  filterModel = new PressReleaseFrontSearchModel();
  categoryCode: number = 0;
  IsLoading = true;
  totalRecords = 0;
  totalPage: number;
  currentPage: number;
  searchBox = '';
  pageSize = 9;
  today = new Date();
  fromDate:any;
  toDate:any;
  days = 10; // Days you want to subtract
  date = new Date();
  lastDate = new Date('2023-12-15') //new Date(this.date.getTime() - (this.days * 24 * 60 * 60 * 1000));
  searchText:any = '';
  // isCodeOfConduct: boolean = true;
  isCodeOfConduct: boolean = false;
  constructor(@Inject('BASE_URL') public baseUrl: string,
    private pagerService: PagerService,
    public translate: TranslateService,
    private newsService: NewsService,
    private _route: ActivatedRoute,
    private datePipe: DatePipe,
    public _commonService: CommonService) {
    this.isCodeOfConduct = this._commonService.GetIsCodeOfConduct();
    this.pagedSpeechesItems = [];
    this.currentPage = 1;
    this._route.paramMap.subscribe((params: ParamMap) => {

      let _dptCode = params.get("dptCode");
      if (_dptCode)
        this.filterModel.DepartmentCode = Number(_dptCode);

      let _catCode = params.get("CategoryCode");
      if (_catCode)
        this.filterModel.CategoryCode = Number(_catCode);

      let _subCatCode = params.get("SubCategoryCode");
      if (_subCatCode)
        this.filterModel.SubCategoryCode = Number(_subCatCode);
    });
  }

  ngOnInit() {
    this.fromDate = this.datePipe.transform(this.lastDate,'yyyy-MM-dd')
    this.toDate = this.datePipe.transform(this.today,'yyyy-MM-dd')
    this.GetPressReleaseData(this.currentPage,this.pageSize);
  }
  setLang(lang: string) {
    this.translate.use(lang);
  }

  onPageSize(evt:any){
    this.pageSize = evt.target.value
    this.GetPressReleaseData(this.currentPage,this.pageSize)
  }

  onSearch(){
    this.GetPressReleaseData(this.currentPage,this.pageSize)
  }

  GetPressReleaseData(page: number,pagesize:any) {
    // debugger;
    this.currentPage = page;
    this.filterModel.Page = page;
    this.filterModel.PageSize = pagesize;
    this.filterModel.PressReleaseToDate = this.toDate;
    this.filterModel.PressReleaseFromDate = this.fromDate;
    this.filterModel.Description = this.searchText;
    this.filterModel.IsBase64File = false;
    this.filterModel.OrderBy = "PressreleaseDate";
    this.filterModel.VIPCategoryCode = VIPCategoryEnum.HCMCode;
    this.filterModel.PressReleaseLevelCode = LookupEnum.PressReleaseLevelCode_For_State;
    if (this.filterModel.DepartmentCode == 0 && this.filterModel.CategoryCode == 0 && this.filterModel.SubCategoryCode == 0) {
      this.filterModel.CategoryCode = PressReleaseCategoryTypeEnum.PressReleaseCode;
      this.filterModel.VIPPersonCode = VIPPersonCategoryEnum.ShriBhajanLalSharmaCode;
      this.filterModel.DepartmentCode = DepartmentCodeEnum.CmoWebsiteCode;
    }
    this.IsLoading = true;
    this.newsService.GetPressReleaseList(this.filterModel).subscribe((response) => {
      if (response.IsSuccess) {
        this.IsLoading = response.Data.Data.length >= 0 ? false : true;
        this.generalModel = response.Data.Data as InAugrationListModel[];
        this.totalRecords = response.Data.TotalRecords;
        this.setPage(page,pagesize);
      }else{
        this.IsLoading = false;
      }
    }, (error) => {
      console.error(error);
    });
  }

  setPage(page: number,pagesize:any) {
    window.scroll(0, 0);
    this.currentPage = page;
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    // get pager object from service
    this.pager = this.pagerService.getPager(Number(this.generalModel[0].TotalRecords), page, pagesize);
    // get current page of items
    this.pagedSpeechesItems = this.generalModel;
  }
}

// export class PressReleaseFrontSearchModel extends IndexModel {
//   DepartmentCode: number;
//   DistrictDepartmentCode: number;
//   DistrictCode: number;
//   CategoryCode: number;
//   SubCategoryCode: number;
//   IsBase64File: boolean;
//   VIPCategoryCode: string;
//   VIPPersonCode: number;
//   EntryNumber: number;
//   ToDate: string | Date;
//   FromDate: string | Date;
//   PressReleaseFromDate: string;
//   PressReleaseToDate: string;
//   DeptValue: string[];
//   DistValue: string[];
//   IsLokarpanInauguration: boolean = false;

//   constructor() {
//     super();
//     this.DistrictDepartmentCode = 0;
//     this.PageSize = 9;
//     this.SubCategoryCode = 0;
//   }
// }
export class InAugrationListModel extends IndexModel {
  Id: number;
  Code: number;
  CategoryCode: number;
  SubCategoryCode: number;
  GeneralDescription: any;
  Description: string;
  URL: any;
  DisplayOrder: number;
  PDFUrl: string;
  ImageUrl: string;
  HomePageImageUrl: string;
  KeyWords: any;
  CreatedDate: Date | string;
  ModifiedDate: Date | string;
  CategoryName: string;
  CategoryNameHindi: string;
  SubCategoryName: string;
  SubCategoryNameHindi: string;
  PressReleaseLevel: string;
  PressReleaseLevelHindi: string;
  DepartmentTitle: string;
  DepartmentTitleHindi: string;
  DistrictNameHindi: string;
  DistrictName: string;
  VIPCategory: string;
  VIPCategoryHindi: string;
  VIPPersion: any;
  VIPPersionHindi: any;
  Attachments: any[];
  ImageAttachments: any[];
  TotalRecords: number;
  CommonCategoryCode: any;
  PressReleaseTime: any;
  PressreleaseDate: any;
  PressReleaseDateHindi: string;
}
