
<div class="bg-scroll pos-rel breadcrumbs-page-img">
  <div class="container">

    <h1>{{'State_MinistersFooter'| translate}}</h1>

  </div>
</div>
<main id="main">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
      <li class="breadcrumb-item"><a href="javascript:void(0)">{{'State_ProfileNav'| translate}} </a></li>
      <li class="breadcrumb-item active" aria-current="page">{{'State_MinistersFooter'| translate}}</li>
    </ol>
  </nav>
  <section class="program_list">
    <div class="container">
      <div class="row">
        <div class="table-responsive">
          <table class="table table_blue cabinet_table table-bordered" cellspacing="0" cellpadding="0">
            <thead>
              <tr>
                <th>{{'SNo'| translate}}</th>
                <th class="text-center">{{'Name_of_the_Minister'| translate}}</th>
                <th width="30%">{{'DepartName'| translate}}</th>
                <th>{{'RoomNo_Building'| translate}}</th>
                <th>{{'Minister_Address'| translate}}</th>
                <th width="13%">{{'Office'| translate}}</th>
                <th>{{'Residence'| translate}}</th>
                <th width="150">Vidhan Sabha (Room & PBX Ext. No.)</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let minister of stateMinisters">
                <td>{{minister.SNo}}<!--{{minister.CabinetId}}--></td>
                <td align="center">
                  <img src="{{this.imageUrl}}{{minister.ProfileImage}}" onError="this.src='../../assets/images/program-no-img.png'" height="140" width="120" alt="">
                  <!--<img src="assets/img/govindsingh.jpg" height="140" width="120">--><br>

                  <strong>{{this.translate.currlang == 'hi' ?minister.TitleHindi:minister.Title }} {{this.translate.currlang == 'hi' ?  minister.CabinetNameHindi :minister.CabinetName}}</strong>
                </td>
                <td>{{this.translate.currlang == 'hi' ? minister.CabinetDepartmentHindi : minister.CabinetDepartment}}</td>
                <td>
                  {{this.translate.currlang == 'hi' ? minister.CabinetRoomHindi : minister.CabinetRoom}}

                </td>
                <td> {{this.translate.currlang == 'hi' ? minister.CabinetAddressHindi : minister.CabinetAddress}}</td>
                <td>{{minister.PhoneOffice}}</td>
                <td>{{minister.PhoneResidence}}</td>
                <td>&nbsp;</td>
              </tr>
              <!--<tr>
                <td>2</td>
                <td align="center">
                  <img src="assets/img/mamtabhupesh.jpg" height="140" width="120"><br>
                  <strong>Smt. Mamta Bhupesh</strong>
                </td>
                <td>Women  and Child Development (Independent Charge), Public Grievances Redressal, Minority  Affairs, Waqf</td>
                <td>
                  6222<br>
                  Mantralaya Bhawan
                </td>
                <td></td>
                <td>0141-2227283 </td>
                <td></td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>3</td>
                <td align="center">
                  <img src="assets/img/arjunsingh.jpg" height="140" width="120"><br>
                  <strong>Sh. Arjun Singh Bamniya</strong>
                </td>
                <td>Tribal  Area (Independent Charge), Industries, State Enterprises</td>
                <td>
                  6306<br>
                  Mantralaya Bhawan
                </td>
                <td></td>
                <td>0141-2227069 </td>
                <td></td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>4</td>
                <td align="center">
                  <img src="assets/img/bhawarsinghbati.jpg" height="140" width="120"><br>
                  <strong>Sh. Bhanwar Singh Bhati</strong>
                </td>
                <td>Higher  Education (Independent Charge), Revenue, Colonization, Command Area Development  &amp; Water Utilization</td>
                <td>
                  6322<br>
                  Mantralaya Bhawan
                </td>
                <td></td>
                <td>0141-2227544 </td>
                <td></td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>5</td>
                <td align="center">
                  <img src="assets/img/sukhrambishnoi.jpg" height="140" width="120"><br>
                  <strong>Sh. Sukhram Vishnoi</strong>
                </td>
                <td>Forest  (Independent Charge), Environment (Independent Charge), Food &amp; Civil  Supplies, Consumer Affairs</td>
                <td>
                  6310<br>
                  Mantralaya Bhawan
                </td>
                <td></td>
                <td>&nbsp;</td>
                <td></td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>6</td>
                <td align="center">
                  <img src="assets/img/ashok.jpg" height="140" width="120"><br>
                  <strong>Sh. Bhajan Lal Sharma</strong>
                </td>
                <td>Youth  Affairs &amp; Sports Department (Independent Charge), Skill, Employment &amp;  Entrepreneurship (Independent Charge), Transport, Soldier Welfare</td>
                <td>
                  2205<br>
                  Main Building
                </td>
                <td><span class="style68"></span></td>
                <td>0141-2385733 </td>
                <td>0141-2385733</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>7</td>
                <td align="center">
                  <img src="assets/img/tikaramjuli.jpg" height="140" width="120"><br>
                  <strong>Sh. Tikaram Jully</strong>
                </td>
                <td>Labour  (Independent Charge), Factories &amp; Boilers Investigation (Independent  Charge), Co-operative, Indira Gandhi Nahar Pariyojana (IGNP)</td>
                <td>
                  6021<br>
                  Mantralaya Bhawan
                </td>
                <td></td>
                <td>0141-2227402 </td>
                <td></td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>8</td>
                <td align="center"><img src="assets/img/bhawarlaljatav.jpg" height="140" width="120"><strong>Sh. Bhajanlal Jatav</strong></td>
                <td>Home  Guards and Civil Defense (Independent Charge), Stamps &amp; Stationary  (Independent Charge), Agriculture, Animal Husbandry, Fisheries</td>
                <td>
                  6122<br>
                  Mantralaya Bhawan
                </td>
                <td></td>
                <td>0141-2227622 </td>
                <td></td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>9</td>
                <td align="center">
                  <img src="assets/img/rajendrasinghyadav.jpg" height="140" width="120"><br>
                  <strong>Sh. Rajendra Singh Yadav</strong>
                </td>
                <td>Planning  (Manpower) (Independent Charge), State Motor Garage (Independent Charge),  Language (Independent Charge), Social Justice &amp; Empowerment, Disaster  Management &amp; Relief</td>
                <td>
                  6103<br>
                  Mantralaya Bhawan
                </td>
                <td></td>
                <td>0141-2227073 </td>
                <td></td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>10</td>
                <td align="center">
                  <img src="assets/img/subhashgarg.jpg" height="140" width="120"><br>
                  <strong>Dr.Subhash Garg</strong>
                </td>
                <td>
                  Technical  Education (Independent Charge), Sanskrit Education Department (Independent  Charge), Medical &amp; Health, Ayurved and Indian Medical, Medical &amp; Health  Services (ESI), Information &amp; Public Relation
                </td>
                <td>
                  6316<br>
                  Mantralaya Bhawan
                </td>
                <td></td>
                <td>0141-2227925 </td>
                <td></td>
                <td>&nbsp;</td>
              </tr>-->
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</main>

