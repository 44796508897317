<div class="intro-page">
  <div class="intro-enter">
    <div class="ie-containt">
      <a routerLink="/home">
        <img src="assets/img/intro-enter.png" alt="Logo" class="img-fluid">
        <span>
          Enter The Website
        </span>
      </a>
    </div>

  </div>
  <div id="logo" class="pull-left">
    <a href="index.html" class="scrollto">
      <img src="../../assets/img/titlemain.png" alt="Logo" class="img-fluid">
    </a>
  </div>

  <div id="carouselExampleControls" class="carousel slide" data-ride="carousel" *ngIf="popupImageList.length > 0">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img class="d-block w-100" src="../../assets/img/intro-carousel/1.jpg" alt="First slide">
      </div>
      <div class="carousel-item">
        <img class="d-block w-100" src="../../assets/img/intro-carousel/2.jpg" alt="Second slide">
      </div>
      <div class="carousel-item">
        <img class="d-block w-100" src="../../assets/img/intro-carousel/3.jpg" alt="Third slide">
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>


  <div id="carouselExampleControls" class="carousel slide" data-ride="carousel" *ngIf="popupImageList.length == 0">
    <div class="carousel-inner">
      <div class="carousel-item" *ngFor="let item of popupImageList; let k = index" [class.active]="k==0">
        <img class="d-block w-100" src="{{imageUrlTemp + item.PopupImages }}">
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>

</div>
