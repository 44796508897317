import { BaseService } from './../services/baseservice';
import { Dictionary } from './../common/dictionary';
import { Injectable } from '@angular/core';
import { AppSettings } from './../common/AppSettings';
import { Observable } from 'rxjs';
import { RecentnewsRequestModel } from '../Model/recentnews-model.model';
import { PressReleaseFrontSearchModel } from '../Model/press-release-model.model';
import { CMProfileModel } from '../Model/cm-profile.model';
// import { PressReleaseFrontSearchModel } from '../recentnews-list/recentnews-list.component';


@Injectable()
export class NewsService {
  public constructor(private httpService: BaseService) {

  }


  GetProgramMessageDetailForInnerLatest(modelRequest:RecentnewsRequestModel) {  
    return this.httpService.post(AppSettings.API_ENDPOINT + AppSettings.API_NEWS_RECENT_NEWS_DETAILS_POST, modelRequest);
  }



  GetProgramNewsListForFront() {
    return this.httpService.get(AppSettings.API_ENDPOINT + AppSettings.API_NEWS_NEWS_FRONT_GET);
  }

  GetProgramNewsListForInner(page: number, text: string) {
    const params = new Dictionary<string>();
    params.Add('page', page.toString());
    params.Add('text', text);
    return this.httpService.get(AppSettings.API_ENDPOINT + AppSettings.API_NEWS_NEWS_GET, params);
  }

  GetPressReleaseList(model: PressReleaseFrontSearchModel) {
    return this.httpService.post(AppSettings.API_ENDPOINT_Jankalyan +  AppSettings.jankalyanApi.pressRelease.PressReleaseListUrl, model);
  }

  GetDIPRPressRelease(model: PressReleaseFrontSearchModel) {

    return this.httpService.post(AppSettings.API_ENDPOINT_Jankalyan +  AppSettings.jankalyanApi.pressRelease.DiprPressReleaseListUrl, model);
  }

  GetDIPRPressReleaseDetailById(id: number) {
    return this.httpService.get(AppSettings.API_ENDPOINT_Jankalyan +  AppSettings.jankalyanApi.pressRelease.DIPRPressReleaseDetailUrl + id);
  }

  GetCMProfile(model: CMProfileModel, excludeEntryType: number = 0, isBase64Files: boolean = false) {
    return this.httpService.post(AppSettings.API_ENDPOINT_Jankalyan + AppSettings.jankalyanApi.DepartmentProfile.GetDepartmentProfileListurl + "?excludeEntryType=" + excludeEntryType + "&isBase64Files=" + isBase64Files, model);
  }

  GetTagline(model: CMProfileModel, excludeEntryType: number = 0, isBase64Files: boolean = false) {
    return this.httpService.post(AppSettings.API_ENDPOINT_Jankalyan + AppSettings.jankalyanApi.DepartmentProfile.GetDepartmentProfileListurl + "?excludeEntryType=" + excludeEntryType + "&isBase64Files=" + isBase64Files, model);
  }

  GovFormerCM(model: CMProfileModel, isRequiredAttachImages: boolean = true) {
    return this.httpService.post(AppSettings.API_ENDPOINT_Jankalyan + AppSettings.jankalyanApi.FormerCM.getAchievementListBySearchFilter + "?isRequiredAttachImages=" + isRequiredAttachImages, model);
  }

  GetUpcomingCMEvents(model: CMProfileModel, isRequiredAttachImages: boolean = true) {
    return this.httpService.post(AppSettings.API_ENDPOINT_Jankalyan + AppSettings.jankalyanApi.UpcomingCMEvents.getAchievementListBySearchFilter + "?isRequiredAttachImages=" + isRequiredAttachImages, model);
  }

}
