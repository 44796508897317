import { Component, ChangeDetectionStrategy, ViewChild, TemplateRef, OnInit, Inject } from '@angular/core';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { Data } from './../services/data.services';
import { EventService } from './../services/events.services';
import { element } from 'protractor';
import { Router } from '@angular/router'
import { TranslateService } from '../translate.service';
import { InAugrationListModel, PressReleaseFrontSearchModel } from '../Model/press-release-model.model';
import { DepartmentCodeEnum, PressReleaseCategoryTypeEnum } from '../Enum/app-enums';
import { VIPCategoryEnum, VIPPersonCategoryEnum } from '../Enum/jankayan.enum';
import { NewsService } from '../services/news.services';
import { CommonService } from '../services/common.service';



const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA'
  }
};

@Component({
  selector: 'app-calender',
  templateUrl: './calender.component.html',
  styleUrls: ['./calender.component.css'],
  providers: [EventService,NewsService]
})
export class CalenderComponent implements OnInit {
  title = 'ng-bootstrap-modal-demo';
  closeResult: string;
  modalOptions: NgbModalOptions;
  events: CalendarEvent[];
  eventsHindi: CalendarEvent[];
  dayEvents: CalendarEvent[];
  SelectedMonth = '';
  SelectedYear = '';
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  date = '';
  currentPage = 1;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };
  refresh: Subject<any> = new Subject();


  activeDayIsOpen: boolean = false;



  filterModel = new PressReleaseFrontSearchModel();
  generalModel: InAugrationListModel[];z
  IsLoading = true;
  totalRecords = 0;
  categoryCode: number = 0;
  DepartmentCodeEnum: DepartmentCodeEnum;
  VIPCategoryEnum: VIPCategoryEnum;
  VIPPersonCategoryEnum: VIPPersonCategoryEnum;
  PressReleaseCategoryTypeEnum: PressReleaseCategoryTypeEnum;
  // isCodeOfConduct: boolean = true;
  isCodeOfConduct: boolean = false;

  constructor(@Inject('BASE_URL') public baseUrl: string, 
  private modalService: NgbModal, 
  private eventService: EventService, 
  private data: Data, 
  private router: Router, 
  public translate: TranslateService,
  private newsService: NewsService,
  public _commonService: CommonService) {
    this.isCodeOfConduct = this._commonService.GetIsCodeOfConduct();
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    }
    this.dayEvents = [];
    this.SelectedMonth = String(new Date().getMonth() + 1);
    this.SelectedYear = String(new Date().getFullYear());

  }

  ngOnInit() {

    if (this.data.storage !== undefined) {
      this.date = this.data.storage.date;
      this.data.storage = undefined;
    }

    this.getProgramData();
    //this.getData();
  }

  //setLang(lang: string) {
  //  alert(lang);
  //  this.translate.use(lang);
  //}


  public getData() {

    this.eventService.GetCalendarProgram().subscribe(result => {
      this.events = [];
      this.eventsHindi = [];
      var responce = <CalendarEvent[]>result;
      responce.forEach(element => {
        this.events.push(
          {
            id: element.id,
            title: element.title,
            titleHindi: element.titleHindi,
            start: new Date(element.start),
            end: new Date(element.end),
            color: element.color,
            allDay: true,
            resizable: {
              beforeStart: true,
              afterEnd: true
            },
            draggable: false
          });

        this.eventsHindi.push(
          {
            id: element.id,
            title: element.titleHindi,
            titleHindi: element.title,
            start: new Date(element.start),
            end: new Date(element.end),
            color: element.color,
            allDay: true,
            resizable: {
              beforeStart: true,
              afterEnd: true
            },
            draggable: false
          });

      });
      //this.programs = success;
    }, error => {
      console.error(error);
    });


  }

//Jankalyan API
  public getProgramData() {

      this.filterModel.IsBase64File = false;
      this.filterModel.CategoryCode = PressReleaseCategoryTypeEnum.PressReleaseCode;
      this.filterModel.VIPCategoryCode = VIPCategoryEnum.HCMCode;
      // this.filterModel.VIPPersonCode = VIPPersonCategoryEnum.ShriAshokGehlotCode;
      this.filterModel.VIPPersonCode = VIPPersonCategoryEnum.ShriBhajanLalSharmaCode;
      this.filterModel.SubCat_GECatTypeCode = 31183;
      this.filterModel.OrderBy = "PressreleaseDate";
      this.IsLoading = true;
      this.filterModel.DepartmentCode = DepartmentCodeEnum.CmoWebsiteCode;
  
      this.newsService.GetPressReleaseList(this.filterModel).subscribe(result => {
      this.events = [];
      this.eventsHindi = [];
      var responce = <InAugrationListModel[]>result.Data.Data;
      responce.forEach(element => {
        this.events.push(
          {
            id: element.Id,
            title: element.Description,
            titleHindi: element.Description,
            start: new Date(element.PressreleaseDate),
            end: new Date(element.PressreleaseDate),
            color: "yellow" as any,
            allDay: true,
            resizable: {
              beforeStart: true,
              afterEnd: true
            },
            draggable: false
          });

        this.eventsHindi.push(
          {
            id: element.Id,
            title: element.Description,
            titleHindi: element.Description,
            start: new Date(element.PressreleaseDate),
            end: new Date(element.PressreleaseDate),
            color: "yellow" as any,
            allDay: true,
            resizable: {
              beforeStart: true,
              afterEnd: true
            },
            draggable: false
          });

      });
      //this.programs = success;
    }, error => {
      console.error(error);
    });


  }


  searchByMonthYear() {

    this.viewDate = new Date(this.SelectedYear + '-' + this.SelectedMonth + '-' + "1");
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }, content): void {

    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;

    }
    //this.open(content)
    //this.dayEvents = events;
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map(iEvent => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.data.storage = this.modalData.event.start
    this.router.navigateByUrl('/Programdetail/' + event.id);
  //  window.location.href = '/Programdetail/' + event.id;


  }
  open(content) {
    //this.modalService.open(content, this.modalOptions).result.then((result) => {
    //  this.closeResult = `Closed with: ${result}`;
    //}, (reason) => {
    //  this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    //});
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }




  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    
    this.activeDayIsOpen = false;
  }
}
