import { PortalEnum } from "../../Enum/portal.enum";

export class RequestAddModel {
    senderName: string;
    organizationName: string;
    senderAddress: string;
    senderDistrictCode: number | string;
    senderPhoneNo: string;
    senderEmail: string;
    subject: string;
    attachmentPath: string;
    attachmentBasePath: string;
    invitationCategoryCode: number | string;
    programDistrictCode: number | string;
    programVenue: string;
    comments: string;
    requestDateFrom: Date | string;
    requestDateTo: Date | string;
    senderTypeCode: number | string;
    portalCode: number;
    statusId: number;

    constructor() {
        this.senderTypeCode = '';
        this.invitationCategoryCode = '';
        this.programDistrictCode = '';
        this.senderDistrictCode = '';
        this.attachmentPath = '';
        this.attachmentBasePath = '';
        this.portalCode = PortalEnum.CMOWebsite;
        this.statusId = 4;
    }

}
