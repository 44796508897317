export class FilterProgramRequestModel {
  page: number;
  text: string;
  toDate: string;
  fromDate: string;
  groupType: string;
}
export class ProgramImageRequestModel {
  isShowAll: boolean;

}
