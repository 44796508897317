import { Injectable } from '@angular/core';
import { BaseService } from './baseservice';
import { AppSettings } from '../common/AppSettings';
import { CMLiveProgram } from '../Model/cm-live-programs.model';

@Injectable({
  providedIn: 'root'
})
export class CmLiveProgramsService {

  constructor(private httpService: BaseService) { }

  GetCMLivePrograms(model:CMLiveProgram){
    return this.httpService.post(AppSettings.API_ENDPOINT_Jankalyan + AppSettings.jankalyanApi.CMLiveprogram.GetLivePrograms, model);
  }
}
