import { Component } from '@angular/core';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html'
})

export class ThankYouComponent {


  constructor() {

  }

}
