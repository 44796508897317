import { Component, OnInit } from '@angular/core';
import { FrontUploaderDataRequestModel, FrontUploaderDataResponseModel } from '../Model/photo-video-uploader.model';
import { MediaService } from '../services/media.services';
import { PagerService } from '../pager.service';
import { TranslateService } from '../translate.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { PageTitleModel } from '../Model/department-model.model';
import { DepartmentSubMenuDetailModel } from '../Model/awards-request-model.model';
import { UploaderTypeEnum } from '../Enum/uploader.enum';

@Component({
  selector: 'app-photo-video-uploader-gallery',
  templateUrl: './photo-video-uploader-gallery.component.html',
  styleUrls: ['./photo-video-uploader-gallery.component.css'],
  providers: [MediaService]
})
export class PhotoVideoUploaderGalleryComponent implements OnInit { 
  currentPlayingVideo: HTMLVideoElement;
  currentPlayingAudio: HTMLAudioElement;
  pageTitle: PageTitleModel;
  pageName: string = "";
  pagedItems: FrontUploaderDataResponseModel[];
  model: FrontUploaderDataResponseModel[];
  indexModel = new FrontUploaderDataRequestModel();
  totalRecords = 0;
  pager: any = {};
  currentPage: number;
  departmentCode: number = 0;
  submenuCode: number;
  catCode: number = 0;
  subCatCode: number;
  actCode:number;
  isShowPoster = false;
  isShowVideo = false;

  constructor(
    private mediaService: MediaService,
    private pagerService: PagerService,
    public translate: TranslateService,
    private _route: ActivatedRoute,
    private readonly route: Router,
  ) { }

  ngOnInit() {
    this._route.paramMap.subscribe((params: ParamMap) => {

      this.departmentCode = this._route.snapshot.params.dptCode;
      this.indexModel.DepartmentCode = this.departmentCode;

      let _uploaderType = this._route.snapshot.params.type;
      if (_uploaderType == UploaderTypeEnum.Photo) {
        this.pageName = 'Photo';
        this.isShowPoster = true
        this.indexModel.PageSize = 9;
      }
      else if (_uploaderType == UploaderTypeEnum.Video) {
        this.pageName = 'Video';
        this.isShowVideo = true;
        this.indexModel.PageSize = 9;
      } else if(_uploaderType == UploaderTypeEnum.CMSpeeches) {
        this.pageName = 'CM Speeches';
        this.isShowVideo = true;
        this.indexModel.PageSize = 9;
      }
      else {
        this.route.navigate(["home"])
      }

      let _catCode = this._route.snapshot.params.catCode;
      if (_catCode) {
        this.catCode = Number(_catCode) || 0;
        this.indexModel.CategoryCode = this.catCode;
      }
      let _submenuCode = this._route.snapshot.params.submenuCode;
      this.submenuCode = Number(_submenuCode || 0);

      let _actCode = this._route.snapshot.params.actCode;      
      if(_actCode){
        this.actCode = Number(_actCode) || 0;
        this.indexModel.ActivityCode = this.actCode;
      }

      this.getPageHeading();
      this.getListData(this.currentPage);
    });
  }

  getPageHeading() {
    this.pageTitle = new PageTitleModel();
    if (this.submenuCode > 0) {
      this.getSubmenuDetail();
    }
    else if (this.pageName == 'Photo') {
      this.pageTitle.NameEnglish = "Photo Gallery";
      this.pageTitle.NameHindi = "फ़ोटो गेलरी";
    }
    else if (this.pageName == 'Video') {
      this.pageTitle.NameEnglish = "Video Gallery";
      this.pageTitle.NameHindi = "वीडियो गेलरी";
    }else if(this.pageName == 'CM Speeches'){
      this.pageTitle.NameEnglish = "CM Speeches";
      this.pageTitle.NameHindi = "सीएम भाषण";
    }
    // this.pageName = 'Photo';
  }

  getSubmenuDetail() {
    this.mediaService.GetSubmenuDetail(this.submenuCode).subscribe(res => {
      if (res.IsSuccess) {
        let submenu = res.Data as DepartmentSubMenuDetailModel;
        if (submenu) {
          this.pageTitle = new PageTitleModel();
          this.pageTitle.NameEnglish = submenu.DisplayNameEnglish;
          this.pageTitle.NameHindi = submenu.DisplayNameHindi;
          this.pageTitle.ImagePath = submenu.IconImage;
        }
      }
    }, error => { });
  }

  getListData(page: number) {
    this.indexModel.OrderBy = "ModifiedDate";
    this.indexModel.OrderByAsc = 0;
    this.indexModel.UploaderType = this.actCode == 32174 ? 'Video' : this.pageName;
    this.indexModel.Page = page
    this.indexModel.PageSize = 12;
    this.mediaService.GetUploaderDataForFront(this.indexModel).subscribe((response) => {
      if (response.IsSuccess) {
        this.model = response.Data.Data as FrontUploaderDataResponseModel[];
        if (!this.indexModel.IsPostBack) {
          this.totalRecords = response.Data.TotalRecords;
          this.setPage(page);
        }
      } else {
        this.model = []
      }
    }, (error) => {
      this.model = []
    });
  }

  setPage(page: number) {
    window.scroll(0, 0);
    this.currentPage = page;
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    // get pager object from service
    this.pager = this.pagerService.getPager(this.totalRecords, page);
    // get current page of items
    this.pagedItems = this.model;
  }

  onMediaPlaying(event, IsAudio: boolean) {
    // event.preventDefault();
    // if (this.currentPlayingVideo != undefined) {
    //   this.currentPlayingVideo.pause();
    // }
    // if (this.currentPlayingAudio != undefined) {
    //   this.currentPlayingAudio.pause();
    // }
    // if (IsAudio) {
    //   this.currentPlayingAudio = event.target;
    //   this.currentPlayingAudio.play();
    // }
    // else {
    //   this.currentPlayingVideo = event.target;
    //   this.currentPlayingVideo.play();
    // }
  }

  playVideo(event:any) {
    const video = event.target as HTMLVideoElement;
    if (video) {
      // console.log('Attempting to play video');
      if (video.paused) {
        video.play().then(() => {
          // console.log('Video is playing');
        }).catch(error => {
          console.error('Error playing video:', error);
        });
      } else {
        // console.log('Video is already playing');
      }
    } else {
      // console.error('Video element not found or not accessible');
    }
  }

  pauseVideo(event:any) {
    const video = event.target as HTMLVideoElement;
    if (video) {
      // console.log('Attempting to pause video');
      if (!video.paused) {
        video.pause();
        // console.log('Video is paused');
      } else {
        // console.log('Video is already paused');
      }
    } else {
      // console.error('Video element not found or not accessible');
    }
  }

}
