import { Data } from './../services/data.services';
import { EventService } from './../services/events.services';
import { Component, Inject, OnInit } from '@angular/core';
import { PagerService } from '../pager.service';
import { environment } from '../../environments/environment';
import { TranslateService } from '../translate.service';

@Component({
  selector: 'app-keyinitiatives',
  templateUrl: './keyinitiatives.component.html',
  styleUrls: ['./keyinitiatives.component.css'],
  providers: [EventService]
})
export class KeyinitiativesComponent implements OnInit {

  public programKeyInitiatives: keyinitiativesModel[];

  imageUrl = environment.imageUrl + 'Program/KeyInitiativesImage/';
  constructor(@Inject('BASE_URL') public baseUrl: string,
    public translate: TranslateService, private eventService: EventService) {
    this.programKeyInitiatives = [];
  }
  ngOnInit() {
    this.getData();
  }

  setLang(lang: string) {
    this.translate.use(lang);
  }
  public getData() {
    this.eventService.GetkeyinitiativesList().subscribe(success => {
      this.programKeyInitiatives = success;
    }, error => {
      console.error(error);
    });

  }
}

export class keyinitiativesModel {
  ProgramId: number;
  ProgramCategoryId: number;
  ProgramSubCategoryId: number;
  ProgramDate: string;
  ProgramDistrictId: number;
  ProgramLocationId: number;
  ProgramAddress: string;
  ProgramAddressHindi: string;
  ProgramLanguage: string;
  ProgramSubject: string;
  ProgramSubjectHindi: string;
  ProgramSpeech: string;
  ProgramSpeechHindi: string;
  Keywords: string;
  KeywordsHindi: string;
  SpeechPdf: string;
  Pdf: string;
  ProgramStateId: number;
  ProgramTime: string;
  UserId: string;
  IsShowLatest: string;
  KeyImage: string;
  FolderName: string;
  PdfEnglish: string;
  District: string;
  ProgramCategory: string;
  ProgramCategoryhindi: string;
  ProgramSubCategoryhindi: string;
  ProgramSubCategory: string;
  ProgramDateHindi: string;
  BannerImages: string;
}

