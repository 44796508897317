
import { KeyofficialsService } from './../services/keyofficials.service';
import { Component, Inject, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { TranslateService } from '../translate.service';
import { IndexModel } from '../Model/awards-request-model.model';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DepartmentContactDetailsFrontViewModel, keyOfficialModel } from '../Model/key-official.model';
import { DepartmentCodeEnum } from '../Enum/app-enums';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-key-officials',
  templateUrl: './key-officials.component.html',
  styleUrls: ['./key-officials.component.css']
})
export class KeyOfficialsComponent implements OnInit {

  indexModel = new IndexModel();
  model: DepartmentContactDetailsFrontViewModel[];
  departmentCode: number = 0;
  catCode: number = 0; //31041; //Office Category(Block Level Officers)
  IsLoading = true;
  IsData: boolean;
  IsPDF: boolean;
  IsURL: boolean;
  safeURL: SafeResourceUrl;
  isMobileExist: boolean = false;
  isEmailExist: boolean = false;
  totalRecords = 0;
  pagerConfig: any = {
    id: 'datapager',
    itemsPerPage: this.indexModel.PageSize,
    currentPage: this.indexModel.Page,
    totalItems: this.totalRecords,
  };
  DepartmentCodeEnum: DepartmentCodeEnum;
  public programkeyOfficial: keyOfficialModel[];

  // imageUrl = environment.imageUrl + 'Program/Image/Thumbnail/Fr_';
  isCodeOfConduct: boolean = true;
  constructor(@Inject('BASE_URL')
  public baseUrl: string,
    public sanitizer: DomSanitizer,
    public translate: TranslateService,
    private keyOfficialService: KeyofficialsService,
    public _commonService: CommonService) {
    this.isCodeOfConduct = this._commonService.GetIsCodeOfConduct();
    this.programkeyOfficial = [];
  }
  ngOnInit() {
    //this.getData();
    this.getDataFromJankalyanApi();
  }

  setLang(lang: string) {
    this.translate.use(lang);
  }
  public getData() {
    this.keyOfficialService.GetKeyOfficial().subscribe(success => {
      this.programkeyOfficial = success;
    }, error => {
      console.error(error);
    });

  }

  public getDataFromJankalyanApi() {
    this.departmentCode = DepartmentCodeEnum.CmoWebsiteCode;
    this.indexModel.IsPostBack = true;
    this.indexModel.PageSize = 101;
    this.indexModel.OrderBy = 'DisplayOrderNo';
    this.indexModel.OrderByAsc = 1;
    this.indexModel.AdvanceSearchModel = { DepartmentCode: this.departmentCode, CategoryCode: this.catCode };

    this.keyOfficialService.GetDepartmentontactDetail(this.indexModel).subscribe(success => {
      if (success.IsSuccess) {
        this.model = success.Data.Data as DepartmentContactDetailsFrontViewModel[];
        if (this.model.length > 0) {
          this.IsData = this.model[0].IsData;
          this.IsPDF = this.model[0].IsPDF;
          this.IsURL = this.model[0].IsURL;

          if (this.IsPDF) {
            this.safeURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.model[0].AttachmentUrl);
          }
          else if (this.IsURL) {
            setTimeout(() => { window.history.back(); }, 100);
            window.open(this.model[0].URL, "_blank");
          }
          else {
            if (this.model.find(x => x.MobileNo)) {
              this.isMobileExist = true;
            }
            if (this.model.find(x => x.Email)) {
              this.isEmailExist = true;
            }
          }
        }

        if (!this.indexModel.IsPostBack) {
          this.totalRecords = success.Data.TotalRecords;
        }
        this.updatePageConfig();
      }
      this.IsLoading = false;
    }, error => {
      console.error(error);
    });

  }

  updatePageConfig() {
    this.pagerConfig = {
      itemsPerPage: this.indexModel.PageSize,
      currentPage: this.indexModel.Page,
      totalItems: this.totalRecords
    };
  }

}

