<!-- <div [ngClass]="!isCodeOfConduct ? 'bg-scroll pos-rel breadcrumbs-page-img' : 'bg-scroll pos-rel'">
  <div class="container">
    <h1>{{'Home_RECENT_NEWS'| translate}}</h1>
  </div>
</div> -->
<div class="breadcrumbs-page-img">
    <div class="banner-hight-2">
      <h1>{{'Home_RECENT_NEWS'| translate}}</h1>
      <div class="inner-page-banner">
        <img src="assets/images/cmo-banner.png" alt="banner" class="cmo-banner">
      </div>
    </div>
  </div>
  <main id="main">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
        <li class="breadcrumb-item"><a href="javascript:void(0)">{{'Media_CornerSubNav'| translate}}</a></li>
        <li class="breadcrumb-item active" aria-current="page">{{'Success_Stories_txt'| translate}}</li>
      </ol>
    </nav>
      <nav class="navbar justify-content-between m-1">
        <a class="navbar-brand"></a>
        <div class="form-inline">
          <div class="text-start">
            <label>From Date</label>
            <input class="form-control mr-sm-2" type="date" name="" id="" [(ngModel)]="fromDate">
          </div>
          <div class="text-start">
            <label>To Date</label>
            <input class="form-control mr-sm-2" type="date" name="" id="" [(ngModel)]="toDate">
          </div>
          <div class="text-start">
            <label>Search</label>
            <input class="form-control mr-sm-2" [(ngModel)]="searchText" type="search" placeholder="Search" aria-label="Search">
          </div>
          <div class="search-btn">
            <label>&nbsp;</label> 
            <button class="btn btn-outline-primary mr-sm-2" type="submit" (click)="onSearch()"><i class="fa fa-search"></i></button>
          </div>
        </div>
      </nav>
    
    <section class="program_list message_cm">
      <div class="container">
        <p *ngIf="IsLoading" class="text-center"><b>Loading...</b></p>
  
        <div class="row" *ngIf="!IsLoading">
          <section id="programmes" class="w-100">
            <div class="row">
              <div class="col-lg-4" *ngFor="let news of generalModel let i = index">
                <div class="box pbox wow  dis-table"
                  [ngClass]="[i%3 <1 ? 'fadeInLeft' : i%3 <2? 'fadeInUp':i%3 <3? 'fadeInRight':'fadeInLeft' ]">
                  <div class="icon align-self-center" routerLink="/special-release-detail/{{news.Id}}" style="cursor: pointer;"> <img src="{{news.HomePageImageUrl}}" alt="Icon" onerror="this.src='assets/images/story-icon.png'" class="img-fluid"> </div>
                  <div class="programme-content align-self-center">
                    <h4>
                      <a routerLink="/special-release-detail/{{news.Id}}">
                        <p
                          *ngIf="(this.translate.currlang == 'hi' && news.Description.length<=50 )||(this.translate.currlang != 'hi' && news.Description.length<=50)">
                          {{this.translate.currlang == 'hi' ? news.Description : news.Description}}
                        </p>
                        <p
                          *ngIf="(this.translate.currlang == 'hi' && news.Description.length>50 )||(this.translate.currlang != 'hi' && news.Description.length>50)">
                          {{(this.translate.currlang == 'hi' ? news.Description : news.Description)|slice:0:45}}...
                        </p>
                      </a>
                    </h4>
                    <span><strong>{{'Posted_On'| translate}} :</strong>
                      {{this.translate.currlang == 'hi' ? (news.PressReleaseDateHindi)
                      : (news.PressreleaseDate | date : 'd MMM, y')}}</span> <a
                      routerLink="/special-release-detail/{{news.Id}}" class="mr-auto">{{'Read_More' | translate}}...</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="generalModel && generalModel.length == 0">
              <div class="no-record">
                {{'No_Record_Found'| translate}}
              </div>
            </div>
          </section>
          <select name="example_length " aria-controls="example " (change)="onPageSize($event)" [(ngModel)]="pageSize" *ngIf="totalRecords > pageSize"
            class="form-control-sm ng-pristine ng-valid ng-touched" style="width: 70px;">
            <option [value]="totalRecords"> All </option>
            <option value="9">9</option>
            <option value="18">18</option>
            <option value="36">36</option>
            <option value="72">72</option>
            <option value="144">144</option>
          </select>
          <nav aria-label="Page navigation example" class="w-100 text-center mt-4" *ngIf="totalRecords > pageSize">
  
            <ul class="pagination d-inline-flex" *ngIf="pager.pages && pager.pages.length">
              <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                <a class="page-link" (click)="GetPressReleaseData(1,pageSize)">{{'First'| translate}}</a>
              </li>
              <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                <a class="page-link" (click)="GetPressReleaseData(pager.currentPage - 1,pageSize)">{{'Previous'| translate}}</a>
              </li>
              <li class="page-item" *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
                <a class="page-link" (click)="GetPressReleaseData(page,pageSize)">{{page}}</a>
              </li>
              <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                <a class="page-link" (click)="GetPressReleaseData(pager.currentPage + 1,pageSize)">{{'Next'| translate}}</a>
              </li>
              <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                <a class="page-link" (click)="GetPressReleaseData(pager.totalPages,pageSize)">{{'Last'| translate}}</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </section>
  </main>