
import { AchievementService } from './../services/Achievement.services';
import { Component, OnInit, AfterContentChecked } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateService } from '../translate.service';
import { environment } from '../../environments/environment';
import { AchievementViewModel, ActiveAwardsRequestModel, ActiveLatestAwardsRequestModel } from '../Model/awards-request-model.model';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { CommonService } from '../services/common.service';


@Component({
  selector: 'app-awarddetail',
  templateUrl: './awarddetail.component.html',
  styleUrls: ['./awarddetail.component.css'],
  providers: [AchievementService]
})
export class AwarddetailComponent implements OnInit, AfterContentChecked {
  DateFormat = environment.DateFormat;
  latestAwardModels: AwardsModel[];
  id: string;
  isEnglish: boolean;
  environmentUrlPDF = environment.imageUrl + 'AwardsPdf/';
  environmentUrlImage = environment.imageUrl + 'Images/AwardsImages/';
  totalPage: number;
  searchBox = '';
  date = '';
  currentPage = 1;
  PDFFile: SafeResourceUrl;
  recordId: number;
  model: AchievementViewModel;
  isCodeOfConduct: boolean = false;

  constructor(
    private route: ActivatedRoute,
    public translate: TranslateService,
    private achievementService: AchievementService,
    public sanitizer: DomSanitizer,
    public _commonService: CommonService) {

    this.isCodeOfConduct = this._commonService.GetIsCodeOfConduct();
  }
  setLang(lang: string) {
    this.translate.use(lang);
  }
  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get('id');
      this.GetAwardDetailById();
    });
  }
  ngAfterContentChecked() {
    this.isEnglish = this.translate.currlang === 'en' ? true : false;
  }

  GetAwardDetailById() {
    this.recordId = Number(this.id);
    this.achievementService.GetDetailById(this.recordId).subscribe(responce => {
      if (responce.IsSuccess) {
        this.model = responce.Data;
        this.PDFFile = this.sanitizer.bypassSecurityTrustResourceUrl(this.model.PdfFIleName);
      }
    },
      error => { });
  }
  sanitizeImageUrl(imageUrl: string): SafeUrl {

    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }

  downloadPdf(url, name) {

    if (url || '' != '') {
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', url);
      link.setAttribute('download', name + ".pdf");
      document.body.appendChild(link);
      link.click();
      link.remove();
    }

  }
  openInFullScreen(url) {
    if (url) {
      window.open(url, "_blank");
    }

  }



}


export class AwardsModel {
  AwardId: number;
  Subject: string;
  SubjectHindi: string;
  PdfFIleName: string;
  Image: string[] = [];
  IsActive: boolean;
  IsDelete: boolean;
  CreatedDate: Date;
  ModifiedDate: Date;
  AwardDate: string;
  AwardDateHindi: string;
}




