import { ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { PhoneBoothService } from '../services/phone-booth.service';
import { TranslateService } from '../translate.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-phone-booth-admin',
  templateUrl: './phone-booth-admin.component.html',
  styleUrls: ['./phone-booth-admin.component.css'],
})
export class PhoneBoothAdminComponent {
  constructor(
    private fb: FormBuilder,
    private phoneBoothService: PhoneBoothService,
    public translate: TranslateService,
    public readonly _commonService: CommonService,
    public sanitizer: DomSanitizer,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) { }

  files: any = [];
  email = environment.TEST_CERD_EMAIL;
  password = environment.TEST_CRED_PASSWORD;
  fileError = "";
  isfileValid = true;
  allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"];
  eventForm = this.fb.group({
    eventName: ["", Validators.required],
    images: [null],
  });

  events: string[] = [];
  ngOnInit() {

    this.getAllEvents();
    setInterval(() => {
      const cred = localStorage.getItem('token')
      if (cred != btoa(this.email + this.password)) {
        localStorage.removeItem('token');
        this.router.navigate(['/phone-booth-admin-login'])
      }
    }, 100)
  }

  getAllEvents() {
    this.phoneBoothService.getEvents().subscribe(
      (data) => {
        this.events = data.events;
      },
      (error) => {
        console.error("Error fetching events:", error);
      }
    );
  }

  onDeleteEvent() {
    this.eventForm.controls.eventName.markAsTouched();
    if (this.eventForm.valid) {
      debugger
      this.phoneBoothService.deleteEvents(this.eventForm.controls.eventName.value).subscribe(
        (data) => {
          this._commonService.alertMsg("Success!", data.message, "success");
        },
        (error) => {
          console.error("Error deleting events:", error);
        }
      );
    }
  }

  onResetEvent() {
    this.eventForm.controls.eventName.setValue("");
    this.eventForm.controls.eventName.markAsUntouched();
    this.eventForm.controls.eventName.updateValueAndValidity();
    this.eventForm.controls.images.setValue(null);
  }

  // Handle file input change
  onFileChange(event: any) {
    this.isfileValid = true;
    const fileInput = event.target as HTMLInputElement;
    const fileList: FileList = fileInput.files;
    Array.from(fileList).forEach((file: File) => {
      // Validate file type
      if (!this.allowedFileTypes.includes(file.type)) {
        this.fileError = `Invalid file type for ${file.name}. Allowed types: JPG, JPEG, PNG.\n`;
        this.isfileValid = false;
        return;
      }
      if (event.target.files && event.target.files.length) {
        this.files = event.target.files;
        console.log(event.target.files, "event.target.filesevent.target.files");
      }
    });
  }

  // Submit form (placeholder for handling form submission)
  onSubmit() {
    if (this.isfileValid == false) return
    console.log(this.files, "files-files");
    // Process form data here, e.g., upload to the server
    console.log(this.eventForm.value);
    this.eventForm.markAllAsTouched();
    if (this.eventForm.valid) {
      if (this.files.length >= 0) {
        for (let i = 0; i < this.files.length; i++) {
          const formData = new FormData();
          formData.append("event_name", this.eventForm.value.eventName);
          formData.append("file", this.files[i]);
          this.phoneBoothService.addFace(formData).subscribe((res: any) => {
            console.log(res, "RES");
            // this.getAllEvents();
          });
        }
      }
    }
  }
}
