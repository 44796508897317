<!--<section class="top-news">
  <div class="container">
    <div class="row">
      <div class="col-sm-6" *ngFor="let message of Messageprograms">
        <h2>{{this.translate.currlang == 'hi' ? message.ProgramSubCategoryhindi:message.ProgramSubCategory}}:{{this.translate.currlang == 'hi' ? message.ProgramDateHindi : message.ProgramDate}}</h2>
        <p> {{this.translate.currlang == 'hi' ? message.ProgramSubjectHindi : message.ProgramSubject}}</p>
      </div>

    </div>
  </div>
</section>-->

<div [ngClass]="!isCodeOfConduct ? 'bg-scroll pos-rel page-img' : 'bg-scroll pos-rel'">
  <div class="container">

    <h1>{{'Chief_MinisterNav'| translate}}</h1>

  </div>
</div>
<main id="main">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
      <li class="breadcrumb-item"><a href="javascript:void(0)">{{'State_GovernmentSubNav'| translate}}</a></li>
      <li class="breadcrumb-item"><a href="javascript:void(0)">{{'Chief_MinisterNav'| translate}}</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{'NavMessageFromCM'| translate}}</li>
    </ol>
  </nav>
  <section class="program_list message_cm">
    <div class="container">

      <div class="row">
        <section id="programmes">

          <div class="row">
            <div class="col-lg-6" *ngFor="let message of this.generalModel let i = index">
              <div class="box pbox wow  dis-table"
                [ngClass]="[i%3 <1 ? 'fadeInLeft' : i%3 <2? 'fadeInUp':i%3 <3? 'fadeInRight':'fadeInLeft' ]">
                <div class="icon align-self-center"> <img src="assets/img/Message_From_CM.png" alt="Icon"
                    class="img-fluid"> </div>
                <div class="programme-content align-self-center">
                  <h4>
                    <a routerLink="/messageDetail/{{message.Id}}">

                      <!--{{this.translate.currlang == 'hi' ? message.ProgramSubCategoryhindi:message.ProgramSubCategory}}:-->
                      {{this.translate.currlang == 'hi' ? message.Description : message.Description}}
                    </a>
                  </h4>

                  <span><strong> {{'Posted_On'| translate}}:</strong> {{this.translate.currlang == 'hi' ?
                    message.PressReleaseDateHindi : (message.PressreleaseDate | date : 'd MMM, y')}} </span> <a
                    routerLink="/messageDetail/{{message.Id}}" class="mr-auto">{{'Read_More'| translate}}...</a>
                </div>
              </div>
            </div>
          </div>

        </section>
        <nav aria-label="Page navigation example" class="w-100 text-center mt-4" *ngIf="this.totalRecords > 9">

          <ul class="pagination d-inline-flex" *ngIf="pager.pages && pager.pages.length">
            <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
              <a class="page-link" (click)="GetPressReleaseData(1)">{{'First'| translate}}</a>
            </li>
            <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
              <a class="page-link" (click)="GetPressReleaseData(pager.currentPage - 1)">{{'Previous'| translate}}</a>
            </li>
            <li class="page-item" *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
              <a class="page-link" (click)="GetPressReleaseData(page)">{{page}}</a>
            </li>
            <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
              <a class="page-link" (click)="GetPressReleaseData(pager.currentPage + 1)">{{'Next'| translate}}</a>
            </li>
            <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
              <a class="page-link" (click)="GetPressReleaseData(pager.totalPages)">{{'Last'| translate}}</a>
            </li>
          </ul>

        </nav>
      </div>
    </div>
    <div class="row" *ngIf="generalModel==undefined || generalModel.length == 0">
      <div class="no-record">
        {{'No_Record_Found'| translate}}
      </div>
    </div>
  </section>



</main>