
<div class="bg-scroll pos-rel breadcrumbs-page-img">
  <div class="container">
   
    <h1>{{this.translate.currlang == 'hi' ? "राजस्थान" : "Rajasthan"}}</h1>
   
  </div>
</div>
<main id="main">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
      <li class="breadcrumb-item"><a href="javascript:void(0)">{{this.translate.currlang == 'hi' ? "राज्य परिचय" : "State Profile"}}</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{this.translate.currlang == 'hi' ? "राजस्थान" : "Rajasthan"}}</li>
    </ol>
  </nav>
  <section class="program_list rajasthan_page">
    <div class="container">
      <div class="row mb-4">
        <div class="col-xl-6">
          <div class="row  no-gutters wow fadeInLeft">
            <div class='col-sm-4 col-xs-6 col-md-4 col-lg-4 '> <a class="thumbnail" title="Rajasthan" href="assets/img/rajasthan/raj_1.jpg"> <img class="img-fluid" alt="" src="assets/img/rajasthan/raj_1.jpg" /> </a> </div>
            <!-- col-6 / end -->
            <div class='col-sm-4 col-xs-6 col-md-4 col-lg-4'> <a class="thumbnail" title="Rajasthan" href="assets/img/rajasthan/raj_2.jpg"> <img class="img-fluid" alt="" src="assets/img/rajasthan/raj_2.jpg" /> </a> </div>
            <!-- col-6 / end -->
            <div class='col-sm-4 col-xs-6 col-md-4 col-lg-4'> <a class="thumbnail" title="Rajasthan" href="assets/img/rajasthan/raj_3.jpg"> <img class="img-fluid" alt="" src="assets/img/rajasthan/raj_3.jpg" /> </a> </div>
            <!-- col-6 / end -->
            <div class='col-sm-4 col-xs-6 col-md-4 col-lg-4'> <a class="thumbnail" title="Rajasthan" href="assets/img/rajasthan/raj_4.jpg"> <img class="img-fluid" alt="" src="assets/img/rajasthan/raj_4.jpg" /> </a> </div>
            <!-- col-6 / end -->
            <div class='col-sm-4 col-xs-6 col-md-4 col-lg-4'> <a class="thumbnail" title="Rajasthan" href="assets/img/rajasthan/raj_5.jpg"> <img class="img-fluid" alt="" src="assets/img/rajasthan/raj_5.jpg" /> </a> </div>
            <!-- col-6 / end -->
            <div class='col-sm-4 col-xs-6 col-md-4 col-lg-4'> <a class="thumbnail" title="Rajasthan" href="assets/img/rajasthan/raj_6.jpg"> <img class="img-fluid" alt="" src="assets/img/rajasthan/raj_6.jpg" /> </a> </div>
            <!-- col-6 / end -->

            <div class='col-sm-4 col-xs-6 col-md-4 col-lg-4'> <a class="thumbnail" title="Rajasthan" href="assets/img/rajasthan/raj_7.jpg"> <img class="img-fluid" alt="" src="assets/img/rajasthan/raj_7.jpg" /> </a> </div>
            <!-- col-6 / end -->
            <div class='col-sm-4 col-xs-6 col-md-4 col-lg-4'> <a class="thumbnail" title="Rajasthan" href="assets/img/rajasthan/raj_8.jpg"> <img class="img-fluid" alt="" src="assets/img/rajasthan/raj_8.jpg" /> </a> </div>
            <!-- col-6 / end -->
            <div class='col-sm-4 col-xs-6 col-md-4 col-lg-4'> <a class="thumbnail" title="Rajasthan" href="assets/img/rajasthan/raj_9.jpg"> <img class="img-fluid" alt="" src="assets/img/rajasthan/raj_9.jpg" /> </a> </div>
            <!-- col-6 / end -->
            <!-- col-6 / end -->
            <!-- col-6 / end -->
            <!-- col-6 / end -->
          </div>
        </div>
        <div class="col-xl-6" *ngIf="this.translate.currlang == 'hi'">
          <div class="raj_details">
            <div class="section-header">
              <h2>परिचय </h2>
            </div>
            <p class="wow fadeInUp">राजस्थान, भारत का सबसे बड़ा राज्य देश के उत्तर-पश्चिमी भाग में स्थित है। यह उत्तर एवं उत्तर पूर्व में पंजाब तथा हरियाणा, पूर्व में उत्तर प्रदेश, दक्षिण पूर्व में मध्य प्रदेश एवं दक्षिण पश्चिम में गुजरात द्वारा घिरा हुआ है । राज्य का दक्षिणी हिस्सा कच्छ की खाड़ी से 225 किमी तथा अरब सागर से 400 किमी दूर स्थित है । </p>
            <!--<div class="section-header">
              <h2>History </h2>
            </div>-->
            <p class="wow fadeInUp">जयपुर राजस्थान की राजधानी है जो की राज्य के मध्य पूर्व में स्थित है ।</p>
            <!--<p class="wow fadeInUp">In the medieval era, the major regions of the state such as Nagaur, Ajmer and Ranthambhore became a part of the Mughal empire, which was headed by Akbar. The most famous Rajput warriors of this era were Rana Uday Singh, his son Rana Pratap, Bhappa Rawal, Rana Kumbha and Prithviraj Chauhan. With the end of the Mughal regime in 1707, the Marathas gained supremacy and captured Ajmer in 1775. The Maratha ascendancy ended in the late 17th century with the arrival of British. The present state of Rajasthan was formed in 1956. </p>-->
            <a href="http://rajasthan.gov.in/index.php/home/about-rajasthan/" rel="noopener noreferrer" target="_blank" class="btn-theme bg-blue mt-3">और जानिये &nbsp;<i class="fa fa-angle-right" aria-hidden="true"></i> </a>
          </div>
        </div>

        <div class="col-xl-6" *ngIf="this.translate.currlang != 'hi'">
          <div class="raj_details">
            <div class="section-header">
              <h2>Introduction</h2>
            </div>
            <p class="wow fadeInUp">Rajasthan, the largest state of India is located in the north-western part of the subcontinent. It is bounded to the north and north-east by the states of Punjab and Haryana, to the east and south-east by the states of Uttar Pradesh and Madhya Pradesh, to the south-west by the state of Gujarat, and to the west and north-west by Pakistan. The southern part of the state is about 225km from the Gulf of Kutch and about 400 km from the Arabian Sea. Jaipur is the capital city and lies in the east-central part of the state. </p>
            <div class="section-header">
              <h2>History </h2>
            </div>
            <p class="wow fadeInUp"> The history of Rajasthan is about 5000 years old and the mythological origin of this gigantic land is related to the famous myth of Ram, the seventh incarnation of Lord Vishnu. In the ancient period, Rajasthan was a part of different dynasties including the Mauryan Empire . The first batch of Aryans who came to India settled in the region of Dundhmer and the first inhabitants of this area were the Bhils and the Minas. The earliest Rajput dynasty that emerged around 700 AD was the Gurjara and Partiharas and since then Rajasthan was known as Rajputana (the land of the Rajputs). Soon, the Rajput clan gained supremacy and the Rajputs were divided into 36 royal clans and 21 dynasties. The armed conflicts and the struggle for supremacy among the Parmars, Chalukyas, and Chauhans resulted in a lot of bloodshed. </p>
            <p class="wow fadeInUp">In the medieval era, the major regions of the state such as Nagaur, Ajmer and Ranthambhore became a part of the Mughal empire, which was headed by Akbar. The most famous Rajput warriors of this era were Rana Uday Singh, his son Rana Pratap, Bhappa Rawal, Rana Kumbha and Prithviraj Chauhan. With the end of the Mughal regime in 1707, the Marathas gained supremacy and captured Ajmer in 1775. The Maratha ascendancy ended in the late 17th century with the arrival of British. The present state of Rajasthan was formed in 1956. </p>
            <a href="http://rajasthan.gov.in/index.php/home/about-rajasthan/" rel="noopener noreferrer" target="_blank" class="btn-theme bg-blue mt-3">READ MORE &nbsp;<i class="fa fa-angle-right" aria-hidden="true"></i> </a>
          </div>
        </div>
      </div>
      <!-- row / end -->
    </div>
  </section>
</main>
