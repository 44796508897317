import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DepartmentCodeEnum } from '../Enum/app-enums';
import { EntryTypeEnum, JankalyanCategoryEnum, SingleEntryDataType } from '../Enum/jankayan.enum';
import { DepartmentProfileFilterModel, DepartmentProfileViewModel, PageTitleModel } from '../Model/department-model.model';
import { CommonService } from '../services/common.service';
import { DepartmentService } from '../services/department.service';
import { TranslateService } from '../translate.service';

@Component({
  selector: 'app-profile-cm',
  templateUrl: './profile-cm.component.html',
  styleUrls: ['./profile-cm.component.css']
})
export class ProfileCmComponent implements OnInit {

  pageTitle: PageTitleModel = new PageTitleModel();
  departmentProfileModel: DepartmentProfileViewModel[];
  dataModel = new DepartmentProfileViewModel();
  departmentProfileFilterModel = new DepartmentProfileFilterModel();
  entryDataType = SingleEntryDataType;
  IsLoading = true;
  departmentProfileTotalRecords = 0;
  DepartmentCodeEnum: DepartmentCodeEnum;
  departmentProfilePagerConfig: any = {
    id: "departmentProfile-paging",
    itemsPerPage: this.departmentProfileFilterModel.IndexModel.PageSize,
    currentPage: this.departmentProfileFilterModel.IndexModel.Page,
    totalItems: this.departmentProfileTotalRecords
  };
  // isCodeOfConduct: boolean = true;
  isCodeOfConduct: boolean = false;

  constructor(
    public translate: TranslateService,
    public sanitizer: DomSanitizer,
    public readonly commonService: CommonService,
    private readonly departmentProfile: DepartmentService
  ) {
    this.isCodeOfConduct = this.commonService.GetIsCodeOfConduct();
   }

  ngOnInit() {
    this.getDepartmentProfileListData();
  }

   //Jankalyan API Start
   getDepartmentProfileListData() {
    this.IsLoading = true;
    this.dataModel = undefined;
    this.departmentProfileFilterModel.IndexModel.PageSize = 1;
    this.departmentProfileFilterModel.DepartmentCode = DepartmentCodeEnum.CmoWebsiteCode;
    this.departmentProfileFilterModel.JankalyanCategoryCode = JankalyanCategoryEnum.AboutDepartmentDistrictCode;
    this.departmentProfileFilterModel.EntryTypeCode = EntryTypeEnum.AboutCMCode;

    this.departmentProfile.GetList(this.departmentProfileFilterModel).subscribe((data:any) => {
      
      if (data.IsSuccess) {
        this.departmentProfileModel = data.Data.Data as DepartmentProfileViewModel[];
        if (this.departmentProfileModel && this.departmentProfileModel.length == 1) {
          this.dataModel = this.departmentProfileModel[0];
          this.pageTitle.NameEnglish = this.pageTitle.NameEnglish ? this.pageTitle.NameEnglish : this.dataModel.EntryTypeName;
          this.pageTitle.NameHindi = this.pageTitle.NameHindi ? this.pageTitle.NameHindi : this.dataModel.EntryTypeNameHindi;
          if (this.dataModel.PDFURL) {
            this.dataModel.PDFURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.dataModel.PDFURL);
          }
          if (this.dataModel.DataTypeCode == this.entryDataType.URL && !this.dataModel.Details) {
            window.open(this.dataModel.url, "_blank");
            this.commonService.gotoPreviousPage();
          }
        }
        else {
          this.dataModel = new DepartmentProfileViewModel();
        }
        if (!this.departmentProfileFilterModel.IndexModel.IsPostBack) {
          this.departmentProfileTotalRecords = data.Data.TotalRecords || 0;
        }
        this.departmentProfileUpdatePageConfig();
      }
      else {
        this.dataModel = {} as DepartmentProfileViewModel;
      }
      this.IsLoading = false;
    });
  }

  departmentProfileUpdatePageConfig() {
    this.departmentProfilePagerConfig.itemsPerPage = this.departmentProfileFilterModel.IndexModel.PageSize;
    this.departmentProfilePagerConfig.currentPage = this.departmentProfileFilterModel.IndexModel.Page;
    this.departmentProfilePagerConfig.totalItems = this.departmentProfileTotalRecords;
  }

}
