export class GalleryDetailModel {
  
}
export class GalleryDetailRequestModel {
  id: number;
}
export class ProgramGalleryListRequestModel {
  page: number;
  text: string;
}
