import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rti',
  templateUrl: './rti.component.html',
  styleUrls: ['./rti.component.css']
})
export class RtiComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
