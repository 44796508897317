import { IndexModel } from "./awards-request-model.model";

// export class PressReleaseModel {
// }
export class PressReleaseRequestModel {
  id: number;
}


export class PressReleaseModel {
  ProgramId: number;
  ProgramCategoryId: number;
  ProgramSubCategoryId: number;
  ProgramDate: string;
  ProgramDistrictId: number;
  ProgramLocationId: number;
  ProgramAddress: string;
  ProgramAddressHindi: string;
  ProgramLanguage: string;
  ProgramSubject: string;
  ProgramSubjectHindi: string;
  ProgramSpeech: string;
  ProgramSpeechHindi: string;
  Keywords: string;
  KeywordsHindi: string;
  SpeechPdf: string;
  Pdf: string;
  ProgramStateId: number;
  ProgramTime: string;
  UserId: string;
  IsShowLatest: string;
  KeyImage: string;
  FolderName: string;
  PdfEnglish: string;
  District: string;
  ProgramCategory: string;
  ProgramCategoryhindi: string;
  ProgramSubCategoryhindi: string;
  ProgramSubCategory: string;
  ProgramDateHindi: string;
  BannerImages: string;

}


export class LatestEventPressReleaseModel {
  ProgramId: number;
  ProgramCategoryId: number;
  ProgramSubCategoryId: number;
  ProgramDate: string;
  ProgramDistrictId: number;
  ProgramLocationId: number;
  ProgramAddress: string;
  ProgramAddressHindi: string;
  ProgramLanguage: string;
  programSubject: string;
  programSubjectHindi: string;
  ProgramSpeech: string;
  ProgramSpeechHindi: string;
  Keywords: string;
  KeywordsHindi: string;
  SpeechPdf: string;
  Pdf: string;
  IsActive: boolean;
  IsDeleted: boolean;
  ProgramStateId: number;
  ProgramTime: string;
  UserId: string;
  IsShowLatest: string;
  KeyImage: string;
  FolderName: string;
  PopupImages: string;
  PdfEnglish: string;
  Status: string;
  District: string;
  ProgramCategory: string;
  ProgramCategoryhindi: string;
  ProgramSubCategoryhindi: string;
  ProgramSubCategory: string;
  TotalEvents: number;
  ProgramDateHindi: string;
  BannerImages: string;
}

export class PressReleaseFrontSearchModel extends IndexModel {
  DepartmentCode: number;
  DistrictDepartmentCode: number;
  DistrictCode: number;
  CategoryCode: number;
  SubCategoryCode: number;
  IsBase64File: boolean;
  VIPCategoryCode: string;
  VIPPersonCode: number;
  EntryNumber: number;
  ToDate: string | Date;
  FromDate: string | Date;
  PressReleaseFromDate: string;
  PressReleaseToDate: string;
  DeptValue: string[];
  DistValue: string[];
  IsLokarpanInauguration: boolean = false;
  CMOPRCategoryCode: number;
  PressReleaseLevelCode: number;
  SubCat_GECatTypeCode: number;
  GroupType = '';
  IsHomePageUrl: number;
  Description: string;
  SearchKeyword: string;
  Search: string;
  
  constructor() {
    super();
    this.DistrictDepartmentCode = 0;
    this.PageSize = 9;
    this.SubCategoryCode = 0;
    this.DepartmentCode = 0;
    this.CategoryCode = 0;
  }
}

export class InAugrationListModel extends IndexModel {
  Id: number;
  Code: number;
  CategoryCode: number;
  SubCategoryCode: number;
  GeneralDescription: any;
  Description: string;
  URL: any;
  DisplayOrder: number;
  PDFUrl: string;
  ImageUrl: string;
  HomePageImageUrl: string;
  KeyWords: any;
  CreatedDate: Date | string;
  ModifiedDate: Date | string;
  CategoryName: string;
  CategoryNameHindi: string;
  SubCategoryName: string;
  SubCategoryNameHindi: string;
  PressReleaseLevel: string;
  PressReleaseLevelHindi: string;
  DepartmentTitle: string;
  DepartmentTitleHindi: string;
  DistrictNameHindi: string;
  DistrictName: string;
  VIPCategory: string;
  VIPCategoryHindi: string;
  VIPPersion: any;
  VIPPersionHindi: any;
  Attachments: any[];
  ImageAttachments: any[];
  TotalRecords: number;
  CommonCategoryCode: any;
  PressReleaseTime: any;
  PressreleaseDate: any;
  PressReleaseDateHindi: string;
}