import { MediaService } from './../services/media.services';
import { Component, OnInit, Inject } from '@angular/core';
import { TranslateService } from '../translate.service';
import { PagerService } from '../pager.service';
import { InAugrationListModel, PressReleaseFrontSearchModel } from '../Model/press-release-model.model';
import { NewsService } from '../services/news.services';
import { DepartmentCodeEnum, PressReleaseCategoryTypeEnum } from '../Enum/app-enums';
import { PressReleaseSubCategoryEnum, VIPCategoryEnum, VIPPersonCategoryEnum } from '../Enum/jankayan.enum';
import { CommonService } from '../services/common.service';
@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css'],
  providers: [MediaService,NewsService]
})
export class MessageComponent implements OnInit {
  public Messageprograms: MessageModel[];
  pager: any = {};
  pagedMessageItems: InAugrationListModel[];
  totalPage: number;
  currentPage: number;

  //Jankalyan API model
  generalModel: InAugrationListModel[];
  filterModel = new PressReleaseFrontSearchModel();
  totalRecords = 0;
  pagerConfig: any;
  categoryCode: number = 0;
  DepartmentCodeEnum: DepartmentCodeEnum;
  // isCodeOfConduct: boolean = true;
  isCodeOfConduct: boolean = false;

  constructor(@Inject('BASE_URL') public baseUrl: string,
    private pagerService: PagerService, public translate: TranslateService,
    private mediaService: MediaService,
    private newsService: NewsService,
    public _commonService: CommonService) {
    this.isCodeOfConduct = this._commonService.GetIsCodeOfConduct();
    this.pagedMessageItems = [];
    this.currentPage = 1;
    this.totalPage = 1;
  }

  ngOnInit() {
    //this.getdata(this.currentPage);
    this.GetPressReleaseData(this.currentPage);
  }
  setLang(lang: string) {
    this.translate.use(lang);
  }
  public getdata(page: number) {

    this.currentPage = page;
    this.mediaService.GetProgramMessageListForFront(page).subscribe(success => {
      if (success !== undefined && success !== null) {
        this.Messageprograms = success;
        this.totalPage = Number(this.Messageprograms[0].TotalMessages);
        this.setPage(page);
      }
    }, error => {
      console.error(error);
    });
  }
  setPage(page: number) {
    window.scroll(0, 0);
    this.currentPage = page;
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    // get pager object from service
    this.pager = this.pagerService.getPager(Number(this.generalModel[0].TotalRecords), page);
    // get current page of items
    this.pagedMessageItems = this.generalModel;
  }



  //Jankalyan API
  GetPressReleaseData(page: number) {
    this.currentPage = page;
    this.filterModel.Page = page;
    this.generalModel=undefined;
    this.filterModel.IsBase64File = false;
    this.filterModel.DepartmentCode = DepartmentCodeEnum.CmoWebsiteCode;
    this.filterModel.CategoryCode = PressReleaseCategoryTypeEnum.PressReleaseCode;
    this.filterModel.VIPCategoryCode = VIPCategoryEnum.HCMCode;
    // this.filterModel.VIPPersonCode = VIPPersonCategoryEnum.ShriAshokGehlotCode;
    this.filterModel.VIPPersonCode = VIPPersonCategoryEnum.ShriBhajanLalSharmaCode;
    this.filterModel.SubCategoryCode =  PressReleaseSubCategoryEnum.DayMessageCode ;
    this.filterModel.OrderBy = "PressreleaseDate";

    this.newsService.GetPressReleaseList(this.filterModel).subscribe((response) => {
      if (response.IsSuccess) {
        this.generalModel = response.Data.Data as InAugrationListModel[];        
        this.totalRecords = response.Data.TotalRecords;
        this.setPage(page);
      }else{
        this.generalModel=[]
      }
    }, (error) => {
      this.generalModel=[]
      console.error(error);
    });
  }
}


interface MessageModel {
  ProgramId: number;
  ProgramCategoryId: number;
  ProgramSubCategoryId: number;
  ProgramDate: string;
  ProgramDateHindi: string;
  ProgramDistrictId: number;
  ProgramLocationId: number;
  ProgramAddress: string;
  ProgramAddressHindi: string;
  ProgramLanguage: string;
  ProgramSubject: string;
  ProgramSubjectHindi: string;
  ProgramSpeech: string;
  ProgramSpeechHindi: string;
  Keywords: string;
  KeywordsHindi: string;
  SpeechPdf: string;
  Pdf: string;
  IsActive: boolean;
  IsDeleted: boolean;
  ProgramStateId: number;
  ProgramTime: string;
  UserId: string;
  IsShowLatest: string;
  KeyImage: string;
  FolderName: string;
  PopupImages: string;
  PdfEnglish: string;
  Status: string;
  Times: string;
  Type: string;
  ProgramSubCategory: string;
  ProgramSubCategoryhindi: string;
  TotalMessages: number;

}

