import { Component, ElementRef, EventEmitter, HostListener, Output, Renderer2, ViewChild } from '@angular/core';
import { TranslateService } from '../translate.service';
import { DatePipe } from '@angular/common';
import { environment } from '../../environments/environment';
import { DepartmentDetailModel, DepartmentMenuListModel, DepartmentProfileFilterModel, DepartmentProfileViewModel, GenerateSubMenuListForDepartmentModel, PageTitleModel } from '../Model/department-model.model';
import { EntryTypeEnum, JankalyanCategoryEnum, SingleEntryDataType } from '../Enum/jankayan.enum';
import { DepartmentCodeEnum } from '../Enum/app-enums';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { CommonService } from '../services/common.service';
import { DepartmentService } from '../services/department.service';
import { CMprofileViewModel } from '../Model/cmprofile-view-model';
import { ChiefMnisterProfileService } from '../services/cm-profile-service';
import { LookupEnum } from '../Enum/lookup-type.enum';
import { Router } from '@angular/router';
import { CmLiveProgramsService } from '../services/cm-live-programs.service';
import { CMLiveProgram } from '../Model/cm-live-programs.model';
declare var $: any;
@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {

  currentDate = new Date();
  currentDt: string;
  currentDateHindi: string;
  monthNames: string[];
  monthNamesEng: string[];
  Month: string;
  @Output() fontSize = new EventEmitter<number>();
  currentFontSize: number = 14;
  fileUrl = environment.imageUrl + 'publicpdf1/Work_Distribution.pdf';
  pageTitle: PageTitleModel = new PageTitleModel();
  departmentProfileModel: DepartmentProfileViewModel[];
  dataModel = new DepartmentProfileViewModel();
  departmentProfileFilterModel = new DepartmentProfileFilterModel();
  entryDataType = SingleEntryDataType;
  IsLoading = true;
  departmentProfileTotalRecords = 0;
  DepartmentCodeEnum: DepartmentCodeEnum;
  model: CMprofileViewModel = new CMprofileViewModel();
  dptModel = new DepartmentDetailModel();
  menuListModel: DepartmentMenuListModel = new DepartmentMenuListModel();
  enumdata = LookupEnum;
  CMLiveProgramsModel = new CMLiveProgram();
  CMLiveProgramsData: any = [];
  YouTubeUrl = "https://www.youtube.com/embed/";
  videoID: SafeResourceUrl;
  @ViewChild('toggleDiv') toggleDiv!: ElementRef;
  
  constructor(
    public translate: TranslateService,
    public sanitizer: DomSanitizer,
    public readonly commonService: CommonService,
    private readonly departmentProfile: DepartmentService,
    private readonly cmprofileService: ChiefMnisterProfileService,
    private readonly cmLivePrograms: CmLiveProgramsService,
    public router: Router,
    private renderer: Renderer2
  ) {
    this.monthNames = ['जनवरी', 'फरवरी', 'मार्च', 'अप्रैल', 'मई', 'जून', 'जुलाई', 'अगस्त', 'सितंबर', 'अक्टूबर', 'नवंबर', 'दिसंबर'];
    this.monthNamesEng = ['January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'];
    this.Month = this.monthNames[(new Date()).getMonth()];
    const datePipe = new DatePipe('en-US');
    this.currentDt = datePipe.transform(this.currentDate, 'MMMM dd yyyy');
    this.currentDt.toString().replace(this.monthNamesEng[(this.currentDate).getMonth()], this.Month.toString())
    this.currentDateHindi = this.currentDt.toString().replace(this.monthNamesEng[(this.currentDate).getMonth()], this.Month.toString());
  }

  ngOnInit() {
    //#region Get Department Menu List from Subject Behaviour
    this.departmentProfile.getDeptMenuList().subscribe(data => {
      if (data) {
        this.menuListModel = data;
        this.menuListModel.MenuList.sort((a, b) => {
          const displayOrderA = a.DisplayOrder !== null && a.DisplayOrder !== undefined ? a.DisplayOrder : Number.MAX_SAFE_INTEGER;
          const displayOrderB = b.DisplayOrder !== null && b.DisplayOrder !== undefined ? b.DisplayOrder : Number.MAX_SAFE_INTEGER;         
          return displayOrderA - displayOrderB;
        });
      }
    });
    //#endregion
    this.GetCMProfile();
    this.getDepartmentProfileListData();
    this.departmentProfile.departmentModel.subscribe((res) => {
      this.dptModel = res;
    })
    setTimeout(() => {
      //   this.changeTheme();
      this.SidebarBtn();
    }, 10);
    this.CMLiveProgram();
  }

  getSubMenuList(mainMenuCode: number): GenerateSubMenuListForDepartmentModel[] {
    return this.menuListModel.SubMenuList.filter(x => x.DepartmentMainMenuCode == mainMenuCode).sort((a, b) => {
      if (a.DisplayOrder === null || a.DisplayOrder === undefined) return 1;
      if (b.DisplayOrder === null || b.DisplayOrder === undefined) return -1;
      return a.DisplayOrder - b.DisplayOrder;
    });;
  }

  getSubSubMenuList(SubMenuCode) {
    return this.menuListModel.SubSubMenuList.filter(x => x.DepartmentSubMenuCode == SubMenuCode).sort((a, b) => {
      if (a.DisplayOrder === null || a.DisplayOrder === undefined) return 1;
      if (b.DisplayOrder === null || b.DisplayOrder === undefined) return -1;
      return a.DisplayOrder - b.DisplayOrder;
    });;
  }

  @HostListener('document:keydown.escape', ['$event'])
  onEscapePress(event: KeyboardEvent) {
    this.renderer.removeClass(this.toggleDiv.nativeElement, 'active');  
  }

  setLang(lang: string) {

    this.translate.use(lang);
  }
  setFontSize(size: number, mode: string) {
    this.currentFontSize = localStorage.getItem("fontSize") != null ? Number(localStorage.getItem("fontSize")) : this.currentFontSize;
    if (mode === '+' && this.currentFontSize < 17) { //increment
      this.currentFontSize += size;
    }
    else if (mode === '-' && this.currentFontSize > 11) {  //for decrement
      this.currentFontSize += size;
    }
    else if (mode === '=') {  //for reset
      this.currentFontSize = size;
    }

    this.fontSize.emit(this.currentFontSize);

  }

  //Jankalyan API Start
  getDepartmentProfileListData() {
    this.dataModel = undefined;
    this.departmentProfileFilterModel.IndexModel.PageSize = 1;
    this.departmentProfileFilterModel.DepartmentCode = DepartmentCodeEnum.CmoWebsiteCode;
    this.departmentProfileFilterModel.JankalyanCategoryCode = JankalyanCategoryEnum.AboutDepartmentDistrictCode;
    this.departmentProfileFilterModel.EntryTypeCode = EntryTypeEnum.WorkDistributionCode

    this.departmentProfile.GetList(this.departmentProfileFilterModel).subscribe((data: any) => {

      if (data.Data) {
        this.departmentProfileModel = data.Data.Data as DepartmentProfileViewModel[];
        if (this.departmentProfileModel && this.departmentProfileModel.length == 1) {
          this.dataModel = this.departmentProfileModel[0];
          this.pageTitle.NameEnglish = this.pageTitle.NameEnglish ? this.pageTitle.NameEnglish : this.dataModel.EntryTypeName;
          this.pageTitle.NameHindi = this.pageTitle.NameHindi ? this.pageTitle.NameHindi : this.dataModel.EntryTypeNameHindi;
          if (this.dataModel.PDFURL) {
            this.dataModel.PDFURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.dataModel.PDFURL);
          }
          if (this.dataModel.DataTypeCode == this.entryDataType.URL && !this.dataModel.Details) {
            window.open(this.dataModel.url, "_blank");
            this.commonService.gotoPreviousPage();
          }
        }
        else {
          this.dataModel = new DepartmentProfileViewModel();
        }
        if (!this.departmentProfileFilterModel.IndexModel.IsPostBack) {
          this.departmentProfileTotalRecords = data.Data.TotalRecords || 0;
        }
        //this.departmentProfileUpdatePageConfig();
      }
      else {
        this.dataModel = {} as DepartmentProfileViewModel;
      }
      this.IsLoading = false;
    });
  }

  sanitizeImageUrl(imageUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }

  GetCMProfile() {
    this.cmprofileService.GetCMProfileDetail().subscribe(response => {
      if (response.IsSuccess) {
        this.model = response.Data as CMprofileViewModel;
      }
    }, error => { });

  }

  CMLiveProgram() {
    this.CMLiveProgramsModel.AdvanceSearchModel.CategoryCode = "126";
    this.CMLiveProgramsModel.AdvanceSearchModel.SubCategoryCode = 0;
    this.CMLiveProgramsModel.AdvanceSearchModel.DepartmentCode = "85";
    this.CMLiveProgramsModel.PageSize = 1;
    this.CMLiveProgramsModel.IsPostBack = false
    this.CMLiveProgramsModel.OrderByAsc = 0
    this.CMLiveProgramsModel.OrderBy = "AchievementDATE";
    this.CMLiveProgramsModel.Page = 1;
    this.cmLivePrograms.GetCMLivePrograms(this.CMLiveProgramsModel).subscribe((res: any) => {
      if (res.Data.Data.length) {
        this.CMLiveProgramsData = res.Data.Data[0];
        // const parts = this.CMLiveProgramsData.YoutubeURL.split('/');
        // const videourl = parts[parts.length - 1];
        // const embedUrl = `${this.YouTubeUrl}${videourl}`
        // this.videoID = this.sanitizeUrl(embedUrl);
        const videourl = this.commonService.getYoutubeEmbedUrl(this.CMLiveProgramsData.YoutubeURL);
        this.videoID = this.commonService.SafeUrl(videourl);
        // console.log(this.CMLiveProgramsData,"CMLiveProgramsData",this.videoID)
      }
    })
  }

  sanitizeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  SidebarBtn() {
    $(".new-menu-icon").click(function () {
      $("body").toggleClass("sidebar-open");
    });

    $(".sidemenu-close, ul.side-mainmenu li a").click(function () {
      $("body").removeClass("sidebar-open");
    });
  }


  menuClicked(val: any) {
    var element = document.getElementById("mainMenuId");
    element.classList.toggle("active");
  }


}



$(window).scroll(function () {
  if ($(this).scrollTop() > 50) {
    $('#dynamic').addClass('newClass');
  } else {
    $('#dynamic').removeClass('newClass');
  }
});




