import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { IDictionary } from './../common/dictionary';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable()
export class BaseService {
  headers: HttpHeaders;
  public constructor(public httpclient: HttpClient) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache'
    });
  }

  get(endPoint: string, params?: IDictionary<string>) {

    let httpParams: HttpParams;
    if (params) {
      httpParams = this.buildParams(params);
    }
    let httpHeaders = new HttpHeaders()
      .set('Accept', 'application/json').set('Cache-Control', 'no-cache').set('Pragma', 'no-cache');
    return this.httpclient.get(endPoint, { headers: httpHeaders, params: httpParams }).pipe(map((response: Response) => {
      return JSON.parse(JSON.stringify(response));
    }));

  }


  //post(endPoint: string, requestObject: any) {
  //  // tslint:disable-next-line:max-line-length
  //  return this._httpclient.post(endPoint, requestObject, { headers: { 'Accept': 'application/*' } }).pipe(map(res => JSON.parse(JSON.stringify(res))));
  //}


  post(endPoint: string, requestObject: any) {

    return this.httpclient.post(endPoint, requestObject, { headers: this.headers }).pipe(map((response: Response) => {
      return JSON.parse(JSON.stringify(response));
    }));
  }

  /**
   * put
   */
  //public put(endPoint: string, requestObject: any) {
  //  return this.httpclient.put(endPoint, requestObject).map(res => JSON.parse(JSON.stringify(res)));
  //}
  public put(endPoint: string, requestObject: any) {
    return this.httpclient.put(endPoint, requestObject).pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  //public delete(endPoint: string, params?: IDictionary<string>) {

  //  let httpParams;

  //  if (params) {
  //    httpParams = this.buildParams(params);
  //  }


  //  return this.httpclient.delete(endPoint, httpParams).map(res => JSON.parse(JSON.stringify(res)));
  //}

  public Delete(endPoint: string, params?: IDictionary<string>) {

    let httpParams;

    if (params) {
      httpParams = this.buildParams(params);  }
    return this.httpclient.delete(endPoint, httpParams).pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  /**
   * buildParams - Converts from Dictionary to HttpParams
   */
  public buildParams(params: IDictionary<string>): HttpParams {
    let httpParams = new HttpParams();

    if (params) {
      const keys: string[] = params.Keys();

      keys.forEach(key => {
        httpParams = httpParams.append(key, params.Item(key));
      });
    }

    return httpParams;
  }

}
