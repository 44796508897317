import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { environment } from '../../environments/environment';
import { PagerService } from '../pager.service';
import { TranslateService } from '../translate.service';
import { IndexModel } from '../Model/awards-request-model.model';
import { DepartmentPageDetailsService } from '../services/department-page-details.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-ministerlist',
  templateUrl: './ministerlist.component.html',
  styleUrls: ['./ministerlist.component.css'],
  providers:[DepartmentPageDetailsService]
})
export class MinisterlistComponent implements OnInit {

  ministerprograms: MinisterModel[];
  ministerChiefMinisterprograms: MinisterModel[];
  ministerCabinetMinistersprograms: MinisterModel[];
  ministerStateMinisterIndependentprograms: MinisterModel[];
  ministerStateMinisterprograms: MinisterModel[];
  indexModel: IndexModel = new IndexModel();
  model: MLAConstituencyListModel[];
  departmentCode:any;
  designationCode:any;
  groupByDirectories: any;

  constructor(public http: HttpClient, @Inject('BASE_URL') public baseUrl: string,
  private pagerService: PagerService, public translate: TranslateService, private readonly route: ActivatedRoute, private departmentService : DepartmentPageDetailsService, public sanitizer: DomSanitizer, public readonly _commonService: CommonService,) {
    this.ministerprograms = [];
    this.ministerChiefMinisterprograms = [];
    this.ministerCabinetMinistersprograms = [];
    this.ministerStateMinisterIndependentprograms = [];
    this.ministerStateMinisterprograms = [];

  }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      let _deptCode = this.route.snapshot.params.dptCode;
      this.departmentCode = Number(_deptCode || 0);

      let _designationCode = this.route.snapshot.params.designationCode;
      this.designationCode = Number(_designationCode || 0);

      this.GetList();
    });
    // this.getdata();
  }
  setLang(lang: string) {
    this.translate.use(lang);
  }

  GetList() {
    this.indexModel.PageSize = 101
    this.indexModel.OrderByAsc = 1;
    this.indexModel.AdvanceSearchModel = {
      DepartmentCode: this.departmentCode,
      DesignationCode: this.designationCode
    };

    this.departmentService.GetMLAConstituency(this.indexModel).subscribe((response: any) => {
      if (response.IsSuccess) {
        this.model = response.Data.Data as MLAConstituencyListModel[];

        if (this.model && this.model.length > 0) {
          this.model.forEach(item => {
            if (item.DesignationDisplayName) {
              item.DesignationDisplayNameHtml = item.DesignationDisplayName.split(',').join(', <br>');
            }
            if (item.DesignationDisplayNameHindi) {
              item.DesignationDisplayNameHindiHtml = item.DesignationDisplayNameHindi.split(',').join(', <br>');
            }
          });
        }

        if(this.designationCode == 0)this.setGroupOnData(this.model);
      }
      else {
        this.model = [];
      }
    }, (error: any) => {
      this.model = [];
    });
  }

  setGroupOnData(records: MLAConstituencyListModel[]) {
    this.groupByDirectories = undefined;
    if (records && records.length > 0) {
      records = records.filter((item) => item.DesignationName && item.DesignationName != '');
      this.groupByDirectories = this._commonService.groupBy(records, (x: any) => x.DesignationName);
      this.groupByDirectories = [...new Map([...this.groupByDirectories])];
    }
  }

  sanitizeImageUrl(imageUrl: any): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl || "");
  }

  public getdata() {

    this.http.get<MinisterModel[]>(this.baseUrl + 'api/Home/GetMinisters').subscribe(result => {

      this.ministerprograms = result;
      this.ministerprograms.forEach(obj => {
        if (obj.cabinetType === '1') {
          this.ministerChiefMinisterprograms.push(obj);
        } else if (obj.cabinetType === '2') {
          this.ministerCabinetMinistersprograms.push(obj);
        } else if (obj.cabinetType === '3') {
          this.ministerStateMinisterIndependentprograms.push(obj);
        }
      });

    }, error => console.error(error));
  }
}

interface MinisterModel {

  CabinetId: number;
  CabinetName: string;
  CabinetNameHindi: string;
  CabinetDepartment: string;
  CabinetDepartmentHindi: string;
  CabinetAddress: string;
  CabinetAddressHindi: string;
  CabinetRoom: string;
  CabinetRoomHindi: string;
  PhoneOffice: string;
  PhoneResidence: string;
  PhoneAssembly: string;
  CabinetOrder: string;
  CabinetTitleId: string;
  cabinetType: string;
  IsActive: string;
  IsDeleted: string;
  Url: string;
  Status: string;
  MinisterType: string;

}

export interface MLAConstituencyListModel {
  Id: number;
  Name: string;
  NameHindi: string;
  Latitude: string;
  Longitude: string;
  MAPImagePath: string;
  MLANameEng: string;
  MLANameHindi: string;
  MLAPhoto: string;
  ShortName: string;
  DesignationDisplayName: string;
  DesignationDisplayNameHindi: string;
  DesignationName: string;
  DesignationHindi: string;
  DistrictTitle: string;
  DistrictTitleHindi: string;
  MLACode: number;
  DisplayOrder: number | null;
  Email: string;
  whatsappNumber: string;

  DesignationDisplayNameHtml: string;
  DesignationDisplayNameHindiHtml: string;
}
