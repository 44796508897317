import { Injectable } from '@angular/core';
import { AppSettings } from '../common/AppSettings';
import { Dictionary } from '../common/dictionary';
import { BaseService } from "./baseservice";

@Injectable({
  providedIn: 'root'
})
export class ChiefMnisterProfileService {
  public constructor(private httpService: BaseService) {
  }
  GetCMProfileDetail(): any {
    const param = new Dictionary<any>();
    param.Add("isbase64File", false);
    return this.httpService.get(AppSettings.API_ENDPOINT_Jankalyan + AppSettings.jankalyanApi.CMProfile.GetCMProfileDetailurl, param);
  }
}