import { AchievementService } from './../services/Achievement.services';
import { Component, OnInit, Inject, Input } from '@angular/core';
import { PagerService } from '../pager.service';
import { TranslateService } from '../translate.service';
import { environment } from '../../environments/environment';
import { AchievementAllSearchModel, AchievementViewModel } from '../Model/awards-request-model.model';
import { AchievementCategoryEnum } from '../Enum/achievement-category.enum';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DepartmentCodeEnum } from '../Enum/app-enums';
import { CommonService } from '../services/common.service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-awards',
  templateUrl: './awards.component.html',
  providers: [AchievementService,DatePipe]
})
export class AwardComponent implements OnInit {
  @Input() homepage: boolean = false;
  isHome = this.homepage != undefined ? this.homepage : false;
  environmentUrlPDF = environment.imageUrl + 'Publicpdf/';
  environmentUrlImage = environment.imageUrl + 'Images/AwardsImages/';
  achievementEnum = AchievementCategoryEnum;
  pager: any = {};
  pagedAwardprograms: AwardsModel[];
  awardModelFromJankalyan: AchievementViewModel[];
  pagedAwardModelFromJankalyan: AchievementViewModel[];
  totalRecord: number;
  currentPage: number;
  pageSize = 12;
  DepartmentCodeEnum: DepartmentCodeEnum;
  filterModel: AchievementAllSearchModel = new AchievementAllSearchModel();
  totalRecords = 0;
  today = new Date();
  fromDate:any;
  toDate:any;
  days = 10; // Days you want to subtract
  date = new Date();
  lastDate = new Date('2023-12-15') //new Date(this.date.getTime() - (this.days * 24 * 60 * 60 * 1000));
  searchText:any = '';
  // isCodeOfConduct: boolean = true;
  isCodeOfConduct: boolean = false;
  pagerConfig: any = {
    id: "data-paging",
    itemsPerPage: this.filterModel.IndexModel.PageSize,
    currentPage: this.filterModel.IndexModel.Page,
    totalItems: this.totalRecords,
  };
  awardsModel:any=[]

  constructor(@Inject('BASE_URL') public baseUrl: string,
    private achievementService: AchievementService,
    private pagerService: PagerService,
    public readonly translate: TranslateService,
    public sanitizer: DomSanitizer,
    public _commonService: CommonService,
    private datePipe: DatePipe
  ) {
    this.isCodeOfConduct = this._commonService.GetIsCodeOfConduct();
    this.pagedAwardModelFromJankalyan = [];
    this.currentPage = 1;
  }

  ngOnInit() {
    this.fromDate = this.datePipe.transform(this.lastDate,'yyyy-MM-dd')
    this.toDate = this.datePipe.transform(this.today,'yyyy-MM-dd')
    this.getAwardsDataFromJankalyan(this.currentPage,this.pageSize);
  }

  setLang(lang: string) {
    this.translate.use(lang);
  }

  sanitizeImageUrl(imageUrl: string): SafeUrl {

    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }

  onPageSize(evt:any){
    this.pageSize = evt.target.value
    this.getAwardsDataFromJankalyan(this.currentPage,this.pageSize)
  }

  onSearch(){
    this.getAwardsDataFromJankalyan(this.currentPage,this.pageSize)
  }

  getAwardsDataFromJankalyan(page: number,pagesize:any) {
    this.currentPage = page;
    this.filterModel.IndexModel.OrderBy = "AchievementDate";
    this.filterModel.IndexModel.OrderByAsc = 0;
    this.filterModel.IndexModel.Page = page;
    this.filterModel.IndexModel.PageSize = pagesize;
    this.filterModel.ToDate = this.toDate;
    this.filterModel.FromDate = this.fromDate;
    this.filterModel.SearchKeyword = this.searchText;
    this.filterModel.CategoryCode = this.achievementEnum.StateAwardsCode;
    this.achievementService.GetAchievementListBySearchFilterData(this.filterModel).subscribe(success => {
      if (success.IsSuccess) {
        this.awardModelFromJankalyan = success.Data.Data;
        if (!this.filterModel.IndexModel.IsPostBack) {
          this.totalRecords = success.Data.TotalRecords;
          this.setPage(page,pagesize);
        }
      }
    }, error => {
      console.error(error);
    });
  }

  setPage(page: number,pagesize:any) {
    window.scroll(0, 0);
    this.currentPage = page;
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    this.pager = this.pagerService.getPager(this.totalRecords, page, pagesize);
    this.pagedAwardModelFromJankalyan = this.awardModelFromJankalyan
  }

}
class AwardsModel {
  AwardId: number;
  Subject: string;
  SubjectHindi: string;
  PdfFIleName: string;
  Image: string[];
  AwardDate: string;
  AwardDateHindi: string;
  TotalRecord: number;
  Active: boolean;
}


