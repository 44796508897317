<!-- <div class="bg-scroll pos-rel breadcrumbs-page-img">
  <div class="container">


    <h1>{{'Home_Our_Video'| translate}}</h1>
   
  </div>
</div> -->
<div class="breadcrumbs-page-img">
  <div class="banner-hight-2">
    <h1>{{'Home_Our_Video'| translate}}</h1>
    <div class="inner-page-banner">
      <img src="assets/images/cmo-banner.png" alt="banner" class="cmo-banner">
    </div>
  </div>
</div>
<main id="main">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
      <li class="breadcrumb-item" aria-current="page"><a href="javascript:void(0)">{{'Media_CornerSubNav'| translate}}</a></li>
      <li class="breadcrumb-item active" aria-current="page" *ngIf="isSpeeches">{{'Nav_Speech'| translate}}</li>
      <li class="breadcrumb-item active" aria-current="page" *ngIf="!isSpeeches">{{'Video_Gallery'| translate}}</li>
    </ol>
  </nav>
  <nav class="navbar justify-content-between m-1">
    <a class="navbar-brand"></a>
    <div class="form-inline">
      <div class="text-start">
        <label>From Date</label>
        <input class="form-control mr-sm-2" type="date" name="" id="" [(ngModel)]="fromDate">
      </div>
      <div class="text-start">
        <label>To Date</label>
        <input class="form-control mr-sm-2" type="date" name="" id="" [(ngModel)]="toDate">
      </div>
      <div class="text-start">
        <label>Search</label>
        <input class="form-control mr-sm-2" [(ngModel)]="searchText" type="search" placeholder="Search" aria-label="Search">
      </div>
      <div class="search-btn">
        <label>&nbsp;</label> 
        <button class="btn btn-outline-primary mr-sm-2" type="submit" (click)="onSearch()"><i class="fa fa-search"></i></button>
      </div>
    </div>
  </nav>
  <section class="video">
    <div class="container">
      <div class="row mb-4  no-gutters">
        <!-- <div class="col-xl-3" *ngFor="let video of pagedItems">
          <div class="video_bx">
            <a href="javascript:void(0)" (click)="openModalDialog(this.videoUrl+video.video)">
              <img src={{this.imageUrl}}{{video.videoimage}} alt="" class="img-fluid"> <i class="fa fa-play" aria-hidden="true"></i>
              <div class="video_title">{{this.translate.currlang == 'hi' ? video.ProgramSubjectHindi : video.ProgramSubject}}</div>
            </a>
          </div>
        </div> -->
        <div class='col-sm-6 col-xs-6 col-md-4 col-lg-4 mb-4' *ngFor="let items of CMLiveProgramsData; let i = index">
          <a [href]="items.videoUrl" target="_blank">
          <div class="card wow uploader-gallery-list-items"
              [ngClass]="[i%3 <1 ? 'fadeInLeft' : i%3 <2? 'fadeInUp':i%3 <3? 'fadeInRight':'fadeInLeft' ]">
              <span class="uploader-gallery-items-date">{{this.translate.currlang == 'hi' ?
                  items.AchievementDateHindi :
                  (items.AchievementDate | date : 'd MMM, y')}}</span>

                  <div class="embed-responsive embed-responsive-21by9">
                      <!-- <video #videoPlayer{{i}} tabindex="-1" (playing)="onMediaPlaying($event, false)"
                          id="my_video_{{i}}" (mouseover)="playVideo($event)" (mouseout)="pauseVideo($event)"
                          class="embed-responsive-item" width="100%" height="100%" controls preload="none"
                          data-setup='{ "aspectRatio":"100%:100%", "playbackRates": [1, 1.5, 2] }'
                          disablePictureInPicture
                          controlslist="nodownload noremoteplayback noplaybackrate foobar">
                          <source src="{{items.VideoPath}}"
                              type="video/{{items.VideoPath.split('.')[items.VideoPath.split('.').length-1]}}">
                      </video> -->
                      <iframe class="live-video2" width="100%" height="100%"
                        [src]="items.videoUrl" frameborder="0" style="aspect-ratio: 16/9;"
                        allow="accelerometer; autoplay; gyroscope; picture-in-picture" allowfullscreen> </iframe>
                  </div>
              <div class="gallery_caption">
                  <h3 *ngIf="(this.translate.currlang == 'hi' && items.DescriptionHindi?.length<=100 )||(this.translate.currlang != 'hi' && items.Description?.length<=100)">
                      {{this.translate.currlang == 'hi' ? items.DescriptionHindi : items.Description}}</h3>
                  <h3 *ngIf="(this.translate.currlang == 'hi' && items.DescriptionHindi?.length>100 )||(this.translate.currlang != 'hi' && items.Description?.length>100)">
                      {{(this.translate.currlang == 'hi' ? items?.DescriptionHindi :
                      items?.Description)|slice:0:100}}...</h3>
                  <div class="d-flex justify-content-between">
                      <!-- <div class="date">
                          {{this.translate.currlang == 'hi' ? items.DateHindi : (items.Date | date : 'd MMM, y')}}
                      </div> -->
                      <!-- <div>
                          <a [href]="items.VideoPath" target="_blank" download class="download-button">Download</a>
                      </div> -->
                  </div>
              </div>
          </div>
        </a>
      </div>
      <div class="row" *ngIf="CMLiveProgramsData && CMLiveProgramsData.length == 0">
        <div class="no-record">
          {{'No_Record_Found'| translate}}
        </div>
      </div>
      </div>
      <select name="example_length " aria-controls="example " (change)="onPageSize($event)" [(ngModel)]="pageSize" *ngIf="totalRecords > pageSize"
        class="form-control-sm ng-pristine ng-valid ng-touched" style="width: 70px;">
        <option value="9">9</option>
          <option value="18">18</option>
          <option value="36">36</option>
          <option value="72">72</option>
          <option value="144">144</option>
      </select>
      <nav aria-label="Page navigation example" class="w-100 text-center mt-4" *ngIf="totalRecords > pageSize">

        <ul class="pagination d-inline-flex" *ngIf="pager.pages && pager.pages.length">
          <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
            <a class="page-link" (click)="CMLiveProgram(1,pageSize)">{{'First'| translate}}</a>
          </li>
          <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
            <a class="page-link" (click)="CMLiveProgram(pager.currentPage - 1,pageSize)">{{'Previous'| translate}}</a>
          </li>
          <li class="page-item" *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
            <a class="page-link" (click)="CMLiveProgram(page,pageSize)">{{page}}</a>
          </li>
          <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
            <a class="page-link" (click)="CMLiveProgram(pager.currentPage + 1,pageSize)">{{'Next'| translate}}</a>
          </li>
          <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
            <a class="page-link" (click)="CMLiveProgram(pager.totalPages,pageSize)">{{'Last'| translate}}</a>
          </li>
        </ul>
      </nav>
    </div>
  </section>
</main>
<div class="backdrop" [ngStyle]="{'display':display}"></div>

<!-- modal -->
<div class="modal" tabindex="-1" role="dialog" style="z-index: 99999999999999;" [ngStyle]="{'display':display}">

  <div class="modal-dialog" role="document">

    <div class="modal-content">

      <div class="modal-header">
        <h4 class="modal-title"> {{'Program_Video'| translate}}</h4>

      </div>

      <div class="modal-body">
        <mat-sidenav-container class="sidenav-container">
          <mat-sidenav-content>
            <div class="content video">
              <!-- <mat-video #vid [ngClass]="ngclass" [src]="Url" [title]="title" [autoplay]="autoplay" [preload]="preload"
                         [loop]="loop" [fullscreen]="fullscreen" [download]="download" [quality]="quality" [keyboard]="keyboard"
                         [color]="color" [spinner]="spinner" [poster]="poster" [overlay]="overlay" [(muted)]="muted"
                         [showFrameByFrame]="showFrameByFrame" [(time)]="currentTime">

              </mat-video> -->
            </div>
          </mat-sidenav-content>
        </mat-sidenav-container>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="closeModalDialog()">{{'Close'| translate}}</button>
      </div>
    </div>
  </div>
</div>



