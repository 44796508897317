
    <!-- Filters -->


<div [ngClass]="!isCodeOfConduct ? 'bg-scroll pos-rel' : 'bg-scroll pos-rel breadcrumbs-page-img'">
  <div class="container">
    <h1>{{'Achievement'| translate}}</h1>
  </div>
</div>
<main id="main">
  <section class="program_list_detail page_search"  *ngIf="filterModel.CategoryCode > 0">
    <div class="container">
      <div class="row">
        <div class="col-xl-3 col-sm-6  wow fadeInUp">
          <label>{{'AdminDepartment_txt'|translate}}</label>

          <select class="form-control" [(ngModel)]="filterModel.AdmDepartmentCode"
            (change)="getFilteredDDL('ddlDepartmentImportantDecision',filterModel.AdmDepartmentCode,'ddlAdminDepartment')">
            <option value='0'>--Select--</option>
            <option *ngFor="let item of  dDLList?.ddlAdminDepartment" [ngValue]="item.Value">
              {{item.Text}}
            </option>
          </select>
        </div>

        <div class="col-xl-3 col-sm-6  wow fadeInUp">
          <label>{{'Department_txt'|translate}}</label>

          <select class="form-control" [(ngModel)]="filterModel.DepartmentCode">
            <option value='0'>--Select--</option>
            <option *ngFor="let item of  dDLList?.ddlDepartmentImportantDecision" [ngValue]="item.Value">
              {{item.Text}}
            </option>
          </select>

        </div>


        <div class="col-xl-3 col-sm-6  wow fadeInUp">
          <label>&nbsp;</label>
          <button (click)="OnSearchClick()" class="gray-btn gray-orange btn-block btn-search  wow fadeInUp">{{'Search_btntxt' |translate}}</button>
        </div>

        <div class="col-xl-3 col-sm-6  wow fadeInUp">
          <label>&nbsp;</label>
          <button style="border: none;" class="btn btn-danger  filter-btn d-block w-100 fadeInUp" (click)="reset()">
            <i class="fas fa-redo-alt mr-2"></i> {{'Reset_txt'|translate}}</button>
        </div>
      </div>
    </div>
  </section>


  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
      <li class="breadcrumb-item"><a href="javascript:void(0)">{{'Media_CornerSubNav'| translate}}</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{'Achievement'| translate}}</li>
    </ol>
  </nav>
  <section class="program_list message_cm">



    <div class="container">
      <!-- Heading -->
      <!-- <h1 class="title text-center mb-1">

        <span *ngIf="this.translate.currlang=='hi'">
          {{'Achievement' |translate}}
        </span>

        <span *ngIf="this.translate.currlang !='hi'">
          {{'Achievement' |translate}}
        </span>
      </h1> -->

      <!-- Categories wise count -->
      <div class="row m-0" *ngIf="filterModel.CategoryCode == 0">
        <div class="col-lg-4 mb-3" *ngFor="let item of achievementDepartmentCategoryList let i=index">
          <div class="new-card-design-1">
            <div class="new-card-design-1-header d-flex mb-3 justify-content-between align-items-start">
              <h2 class="m-0">{{ translate.currlang=='hi'? item.CategoryNameHindi:item.CategoryName }}</h2>
              <p class="m-0">{{item.Total}} </p>
            </div>
            <a routerLink="/cmis-achievement/{{item.CategoryCode}}" class="btn btn-new-theme p-1e w-100 mt-auto">
              {{'ViewDetail_txt'|translate}}
            </a>
          </div>
        </div>
      </div>

      <!-- Department wise list -->
      <div *ngIf="filterModel.CategoryCode && departmentList" class="container-fluid p-0" id="programmes">
        <div class="row home_programmes bg-white">
          <div class="col-lg-4 col-12 col-sm-12 col-md-6" *ngFor="let item of departmentList; let i = index;">

            <a class="box pbox wow fadeInLeft" href="javascript:void(0);"
              (click)="getDeptAchievementList(item.DepartmentCode);" data-toggle="modal"
              [attr.data-target]="'#collapse_1'">
              <div class="icon align-self-center">
                <img src="../../assets/images/Achivement_Icon.png" alt="CMO" class="img-fluid" />
              </div>
              <div class="programme-content align-self-center">
                <h4> <a>
                    <p> {{ this.translate.currlang !='en' ? item.DepartmentTitleHindi : item.DepartmentTitle
                      }}</p>
                  </a></h4>
              </div>
              <span class="acievement-total-record">{{item.TotalRecords}}</span>

            </a>

          </div>
          <!-- Popup: Department wise achievement list -->
          <div class="modal fade new-modal-design" id="collapse_1"  tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">

            <div class="modal-dialog modal-lg" role="document">
              <div *ngIf="deptAchievementList && deptAchievementList.length>0" class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">
                    <b>
                      {{ this.translate.currlang !='en' ? deptAchievementList[0]?.DepartmentTitleHindi
                      : deptAchievementList[0]?.DepartmentTitle }}
                    </b>

                  </h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div class="modal-body">
                  <div class="new-achivement d-flex align-items-start mb-3" *ngFor="let ach of deptAchievementList">
                    <div class="new-achivement-icon">
                      <img src="../../assets/images/Achivement_Icon.png" alt="CMO" class="img-fluid" />
                    </div>
                    <div class="new-achivemen-inof">
                      <h3>
                        <pre
                          [innerHTML]="translate.currlang=='en'?
                                                  (ach?.Achievement != null ? (ach?.Achievement) : (ach?.AchievementHindi)) :
                                                  (ach?.AchievementHindi != null ? (ach?.AchievementHindi) : (ach?.Achievement))">
                        </pre>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="row m-0" *ngIf="this.achievementDepartmentCategoryList?.length">
        <div class="col-lg-12 p-0">
          <div class="table-responsive">
            <table class="table table-striped table-bordered table_blue" border="0" cellspacing="0" cellpadding="0"
              width="100%">
              <thead>
                <tr>
                  <th width="70px">{{'Sno'|translate}}</th>
                  <th>{{'AchievementCategory_txt'|translate}}</th>
                  <th style="width: 250px ;">{{'DocumentsCount_txt'|translate}}</th>
                  <th style="width: 180px ;">{{'ViewDetail_txt'|translate}}</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let item of achievementDepartmentCategoryList; let i=index">
                  <tr>
                    <td>{{ i +1}}</td>
                    <td>{{ this.translate.currlang=='en' ? item.CategoryName : item.CategoryNameHindi }}</td>
                    <td>{{ item.Total }}</td>
                    <td><a routerLink="/achivementdetail/{{item.CategoryCode}}"
                        class="gray-btn gray-orange px-3 py-2">{{'ViewDetail_txt'|translate}}</a>
                    </td>
                  </tr>
                </ng-container>

              </tbody>
            </table>
          </div>
        </div>
      </div> -->

    </div>
    <div class="row" *ngIf="noRecords">
      <div class="no-record">
        {{'No_Record_Found'| translate}}
      </div>
    </div>
  </section>
</main>
