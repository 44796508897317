<section class="inner-page-banner">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="page-title">
          Thank You
        </div>
      </div>
    </div>
  </div>
</section>
<section class="form-page inner-page">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="thanks-page">
          <p>
            मुख्‍यमंत्री कार्यालय, राजस्‍थान की वेबसाईट
            <a href="http://cmo.rajasthan.gov.in">http://cmo.rajasthan.gov.in</a> पर न्‍यूज लैटर के सदस्‍य बनने के लिए धन्‍यवाद ! आपके द्वारा बताई गई ईमेल पर इस हेतु एक URL भेजा गया है। न्‍यूज लैटर प्राप्‍त करने के लिए कृप्‍या URL पर क्लिक करें ।
          </p>
          <p>
            Thanks for becoming news letter member of website <a href="http://cmo.rajasthan.gov.in">http://cmo.rajasthan.gov.in</a>  of chief minister office,Rajasthan. For this an URL is sent to the email address mentioned by you. Please click on URL for recieving news letter.
          </p>
          </div>
        </div>
    </div>
  </div>
</section>

