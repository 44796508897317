<section id="programmes" class="four-boxes d-none">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-3 col-6" data-wow-delay="0.2s">
        <div class="box wow fadeInUp boxheight">
          <a routerLink="/calendar" target="_blank">
            <div class="icon">
              <img src="../../assets/img/sm-calendar.png" />
            </div>
            <div>
              <h4 class="text-white">{{'CalendarSubNav'| translate}}<i class="fa fa-chevron-right ml-2"></i></h4>
            </div>
          </a>
        </div>
      </div>
      <!-- <div class="col-lg-3 col-6" data-wow-delay="0.2s">
        <div class="box wow fadeInUp boxheight">
          <a routerLink="/achivement-list" target="_blank">
            <div class="icon">
              <img src="../../assets/img/sm_Achievement.png" />
            </div>
            <div>
              <h4 class="text-white">{{'NavAchievements'| translate}} <i class="fa fa-chevron-right ml-2"></i>
              </h4>
            </div>
          </a>
        </div>
      </div> -->
      <div class="col-lg-3 col-6" data-wow-delay="0.2s">
        <div class="box wow fadeInUp boxheight">
          <a routerLink="/awards" target="_blank">
            <div class="icon">
              <img src="../../assets/img/sm_Award.png" />
            </div>
            <div>
              <h4 class="text-white">{{'NavAwards'| translate}}<i class="fa fa-chevron-right ml-2"></i></h4>
            </div>
          </a>
        </div>
      </div>
      <div class="col-lg-3 col-6" data-wow-delay="0.2s">
        <div class="box wow fadeInUp boxheight">
          <a href="http://cmrelief.rajasthan.gov.in/" target="_blank">
            <div class="icon">
              <img src="../../assets/img/sm_Relif_Fund1.png" />
            </div>
            <div>
              <h4 class="text-white">{{'CM_Relief_FundSubNav'| translate}}<i class="fa fa-chevron-right ml-2"></i>
              </h4>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
