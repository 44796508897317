<section id="topbar" class="top-bar ">
  <div class="contact-info">

    <!-- <span>
      {{'Welcome_txt'| translate}}
    </span> -->
    <a (click)="setLang('hi')" class="">{{'Nav_LanuageHindi'| translate}}</a>
    <a (click)="setLang('en')">{{'Nav_LanuageEnglish'| translate}}</a>
    <!-- <a routerLink="/home"><i class="fa fa-home"></i></a> -->
    <!-- <a href="tel:+91-141-2921063"><i class="fa fa-phone"></i></a> -->
    <a (click)="setFontSize(14,'=')">{{'Font_txt'| translate}}</a>
    <a (click)="setFontSize(-1,'-')">{{'Font_txt'| translate}}-</a>
    <a (click)="setFontSize(1,'+')">{{'Font_txt'| translate}}+</a>
  </div>

  <div class="clearfix"></div>
</section>
<div class="clearfix"></div>
<div>

  <div class="top-add-news d-none" *ngIf="dptModel?.IsShowHeaderIcon && dptModel.HeaderIconPath">
    <a *ngIf="dptModel.HeaderIconURL" [href]="dptModel.HeaderIconURL" target="_blank" class="budget-icon"
      target="_blank" alt="">
      <img alt="" class="img-fluid" [src]="sanitizeImageUrl(dptModel.HeaderIconPath )">
    </a>

    <a *ngIf="!dptModel.HeaderIconURL" routerLink="/pages/assets-list" target="_blank" title="">
      <img alt="" class="img-fluid" [src]="sanitizeImageUrl(dptModel.HeaderIconPath )">
    </a>

  </div>




  <!-- <header id="header" class="sticky-wrapper d-none">
    <div class="container pos-rel">
      <div id="logo" class="pull-left">
        <a routerLink="/home" class="scrollto">
          <img src="assets/img/titlemain.png" alt="Shri Bhajanlal Sharma" class="img-fluid">
        </a>
      </div>

      <nav class="navbar navbar-expand-lg navbar-light shadow-sm">
        <a href="javascript:void(0)" class="navbar-brand font-weight-bold d-block d-lg-none">&nbsp;</a>
        <button type="button" data-bs-toggle="collapse" data-bs-target="#navbarContent" aria-controls="navbars"
          aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="ml-auto d-flex align-items-center">
          <div id="navbarContent" class="collapse navbar-collapse">
            <ul class="navbar-nav">
              <li class="nav-item"><a routerLink="/home" class="nav-link">{{'Nav_Home'| translate}}<span>|</span></a>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="javascript:void(0)" id="navbarDropdownMenuLink"
                  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> {{'CM_OfficeNav'| translate}}
                  <span>|</span></a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <a class="dropdown-item" routerLink="/keyOfficial">{{'Key_OfficialsSubNav'| translate}} </a>
                </div>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="javascript:void(0)" id="navbarDropdownMenuLink"
                  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> {{'State_GovernmentSubNav'|
                  translate}} <span>|</span> </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <li><a class="dropdown-item" target="_blank"
                      href="https://rajasthan.gov.in/pages/sm/department-page/142643/866">{{'NavGovernor'|
                      translate}}</a></li>
                  <li><a class="dropdown-item" target="_blank"
                      href="https://rajasthan.gov.in/pages/sm/department-page/142677/876">{{'Chief_Minister'|
                      translate}}</a></li>
                  <li><a class="dropdown-item" target="_blank"
                      href="https://rajasthan.gov.in/pages/sm/department-page/371534/2402">{{'Deputy_Chief_MinisterFooter'|
                      translate}}</a></li>
                  <li><a class="dropdown-item" target="_blank"
                      href="https://rajasthan.gov.in/pages/sm/assembly-constituency/354894/0/1">{{'Cabinet_MinistersSubNav'|
                      translate}}</a>
                  </li>
                  <li><a class="dropdown-item" target="_blank"
                      href="https://rajasthan.gov.in/pages/sm/assembly-constituency/372005/0/457">{{'State_MinistersIndependantChargeSubNav'|
                      translate}}</a>
                  </li>
                  <li><a class="dropdown-item" target="_blank"
                      href="https://rajasthan.gov.in/pages/sm/assembly-constituency/354895/0/2">{{'State_MinistersSubNav'|
                      translate}}</a>
                  </li>
                  <li><a class="dropdown-item" target="_blank"
                      href="https://rajasthan.gov.in/pages/sm/department-page/142678/877">{{'Chief_SecretarySubNav'|
                      translate}} </a></li>
                </ul>
              </li>

              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="javascript:void(0)" id="navbarDropdownMenuLink"
                  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> {{'Media_CornerSubNav'| translate}}
                  <span>|</span></a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <a class="dropdown-item" routerLink="/program-list">{{'ProgrammesSubNav'| translate}}</a>
                  <a class="dropdown-item" routerLink="/awards">{{'NavAwards'| translate}}</a>
                  <a class="dropdown-item" routerLink="/pressrelease-list">{{'Press_ReleasesSubNav'| translate}}</a>
                  <a class="dropdown-item" routerLink="/calendar">{{'CalendarSubNav'| translate}}</a>
                  <a class="dropdown-item" routerLink="/gallery-list">{{'PhotosSubNav'| translate}} </a>
                  <a class="dropdown-item" routerLink="/mega-event-list">{{'Mega_Event'| translate}}</a>
                </div>
              </li>
              <li class="nav-item"><a routerLink="/search" class="nav-link">{{'SearchSubNav'| translate}}
                  <span>|</span></a></li>
              <li class="nav-item"><a routerLink="/contactus" class="nav-link">{{'ContactNav'| translate}} </a></li>
            </ul>

          </div>
        </div>
      </nav>
      <div class="cm-profile text-center">
        <a *ngIf="model.PhotoPath" href="javascript:void(0);" class="scrollto">
          <img [src]="sanitizeImageUrl(model.PhotoPath)" alt="{{translate.currlang=='hi'?model.NameHindi:model.Name}}"
            title="{{translate.currlang=='hi'?model.NameHindi:model.Name}}"
            onerror="this.src='assets/images/loader.gif';" />
          <div class="cm-profile-header-info">
            <strong class="m-0 text-dark cm-sir-name d-block"> {{translate.currlang == 'hi' ? model.NameHindi :
              model.Name}} </strong>
            <small class="text-muted d-block">
              {{translate.currlang == 'hi' ? model.DesignationNameHindi : model.DesignationName}}
            </small>
          </div>
        </a>
      </div>
    </div>
  </header> -->


  <section class="new-header-2" id="dynamic">
    <div class="new-header-items"> <!-- *ngIf="router.url == '/home'"-->
      <a [routerLink]="['/home']" routerLinkActive="router-link-active" class="new-logo">
        <img src="assets/images/logo3.png" alt="Logo" class="img-3">
        <div class="logo-text">
          <div class="logo-top-text">{{'Footer_Chief_minister_office' | translate}}</div>
          <div class="logo-bottom-text">{{'RajGov_txt' | translate}}</div>
        </div>
      </a>
    </div>
    <div class="col-md-12 main-m1">
      <div #toggleDiv class="main main-menu" id="mainMenuId">
        <label (click)="menuClicked(1)" class="menu-button-wrapper" for="">
          <input type="checkbox" class="menu-button" />
          <div class="icon-wrapper">
            <label class="hamburger">
              <input class="hamburger-input" type="checkbox" />
              <span (click)="menuClicked(2)" class="hamburger-line first"></span>
              <span class="hamburger-line second"></span>
              <span class="hamburger-line third"></span>
            </label>
          </div>

          <div class="item-list">
            <ul class="navbar-nav-1 w-100 justify-content-center">

              <li [ngClass]="{'dropdown1': item?.IsSubMenu}" *ngFor="let item of menuListModel?.MenuList">
                <ul class="navbar-inner">
                  <li>
                    <a [ngClass]="{'dropdown-toggle1': item?.IsSubMenu}" [href]="item?.ExternalUrl" target="_blank"
                      id="navbarDropdownMenuLink" aria-haspopup="true" aria-expanded="false"
                      *ngIf="item?.IsExternalUrl">
                      <strong>{{translate.currlang!='en'? item?.DisplayNameHindi:item?.DisplayNameEnglish}}</strong>
                    </a>
                    <a [ngClass]="{'dropdown-toggle1': item?.IsSubMenu}" [routerLink]="item?.InternalUrl"
                      id="navbarDropdownMenuLink" aria-haspopup="true" aria-expanded="false"
                      *ngIf="!item?.IsExternalUrl">
                      <strong>{{translate.currlang!='en'? item?.DisplayNameHindi:item?.DisplayNameEnglish}}</strong>
                    </a>
                    <ul class="dropdown-menu1" aria-labelledby="navbarDropdownMenuLink" *ngIf="item?.IsSubMenu">
                      <li class="dropdown-submenu" *ngFor="let subItem of getSubMenuList(item?.MainMenuCode)">
                        <!-- {{ subItem | json }} -->

                        <a class="dropdown-item1" tabindex="1" [ngClass]="{'dropdown-toggle': subItem?.IsSubMenu}"
                          href="javascript:void(0)"
                          *ngIf="!subItem.IsDisable && enumdata.HasSubMenu==subItem?.RedirectionManagementRadio">
                          {{translate.currlang!='en' ? subItem?.DisplayNameHindi :
                          subItem?.DisplayNameEnglish}}
                        </a>
                        <a class="dropdown-item1" tabindex="2" [ngClass]="{'dropdown-toggle': subItem?.IsSubMenu}"
                          [routerLink]="subItem?.IsSubMenu?undefined:subItem?.Url"
                          *ngIf="!subItem.IsDisable && enumdata.DataEnteredInScreens==subItem?.RedirectionManagementRadio">
                          {{translate.currlang!='en' ? subItem?.DisplayNameHindi :
                          subItem?.DisplayNameEnglish}}
                        </a>
                        <a class="dropdown-item1" tabindex="3" [ngClass]="{'dropdown-toggle': subItem?.IsSubMenu}"
                          [href]="subItem?.IsSubMenu?undefined:subItem?.PDFAttachment" target="_blank"
                          *ngIf="!subItem.IsDisable && enumdata.PDFFileUpload==subItem?.RedirectionManagementRadio">
                          {{translate.currlang!='en' ? subItem?.DisplayNameHindi :
                          subItem?.DisplayNameEnglish}}
                        </a>
                        <a class="dropdown-item1" tabindex="4" [ngClass]="{'dropdown-toggle': subItem?.IsSubMenu}"
                          [href]="subItem?.IsSubMenu?undefined:subItem?.RedirectionURL" target="_blank"
                          *ngIf="!subItem.IsDisable && enumdata.URLOfSameWebsiteOtherWebsite==subItem?.RedirectionManagementRadio">
                          {{translate.currlang!='en' ? subItem?.DisplayNameHindi :
                          subItem?.DisplayNameEnglish}}
                        </a>

                        <a class="dropdown-item1" tabindex="5" [ngClass]="{'dropdown-toggle': subItem?.IsSubMenu}"
                          href="javascript:void(0);" *ngIf="subItem.IsDisable" style="cursor: not-allowed;">
                          {{translate.currlang!='en' ? subItem?.DisplayNameHindi :
                          subItem?.DisplayNameEnglish}}
                        </a>

                        <ul class="dropdown-menu1" *ngIf="subItem?.IsSubMenu">
                          <li *ngFor="let subSubItem of getSubSubMenuList(subItem?.SubMenuCode)">
                            <a class="dropdown-item" tabindex="5" [routerLink]="subSubItem?.Url"
                              *ngIf="!subSubItem.IsDisable && enumdata.DataEnteredInScreens==subSubItem?.RedirectionManagementRadio">
                              {{translate.currlang!='en' ? subSubItem?.DisplayNameHindi :
                              subSubItem?.DisplayNameEnglish}}
                            </a>
                            <a class="dropdown-item1" tabindex="6" [href]="subSubItem?.PDFAttachment" target="_blank"
                              *ngIf="!subSubItem.IsDisable && enumdata.PDFFileUpload==subSubItem?.RedirectionManagementRadio">
                              {{translate.currlang!='en' ? subSubItem?.DisplayNameHindi :
                              subSubItem?.DisplayNameEnglish}}
                            </a>
                            <a class="dropdown-item1" tabindex="7" [href]="subSubItem?.RedirectionURL" target="_blank"
                              *ngIf="!subSubItem.IsDisable && enumdata.URLOfSameWebsiteOtherWebsite==subSubItem?.RedirectionManagementRadio">
                              {{translate.currlang!='en' ? subSubItem?.DisplayNameHindi :
                              subSubItem?.DisplayNameEnglish}}
                            </a>
                            <a class="dropdown-item1" tabindex="8" href="javascript:void(0);"
                              *ngIf="subSubItem.IsDisable" style="cursor: not-allowed;">
                              {{translate.currlang!='en' ? subSubItem?.DisplayNameHindi :
                              subSubItem?.DisplayNameEnglish}}
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>


            <!-- <ul class="navbar-nav-1 w-100 justify-content-center">
              <li>
                <h5 class="nav-title">{{'CM_Office_txt'| translate}} </h5>
                <ul class="navbar-inner">
                  <li> <a href="/home">{{'CM_OfficeNav'| translate}}</a></li>
                  <li> <a routerLink="/keyOfficial">{{'Key_OfficialsSubNav'| translate}} </a></li>
                  <li> <a
                      href="https://jankalyanfile.rajasthan.gov.in//Content/UploadFolder/DepartmentMaster/980/2024/Feb/30409/9806e81372b-eb8b-4715-b9a9-7ea445c5fba4.pdf"
                      target="_blank">{{'NavWork_Distribution'| translate}} </a></li>
                </ul>
              </li>
              <li>
                <h5 class="nav-title"> Coming Soon</h5>
                <ul class="navbar-inner">
                  <li>
                    <a href="javascript:void(0)">{{'State_GovernmentSubNav'|
                      translate}}
                    </a>
                  </li>

                  <li><a target="_blank"
                      href="https://rajasthan.gov.in/pages/sm/department-page/142643/866">{{'NavGovernor'|
                      translate}}</a></li>
                  <li><a target="_blank"
                      href="https://rajasthan.gov.in/pages/sm/department-page/142677/876">{{'Chief_Minister'|
                      translate}}</a></li>
                  <li><a target="_blank"
                      href="https://rajasthan.gov.in/pages/sm/department-page/371534/2402">{{'Deputy_Chief_MinisterFooter'|
                      translate}}</a></li>
                  <li><a target="_blank"
                      href="https://rajasthan.gov.in/pages/sm/assembly-constituency/354894/0/1">{{'Cabinet_MinistersSubNav'|
                      translate}}</a>
                  </li>
                  <li><a target="_blank"
                      href=" https://rajasthan.gov.in/pages/sm/assembly-constituency/372005/0/457">{{'State_MinistersIndependantChargeSubNav'|
                      translate}}</a>
                  </li>
                  <li><a target="_blank"
                      href=" https://rajasthan.gov.in/pages/sm/assembly-constituency/354895/0/2">{{'State_MinistersSubNav'|
                      translate}}</a>
                  </li>
                  <li><a target="_blank"
                      href="https://rajasthan.gov.in/pages/sm/department-page/142678/877">{{'Chief_SecretarySubNav'|
                      translate}} </a></li>
                </ul>
              </li>
              <li>
                <h5 class="nav-title">Coming Soon</h5>
                <ul class="navbar-inner">
                  <li> <a href="javascript:void(0)">{{'Media_CornerSubNav'| translate}} </a></li>
                  <li> <a routerLink="/program-list">{{'ProgrammesSubNav'| translate}}</a> </li>
                  <li><a routerLink="/awards">{{'NavAwards'| translate}}</a> </li>
                  <li> <a routerLink="/pressrelease-list">{{'Press_ReleasesSubNav'| translate}}</a> </li>
                  <li> <a routerLink="/calendar">{{'CalendarSubNav'| translate}}</a> </li>
                  <li> <a routerLink="/gallery-list">{{'PhotosSubNav'| translate}} </a> </li>
                  <li> <a routerLink="/mega-event-list">{{'Mega_Event'| translate}}</a></li>
                </ul>
              </li>
              <li>
                <h5 class="nav-title">{{'Our_Goverment_txt'|
                  translate}} </h5>
                <ul class="navbar-inner">
                  <li> <a target="_blank" href="https://rajasthan.gov.in">{{'NavState_Portal'|
                      translate}}</a>
                  </li>
                  <li> <a target="_blank" href="https://assembly.rajasthan.gov.in/">{{'Rajasthan_Assembly_txt'|
                      translate}}</a> </li>
                  <li> <a target="_blank" href="https://sampark.rajasthan.gov.in/">{{'Rajasthan_SamparkSubNav' |
                      translate}}</a> </li>
                  <li> <a target="_blank" href="https://rti.rajasthan.gov.in/">{{'Right_To_InformationSubNav' |
                      translate}}</a> </li>
                  <li>
                    <a target="_blank" href="https://cmrelief.rajasthan.gov.in/">{{'CM_Relief_FundFooter' |
                      translate}} </a>
                  </li>
                </ul>
              </li>
              <li>
                <h5 class="nav-title">{{'Contact_Us_txt' |
                  translate}} </h5>
                <div class="text-nav">{{'Address_cm' | translate}}</div>

              </li>
            </ul> -->
          </div>
        </label>
      </div>
      <div class="crl"></div>
    </div>
    <div class="top-page-title">
      <a _ngcontent-ng-cli-universal-c22="" routerlink="/home" ng-reflect-router-link="/home" href="/home"><i
          class="fa fa-home" aria-hidden="true"></i></a>
      &nbsp;&nbsp;
      <a _ngcontent-ng-cli-universal-c22="" data-bs-toggle="modal" data-bs-target="#cmLive" href="javascript:void(0)"><i
          class="fa fa-video-camera" aria-hidden="true"></i> &nbsp;<span class="live-stream">{{'LiveStreaming_txt' | translate}}</span></a>
      <a href="{{dptModel?.TwitterURL ? dptModel?.TwitterURL : 'https://twitter.com/RajCMO'}}"
        class="svg-img-icon icon-head" rel="noopener noreferrer" target="_blank">
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"
          viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
          <path
            d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
        </svg>
      </a>
      <a href="{{dptModel?.YoutubeURL ? dptModel?.YoutubeURL : 'https://www.youtube.com/@cmoraj' }}" target="_blank"
        class="icon-head icon-you"><i class="fa fa-youtube-play" aria-hidden="true"></i></a> &nbsp;&nbsp;
      <a href="{{dptModel?.InstagramURL ? dptModel?.InstagramURL : 'https://www.instagram.com/rajcmo/?hl=en' }}"
        target="_blank" class="icon-head icon-insta"> <i class="fa fa-instagram" aria-hidden="true"></i></a>
      &nbsp;&nbsp;
      <a href="{{dptModel?.FacebookURL ? dptModel?.FacebookURL : 'https://www.facebook.com/RajCMO/' }}"
        rel="noopener noreferrer" target="_blank" class="icon-head icon-facebook"><i class="fa fa-facebook"
          aria-hidden="true"></i>
      </a>
      <a [routerLink]="['/phone-booth']" routerLinkActive="router-link-active"  class="booth"><i><img src="assets/images/booth-icon.png" alt="photo booth"> </i>Moments With CM</a>
      <!-- <a href=" https://www.itu.int/wtsa/2024/" target="_blank" class="itu">
        <img src="assets/images/ituwtsa.png" alt="">
      </a> -->
    </div>
    <div class="clearfix"></div>
  </section>



  <!-- <header class="new-header">
    <div class="new-header-items">
      <a href="javascript:void(0)" routerLink="/home" class="new-logo">
        <img src="assets/images/logo.png" alt="Logo" class="img-logo">
      </a>
    </div>
    <div class="new-header-items d-none">
      <div class="header-cm" *ngIf="model.PhotoPath">
        <div class="header-cm-img">
          <img [src]="sanitizeImageUrl(model.PhotoPath)" alt="{{translate.currlang=='hi'?model.NameHindi:model.Name}}"
            title="{{translate.currlang=='hi'?model.NameHindi:model.Name}}"
            onerror="this.src='assets/images/loader.gif';" />
        </div>
        <div class="header-cm-info">
          <strong class="m-0 text-dark cm-sir-name d-block"> {{translate.currlang == 'hi' ? model.NameHindi :
            model.Name}} </strong>
          <small class="text-muted d-block">
            {{translate.currlang == 'hi' ? model.DesignationNameHindi : model.DesignationName}}
          </small>
        </div>
      </div>
    </div>
    <div class="new-header-items d-none">
      <div class="new-menu">
        <a href="javascript:void(0)" class="new-menu-icon mr-3">
          <i class="fa fa-bars"></i> <span> Menu </span>
        </a>
        <a href="javascript:void(0)" class="new-menu-search" routerLink="/search">
          <i class="fa fa-search"></i>
        </a>
      </div>
    </div>
  </header>
</div>

<nav class="navbar">
  <div class="collapse navbar-collapse">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#">Link</a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          Dropdown
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="#">Action</a>
          <a class="dropdown-item" href="#">Another action</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="#">Something else here</a>
        </div>
      </li>
    </ul>
  </div>
</nav>
<div class="sidemenu" id="SideMenu">
  <div class="text-right">
    <a href="javascript:void(0)" class="sidemenu-close"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
        fill="#fff" class="bi bi-x-circle" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
        <path
          d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
      </svg></a>
  </div>
  <ul class="side-mainmenu">
    <li> <a routerLink="/home">{{'Nav_Home'| translate}}</a>
    </li>

    <li> <a href="javascript:void(0)">{{'CM_OfficeNav'| translate}} <svg xmlns="http://www.w3.org/2000/svg" width="20"
          height="20" fill="#fff" class="bi bi-chevron-right" viewBox="0 0 16 16">
          <path fill-rule="evenodd"
            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
        </svg></a>
      <ul class="side-submenu">
        <li> <a routerLink="/keyOfficial">{{'Key_OfficialsSubNav'| translate}} </a></li>
        <li> <a
            href="https://jankalyanfile.rajasthan.gov.in//Content/UploadFolder/DepartmentMaster/980/2024/Feb/30409/9806e81372b-eb8b-4715-b9a9-7ea445c5fba4.pdf"
            target="_blank">{{'NavWork_Distribution'| translate}} </a></li>
      </ul>
    </li>


    <li> <a href="javascript:void(0)">{{'State_GovernmentSubNav'|
        translate}}
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff" class="bi bi-chevron-right"
          viewBox="0 0 16 16">
          <path fill-rule="evenodd"
            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
        </svg>
      </a>
      <ul class="side-submenu">
        <li><a target="_blank" href="https://rajasthan.gov.in/pages/sm/department-page/142643/866">{{'NavGovernor'|
            translate}}</a></li>
        <li><a target="_blank" href="https://rajasthan.gov.in/pages/sm/department-page/142677/876">{{'Chief_Minister'|
            translate}}</a></li>
        <li><a target="_blank"
            href="https://rajasthan.gov.in/pages/sm/department-page/371534/2402">{{'Deputy_Chief_MinisterFooter'|
            translate}}</a></li>
        <li><a target="_blank"
            href="https://rajasthan.gov.in/pages/sm/assembly-constituency/354894/0/1">{{'Cabinet_MinistersSubNav'|
            translate}}</a>
        </li>
        <li><a target="_blank"
            href=" https://rajasthan.gov.in/pages/sm/assembly-constituency/372005/0/457">{{'State_MinistersIndependantChargeSubNav'|
            translate}}</a>
        </li>
        <li><a target="_blank"
            href=" https://rajasthan.gov.in/pages/sm/assembly-constituency/354895/0/2">{{'State_MinistersSubNav'|
            translate}}</a>
        </li>
        <li><a target="_blank"
            href="https://rajasthan.gov.in/pages/sm/department-page/142678/877">{{'Chief_SecretarySubNav'|
            translate}} </a></li>
      </ul>
    </li>





    <li> <a href="javascript:void(0)">{{'Media_CornerSubNav'| translate}} <svg xmlns="http://www.w3.org/2000/svg"
          width="20" height="20" fill="#fff" class="bi bi-chevron-right" viewBox="0 0 16 16">
          <path fill-rule="evenodd"
            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
        </svg></a>
      <ul class="side-submenu">
        <li> <a routerLink="/program-list">{{'ProgrammesSubNav'| translate}}</a> </li>
        <li><a routerLink="/awards">{{'NavAwards'| translate}}</a> </li>
        <li> <a routerLink="/pressrelease-list">{{'Press_ReleasesSubNav'| translate}}</a> </li>
        <li> <a routerLink="/calendar">{{'CalendarSubNav'| translate}}</a> </li>
        <li> <a routerLink="/gallery-list">{{'PhotosSubNav'| translate}} </a> </li>
        <li> <a routerLink="/mega-event-list">{{'Mega_Event'| translate}}</a></li>

      </ul>
    </li>



    <li> <a routerLink="/search">{{'SearchSubNav'| translate}}
      </a> </li>
    <li> <a routerLink="/contactus">{{'ContactNav'| translate}} </a></li>
  </ul>
</div> -->

  <!-- <div class="modal fade" id="cmLive" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog live-video">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">CM Live Program</h5>
        <button type="button" class="close close-in" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="text-2">
          <iframe _ngcontent-hfo-c186="" title="YouTube video player" class="live-video2"  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="" [src]="videoID"></iframe>
        </div>
      </div>

    </div>
  </div>
</div> -->

  <div class="modal fade" id="cmLive" data-bs-keyboard="false" data-bs-backdrop="static">
    <div class="modal-dialog live-video" style="max-width:1000px">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">CM Live Program</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <!-- <iframe _ngcontent-hfo-c186="" width="100%" height="610px" title="YouTube video player" class="live-video2"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
          allowfullscreen [src]="videoID"></iframe> -->
        <iframe class="live-video2" width="100%" height="610px"
          [src]="videoID" frameborder="0"
          allow="accelerometer; autoplay; gyroscope; picture-in-picture" allowfullscreen> </iframe>
          <!--end modal-content-->
      </div>
      <!--end modal-dialoge-->
    </div>
    <!--end myModal-->
  </div>