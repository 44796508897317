import { CanActivateFn, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { inject } from '@angular/core';

export const authGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  
  const email = environment.TEST_CERD_EMAIL
  const password = environment.TEST_CRED_PASSWORD
  const cred = atob(localStorage.getItem('token'))
  if(cred == email+""+password) return true;
  else router.navigate(['/phone-booth-admin-login']);
};

export const loginGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  
  const email = environment.TEST_CERD_EMAIL
  const password = environment.TEST_CRED_PASSWORD
  const cred = atob(localStorage.getItem('token'))
  if(cred == email+""+password){
    window.history.back();
    return false;
  } 
  else true
};
