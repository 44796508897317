import { AchievementViewModel, IndexModel } from "./awards-request-model.model";

export class DepartmentProfileViewModel {
  Id: number;
  Code: number;
  DepartmentCode: number | string;
  EntryTypeCode: number | string;
  DataTypeCode: number;
  Details: any;
  ImageURL: string;
  PDFURL: string | any;
  CreatedDate: Date;
  CreatedBy: number;
  ModifiedDate: Date;
  ModifiedBy: number;
  IsActive: boolean;
  IsDeleted: boolean;
  DepartmentTitle: string;
  DepartmentTitleHindi: string;
  DepartmentShortTitle: string;
  DepartmentShortTitleHindi: string;
  EntryTypeName: string;
  EntryTypeNameHindi: string;
  EntryTypeDisplayOrder: number;
  DetailsInHindi: string | any;
  url: string | any;
}

export class DepartmentProfileFilterModel {
  IndexModel = new IndexModel();
  DepartmentCode: number | string;
  EntryTypeCode: number | string;
  JankalyanCategoryCode: number | string;
  LookupCode: number | string;

  constructor() {
    this.DepartmentCode = 0;
    this.EntryTypeCode = 0;
    this.JankalyanCategoryCode = 0;
    this.LookupCode = 0;
  }
}

export class PageTitleModel {
  NameHindi: string;
  NameEnglish: string;
  ImagePath: string;
}
export class DepartmentDetailModel {
  DepartmentTitle: string;
  DepartmentTitleHindi: string;
  DepartmentAddress: string;
  DepartmentAddressHindi: string;
  Department_DistrictCode: number | null;
  Department_AdmDepartmentCode: number | null;
  DepartmentCode: number | null;
  WebsiteName: string;
  NodalOfficerName: string;
  NodalOfficerDesignation: string;
  MobileNo: string;
  Email: string;
  SSOID: string;
  DisplayName: string;
  FacebookURL: string;
  TwitterURL: string;
  YoutubeURL: string;
  InstagramURL: string;
  CMISDeptID: string;
  DepartmentDistrictCode: number | null;
  CMOOfficerCode: number | null;
  LogoUrl: string;
  DepartmentPassword: string;
  WebsiteImage: string;
  StateMinisterPhoto: string;
  CabinetMinisterPhoto: string;
  WebsiteDynamicCategory: string;
  WebsiteDynamictransaction: string;
  StateMinisterNameEnglish: string;
  StateMinisterNameHindi: string;
  CabinetMinisterNameEnglish: string;
  CabinetMinisterNameHindi: string;
  DeparmtentWebsiteUrlModel: DeparmtentWebsiteUrlModel[] = [];
  GetDepartmentList: number;
  length: number;
  DepartmentListmodel: number;
  DefaultThemeColor: string;
  IsUpdationComplete: boolean;
  DefaultFontSize: number;
  WebsiteLogoPath: string;
  DefaultLanguage: string;
  IsShowHeaderIcon: boolean;
  HeaderIconURL: string;
  IsHighlightHeaderIcon: boolean;
  HeaderIconPath: string;
  LastUpdateDate: Date;
  LastUpdateDateHindi: string;
  BannerList: AchievementViewModel[] = [];
  ExternalLinkList: AchievementViewModel[] = [];
  NewsTickerList: AchievementViewModel[] = [];
  IsDisplaySideNewsWindow: boolean;
  IsDisplayRajAdvtPopup: boolean;
  IsSideBarDeptSpecific: boolean;
  RajAdvtDataSourceCode: number;
  IsCodeOfConduct: boolean;
}
export class DeparmtentWebsiteUrlModel {
  DepartmentCode: number;
  DepartmentTitle: string;
  DepartmentTitleHindi: string;
}

export class DepartmentMenuListModel {
  MenuList: GenerateMenuListForDepartmentModel[] = [];
  SubMenuList: GenerateSubMenuListForDepartmentModel[] = [];
  SubSubMenuList: GenerateSubSubMenuListForDepartmentModel[] = [];
}

export class GenerateMenuListForDepartmentModel {
  Id: number;
  MainMenuCode: number | null;
  DisplayNameEnglish: string;
  DisplayNameHindi: string;
  IsSubMenu: boolean;
  IsExternalUrl: boolean;
  ExternalUrl: string;
  InternalUrl: string;
  DisplayOrder: any;
}

export class GenerateSubMenuListForDepartmentModel {
  Id: number;
  SubMenuCode: number;
  DepartmentMainMenuCode: number | null;
  IconImage: string;
  IsSubMenu: boolean;
  DepartmentSubMenuCode: number | null;
  DisplayNameEnglish: string;
  DisplayNameHindi: string;
  DisplayOrder: number | null;
  Url: string;
  ModuleCategoryCode: number | null;
  RedirectionManagementRadio: number;
  PDFAttachment: string;
  RedirectionURL: string;
  IsDisable: boolean;
}

export class GenerateSubSubMenuListForDepartmentModel {
  Id: number;
  DepartmentMainMenuCode: number | null;
  IconImage: string;
  IsSubMenu: boolean;
  DepartmentSubMenuCode: number | null;
  DisplayNameEnglish: string;
  DisplayNameHindi: string;
  DisplayOrder: number | null;
  Url: string;
  ModuleCategoryCode: number | null;
  RedirectionManagementRadio: number;
  PDFAttachment: string;
  RedirectionURL: string;
  IsDisable: boolean;
}
