
<!-- <div [ngClass]="!isCodeOfConduct ? 'bg-scroll pos-rel breadcrumbs-page-img' : 'bg-scroll pos-rel'">
  <div class="container">

    <h1>{{'Event_Calendar'| translate}}</h1>

  </div>
</div> -->

<div class="breadcrumbs-page-img">
  <div class="banner-hight-2">
    <h1>{{'Event_Calendar'| translate}}</h1>
    <div class="inner-page-banner">
      <img src="assets/images/cmo-banner.png" alt="banner" class="cmo-banner">
    </div>
  </div>
</div>
<main id="main">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
      <li class="breadcrumb-item"><a href="javascript:void(0)">{{'Media_CornerSubNav'| translate}}</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{'CalendarSubNav'| translate}}</li>
    </ol>
  </nav>
  <section class="program_list cmrelief rti calendar_page">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-sm-12">

          <div class="row">


            <div class="col-xl-2 col-lg-3 col-md-4  offset-lg-1 offset-xl-3">
              <div class="form-group">
                <label><strong>{{'Month'| translate}}</strong></label>
                <select class="form-control" [(ngModel)]="SelectedMonth">
                  <option value="1">January</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April</option>
                  <option value="5">May</option>
                  <option value="6">June</option>
                  <option value="7">July</option>
                  <option value="8">August</option>
                  <option value="9">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
              </div>
            </div>

            <div class="col-xl-2 col-lg-3 col-md-4">
              <div class="form-group">
                <label><strong>{{'Year'| translate}}</strong></label>
                <select class="form-control" [(ngModel)]="SelectedYear">
                  <!--<option value="2013">2013</option>
                  <option value="2014">2014</option>
                  <option value="2015">2015</option>
                  <option value="2016">2016</option>
                  <option value="2017">2017</option>-->
                  <option value="2018">2018</option>
                  <option value="2019">2019</option>
                  <option value="2020">2020</option>
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                  <option value="2026">2026</option>
                  <option value="2027">2027</option>
                  <option value="2028">2028</option>
                  <option value="2029">2029</option>
                  <option value="2030">2030</option>
                  <option value="2031">2031</option>
                  <option value="2032">2032</option>
                  <option value="2033">2033</option>
                  <option value="2034">2034</option>
                  <option value="2035">2035</option>
                  <option value="2036">2036</option>
                  <option value="2037">2037</option>
                  <option value="2038">2038</option>
                  <option value="2039">2039</option>
                  <option value="2040">2040</option>
                  <option value="2041">2041</option>
                  <option value="2042">2042</option>
                  <option value="2043">2043</option>
                  <option value="2044">2044</option>
                  <option value="2045">2045</option>
                  <option value="2046">2046</option>
                  <option value="2047">2047</option>
                  <option value="2048">2048</option>
                  <option value="2049">2049</option>
                  <option value="2050">2050</option>

                </select>
              </div>
            </div>

            <div class="col-xl-2 col-lg-3 col-md-4">
              <div class="form-group">
                <label>&nbsp;</label>
                <a (click)="searchByMonthYear()" class="btn-theme bg-blue d-block w-100 text-center">{{'SearchSubNav'| translate}} &nbsp;<i class="fa fa-angle-right" aria-hidden="true"></i> </a>
              </div>
            </div>
          </div>
        </div>

        <div class="table-responsive">

          <div class="row text-center">
            <div class="col-md-4">
              <div class="btn-group">
                <div class="btn btn-primary"
                     mwlCalendarPreviousView
                     [view]="view"
                     [(viewDate)]="viewDate"
                     (viewDateChange)="closeOpenMonthViewDay()">
                  {{'Previous'| translate}}
                </div>
                <div class="btn btn-outline-secondary"
                     mwlCalendarToday
                     [(viewDate)]="viewDate">
                  {{'Today'| translate}}
                </div>
                <div class="btn btn-primary"
                     mwlCalendarNextView
                     [view]="view"
                     [(viewDate)]="viewDate"
                     (viewDateChange)="closeOpenMonthViewDay()">
                  {{'Next'| translate}}
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
            </div>
            <!--<div class="col-md-4">
              <div class="btn-group">
                <div class="btn btn-primary"
                     (click)="setView(CalendarView.Month)"
                     [class.active]="view === CalendarView.Month">
                  Month
                </div>
                <div class="btn btn-primary"
                     (click)="setView(CalendarView.Week)"
                     [class.active]="view === CalendarView.Week">
                  Week
                </div>
                <div class="btn btn-primary"
                     (click)="setView(CalendarView.Day)"
                     [class.active]="view === CalendarView.Day">
                  Day
                </div>
              </div>
            </div>-->
          </div>
          <br />
          <div [ngSwitch]="view">
            <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month"
                                     [viewDate]="viewDate"
                                     [events]="this.translate.currlang == 'en'? events: eventsHindi"
                                     [refresh]="refresh"
                                     (dayClicked)="dayClicked($event.day,modalContent)"
                                     [activeDayIsOpen]="activeDayIsOpen"
                                     (eventClicked)="handleEvent('Clicked', $event.event)">
            </mwl-calendar-month-view>

          </div>

        </div>
      </div>



    </div>

  </section>
</main>



<!-- Everything you see below is just for the demo, you don't need to include it in your app -->
<br /><br /><br />

<!--<h3>
  Edit events
  <button class="btn btn-primary pull-right" (click)="addEvent()">
    Add new
  </button>

</h3>-->
<ng-template #modalContent let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Event action occurred</h5>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <section class="program_list">
      <div class="container">

        <div class="row">
          <section id="programmes">

            <div class="row">
              <div class="col-lg-6" *ngFor="let row of dayEvents let i = index">
                <div class="icon align-self-center"> <img src="assets/img/news_ico.png" alt="Icon" class="img-fluid"> </div>
                <div class="programme-content align-self-center">
                  <h4 class="title">
                    {{row.title}}

                  </h4>
                  <span><strong>Event On:</strong> {{row.start}}</span>
                </div>

              </div>
            </div>

          </section>
        </div>
      </div>

    </section>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="close()">
      OK
    </button>
  </div>
</ng-template>

