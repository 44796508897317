<!--<div class="press_releases ps1">-->
<div class="marquee">
  <div class="press_releases marquee__content" style="height:auto;">
    <div id="news-container">
      <ul>

        <ng-container *ngFor="let program of generalModel; let i = index">
          <li *ngIf="this.translate.currlang != 'en'">
            <div class="press_releases_bx" routerLink="/pressreleasedetail/{{program.Id}}" *ngIf="i<5">
              <div class="press_releases_bx-detail-img"><img src="assets/img/press-release_new.png" alt=""></div>
              <div class="press_releases_bx-detail">
                <div> {{program.Description | slice:0:70}}... </div>
                <small> {{program.PressReleaseDateHindi[0]}}, {{program.PressReleaseDateHindi[1]}} ,{{program.PressReleaseDateHindi[2]}}</small>
                <!-- <span><i class="ion-ios-location-outline"></i>{{program.ProgramAddressHindi}} </span> -->
              </div>

            </div>
          </li>

          <li *ngIf="this.translate.currlang != 'hi'">
            <div class="press_releases_bx" routerLink="/pressreleasedetail/{{program.Id}}" *ngIf="i<5">
              <div class="press_releases_bx-detail-img"><img src="assets/img/press-release_new.png" alt=""></div>
              <div class="press_releases_bx-detail">
                <div>{{program.Description| slice:0:70}}... </div>
                <small>{{program.PressreleaseDate | date : 'dd'}}, {{program.PressreleaseDate| date : 'MMMM'}}, {{program.PressreleaseDate| date : 'yyyy'}} </small>
                <!-- <span><i class="ion-ios-location-outline"></i>{{program.ProgramAddress }} </span> -->
              </div>

            </div>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
  </div>
