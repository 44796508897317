<section class="uploader-gallery">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="section-header">
                    <h2>{{'Photo_Gallery'| translate}}</h2>
                </div>
            </div>

            <div class="col-md-3 mb-3" *ngFor="let items of model; let i = index">
                <div *ngIf="i<4">
                    <a href="javascript:void(0)" class="uploader-gallery-items">
                        <span class="uploader-gallery-items-date">{{this.translate.currlang == 'hi' ? items.DateHindi :
                            (items.Date | date : 'd MMM, y')}}</span>
                        <div class="uploader-gallery-items-img">

                            <a class="img-loader" [href]="items.Images[0]"
                                onerror="this.src='assets/images/default.png'" attr.data-lightbox="images_{{i}}"
                                title="{{items.Subject}}">

                                <img [src]="items.Images[0]" alt="{{items.Subject}}" title="{{items.Subject}}"
                                    onerror="this.src='assets/images/default.png'">

                                <!---Inner Section-->
                                <ul *ngIf="items.Images && items.Images.length>0" style="display: none;">
                                    <ng-container *ngFor="let imgSrc of items.Images; let j=index">
                                        <li *ngIf="j>0">
                                            <a href="{{imgSrc}}" attr.data-lightbox="images_{{i}}"
                                                data-title="{{items.Subject}}">
                                                <img [src]="imgSrc" title="{{items.Subject}}" alt="{{items.Subject}}"
                                                    onerror="this.src='assets/images/default.png';" />
                                            </a>
                                        </li>
                                    </ng-container>
                                </ul>
                            </a>
                        </div>
                        <div *ngIf="items.Subject" class="uploader-gallery-items-caption">{{this.translate.currlang ==
                            'hi' ? items.Subject :
                            items.Subject}}</div>
                    </a>
                </div>
            </div>
            <div *ngIf="model.length > 4" class="col-lg-12 text-center pt-3 wow fadeInUp">
                <a routerLink="/pages/sm/uploader/PHOTO/gallery/0/{{departmentCode}}/{{catCode}}"
                    class="gray-btn gray-orange">{{'View_More'|
                    translate}}</a>
            </div>
        </div>
        <div class="text-center" *ngIf="!model?.length">
            <h3>Record Not Found</h3>
        </div>
    </div>
</section>