
import { BaseService } from './../services/baseservice';
import { Dictionary } from './../common/dictionary';
import { Injectable } from '@angular/core';
import { AppSettings } from './../common/AppSettings';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StateministerService {
  public constructor(private httpService: BaseService) {

  }
  GetStateMinisterList() {
    return this.httpService.get(AppSettings.API_ENDPOINT + AppSettings.API_STATEMINISTER_LIST_GET);
  }

  GetCabinetMinisterList() {
    return this.httpService.get(AppSettings.API_ENDPOINT + AppSettings.API_CABINETMINISTER_LIST_GET);
  }

  GetAdvertisment() {
    return this.httpService.get(AppSettings.Api_Advertisement_Get);
  }



}
