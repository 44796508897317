import { Component, Inject, AfterViewChecked, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsLetter.component.html',
  styleUrls: ['./newsLetter.component.css']
})
export class NewsLetterComponent implements AfterViewChecked {
  msgEnglish = false;
  newsLetterModel: NewsLetterModel;
  newsLetterForm: NgForm;
  @ViewChild('newsLetterForm') currentForm: NgForm;
  isValid = false;
  headers: HttpHeaders;
  buttonText: string;
  title: string;
  isError = false;
  forceValidationCheck = false;
  group: string[];

  constructor(
    public http: HttpClient,
    @Inject('BASE_URL') public baseUrl: string
  ) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    this.newsLetterModel = new NewsLetterModel();
    this.newsLetterModel.Address = '';
    this.newsLetterModel.Comment = '';
    this.newsLetterModel.Designation = '';
    this.newsLetterModel.Email = '';
    this.newsLetterModel.Name = '';
    this.newsLetterModel.Organization = '';
    this.newsLetterModel.Phone = '';
    this.newsLetterModel.selfphoto = '';
    this.newsLetterModel.Attchpdf = '';
    this.newsLetterModel.FdTitle = '';
    this.newsLetterModel.SelectedGroup = '';
    this.newsLetterModel.Slang = this.msgEnglish ? 'English' : 'Hindi';
    this.buttonText = 'Click here to write in english';
    this.title = 'न्यूजलैटर';
    this.group = [];
  }

  ngAfterViewChecked() {
    this.formChanged();
  }

  changeLanguage() {
    if (this.msgEnglish) {
      this.msgEnglish = false;
      this.buttonText = 'Click here to write in english';
      this.title = 'न्यूजलैटर';
    } else {
      this.msgEnglish = true;
      this.buttonText = 'Click here to write in hindi';
      this.title = 'NEWSLETTER';
    }
  }

  formChanged() {
    if (this.currentForm === this.newsLetterForm) {
      return;
    }

    this.newsLetterForm = this.currentForm;
    if (this.newsLetterForm) {
      this.newsLetterForm.valueChanges.subscribe(data =>
        this.onValueChanged(data)
      );
    }
  }

  mangeGroup(name: string, isChecked: boolean) {
    if (isChecked) {
      this.group.push(name);
    } else {
      const index = this.newsLetterModel.SelectedGroup.indexOf(name);
      this.group.splice(index, 1);
    }
  }
  onSubmit() {
    if (!this.newsLetterForm.valid) {
      this.forceValidationCheck = true;
      this.onValueChanged();
      return;
    }
    this.newsLetterModel.Slang = this.msgEnglish ? 'English' : 'Hindi';
    this.newsLetterModel.SelectedGroup = this.group.toString();
    this.http
      .post(this.baseUrl + 'api/Home/SetFeedBack', this.newsLetterModel, {
        headers: this.headers
      })
      .subscribe(
        result => {
          if (result) {
            window.location.href = '/thankyou';
          } else {
            this.isError = true;
          }
        },
        error => console.error(error)
      );
  }

  onValueChanged(data?: any) {
    this.isValid = true;
    if (!this.newsLetterForm) {
      return;
    }
    const form = this.newsLetterForm.form;
    for (const field in this.formErrors) {
      this.formErrors[field] = '';
      const control = form.get(field);

      if (control && (control.dirty || this.forceValidationCheck) && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }

  formErrors = {
    FullName: '',
    Email: '',
    Phone: '',
    Designation: '',
    Organization: '',
    Address: ''
  };
  validationMessages = {
    FullName: {
      required: 'Name is required.',
      maxlength: 'Name Id length should be maximum of 100 characters.'
    },
    Email: {
      required: 'Email Id is required.',
      maxlength: 'Email Id length should be maximum of 100 characters.',
      pattern: 'Enter valid email id.'
    },
    Phone: {
      minlength: 'Please enter a Vaild Number.',
      pattern: 'Enter valid Phone No.'
    },

    Designation: {
      maxlength: 'Designation length should be maximum of 100 characters.'
    },
    Organization: {
      maxlength: 'Organization length should be maximum of 150 characters.'
    },
    Address: {
      maxlength: 'Address length should be maximum of 1000 characters.'
    }
  };
}
class NewsLetterModel {
  Name: string;
  Email: string;
  Phone: string;
  Designation: string;
  Organization: string;
  Address: string;
  Comment: string;
  Attchpdf: string;
  Slang: string;
  selfphoto: string;
  FdTitle: string;
  SelectedGroup: string;
}
