<div class="bg-scroll pos-rel breadcrumbs-page-img">
    <div class="container">

        <h1>Invitation Request Form</h1>

    </div>
</div>
<main id="main">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
            <li class="breadcrumb-item active" aria-current="page">Invitation Request Form</li>
        </ol>
    </nav>
</main>
<section class="py-5 page-rajvision-feedback">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8">
                <div class="form-box">
                    <form [formGroup]="Form" (ngSubmit)="onSubmit();">

                        <h4 class="form-title">Sender Details</h4>

                        <div class="row justify-content-start">
                            <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                                <label> Name <span style="color: red">*</span> </label>
                                <input type="text" placeholder="Name" formControlName="senderName" class="form-control">
                                <span *ngIf="senderName?.hasError('required') && (senderName?.touched ||
                                    senderName?.dirty)" style="color: red">Name is Required</span>
                            </div>

                            <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                                <label> Organization Name </label>
                                <input type="text" placeholder="Organization Name" formControlName="organizationName"
                                    class="form-control">
                            </div>

                            <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                                <label> Sender Type <span style="color: red">*</span> </label>
                                <select class="form-control" formControlName="senderTypeCode">
                                    <option selected value=''>--Select--</option>
                                    <option *ngFor="let item of dropdowns?.ddlHCMInvitationSenderType"
                                        [ngValue]="item.Value"> {{item.Text}}
                                    </option>
                                </select>
                                <span *ngIf="senderTypeCode?.hasError('required') && (senderTypeCode?.touched ||
                                senderTypeCode?.dirty)" style="color: red">Sender Type is Required</span>
                            </div>

                            <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                                <label> Email </label>
                                <input type="text" placeholder="Email" formControlName="senderEmail"
                                    class="form-control">
                            </div>

                            <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                                <label> District <span style="color: red">*</span> </label>
                                <select class="form-control" formControlName="senderDistrictCode">
                                    <option selected value=''>--Select--</option>
                                    <option *ngFor="let item of dropdowns?.ddlDistrict" [ngValue]="item.Value">
                                        {{item.Text}}
                                    </option>
                                </select>
                                <span *ngIf="senderDistrictCode?.hasError('required') && (senderDistrictCode?.touched ||
                                    senderDistrictCode?.dirty)" style="color: red">District is Required</span>
                            </div>

                            <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                                <label> Phone No. </label>
                                <input type="text" placeholder="Phone No." formControlName="senderPhoneNo"
                                    maxlength="10" minlength="10" class="form-control"
                                    (keypress)="_commonservice.numberOnly($event)">
                            </div>

                            <div class="col-sm-6 col-md-12 col-lg-12 col-xl-12 mb-3">
                                <label> Address <span style="color: red">*</span> </label>
                                <input type="text" placeholder="Address" formControlName="senderAddress"
                                    class="form-control">
                                <span *ngIf="senderAddress?.hasError('required') && (senderAddress?.touched ||
                                    senderAddress?.dirty)" style="color: red">Address is Required</span>
                            </div>
                        </div>
                        <h4 class="form-title">Program Details</h4>
                        <div class="row justify-content-start">

                            <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                                <label> Category <span style="color: red">*</span></label>
                                <select class="form-control" formControlName="invitationCategoryCode">
                                    <option selected value=''>--Select--</option>
                                    <option *ngFor="let item of ddlHCMInvitationCategory"
                                        [ngValue]="item.Value">
                                        {{item.Text}}
                                    </option>
                                </select>
                                <span *ngIf="invitationCategoryCode?.hasError('required') && (invitationCategoryCode?.touched ||
                                    invitationCategoryCode?.dirty)" style="color: red">Category is Required</span>
                            </div>

                            <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                                <label> District <span style="color: red">*</span> </label>
                                <select class="form-control" formControlName="programDistrictCode">
                                    <option selected value=''>--Select--</option>
                                    <option *ngFor="let item of dropdowns?.ddlDistrict" [ngValue]="item.Value">
                                        {{item.Text}}
                                    </option>
                                </select>
                                <span *ngIf="programDistrictCode?.hasError('required') && (programDistrictCode?.touched ||
                                    programDistrictCode?.dirty)" style="color: red">District is Required</span>
                            </div>

                            <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                                <label> Venue </label>
                                <input type="text" placeholder="Venue" formControlName="programVenue"
                                    class="form-control">
                            </div>

                            <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                                <label> Request Date (From) </label>
                                <!-- <ng-datepicker placeholder="Request Date (From)" formControlName="requestDateFrom"
                                    class="w-100" [options]="options"><svg
                                        class="mat-datepicker-toggle-default-icon ng-star-inserted dateIcon"
                                        fill="currentColor" focusable="false" height="24px" viewBox="0 0 24 24"
                                        width="24px">
                                        <path
                                            d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z">
                                        </path>
                                    </svg></ng-datepicker> -->
                            </div>

                            <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                                <label> Request Date (To) </label>
                                <!-- <ng-datepicker placeholder="Request Date (To)" formControlName="requestDateTo"
                                    class="w-100" [options]="options"><svg
                                        class="mat-datepicker-toggle-default-icon ng-star-inserted dateIcon"
                                        fill="currentColor" focusable="false" height="24px" viewBox="0 0 24 24"
                                        width="24px">
                                        <path
                                            d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z">
                                        </path>
                                    </svg></ng-datepicker> -->
                            </div>

                            <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3" style="position: relative;">
                                <label>Attachment (PDF)</label>
                                <div>
                                  <label class="custom-file-upload">
                                    <input type="file" id="PDFPath" (change)="handleFileInput($event)" accept=".pdf,"
                                        class="form-control" style="display: none;">
                                        <i class="fa fa-upload" aria-hidden="true"></i> Select File
                                      </label>
                                </div>
                                <div class="file-del-icon" *ngIf="attachmentPath.value">
                                    <a href="Javascript:void(0)" (click)="downloadPdf(attachmentPath.value,'Aboutpdf')"
                                        class="btn-soft-copy">
                                        <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                                    </a>
                                    <a [ngStyle]="{'cursor': 'pointer'}" title="Remove" (click)="RemoveFile()">
                                        <i class="fa fa-trash"></i> </a>
                                </div>
                                <span *ngIf="this.fileValidationMsg?.length >0"
                                    style="color: red">{{fileValidationMsg}}</span>
                            </div>
                            <div class="col-sm-6 col-md-12 col-lg-12 col-xl-12 mb-3">
                                <label> Subject <span style="color: red">*</span> </label>
                                <input type="text" placeholder="Subject" formControlName="subject" class="form-control">
                                <span *ngIf="subject?.hasError('required') && (subject?.touched ||
                                    subject?.dirty)" style="color: red">Subject is Required</span>
                            </div>

                            <div class="col-sm-6 col-md-12 col-lg-12 col-xl-12 mb-3">
                                <label> Request </label>
                                <textarea type="text" placeholder="Request" formControlName="comments"
                                    class="form-control" rows="4"></textarea>
                            </div>


                        </div>

                        <div class="col-sm-12 text-center">
                            <button class="gray-btn gray-orange">Submit</button>
                        </div>

                    </form>
                </div>
            </div>
        </div>



    </div>
</section>
