import { BaseService } from './../services/baseservice';
import { Dictionary } from './../common/dictionary';
import { Injectable } from '@angular/core';
import { AppSettings } from './../common/AppSettings';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';



@Injectable()
export class WriteToCmService {
  sourceId='NDc=';
  clientId='9b21e7a4-5911-4e51-8da7-ce9f4989036e';

  public constructor(private httpService: BaseService, private http:HttpClient) {}

  WriteToCMO(model: any) {
    return this.httpService.post(AppSettings.API_ENDPOINT + AppSettings.API_WRITETOCMO_POST, model);
  }

  GenerateOTP(mobile:any){
    return this.http.get(`${AppSettings.API_ENDPOINT_Raj_Sampark}app/live/Rajsampark/Prod/RestServices/Grievance/chatbotvalidatemno/service?mobileno=${mobile}&SourceId=${this.sourceId}&client_id=${this.clientId}`);
  }

  ValidateOTP(mobile:any,otp:any,uniqueId:any){
    return this.http.get(`${AppSettings.API_ENDPOINT_Raj_Sampark}app/live/Rajsampark/Prod/RestServices/Grievance/chatbotvalidateotp/service?uniqueid=${uniqueId}&mobileno=${Number(mobile)}&otp=${Number(otp)}&SourceId=${this.sourceId}&client_id=${this.clientId}`);
  }

  SaveWriteToCM(model:any){
    model.sourceId = this.sourceId;
    return this.httpService.post(`${AppSettings.API_ENDPOINT_Raj_Sampark}app/live/Rajsampark/RestServices/Grievance.svc/AddShortGrievanceRegRequestPOST?client_id=${this.clientId}`,model);
  }
}
