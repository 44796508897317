<p>phone-booth-admin-login works!</p>
<div class="breadcrumbs-page-img">
    <div class="banner-hight-2">
        <h1>PhoneBooth</h1>
        <div class="inner-page-banner">
            <img src="assets/images/cmo-banner.png" alt="banner" class="cmo-banner">
        </div>
    </div>
</div>
<main id="main">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
            <li class="breadcrumb-item active" aria-current="page">Phone Booth</li>
        </ol>
    </nav>
    <section class="mb-3">
        <div class="login-container m-auto">
            <h2>Login</h2>
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <label for="email">Email</label>
                    <input type="email" class="form-control" id="email" name="email" formControlName="email" [ngClass]="{ 'is-invalid': loginForm.controls.email.errors && loginForm.controls.email.touched}">
                    <div class="invalid-feedback"
                        *ngIf="(loginForm.get('email')?.hasError('required') && loginForm.get('email')?.touched)">
                        {{translate.currlang !='en'? 'कृपया '+('txtEmail'|translate) +' लिखें' :
                        'Please Enter '+ ('txtEmail'|translate)}} </div>
                </div>
                <div class="form-group">
                    <label for="password">Password</label>
                    <input type="password" class="form-control" id="password" name="password" formControlName="password" [ngClass]="{ 'is-invalid': loginForm.controls.password.errors && loginForm.controls.password.touched}">
                    <div class="invalid-feedback"
                        *ngIf="(loginForm.get('password')?.hasError('required') && loginForm.get('password')?.touched)">
                        {{translate.currlang !='en'? 'कृपया '+('Password_txt'|translate) +' लिखें' :
                        'Please Enter '+ ('Password_txt'|translate)}} </div>
                </div>
                <button type="submit" class="login-button">Login</button>
                <!-- <div class="forgot-password">
                    <a href="#">Forgot Password?</a>
                </div> -->
            </form>
        </div>
    </section>
</main>