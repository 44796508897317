import { Component, OnInit, Inject } from '@angular/core';
import { TranslateService } from '../translate.service';
import { EventService } from '../services/events.services';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AchievementService } from '../services/Achievement.services';
import { AchievementViewModel } from '../Model/awards-request-model.model';
import { AchievementDepartmentCategoryViewModel, DepartmentalAchievementFilterModel } from '../Model/achievement.model';
import { DepartmentCodeEnum } from '../Enum/app-enums';

@Component({
  selector: 'app-achivementdetail',
  templateUrl: './achivementdetail.component.html',
  styleUrls: ['./achivementdetail.component.css'],
  providers: [AchievementService, EventService]
})
export class AchivementdetailComponent implements OnInit {
  PDFFile: SafeResourceUrl;
  // PDFFile: string;

  id: string;
  isEnglish: boolean;
  totalPage: number;
  achivementDetail: AchievementModel;
  achivementLatest: AchievementModel[];
  environmentUrl = environment.imageUrl + 'Publicpdf/';

  departmentCode: number = 0;
  cateCode: number = 0;
  achievementList: any[];
  achievementDepartmentCategorymodel: any;
  achievementDepartmentCategoryFilterModel: DepartmentalAchievementFilterModel = new DepartmentalAchievementFilterModel();
  DepartmentCodeEnum: DepartmentCodeEnum;

  constructor(@Inject('BASE_URL') 
  public baseUrl: string, 
  private achievementService: AchievementService, 
  private route: ActivatedRoute, 
  public translate: TranslateService, 
  private eventService: EventService, 
  public sanitizer: DomSanitizer) {
  }
  
  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get('id');
      this.getdata(this.id);
      this.getLatestAchievementData();
    });
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.cateCode = Number(params.get('id'));
      this.GetAchievementListByCategoryFromJankalyanApi()
      if (this.cateCode) {
        this.achievementDepartmentCategoryFilterModel.CategoryCode = params.get('id');
        this.achievementDepartmentCategoryFilterModel.PageSize = 101;
        this.GetDepartmentAchivement_DeptCatWiseList()
      }
    })
  }

  setLang(lang: string) {
    this.translate.use(lang);
  }

  public getdata(id: string) {
    this.achievementService.GetActiveAchievementDetailById(id).subscribe(success => {
      if (success !== undefined && success !== null) {
        this.achivementDetail = success;
        if (this.achivementDetail.PdfFIleName != undefined && this.achivementDetail.PdfFIleName != "") {
          this.PDFFile = this.sanitizer.bypassSecurityTrustResourceUrl(this.environmentUrl + this.achivementDetail.PdfFIleName);
          // this.getBase64EnglishString(this.environmentUrl + this.achivementDetail.PdfFIleName);
        }


      }
    }, error => {
      console.error(error);
    });

  }

  //public getBase64EnglishString(pdffilePath: string) {
  //  this.eventService.GetBase64StringbyPath(pdffilePath).subscribe(success => {
  //    if (success !== undefined && success !== null && success["file"] != '') {
  //      this.PDFFile = this.sanitizer.bypassSecurityTrustResourceUrl(success["file"]);
  //    } else {
  //      this.PDFFile = null;
  //    }

  //  }, error => {
  //    console.error(error);
  //  });
  //}

  public getLatestAchievementData() {

    this.achievementService.GetAchievementListForFront(1, 3, Number(this.id)).subscribe(success => {
      if (success !== undefined && success !== null) {
        this.achivementLatest = success;
      }
    }, error => {
      console.error(error);
    });
  }

  //Janklayan API
  GetDepartmentAchivement_DeptCatWiseList() {
    this.achievementList = undefined;
    this.achievementDepartmentCategoryFilterModel.DepartmentCode = DepartmentCodeEnum.CmoWebsiteCode;
    this.achievementService.GetAchievementCountWoseListFromJankalyanApi(this.achievementDepartmentCategoryFilterModel).subscribe(data => {
      if (data.IsSuccess) {
        this.achievementList = data.Data.Data as AchievementViewModel[];
      } else {
        this.achievementList = [];
      }
    }, error => {
      this.achievementList = [];
      console.error(error);
    });
  }
//Janklayan API
 GetAchievementListByCategoryFromJankalyanApi() {
    this.cateCode = Number(this.id);
    this.departmentCode = 10;
    this.achievementService.GetAchievementListFromJankalyanApi(this.departmentCode).subscribe(data => {
      if (data.IsSuccess) {
        this.achievementDepartmentCategorymodel = (data.Data as AchievementDepartmentCategoryViewModel[]).find(x => x.CategoryCode == this.cateCode);
      }
    }, error => {
      console.error(error);
    });
  }


}

interface AchievementModel {
  AchievementId: number;
  Achievement: string;
  AchievementHindi: number;
  Priority: string;
  PdfFIleName: string;
  Url: string;
  AchievementDate: string;
  AchievementDateHindi: string;
  Active: boolean;
}


