import { Injectable } from '@angular/core';
import { AppSettings } from './../common/AppSettings';
import { PortalUserFeedbackAddRequestModel } from '../Model/suggestion-feedback.model';
import { BaseService } from './baseservice';

@Injectable()
export class SuggestionFeedbackService {

  constructor(
    private httpService: BaseService
  ) { }

  AddSuggestionFeedbackData(model: PortalUserFeedbackAddRequestModel): any {
    return this.httpService.post(AppSettings.API_ENDPOINT_RW_Jankalyan + AppSettings.jankalyanApi.DepartmentProfile.AddPortalUserFeedbackCodeUrl, model);
  }

  SectorsData(keys="ddlAllSectoralDepartment",userType=0,userid=0): any {
    return this.httpService.get(`${AppSettings.API_ENDPOINT_RW_Jankalyan}${AppSettings.jankalyanApi.DepartmentProfile.SectorsUrl}?keys=${keys}&userType${userType}&userid${userid}`);
  }
}


