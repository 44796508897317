
import { StateministerService } from './../services/stateminister.service';
import { Component, Inject, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { TranslateService } from '../translate.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
declare var $: any;
@Component({
  selector: 'app-advertisment',
  templateUrl: './advertisment.component.html',
  styleUrls: ['./advertisment.component.css'],

})
export class AdvertismentComponent implements OnInit {
  rajAdvtUrl = environment.rajAdvtUrl;
  public model: AdvertisementViewModel;
  popUpUrl: SafeResourceUrl;
  constructor(@Inject('BASE_URL') public baseUrl: string,
    private translate: TranslateService, private stateministerService: StateministerService, private _sanitizer: DomSanitizer) {

  }
  ngOnInit() {
    ;
    setTimeout(() => { this.showPopUp(); }, 500);
    // this.getAdvertisment();
  }

  setLang(lang: string) {
    this.translate.use(lang);
  }

  public getAdvertisment() {
    this.stateministerService.GetAdvertisment().subscribe(success => {

      this.model = success.Data;

    }, error => {
      console.error(error);
    });

  }

  getSafeImageUrl(url) {
    return this._sanitizer.bypassSecurityTrustUrl(url);
  }

  onDownload(url) {
    window.open(url);
  }

  showPopUp() {

    this.popUpUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this.rajAdvtUrl);
    let isPopup = localStorage.getItem("IsShowPopUp");
    if (isPopup || '0' == '0') {
      localStorage.setItem("IsShowPopUp", "1");
      $('#myModal').modal('show');
    }
  }
}
export class AdvertisementViewModel {
  AdvertismentDataList: AdvertismentDataList[];
  GovAchievementDataList: GovAchievementDataList[];
  IsAdvertisementorGovermentAchivement: number;
}

export class GovAchievementDataList {
  Id: number;
  ImageUrl: string;
  UploadAttachment: string;
  DetailText: string;
}

export class AdvertismentDataList {
  SubjectEng: string;
  SubjectHin: string;
  DocumentUrl: string;
  PdfUrl: string;
}
