import { NewsService } from './../services/news.services';
import { Component, OnInit, Inject } from '@angular/core';
import { TranslateService } from '../translate.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-recent-news',
  templateUrl: './recent-news.component.html',
  styleUrls: ['./recent-news.component.css'],
  providers: [NewsService]
})
export class RecentNewsComponent implements OnInit {
  public Newsprograms: NewsModel[];
  constructor(@Inject('BASE_URL') public baseUrl: string, public translate: TranslateService,
    private newsService: NewsService) {
    this.Newsprograms = [];
  }

  ngOnInit() {
    this.getdata();
  }
  setLang(lang: string) {
    this.translate.use(lang);
  }

  chunks(array, size) {
    let results = [];
    results = [];
    while (array.length) {
      results.push(array.splice(0, size));
    }
    return results;
  }

  public getdata() {
    this.newsService.GetProgramNewsListForFront().subscribe(success => {
      if (success !== undefined && success !== null) {
        this.Newsprograms = this.chunks(success, 3);
        //this.Newsprograms = success;
       
      }
    }, error => {
      console.error(error);
    });

  }

}

interface NewsModel {
  ProgramId: number;
  ProgramCategoryId: number;
  ProgramSubCategoryId: number;
  ProgramDate: string;
  ProgramDistrictId: number;
  ProgramLocationId: number;
  ProgramAddress: string;
  ProgramAddressHindi: string;
  ProgramLanguage: string;
  ProgramSubject: string;
  ProgramSubjectHindi: string;
  ProgramSpeech: string;
  ProgramSpeechHindi: string;
  Keywords: string;
  KeywordsHindi: string;
  SpeechPdf: string;
  Pdf: string;
  IsActive: boolean;
  IsDeleted: boolean;
  ProgramStateId: number;
  ProgramTime: string;
  UserId: string;
  IsShowLatest: string;
  KeyImage: string;
  FolderName: string;
  PopupImages: string;
  PdfEnglish: string;
  Status: string;
  Times: string;
  Type: string;
  ProgramSubCategory: string;
  ProgramSubCategoryhindi: string;
  ProgramDateHindi: string;

  // district: string;
  // ProgramCategory: string;
  // ProgramCategoryhindi: string;
  // ProgramSubCategoryhindi: string;
  // ProgramSubCategory: string;
  // imagename: string;
}
