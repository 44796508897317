export enum SingleEntryDataType {
  PDF = 30404,
  Data = 30403,
  URL = 30405,
  IFrame = 31124,
}

export enum VIPCategoryEnum {
  HCM = "HCM",
  HCMCode = "96",
}

export enum VIPPersonCategoryEnum {
  ShriAshokGehlot = "ShriAshokGehlot",
  ShriAshokGehlotCode = 184,
  ShriBhajanLalSharma = "ShriBhajanLalSharma",
  ShriBhajanLalSharmaCode = 47,
}

export enum PressReleaseSubCategoryEnum {
  DayMessage = "DayMessage",
  DayMessageCode = 49,
}

export enum PressReleaseSubGeneralCatTypeEnum {
  CMOProgram = "CMOProgram",
  CMOProgramCode = 31183,
}

export enum JankalyanCategoryEnum {
  AboutDepartmentDistrict = "AboutDepartment/District",
  AboutDepartmentDistrictCode = 4,
  CMProfile = "CMOSpecific",
  CMProfileCode = 52,
  GOVTrackRecord = "GovernanceTrackRecord",
  GOVTrackRecordCode = 532,
}

export enum EntryTypeEnum {
  AboutCM = "AboutCM",
  AboutCMCode = 1,
  contactus = "contactus",
  contactusCode = 37,
  WorkDistribution = "WorkDistribution",
  WorkDistributionCode = 261,
  CMProfile = "CMProfile",
  CMProfileCode = 531,
  Tagline = "Tagline",
  TaglineCode = 544,
  GOVTrackRecord = "GovernanceTrackRecord",
  GOVTrackRecordCode = 52,
}

export enum VideoUploadCategoryEnum {
  CMEvents = "CMEvents",
  CMEventsCode = 32114,
}

export enum CategoryTypeEnum {
  FormerCMCategoryCode = 59,
  FormerCMCategory = "CMPhotoGallery",
  UpcomingCMEvtCategoryCode = 133,
  UpcomingCMEvtCategory = "Upcoming CM Events",
}

export enum SubCategoryCodeTypeEnum {
  FormerCMSubCategoryCode = 491,
  FormerCMSubCategory = "FormerChiefMinisters",
}

export enum WebistePoliciesTypeEnum {
  WebsitePolicy = "Website Policy",
  WebsitePolicyCode = "51",
}
