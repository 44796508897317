import { AchievementViewModel } from "./awards-request-model.model";

export class AchievementDepartmentCategoryViewModel {
    CategoryCode: number;
    CategoryName: string;
    CategoryNameHindi: string;
    Total: number;
  }

  
  // export class AchievementDepartmentCategoryFilterModel {
  //   DepartmentCode : number;
  //   CategoryCode: string;
  //   PageSize: number;
  // }
  export class DepartmentalAchievementFilterModel {
    AdmDepartmentCode: number | string;
    DepartmentCode: number | string;
    CategoryCode: number | string;
    PageSize: number;

    constructor() {
        this.CategoryCode = 0;
        this.AdmDepartmentCode = 0;
        this.DepartmentCode = 0;
    }
}

