export class CMprofileViewModel {
    Id: number;
    Code: number;
    TitleCode: number;
    DesignationCode: number;
    Name: string;
    NameHindi: string;
    Message: string;
    MessageHindi: string;
    PhotoPath: string;
    CreatedBy: number;
    ModifiedBy: number;
    CreatedDate: Date;
    ModifiedDate: Date;
    IsActive: boolean;
    IsDelete: boolean;
    TitleName: string;
    TitleNameHindi: string;
    DesignationName: string;
    DesignationNameHindi: string;
    LogoURL: string;
    GovtInCodeOfConduct: boolean;
    Details: any;
    DetailsInHindi: string | any;
}