export class PortalUserFeedbackAddRequestModel {
    Name: string;
    Mobile: string;
    Email: string;
    Address: string;
    SectorCode: number | string;
    DepartmentCode: number | string;
    Feedback: string;
    AppUserId: number;
    AttachmentPath:string;

    constructor() {
        this.Name = '';
        this.Mobile = '';
        this.Email = '';
        this.Address = '';
        this.SectorCode = 0;
        this.DepartmentCode = 0;
        this.Feedback = '';
        this.AppUserId = 0;
    }
}