
<script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" crossorigin="anonymous"></script>
<section class="video" *ngIf="vidPrograms.length>0">
  <div class="container">
    <div class="section-header">
      <h2>{{'Home_Our_Video'| translate}}</h2>
    </div>
    <div class="row mb-4  no-gutters">
      <div class="col-sm-4 col-12 col-md-4 col-lg-3" *ngFor="let program of vidPrograms">
        <div class="video_bx">
          <a href="javascript:void(0)" (click)="openModalDialog(this.videoUrl+program.Video)">
            <img src={{this.imageUrl}}{{program.VideoImage}} alt="" class="img-fluid"> <i class="fa fa-play" aria-hidden="true"></i>
            <div class="video_title">{{this.translate.currlang == 'hi' ? program.ProgramSubjectHindi : program.ProgramSubject}}</div>
          </a>
        </div>
      </div>
      <div class="text-center col-sm-12" *ngIf="vidPrograms.length>4"><a routerLink="/video-gallery" class=" btn btn-red mg-top-50 btn-round text-uppercase text-center">{{'View_More'| translate}}</a></div>
    </div>
  </div>
</section>















<!--<section class="video">
  <div class="container">
    <div class="row">
      <div class="heading-title text-center col-sm-12">{{'Home_Our_Video'| translate}} </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4" *ngFor="let program of vidPrograms">
        <div class="video-bx">

          <img src={{this.imageUrl}}{{program.VideoImage}} alt="" class="img-fluid"> <a href="javascript:void(0)" (click)="openModalDialog(this.videoUrl+program.Video)" class="play-btn"><i class="fas fa-play-circle"></i></a>
          <div class="video-text">
            <h2>{{this.translate.currlang == 'hi' ? program.ProgramSubjectHindi : program.ProgramSubject}}</h2>
            <div class="date"><i class="fas fa-calendar-alt"></i> &nbsp;{{program.ProgramDate}}</div>
          </div>
        </div>
      </div>
      <div class="text-center col-sm-12" *ngIf="vidPrograms.length>0"><a routerLink="/video-gallery" class=" btn btn-red mg-top-50 btn-round text-uppercase text-center">{{'View_More'| translate}}</a></div>
    </div>
  </div>
</section>-->
<div class="backdrop" [ngStyle]="{'display':display}"></div>

<!-- modal -->
<div class="modal" tabindex="-1" role="dialog" style="z-index: 99999999999999;" [ngStyle]="{'display':display}">
  <!-- modal-dialog -->
  <div class="modal-dialog" role="document">
    <!-- modal-content -->
    <div class="modal-content">
      <!-- modal-header -->
      <div class="modal-header">
        <h4 class="modal-title">{{'Program_Video'| translate}}</h4>
        <!--<button type="button" class="close" aria-label="Close" (click)="closeModalDialog()"><span aria-hidden="true">&times;</span></button>-->
      </div>
      <!-- modal-body -->
      <div class="modal-body">
        <mat-sidenav-container class="sidenav-container">
          <mat-sidenav-content>
            <div class="content video">
              <!-- <mat-video #vid [ngClass]="ngclass" [src]="Url" [title]="title" [autoplay]="autoplay" [preload]="preload"
                         [loop]="loop" [fullscreen]="fullscreen" [download]="download" [quality]="quality" [keyboard]="keyboard"
                         [color]="color" [spinner]="spinner" [poster]="poster" [overlay]="overlay" [(muted)]="muted"
                         [showFrameByFrame]="showFrameByFrame" [(time)]="currentTime">

              </mat-video> -->
            </div>
          </mat-sidenav-content>
        </mat-sidenav-container>
      </div>
      <!-- modal-footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="closeModalDialog()">{{'Close'| translate}}</button>
      </div>
    </div>
  </div>
</div>
<style>
  .progress {
  }

  .progress-bar {
  }
</style>
