import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { PhoneBoothService } from '../services/phone-booth.service';
import { TranslateService } from '../translate.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-phone-booth-admin-login',
  templateUrl: './phone-booth-admin-login.component.html',
  styleUrls: ['./phone-booth-admin-login.component.css']
})
export class PhoneBoothAdminLoginComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private phoneBoothService: PhoneBoothService,
    public translate: TranslateService,
    public sanitizer: DomSanitizer,
    private router: Router
  ) {}
  email = environment.TEST_CERD_EMAIL;
  password = environment.TEST_CRED_PASSWORD;
  loginForm = this.fb.group({
    email: ["", Validators.required],
    password: ["", Validators.required],
  })

  ngOnInit(){
    
  }

  onSubmit(){
    this.loginForm.markAllAsTouched();
    if(this.loginForm.valid){
      if(this.loginForm.value.email == this.email && this.loginForm.value.password == this.password){
        const cred = btoa(this.loginForm.value.email+this.loginForm.value.password)
        localStorage.setItem('token',cred);
        this.router.navigate(['/phone-booth-admin']);
      }
    }
  }
}
