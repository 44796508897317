export class DDLModel {
    //ddlPressReleaseCategory: DdlItemModel[] = [];
    RadioStateDistrict: DdlItemModel[] = [];
    ddlDepartmentImportantDecision: DdlItemModel[] = [];
    ddlHelipadMaster: DdlItemModel[] = [];
    ddlHCMInvitationCategory: DdlItemModel[] = [];
    ddlHCMInvitationSenderType: DdlItemModel[] = [];
    ddlDistrict: DdlItemModel[] = [];
    ddlAdminDepartment: DdlItemModel[] = [];
}

export class DdlItemModel {
    Value: any;
    Text: string;
    OtherData?: any;
}

export class FilterDDlModel {
    FilterFor: string;
    Value: any;
    FilterFrom: string;
}
export class PageTitleModel {
    NameHindi: string;
    NameEnglish: string;
    ImagePath: string;
  }
  