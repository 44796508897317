import { Component, OnInit } from '@angular/core';
import { TranslateService } from '../translate.service';

@Component({
  selector: 'app-aboutrajasthan',
  templateUrl: './aboutrajasthan.component.html',
  styleUrls: ['./aboutrajasthan.component.css']
})
export class AboutrajasthanComponent implements OnInit {

  constructor(public translate: TranslateService) { }

  ngOnInit() {
  }
  setLang(lang: string) {
    this.translate.use(lang);
  }

}
