import { Component, AfterContentChecked, ViewChild, ElementRef, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from './translate.service';
import { DepartmentService } from './services/department.service';
import { DepartmentCodeEnum } from './Enum/app-enums';
import { DepartmentDetailModel } from './Model/department-model.model';
import { environment } from '../environments/environment';
import { AppService } from './services/app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterContentChecked, OnInit {
  islogin = true;
  title = 'app';
  isHomePage: boolean;
  fontSize = 14;
  environment = environment
  @ViewChild('pageBody') pageBody: ElementRef;
  deptCode = DepartmentCodeEnum.CmoWebsiteCode;
  isBannerRequired = true;
  isNewsTickerRequired = true;
  isExternalLinkRequired = true;
  dptModel: DepartmentDetailModel;

  constructor
    (
      private readonly _route: Router, public readonly lang: TranslateService,
      private readonly departmentProfile: DepartmentService, private _elementRef: ElementRef,
      public appService: AppService,) {


    lang.currlang = localStorage.getItem("lang") != undefined ? localStorage.getItem("lang") : "en";
    this.getDepartmentDetail();
    this.GetDepartmentMenuList(this.deptCode);

    // if (this._route.url.replace('/', '').includes('launching')) {
    //   sessionStorage.clear();
    // }

    // if (!(sessionStorage.getItem('IsAuthenticate'))) {
    //   if ((!window.location.href.includes('departmentscheme') || !window.location.href.includes('departmentorder'))) {
    //     this.islogin = true;
    //   }
    //   else {
    //     this._route.navigate(['login']);
    //   }
    // }
    // else {
    //   this.islogin = true;
    // }


  }
  ngOnInit(): void {
    this._elementRef.nativeElement.removeAttribute("ng-version");
  }


  ngAfterContentChecked() {

    this.isHomePage = true;
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    localStorage.removeItem('IsShowPopUp');
    localStorage.removeItem('IsCodeOfConduct');
    localStorage.removeItem('lang');
  }

  setFont(size?: number) {

    if (size == undefined) {
      this.fontSize = (localStorage.getItem("fontSize") != null ? Number(localStorage.getItem("fontSize")) : this.fontSize);
    }
    else {
      this.fontSize = size;
      localStorage.setItem("fontSize", this.fontSize.toString())
    }
    (this.pageBody.nativeElement as HTMLParagraphElement).style.fontSize = `${this.fontSize}px`;

  }


  getDepartmentDetail() {
    this.departmentProfile.GetDepartmentDetailByDptCode(this.deptCode, this.isBannerRequired, this.isNewsTickerRequired, this.isExternalLinkRequired).subscribe((response) => {
      if (response.IsSuccess) {
        let deptData = response.Data as DepartmentDetailModel;
        this.dptModel = deptData as DepartmentDetailModel;
        // localStorage.setItem("IsCodeOfConduct", String(this.dptModel.IsCodeOfConduct));
        localStorage.setItem("IsCodeOfConduct", String(false));
        this.departmentProfile.departmentModel.next(this.dptModel);
      }
    }, (error) => {
    });
  }

  VarifyLogin(isLogin: boolean = false) {
    this.islogin = isLogin;
  }

  GetDepartmentMenuList(deptCode: number) {
    this.departmentProfile.GetDepartmentMenuList(deptCode).subscribe((response) => {
      if (response.IsSuccess) {
        let menuListModel = response.Data;
        if (menuListModel) {
          this.departmentProfile.setDeptMenuList(menuListModel);
        }
      }
    }, (error) => {
      console.error(error);
    });
  }

}
