import { Component, OnInit } from '@angular/core';
import { TranslateService } from '../translate.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { DepartmentProfileFilterModel, DepartmentProfileViewModel, PageTitleModel } from '../Model/department-model.model';
import { CommonService } from '../services/common.service';
import { SingleEntryDataType } from '../Enum/jankayan.enum';
import { DepartmentService } from '../services/department.service';
import { LoadingService } from '../services/loading.service';

@Component({
  selector: 'app-jankalyan-category-and-entry-type',
  templateUrl: './jankalyan-category-and-entry-type.component.html',
  styleUrls: ['./jankalyan-category-and-entry-type.component.css'],
  providers: [DepartmentService,LoadingService,CommonService]
})
export class JankalyanCategoryAndEntryTypeComponent implements OnInit {

  pageTitle: PageTitleModel;
  departmentProfileModel: DepartmentProfileViewModel[];
  dataModel = new DepartmentProfileViewModel();
  submenuCode: number = 0;
  departmentProfileFilterModel = new DepartmentProfileFilterModel();
  entryDataType = SingleEntryDataType;

  constructor(
    public readonly translate: TranslateService,
    public sanitizer: DomSanitizer,
    private _route: ActivatedRoute,
    public readonly commonService: CommonService,
    private departmentProfile: DepartmentService,
    private loadingService: LoadingService
  ) { }

  ngOnInit() {
    this._route.paramMap.subscribe((params: ParamMap) => {
      this.pageTitle = undefined;
      let _submenuCode = params.get("submenuCode");
      this.submenuCode = Number(_submenuCode || 0);

      // let deptCode = this.commonService.GetWebDistDeptCode();
      // let param_deptCode = params.get("deptCode");
      //if (param_deptCode) { deptCode = Number(param_deptCode); }

      // this.orderFilterModel.DepartmentCode =  deptCode;
      // this.departmentProfileFilterModel.DepartmentCode = deptCode;

      // this.orderFilterModel.DepartmentCode = param_deptCode ?? deptCode;
      // this.departmentProfileFilterModel.DepartmentCode = param_deptCode ?? deptCode;

      let param_catCode = params.get("catCode");
      this.departmentProfileFilterModel.JankalyanCategoryCode = param_catCode || 0;
      this.departmentProfileFilterModel.EntryTypeCode = params.get("entryTypeCode") || 0;
      this.departmentProfileFilterModel.LookupCode = params.get("lookupCode") || 0;

      // if (param_catCode == JankalyanCategoryEnum.GIGWGuidelinesCode
      //   || param_catCode == JankalyanCategoryEnum.WebsitePolicyCode) {
      //   this.departmentProfileFilterModel.DepartmentCode = deptCode;
      // }

      // this.getPageHeading();
      this.getDepartmentProfileListData();
    });
  }
  

  getDepartmentProfileListData() {
    this.loadingService.show();
    // this.IsLoading = true;
    this.dataModel = undefined;
    this.departmentProfileFilterModel.IndexModel.PageSize = 1;

    this.departmentProfile.GetList(this.departmentProfileFilterModel).subscribe(data => {
      if (data.IsSuccess) {
        this.loadingService.hide();
        this.departmentProfileModel = data.Data.Data;
        if (this.departmentProfileModel && this.departmentProfileModel.length == 1) {
          this.dataModel = this.departmentProfileModel[0];
          if (this.submenuCode == 0) {
            this.pageTitle = new PageTitleModel();
            this.pageTitle.NameEnglish = this.pageTitle.NameEnglish ? this.pageTitle.NameEnglish : this.dataModel.EntryTypeName;
            this.pageTitle.NameHindi = this.pageTitle.NameHindi ? this.pageTitle.NameHindi : this.dataModel.EntryTypeNameHindi;
            this.pageTitle.ImagePath = this.pageTitle.ImagePath ? this.pageTitle.ImagePath : this.dataModel.ImageURL;
          }
          if (this.dataModel.PDFURL) {
            this.dataModel.PDFURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.dataModel.PDFURL);
          }
          if (this.dataModel.DataTypeCode == this.entryDataType.URL && !this.dataModel.Details) {
            window.open(this.dataModel.url, "_blank");
            //window.location.href = this.dataModel?.url;
            this.commonService.gotoPreviousPage();
          }
          if (this.dataModel.DataTypeCode == this.entryDataType.IFrame && this.dataModel.url) {
            this.dataModel.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.dataModel.url);
          }
        }
        else {
          this.dataModel = new DepartmentProfileViewModel();
        }
        // if (!this.departmentProfileFilterModel.IndexModel.IsPostBack) {
        //   this.departmentProfileTotalRecords = data?.Data?.TotalRecords || 0;
        // }
        // this.departmentProfileUpdatePageConfig();
      }
      else {
        this.dataModel = {} as DepartmentProfileViewModel;
      }
      // this.IsLoading = false;
    }, error => {
      this.dataModel = {} as DepartmentProfileViewModel;
      // this.IsLoading = false;
      //console.error(error.message);
    });
  }

}
