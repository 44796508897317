import { IndexModel } from "./awards-request-model.model";


export class CMProfileModel {
    IndexModel = new IndexModel();
    DepartmentCode: number | string;
    OfficeCode: number | string;
    EntryTypeCode: number | string;
    JankalyanCategoryCode: number | string;
    LookupCode: number | string;
  
    constructor() {
        this.DepartmentCode = 0;
        this.OfficeCode = 0;
        this.EntryTypeCode = 0;
        this.JankalyanCategoryCode = 0;
        this.LookupCode = 0;
    }
  }

  export class CMProfileViewModel {
    Id: number;
    Code: number;
    DepartmentCode: number | string;
    EntryTypeCode: number | string;
    DataTypeCode: number;
    Details: any;
    ImageURL: string;
    PDFURL: string | any;
    CreatedDate: Date;
    CreatedBy: number;
    ModifiedDate: Date;
    ModifiedBy: number;
    IsActive: boolean;
    IsDeleted: boolean;
    DepartmentTitle: string;
    DepartmentTitleHindi: string;
    DepartmentShortTitle: string;
    DepartmentShortTitleHindi: string;
    EntryTypeName: string;
    EntryTypeNameHindi: string;
    EntryTypeDisplayOrder: number;
    DetailsInHindi: string | any;
    url: string;
  }
  