export class CMLiveProgram {
  AdvanceSearchModel: {
    CategoryCode: any;
    DepartmentCode: any;
    SubCategoryCode: any;
    Search:any;
  };
  PageSize: Number;
  IsPostBack: Boolean;
  OrderByAsc: Number;
  OrderBy: String;
  Page: Number;
  ToDate: any;
  FromDate: any;

  constructor() {
    this.AdvanceSearchModel = {
      // Initialize the object here
      CategoryCode: "",
      DepartmentCode: "",
      SubCategoryCode: "",
      Search: "",
    };
    this.PageSize = 0;
    this.IsPostBack = false;
    this.OrderByAsc = 0;
    this.OrderBy = "";
    this.Page = 0;
    this.ToDate = ''
    this.FromDate = ''
  }
}
