import { Data } from './../services/data.services';
import { EventService } from './../services/events.services';
import { Component, Inject, OnInit } from '@angular/core';
import { PagerService } from '../pager.service';
import { environment } from '../../environments/environment';
import { TranslateService } from '../translate.service';
// import { DatepickerOptions } from 'ng2-datepicker';
import { InAugrationListModel, PressReleaseFrontSearchModel } from '../Model/press-release-model.model';
import { NewsService } from '../services/news.services';
import { DepartmentCodeEnum, PressReleaseCategoryTypeEnum } from '../Enum/app-enums';
import { PressReleaseSubGeneralCatTypeEnum, VIPCategoryEnum, VIPPersonCategoryEnum } from '../Enum/jankayan.enum';
import { LookupEnum } from '../Enum/lookup-type.enum';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-event-listing',
  templateUrl: './event-listing.component.html',
  styleUrls: ['./event-listing.component.css'],
  providers: [EventService,NewsService,DatePipe]
})
export class EventListingComponent implements OnInit {
  programs: EventModel[];
  // paged items
  totalPage: number;
  pagedItems: InAugrationListModel[];
  searchBox = '';
  // pager object
  pager: any = {};
  currentPage = 1;
  // date = '';
  // FromDate: Date;
  // ToDate: Date;
  // options: DatepickerOptions = {
  //   minYear: 2018,
  //   maxYear: 2030,
  //   maxDate: new Date(Date.now()),
  //   minDate: environment.defaultDate,
  // }

  filterModel = new PressReleaseFrontSearchModel();
  generalModel: InAugrationListModel[];
  IsLoading = true;
  totalRecords = 0;
  categoryCode: number = 0;
  DepartmentCodeEnum: DepartmentCodeEnum;
  pageSize = 8;
  today = new Date();
  fromDate:any;
  toDate:any;
  days = 10; // Days you want to subtract
  date = new Date();
  lastDate = new Date('2023-12-15') //new Date(this.date.getTime() - (this.days * 24 * 60 * 60 * 1000));
  searchText:any = '';

  constructor(@Inject('BASE_URL') public baseUrl: string,
    private pagerService: PagerService, public translate: TranslateService,
    private eventService: EventService, private data: Data, private datePipe: DatePipe,

    private newsService: NewsService) {
    this.pagedItems = [];
    this.programs = [];
    // this.FromDate = environment.defaultDate;
    // this.ToDate = new Date();
  }
  ngOnInit() {
    this.fromDate = this.datePipe.transform(this.lastDate,'yyyy-MM-dd')
    this.toDate = this.datePipe.transform(this.today,'yyyy-MM-dd')
    // if (this.data.storage !== undefined) {
    //   this.date = this.data.storage.date;
    //   this.data.storage = undefined;
    // }
    this.GetPressReleaseData(this.currentPage,this.pageSize);
  }

  setLang(lang: string) {
    this.translate.use(lang);
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  setPage(page: number,pagesize:any) {
    window.scroll(0, 0);
    this.currentPage = page;
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }

    // get pager object from service
    if (this.generalModel.length > 0) {
      this.pager = this.pagerService.getPager(Number(this.generalModel[0].TotalRecords), page, pagesize);
      // get current page of items
      this.pagedItems = this.generalModel;
    } else {
      this.pagedItems = [];
    }

  }

  onPageSize(evt:any){
    this.pageSize = evt.target.value
    this.GetPressReleaseData(this.currentPage,this.pageSize)
  }

  onSearch(){
    this.GetPressReleaseData(this.currentPage,this.pageSize)
  }

     //Janklayan API
     GetPressReleaseData(page: number,pagesize:any) {
      this.filterModel.Page = page;
      this.filterModel.IsBase64File = false;
      this.filterModel.CategoryCode = PressReleaseCategoryTypeEnum.PressReleaseCode;
      this.filterModel.VIPCategoryCode = VIPCategoryEnum.HCMCode;
      // this.filterModel.VIPPersonCode = VIPPersonCategoryEnum.ShriAshokGehlotCode;
      this.filterModel.VIPPersonCode = VIPPersonCategoryEnum.ShriBhajanLalSharmaCode;
      this.filterModel.SubCat_GECatTypeCode = PressReleaseSubGeneralCatTypeEnum.CMOProgramCode;
      this.filterModel.OrderBy = "PressreleaseDate";
      this.IsLoading = true;
      this.filterModel.DepartmentCode = DepartmentCodeEnum.CmoWebsiteCode;
      this.filterModel.PageSize = pagesize;
      this.filterModel.PressReleaseToDate = this.toDate;
      this.filterModel.PressReleaseFromDate = this.fromDate;
      this.filterModel.Description = this.searchText;
      this.filterModel.PressReleaseLevelCode = LookupEnum.PressReleaseLevelCode_For_State;

      //this.filterModel.DepartmentCode = 18
      //this.filterModel.CategoryCode = 36;
      //this.filterModel.VIPCategoryCode = "96";
      //this.filterModel.VIPPersonCode = 184;
      //this.filterModel.SubCategoryCode = 57;
  
      this.newsService.GetPressReleaseList(this.filterModel).subscribe((response) => {
        if (response.IsSuccess) {
          this.generalModel = response.Data.Data as InAugrationListModel[];
          this.generalModel.map((item)=>{
            if(item.PressReleaseDateHindi){
              item.PressReleaseDateHindi = item.PressReleaseDateHindi.split('-') as any
            }
          })
          this.totalRecords = response.Data.TotalRecords;
          this.setPage(page,pagesize);
        }
        this.IsLoading = false;
      }, (error) => {
        console.error(error);
      });
    }
}

export class EventModel {
  ProgramId: number;
  ProgramCategoryId: number;
  ProgramSubCategoryId: number;
  ProgramDate: string;
  ProgramDistrictId: number;
  ProgramLocationId: number;
  ProgramAddress: string;
  ProgramAddressHindi: string;
  ProgramLanguage: string;
  programSubject: string;
  programSubjectHindi: string;
  ProgramSpeech: string;
  ProgramSpeechHindi: string;
  Keywords: string;
  KeywordsHindi: string;
  SpeechPdf: string;
  Pdf: string;
  IsActive: boolean;
  IsDeleted: boolean;
  ProgramStateId: number;
  ProgramTime: string;
  UserId: string;
  IsShowLatest: string;
  KeyImage: string;
  FolderName: string;
  PopupImages: string;
  PdfEnglish: string;
  Status: string;
  District: string;
  ProgramCategory: string;
  ProgramCategoryhindi: string;
  ProgramSubCategoryhindi: string;
  ProgramSubCategory: string;
  TotalEvents: number;
  ProgramDateHindi: string;
  BannerImages: string;
}


