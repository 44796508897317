import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DDLModel, DdlItemModel } from '../../Model/common.model';
import { RequestAddModel } from '../../Model/hcm-invitation/request-add-model.model';
import { AppSettings } from '../../common/AppSettings';
import { CommonService } from '../../services/common.service';
import { RequestService } from '../../services/hcm-invitation/request.service';
import { PortalEnum } from '../../Enum/portal.enum';
import { InvitationRequestStatusEnum } from '../../Enum/hcm-Invitation.enum';
// import { DatepickerOptions } from 'ng2-datepicker';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-add-request',
  templateUrl: './add-request.component.html',
  styleUrls: ['./add-request.component.css'],
  providers: [RequestService]
})
export class AddRequestComponent implements OnInit {

  public model: RequestAddModel;
  public dropdowns: DDLModel = new DDLModel();
  ddlHelipad: DdlItemModel[] = [];
  ddlHCMInvitationCategory: DdlItemModel[] = [];

  fileValidationMsg: string = "";
  fileSizeValidationMsg: string;

  // options: DatepickerOptions = {
  //   minYear: 2018,
  //   maxYear: 2030,
  //   maxDate: new Date(Date.now()),
  //   minDate: environment.defaultDate,
  // }

  constructor(
    private readonly _router: Router,
    private readonly _addService: RequestService,
    public readonly _commonservice: CommonService,) {
    this.model = new RequestAddModel();
    this.fileSizeValidationMsg = " (Max " + (this._commonservice.GetMaxFileSizeInMB(1.2)) + " MB.)";
  }

  ngOnInit() {
    this.getDdl();
  }

  get Form() {
    return this._addService.Form;
  }
  get senderName() {
    return this._addService.senderName;
  }
  get organizationName() {
    return this._addService.organizationName;
  }
  get senderTypeCode() {
    return this._addService.senderTypeCode;
  }
  get senderEmail() {
    return this._addService.senderEmail;
  }
  get senderDistrictCode() {
    return this._addService.senderDistrictCode;
  }
  get senderPhoneNo() {
    return this._addService.senderPhoneNo;
  }
  get senderAddress() {
    return this._addService.senderAddress;
  }
  get invitationCategoryCode() {
    return this._addService.invitationCategoryCode;
  }
  get subject() {
    return this._addService.subject;
  }
  get programDistrictCode() {
    return this._addService.programDistrictCode;
  }
  get programVenue() {
    return this._addService.programVenue;
  }
  get comments() {
    return this._addService.comments;
  }
  get vipCodes() {
    return this._addService.vipCodes;
  }
  get requestDateFrom() {
    return this._addService.requestDateFrom;
  }
  get requestDateTo() {
    return this._addService.requestDateTo;
  }
  get attachmentPath() {
    return this._addService.attachmentPath;
  }
  get attachmentBasePath() {
    return this._addService.attachmentBasePath;
  }

  public onSubmit() {
    this.Form.markAllAsTouched();

    if (this.Form.invalid) {
      return;
    }
    this.mapToModel();
    this.addRequest();
  }

  private addRequest() {
    if (this.Form.invalid) {
      return;
    }
    this._addService.addRequest(this.model).subscribe((response) => {
      if (response.IsSuccess) {
        this._commonservice.alertMsg("Success!", "Request Sent Successfully, Your Request Id is " + response.Data, "info");
        this._router.navigate(["/home"]);
      }
    });
  }

  private mapToModel() {
    this.model = this.Form.value;
    this.model.senderName = this.model.senderName;
    this.model.organizationName = this.model.organizationName;
    this.model.senderTypeCode = +this.model.senderTypeCode;
    this.model.senderEmail = this.model.senderEmail;
    this.model.senderDistrictCode = +this.model.senderDistrictCode;
    this.model.senderPhoneNo = this.model.senderPhoneNo;
    this.model.senderAddress = this.model.senderAddress;
    this.model.invitationCategoryCode = +this.model.invitationCategoryCode;
    this.model.subject = this.model.subject;
    this.model.programDistrictCode = +this.model.programDistrictCode;
    this.model.programVenue = this.model.programVenue;
    this.model.comments = this.model.comments;
    this.model.requestDateFrom = this.model.requestDateFrom;
    this.model.requestDateTo = this.model.requestDateTo;
    this.model.statusId = InvitationRequestStatusEnum.Pending;
    this.model.portalCode = PortalEnum.CMOWebsite;
  }

  private getDdl() {
    this._commonservice
      .GetAllDDL(AppSettings.DDlKeyForHCMInvitation)
      .subscribe((result) => {
        if (result.IsSuccess) {
          if (result.Data) {
            this.dropdowns = result.Data;
            this.ddlHelipad = this.dropdowns.ddlHelipadMaster;
            this.ddlHCMInvitationCategory = this.dropdowns.ddlHCMInvitationCategory.filter(x => x.OtherData.IsProjectDetailRequired == false);
          }
        }
      });
  }


  handleFileInput(event: any) {
    let fileMaxSize = this._commonservice.GetMaxFileSizeInByte(1.2);   // set as 100 MB as per saini sir requirement
    let ext = this._commonservice.getFileExtension(event.target.files.item(0).name);
    if (ext == 'pdf') {
      if (event.target.files.item(0).size <= fileMaxSize) {

        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.attachmentBasePath.setValue(event.target.result);
          this.attachmentPath.setValue(event.target.result);
        };
        reader.readAsDataURL(event.target.files.item(0));
        this.attachmentPath.setValue('');
        this.fileValidationMsg = "";
      }
      else {
        this.attachmentPath.setValue('');
        this.attachmentBasePath.setValue('');
        this.fileValidationMsg = this.fileSizeValidationMsg;
        return
      }
    }
    else {
      this.attachmentPath.setValue('');
      this.attachmentBasePath.setValue('');
      this.fileValidationMsg = "only *pdf file accepted ";
      return
    }
  }

  downloadPdf(url, name) {
    if (url) {
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", name);
      link.target = "blank";
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }

  RemoveFile() {
    this.attachmentPath.setValue('');
    this.attachmentBasePath.setValue('');
  }

}
