<section class="about-us">
  <div class="container">
    <div class="row">
      <div class="col-sm-4"> <img src="../../assets/images/cm.png" alt="" class="img-fluid bx-shadow"> </div>
      <div class="col-sm-1">&nbsp;</div>
      <div class="col-sm-7">
        <div class="pos-title">Hon'ble Chief Minister</div>
        <div class="cm-name">Shri Bhajanlal Sharma</div>
        <p>
          माननीय मुख्यमंत्री
          श्री भजन लाल शर्मा
        </p>
        <a routerLink="/profilecm" class=" btn btn-red mg-top-30 btn-round text-uppercase">{{'Read_More'|
          translate}}</a>
      </div>
    </div>
  </div>
</section>