import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '../translate.service';
import { environment } from '../../environments/environment';
import { EventService } from '../services/events.services';
import { DatePipe, formatDate } from '@angular/common';
import { AchievementAllSearchModel, AchievementViewModel } from '../Model/awards-request-model.model';
import { AchievementCategoryEnum, JankalyanEntryCategoryTypeEnum, JankalyanEntryTypeEnum } from '../Enum/achievement-category.enum';
import { DepartmentCodeEnum } from '../Enum/app-enums';
import { AchievementService } from '../services/Achievement.services';
import { DepartmentDetailModel, DepartmentProfileFilterModel, DepartmentProfileViewModel } from '../Model/department-model.model';
import { DepartmentService } from '../services/department.service';
import { CommonService } from '../services/common.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { registerLocaleData } from '@angular/common';
import localeHi from '@angular/common/locales/hi'; // Import Hindi locale data

registerLocaleData(localeHi); // Register Hindi locale data

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  providers: [EventService, AchievementService, DatePipe]
})
export class FooterComponent implements OnInit {
  WebsiteInfo: WebsiteInfoModel;
  fileUrl = environment.imageUrl + 'publicpdf1/Work_Distribution.pdf';
  achievementEnum = AchievementCategoryEnum;
  footerFixIcons: AchievementViewModel[] = [];
  dptModel = new DepartmentDetailModel();
  // isCodeOfConduct: boolean = true;
  isCodeOfConduct: boolean = false;
  externalLinks: AchievementViewModel[] = [];
  importantLinks: AchievementViewModel[] = [];
  nodalOfficerDetail: DepartmentProfileViewModel;
  cmReliefData:any=[];
  janCatEnum = JankalyanEntryCategoryTypeEnum;
  jankalyanEntryTypeEnum = JankalyanEntryTypeEnum;
  visitorCount:any;
  constructor(
    public translate: TranslateService,
    private eventService: EventService,
    private _achievementService: AchievementService,
    private readonly departmentProfile: DepartmentService,
    public readonly _commonService: CommonService,
    public sanitizer: DomSanitizer,
    private datePipe: DatePipe

  ) {
    this.isCodeOfConduct = this._commonService.GetIsCodeOfConduct();
    this.WebsiteInfo = new WebsiteInfoModel();
    this.WebsiteInfo.UpdateDate = formatDate(new Date(), 'dd-MMM-yyyy', 'en').toString();
    this.WebsiteInfo.UpdateDateHindi = formatDate(new Date(), 'dd-MMMM-yyyy', 'hi').toString();
  }
  setLang(lang: string) {
    this.translate.use(lang);
  }
  ngOnInit() {
    this.GetExternalLink();
    this.GetFooterFixIcons();
    this.GetImportantLinks();
    this.GetVisitorCount();
    this.departmentProfile.departmentModel.subscribe((res) => {
      if (res) {
        this.dptModel = res;
        // this.externalLinkList = res.ExternalLinkList; 
      }
    });
    this.getNodalOfficerDetail();
    // this.getCMReliefData()
  }
  GetFooterFixIcons() {
    let filterModel = new AchievementAllSearchModel();
    filterModel.IndexModel.OrderBy = "Priority";
    filterModel.IndexModel.OrderByAsc = 1;
    filterModel.IndexModel.PageSize = 101;
    filterModel.CategoryCode = this.achievementEnum.FooterIconsFixOnAllWebsites;
    filterModel.DepartmentCode = Number(DepartmentCodeEnum.JankalyanWebPortal);

    this._achievementService.GetAchievementListBySearchFilterData(filterModel, false).subscribe((response) => {
      if (response.IsSuccess) {
        this.footerFixIcons = response.Data.Data;
      }
    }, (error) => {
      console.error(error);
    });
  }

  GetVisitorCount() {
    this._achievementService.GetVisitorCount(DepartmentCodeEnum.CmoWebsiteCode).subscribe((response:any) => {
     this.visitorCount = response.Data;
    }, (error) => {
      console.error(error);
    });
  }

  GetExternalLink() {
    let filterModel = new AchievementAllSearchModel();
    filterModel.IndexModel.OrderBy = "Priority";
    filterModel.IndexModel.OrderByAsc = 1;
    filterModel.IndexModel.PageSize = 101;
    filterModel.CategoryCode = this.achievementEnum.ExternalLinksFooterCode;
    filterModel.DepartmentCode = DepartmentCodeEnum.CmoWebsiteCode;

    this._achievementService.GetAchievementListBySearchFilterData(filterModel, false).subscribe((response) => {
      if (response.IsSuccess) {
        this.externalLinks = response.Data.Data;
      }
    }, (error) => {
      console.error(error);
    });
  }

  GetImportantLinks() {
    let filterModel = new AchievementAllSearchModel();
    filterModel.IndexModel.OrderBy = "Priority";
    filterModel.IndexModel.OrderByAsc = 1;
    filterModel.IndexModel.PageSize = 101;
    filterModel.CategoryCode = this.achievementEnum.ImportantWebLinksCode;
    filterModel.DepartmentCode = DepartmentCodeEnum.CmoWebsiteCode;

    this._achievementService.GetAchievementListBySearchFilterData(filterModel, false).subscribe((response) => {
      if (response.IsSuccess) {
        this.importantLinks = response.Data.Data;
        console.log('imp', this.importantLinks)
      }
    }, (error) => {
      console.error(error);
    });
  }

  safeUrl(url): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  getCMReliefData(){
    this._achievementService.GetCMRelef().subscribe(success => {
      // console.log("HHHHH")
    }, error => {
      console.error(error);
    });
  }

  getNodalOfficerDetail() {
    let filterModel = new DepartmentProfileFilterModel();
    filterModel.IndexModel.PageSize = 1;
    filterModel.DepartmentCode = Number(DepartmentCodeEnum.CmoWebsiteCode);
    filterModel.EntryTypeCode = Number(
      JankalyanEntryTypeEnum.FooterNodalOfficerDetailEnglishCode
    );
    this.departmentProfile.GetList(filterModel).subscribe((data) => {
      if (data.IsSuccess) {
        if (data && data.Data && data.Data.Data[0]) {
          this.nodalOfficerDetail = data.Data.Data[0];
        }

        if (this.nodalOfficerDetail) {
          this.nodalOfficerDetail.Details = (this.sanitizer.bypassSecurityTrustHtml(this.nodalOfficerDetail.Details) as any).changingThisBreaksApplicationSecurity;
          this.nodalOfficerDetail.DetailsInHindi = (this.sanitizer.bypassSecurityTrustHtml(this.nodalOfficerDetail.DetailsInHindi) as any).changingThisBreaksApplicationSecurity;
        }
      }
    }, (error) => {
      console.error(error.message);
    });
  }

  getFormattedDate(date: Date, locale: string): string | null {
    return this.datePipe.transform(date, 'dd-MMM-yyyy, h:mm a', undefined, locale);
  }

  onOpen(){
    window.open('https://sampark.rajasthan.gov.in/Grievance_Entry/Complain_Request.aspx#stay', 'popupWindow', 'width=1100,height=600,scrollbars=yes');
  }


}
export class WebsiteInfoModel {
  UpdateDate: string;
  UpdateDateHindi: string;
  UpdateScreen: string;

}
