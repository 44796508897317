import { Component, OnInit } from '@angular/core';
import { MediaService } from '../services/media.services';
import { AchievementCatgoryModel, AchievementSubCategoryModel, AchievementViewModel, DepartmentSubMenuDetailModel, IndexModel } from '../Model/awards-request-model.model';
import { PagerService } from '../pager.service';
import { TranslateService } from '../translate.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { PageTitleModel } from '../Model/department-model.model';

@Component({
  selector: 'app-photo-gallery',
  templateUrl: './photo-gallery.component.html',
  styleUrls: ['./photo-gallery.component.css'],
  providers: [MediaService]
})
export class PhotoGalleryComponent implements OnInit {

  pageTitle: PageTitleModel;
  pageName: string = "";
  category: AchievementCatgoryModel = new AchievementCatgoryModel();
  pagedItems: AchievementViewModel[];
  posterModel: AchievementViewModel[];
  posterIndexModel = new IndexModel();
  departmentCode: number = 0;
  submenuCode: number;
  catCode: number = 0;
  subCatCode: number;
  totalRecords = 0;
  pager: any = {};
  currentPage: number;
  isShowDownload = false;
  pageSize = 16;

  constructor(
    private mediaService: MediaService,
    private pagerService: PagerService,
    public translate: TranslateService,
    private _route: ActivatedRoute,
    private readonly route: Router,) {

    this.currentPage = 1;
  }

  ngOnInit() {
    this._route.paramMap.subscribe((params: ParamMap) => {
      let url = this.route.url.replace('/', '').split('/');
      this.departmentCode = this._route.snapshot.params.dptCode;
      let _catCode = this._route.snapshot.params.catCode;
      if (_catCode) {
        this.catCode = Number(_catCode) || 0;
      }
      let _subCatCode = this._route.snapshot.params.subCatCode;
      if (_subCatCode) {
        this.subCatCode = Number(_subCatCode) || 0;
      }
      let _submenuCode = this._route.snapshot.params.submenuCode;
      this.submenuCode = Number(_submenuCode || 0);
      if (this.submenuCode > 0 || url[0] == 'sm') {
        this.pageName = (this.subCatCode || this.subCatCode == 0) ? url[url.length - 5] : ((this.catCode || this.catCode == 0) ? url[url.length - 4] : url[url.length - 3]);
      }
      else {
        this.pageName = (this.subCatCode || this.subCatCode == 0) ? url[url.length - 4] : ((this.catCode || this.catCode == 0) ? url[url.length - 3] : url[url.length - 2]);
      }
      this.getPageHeading();
      this.getData();
    });
  }

  getPageHeading() {
    if (this.submenuCode > 0) {
      this.getSubmenuDetail();
    }
    else if (this.subCatCode > 0) {
      this.getSubCategoryDetail();
    }
    else if (this.catCode > 0) {
      this.getCategoryDetail();
    }
  }

  getData() {
    switch (this.pageName) {
      case "download-gallery":
        if (this.pageName == "download-gallery") {
          this.isShowDownload = true
        }
        this.posterIndexModel.PageSize = 16;
        this.GetPosterData(this.currentPage);
        break;

      case "photo-gallery":
        this.posterIndexModel.PageSize = 16;
        this.GetPosterData(this.currentPage);
        break;

      default:
        this.route.navigate(["home"])
        break;
    }
  }

  getSubmenuDetail() {
    this.mediaService.GetSubmenuDetail(this.submenuCode).subscribe(res => {
      if (res.IsSuccess) {
        let submenu = res.Data as DepartmentSubMenuDetailModel;
        if (submenu) {
          this.pageTitle = new PageTitleModel();
          this.pageTitle.NameEnglish = submenu.DisplayNameEnglish;
          this.pageTitle.NameHindi = submenu.DisplayNameHindi;
          this.pageTitle.ImagePath = submenu.IconImage;
          //this._commonService.SetWebTitle(this.translate.currlang == 'hi' ? this.pageTitle.NameHindi : this.pageTitle.NameEnglish);
        }
      }
    }, error => { });
  }

  getCategoryDetail() {
    this.pageTitle = new PageTitleModel();
    this.mediaService.GetAcievementCatDetail(this.catCode).subscribe(res => {
      debugger
      if (res.IsSuccess) {
        this.category = <AchievementCatgoryModel>res.Data;
        let cat = this.category;
        if (cat) {
          this.pageTitle.NameEnglish = cat.SubMenuNameEnglish;
          this.pageTitle.NameHindi = cat.SubMenuNameHindi;
          //this._commonService.SetWebTitle(this.translate.currlang == 'hi' ? this.pageTitle.NameHindi : this.pageTitle.NameEnglish);
        }
      }
    }, error => {
      console.error(error);
    })
  }

  getSubCategoryDetail() {
    this.mediaService.GetAcievementSubCatDetail(this.subCatCode).subscribe(res => {
      if (res.IsSuccess) {
        let subCatDetail = <AchievementSubCategoryModel>res.Data;
        this.pageTitle = new PageTitleModel();
        this.pageTitle.NameEnglish = subCatDetail.Title;
        this.pageTitle.NameHindi = subCatDetail.TitleHindi;
        this.pageTitle.ImagePath = subCatDetail.ImagePath;

        //this._commonService.SetWebTitle(this.translate.currlang == 'hi' ? this.pageTitle.NameHindi : this.pageTitle.NameEnglish);
      }
    }, error => {
      console.error(error);
    })
  }

  GetPosterData(page: number) {
    this.posterIndexModel.OrderBy = "AchievementDate";
    this.posterIndexModel.OrderByAsc = 0;
    this.posterIndexModel.AdvanceSearchModel = { DepartmentCode: this.departmentCode, CategoryCode: this.catCode || 0, SubCategoryCode: this.subCatCode || 0 }
    this.posterIndexModel.Page = page;
    this.posterIndexModel.PageSize = 16;
    this.mediaService.GetPostersListDataFromJankalyan(this.posterIndexModel).subscribe((response) => {
      if (response.IsSuccess) {
        this.posterModel = response.Data.Data as AchievementViewModel[];
        if (!this.posterIndexModel.IsPostBack) {
          this.totalRecords = response.Data.TotalRecords;
          console.log('total records', this.totalRecords);
          this.setPage(page);
        }
      } else {
        this.posterModel = []
      }
    }, (error) => {
      this.posterModel = []
    });
  }

  setPage(page: number) {
    window.scroll(0, 0);
    this.currentPage = page;
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    // get pager object from service
    this.pager = this.pagerService.getPager(this.totalRecords, page, this.pageSize);
    // get current page of items
    this.pagedItems = this.posterModel;
  }

  getImagesToDownload(images: []) {
    if (images && images.length > 0) {
      images.forEach(item => {
        this.ImageDownload(item);
      });
    }
  }

  ImageDownload(url) {
    const imgName = url.substr(url.lastIndexOf("/") + 1);
    fetch(url).then(response => response.blob())
      .then(blob => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = imgName;

        // Version link.click() to work at firefox
        link.dispatchEvent(
          new MouseEvent("click", {
            bubbles: true,
            cancelable: true,
            view: window
          })
        );
        setTimeout(() => {
          // firefox
          window.URL.revokeObjectURL(url);
          link.remove();
        }, 100);
      })
  }

}
