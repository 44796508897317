import { RegistrationEnum } from "../Enum/registration-portal.enum";

export class UserRegistrationPostModel {
    constructor() {
        this.RegisterationPortal = RegistrationEnum.DepartmentPortal;
    }
    AppUserId: number;
    Name: string;
    Mobile: string;
    OTP: any | null ;
    RegisterationPortal: number = RegistrationEnum.DepartmentPortal;
}

export class UserResponseModel {
    AppUserId: number;
    OTP: string;
    Mobile: string;
    Name: string;
    Email: string;
    Address: string;
    DistrictCode: number | null;
    Photo: string;
    IsVerified: boolean;
    IsActive: boolean;
    IsDelete: boolean;
    CreatedDate: string;
    ModifiedDate: string;
    IsNewUser: boolean;
}
export interface UserViewModel {
    AppUserId: number;
    Name: string;
    Mobile: string;
    Email: string;
    Address: string;
    DistrictCode: number | null;
    DistrictTitle: string;
    DistrictTitleHindi: string;
    ImageUrl: string;
    IsVerified: boolean;
    IsActive: boolean;
    ModifiedDate: string;
    CreatedDate: string;
    DateOfBirth: string;
    StateCode: number | null;
    StateTitle: string;
    StateTitleHindi: string;
    ACCode: number | null;
    ACTitle: string;
    ACTitleHindi: string;
    PinCode: number | null;
    SubscriptionId: number;
    SubscriptionDate: string;
    TotalRecords: number;
    ViewImagePath: string;
}