import { NewsService } from './../services/news.services';
import { MediaService } from './../services/media.services';
import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PagerService } from '../pager.service';
import { TranslateService } from '../translate.service';
import { AchievementService } from '../services/Achievement.services';
import { AchievementAllSearchModel, AchievementViewModel } from '../Model/awards-request-model.model';
import { DepartmentCodeEnum } from '../Enum/app-enums';
import { AchievementCategoryEnum } from '../Enum/achievement-category.enum';
import { FrontUploaderDataRequestModel, FrontUploaderDataResponseModel } from '../Model/photo-video-uploader.model';

@Component({
  selector: 'app-speech-listing',
  templateUrl: './speech-listing.component.html',
  styleUrls: ['./speech-listing.component.css'],
  providers: [AchievementService]
})
export class SpeechListingComponent implements OnInit {
  pager: any = {};
  totalPage: number;
  pagedSpeechesItems: any[];
  currPage = 1;
  currentPage: number;
  filterModel: AchievementAllSearchModel = new AchievementAllSearchModel();
  indexModel = new FrontUploaderDataRequestModel();
  achievementEnum = AchievementCategoryEnum;
  // cmSpeechModel: AchievementViewModel[] = [];
  cmSpeechModel:FrontUploaderDataResponseModel[];
  totalRecords = 0;
  pageSize = 9;

  constructor(@Inject('BASE_URL') public baseUrl: string,
    private pagerService: PagerService,
    public translate: TranslateService,
    private _achievementService: AchievementService) {
    this.currentPage = 1;
    this.pagedSpeechesItems = [];

  }
  ngOnInit() {
    // this.GetCMSpeeches(this.currentPage);
    this.getListData(this.currentPage)
  }

  setPage(page: number) {
    window.scroll(0, 0);
    this.currPage = page;
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    // get pager object from service
    this.pager = this.pagerService.getPager(this.totalRecords, page, this.pageSize);
    // get current page of items
    this.pagedSpeechesItems = this.cmSpeechModel;

  }

  GetCMSpeeches(page: number) {
    this.currentPage = page;
    this.filterModel.IndexModel.OrderBy = "Priority";
    this.filterModel.IndexModel.OrderByAsc = 1;
    this.filterModel.IndexModel.PageSize = 101;
    this.filterModel.CategoryCode = this.achievementEnum.CMSpeechCode;
    this.filterModel.DepartmentCode = DepartmentCodeEnum.CmoWebsiteCode;

    this._achievementService.GetAchievementListBySearchFilterData(this.filterModel, false).subscribe((response) => {
      if (response.IsSuccess) {
        this.cmSpeechModel = response.Data.Data;
        if (!this.filterModel.IndexModel.IsPostBack) {
          this.totalRecords = response.Data.TotalRecords;
          this.setPage(page);
        }
      }
    }, (error) => {
      console.error(error);
    });
  }

  getListData(page: number) {
    this.indexModel.DepartmentCode = 980;
    this.indexModel.CategoryCode = 0;
    this.indexModel.ActivityCode = 32174; 
    this.indexModel.OrderBy = "ModifiedDate";
    this.indexModel.OrderByAsc = 0;
    this.indexModel.UploaderType = 'Video';
    this.indexModel.Page = page
    this.indexModel.PageSize = 12;
    this._achievementService.GetUploaderDataForFront(this.indexModel).subscribe((response) => {
      if (response.IsSuccess) {
        this.cmSpeechModel = response.Data.Data;
        if (!this.indexModel.IsPostBack) {
          this.totalRecords = response.Data.TotalRecords;
          this.setPage(page);
        }
      } else {
        this.cmSpeechModel = []
      }
    }, (error) => {
      this.cmSpeechModel = []
    });
  }
}
