import { Component, OnInit } from '@angular/core';
import { LoginModel } from '../Model/login.model';
import { AppComponent } from '../app.component';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  model: LoginModel;
  ShowSecondImage = false;
  pageName = '';
  wayOne = true;
  environment = environment

  constructor(
    public readonly _appComponent: AppComponent,
    private readonly router: Router,
    private readonly actRoute: ActivatedRoute
  ) { 
    if(!environment.LoginEnable){
      this.router.navigate(['/'])
    }
    this.wayOne = this.actRoute.snapshot.params.ways == 1 ? true : false;
    
    if (this.router.url.includes('launching')) {
      this.pageName = "launching";

      sessionStorage.clear();
    }

    this.model = new LoginModel();
  }

  ngOnInit() {
    if (!Boolean(sessionStorage.getItem('IsAuthenticate'))) {
      this._appComponent.VarifyLogin(false);
    }
    else {
      this._appComponent.VarifyLogin(true);
      this.router.navigate(['home']);
    }
  }

  showSecondImage() {
    this.ShowSecondImage = true;
    setTimeout(() => {
      sessionStorage.setItem('IsAuthenticate', 'true');
      sessionStorage.setItem('AuthValid', String(new Date().setMinutes(new Date().getMinutes() + 1))
      );
      this._appComponent.VarifyLogin(true);
      this.router.navigate(['home']);
    }, 8000);
  }

  submit() {
  
      sessionStorage.setItem('IsAuthenticate', 'true');
      sessionStorage.setItem('AuthValid', String(new Date().setMinutes(new Date().getMinutes() + 20))
      );
      this._appComponent.VarifyLogin(true);
      this.router.navigate(['home']);
  
  }

  Login() {
    if (this.model.UserName.toLowerCase() === environment.userName.toLowerCase() && this.model.PassWord === environment.passWord) {

      sessionStorage.setItem('IsAuthenticate', 'true');
      sessionStorage.setItem('AuthValid', String(new Date().setMinutes(new Date().getMinutes()+20))
      );
      this._appComponent.VarifyLogin(true);
      this.router.navigate(['home']);
    } else {
      alert("Invalid credentials");
    }
  }

}
