import { CmoMessageService } from './../services/cmo-message.service';
import { Component, OnInit, Inject, Input } from '@angular/core';
import { PagerService } from '../pager.service';
import { TranslateService } from '../translate.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-cmo-message',
  templateUrl: './cmo-message.component.html',
  styleUrls: ['./cmo-message.component.css'],
  providers: [CmoMessageService]
})
export class CmoMessageComponent implements OnInit {
  
  cmoMeassages: CMOMessageModel[];
  constructor(@Inject('BASE_URL') public baseUrl: string, private cmoMessageService: CmoMessageService,
    private pagerService: PagerService, public translate: TranslateService) {
    this.cmoMeassages = [];
  }

  ngOnInit() {
    this.getCmoMeassagesData();
  }
  setLang(lang: string) {
    this.translate.use(lang);
  }
  public getCmoMeassagesData() {
    this.cmoMessageService.GetMessageList().subscribe(success => {
      if (success !== undefined && success !== null) {
        this.cmoMeassages = success;
      }
    }, error => {
      console.error(error);
    });
  }
}

interface CMOMessageModel {
  MessageId: number;
  Message: string;
  Language: boolean;
  IsActive: boolean;
  IsDeleted: boolean;
  MessageHindi: string;
  
}
