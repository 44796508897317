<!-- <div class="bg-scroll pos-rel breadcrumbs-page-img">
  <div class="container">
    <h1>{{'Home_PROGRAMS'| translate}}</h1>
  </div>
</div> -->
<div class="breadcrumbs-page-img">
  <div class="banner-hight-2">
    <h1>{{'Home_PROGRAMS'| translate}}</h1>
    <div class="inner-page-banner">
      <img src="assets/images/cmo-banner.png" alt="banner" class="cmo-banner">
    </div>
  </div>
</div>
<main id="main">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
      <li class="breadcrumb-item"><a href="javascript:void(0)">{{'Media_CornerSubNav'| translate}}</a></li>
      <li class="breadcrumb-item"><a routerLink="/program-list">{{'ProgrammesSubNav'| translate}}</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{'Program_Detail'| translate}}</li>
    </ol>
  </nav>
  <section class="program_list_detail">
    <div class="container">
      <div class="row">
        <div class="col-xl-9 wow fadeInLeft">
          <div class="program_detail_left">

            <a href="javascript:void(0)"  data-bs-toggle="modal" data-bs-target="#HomePageImage">
              <img class="d-block w-100 main-image" src="{{generalModel?.HomePageImageUrl}}" 
              onError="this.src='../../assets/images/program-no-img.png'">
            </a>
            <!-- <div id="carouselExampleControls" class="carousel slide mb-2" data-ride="carousel"
              *ngIf="generalModel.ImageAttachments.length>0">
              <div class="carousel-inner">
                <div class="carousel-item" [ngClass]="[i==0 ? 'active' : '' ]"
                  *ngFor="let item of generalModel?.ImageAttachments let i = index">
                  <img class="d-block w-100" src="{{item}}" alt="item{{i}}"
                    onError="this.src='../../assets/images/program-no-img.png'">
                </div>

              </div>
              <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev"> <span
                  class="carousel-control-prev-icon" aria-hidden="true"></span> <span class="sr-only">Previous</span>
              </a> <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span> <span class="sr-only">Next</span>
              </a>
              <div class="carousel-inner" *ngIf="generalModel.length==0">

                <img class="d-block w-100" src="../../assets/images/default_img1.png" />


              </div>

            </div> -->
            <h3> 
              <!-- {{this.translate.currlang == 'hi' ? generalModel?.Description : generalModel?.Description}} -->
              {{ 
                showFullText 
                  ? (translate.currlang == 'hi' ? generalModel?.Description : generalModel?.Description) 
                  : ((translate.currlang == 'hi' ? generalModel?.Description : generalModel?.Description) | slice:0:100) + '...'
              }}
              <a (click)="toggleText()" style="cursor: pointer; color: blue; font-size: small;font-weight: 600;">
                {{ showFullText ? 'Read Less' : 'Read More' }}
              </a>
            </h3>
            

            <div class="d-flex align-items-center justify-content-between mb-2">
              <div class="text-muted"><i class="fa fa-calendar"></i> {{this.translate.currlang == 'hi' ?
                generalModel?.PressReleaseDateHindi : generalModel?.PressreleaseDate | date : 'd MMM, y'}}</div>
                <div class="d-flex align-items-center justify-content-end mb-2">
                  <a href="{{generalModel?.Attachments}}" class="gray-btn gray-orange px-3 py-2 text-white">
                    <i class="fa fa-file-pdf-o mr-1"></i>Download</a>
                  
                    <!-- <a [href]="'https://www.facebook.com/dialog/feed?app_id=1589272984617635&amp;link=https://cmo.rajasthan.gov.in'+router.url+'/&amp;name='+generalModel.DepartmentTitle+'&amp;description='+generalModel?.Description+'&amp;redirect_uri='+'https://cmo.rajasthan.gov.in'+router.url+'/&amp;picture='+generalModel.HomePageImageUrl" id="facebook_social_id" target="_blank" >
                          <img src="https://www.pmindia.gov.in/wp-content/themes/pmindia2015/images/share-facebook.png" alt="facebook" title="facebook">
                    </a> -->
    
                    <!-- <a href="https://www.facebook.com/dialog/feed?app_id=1589276984617635&amp;link=https://www.pmindia.gov.in/en/news_updates/pm-attends-oath-taking-ceremony-of-new-andhra-pradesh-government/&amp;name=PM attends oath taking ceremony of new Andhra Pradesh Government&amp;description=Congratulates Shri N Chandrababu Naidu on taking oath as Chief Minister of Andhra Pradesh
                        The Prime Minister, Shri Narendra Modi has congratulated Shri N Chandrababu Naidu on taking oath as the Chief Minister of Andhra Pradesh.
    
                        The Prime Minister posted on X:
    
                        “Attended the oath taking ceremony&amp;redirect_uri=https://www.pmindia.gov.in/en/news_updates/pm-attends-oath-taking-ceremony-of-new-andhra-pradesh-government/&amp;picture=https://www.pmindia.gov.in/wp-content/uploads/2023/10/Default-01.jpg" id="facebook_social_id" target="_blank" >
                        <img src="https://www.pmindia.gov.in/wp-content/themes/pmindia2015/images/share-facebook.png" alt="facebook" title="facebook">
                    </a> -->
    
                    <a (click)="onSocial(generalModel?.DepartmentTitle,'fb')" style="cursor: pointer;" class="px-3 py-2" id="facebook_social_id" target="_blank" >
                        <img src="https://www.pmindia.gov.in/wp-content/themes/pmindia2015/images/share-facebook.png" alt="facebook" title="facebook">
                    </a>

                    <!-- this.facebookService.share('https://cmo.rajasthan.gov.in'+router.url,generalModel.DepartmentTitle,generalModel.HomePageImageUrl); -->

                    <a (click)="onSocial(generalModel?.Description,'twiiter')" href="javascript:void(0);">
                      <img src="https://www.pmindia.gov.in/wp-content/themes/pmindia2015/images/share-twitter.png" alt="twitter" title="twitter">
                    </a>
                </div>

            </div>


            <p [innerHTML]="generalModel?.GeneralDescription"></p>





            <!--<p></p>-->
            <!--<p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32. </p>-->
          </div>
        </div>

        <div class="col-xl-3  wow fadeInRight">
          <div class="program_detail_right">

            <div class="program_detail_right_box mb-4">
              <div class="section-header">
                <h2>{{'Photo_Gallery'| translate}}</h2>
              </div>
              <h6 *ngIf="generalModel?.ImageAttachments.length == 0">No Photos Available</h6>
              <div class="row photos">
                <div class="col-sm-6 col-md-4 col-lg-4 item" *ngFor="let item of generalModel?.ImageAttachments let i = index">
                  <a data-bs-toggle="modal" data-bs-target="#galleryBlock" (click)="openCarousel(i)" href="javascript:void(0)"><img class="img-fluid"
                      src="{{item}}"
                      onError="this.src='../../assets/images/program-no-img.png'"></a>
                </div>

              </div>
            </div>


            <div class="program_detail_right_box mb-4" *ngIf="videos.length>0">
              <div class="section-header">
                <h2>{{'Video_Gallery'| translate}}</h2>
              </div>
              <div class="row photos">
                <div class="col-sm-6 col-md-4 col-lg-4 item" *ngFor="let item of videos let i = index">
                  <a href="{{this.imageVideoUrl}}{{item.videoimage}}" data-lightbox="photos"><img class="img-fluid"
                      src="{{this.imageVideoUrl}}{{item.videoimage}}"
                      onError="this.src='../../assets/images/program-no-img.png'"></a>
                </div>

              </div>
            </div>

            <div class="program_detail_right_box mb-4" *ngIf="generalModel?.URL">
              <div class="section-header">
                <h2>{{'Video_Gallery'| translate}}</h2>
              </div>
              <div class="rounded">
                <!-- <div class="col-sm-6 col-md-4 col-lg-4 item" *ngFor="let item of videos let i = index"> -->
                  <a [href]="generalModel.URL" target="_blank">
                    <div width="640" height="360" class="embed-responsive embed-responsive-21by9">
                        <video #videoPlayer tabindex="-1" (playing)="onMediaPlaying($event, false)"
                            id="my_video" (mouseover)="playVideo($event)" (mouseout)="pauseVideo($event)"
                            class="embed-responsive-item" width="100%" height="100%" controls preload="none"
                            data-setup='{ "aspectRatio":"100%:100%", "playbackRates": [1, 1.5, 2] }'
                            disablePictureInPicture
                            controlslist="nodownload noremoteplayback noplaybackrate foobar">
                            <source src="{{generalModel.URL}}"
                                type="video/{{generalModel.URL.split('.')[generalModel.URL.split('.').length-1]}}">
                        </video>
                    </div>
                </a>
                <!-- </div> -->
              </div>
            </div>

            <div class="program_detail_right_box mb-4" *ngIf="programPressRelease.length>0">
              <section class=" box-content">
                <div class=" press_box">
                  <div class="section-header">
                    <h2>
                      PRESS RELEASES
                    </h2>
                  </div>
                  <div class="press_releases">
                    <ul class="wow fadeInRight">
                      <li class="wow fadeInLeft" *ngFor="let program of programPressRelease">
                        <div class="press_releases_bx d-flex">
                          <div class="date align-self-center">
                            {{program.ProgramDate | date : 'dd'}} <br>
                            <div style="font-size:13px;">{{program.ProgramDate| date : 'MMMM'}}</div>
                          </div>
                          <div class="detail align-self-center">


                            <a *ngIf="isEnglish && program.PdfEnglish != null && program.PdfEnglish != ''"
                              href="{{environmentUrl}}{{program.PdfEnglish}}" rel="noopener noreferrer"
                              target="_blank" title="PDF">

                              <p
                                *ngIf="(this.translate.currlang == 'hi' && program.ProgramSubjectHindi.length<=30 )||(this.translate.currlang != 'hi' && program.ProgramSubject.length<=30)">

                                {{this.translate.currlang == 'hi' ? program.ProgramSubjectHindi :
                                program.ProgramSubject}}

                              </p>
                              <p
                                *ngIf="(this.translate.currlang == 'hi' && program.ProgramSubjectHindi.length>30 )||(this.translate.currlang != 'hi' && program.ProgramSubject.length>30)">

                                {{(this.translate.currlang == 'hi' ? program.ProgramSubjectHindi :
                                program.ProgramSubject)|slice:0:30}}...

                              </p>

                              <!--{{this.translate.currlang == 'hi' ? program.ProgramSubjectHindi : program.ProgramSubject}}-->
                            </a>
                            <a *ngIf="!isEnglish && program.Pdf != null && program.Pdf != ''"
                              href="{{environmentUrl}}{{program.Pdf}}" rel="noopener noreferrer" target="_blank"
                              title="PDF">

                              <p
                                *ngIf="(this.translate.currlang == 'hi' && program.ProgramSubjectHindi.length<=30 )||(this.translate.currlang != 'hi' && program.ProgramSubject.length<=30)">

                                {{this.translate.currlang == 'hi' ? program.ProgramSubjectHindi :
                                program.ProgramSubject}}

                              </p>
                              <p
                                *ngIf="(this.translate.currlang == 'hi' && program.ProgramSubjectHindi.length>30 )||(this.translate.currlang != 'hi' && program.ProgramSubject.length>30)">

                                {{(this.translate.currlang == 'hi' ? program.ProgramSubjectHindi :
                                program.ProgramSubject)|slice:0:30}}...

                              </p>

                              <!--{{this.translate.currlang == 'hi' ? program.ProgramSubjectHindi : program.ProgramSubject}}-->
                            </a>
                            <span><i class="ion-ios-location-outline"></i>{{this.translate.currlang == 'hi' ?
                              program.ProgramAddress : program.ProgramAddress}} </span>
                            <span>
                              <i class="fa fa-calendar" aria-hidden="true"></i> &nbsp;{{program.ProgramDate}}
                            </span>
                          </div>
                        </div>
                      </li>

                    </ul>

                  </div>
                </div>
              </section>
            </div>

            <!-- Latest Press Releases from Jankalyan API -->
            <div class="program_detail_right_box mb-4"
              *ngIf="PressReleaseListModel!=undefined && PressReleaseListModel.length>0">
              <div class="section-header">
                <h2>{{'Latest_Programmes'| translate}}</h2>
              </div>
              <div class="media d-flex" *ngFor="let program of PressReleaseListModel let i = index">
                <div class="align-self-center"> <img class="mr-2" src="{{program.HomePageImageUrl}}"
                    onError="this.src='../../assets/images/program-no-img.png'" alt=""> </div>
                <div class="media-body align-self-center ps-2">
                  <!--<h5 class="mt-0"><a href="javascript:void(0)">Card title</a></h5>-->
                  <a routerLink="/Programdetail/{{program.Id}}">

                    <p
                      *ngIf="(this.translate.currlang == 'hi' && program.Description.length<=50 )||(this.translate.currlang != 'hi' && program.Description.length<=50)">

                      {{this.translate.currlang == 'hi' ? program.Description : program.Description}}

                    </p>
                    <p
                      *ngIf="(this.translate.currlang == 'hi' && program.Description.length>50 )||(this.translate.currlang != 'hi' && program.Description.length>50)">
                      {{(this.translate.currlang == 'hi' ? program.Description : program.Description)|slice:0:50}}...
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>





<!--<div class="bg-scroll pos-rel breadcrumbs-page-img">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
        <li class="breadcrumb-item"><a routerLink="/program-list">Program</a></li>
        <li class="breadcrumb-item active" aria-current="page">program Detail</li>
      </ol>
    </nav>
    <h1>  {{'Home_PROGRAMS'| translate}}</h1>
    <div class="breadcrumbs-description">
    </div>
  </div>
</div>

<main id="main">
  <section class="program_list_detail">
    <div class="container">
      <div class="row">
        <div class="col-xl-9 wow fadeInLeft">
          <div class="program_detail_left">
            <div id="carouselExampleControls" class="carousel slide mb-2" data-ride="carousel">
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img *ngIf="programDetail.ProgramMainImage !== null && programDetail.ProgramMainImage !== ''" src="{{imageUrl}}{{programDetail.ProgramMainImage}}" onError="this.src='../../assets/images/program-no-img.png'" class="d-block w-100" alt="">
                  <img *ngIf="programDetail.ProgramMainImage === null || programDetail.ProgramMainImage === ''" src="{{imageUrl}}{{programDetail.ProgramImage}}" onError="this.src='../../assets/images/program-no-img.png'" class="d-block w-100" alt="">

                </div>

              </div>

            </div>

            <div class="row">

              <div class="col-xl-6">
                <div class="detail_icon">
                  <a *ngIf="programDetail.TotalVideo > 0" href="javascript:void(0)" title="video"><i class="fas fa-video"></i></a>
                  <a *ngIf="programDetail.TotalImages > 0" routerLink="/gallerydetail/{{programDetail.ProgramId}}" title="image"> <i class="fa fa-file-image-o" aria-hidden="true"></i></a>
                  <a *ngIf="isEnglish && programDetail.PdfEnglish != null && programDetail.PdfEnglish != ''" href="{{environmentUrl}}{{programDetail.PdfEnglish}}" rel="noopener noreferrer" target="_blank" title="PDF"> <i class="fa fa-file-pdf-o" aria-hidden="true"></i></a>
                  <a *ngIf="!isEnglish && programDetail.Pdf != null && programDetail.Pdf != ''" href="{{environmentUrl}}{{programDetail.Pdf}}" rel="noopener noreferrer" target="_blank" title="PDF"> <i class="fa fa-file-pdf-o" aria-hidden="true"></i></a>

                </div>
              </div>

              <div class="col-xl-6 text-right  pt-3"><small class="text-muted">  {{this.translate.currlang == 'hi' ? programDetail.ProgramDateHindi : programDetail.ProgramDate}}</small></div>
            </div>
            <h3>{{this.translate.currlang == 'hi' ? programDetail.ProgramSubCategoryHindi : programDetail.ProgramSubCategory}}</h3>

            <p>
              <strong>Location:</strong> {{this.translate.currlang == 'hi' ? programDetail.ProgramAddressHindi : programDetail.ProgramAddress}}

            </p>
            <p>
              {{this.translate.currlang == 'hi' ? programDetail.ProgramSubjectHindi : programDetail.ProgramSubject}}
            </p>


          </div>
        </div>

        <div class="col-xl-3  wow fadeInRight">

          <div class="program_detail_right">

            <div class="program_detail_right_box mb-4">
              <div class="section-header">
                <h2>Other Programmes</h2>
              </div>
              <div class="media">
                <img class="mr-2" src="assets/img/no_image_64.png" alt="">
                <div class="media-body">
                  <h5 class="mt-0"><a href="javascript:void(0)">Card title</a></h5>
                  <p>Ensure Early Completion of Metro Phase 1-B</p>

                </div>
              </div>

              <div class="media">
                <img class="mr-2" src="assets/img/no_image_64.png" alt="">
                <div class="media-body">
                  <h5 class="mt-0"><a href="javascript:void(0)">Card title</a></h5>
                  <p>Ensure Early Completion of Metro Phase 1-B</p>

                </div>
              </div>

              <div class="media">
                <img class="mr-2" src="assets/img/no_image_64.png" alt="">
                <div class="media-body">
                  <h5 class="mt-0"><a href="javascript:void(0)">Card title</a></h5>
                  <p>Ensure Early Completion of Metro Phase 1-B</p>

                </div>
              </div>
            </div>

            <div class="program_detail_right_box mb-4">
              <div class="help_box text-center rounded py-4">
                <div class="mb-2"><i class="icofont-headphone-alt icofont-4x"></i></div>
                <h3 class="h3-md fw-5 txt-orange mb-3">Have Any Questions ?</h3>
                <p class="mb-1">Call our award-winning<br> support team 24/7</p>
                <a href="javascript:void(0)" class="btn-theme bg-blue mt-3">
                  Get In Touch &nbsp;<i class="fa fa-angle-right" aria-hidden="true"></i>
                </a>
              </div>

            </div>
            <div class="program_detail_right_box mb-4">
              <div class="section-header">
                <h2>Quick Links</h2>
              </div>

              <ul class="quick_links">
                <li><a href="javascript:void(0)"><i class="fa fa-angle-right" aria-hidden="true"></i> key officials</a></li>
                <li><a routerLink="/Chiefministersofraj"><i class="fa fa-angle-right" aria-hidden="true"></i> Rajasthan Chief Minister</a></li>
                <li><a routerLink="/program-list"><i class="fa fa-angle-right" aria-hidden="true"></i> Programs</a></li>
                <li><a routerLink="/pressrelease-list"><i class="fa fa-angle-right" aria-hidden="true"></i> News</a></li>
                <li><a href="gallery-list"><i class="fa fa-angle-right" aria-hidden="true"></i> Photo</a></li>
                <li><a routerLink="/video-gallery"><i class="fa fa-angle-right" aria-hidden="true"></i> Video</a></li>
              </ul>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
</main>-->

<div class="modal fade galleryBlock" id="galleryBlock" tabindex="-1" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"></h5>
        <button type="button" class="close close-in" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" >
        <!-- <p *ngIf="isLoading" class="text-left"><b>Loading...</b></p> -->
        <div id="carouselExampleControls" class="carousel slide mb-2" data-bs-ride="carousel"
              *ngIf="carouselData.length>0">
              <div class="carousel-inner" *ngIf="isVisible">
                <div class="carousel-item" *ngFor="let item of carouselData; let i = index" [ngClass]="item.active == true ? 'active' : '' ">
                  <img class="d-block w-100" src="{{item.image}}"
                  onError="this.src='../../assets/images/program-no-img.png'">
                </div>

              </div>
              <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-bs-slide="prev"> <span
                  class="carousel-control-prev-icon" aria-hidden="true"></span> <span class="sr-only">Previous</span>
              </a> <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span> <span class="sr-only">Next</span>
              </a>
              <div class="carousel-inner" *ngIf="carouselData.length==0">

                <img class="d-block w-100" src="../../assets/images/default_img1.png" />


              </div>

            </div>
            <div class="carousel-inner" *ngIf="carouselData.length==0">

              <img class="d-block w-100" src={{galleryDetails?.HomePageImageUrl}} />


            </div>
        <!-- <img class="d-block w-100" src="{{image}}" alt=""
          onError="this.src='../../assets/images/program-no-img.png'"> -->
      </div>

    </div>
  </div>
</div>


<!-- Main Image view Modal -->
<div class="modal fade galleryBlock" id="HomePageImage" tabindex="-1" aria-labelledby="exampleModalLabel"
aria-hidden="true">
<div class="modal-dialog modal-lg">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabel"></h5>
      <button type="button" class="close close-in" data-bs-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" >
      <img class="d-block w-100" src="{{generalModel?.HomePageImageUrl}}" alt=""
        onError="this.src='../../assets/images/program-no-img.png'">
    </div>

  </div>
</div>
</div>