import { MediaService } from './../services/media.services';
import { Data } from './../services/data.services';
import { Component, Inject, OnInit } from '@angular/core';
import { PagerService } from '../pager.service';
import { environment } from '../../environments/environment';
import { TranslateService } from '../translate.service';
import { Router } from '@angular/router';
import { ProgramGalleryListRequestModel } from '../Model/gallery-detail-model.model';
import { InAugrationListModel, PressReleaseFrontSearchModel } from '../Model/press-release-model.model';
import { NewsService } from '../services/news.services';
import { DepartmentCodeEnum, PressReleaseCategoryTypeEnum } from '../Enum/app-enums';
import { VIPCategoryEnum, VIPPersonCategoryEnum } from '../Enum/jankayan.enum';
import { DomSanitizer } from "@angular/platform-browser";
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-gallery-list',
  templateUrl: './gallery-list.component.html',
  styleUrls: ['./gallery-list.component.css'],
  providers: [MediaService, NewsService,DatePipe]
})
export class GalleryListComponent implements OnInit {
  galleryPrograms: GalleryListModel[];
  // paged items
  pagedItems: InAugrationListModel[];
  totalPage: number;
  searchBox = '';
  imageUrl = environment.imageUrl + 'Program/Image/Thumbnail/Fr_';
  // pager object
  pager: any = {};
  currentPage: number;
  modelRequest: ProgramGalleryListRequestModel;

  generalModel: InAugrationListModel[];
  galleryDetails: InAugrationListModel;
  filterModel = new PressReleaseFrontSearchModel();
  CategoryCode: number = 0;
  totalRecords = 0;
  DepartmentCodeEnum: DepartmentCodeEnum;
  VIPCategoryEnum: VIPCategoryEnum;
  VIPPersonCategoryEnum: VIPPersonCategoryEnum;
  PressReleaseCategoryTypeEnum: PressReleaseCategoryTypeEnum;
  images:any=[];
  isLoading = false;
  IsLoading = false;
  pageSize = 9;
  today = new Date();
  fromDate:any;
  toDate:any;
  days = 10; // Days you want to subtract
  date = new Date();
  lastDate = new Date('2023-12-15') //new Date(this.date.getTime() - (this.days * 24 * 60 * 60 * 1000));
  searchText:any = '';
  imageIndex:any;

  constructor(@Inject('BASE_URL') public baseUrl: string,
    private pagerService: PagerService,
    public translate: TranslateService,
    private router: Router,
    private data: Data,
    private mediaService: MediaService,
    public sanitizer: DomSanitizer,
    private datePipe: DatePipe,
    private newsService: NewsService) {
    this.pagedItems = [];
    this.currentPage = 1;
    this.modelRequest = new ProgramGalleryListRequestModel();
  }
  ngOnInit() {
    //this.getData(this.currentPage);
    // this.data.storage = { 'date': '28-Aug-2019' };
    // console.log(this.data.storage);
    // this.router.navigate(['../program-list']);
    this.fromDate = this.datePipe.transform(this.lastDate,'yyyy-MM-dd')
    this.toDate = this.datePipe.transform(this.today,'yyyy-MM-dd')
    this.GetPressReleaseData(this.currentPage,this.pageSize);
  }

  setLang(lang: string) {
    this.translate.use(lang);
  }
  public getData(page: number) {
    this.modelRequest.page = page;
    this.modelRequest.text = this.searchBox
    this.mediaService.GalleryListLatest(this.modelRequest).subscribe(success => {
      if (success !== undefined && success !== null) {
        this.galleryPrograms = success;
        this.totalPage = Number(this.galleryPrograms[0].TotalEvents);
        this.setPage(page,this.pageSize);
      }
    }, error => {
      console.error(error);
    });
  }

  onPageSize(evt:any){
    this.pageSize = evt.target.value
    this.GetPressReleaseData(this.currentPage,this.pageSize)
  }

  onSearch(){
    this.GetPressReleaseData(this.currentPage,this.pageSize)
  }


  GetPressReleaseMegaEventDataById(EntryNumber:any) {
    this.IsLoading = true;
    this.galleryDetails = undefined;
    let filterModel = new PressReleaseFrontSearchModel();
    filterModel.PageSize = 3;
    filterModel.IsBase64File = false;
    filterModel.EntryNumber = EntryNumber;
    //filterModel.DepartmentCode = this.indexModel.DepartmentCode;

    this.newsService.GetPressReleaseList(filterModel).subscribe(
      (response) => {
        if (response.IsSuccess) {
          this.IsLoading = false;
          this.galleryDetails = response.Data.Data[0];
          this.galleryDetails.ImageAttachments.unshift(this.galleryDetails.HomePageImageUrl)
          // console.log(this.galleryDetails, "galleryDetails");
          this.galleryDetails.GeneralDescription =
            this.sanitizer.bypassSecurityTrustHtml(
              this.galleryDetails.GeneralDescription
            );
        } else {
          this.IsLoading = false;
          this.galleryDetails = {} as InAugrationListModel;
        }
      },
      (error) => {
        this.IsLoading = false;
        this.galleryDetails = {} as InAugrationListModel;
        console.error(error);
      }
    );
  }


  setPage(page: number,pagesize:any) {
    window.scroll(0, 0);
    this.currentPage = page;
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    // get pager object from service
    this.pager = this.pagerService.getPager(Number(this.generalModel[0].TotalRecords), page,pagesize);
    // get current page of items
    this.pagedItems = this.generalModel;
  }


  //Jankalyan API
  GetPressReleaseData(page: number,pagesize:any) {
    this.generalModel = undefined;
    this.filterModel.IsHomePageUrl = 1;
    this.filterModel.Page = page;
    this.filterModel.PageSize = pagesize;
    this.filterModel.PressReleaseToDate = this.toDate;
    this.filterModel.PressReleaseFromDate = this.fromDate;
    this.filterModel.SearchKeyword = this.searchText;    
    this.filterModel.IsBase64File = false;
    this.filterModel.CategoryCode = PressReleaseCategoryTypeEnum.PressReleaseCode;
    this.filterModel.VIPCategoryCode = VIPCategoryEnum.HCMCode;
    // this.filterModel.VIPPersonCode = VIPPersonCategoryEnum.ShriAshokGehlotCode;
    this.filterModel.VIPPersonCode = VIPPersonCategoryEnum.ShriBhajanLalSharmaCode;
    this.filterModel.OrderBy = "PressreleaseDate";
    this.filterModel.DepartmentCode = DepartmentCodeEnum.CmoWebsiteCode;
    this.isLoading = true;
    this.newsService.GetDIPRPressRelease(this.filterModel).subscribe((response) => {
      if (response.IsSuccess) {
        this.generalModel = response.Data.Data as InAugrationListModel[];
        this.isLoading = response.Data.Data.length >= 0 ? false : true;
        // this.generalModel = this.generalModel.filter((item)=>{
        //   return item.HomePageImageUrl ? true : false
        // })
        if (!this.filterModel.IsPostBack) {
          this.totalRecords = response.Data.TotalRecords;
          this.setPage(page,pagesize);
        }
      } else {
        this.isLoading = false;
        this.generalModel = []
      }
    }, (error) => {
      this.generalModel = []
    });
  }


}

interface GalleryListModel {
  ProgramId: number;
  ImageName: string;
  ProgramSubcategoryHindi: string;
  ProgramSubCategory: string;
  ProgramDate: string;
  ProgramSubjectHindi: string;
  ProgramSubject: string;
  TotalEvents: number;
  ProgramDateHindi: string;

}
