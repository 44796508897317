
<div class="bg-scroll pos-rel breadcrumbs-page-img">
  <div class="container">
   
    <h1>{{'Rajasthan_SamparkSubNav'| translate}}</h1>
   
  </div>
</div>
<main id="main">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
      <li class="breadcrumb-item"><a href="javascript:void(0)">{{'Citizen_CornerNav'| translate}}</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{'Rajasthan_SamparkSubNav'| translate}}</li>
    </ol>
  </nav>
  <section class="program_list rajasthan_page">
    <div class="container">
      <div class="row mb-4">
        <div class="col-xl-5">
          <img src="assets/img/rajsampark.jpg" alt="" class="img-fluid" />
        </div>
        <div class="col-xl-7">
          <div class="raj_details">
            <div class="section-header">
              <h2>Public Grievance Redressal Portal - Rajasthan Sampark</h2>
            </div>
            <p class="wow fadeInUp">
              Government of Rajasthan is dedicated to bring in transformation in the way of citizen centric governance in the state. For this the government of Rajasthan has been continuously striving to bring in excellence in citizen service delivery by ensuring transparency and minimizing grievances. As an initiation to ensure proper service to the citizen, a project named "Rajasthan Sampark" has been implemented through Department of IT and Communications.The project paves the way for a common man to reach the departments of the State Government for their queries and concerns, with the help of Information Communication and Technology (ICT) Interventions.

            </p>

            <p class="wow fadeInUp">
              Rajasthan Sampark aims towards providing citizens with a centralized platform where any citizen of the state can lodge his/ her grievances to the respective departments. It primarily consists of a State level Call Center with integrated web portal which will act as a single point of contact for addressing and redressing various citizen centric queries and grievances related to government services. Citizens can lodge their grievances against any government department/ office through this portal and the grievance will further be sent to the respective office/ department for redressal.

            </p>
            <p class="wow fadeInUp">
              Through Rajasthan Sampark, Government of Rajasthan intends to achieve all important aspects of good governance viz. Robust Delivery System, Transparency, Communication, Grievance Redressal and Sensitivity towards citizens.
            </p>
            <a href="http://sampark.rajasthan.gov.in/" rel="noopener noreferrer" target="_blank" class="btn-theme bg-blue mt-3">READ MORE &nbsp;<i class="fa fa-angle-right" aria-hidden="true"></i> </a>
          </div>
        </div>
      </div>
      <!-- row / end -->
    </div>
  </section>
</main>
