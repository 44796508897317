
<div class="bg-scroll pos-rel breadcrumbs-page-img">
  <div class="container">
    <h1>{{'Cabinet_MinistersSubNav'| translate}}</h1>
  </div>
</div>
<main id="main">

  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
      <li class="breadcrumb-item"><a href="javascript:void(0)"> {{'STATE_PROFILEFooter'| translate}}</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{'Cabinet_MinistersSubNav'| translate}}</li>
    </ol>
  </nav>
  <section class="program_list">
    <div class="container">
      <div class="row">
        <div class="table-responsive">
          <table class="table table_blue cabinet_table table-bordered" cellspacing="0" cellpadding="0">
            <thead>
              <tr>
                <th>{{'SNo'| translate}}</th>
                <th class="text-center">{{'Name_of_the_Minister'| translate}}</th>
                <th>{{'DepartName'| translate}}</th>
                <th>{{'RoomNo_Building'| translate}}</th>
                <th>{{'Minister_Address'| translate}}</th>
                <th>{{'Office'| translate}}</th>
                <th>{{'Residence'| translate}}</th>
                <th width="150">Vidhan Sabha (Room & PBX Ext. No.)</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let minister of cabinetMinisters">
                <td>{{minister.SNo}} </td>
                <td align="center">
                  <img src="{{this.imageUrl}}{{minister.ProfileImage}}" onError="this.src='../../assets/images/program-no-img.png'" height="140" width="120" alt=""><br>
                  <strong>{{this.translate.currlang == 'hi' ?minister.TitleHindi:minister.Title }} {{this.translate.currlang == 'hi' ? minister.CabinetNameHindi : minister.CabinetName}}</strong><br />

                </td>
                <td>
                  {{this.translate.currlang == 'hi' ? minister.CabinetDepartmentHindi : minister.CabinetDepartment}}
                  <!--<ul>
                    <li>Finance</li>
                    <li>Excise</li>
                    <li>Planning</li>
                    <li>Picy Planning</li>
                    <li>Personnel</li>
                    <li>General Administration</li>
                    <li>Raj.  State Investigation Bureau</li>
                    <li>Information Technogy &amp; Communication</li>
                    <li>Home  Affairs &amp; Justice</li>
                  </ul>-->
                </td>
                <td>  {{this.translate.currlang == 'hi' ? minister.CabinetRoomHindi : minister.CabinetRoom}} </td>
                <td>
                  {{this.translate.currlang == 'hi' ? minister.CabinetAddressHindi : minister.CabinetAddress}}
                </td>
                <td>
                  {{minister.PhoneOffice}}
                </td>
                <td>
                  {{minister.PhoneResidence}}
                </td>
                <td>  </td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</main>


