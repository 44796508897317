<!--  -->
<ng-container [ngSwitch]="pageName">

    <div *ngSwitchCase="'launching'">
      
      <a *ngIf="!ShowSecondImage && wayOne==true" href="javascript:void(0)"
        (click)="ShowSecondImage=true; showSecondImage();">
        <div
          style="background-image: url('/assets/images/new-launch1.jpg'); background-size: cover; height: 100vh; background-position: top center; cursor: pointer;">
        </div>
      </a>
  
  
      <div *ngIf="ShowSecondImage && wayOne==true"
        style="background-image: url('/assets/images/new-launch1.gif'); background-size: cover; height: 100vh; background-position: top center; cursor: pointer;">
  
      </div>
  
      <div *ngIf="!wayOne" (click)="submit()"
        style="background-image: url('/assets/images/Slide-min.jpg'); background-size: cover; height: 100vh; background-position: top center; cursor: pointer;">
  
      </div>
  
    </div>
  
    <ng-template ngSwitchDefault>
      <div class="login_page d-flex justify-content-center align-items-center" style="height: 100vh;">
  
        <div class="card">
          <div class="card-header">
            <h3>LOGIN</h3>
          </div>
          <div class="card-body">
            <form>
              <div class="input-group form-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fa fa-user"></i></span>
                </div>
                <input type="text" class="form-control" placeholder="username" [(ngModel)]="model.UserName"
                  name="username" required (keyup.enter)="Login()"/>
  
              </div>
              <div class="input-group form-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fa fa-key"></i></span>
                </div>
                <input type="password" class="form-control" placeholder="password" [(ngModel)]="model.PassWord"
                  type="password" name="password" required (keyup.enter)="Login()"/>
  
              </div>
  
              <div class="form-group">
  
                <button mat-raised-button (click)="Login()" color="primary" type="submit" value="Login"
                  class="btn float-right login_btn btn-info">Login</button>
              </div>
            </form>
            <div class="home-view-website"> <a href="https://jankalyantest.rajasthan.gov.in/#/home"><i class="fa fa-eye"></i> View test website</a> </div>
            
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
  
  <!-- 
  <ng-container *ngIf="wayOne">
    <ng-container [ngSwitch]="pageName">
      <ng-template *ngSwitchCase="'launching'">
  
        <div>
          
          <a *ngIf="!ShowSecondImage" href="javascript:void(0)" (click)="ShowSecondImage=true; showSecondImage();">
            <div
              style="background-image: url('/assets/images/new-launch1.jpg'); background-size: cover; height: 100vh; background-position: top center; cursor: pointer;">
             
            </div>
  
  
          </a>
  
          <div *ngIf="ShowSecondImage"
            style="background-image: url('/assets/images/new-launch1.gif'); background-size: cover; height: 100vh; background-position: top center; cursor: pointer;">
          
          </div>
        </div>
      </ng-template>
      <ng-template *ngSwitchDefault>
        <div class="login_page d-flex justify-content-center align-items-center">
  
          <div class="card" *>
            <div class="card-header">
              <h3>LOGIN</h3>
            </div>
            <div class="card-body">
              <form>
                <div class="input-group form-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fas fa-user"></i></span>
                  </div>
                  <input type="text" class="form-control" placeholder="username" [(ngModel)]="model.UserName"
                    name="username" required />
  
                </div>
                <div class="input-group form-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fas fa-key"></i></span>
                  </div>
                  <input type="password" class="form-control" placeholder="password" [(ngModel)]="model.PassWord"
                    type="password" name="password" required />
  
                </div>
  
                <div class="form-group">
  
                  <button mat-raised-button (click)="submit()" color="primary" type="submit" value="Login"
                    class="btn float-right login_btn btn-info">Login</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!wayOne">
  
    <div (click)="submit()"
      style="background-image: url('/assets/images/Slide-min.jpg'); background-size: cover; height: 100vh; background-position: top center; cursor: pointer;">
  
      
    </div>
  
  </ng-container> -->