<div *ngIf="homepage">
  <div class="section-header">
    <h2 class="m-0 p-0">{{'NavAwards'| translate}}</h2>
    <div class="section-header-action" *ngIf="awardModelFromJankalyan?.length > 0">
      <a routerLink="/awards" class="gray-btn gray-orange">{{'View_More'| translate}}</a>
    </div>
  </div>
  <div id="carouselExampleControls2" class="carousel slide achivement_slider" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item justify-content-center align-items-center text-center"
        *ngFor="let row of awardModelFromJankalyan; let k = index;" [class.active]="k==0">

        <ng-container *ngIf="row.ImagePath.length > 0 && row.ImagePath[0] !== '' ">
          <a routerLink="/awarddetail/{{row.Id}}">
            <img src="{{row.ImagePath}}" alt="First slide">
            <div *ngIf="row.Achievement.length<=40" class="achivement_caption">{{(this.translate.currlang == 'hi' ?
              row.AchievementHindi : row.Achievement)|slice:0:40}}</div>
            <div *ngIf="row.Achievement.length>40" class="achivement_caption">{{(this.translate.currlang == 'hi' ?
              row.AchievementHindi : row.Achievement)| slice:0:40}}...</div>
          </a>
        </ng-container>
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleControls2" role="button" data-slide="prev"> <span
        class="fa fa-angle-left" aria-hidden="true"></span> <span class="sr-only">Previous</span> </a> <a
      class="carousel-control-next" href="#carouselExampleControls2" role="button" data-slide="next"> <span
        class="fa fa-angle-right" aria-hidden="true"></span> <span class="sr-only">Next</span> </a>
  </div>

</div>
<div *ngIf="!homepage">

  <!-- <div [ngClass]="!isCodeOfConduct ? 'bg-scroll pos-rel breadcrumbs-page-img' : 'bg-scroll pos-rel'">
    <div class="container">
      <h1>{{'NavAwards'| translate}}</h1>
    </div>
  </div> -->
  <div class="breadcrumbs-page-img">
    <div class="banner-hight-2">
      <h1>{{'NavAwards'| translate}}</h1>
      <div class="inner-page-banner">
        <img src="assets/images/cmo-banner.png" alt="banner" class="cmo-banner">
      </div>
    </div>
  </div>
  <main id="main">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
        <li class="breadcrumb-item"><a href="javascript:void(0)">{{'Media_CornerSubNav'| translate}}</a></li>
        <li class="breadcrumb-item active" aria-current="page">{{'NavAwards'| translate}}</li>
      </ol>
    </nav>
    <nav class="navbar justify-content-between m-1">
      <a class="navbar-brand"></a>
      <div class="form-inline">
        <div class="text-start">
          <label>From Date</label>
          <input class="form-control mr-sm-2" type="date" name="" id="" [(ngModel)]="fromDate">
        </div>
        <div class="text-start">
          <label>To Date</label>
          <input class="form-control mr-sm-2" type="date" name="" id="" [(ngModel)]="toDate">
        </div>
        <div class="text-start">
          <label>Search</label>
          <input class="form-control mr-sm-2" [(ngModel)]="searchText" type="search" placeholder="Search" aria-label="Search">
        </div>
        <div class="search-btn">
          <label>&nbsp;</label> 
          <button class="btn btn-outline-primary mr-sm-2" type="submit" (click)="onSearch()"><i class="fa fa-search"></i></button>
        </div>
      </div>
    </nav>
    <section class="program_list">
      <div class="container">

        <div class="row">
          <!-- <div class="card-deck w-100"> -->
            <div class="col-sm-6 col-lg-3 program_list_box"
              *ngFor="let awardItem of awardModelFromJankalyan; let i = index">

              <div class="card wow"
                [ngClass]="[i%3 <1 ? 'fadeInLeft' : i%3 <2? 'fadeInUp':i%3 <3? 'fadeInRight':'fadeInLeft' ]">

                <div class="program-img-bx ">

                  <!--<a href="javascript:void(0)" data-toggle="modal" [attr.data-target]="'#Gal_'+ awardItem.AwardId">-->
                  <a routerLink="/awarddetail/{{awardItem.Id}}">
                    <img src="{{awardItem.ImagePath}}" onError="this.src='../../assets/images/program-no-img.png'"
                      class="card-img-top" alt="">
                  </a>

                </div>

                <div class="card-body">

                  <a routerLink="/awarddetail/{{awardItem.Id}}">
                    <p class="card-text"
                      *ngIf="(this.translate.currlang == 'hi' && awardItem.AchievementHindi.length<=50 )||(this.translate.currlang != 'hi' && awardItem.Achievement.length<=50)">

                      {{this.translate.currlang == 'hi' ? awardItem.AchievementHindi : awardItem.Achievement}}

                    </p>
                    <p class="card-text"
                      *ngIf="(this.translate.currlang == 'hi' && awardItem.AchievementHindi.length>50 )||(this.translate.currlang != 'hi' && awardItem.Achievement.length>50)">

                      {{(this.translate.currlang == 'hi' ? awardItem.AchievementHindi :
                      awardItem.Achievement)|slice:0:50}}...

                    </p>
                  </a>
                  <a class="program_btn_more" routerLink="/awarddetail/{{awardItem.Id}}">{{'Read_More'| translate}} </a>
                </div>


                <div class="card-footer">
                  <small class="text-muted"><i class="fa fa-calendar"></i> {{this.translate.currlang == 'hi' ?
                    awardItem.AchievementDateHindi : awardItem.AchievementDate | date : 'd MMM, y'}}</small>
                </div>
              </div>
            </div>
          <!-- </div> -->
          <select name="example_length " aria-controls="example " (change)="onPageSize($event)" [(ngModel)]="pageSize" *ngIf="totalRecords > 8"
            class="form-control-sm ng-pristine ng-valid ng-touched" style="width: 70px;">
            <option [value]="totalRecords"> All </option>
            <option value="12">12</option>
            <option value="24">24</option>
            <option value="48">48</option>
            <option value="96">96</option>
            <option value="192">192</option>
          </select>
          <nav aria-label="Page navigation example" class="w-100 text-center mt-4" *ngIf="totalRecords > pageSize">

            <ul class="pagination d-inline-flex" *ngIf="pager.pages && pager.pages.length">
              <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                <a class="page-link" (click)="getAwardsDataFromJankalyan(1,pageSize)">{{'First'| translate}}</a>
              </li>
              <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                <a class="page-link" (click)="getAwardsDataFromJankalyan(pager.currentPage - 1,pageSize)">{{'Previous'|
                  translate}}</a>
              </li>
              <li class="page-item" *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
                <a class="page-link" (click)="getAwardsDataFromJankalyan(page,pageSize)">{{page}}</a>
              </li>
              <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                <a class="page-link" (click)="getAwardsDataFromJankalyan(pager.currentPage + 1,pageSize)">{{'Next'|
                  translate}}</a>
              </li>
              <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                <a class="page-link" (click)="getAwardsDataFromJankalyan(pager.totalPages,pageSize)">{{'Last'| translate}}</a>
              </li>
            </ul>
          </nav>
          <div class="col-lg-12" *ngIf="awardModelFromJankalyan && awardModelFromJankalyan?.length == 0">
            <h3 class=" text-center">{{'No_Record_Found'|translate}}</h3>
          </div>
        </div>
      </div>
      <div class="modal new_gallery_slide  fade bd-example-modal-lg" [attr.id]="'Gal_'+ awrd.AwardId" tabindex="-1"
        role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" *ngFor="let awrd of awardsModel">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span
                  aria-hidden="true">&times;</span> </button>
              <div [attr.id]="'demo'+awrd.AwardId" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner">
                  <div class="carousel-item" *ngFor="let row of awrd.Image let k = index" [class.active]="k==0">
                    <img *ngIf="row != ''" class="img-fluid" alt="" src="{{this.environmentUrlImage}}{{row}}" />
                    <div class="carousel-caption" *ngIf="row != ''">
                      <div class="title">{{this.translate.currlang == 'hi' ? awrd.SubjectHindi : awrd.Subject}}</div>
                    </div>
                  </div>
                  <a class="carousel-control-prev" href="#demo{{awrd.AwardId}}" data-slide="prev"> <span
                      class="carousel-control-prev-icon"></span> </a> <a class="carousel-control-next"
                    href="#demo{{awrd.AwardId}}" data-slide="next"> <span class="carousel-control-next-icon"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </main>
</div>

<style>
      select.form-control-sm {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    position: static !important;
    margin: 15px auto auto;
  }
  .text-start {
    padding: 5px;
  }
  .text-start label {
    justify-content: start;
  }

  @media (max-width: 576px) {
    .btn-outline-primary {
      margin-top: 26px;
    }
  }
</style>