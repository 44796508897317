// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  LoginEnable: false,
  defaultDate: new Date('2018-12-17'),
  rajAdvtUrl: "https://rajadvt.rajasthan.gov.in/",
  rajAdvtApiUrl: "https://rajadvtapi.rajasthan.gov.in/",
  CM_Relief_Url: "https://cmrelief.rajasthan.gov.in/",
  CMIS_Url: "https://cmis.rajasthan.gov.in/",
  Raj_Sampark_Url: "https://api.sewadwaar.rajasthan.gov.in/",
  //Phone_Booth_Url: "http://localhost:8000/",
  Phone_Booth_Url: "https://momentswithcm.rajasthan.gov.in/", //live

  TEST_CERD_EMAIL: "test@gmail.com",
  TEST_CRED_PASSWORD: "test123",
  //// for DS Server
  //imageUrl: 'http://cmoweb.projectstatus.in/',
  // WEB_ENDPOINT: 'https://cmoclientapp.24livehost.com/',
  // API_ENDPOINT: 'https://cmoclientapp.24livehost.com/api/',

  //// Staging SERVER

  //imageUrl: 'http://10.70.231.108/cmoadmin/',
  //WEB_ENDPOINT: 'https://10.70.231.108/cmoweb/',
  //API_ENDPOINT: 'https://10.70.231.108/cmoweb/api/',

  //Production  server(Live)
  
  SOCIALMEDIA_SHARING_URL:'https://cmo.rajasthan.gov.in',
  imageUrl: 'https://cmo.rajasthan.gov.in/cmoadmin/',
  WEB_ENDPOINT: 'https://cmo.rajasthan.gov.in/',
  API_ENDPOINT: 'https://cmo.rajasthan.gov.in/api/',

  RajAdvtApiBaseUrl: 'https://rajadvt.rajasthan.gov.in/rajadvtapi/',                    // Live Server
  AdvertisementUrl: 'https://rajadvt.rajasthan.gov.in/',
  API_ENDPOINT_Jankalyan: 'https://jankalyanfrontwebapi.rajasthan.gov.in/', //read only
  API_ENDPOINT_RW_Jankalyan: 'https://jankalyanapi.rajasthan.gov.in/', //read-write api. (local)

  // API_ENDPOINT_Jankalyan: 'https://statedashboardapi.24livehost.com/', // ds read only
  // API_ENDPOINT_RW_Jankalyan: 'https://statedashboardapi.24livehost.com/', // ds read-write api. (local)

  DateFormat: 'dd-MMM-yyyy',
  userName: 'admin',
  passWord: 'cmo@2023',
};
