<div class="breadcrumbs-page-img">
    <div class="banner-hight-2">
        <h1>PhoneBooth</h1>
        <div class="inner-page-banner">
            <img src="assets/images/cmo-banner.png" alt="banner" class="cmo-banner">
        </div>
    </div>
</div>
<main id="main">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
            <li class="breadcrumb-item active" aria-current="page">Phone Booth</li>
        </ol>
    </nav>
    <section class="mb-3">
        <div id="pb-admin" class="container">
            <h2>Add Images to Database</h2>

            <form [formGroup]="eventForm" (ngSubmit)="onSubmit()">
                <!-- Event Name Input -->
                <div class="form-group">
                    <label for="eventName">Enter Event Name</label>
                    <select class="form-control2 form-control" formControlName="eventName" type="text" id="formFile">

                        <option value="">Select Event</option>
                        <option [value]="data" *ngFor="let data of events">{{data}}</option>
                    </select>
                    <div
                        *ngIf="(eventForm.get('eventName')?.hasError('required') && eventForm.get('eventName')?.touched)">
                        {{translate.currlang !='en'? 'कृपया '+('Name_txt'|translate) +' लिखें' :
                        'Please Select '+ ('Name_txt'|translate)}} </div>
                </div>

                <!-- File Upload Section -->
                <div class="upload-section">
                    <label>Upload Images:</label>
                    <div class="drop-zone">
                        <p>Drag and drop files here</p>
                        <p>Limit 200MB per file • JPG, PNG, JPEG</p>
                        <input type="file" (change)="onFileChange($event)" multiple class="file-input" />
                        <div *ngIf="!isfileValid" style="color: red;" class="mt-2">{{fileError}}</div>
                        <!-- <p>( {{'DocType_txt2' | translate}} )</p> -->
                        <button type="button" class="btn btn-primary">Browse files</button>
                    </div>
                </div>

                <!-- Submit Button -->
                <button type="submit" class="btn btn-success">Submit</button>
                <button (click)="onDeleteEvent();" class="btn btn-success me-2">Delete</button>
                <button (click)="onResetEvent();" class="btn btn-success">Reset</button>
            </form>
        </div>
    </section>
</main>