
<div class="bg-scroll pos-rel breadcrumbs-page-img">
  <div class="container">
    <h1>{{'CM_Relief_FundSubNav'| translate}}</h1>

  </div>
</div>
<main id="main">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
      <li class="breadcrumb-item"><a href="javascript:void(0)"> {{'Citizen_CornerNav'| translate}}</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{'CM_Relief_FundSubNav'| translate}}</li>
    </ol>
  </nav>
  <section class="cmrelief">
    <div class="container">
      <div class="row">
        <div class="col-xl-4 col-sm-6">
          <div class="card profile-card-2 wow fadeInUp">
            <div class="card-img-block"> <img class="img-fluid" src="assets/img/cmrelief.png" alt="cmrelief"> </div>
            <div class="card-body pt-4">
              <img src="assets/img/check_black.png" alt="cmrelief" class="profile" />
              <a href="http://cmrelief.rajasthan.gov.in/Static_AccidentalRelief.aspx" rel="noopener noreferrer" target="_blank">
                <p class="card-text">{{'Assistance_to_accident_victims'| translate}}</p>
              </a>

            </div>
          </div>
        </div>
        <div class="col-xl-4 col-sm-6">
          <div class="card profile-card-2 wow fadeInUp">
            <div class="card-img-block"> <img class="img-fluid" src="assets/img/cmrelief.png" alt="cmrelief"> </div>
            <div class="card-body pt-4">
              <img src="assets/img/check_black.png" alt="cmrelief" class="profile" />
              <a href="http://cmrelief.rajasthan.gov.in/Static_IncureDiseaseRelief.aspx" rel="noopener noreferrer" target="_blank">
                <p class="card-text">{{'Assistance_to_victims_of_severe_disease'| translate}}</p>
              </a>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-sm-6">
          <div class="card profile-card-2 wow fadeInUp">
            <div class="card-img-block"> <img class="img-fluid" src="assets/img/cmrelief.png" alt="cmrelief"> </div>
            <div class="card-body pt-4">
              <img src="assets/img/check_black.png" alt="cmrelief" class="profile" />
              <a href="http://cmrelief.rajasthan.gov.in/GeneralHelp.aspx" rel="noopener noreferrer" target="_blank">
                <p class="card-text">{{'General_Help'| translate}}</p>
              </a>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-sm-6">
          <div class="card profile-card-2 wow fadeInUp">
            <div class="card-img-block"> <img class="img-fluid" src="assets/img/cmrelief.png" alt="cmrelief"> </div>
            <div class="card-body pt-4">
              <img src="assets/img/check_black.png" alt="cmrelief" class="profile" />
              <a href="http://cmrelief.rajasthan.gov.in/Static_KargilReliefFund.aspx" rel="noopener noreferrer" target="_blank">
                <p class="card-text">{{'Kargil_Relief_Fund'| translate}}</p>
              </a>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-sm-6">
          <div class="card profile-card-2 wow fadeInUp">
            <div class="card-img-block"> <img class="img-fluid" src="assets/img/cmrelief.png" alt="cmrelief"> </div>
            <div class="card-body pt-4">
              <img src="assets/img/check_black.png" alt="cmrelief" class="profile" />
              <a href="http://cmrelief.rajasthan.gov.in/Static_OrrissaToofanPidit.aspx" rel="noopener noreferrer" target="_blank">
                <p class="card-text">{{'Orissa_cyclone_victims_fund'| translate}}</p>
              </a>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-sm-6">
          <div class="card profile-card-2 wow fadeInUp">
            <div class="card-img-block"> <img class="img-fluid" src="assets/img/cmrelief.png" alt="cmrelief"> </div>
            <div class="card-body pt-4">
              <img src="assets/img/check_black.png" alt="cmrelief" class="profile" />
              <a href="http://cmrelief.rajasthan.gov.in/Static_GujratBhookampRelief.aspx" rel="noopener noreferrer" target="_blank">
                <p class="card-text">{{'Gujarat_earthquake_victims_fund'| translate}}</p>
              </a>
            </div>
          </div>
        </div>
        <div class="col-xl-12 text-center"><a href="http://cmrelief.rajasthan.gov.in/" rel="noopener noreferrer" target="_blank" class="btn-theme bg-blue mt-3">{{'KNOW_MORE'| translate}} &nbsp;<i class="fa fa-angle-right" aria-hidden="true"></i></a></div>
      </div>
    </div>
  </section>
</main>
