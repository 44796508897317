<div class="bg-scroll pos-rel breadcrumbs-page-img">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
        <li class="breadcrumb-item"><a href="javascript:void(0)"> CM Office</a></li>
        <li class="breadcrumb-item active" aria-current="page">Former CM</li>
      </ol>
    </nav>

    <h1>Chief Ministers of Rajasthan</h1>
    <div class="breadcrumbs-description">
    </div>
  </div>
</div>



<main id="main">
  <section class="program_list cm-rajasthan">
    <div class="container">
      <div class="row">
        <div class="col-xl-3 wow fadeInLeft">
          <div class="cm_box text-center">
            <img src="img/cm-raj/gehlotnew.jpg" alt="" class="img-fluid" />
            <h3>Shri Bhajanlal Sharma</h3>
            <div class="duration">
              <h4>Duration</h4>
              <ul>
                <li>17-12-2018 to contd.</li>
                <li>13-12-2008 to 13-12-2013</li>
                <li>01-12-1998 to 08-12-2003</li>
              </ul>
              <div class="footer">
                <strong>Party:</strong>&nbsp;Indian National Congress
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 wow fadeInUp">
          <div class="cm_box text-center">
            <img src="img/cm-raj/cm1.png" alt="" class="img-fluid" />
            <h3>Smt Vasundhara Raje</h3>
            <div class="duration">
              <h4>Duration</h4>
              <ul>
                <li>13-12-2013 to 17-12-2018</li>
                <li>08-12-2003 to 13-12-2008</li>

              </ul>

              <div class="footer">
                <strong>Party:</strong>&nbsp;Bharatiya Janata Party

              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 wow fadeInUp">
          <div class="cm_box text-center">
            <img src="img/cm-raj/bhairon.png" alt="" class="img-fluid" />
            <h3>
              Shri Bhairon Singh Shekhawat
            </h3>
            <div class="duration">
              <h4>Duration</h4>
              <ul>
                <li>04-12-1993 to 01-12-1998</li>
                <li>04-03-1990 to 15-12-1992</li>
                <li>
                  22-06-1977 to 16-02-1980
                </li>
              </ul>

              <div class="footer">
                <strong>Party:</strong>&nbsp;Bharatiya Janata Party / Janata Party

              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 wow fadeInLeft">
          <div class="cm_box text-center">
            <img src="img/cm-raj/cm10.jpg" alt="" class="img-fluid" />
            <h3>Shri Heera Lal Devpura</h3>
            <div class="duration">
              <h4>Duration</h4>
              <ul>
                <li>23-02-1985 to 10-03-1985</li>
              </ul>

              <div class="footer">
                <strong>Party:</strong>&nbsp;Indian National Congress
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 wow fadeInRight">
          <div class="cm_box text-center">
            <img src="img/cm-raj/charan.png" alt="" class="img-fluid" />
            <h3>
              Shri Shiv Charan Mathur
            </h3>
            <div class="duration">
              <h4>Duration</h4>
              <ul>
                <li>20-01-1988 to 04-12-1989</li>
                <li>
                  14-07-1981 to 23-02-1985
                </li>

              </ul>

              <div class="footer">
                <strong>Party:</strong>&nbsp;Indian National Congress
              </div>
            </div>
          </div>
        </div>



        <div class="col-xl-3 wow fadeInUp">
          <div class="cm_box text-center">
            <img src="img/cm-raj/jagannath.png" alt="" class="img-fluid" />
            <h3>
              Shri Jagannath Pahadia
            </h3>
            <div class="duration">
              <h4>Duration</h4>
              <ul>
                <li>
                  06-06-1980 to 13-07-1981
                </li>

              </ul>

              <div class="footer">
                <strong>Party:</strong>&nbsp;Indian National Congress
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 wow fadeInUp">
          <div class="cm_box text-center">
            <img src="img/cm-raj/haridev.png" alt="" class="img-fluid" />
            <h3>
              Shri Haridev Joshi
            </h3>
            <div class="duration">
              <h4>Duration</h4>
              <ul>
                <li>
                  04-12-1989 to 04-03-1990
                </li>
                <li>
                  10-03-1985 to 20-01-1988
                </li>
                <li>
                  11-10-1973 to 29-04-1977
                </li>
              </ul>

              <div class="footer">
                <strong>Party:</strong>&nbsp;Indian National Congress
              </div>
            </div>
          </div>
        </div>



        <div class="col-xl-3 wow fadeInRight">
          <div class="cm_box text-center">
            <img src="img/cm-raj/barkatullah.png" alt="" class="img-fluid" />
            <h3>
              Shri Barkatullah Khan
            </h3>
            <div class="duration">
              <h4>Duration</h4>
              <ul>
                <li>
                  09-07-1971 to 11-10-1973
                </li>

              </ul>

              <div class="footer">
                <strong>Party:</strong>&nbsp;Indian National Congress
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 wow fadeInLeft">
          <div class="cm_box text-center">
            <img src="img/cm-raj/mohanlal.png" alt="" class="img-fluid" />
            <h3>
              Shri Mohanlal Sukhadia
            </h3>
            <div class="duration">
              <h4>Duration</h4>
              <ul>
                <li>26-04-1967 to 09-07-1971</li>
                <li>
                  12-03-1962 to 13-03-1967
                </li>
                <li>
                  11-04-1957 to 11-03-1962
                </li>
                <li>
                  13-11-1954 to 11-04-1957

                </li>
              </ul>

              <div class="footer">
                <strong>Party:</strong>&nbsp;Indian National Congress
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 wow fadeInUp">
          <div class="cm_box text-center">
            <img src="img/cm-raj/tikaram.png" alt="" class="img-fluid" />
            <h3>
              Shri Tikaram Paliwal
            </h3>
            <div class="duration">
              <h4>Duration</h4>
              <ul>
                <li>
                  03-03-1952 to 31-10-1952
                </li>

              </ul>

              <div class="footer">
                <strong>Party:</strong>&nbsp;Indian National Congress
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 wow fadeInUp">
          <div class="cm_box text-center">
            <img src="img/cm-raj/narayan.png" alt="" class="img-fluid" />
            <h3>
              Shri Jay Narayan Vyas
            </h3>
            <div class="duration">
              <h4>Duration</h4>
              <ul>
                <li>
                  01-11-1952 to 12-11-1954
                </li>
                <li>
                  26-04-1951 to 03-03-1952
                </li>

              </ul>

              <div class="footer">
                <strong>Party:</strong>&nbsp;Indian National Congress
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 wow fadeInRight">
          <div class="cm_box text-center">
            <img src="img/cm-raj/venkatachari.png" alt="" class="img-fluid" />
            <h3>
              Shri C. S. Venkatachari
            </h3>
            <div class="duration">
              <h4>Duration</h4>
              <ul>
                <li>06-01-1951 to 25-04-1951</li>

              </ul>

              <div class="footer">
                <strong>Party:</strong>&nbsp;
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 wow fadeInLeft">
          <div class="cm_box text-center">
            <img src="img/cm-raj/heeralal.png" alt="" class="img-fluid" />
            <h3>
              Shri Heera Lal Shastri

            </h3>
            <div class="duration">
              <h4>Duration</h4>
              <ul>
                <li>
                  07-04-1949 to 05-01-1951
                </li>

              </ul>

              <div class="footer">
                <strong>Party:</strong>&nbsp;
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </section>
</main>




<!--<section class="inner-page-banner">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="page-title">Chief Ministers of Raj.</div>
      </div>
    </div>
  </div>
</section>
<section class="form-page inner-page">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="chief-page">
          <div class="table-responsive">
            <table class="table table-bordered table-striped table-red" *ngIf="this.translate.currlang == 'hi'">
              <thead>
                <tr>
                  <th class="align-middle">&nbsp;</th>
                  <th class="align-middle">Name</th>
                  <th class="align-middle">Time</th>
                  <th class="align-middle">Party</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="align-middle"><img src="assets/chiefministerImages/cm1.png" alt="" class="img-fluid" width="100" /></td>
                  <td class="align-middle">श्रीमती वसुन्‍धरा राजे</td>
                  <td class="align-middle">
                    <span>13-12-2013 से ... </span><br />
                    <span>08-12-2003 से 13-12-2008</span>
                  </td>
                  <td class="align-middle">भारतीय जनता पार्टी</td>
                </tr>
                <tr>
                  <td class="align-middle"> <img src="assets/chiefministerImages/gehlotnew.jpg" alt="" class="img-fluid" width="100" /></td>
                  <td class="align-middle">श्री अशोक गहलोत</td>
                  <td class="align-middle">
                    <span>13-12-2008 से 13-12-2013  </span><br />
                    <span>01-12-1998 से 08-12-2003</span>
                  </td>
                  <td class="align-middle">इण्डियन नेशनल कांग्रेस</td>
                </tr>

                <tr>
                  <td class="align-middle"><img src="assets/chiefministerImages/cm6.JPG" alt="" class="img-fluid" width="100" /></td>
                  <td class="align-middle">श्री भैरोसिंह शेखावत</td>
                  <td class="align-middle">
                    <span>04-12-1993 से 01-12-1998</span><br />
                    <span>
                      04-03-1990 से 15-12-1992
                    </span><br />
                    <span>22-06-1977 से 16-02-1980</span>
                  </td>
                  <td class="align-middle">भारतीय जनता पार्टी / जनता पार्टी</td>
                </tr>
                <tr>
                  <td class="align-middle"><img src="assets/chiefministerImages/cm10.jpg" alt="" class="img-fluid" width="100" /></td>
                  <td class="align-middle">श्री हीरा लाल देवपुरा</td>
                  <td class="align-middle">23-02-1985 से 10-03-1985</td>
                  <td class="align-middle">इण्डियन नेशनल कांग्रेस</td>
                </tr>
                <tr>
                  <td class="align-middle"><img src="assets/chiefministerImages/cm9.jpg" alt="" class="img-fluid" width="100" /></td>
                  <td class="align-middle">श्री शिवचरण माथुर</td>
                  <td class="align-middle"><span>20-01-1988 से 04-12-1989</span><br /><span>14-07-1981 से 23-02-1985</span></td>
                  <td class="align-middle">इण्डियन नेशनल कांग्रेस</td>
                </tr>
                <tr>
                  <td class="align-middle"><img src="assets/chiefministerImages/cm8.jpg" alt="" class="img-fluid" width="100" /></td>
                  <td class="align-middle">श्री जगन्‍नाथ पहाडिया</td>
                  <td class="align-middle"><span>06-06-1980 से 13-07-1981</span></td>
                  <td class="align-middle">इण्डियन नेशनल कांग्रेस</td>
                </tr>
                <tr>
                  <td class="align-middle"><img src="assets/chiefministerImages/cm7.jpg" alt="" class="img-fluid" width="100" /></td>
                  <td class="align-middle">श्री हरिदेव जोशी</td>
                  <td class="align-middle">
                    <span>04-12-1989 से 04-03-1990</span><br />
                    <span>10-03-1985 से 20-01-1988</span><br />
                    <span>11-10-1973 से 29-04-1977</span>
                  </td>
                  <td class="align-middle">इण्डियन नेशनल कांग्रेस</td>
                </tr>
                <tr>
                  <td class="align-middle"><img src="assets/chiefministerImages/cm5.jpg" alt="" class="img-fluid" width="100" /></td>
                  <td class="align-middle">श्री बरकतुल्‍ला खान</td>
                  <td class="align-middle">
                    <span>09-07-1971 से 11-10-1973</span>

                  </td>
                  <td class="align-middle">इण्डियन नेशनल कांग्रेस</td>
                </tr>
                <tr>
                  <td class="align-middle"><img src="assets/chiefministerImages/cm2.jpg" alt="" class="img-fluid" width="100" /></td>
                  <td class="align-middle">श्री मोहनलाल सुखाडिया</td>
                  <td class="align-middle">
                    <span> 26-04-1967 से 09-07-1971</span><br />
                    <span> 12-03-1962 से 13-03-1967</span><br />
                    <span> 11-04-1957 से 11-03-1962</span><br /><span> 13-11-1954 से 11-04-1957</span>
                  </td>
                  <td class="align-middle">इण्डियन नेशनल कांग्रेस</td>
                </tr>
                <tr>
                  <td class="align-middle"><img src="assets/chiefministerImages/cm11.jpg" alt="" class="img-fluid" width="100" /></td>
                  <td class="align-middle">श्री टीकाराम पालीवाल</td>
                  <td class="align-middle">
                    <span>  03-03-1952 से 31-10-1952</span>


                  </td>
                  <td class="align-middle">इण्डियन नेशनल कांग्रेस</td>
                </tr>
                <tr>
                  <td class="align-middle"><img src="assets/chiefministerImages/cm4.jpg" alt="" class="img-fluid" width="100" /></td>
                  <td class="align-middle">श्री जयनारायण व्‍यास</td>
                  <td class="align-middle">
                    <span> 01-11-1952 से 12-11-1954</span><br />
                    <span> 26-04-1951 से 03-03-1952</span>

                  </td>
                  <td class="align-middle">इण्डियन नेशनल कांग्रेस</td>
                </tr>

                <tr>
                  <td class="align-middle"><img src="assets/chiefministerImages/CSVenkatachari.JPG" alt="" class="img-fluid" width="100" /></td>
                  <td class="align-middle">श्री सी.एस. वेंकटाचारी</td>
                  <td class="align-middle">
                    <span>06-01-1951 से 25-04-1951</span>
                  </td>
                  <td class="align-middle"></td>
                </tr>

                <tr>
                  <td class="align-middle"><img src="assets/chiefministerImages/images/cm3.jpg" alt="" class="img-fluid" width="100" /></td>
                  <td class="align-middle">श्री हीरालाल शास्‍त्री</td>
                  <td class="align-middle">
                    <span>07-04-1949 से 05-01-1951</span>
                  </td>
                  <td class="align-middle">इण्डियन नेशनल कांग्रेस</td>
                </tr>
              </tbody>
            </table>

            <table class="table table-bordered table-striped table-red" *ngIf="this.translate.currlang == 'en'">
              <thead>
                <tr>
                  <th class="align-middle">&nbsp;</th>
                  <th class="align-middle">Name</th>
                  <th class="align-middle">Time</th>
                  <th class="align-middle">Party</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="align-middle"><img src="assets/chiefministerImages/cm1.png" alt="" class="img-fluid" width="100" /></td>
                  <td class="align-middle">Smt. Vasundhara Raje</td>
                  <td class="align-middle">
                    <span>13-12-2013 to ... </span><br />
                    <span> 08-12-2003 to 13-12-2008</span>
                  </td>
                  <td class="align-middle">BJP</td>
                </tr>
                <tr>
                  <td class="align-middle"><img src="assets/chiefministerImages/gehlotnew.jpg" alt="" class="img-fluid" width="100" /></td>
                  <td class="align-middle">Shri Bhajan Lal Sharma</td>
                  <td class="align-middle">
                    <span>13-12-2008 to 13-12-2013 </span><br />
                    <span>01-12-1998 to 13-12-2003</span>
                  </td>
                  <td class="align-middle">INC</td>
                </tr>

                <tr>
                  <td class="align-middle"><img src="assets/chiefministerImages/cm6.jpg" alt="" class="img-fluid" width="100" /></td>
                  <td class="align-middle">Shri Bhairon Singh Shekhawat</td>
                  <td class="align-middle">
                    <span>04-12-1993 to 01-12-1998</span><br />
                    <span>
                      04-03-1990 to 15-12-1992
                    </span><br />
                    <span>22-06-1977 to 16-02-1980</span>
                  </td>
                  <td class="align-middle">BJP / JP</td>
                </tr>
                <tr>
                  <td class="align-middle"><img src="assets/chiefministerImages/cm10.jpg" alt="" class="img-fluid" width="100" /></td>
                  <td class="align-middle">Shri Heera Lal Devpura</td>
                  <td class="align-middle">23-02-1985 to 10-03-1985</td>
                  <td class="align-middle">INC</td>
                </tr>
                <tr>
                  <td class="align-middle"><img src="assets/chiefministerImages/cm9.jpg" alt="" class="img-fluid" width="100" /></td>
                  <td class="align-middle">Shri Shiv Charan Mathur</td>
                  <td class="align-middle">
                    <span>20-01-1988 to 04-12-1989</span><br />
                    <span>14-07-1981 to 23-02-1985</span>
                  </td>
                  <td class="align-middle">INC</td>
                </tr>
                <tr>
                  <td class="align-middle"><img src="assets/chiefministerImages/cm8.jpg" alt="" class="img-fluid" width="100" /></td>
                  <td class="align-middle">Shri Jagannath Pahadia</td>
                  <td class="align-middle"><span>06-06-1980 to 13-07-1981</span></td>
                  <td class="align-middle">INC</td>
                </tr>
                <tr>
                  <td class="align-middle"><img src="assets/chiefministerImages/cm7.jpg" alt="" class="img-fluid" width="100" /></td>
                  <td class="align-middle">Shri Hari Dev Joshi</td>
                  <td class="align-middle">
                    <span>04-12-1989 to 04-03-1990</span><br />
                    <span>10-03-1985 to 20-01-1988</span><br />
                    <span> 11-10-1973 to 29-04-1977</span>
                  </td>
                  <td class="align-middle">INC</td>
                </tr>
                <tr>
                  <td class="align-middle"><img src="assets/chiefministerImages/cm5.jpg" alt="" class="img-fluid" width="100" /></td>
                  <td class="align-middle">Shri Barkatullah Khan</td>
                  <td class="align-middle">
                    <span> 09-07-1971 to 11-10-1973</span>

                  </td>
                  <td class="align-middle">INC</td>
                </tr>
                <tr>
                  <td class="align-middle"><img src="assets/chiefministerImages/cm2.jpg" alt="" class="img-fluid" width="100" /></td>
                  <td class="align-middle">Shri Mohan Lal Sukhadia</td>
                  <td class="align-middle">
                    <span> 26-04-1967 to 09-07-1971</span><br />
                    <span> 12-03-1962 to 13-03-1967</span><br />
                    <span> 11-04-1957 to 11-03-1962</span><br /><span> 13-11-1954 to 11-04-1957</span>
                  </td>
                  <td class="align-middle">INC</td>
                </tr>
                <tr>
                  <td class="align-middle"><img src="assets/chiefministerImages/cm11.jpg" alt="" class="img-fluid" width="100" /></td>
                  <td class="align-middle">Shri Tikaram Paliwal</td>
                  <td class="align-middle">
                    <span>  03-03-1952 to 31-10-1952</span>


                  </td>
                  <td class="align-middle">INC</td>
                </tr>
                <tr>
                  <td class="align-middle"><img src="assets/chiefministerImages/cm4.jpg" alt="" class="img-fluid" width="100" /></td>
                  <td class="align-middle">Shri Jai Narayan Vyas</td>
                  <td class="align-middle">
                    <span> 01-11-1952 to 12-11-1954</span><br />
                    <span> 26-04-1951 to 03-03-1952</span>

                  </td>
                  <td class="align-middle">INC</td>
                </tr>

                <tr>
                  <td class="align-middle"><img src="assets/chiefministerImages/CSVenkatachari.JPG" alt="" class="img-fluid" width="100" /></td>
                  <td class="align-middle">Shri C.S. Venkatachari</td>
                  <td class="align-middle">
                    <span>06-01-1951 to 25-04-1951</span>
                  </td>
                  <td class="align-middle"></td>
                </tr>

                <tr>
                  <td class="align-middle"><img src="assets/chiefministerImages/cm3.jpg" class="img-fluid" width="100" /></td>
                  <td class="align-middle">Shri Heera Lal Shastri</td>
                  <td class="align-middle">
                    <span>07-04-1949 to 05-01-1951</span>
                  </td>
                  <td class="align-middle">INC</td>
                </tr>



              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>-->