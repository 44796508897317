<!-- Rmove this class from div bcox it has cm sir image "breadcrumbs-page-img" -->
<!-- <div class="bg-scroll pos-rel breadcrumbs-page-img">
  <div class="container">
    <h1>{{'Photo_Gallery'| translate}}</h1>
  </div>
</div> -->
<div class="breadcrumbs-page-img">
  <div class="banner-hight-2">
    <h1>{{'Photo_Gallery'| translate}}</h1>
    <div class="inner-page-banner">
      <img src="assets/images/cmo-banner.png" alt="banner" class="cmo-banner">
    </div>
  </div>
</div>
<main id="main">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
      <li class="breadcrumb-item"><a href="javascript:void(0)">{{'Media_CornerSubNav'| translate}}</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{'PhotosSubNav'| translate}}</li>
    </ol>
  </nav>
  <nav class="navbar justify-content-between m-1">
    <a class="navbar-brand"></a>
    <div class="form-inline">
      <div class="text-start">
        <label>From Date</label>
        <input class="form-control mr-sm-2" type="date" name="" id="" [(ngModel)]="fromDate">
      </div>
      <div class="text-start">
        <label>To Date</label>
        <input class="form-control mr-sm-2" type="date" name="" id="" [(ngModel)]="toDate">
      </div>
      <div class="text-start">
        <label>Search</label>
        <input class="form-control mr-sm-2" [(ngModel)]="searchText" type="search" placeholder="Search" aria-label="Search">
      </div>
      <div class="search-btn">
        <label>&nbsp;</label> 
        <button class="btn btn-outline-primary mr-sm-2" type="submit" (click)="onSearch()"><i class="fa fa-search"></i></button>
      </div>
    </div>
  </nav>
  <section class="gallery_page">
    <p *ngIf="isLoading" class="text-center"><b>Loading...</b></p>
    <div class="container" *ngIf="!isLoading">
      <div class="row mb-4">
        <div class='col-sm-4 col-xs-6 col-md-4 col-lg-4 mb-4' *ngFor="let gallery of generalModel let i = index">
          <div class="card wow"
            [ngClass]="[i%3 <1 ? 'fadeInLeft' : i%3 <2? 'fadeInUp':i%3 <3? 'fadeInRight':'fadeInLeft' ]">
              <a style="cursor: pointer;" data-bs-toggle="modal" data-bs-target="#galleryBlock" (click)="GetPressReleaseMegaEventDataById(gallery.Id)"> <!-- routerLink="/gallerydetail/{{gallery.Id}}"-->
                <div class="program-img-bx">
                <img src="{{gallery.HomePageImageUrl}}" onError="this.src='../../assets/images/gallery-no-img.png'"
                  alt="" class="img-fluid" onError="this.src='../../assets/images/program-no-img.png'">
              </div>
            </a>

            <div class="gallery_caption">
              <h3
                *ngIf="(this.translate.currlang == 'hi' && gallery.Description.length<=50 )||(this.translate.currlang != 'hi' && gallery.Description.length<=50)">
                {{this.translate.currlang == 'hi' ? gallery.Description : gallery.Description}}</h3>
              <h3
                *ngIf="(this.translate.currlang == 'hi' && gallery.Description.length>50 )||(this.translate.currlang != 'hi' && gallery.Description.length>50)">
                {{(this.translate.currlang == 'hi' ? gallery.Description : gallery.Description)}}</h3> <!--|slice:0:50-->
              <!--<div class="location">Jodhpur</div>-->
              <div class="date">{{this.translate.currlang == 'hi' ? gallery.PressReleaseDateHindi :
                gallery.PressreleaseDate | date : 'd MMM, y'}}</div>
            </div>
          </div>
        </div>
        <div class="mx-auto col-12">
          <select name="example_length " aria-controls="example " (change)="onPageSize($event)" [(ngModel)]="pageSize" *ngIf="totalRecords > 9"
            class="form-control-sm ng-pristine ng-valid ng-touched" style="width: 70px;">
            <option [value]="totalRecords"> All </option>
            <option value="9">9</option>
            <option value="18">18</option>
            <option value="36">36</option>
            <option value="72">72</option>
            <option value="144">144</option>
          </select>
        </div>
        <nav aria-label="Page navigation example" class="w-100 text-center mt-4" *ngIf="totalRecords > 9">
          <ul class="pagination d-inline-flex" *ngIf="pager.pages && pager.pages.length">
            <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
              <a class="page-link" (click)="GetPressReleaseData(1,pageSize)">{{'First'| translate}}</a>
            </li>
            <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
              <a class="page-link" (click)="GetPressReleaseData(pager.currentPage - 1,pageSize)">{{'Previous'| translate}}</a>
            </li>
            <li class="page-item" *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
              <a class="page-link" (click)="GetPressReleaseData(page,pageSize)">{{page}}</a>
            </li>
            <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
              <a class="page-link" (click)="GetPressReleaseData(pager.currentPage + 1,pageSize)">{{'Next'| translate}}</a>
            </li>
            <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
              <a class="page-link" (click)="GetPressReleaseData(pager.totalPages,pageSize)">{{'Last'| translate}}</a>
            </li>
          </ul>
        </nav>
        <!-- <ng-template #other_content>Record Not Found</ng-template> -->
      </div>
      <div class="row" *ngIf="generalModel && generalModel.length == 0">
        <div class="no-record">
          {{'No_Record_Found'| translate}}
        </div>
      </div>
    </div>
  </section>
</main>
<!--<section class="inner-page-banner">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="page-title">{{'Home_OUR_GALLERY'| translate}}</div>
      </div>
    </div>
  </div>
</section>


<section class="gallery gallery-page inner-page">
  <div class="container">
    <div class="row">
      <div class="col-md-12" align="right">
        <input [(ngModel)]="searchBox" type="text" />
        <button class="btn btn-primary" (click)="getData(currentPage);">Search</button>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4" *ngFor="let gallery of pagedItems">
        <div class="gallery-bx">

          <a routerLink="/gallerydetail/{{gallery.ProgramId}}"><img src="{{this.imageUrl}}{{gallery.ImageName}}" onError="this.src='../../assets/images/gallery-no-img.png'" alt="" class="img-fluid"></a>

          <div class="gallery-text">
            <h2>{{this.translate.currlang == 'hi' ? gallery.ProgramSubjectHindi : gallery.ProgramSubject}} </h2>
            <div class="date"><i class="fas fa-calendar-alt"></i> &nbsp;{{this.translate.currlang == 'hi' ? gallery.ProgramDateHindi : gallery.ProgramDate}}</div>
          </div>
        </div>
      </div>


      <div class="col-sm-12 text-center row mt-4" *ngIf="totalPage > 9">


        <ul class="pagination mx-auto text-center" *ngIf="pager.pages && pager.pages.length">
          <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
            <a class="page-link" (click)="getData(1)">{{'First'| translate}}</a>
          </li>
          <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
            <a class="page-link" (click)="getData(pager.currentPage - 1)">{{'Previous'| translate}}</a>
          </li>
          <li class="page-item" *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
            <a class="page-link" (click)="getData(page)">{{page}}</a>
          </li>
          <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
            <a class="page-link" (click)="getData(pager.currentPage + 1)">{{'Next'| translate}}</a>
          </li>
          <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
            <a class="page-link" (click)="getData(pager.totalPages)">{{'Last'| translate}}</a>
          </li>
        </ul>

      </div>
      <ng-template #other_content>Record Not Found</ng-template>

    </div>
  </div>
</section>-->

<div class="modal fade galleryBlock" id="galleryBlock" tabindex="-1" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"></h5>
        <button type="button" class="close close-in" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" >
        <p *ngIf="IsLoading" class="text-left"><b>Loading...</b></p>
        <div id="carouselExampleControls" class="carousel slide mb-2" data-bs-ride="carousel"
              *ngIf="galleryDetails?.ImageAttachments.length>0 || !IsLoading">
              <div class="carousel-inner">
                <div class="carousel-item" [ngClass]="[i==0 ? 'active' : '' ]"
                  *ngFor="let item of galleryDetails?.ImageAttachments let i = index">
                  <img class="d-block w-100" src="{{item}}" alt="item{{i}}"
                    onError="this.src='../../assets/images/program-no-img.png'">
                </div>

              </div>
              <a (click)="imageIndex=0" class="carousel-control-prev" href="#carouselExampleControls" role="button" data-bs-slide="prev"> <span
                  class="carousel-control-prev-icon" aria-hidden="true"></span> <span class="sr-only">Previous</span>
              </a> <a (click)="imageIndex=0" class="carousel-control-next" href="#carouselExampleControls" role="button" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span> <span class="sr-only">Next</span>
              </a>
              <div class="carousel-inner" *ngIf="!galleryDetails">

                <img class="d-block w-100" src="../../assets/images/default_img1.png" />


              </div>

            </div>
            <div class="carousel-inner" *ngIf="galleryDetails?.ImageAttachments.length==0">

              <img class="d-block w-100" src={{galleryDetails.HomePageImageUrl}} />


            </div>
        <!-- <img class="d-block w-100" src="{{image}}" alt=""
          onError="this.src='../../assets/images/program-no-img.png'"> -->
      </div>

    </div>
  </div>
</div>