import { Component, Inject, OnInit } from '@angular/core';
import { PagerService } from '../pager.service';
import { environment } from '../../environments/environment';
import { TranslateService } from '../translate.service';
import { Router } from '@angular/router';
import { EventService } from '../services/events.services';
import { ProgramMegaEventRequestModel } from '../Model/event.model';
import { InAugrationListModel, PressReleaseFrontSearchModel } from '../Model/press-release-model.model';
import { NewsService } from '../services/news.services';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DepartmentCodeEnum, PressReleaseCategoryTypeEnum, PressReleaseCMOPRCategoryEnum } from '../Enum/app-enums';
import { VIPCategoryEnum, VIPPersonCategoryEnum } from '../Enum/jankayan.enum';
import { LookupEnum } from '../Enum/lookup-type.enum';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-mega-event-list',
  templateUrl: './mega-event-list.component.html',
  styleUrls: ['./mega-event-list.component.css'],
  providers: [EventService,NewsService,DatePipe]
})
export class MegaEventListComponent implements OnInit {
  megaEventPrograms: ProgramListModel[];
  // paged items
  pagedItems: any;
  totalEvents: number;
  searchBox = '';
  imageUrl = environment.imageUrl + 'Program/Image/Thumbnail/Fr_';
  mainImageUrl = environment.imageUrl + 'Program/ProgramMainImage/';
  // pager object
  pager: any = {};
  currentPage: number;
  modelRequest: ProgramMegaEventRequestModel;

  generalModel: InAugrationListModel[];
  filterModel = new PressReleaseFrontSearchModel();
  categoryCode: number = 0;
  totalRecords = 0;
  pagerConfig: any;
  pressReleaseCategoryTypeEnum= PressReleaseCategoryTypeEnum;
  pressReleaseCMOPRCategoryEnum= PressReleaseCMOPRCategoryEnum;
  IsLoading = false;
  pageSize = 8;
  today = new Date();
  fromDate:any;
  toDate:any;
  days = 10; // Days you want to subtract
  date = new Date();
  lastDate = new Date('2023-12-15') //new Date(this.date.getTime() - (this.days * 24 * 60 * 60 * 1000));
  searchText:any = '';
  
  constructor(@Inject('BASE_URL') public baseUrl: string,
    private pagerService: PagerService, 
    public readonly translate: TranslateService,
    private router: Router, 
    private programService: EventService,
    private newsService: NewsService,
    private datePipe: DatePipe,
    public sanitizer: DomSanitizer) {
    this.pagedItems = [];
    this.currentPage = 1;
    this.modelRequest = new ProgramMegaEventRequestModel();
    this.pagerConfig = {
      id: "prs09890",
      itemsPerPage: this.filterModel.PageSize,
      currentPage: this.filterModel.Page,
      totalItems: this.totalRecords,
    };
  }
  ngOnInit() {
    this.fromDate = this.datePipe.transform(this.lastDate,'yyyy-MM-dd')
    this.toDate = this.datePipe.transform(this.today,'yyyy-MM-dd')
    //this.getData(this.currentPage,this.pageSize);
    this.GetPressReleaseMegaEventData(this.currentPage,this.pageSize);
  }

  onPageSize(evt:any){
    this.pageSize = evt.target.value
    this.GetPressReleaseMegaEventData(this.currentPage,this.pageSize)
  }

  onSearch(){
    this.GetPressReleaseMegaEventData(this.currentPage,this.pageSize)
  }

  setLang(lang: string) {
    this.translate.use(lang);
  }


  public getData(page: number,pagesize:any) {
    this.modelRequest.page = page;
    this.modelRequest.text = this.searchBox;
    this.programService.GetProgramMegaEventListLatest(this.modelRequest).subscribe(success => {
      if (success !== undefined && success !== null) {
        this.megaEventPrograms = success;
        
        this.totalEvents = Number(this.megaEventPrograms[0].TotalEvents);
        this.setPage(page,pagesize);
      }
    }, error => {
      console.error(error);
    });
  }



  setPage(page: number,pagesize:any) {
    window.scroll(0, 0);
    this.currentPage = page;
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    // get pager object from service
    this.pager = this.pagerService.getPager(this.totalRecords, page, pagesize);
    // get current page of items
    this.pagedItems = this.generalModel;
  }
// Janklayan API
  GetPressReleaseMegaEventData(page: number,pagesize:any) {
    this.IsLoading = true;
    this.generalModel=undefined;
    this.filterModel.IsBase64File = false;
    this.filterModel.DepartmentCode = DepartmentCodeEnum.CmoWebsiteCode;
    this.filterModel.CategoryCode = this.pressReleaseCategoryTypeEnum.PressReleaseCode;
    this.filterModel.VIPCategoryCode = VIPCategoryEnum.HCMCode;
    // this.filterModel.VIPPersonCode = VIPPersonCategoryEnum.ShriAshokGehlotCode;
    this.filterModel.VIPPersonCode = VIPPersonCategoryEnum.ShriBhajanLalSharmaCode;
    this.filterModel.CMOPRCategoryCode = this.pressReleaseCMOPRCategoryEnum.MegaEventCode;
    this.filterModel.OrderBy = "PressreleaseDate";
    this.filterModel.Page = page;
    this.filterModel.PageSize = pagesize;
    this.filterModel.PressReleaseToDate = this.toDate;
    this.filterModel.PressReleaseFromDate = this.fromDate;
    this.filterModel.Description = this.searchText;
    this.filterModel.PressReleaseLevelCode = LookupEnum.PressReleaseLevelCode_For_State;

    this.newsService.GetPressReleaseList(this.filterModel).subscribe((response) => {
      this.IsLoading = false;
      if (response.IsSuccess) {
        this.generalModel = response.Data.Data as InAugrationListModel[];        
        this.totalRecords = response.Data.TotalRecords;           
        this.setPage(page,pagesize);
      }else{
        this.generalModel=[]
      }
    }, (error) => {
      this.generalModel=[]
      console.error(error);
    });
  }

  posterUpdatePageConfig() {
    this.pagerConfig.itemsPerPage = this.filterModel.PageSize;
    this.pagerConfig.currentPage = this.filterModel.Page;
    this.pagerConfig.totalItems = this.totalRecords;
  }

  sanitizeImageUrl(imageUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }
}


export class ProgramListModel {

  ProgramId: number;
  ProgramCategoryId: number;
  ProgramSubCategoryId: number;
  ProgramDate: string;
  ProgramDistrictId: number;
  ProgramLocationId: number;
  ProgramAddress: string;
  ProgramAddressHindi: string;
  ProgramLanguage: string;
  ProgramSubject: string;
  ProgramSubjectHindi: string;
  ProgramSpeech: string;
  ProgramSpeechHindi: string;
  Keywords: string;
  KeywordsHindi: string;
  SpeechPdf: string;
  Pdf: string;
  IsActive: boolean;
  IsDeleted: boolean;
  ProgramStateId: number;
  programtime: string;
  UserId: string;
  IsShowLatest: string;
  KeyImage: string;
  FolderName: string;
  PopupImages: string;
  PdfEnglish: string;
  Status: string;
  District: string;
  ProgramCategory: string;
  ProgramCategoryhindi: string;
  ProgramSubCategoryhindi: string;
  ProgramSubCategory: string;
  ImageName: string;
  ProgramDateHindi: string;
  ProgramMainImage: string;
  ProgramGallery: GalleryDetailModel[];
  TotalEvents: number;

}
interface GalleryDetailModel {

  ProgramAddress: string;
  ProgramAddressHindi: string;
  ImageName: string;
  ProgramDate: string;
  ProgramSubcategory: string;
  ProgramSubcategoryhindi: string;
  ProgramSubjecthindi: string;
  ProgramSubject: string;
  ProgramDateHindi: string;

}

