import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '../translate.service';
import { EventService } from './../services/events.services';
import { environment } from '../../environments/environment';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { AchievementAllSearchModel, AchievementViewModel } from '../Model/awards-request-model.model';
import { DepartmentCodeEnum, RajAdvt_AchievementCategoryEnum, RajAdvt_AchievementSubCategoryEnum } from '../Enum/app-enums';
import { AchievementCategoryEnum } from '../Enum/achievement-category.enum';
import { AchievementService } from '../services/Achievement.services';
import { CommonService } from '../services/common.service';
import { MediaService } from '../services/media.services';
import { NewsService } from '../services/news.services';
import { CMProfileModel } from '../Model/cm-profile.model';
import { EntryTypeEnum, JankalyanCategoryEnum } from '../Enum/jankayan.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  providers: [EventService, AchievementService, MediaService,NewsService]
})
export class MainComponent implements OnInit {
  bannerImageList: BannerImageList[];
  imageUrlTemp = environment.imageUrl + 'Program/BannerImage/';
  rajAdvtSideBarUrl: String = environment.AdvertisementUrl + '#/side-bar';

  bannerImagesModel: AchievementViewModel[] = [];
  newsImagesModel: AchievementViewModel[] = [];
  // isCodeOfConduct: boolean = true;
  isCodeOfConduct: boolean = false;
  CMProfileModel = new CMProfileModel();
  CMProfileModelViewModel:any;
  TaglineModel:any;

  constructor(@Inject('BASE_URL') public baseUrl: string,
    public translate: TranslateService,
    private eventService: EventService,
    public sanitizer: DomSanitizer,
    private router: Router,
    private readonly _achivementService: AchievementService,
    private newsService: NewsService,
    public readonly _commonService: CommonService) {
    this.isCodeOfConduct = this._commonService.GetIsCodeOfConduct();
    this.bannerImageList = [];
  }

  setLang(lang: string) {
    this.translate.use(lang);
  }

  ngOnInit() {
    //this.getImageData();
    this.GetBannerData();
    this.GetNewsStickerData();
    this.GetCMProfile();
    this.GetTagline();
  }
  getImageData() {
    this.eventService.GetBannerList().subscribe(success => {
      if (success !== undefined && success !== null) {
        this.bannerImageList = success;
      }
    }, error => {
      console.error(error);
    });
  }

  scrollToTarget() {
    window.scrollTo({ top: 3000, behavior: 'smooth' });    
    // this.router.navigate(['/target'], { fragment: 'scrollTarget' });
  }


  sanitizeImageUrl(imageUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }

  GetCMProfile() {
    this.CMProfileModel.IndexModel.PageSize = 1;
    this.CMProfileModel.DepartmentCode = DepartmentCodeEnum.CmoWebsiteCode;
    this.CMProfileModel.JankalyanCategoryCode = JankalyanCategoryEnum.CMProfileCode;
    this.CMProfileModel.EntryTypeCode = EntryTypeEnum.CMProfileCode

    this.newsService.GetCMProfile(this.CMProfileModel).subscribe((response: any) => {
      if (response.IsSuccess) {
        this.CMProfileModelViewModel = response.Data.Data[0];
        // console.log(this.CMProfileModelViewModel, "response")
      }
    });
  }

  GetTagline() {
    this.CMProfileModel.IndexModel.PageSize = 1;
    this.CMProfileModel.DepartmentCode = DepartmentCodeEnum.CmoWebsiteCode;
    this.CMProfileModel.JankalyanCategoryCode = JankalyanCategoryEnum.CMProfileCode;
    this.CMProfileModel.EntryTypeCode = EntryTypeEnum.TaglineCode

    this.newsService.GetTagline(this.CMProfileModel).subscribe((response: any) => {
      if (response.IsSuccess) {
        this.TaglineModel = response.Data ? response.Data.Data[0] : [];
      }
    });
  }


  GetBannerData() {
    let filterModel = new AchievementAllSearchModel();
    filterModel.IndexModel.OrderBy = "Priority";
    filterModel.IndexModel.OrderByAsc = 1;
    filterModel.CategoryCode = AchievementCategoryEnum.WebHomePageImageCode;
    filterModel.DepartmentCode = DepartmentCodeEnum.CmoWebsiteCode;
    //filterModel.DepartmentCode = 10; //Static code passed only for checking banner images
    filterModel.IndexModel.PageSize = 5;
    filterModel.IsBase64File = false;

    this._achivementService.GetAchievementListBySearchFilterData(filterModel, false).subscribe((response) => {
      if (response.IsSuccess) {
        this.bannerImagesModel = undefined;
        this.bannerImagesModel = response.Data.Data;
      }
    }, (error) => {
      console.error(error);
    });
  }

  GetNewsStickerData() {
    let filterModel = new AchievementAllSearchModel();
    filterModel.IndexModel.OrderBy = "Priority";
    filterModel.IndexModel.OrderByAsc = 1;
    filterModel.CategoryCode = AchievementCategoryEnum.NewsTickerCode;
    filterModel.DepartmentCode = DepartmentCodeEnum.CmoWebsiteCode;
    //filterModel.DepartmentCode = 982; //Static code passed only for checking banner images
    filterModel.IndexModel.PageSize = 5;
    filterModel.IsBase64File = false;

    this._achivementService.GetAchievementListBySearchFilterData(filterModel, false).subscribe((response) => {
      if (response.IsSuccess) {
        this.newsImagesModel = [];
        this.newsImagesModel = response.Data.Data;
      }
    }, (error) => {
      console.error(error);
    });
  }



}


export class BannerImageList {
  ProgramId: number;
  BannerImages: string;
}


