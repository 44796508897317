<!--<ng-container *ngFor="let item of this.advertisements.AdvertismentDataList">
  <img [src]="item.DocumentUrl" alt="" class="img-fluid" />
</ng-container>-->

<div class="modal fade" id="myModal" data-bs-keyboard="false" data-bs-backdrop="static">
  <div class="modal-dialog" style="max-width:1000px">
    <div class="modal-content">
      <div class="modal-header">
        <!--<div class="pull-left"></div>-->
        <button type="button" class="close" data-bs-dismiss="modal" title="Close"> <span class="glyphicon glyphicon-remove">X</span></button>
      </div>
      <iframe [src]="popUpUrl" width="100%" height="610px"></iframe>
      <!--<div id="popup" class="carousel slide offer-popup_slider text-center" data-bs-ride="carousel" data-bs-interval="3000">

    <div class="carousel-inner">


      <div class="carousel-item"
           *ngFor="let temp of model?.AdvertismentDataList; let i = index"
           [ngClass]="i == 0 ? 'active' : ''">
        <img [src]="getSafeImageUrl(temp.DocumentUrl)" alt="" class="img-fluid" />
        <button *ngIf="temp.PdfUrl"
                (click)="onDownload(temp.PdfUrl)"
                Target="_blank"
                class="btn btn-info btn_pdf">
          Pdf Download
        </button>
      </div>
    </div>
    <a class="carousel-control-prev" href="#popup" data-bs-slide="prev">
      <span class="fas fa-chevron-left"></span>
    </a>
    <a class="carousel-control-next" href="#popup" data-bs-slide="next">
      <span class="fas fa-chevron-right"></span>
    </a>
  </div>-->
      <!--end modal-content-->
    </div>
    <!--end modal-dialoge-->
  </div>
  <!--end myModal-->>
</div>




