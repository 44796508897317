import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, Component, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { Data } from './services/data.services';
import { BaseService } from './services/baseservice';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { NewsComponent } from './news/news.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { RecentNewsComponent } from './recent-news/recent-news.component';
import { ProgramComponent } from './program/program.component';
import { GalleryComponent } from './gallery/gallery.component';
import { HomeVideoComponent } from './home-video/home-video.component';
import { HomeSpeechComponent } from './home-speech/home-speech.component';
import { FooterComponent } from './footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { MatVideoModule } from 'mat-video';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MessageComponent } from './message/message.component';
import { GalleryListComponent } from './gallery-list/gallery-list.component';
import { EventListingComponent } from './event-listing/event-listing.component';
import { SpeechListingComponent } from './speech-listing/speech-listing.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ProgramDetailComponent } from './program-detail/program-detail.component';
import { GalleryDetailComponent } from './gallery-detail/gallery-detail.component';
import { VideogalleryComponent } from './videogallery/videogallery.component';
import { PagerService } from './pager.service';
import { SpeechDetailComponent } from './speech-detail/speech-detail.component';
import { RecentnewsListComponent } from './recentnews-list/recentnews-list.component';
import { NewsLetterComponent } from './newsLetter/newsLetter.component';
import { ThankYouComponent } from './thankyou/thankyou.component';
import { WriteToCMComponent } from './writeToCM/writeToCM.component';
import { TranslateService } from './translate.service';
import { TranslatePipe } from './translate.pipe';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { RecentnewsdetailComponent } from './recentnewsdetail/recentnewsdetail.component';
import { ChiefMinistersofRajComponent } from './chief-ministersof-raj/chief-ministersof-raj.component';
import { AchivementComponent } from './achivement/achivement.component';
import { MessageListComponent } from './messageList/messageList.componemt';
import { MinisterlistComponent } from './ministerlist/ministerlist.component';
import { MessagedetailComponent } from './messagedetail/messagedetail.component';
import { ProfileCmComponent } from './profile-cm/profile-cm.component';
import { MainComponent } from './main/main.component';
import { CmReliefFundComponent } from './cmrelieffund/cmrelieffund.component';
import { StateministerComponent } from './stateminister/stateminister.component';
import { CmreliefComponent } from './cmrelief/cmrelief.component';
import { RtiComponent } from './rti/rti.component';
import { RajsamparkComponent } from './rajsampark/rajsampark.component';
import { ChiefsecretaryComponent } from './chiefsecretary/chiefsecretary.component';
import { DeputyCmComponent } from './deputy-cm/deputy-cm.component';
import { KeyOfficialsComponent } from './key-officials/key-officials.component';
import { CabinateministerComponent } from './cabinateminister/cabinateminister.component';
import { CalenderComponent } from './calender/calender.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { AboutrajasthanComponent } from './aboutrajasthan/aboutrajasthan.component';
import { HomeMainpressComponent } from './home-mainpress/home-mainpress.component';
import { HomePressReleaseComponent } from './home-press-release/home-press-release.component';
import { HomeLatestNewsComponent } from './home-latest-news/home-latest-news.component';
import { AdvertismentComponent } from './advertisment/advertisment.component';
import { KeyinitiativesComponent } from './keyinitiatives/keyinitiatives.component';
import { AwardComponent } from './awards/awards.component';
import { SearchComponent } from './search/search.component';
// import { NgDatepickerModule } from 'ng2-datepicker';
import { QuickLinkComponent } from './quick-link/quick-link.component';
import { HomeContactComponent } from './home-contact/home-contact.component';
import { CmoMessageComponent } from './cmo-message/cmo-message.component';
import { AwarddetailComponent } from './awarddetail/awarddetail.component';
import { AchivementdetailComponent } from './achivementdetail/achivementdetail.component';
import { MegaEventListComponent } from './mega-event-list/mega-event-list.component';
import { MegaEventDetailComponent } from './mega-event-detail/mega-event-detail.component';
import { SafeUrlPipeService } from './services/safe-url-pipe.service.service';
import { LoginComponent } from './login/login.component';
import { environment } from '../environments/environment';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HcmInvitationComponent } from './hcm-invitation/hcm-invitation.component';
import { AddRequestComponent } from './hcm-invitation/add-request/add-request.component';
import { PhotoGalleryComponent } from './photo-gallery/photo-gallery.component';
import { PhotoVideoUploaderGalleryComponent } from './photo-video-uploader-gallery/photo-video-uploader-gallery.component';
import { PhotoVideoUploaderGallerySectionComponent } from './photo-video-uploader-gallery-section/photo-video-uploader-gallery-section.component';
import { QuotesComponent } from './quotes/quotes.component';
import { QuotesDetailComponent } from './quotes-detail/quotes-detail.component';
import { SuggestionFeedbackComponent } from './suggestion-feedback/suggestion-feedback.component';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile/profile.component';
import { DepartmentPageDetailComponent } from './department-page-detail/department-page-detail.component';
import { NumberFormatterPipe } from './number-formatter.pipe';
import { CmProfileComponent } from './cm-profile/cm-profile.component';
import { LoaderComponent } from './loader/loader.component';
import { FormerCMListComponent } from './former-cm-list/former-cm-list.component';
import { SitemapComponent } from './sitemap/sitemap.component';
import { WebsitePoliciesComponent } from './website-policies/website-policies.component';
import { JankalyanCategoryAndEntryTypeComponent } from './jankalyan-category-and-entry-type/jankalyan-category-and-entry-type.component';
import { SpecialReleaseComponent } from './special-release/special-release.component';
import { MapComponent } from './map/map.component';
import { PhoneBoothComponent } from './phone-booth/phone-booth.component';
import { PhoneBoothAdminComponent } from './phone-booth-admin/phone-booth-admin.component';
import { PhoneBoothAdminLoginComponent } from './phone-booth-admin-login/phone-booth-admin-login.component';
import { authGuard, loginGuard } from './guards/auth.guard';
import { EmailFormatPipe } from './email-format.pipe';

export function setupTranslateFactory(
  service: TranslateService): Function {
  return () => service.use('');
}
@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    PdfViewerModule,
    BrowserAnimationsModule,
    // MatVideoModule,
    MatSidenavModule,
    // NgDatepickerModule,
    CommonModule,
    RouterModule.forRoot([
      { path: '', redirectTo: environment.LoginEnable ? '/login' : 'home', pathMatch: 'full' },
      { path: '', component: MainComponent },
      // { path: '', redirectTo: environment.LoginEnable ? '/login' : 'home', pathMatch: 'full' },
      // { path: environment.LoginEnable ? 'login' : '', component: environment.LoginEnable ? LoginComponent : HomeComponent },
      { path: 'login', component: LoginComponent },
      { path: 'program-list', component: EventListingComponent },
      // { path: 'pages/sm/press-release/gallery/:SubmenuCode/:DptCode/:CatCode/:SubCatCode', component: EventListingComponent },
      // { path: 'pages/press-release-list/:DptCode/:CatCode', component: EventListingComponent },
      { path: 'cm-speech', component: EventListingComponent },
      { path: 'gallery-list', component: GalleryListComponent },
      { path: 'sm/download-gallery/:submenuCode/:dptCode/:catCode', component: PhotoGalleryComponent },
      { path: 'sm/download-gallery/:submenuCode/:dptCode/:catCode/:subCatCode', component: PhotoGalleryComponent },
      { path: 'sm/photo-gallery/:submenuCode/:dptCode/:catCode', component: PhotoGalleryComponent },
      { path: 'sm/photo-gallery/:submenuCode/:dptCode/:catCode/:subCatCode', component: PhotoGalleryComponent },
      // { path: 'pages/sm/uploader/PHOTO/gallery/:submenuCode/:dptCode/:catCode', component: PhotoVideoUploaderGalleryComponent },
      { path: 'pages/uploader/:type/gallery/:dptCode/:catCode', component: PhotoVideoUploaderGalleryComponent },
      { path: 'pages/sm/uploader/:type/gallery/:submenuCode/:dptCode/:catCode/:actCode', component: PhotoVideoUploaderGalleryComponent },
      { path: 'speech-list', component: SpeechListingComponent },
      { path: 'contactus', component: ContactUsComponent },
      { path: 'video-gallery', component: VideogalleryComponent },
      { path: 'speeches', component: VideogalleryComponent },
      { path: 'Programdetail/:id', component: ProgramDetailComponent },
      { path: 'gallerydetail/:id', component: GalleryDetailComponent },
      { path: 'speechdetail/:id', component: SpeechDetailComponent },
      { path: 'pressrelease-list', component: RecentnewsListComponent },
      { path: 'pages/press-release-list/:DptCode/:CatCode', component: RecentnewsListComponent },
      { path: 'press-release-list/:dptCode/:CategoryCode/:SubCategoryCode', component: RecentnewsListComponent },
      { path: 'newsletter', component: NewsLetterComponent },
      { path: 'thankyou', component: ThankYouComponent },
      { path: 'writetocm', component: WriteToCMComponent },
      { path: 'pressreleasedetail/:id', component: RecentnewsdetailComponent },
      { path: 'Chiefministersofraj', component: ChiefMinistersofRajComponent },
      { path: 'messagefromcm', component: MessageComponent },
      { path: 'achivement-list', component: AchivementComponent },
      { path: 'achivementdetail/:id', component: AchivementdetailComponent },
      { path: 'messagelist', component: MessageListComponent },
      { path: 'ministerlist', component: MinisterlistComponent },
      { path: 'messageDetail/:id', component: MessagedetailComponent },
      // { path: 'profilecm', component: ProfileCmComponent },
      { path: '', component: ProfileCmComponent },
      { path: 'stateminister', component: StateministerComponent },
      { path: 'cmrelief', component: CmreliefComponent },
      { path: 'rti', component: RtiComponent },
      { path: 'rajsampark', component: RajsamparkComponent },
      { path: 'chiefsecretary', component: ChiefsecretaryComponent },
      { path: 'deputycm', component: DeputyCmComponent },
      { path: 'keyOfficial', component: KeyOfficialsComponent },
      // { path: 'pages/contact-directory/:DptCode/:CatCode', component: KeyOfficialsComponent },
      // { path: 'pages/sm/contact-directory/:SubmenuCode/:DptCode/:CatCode', component: KeyOfficialsComponent },
      { path: 'cabinatemin', component: CabinateministerComponent },
      { path: 'calendar', component: CalenderComponent },
      { path: 'profile', component: ProfileComponent },
      { path: 'about-rajasthan', component: AboutrajasthanComponent },
      { path: 'advertisment', component: AdvertismentComponent },
      { path: 'keyinitiatives', component: KeyinitiativesComponent },
      { path: 'home', component: MainComponent },
      { path: 'awards', component: AwardComponent },
      // { path: 'pages/sm/award/:SubmenuCode/0', component: AwardComponent },
      { path: 'search', component: SearchComponent },
      { path: 'awarddetail/:id', component: AwarddetailComponent },
      { path: 'mega-event-list', component: MegaEventListComponent },
      { path: 'mega-event-detail/:id', component: MegaEventDetailComponent },
      { path: 'cmis-achievement', component: AchivementComponent },
      { path: 'cmis-achievement/:catCode', component: AchivementComponent },
      { path: 'launching', component: LoginComponent },
      { path: 'launching/:ways', component: LoginComponent },
      { path: 'hcm-invitation-request', component: AddRequestComponent },
      { path: 'quotes', component: QuotesComponent },
      { path: 'quotesdetail/:id', component: QuotesDetailComponent },
      { path: 'suggestion-feedback', component: SuggestionFeedbackComponent },
      { path: 'pages/sm/department-page/:submenuCode/:pageCode', component:DepartmentPageDetailComponent },
      { path: 'cm-profile', component:CmProfileComponent },
      { path: 'pages/assembly-constituency/:designationCode', component: MinisterlistComponent },
      { path: 'former-cm-list', component: FormerCMListComponent },
      { path: 'target', component: ProgramComponent },
      { path: 'pages/website-policies', component: WebsitePoliciesComponent },
      { path: 'pages/sitemap', component: SitemapComponent },
      { path: 'jankalyan-category-and-entry-type/:deptCode/:catCode/:entryTypeCode', component: JankalyanCategoryAndEntryTypeComponent },
      { path: 'special-release-list', component: SpecialReleaseComponent },
      { path: 'special-release-detail/:id', component: RecentnewsdetailComponent },
      { path: 'web-directory/:type', component: MapComponent },
      { path: 'phone-booth', component: PhoneBoothComponent },
      { path: 'phone-booth-admin', component: PhoneBoothAdminComponent, canActivate:[authGuard] },
      { path: 'phone-booth-admin-login', component: PhoneBoothAdminLoginComponent, canActivate:[loginGuard] },
      { path: '**', redirectTo: '/404', pathMatch: 'full' },
      { path: '404', component: PageNotFoundComponent }
    ], { scrollPositionRestoration: 'enabled' }

    ),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    })
  ],

  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CounterComponent,
    NewsComponent,
    AboutUsComponent,
    RecentNewsComponent,
    ProgramComponent,
    GalleryComponent,
    HomeVideoComponent,
    HomeSpeechComponent,
    FooterComponent,
    MessageComponent,
    GalleryListComponent,
    EventListingComponent,
    SpeechListingComponent,
    ContactUsComponent,
    ProgramDetailComponent,
    GalleryDetailComponent,
    VideogalleryComponent,
    SpeechDetailComponent,
    RecentnewsListComponent,
    NewsLetterComponent,
    ThankYouComponent,
    WriteToCMComponent,
    TranslatePipe,
    RecentnewsdetailComponent,
    ChiefMinistersofRajComponent,
    AchivementComponent,
    MessageListComponent,
    MinisterlistComponent,
    MessagedetailComponent,
    ProfileCmComponent,
    MainComponent,
    CmReliefFundComponent,
    StateministerComponent,
    CmreliefComponent,
    RtiComponent,
    RajsamparkComponent,
    ChiefsecretaryComponent,
    DeputyCmComponent,
    KeyOfficialsComponent,
    CabinateministerComponent,
    CalenderComponent,
    AboutrajasthanComponent,
    HomeMainpressComponent,
    HomePressReleaseComponent,
    HomeLatestNewsComponent,
    AdvertismentComponent,
    KeyinitiativesComponent,
    AwardComponent,
    SearchComponent,
    QuickLinkComponent,
    HomeContactComponent,
    CmoMessageComponent,
    AwarddetailComponent,
    AchivementdetailComponent,
    MegaEventListComponent,
    MegaEventDetailComponent,
    SafeUrlPipeService,
    LoginComponent,
    PageNotFoundComponent,
    HcmInvitationComponent,
    AddRequestComponent,
    PhotoGalleryComponent,
    PhotoVideoUploaderGalleryComponent,
    PhotoVideoUploaderGallerySectionComponent,
    QuotesComponent,
    QuotesDetailComponent,
    SuggestionFeedbackComponent,
    ProfileComponent,
    DepartmentPageDetailComponent,
    NumberFormatterPipe,
    CmProfileComponent,
    LoaderComponent,
    FormerCMListComponent,
    SitemapComponent,
    WebsitePoliciesComponent,
    JankalyanCategoryAndEntryTypeComponent,
    SpecialReleaseComponent,
    MapComponent,
    PhoneBoothComponent,
    PhoneBoothAdminComponent,
    PhoneBoothAdminLoginComponent,
    EmailFormatPipe,
  ],

  providers: [PagerService, TranslateService,
    { provide: LOCALE_ID, useValue: "en-US" },
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [
        TranslateService
      ],
      multi: true
    },
    BaseService, Data],
  bootstrap: [AppComponent]
})
export class AppModule { }
