<!-- <div [ngClass]="!isCodeOfConduct ? 'bg-scroll pos-rel breadcrumbs-page-img' : 'bg-scroll pos-rel'">
  <div class="container">
    <h1> {{'Home_RECENT_NEWS'| translate}}</h1>

  </div>
</div> -->
<div class="breadcrumbs-page-img">
  <div class="banner-hight-2">
    <h1>{{'Home_RECENT_NEWS'| translate}}</h1>
    <div class="inner-page-banner">
      <img src="assets/images/cmo-banner.png" alt="banner" class="cmo-banner">
    </div>
  </div>
</div>
<main id="main">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
      <li class="breadcrumb-item"><a href="javascript:void(0)">{{'Media_CornerSubNav'| translate}}</a></li>
      <li class="breadcrumb-item" *ngIf="!isSpecialrelease"><a routerLink="/pressrelease-list">{{'Home_RECENT_NEWS'| translate}}</a></li>
      <li class="breadcrumb-item" *ngIf="isSpecialrelease"><a routerLink="/special-release-list">{{'Success_Stories_txt'| translate}}</a></li>
      <li class="breadcrumb-item active" aria-current="page" *ngIf="!isSpecialrelease">{{'Press_Releases_Detail'| translate}}</li>
      <li class="breadcrumb-item active" aria-current="page" *ngIf="isSpecialrelease">{{'Success_Stories_Detail_txt'| translate}}</li>
    </ol>
  </nav>
  <section class="program_list_detail">
    <div class="container">
      <div class="row">
        <div class="col-xl-9 wow fadeInLeft">

          <!-- this section design from district -->
          <!-- <div id="SliderPress" class="carousel slide" data-bs-ride="carousel" *ngIf="generalModel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img *ngIf="generalModel.HomePageImageUrl" [src]="generalModel?.HomePageImageUrl" 
                onerror="this.src='assets/images/no-img.png';" title="{{generalModel?.Description}}" 
                alt="{{generalModel?.Description}}"/>
              </div>
            </div>
            <a class="carousel-control-prev carousel-control-icon" href="#SliderPress" role="button"
              data-bs-slide="prev">
              <span aria-hidden="true">
                <i class="fas fa-chevron-left"></i>
              </span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next carousel-control-icon" href="#SliderPress" role="button"
              data-bs-slide="next">
              <span aria-hidden="true">
                <i class="fas fa-chevron-right"></i>
              </span>
              <span class="sr-only">Next</span>
            </a>
          </div> -->


          <div class="program_detail_left" *ngIf="generalModel">
            <a *ngIf="generalModel?.HomePageImageUrl" href="javascript:void(0)"  data-bs-toggle="modal" data-bs-target="#HomePageImage">
              <img [src]="generalModel?.HomePageImageUrl" class="main-image w-100"
                  onerror="this.src='assets/images/no-img.png';" title="{{generalModel?.Description}}" 
                  alt="{{generalModel?.Description}}"/>
            </a>
            <!-- <div id="carouselExampleControls" class="carousel slide mb-2" data-bs-ride="carousel"
              *ngIf="galleryDetail.length>0">
              <div class="carousel-inner">
                <div class="carousel-item" [ngClass]="[i==0 ? 'active' : '' ]"
                  *ngFor="let item of galleryDetail let i = index">
                  <img class="d-block w-100" src="{{this.imageUrl}}{{item.ImageName}}" alt="item{{i}}">
                </div>
                <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-bs-slide="prev"> <span
                    class="carousel-control-prev-icon" aria-hidden="true"></span> <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-bs-slide="next"> <span
                    class="carousel-control-next-icon" aria-hidden="true"></span> <span class="sr-only">Next</span> </a>
              </div>
            </div> -->

            <h3> 
              <!-- {{this.translate.currlang == 'hi' ? generalModel.Description : generalModel.Description}} -->
              {{ 
                showFullText 
                  ? (translate.currlang == 'hi' ? generalModel?.Description : generalModel?.Description) 
                  : ((translate.currlang == 'hi' ? generalModel?.Description : generalModel?.Description) | slice:0:100) + '...'
              }}
              <a (click)="toggleText()" style="cursor: pointer; color: blue; font-size: small;font-weight: 600;">
                {{ showFullText ? 'Read Less' : 'Read More' }}
              </a>
            </h3>

            <div class="d-flex align-items-center justify-content-between mb-2">
              <div class="text-muted"> 
                <!-- <i class="ion-ios-location-outline"></i>
                {{this.translate.currlang == 'hi' ? programDetail.ProgramAddressHindi : programDetail.ProgramAddress}} ,
                &nbsp; &nbsp; -->
                <i class="fa fa-calendar"></i>
                {{this.translate.currlang == 'hi' ? generalModel.PressReleaseDateHindi : generalModel.PressreleaseDate | date : 'd MMM, y'}}</div>
                
                <div class="d-flex align-items-center justify-content-end mb-2">
                  <ng-container *ngFor="let childitem of generalModel?.Attachments">
                    <a (click)="openAttachmentInNewTabl(childitem)"
                      class="gray-btn gray-orange px-3 py-2 text-white"><i class="fa fa-file-pdf-o mr-1"></i>
                      Download</a>
                  </ng-container>
                  <a (click)="onSocial(generalModel.DepartmentTitle,'fb')" style="cursor: pointer;" class="px-3 py-2" id="facebook_social_id" target="_blank" >
                    <img src="https://www.pmindia.gov.in/wp-content/themes/pmindia2015/images/share-facebook.png" alt="facebook" title="facebook">
                </a>

                <!-- this.facebookService.share('https://cmo.rajasthan.gov.in'+router.url,generalModel.DepartmentTitle,generalModel.HomePageImageUrl); -->

                <a (click)="onSocial(generalModel.Description,'twiiter')" href="javascript:void(0);">
                  <img src="https://www.pmindia.gov.in/wp-content/themes/pmindia2015/images/share-twitter.png" alt="twitter" title="twitter">
                </a>
                </div>
            </div>


                <p [innerHTML]="generalModel?.GeneralDescription"></p>
            <!-- <div class="row">
              <div class="col-xl-12">
                <div class="detail_icon">
                  <a *ngIf="programDetail.TotalVideo > 0" href="javascript:void(0)" title="video"><i
                      class="fas fa-video"></i></a>

                  <a *ngIf="isEnglish && programDetail.PdfEnglish != null && programDetail.PdfEnglish != ''"
                    href="{{pdfRootPath}}{{programDetail.PdfEnglish}}" rel="noopener noreferrer" target="_blank"
                    title="PDF"> <i class="fa fa-file-pdf-o" aria-hidden="true"></i> Read more <span
                      class="fa fa-angle-right" aria-hidden="true"></span> </a>

                  <a *ngIf="!isEnglish && programDetail.Pdf != null && programDetail.Pdf != ''"
                    href="{{pdfRootPath}}{{programDetail.Pdf}}" rel="noopener noreferrer" target="_blank" title="PDF">
                    <i class="fa fa-file-pdf-o" aria-hidden="true"></i> Read more <span class="fa fa-angle-right"
                      aria-hidden="true"></span> </a>

                </div>
              </div>

            </div> -->




            <!--<div class="row">
              <div class="col-xl-6 pt-3">
                <p class="m-0"><strong>Place :</strong>  {{this.translate.currlang == 'hi' ? programDetail.ProgramAddressHindi : programDetail.ProgramAddress}} </p>
              </div>
              <div class="col-xl-6 text-right  pt-3"><small class="text-muted"> <strong>Posted on :</strong>{{this.translate.currlang == 'hi' ? programDetail.ProgramDateHindi : programDetail.ProgramDate}}</small></div>
            </div>
            <h3> {{this.translate.currlang == 'hi' ? programDetail.ProgramSubCategoryhindi : programDetail.ProgramSubCategory}}</h3>
            <p>{{this.translate.currlang == 'hi' ? programDetail.ProgramSubjectHindi : programDetail.ProgramSubject}}</p>

            <a *ngIf="isEnglish && programDetail.PdfEnglish != null && programDetail.PdfEnglish != ''" href="{{pdfRootPath}}{{programDetail.PdfEnglish}}" rel="noopener noreferrer" target="_blank" title="PDF"><i class="fas fa-newspaper"></i> </a>
            <a *ngIf="!isEnglish && programDetail.Pdf != null && programDetail.Pdf != ''" href="{{pdfRootPath}}{{programDetail.Pdf}}" rel="noopener noreferrer" target="_blank" title="PDF"><i class="fas fa-newspaper"></i></a>-->

            <!-- <object *ngIf="isEnglish && programDetail.PdfEnglish||''!='' " width="100%" height="650px">
             
              <iframe title="PDF" [src]='pdfPath_ENG' type="application/pdf" frameborder="0" width="100%"
                height="650px"></iframe>
            </object>

            <object *ngIf="!isEnglish &&programDetail.Pdf||''!='' " width="100%" height="650px">
            
              <iframe title="PDF" [src]='pdfPath_HIN' type="application/pdf" frameborder="0" width="100%"
                height="650px"></iframe>
            </object> -->

          </div>
        </div>

        <div class="col-xl-3  wow fadeInRight">

          <div class="program_detail_right_box mb-4" *ngIf="generalModel?.ImageAttachments.length > 0">
            <div class="section-header">
              <h2>{{'Photo_Gallery'| translate}}</h2>
            </div>
            <h6 *ngIf="generalModel?.ImageAttachments.length == 0">No Photos Available</h6>
            <div class="row photos">
              <div class="col-sm-6 col-md-4 col-lg-4 item" *ngFor="let item of generalModel?.ImageAttachments let i = index">
                <a data-bs-toggle="modal" data-bs-target="#galleryBlock" (click)="openCarousel(i)" href="javascript:void(0)"><img class="img-fluid"
                    src="{{item}}"
                    onError="this.src='../../assets/images/program-no-img.png'"></a>
              </div>

            </div>
          </div>
          
          <div class="program_detail_right_box mb-4" *ngIf="generalModel?.URL">
            <div class="">
              <h2 style="font-weight: 550;">{{'Video_Gallery'| translate}}</h2>
            </div>
            <div class="rounded ">
              <p *ngIf="IsLoading" class="text-left"><b>Loading...</b></p>

              <!-- <div class="col-sm-6 col-md-4 col-lg-4 item" *ngFor="let item of videos let i = index"> -->
                <a [href]="generalModel.URL" target="_blank">
                  <div width="640" height="360" class="embed-responsive embed-responsive-21by9">
                      <video #videoPlayer tabindex="-1" (playing)="onMediaPlaying($event, false)"
                          id="my_video" (mouseover)="playVideo($event)" (mouseout)="pauseVideo($event)"
                          class="embed-responsive-item" width="100%" height="100%" controls preload="none"
                          data-bs-setup='{ "aspectRatio":"100%:100%", "playbackRates": [1, 1.5, 2] }'
                          disablePictureInPicture
                          controlslist="nodownload noremoteplayback noplaybackrate foobar">
                          <source src="{{generalModel.URL}}"
                              type="video/{{generalModel.URL.split('.')[generalModel.URL.split('.').length-1]}}">
                      </video>
                  </div>
              </a>
              <!-- </div> -->
            </div>
          </div>

          <div class="program_detail_right">
            <div class="program_detail_right_box mb-4">
              <div class="section-header">
                <h2 *ngIf="!isSpecialrelease">{{'Latest_PressReleases'| translate}}</h2>
                <h2 *ngIf="isSpecialrelease">{{'Latest_SuccessStories'| translate}}</h2>
              </div>
              <p *ngIf="IsLoading" class="text-left"><b>Loading...</b></p>
                <div class="media d-flex" *ngFor="let program of PressReleaseListModel let i = index">
                  <!-- <div class="align-self-center"> <img class="mr-2" src="{{this.imageUrl}}{{program.ProgramImage}}"
                      onError="this.src='../../assets/img/Press_Release.png'" alt=""> </div> -->
                      <div class="align-self-center"> 
                        <img *ngIf="!isSpecialrelease" class="mr-2" src="{{program?.HomePageImageUrl}}"
                        onError="this.src='../../assets/img/Press_Release.png'" alt=""> 
                        <img *ngIf="isSpecialrelease" class="mr-2" src="{{program?.HomePageImageUrl}}"
                        onError="this.src='assets/images/story-icon.png'" alt=""> 
                      </div>
                  <div class="media-body align-self-center ps-2">
                    <!--<h5 class="mt-0"><a href="javascript:void(0)">Card title</a></h5>-->
                    <a routerLink="/pressreleasedetail/{{program.Id}}">
                      <div class="middle-content-date">    
                        {{ this.translate.currlang=='en' ? (program.PressreleaseDate | date : 'd MMM, y') :
                        program.PressReleaseDateHindi }}
                      </div>
                      <p
                        *ngIf="(program.Description.length<=50 )">
                        {{program.Description}}
                      </p>
                      <p
                        *ngIf="(program.Description.length>50 )">
                        {{(program.Description)|slice:0:50}}...
  
                      </p>
                    </a>
                  </div>
                </div>
             

            </div>
            <!--<div class="program_detail_right_box mb-4">
              <div class="section-header">
                <h2>Photo Gallery</h2>
                <div class="row photos">
                  <div class="col-sm-6 col-md-4 col-lg-4 item" *ngFor="let item of galleryDetail let i = index">
                    <a href="{{this.imageUrl}}{{item.ImageName}}" data-bs-lightbox="photos"><img class="img-fluid" src="{{this.imageUrl}}{{item.ImageName}}"></a>
                  </div>

                </div>

              </div>
            </div>-->
            <!--   <div class="program_detail_right_box mb-4">
                 <section class=" box-content">
                   <div class=" press_box">
                     <div class="section-header">
                       <h2>
                         PRESS RELEASES
                       </h2>

                       <div class="press_releases">
                         <ul class="wow fadeInRight">
                           <li class="wow fadeInLeft" *ngFor="let program of programPressRelease">
                             <div class="press_releases_bx d-flex">
                               <div class="date align-self-center">
                                 {{program.ProgramDate | date : 'dd'}} <br>
                                 <div style="font-size:13px;">{{program.ProgramDate| date : 'MMMM'}}</div>
                               </div>
                               <div class="detail align-self-center">
                                 <a href="javascript:void(0)">

                                   <p *ngIf="(this.translate.currlang == 'hi' && program.ProgramSubjectHindi.length<=30 )||(this.translate.currlang != 'hi' && program.ProgramSubject.length<=30)">

                                     {{this.translate.currlang == 'hi' ? program.ProgramSubjectHindi : program.ProgramSubject}}

                                   </p>
                                   <p *ngIf="(this.translate.currlang == 'hi' && program.ProgramSubjectHindi.length>30 )||(this.translate.currlang != 'hi' && program.ProgramSubject.length>30)">

                                     {{(this.translate.currlang == 'hi' ? program.ProgramSubjectHindi : program.ProgramSubject)|slice:0:30}}...

                                   </p>

                                   <!#--{{this.translate.currlang == 'hi' ? program.ProgramSubjectHindi : program.ProgramSubject}}--#>
                                 </a>
                                 <span><i class="ion-ios-location-outline"></i>{{this.translate.currlang == 'hi' ? program.ProgramAddress : program.ProgramAddress}} </span>
                                 <span>
                                   <i class="fa fa-calendar" aria-hidden="true"></i> &nbsp;{{program.ProgramDate}}
                                 </span>
                               </div>
                             </div>
                           </li>

                         </ul>
                        <!#-- <div class="col-lg-12 text-right pt-2 wow fadeInUp" *ngIf="programPressRelease.length>0"> <a href="javascript:void(0)" class="more_btn">View more <i class="fa fa-angle-right" aria-hidden="true"></i></a> </div>

                         <ul class="wow fadeInRight">


                           <li>
                             <div class="press_releases_bx d-flex">
                               <div class="date align-self-center">
                                 09 <br>
                                 <div style="font-size:13px;">September</div>
                               </div>
                               <div class="detail align-self-center"> <a href="javascript:void(0)"> 5 crore cheque donated to Chief Minister's Relief Fund. </a> <span><i class="ion-ios-location-outline"></i> Jaipur</span> <span> <i class="fa fa-calendar" aria-hidden="true"></i> &nbsp;09/09/2019</span> </div>
                             </div>
                           </li>
                           <li>
                             <div class="press_releases_bx d-flex">
                               <div class="date align-self-center">
                                 09 <br>
                                 <div style="font-size:13px;">September</div>
                               </div>
                               <div class="detail align-self-center"> <a href="javascript:void(0)"> 5 crore cheque donated to Chief Minister's Relief Fund. </a> <span><i class="ion-ios-location-outline"></i> Jaipur</span> <span> <i class="fa fa-calendar" aria-hidden="true"></i> &nbsp;09/09/2019</span> </div>
                             </div>
                           </li>
                           <li>
                             <div class="press_releases_bx d-flex">
                               <div class="date align-self-center">
                                 09 <br>
                                 <div style="font-size:13px;">September</div>
                               </div>
                               <div class="detail align-self-center"> <a href="javascript:void(0)"> 5 crore cheque donated to Chief Minister's Relief Fund. </a> <span><i class="ion-ios-location-outline"></i> Jaipur</span> <span> <i class="fa fa-calendar" aria-hidden="true"></i> &nbsp;09/09/2019</span> </div>
                             </div>
                           </li>
                         </ul>--#>
                       </div>

                     </div>
                   </div>
                 </section>
               </div> -->
          </div>
        </div>
      </div>

    </div>

  </section>
</main>




<!--<section class="inner-page-banner">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="page-title">
          {{'Home_RECENT_NEWS'| translate}}
        </div>
      </div>
    </div>
  </div>

</section>
<section class="program-detail inner-page">
  <div class="container">
    <div class="row">
      <div class="col-md-12" align="right">

        <a routerLink="/pressrelease-list" class="btn btn-primary">Back</a>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="pdf-bx">

          <div class="row  mg-btm-25">
            <div class=" col-xl-5">
              <img src="../../assets/images/program-no-img.png" class="img-fluid" alt="">

            </div>

            <div class="col-xl-7" *ngIf="programDetail">

              <h3 class="">
                {{this.translate.currlang == 'hi' ? programDetail.programSubCategoryhindi : programDetail.programSubCategory}}

              </h3>
              <div class="city-name"> {{this.translate.currlang == 'hi' ? programDetail.programSubjectHindi : programDetail.programSubject}}</div>
              <span class="date">

              </span>
              <div class="detail-ico">
                <a *ngIf="isEnglish && programDetail.pdfEnglish != null && programDetail.pdfEnglish != ''" href="{{pdfRootPath}}{{programDetail.pdfEnglish}}" rel="noopener noreferrer" target="_blank" title="PDF"><i class="fas fa-newspaper"></i></a>
                <a *ngIf="!isEnglish && programDetail.pdf != null && programDetail.pdf != ''" href="{{pdfRootPath}}{{programDetail.pdf}}" rel="noopener noreferrer" target="_blank" title="PDF"><i class="fas fa-newspaper"></i></a>

              </div>

              <p>

              </p>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>-->

<div class="modal fade galleryBlock" id="galleryBlock" tabindex="-1" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"></h5>
        <button type="button" class="close close-in" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" >
        <!-- <p *ngIf="isLoading" class="text-left"><b>Loading...</b></p> -->
        <div id="carouselExampleControls" class="carousel slide mb-2" data-bs-ride="carousel"
              *ngIf="carouselData.length>0">
              <div class="carousel-inner" *ngIf="isVisible">
                <div class="carousel-item" *ngFor="let item of carouselData; let i = index" [ngClass]="item.active == true ? 'active' : '' ">
                  <img class="d-block w-100" src="{{item.image}}"
                  onError="this.src='../../assets/images/program-no-img.png'">
                </div>

              </div>
              <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-bs-slide="prev"> <span
                  class="carousel-control-prev-icon" aria-hidden="true"></span> <span class="sr-only">Previous</span>
              </a> <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span> <span class="sr-only">Next</span>
              </a>
              <div class="carousel-inner" *ngIf="carouselData.length==0">

                <img class="d-block w-100" src="../../assets/images/default_img1.png" />


              </div>

            </div>
            <div class="carousel-inner" *ngIf="carouselData.length==0">

              <img class="d-block w-100" src={{galleryDetails?.HomePageImageUrl}} />


            </div>
        <!-- <img class="d-block w-100" src="{{image}}" alt=""
          onError="this.src='../../assets/images/program-no-img.png'"> -->
      </div>

    </div>
  </div>
</div>

<div class="modal fade galleryBlock" id="HomePageImage" tabindex="-1" aria-labelledby="exampleModalLabel"
aria-hidden="true">
<div class="modal-dialog modal-lg">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabel"></h5>
      <button type="button" class="close close-in" data-bs-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" >
      <img class="d-block w-100" src="{{generalModel?.HomePageImageUrl}}" alt=""
        onError="this.src='../../assets/images/program-no-img.png'">
    </div>

  </div>
</div>
</div>
