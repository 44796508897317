<div class="bg-scroll pos-rel breadcrumbs-page-img">
  <div class="container">
   
    <h1>{{'Right_To_InformationSubNav'| translate}}</h1>
   
  </div>
</div>
<main id="main">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
      <li class="breadcrumb-item"><a href="javascript:void(0)"> {{'Citizen_CornerNav'| translate}}</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{'Right_To_InformationSubNav'| translate}}</li>
    </ol>
  </nav>
  <section class="program_list cmrelief rti">
    <div class="container">
      <div class="row">
        <div class="col-xl-4 col-sm-6">
          <div class="card profile-card-2 wow fadeInUp">
            <div class="card-body pt-4 text-center">
              <img src="assets/img/check_black.png" alt="cmrelief" class="profile" /> <strong class="mb-3 d-block mt-2">Nodal Officer for Right To Information in CMO </strong>
              <p class="card-text">
                Officer on Special Duty,<br />
                CMO, Secretariat, Jaipur-302005, Rajasthan, India<br />
                Phone: +91-141-2229244<br />
                Email: osdgsy.cmo@rajasthan.gov.in
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-sm-6">
          <div class="card profile-card-2 wow fadeInUp">
            <div class="card-body pt-4 text-center">
              <img src="assets/img/check_black.png" alt="cmrelief" class="profile" /> <strong class="mb-3 d-block mt-2">Public Information Officer for CMO </strong>
              <p class="card-text">
                Sr. Deputy Secretary (Cabinet),<br />
                Cabinet Secretariat Department,<br />
                Secretariat, Jaipur-302005,<br />
                Phone: +91-141-2227472<br />
                Mobile: +91-9414067185
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-sm-6">
          <div class="card profile-card-2 wow fadeInUp">
            <div class="card-body pt-4 text-center">
              <img src="assets/img/check_black.png" alt="cmrelief" class="profile" /> <strong class="mb-3 d-block mt-2">First Appellate Authority for CMO </strong>
              <p class="card-text">
                Principal Secretary,
                Cabinet Secretariat,<br />
                Secretariat, Jaipur-302005, Rajasthan, India<br />
                Phone: +91-141-2227103<br />
                Phone: +91-141-2227422<br />
                Mobile: +91-9413344153
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-12 text-center"><a href="http://rti.rajasthan.gov.in/" rel="noopener noreferrer" target="_blank" class="btn-theme bg-blue mt-3">KNOW MORE &nbsp;<i class="fa fa-angle-right" aria-hidden="true"></i></a></div>
      </div>
    </div>
  </section>
</main>
