export enum LookupEnum {
    Assets_DataType_List = 30825,
    AssetsLocal_DataType_List = 30745,
    //Assets_DataType_Icon = 30746, //for local assert master icon
    Assets_DataType_Icon = 30826, //live assert master icon
    AssetsLocal_DataType_Icon = 30746,
    Assets_DataType_Assets = 30827,
    AssetsLocal_DataType_Assets = 30747,
    Assets_EBookletFlipBookFrom = 31303,
    AssetsLocal_EBookletFlipBookFrom = 60284,
    OtherImportantLinkType_OnlyDefaultLinks = 30846,
    OtherImportantLinkType_OnlyLinksFromGeneralEntry = 30847,
    OtherImportantLinkType_Both = 30848,
    URLOfSameWebsiteOtherWebsite = 30256,
    PDFFileUpload = 30257,
    DataEnteredInScreens = 30258,
    HasSubMenu = 30272,

    CategorySubMenu = 30253,
    DisplayInTabOnHomePage = 31327,
    TransactionSubMenu = 30254,

    OrderPreviousUrlSource_DepartmentPage = 31669,
    OrderPreviousUrlSource_ExternalWebsite = 31668,
    PressReleaseLevelCode_For_State = 30204,
}