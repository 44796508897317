import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '../translate.service';

@Component({
  selector: 'app-relief-fund',
  templateUrl: './cmrelieffund.component.html'
})
export class CmReliefFundComponent implements OnInit {
  constructor(@Inject('BASE_URL') public baseUrl: string,
    private translate: TranslateService) {
  }

  ngOnInit() {

  }
  setLang(lang: string) {
    this.translate.use(lang);
  }
}
