import { BehaviorSubject, Subject } from "rxjs";
import { Injectable } from "@angular/core";;
import { DatePipe, Location } from "@angular/common";
import { DomSanitizer, Title } from "@angular/platform-browser";
import { Dictionary } from "../common/dictionary";
import { BaseService } from "./baseservice";
import { AppSettings } from "../common/AppSettings";
import Swal, { SweetAlertIcon } from "sweetalert2";

@Injectable({
  providedIn: "root",
})

export class CommonService {

  updateMenu = new Subject<void>();

  constructor(
    public readonly _titleService: Title,
    private _location: Location,
    private httpService: BaseService,
    private sanitizer: DomSanitizer
  ) { }

  numberOnly(event, isCommaOrDash: boolean = false): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (isCommaOrDash) {
      if (charCode == 44 || charCode == 45) {
        return true;
      }
    }
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  gotoPreviousPage() {
    this._location.back();
  }

  IsDocExtenstionURL(url) {
    var docDgx = new RegExp(/\.(?:pdf|PDF|doc|docx|xlsx|xls)$/i);
    if (docDgx.test(url)) {
      return true
    }
    else {
      return false;
    }
  }

  GetAllDDL(model: any, usertype = "", userId = 0): any {
    return this.httpService.get(AppSettings.API_ENDPOINT_Jankalyan + AppSettings.GetDDlUrl + model + "&userType=" + usertype + "&userid=" + userId);
  }

  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  GetIsCodeOfConduct() {
    return false;
    // return JSON.parse(localStorage.getItem("IsCodeOfConduct")) || true;
    // return false; JSON.parse(localStorage.getItem("IsCodeOfConduct")) || true;
  }

  alertMsg(titleTxt: string, msgTxt: string, type: SweetAlertIcon = "info") {

    return new Promise<void>((resolve) => {
      Swal.fire({
        icon: type,
        title: titleTxt,
        text: msgTxt,
        timer: 5000

      }).then(() => resolve());
    });

  }

  alertMsgForLong(titleTxt: string, msgTxt: string, type: SweetAlertIcon = "info") {

    return new Promise<void>((resolve) => {
      Swal.fire({
        icon: type,
        title: titleTxt,
        text: msgTxt,
        timer: 60000

      }).then(() => resolve());
    });

  }

  ConvertMbintoByte(data) {
    return data * 1024 * 1024;
  }

  GetMaxFileSizeInByte(size_50x: number = 1) {
    return this.ConvertMbintoByte(this.GetMaxFileSizeInMB() * size_50x);
  }

  GetMaxFileSizeInMB(size_50x: number = 1) {
    let fileSizeInMB = localStorage.getItem("FileValidation");
    if (fileSizeInMB) {
      return Number(fileSizeInMB) * size_50x;
    }
    else {
      return 5;   // set 5 MB 
    }
  }
  
  getFileExtension(filename: string) {
    let ext = '';
    if (filename.indexOf('.') > -1) {
      ext = filename.split('.').pop();
    }
    return ext;
  }

  getYoutubeEmbedUrl(youtubeUrl: string): string {
    if (youtubeUrl) {
      let url = youtubeUrl.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
      let videoId = (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
      if (videoId) {
        return 'https://www.youtube.com/embed/' + videoId;
      }
    }
    return youtubeUrl;
  }

  SafeUrl(url: string) {
    if (url != undefined && url.length > 0) {
      var regexp = new RegExp(/\/view(v|V?)/i);
      if (!regexp.test(url)) {
        url = url.replace(/\/watch\?(v|V\?)=/g, '/embed/');
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
      }
      else {
        url = url.replace(/\/view(v|V?)/i, '/preview');
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
      }
    }
    else {
      return null;
    }
  }

}

