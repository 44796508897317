import { Injectable } from '@angular/core';
import { BaseService } from './baseservice';
import { UserRegistrationPostModel } from '../Model/user-registeration.model';
import { Dictionary } from '../common/dictionary';
import { AppSettings } from '../common/AppSettings';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private _baseService: BaseService) { }

  //#endregion <<registration >>

  RegisterUser(model: UserRegistrationPostModel) {
    return this._baseService.post(AppSettings.API_ENDPOINT_RW_Jankalyan + AppSettings.jankalyanApi.RegistrationUser.RegisterUserUrl, model);
  }

  RegisterUserV1(model: UserRegistrationPostModel) {
    return this._baseService.post(AppSettings.API_ENDPOINT_RW_Jankalyan + AppSettings.jankalyanApi.RegistrationUser.RegisterUserV1Url, model);
  }

  VerifyOtp(model: UserRegistrationPostModel) {
    return this._baseService.post(AppSettings.API_ENDPOINT_RW_Jankalyan + AppSettings.jankalyanApi.RegistrationUser.VerifyUserOtpUrl, model);
  }

  ResentOTP(mobile: string,appUserId:number) {
    const param = new Dictionary<any>();
    param.Add("mobile", mobile);
    param.Add("appUserId", appUserId);
    return this._baseService.get(AppSettings.API_ENDPOINT_RW_Jankalyan + AppSettings.jankalyanApi.RegistrationUser.ResentUserOTPUrl, param);
  }

  //#endregion
}
