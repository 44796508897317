import { Component, OnInit } from '@angular/core';
import { AppService } from '../services/app.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

  constructor(private appService: AppService) {
    if (window.location.hash === '#/404') {
      let currentUrl = window.location.href
      let newUrl = currentUrl.replace("#/404", "404");
      window.location.href = newUrl;
      return;
    }

    window.location.reload();
  }

  ngOnInit(): void {
    this.appService.showHeader$.next(false);
    this.appService.showFooter$.next(false);
    // this.redirect();
  }
}
