import { BaseService } from './../services/baseservice';
import { Dictionary } from './../common/dictionary';
import { Injectable } from '@angular/core';
import { AppSettings } from './../common/AppSettings';
import { AwardsRequestModel, ActiveAwardsRequestModel, ActiveLatestAwardsRequestModel, AchievementAllSearchModel } from '../Model/awards-request-model.model';
import { DepartmentalAchievementFilterModel } from '../Model/achievement.model';
import { FrontUploaderDataRequestModel } from '../Model/photo-video-uploader.model';
import { HttpClient } from '@angular/common/http';
// import { AchievementDepartmentCategoryFilterModel } from '../Model/achievement.model';

@Injectable()
export class AchievementService {
  public constructor(private httpService: BaseService, private http:HttpClient) {

  }

  GetActiveAchievements() {
    return this.httpService.get(AppSettings.API_ENDPOINT + AppSettings.API_ACHIEVEMENT_LIST_GET);
  }
  GetAchievementListForFront(page: number, pageSize: number = 9, excludeId: number = 0) {
    const params = new Dictionary<string>();
    params.Add('page', page.toString());
    params.Add('excludeId', excludeId.toString());
    params.Add('pageSize', pageSize.toString());

    return this.httpService.get(AppSettings.API_ENDPOINT + AppSettings.API_ACHIEVEMENT_LIST_FRONT_GET, params);
  }
  GetActiveAchievementDetailById(id: string) {
    const params = new Dictionary<string>();
    params.Add('id', id);
    return this.httpService.get(AppSettings.API_ENDPOINT + AppSettings.API_ACHIEVEMENT_DETAILS_GET, params);
  }


  GetActiveAwardsLatest(model: AwardsRequestModel) {
    
    const headers = { 'Cache-Control': 'no-cache', 'Pragma': 'no-cache' }
    return this.httpService.post(AppSettings.API_ENDPOINT + AppSettings.API_AWARDS_LIST_POST, model);

  }
  
  GetActiveAwardsDetailByIdLatest(modelRequest:ActiveAwardsRequestModel) {
    return this.httpService.post(AppSettings.API_ENDPOINT + AppSettings.API_AWARDS_ACTIVE_DETAILS_POST, modelRequest);
  }
   
  GetActiveLatestAwardsLatest(modelRequest: ActiveLatestAwardsRequestModel) {
    
    return this.httpService.post(AppSettings.API_ENDPOINT + AppSettings.API_LATESTAWARDS_LIST_POST, modelRequest);
  }


  // GetAchievementListBySearchFilterData(model: AchievementAllSearchModel, isRequiredAttachImages: boolean = true) {

  //   return this.httpService.post(AppSettings.API_ENDPOINT_Jankalyan +  AppSettings.jankalyanApi.award.getAchievementListBySearchFilter, model);
  // }

  GetAchievementListBySearchFilterData(model: AchievementAllSearchModel, isRequiredAttachImages: boolean = true): any {
    return this.httpService.post(`${AppSettings.API_ENDPOINT_Jankalyan + AppSettings.jankalyanApi.award.getAchievementListBySearchFilter}?isRequiredAttachImages=${isRequiredAttachImages}`, model);
  }

  GetAchievementListFromJankalyanApi(dptCode = 0): any {

    return this.httpService.get(AppSettings.API_ENDPOINT_Jankalyan +  AppSettings.jankalyanApi.Achievement.AchievementDepartmentCoutListUrl + dptCode);
  }
  GetAchievementCountWoseListFromJankalyanApi(model: DepartmentalAchievementFilterModel): any {

    return this.httpService.post(AppSettings.API_ENDPOINT_Jankalyan +  AppSettings.jankalyanApi.Achievement.AchievementDepartmentCoutWiseListUrl, model);
  }

  GetDetailById(id: number) {
    const param = new Dictionary<any>();
    param.Add("id", id);
    return this.httpService.get(AppSettings.API_ENDPOINT_Jankalyan + AppSettings.jankalyanApi.award.GetAchievementDetailurl, param);
  }

  GetNotableQuotes(){
    return this.http.get(`${AppSettings.API_ENDPOINT_RajAdvtAPi}api/JankalyanHome/GetRajAdvtSideBarData?deptCode=0&pageSize=-1`);
  }

  GetCMRelef(){
    return this.http.get(`${AppSettings.API_ENDPOINT_CMRelief}API/Api_CMRF.aspx?Action=AllCMRF`);
  }

  GetUploaderDataForFront(model: FrontUploaderDataRequestModel): any {
    return this.httpService.post(AppSettings.API_ENDPOINT_Jankalyan + AppSettings.jankalyanApi.PhotoVideoUploader.GetUploaderDataForFront, model);
  }

  GetVisitorCount(deptCode:any){
    return this.http.get(`${AppSettings.API_ENDPOINT_RW_Jankalyan}${AppSettings.jankalyanApi.VisitorCount.VisitorCountUrl}${deptCode}`);
  }

  GetCMISAuthenticationKey(data:any){
    return this.http.post(`${AppSettings.API_ENDPOINT_CMIS}ARM/api/v1/ARMGetEncryptedSecret`,data);
  }

  GetCMISData(data:any){
    return this.http.post(`${AppSettings.API_ENDPOINT_CMIS}ARM/api/v1/ARMExecuteAPI`,data);
  }
}
