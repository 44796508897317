<div class="breadcrumbs-page-img">
    <div class="banner-hight-2">
        <h1>{{'Home_OUR_SPEECH'| translate}}</h1>
        <div class="inner-page-banner">
            <img src="assets/images/cmo-banner.png" alt="banner" class="cmo-banner">
        </div>
    </div>
</div>
<main id="main">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
            <!-- <li class="breadcrumb-item"><a href="javascript:void(0)">{{'Media_CornerSubNav'| translate}}</a></li> -->
            <li class="breadcrumb-item active" aria-current="page">{{'CM_Profile_txt' | translate}}</li>
        </ol>
    </nav>
    <section class="profil-image">
        <img src="assets/images/Group 34.png" alt="">
        <div class="profil-text-overlay">
            <h1>{{'Profile_CM_Name_txt' | translate}}</h1>
            <p>{{"Profile_Hon'ble_Chief_Minister" | translate}}</p>
        </div>
    </section>
    <section class="profil-text">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="life-text">
                        <h2>{{'CM_Profile_txt' | translate}}</h2>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="life-text2">
                        <p [innerHTML]="(this.translate.currlang == 'hi' ? CMProfileModelViewModel?.DetailsInHindi :
                                CMProfileModelViewModel?.Details)"></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>