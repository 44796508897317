import { Component, OnInit, ViewChild, AfterContentChecked } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DepartmentCodeEnum } from '../Enum/app-enums';
import { EntryTypeEnum, JankalyanCategoryEnum, SingleEntryDataType } from '../Enum/jankayan.enum';
import { DepartmentProfileFilterModel, DepartmentProfileViewModel, PageTitleModel } from '../Model/department-model.model';
import { CommonService } from '../services/common.service';
import { DepartmentService } from '../services/department.service';
//import { } from 'googlemaps';
import { TranslateService } from '../translate.service';
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit, AfterContentChecked {
  //@ViewChild("googleMap", null) gmapElement: any;
  //@ViewChild('googleMap') gmapElement: any;
  //map: google.maps.Map;
  currentLat: any;
  currentLong: any;
  isTracking = false;
  isEnglish: boolean = false;
  //marker: google.maps.Marker;

  pageTitle: PageTitleModel = new PageTitleModel();
  departmentProfileModel: DepartmentProfileViewModel[];
  dataModel = new DepartmentProfileViewModel();
  departmentProfileFilterModel = new DepartmentProfileFilterModel();
  entryDataType = SingleEntryDataType;
  IsLoading = true;
  departmentProfileTotalRecords = 0;
  DepartmentCodeEnum: DepartmentCodeEnum;
  // isCodeOfConduct: boolean = true;
  isCodeOfConduct: boolean = false;
  departmentProfilePagerConfig: any = {
    id: "departmentProfile-paging",
    itemsPerPage: this.departmentProfileFilterModel.IndexModel.PageSize,
    currentPage: this.departmentProfileFilterModel.IndexModel.Page,
    totalItems: this.departmentProfileTotalRecords
  };
  constructor(
    public translate: TranslateService,
    public sanitizer: DomSanitizer,
    public readonly commonService: CommonService,
    private readonly departmentProfile: DepartmentService,
    public _commonService: CommonService) {
      this.isCodeOfConduct = this._commonService.GetIsCodeOfConduct();
     }

  ngOnInit() {
    this.getDepartmentProfileListData();
   // const mapProp = {
   //   center: new google.maps.LatLng(24.521550, 81.305420),
   //   zoom: 14,
   //   mapTypeId: google.maps.MapTypeId.ROADMAP
   // };
   // const location = new google.maps.LatLng(24.521550, 81.305420);

   //// this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
   // const marker = new google.maps.Marker({ position: location });
   // marker.setMap(this.map);
   // const infowindow = new google.maps.InfoWindow({
   //   content: 'Hey, We are here'
   // });
   // infowindow.open(this.map, marker);
  }

  setLang(lang: string) {
    this.translate.use(lang);
  }
  ngAfterContentChecked() {
    this.isEnglish = this.translate.currlang === 'en' ? true : false;
  }


  //Jankalyan API Start
  getDepartmentProfileListData() {
    this.IsLoading = true;
    this.dataModel = undefined;
    this.departmentProfileFilterModel.IndexModel.PageSize = 1;
    this.departmentProfileFilterModel.DepartmentCode = DepartmentCodeEnum.CmoWebsiteCode;
    this.departmentProfileFilterModel.JankalyanCategoryCode = JankalyanCategoryEnum.AboutDepartmentDistrictCode;
    this.departmentProfileFilterModel.EntryTypeCode = EntryTypeEnum.contactusCode

    this.departmentProfile.GetList(this.departmentProfileFilterModel).subscribe((data:any) => {
      
      if (data.IsSuccess) {
        this.departmentProfileModel = data.Data.Data as DepartmentProfileViewModel[];
        if (this.departmentProfileModel && this.departmentProfileModel.length == 1) {
          this.dataModel = this.departmentProfileModel[0];
          this.pageTitle.NameEnglish = this.pageTitle.NameEnglish ? this.pageTitle.NameEnglish : this.dataModel.EntryTypeName;
          this.pageTitle.NameHindi = this.pageTitle.NameHindi ? this.pageTitle.NameHindi : this.dataModel.EntryTypeNameHindi;
          if (this.dataModel.PDFURL) {
            this.dataModel.PDFURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.dataModel.PDFURL);
          }
          if (this.dataModel.DataTypeCode == this.entryDataType.URL && !this.dataModel.Details) {
            window.open(this.dataModel.url, "_blank");
            this.commonService.gotoPreviousPage();
          }
        }
        else {
          this.dataModel = new DepartmentProfileViewModel();
        }
        if (!this.departmentProfileFilterModel.IndexModel.IsPostBack) {
          this.departmentProfileTotalRecords = data.Data.TotalRecords || 0;
        }
        this.departmentProfileUpdatePageConfig();
      }
      else {
        this.dataModel = {} as DepartmentProfileViewModel;
      }
      this.IsLoading = false;
    });
  }

  departmentProfileUpdatePageConfig() {
    this.departmentProfilePagerConfig.itemsPerPage = this.departmentProfileFilterModel.IndexModel.PageSize;
    this.departmentProfilePagerConfig.currentPage = this.departmentProfileFilterModel.IndexModel.Page;
    this.departmentProfilePagerConfig.totalItems = this.departmentProfileTotalRecords;
  }
//Jankalyan API End



  //findMe() {
  //  if (navigator.geolocation) {
  //    navigator.geolocation.getCurrentPosition((position) => {
  //      this.showPosition(position);
  //    });
  //  } else {
  //    alert('Geolocation is not supported by this browser.');
  //  }
  //}

  //showPosition(position) {
  //  this.currentLat = position.coords.latitude;
  //  this.currentLong = position.coords.longitude;

  //  const location = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
  //  this.map.panTo(location);

  //  if (!this.marker) {
  //    this.marker = new google.maps.Marker({
  //      position: location,
  //      map: this.map,
  //      title: 'Got you!'
  //    });
  //  } else {
  //    this.marker.setPosition(location);
  //  }
  //}

  //trackMe() {
  //  if (navigator.geolocation) {
  //    this.isTracking = true;
  //    navigator.geolocation.watchPosition((position) => {
  //      this.showTrackingPosition(position);
  //    });
  //  } else {
  //    alert('Geolocation is not supported by this browser.');
  //  }
  //}

  //showTrackingPosition(position) {
  //  console.log(`tracking postion:  ${position.coords.latitude} - ${position.coords.longitude}`);
  //  this.currentLat = position.coords.latitude;
  //  this.currentLong = position.coords.longitude;

  //  const location = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
  //  this.map.panTo(location);

  //  if (!this.marker) {
  //    this.marker = new google.maps.Marker({
  //      position: location,
  //      map: this.map,
  //      title: 'Got you!'
  //    });
  //  } else {
  //    this.marker.setPosition(location);
  //  }
  //}
}
