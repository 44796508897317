import { AchievementService } from './../services/Achievement.services';
import { Component, OnInit, Inject } from '@angular/core';
import { PagerService } from '../pager.service';
import { TranslateService } from '../translate.service';
import { environment } from '../../environments/environment';
import { AchievementDepartmentCategoryViewModel, DepartmentalAchievementFilterModel } from '../Model/achievement.model';
import { DepartmentCodeEnum } from '../Enum/app-enums';
import { AchievementViewModel, IndexModel } from '../Model/awards-request-model.model';
import { CommonService } from '../services/common.service';
import { AppSettings } from '../common/AppSettings';
import { DDLModel, FilterDDlModel } from '../Model/common.model';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-achivement',
  templateUrl: './achivement.component.html',
  styleUrls: ['./achivement.component.css'],
  providers: [AchievementService]
})
export class AchivementComponent implements OnInit {
  environmentUrl = environment.imageUrl + 'Publicpdf/';
  achievementprograms: AchievementModel[];
  pager: any = {};
  pagedachievementprograms: AchievementModel[];
  totalRecord: number = 0;
  currentPage: number;
  pageSize = 10;
  
  catCode: number;
  departmentList: any[];
  allAchievementList: any[];
  groupByDeptAchievementList: any;
  filterModel: DepartmentalAchievementFilterModel = new DepartmentalAchievementFilterModel();
  departmentCode: number = 0;
  achievementDepartmentCategoryList: AchievementDepartmentCategoryViewModel[];
  DepartmentCodeEnum: DepartmentCodeEnum;
  noRecords = false;
  dDLList: DDLModel;
  filterDDlModel: any[];
  deptAchievementList: any[];
  // isCodeOfConduct: boolean = true;
  isCodeOfConduct: boolean = false;

  constructor(@Inject('BASE_URL') public baseUrl: string, 
    private achievementService: AchievementService,
    private pagerService: PagerService, 
    public readonly translate: TranslateService,
    public readonly commonService: CommonService,
    private readonly route: ActivatedRoute,
    public _commonService: CommonService) {
    this.isCodeOfConduct = this._commonService.GetIsCodeOfConduct();
    this.pagedachievementprograms = [];
    this.currentPage = 1;
    this.GetDDLList();
    this.catCode = this.route.snapshot.params.catCode;
    if (this.catCode) {
      this.filterModel.CategoryCode = this.catCode;
      this.GetDepartmentAchivement_DeptCatWiseList();
    }
    else {
      this.GetDepartmentAchivement_DeptCatWiseCount();
    }
  }

  ngOnInit() {
    //this.GetDepartmentAchivement_DeptCatWiseListFromJankalyan();
  }
  setLang(lang: string) {
    this.translate.use(lang);
  }

  setPage(page: number) {
    window.scroll(0, 0);
    this.currentPage = page;
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    // get pager object from service
    this.pager = this.pagerService.getPager(this.totalRecord, page, this.pageSize);
    // get current page of items
    this.pagedachievementprograms = this.achievementprograms;
  }

  // GetDepartmentAchivement_DeptCatWiseListFromJankalyan(){
  //   this.achievementDepartmentCategoryList = undefined;
  //   this.departmentCode = DepartmentCodeEnum.CmoWebsiteCode;
  //   this.achievementService.GetAchievementListFromJankalyanApi(this.departmentCode).subscribe(data => {
  //     if (data.IsSuccess) {
  //       this.achievementDepartmentCategoryList = data.Data as AchievementDepartmentCategoryViewModel[];
  //     } else {
  //       this.achievementDepartmentCategoryList = [];
  //     }
  //   }, error => {
  //     this.achievementDepartmentCategoryList = [];
  //     console.error(error);
  //   });
  // }

  GetDDLList() {
    this.commonService.GetAllDDL(AppSettings.DDLKeyForImportantDecision).subscribe(
      data => {
        if (data.IsSuccess) {
          this.dDLList = data.Data as DDLModel;
        }
      },
      error => {
      }
    );
  }
  getFilteredDDL(filterFor, value, filterFrom) {

    const item = new FilterDDlModel();
    item.FilterFor = filterFor// AppSetting.DDLFilterLKeyForAchievementList;
    item.Value = value;//this.achievementCategoryEnum.DepartmentalAchievementsCode;
    item.FilterFrom = filterFrom//'ddlAchievementCategory';
    this.filterDDlModel = [];
    this.filterDDlModel.push(item);
    this.commonService.GetAllDDL(this.filterDDlModel).subscribe(
      data => {

        if (data.IsSuccess) {
          if (item.FilterFor === 'ddlDepartmentImportantDecision' || data.Data.ddlDepartmentImportantDecision || undefined != undefined) {
            this.filterModel.DepartmentCode = 0
            this.dDLList.ddlDepartmentImportantDecision = data.Data.ddlDepartmentImportantDecision;
          }
        }
      },
      error => {

      }
    );
  }

  OnSearchClick() {
    this.GetDepartmentAchivement_DeptCatWiseList();
  }

  reset() {
    this.filterModel = new DepartmentalAchievementFilterModel();
    this.filterModel.CategoryCode = this.catCode;
    this.GetDepartmentAchivement_DeptCatWiseList();
  }
  getDeptAchievementList(deptCode: number) {
    this.deptAchievementList = this.groupByDeptAchievementList.get(deptCode);
  }

  GetDepartmentAchivement_DeptCatWiseCount() {
    this.achievementService.GetAchievementListFromJankalyanApi(0).subscribe(data => {
      if (data.IsSuccess) {
        this.achievementDepartmentCategoryList = data.Data as AchievementDepartmentCategoryViewModel[];
        this.noRecords = this.achievementDepartmentCategoryList.length > 0 ? false : true;
      } else {
        this.achievementDepartmentCategoryList = [];
        this.noRecords = true;
      }
    }, error => {
      this.achievementDepartmentCategoryList = [];
      console.error(error);
    });
  }

  GetDepartmentAchivement_DeptCatWiseList() {
    this.achievementService.GetAchievementCountWoseListFromJankalyanApi(this.filterModel).subscribe(data => {
      if (data.IsSuccess) {
        this.allAchievementList = data.Data.Data as AchievementViewModel[];

        this.groupByDeptAchievementList = this.commonService.groupBy(this.allAchievementList, x => x.DepartmentCode);
        this.bindDeptDetail();

      } else {
        this.allAchievementList = [];
      }
    }, error => {
      this.allAchievementList = [];
      console.error(error);
    });
  }

  bindDeptDetail() {
    this.departmentList = [];
    for (let key of this.groupByDeptAchievementList.keys()) {
      let achList = this.groupByDeptAchievementList.get(key);
      let dept_detail = {
        "DepartmentCode": achList[0].DepartmentCode,
        "DepartmentTitle": achList[0].DepartmentTitle,
        "DepartmentTitleHindi": achList[0].DepartmentTitleHindi,
        "TotalRecords": achList.length
      };
      this.departmentList.push(dept_detail);
      this.noRecords = this.departmentList.length > 0 ? false : true;
    }
  }

}

interface AchievementModel {
  AchievementId: number;
  Achievement: string;
  AchievementHindi: number;
  Priority: string;
  PdfFIleName: string;
  Url: string;
  AchievementDate: string;
  AchievementDateHindi: string;
  Active: boolean;
  TotalRecord: number;
}

export class AchievementAllSearchModel {
  AdmDepartmentCode: number | string = 0;
  DepartmentCode: number | string = 0;
  CategoryCode: number | string = 0;
  SubCategoryCode: number | string = 0;
  ToDate: string = "";
  FromDate: string = "";
  SearchKeyword: string = "";
  IsURLRequired: boolean = false;
  IsImageRequired: boolean = false;
  IsPDFRequired: boolean = false;
  IsActive: boolean | null = true;
  IsLatestUpdate: number;
  IsVisibleInHome: boolean | null = null;
  IsVisibleInFront: boolean | null = null;
  IndexModel = new IndexModel();
  CommonCategoryCode: number;
  IsBase64File: boolean = false;
  LookupCode: number;

  IsUserManual: number = -1
  IsOrders: number = -1
  IsSchemeImplementationStatus: number = -1
  IsSupportingDocument: number = -1
  IsApplicationForm: number = -1

  constructor() {
    this.IsLatestUpdate = -1;
  }
}



