import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rajsampark',
  templateUrl: './rajsampark.component.html',
  styleUrls: ['./rajsampark.component.css']
})
export class RajsamparkComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
