import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-latest-news',
  templateUrl: './home-latest-news.component.html',
  styleUrls: ['./home-latest-news.component.css']
})
export class HomeLatestNewsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
