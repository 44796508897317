import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chiefsecretary',
  templateUrl: './chiefsecretary.component.html'
 
})
export class ChiefsecretaryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
