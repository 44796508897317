export class IndexModel {
  Page: number;
  PageSize: number;
  Search: string;
  OrderBy: string;
  OrderByAsc: number;
  IsPostBack: boolean;
  AdvanceSearchModel?: { [index: string]: any } = {}; //any;


  constructor() {
    this.PageSize = 10;
    this.IsPostBack = false;
    this.OrderByAsc = 0;
    this.Search = null;
    this.OrderBy = null;
    this.Page = 1;
  }
}

export class UpcomingCMEvtModal {
    IndexModel = new IndexModel();
    DepartmentCode: number | string;
    OfficeCode: number | string;
    EntryTypeCode: number | string;
    JankalyanCategoryCode: number | string;
    LookupCode: number | string;
    CategoryCode: number 
    SubCategoryCode: number;
    FromDate?: Date;
    IsCMUpcomingEvt?: Boolean;

    constructor() {
        this.DepartmentCode = 0;
        this.OfficeCode = 0;
        this.EntryTypeCode = 0;
        this.JankalyanCategoryCode = 0;
        this.LookupCode = 0;
        this.IsCMUpcomingEvt = false;
    }
  }

  export class UpcomingCMEvtViewModal {
    Id: number;
    Code: number;
    DepartmentCode: number | string;
    EntryTypeCode: number | string;
    DataTypeCode: number;
    Details: any;
    ImageURL: string;
    PDFURL: string | any;
    CreatedDate: Date;
    CreatedBy: number;
    ModifiedDate: Date;
    ModifiedBy: number;
    IsActive: boolean;
    IsDeleted: boolean;
    DepartmentTitle: string;
    DepartmentTitleHindi: string;
    DepartmentShortTitle: string;
    DepartmentShortTitleHindi: string;
    EntryTypeName: string;
    EntryTypeNameHindi: string;
    EntryTypeDisplayOrder: number;
    DetailsInHindi: string | any;
    url: string;
  }
  