<!-- <div class="bg-scroll pos-rel breadcrumbs-page-img">
  <div class="container">
    <h1>{{'Mega_Event_Detail'| translate}}</h1>
  </div>
</div> -->
<div class="breadcrumbs-page-img">
  <div class="banner-hight-2">
    <h1>{{'Mega_Event_Detail'| translate}}</h1>
    <div class="inner-page-banner">
      <img src="assets/images/cmo-banner.png" alt="banner" class="cmo-banner">
    </div>
  </div>
</div>
<main id="main">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
      <li class="breadcrumb-item"><a href="javascript:void(0)">{{'Media_CornerSubNav'| translate}}</a></li>
      <li class="breadcrumb-item"><a routerLink="/mega-event-list">{{'Mega_Event'| translate}}</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{'Mega_Event_Detail'| translate}}</li>
    </ol>
  </nav>
  <section class="program_list_detail">
    <div class="container">
      <div class="row">
        <div class="col-xl-9 wow fadeInLeft">
          <div class="program_detail_left">

            <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#HomePageImage">
              <img class="d-block w-100 main-image" src="{{generalModel?.HomePageImageUrl}}"
                      onerror="this.src='../../assets/images/program-no-img.png'">
            </a>
            <!-- <div id="carouselExampleControls" class="carousel slide mb-2" data-ride="carousel" *ngIf="generalModel.ImageAttachments.length > 0">
              <div class="carousel-inner">
                <div class="carousel-item" [ngClass]="[i==0 ? 'active' : '' ]"
                  *ngFor="let item of generalModel?.ImageAttachments let i = index">
                  <img class="d-block w-100" src="{{item}}" alt="item{{i}}"
                    onError="this.src='../../assets/images/program-no-img.png'">
                </div>

              </div>

              <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev"> <span
                  class="carousel-control-prev-icon" aria-hidden="true"></span> <span class="sr-only">Previous</span>
              </a> <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span> <span class="sr-only">Next</span>
              </a>

            </div> -->
            <h3>
               <!-- {{this.translate.currlang == 'hi' ? generalModel?.Description : generalModel?.Description}} -->
              {{ 
                showFullText 
                  ? (translate.currlang == 'hi' ? generalModel?.Description : generalModel?.Description) 
                  : ((translate.currlang == 'hi' ? generalModel?.Description : generalModel?.Description) | slice:0:100) + '...'
              }}
              <a (click)="toggleText()" style="cursor: pointer; color: blue; font-size: small;font-weight: 600;">
                {{ showFullText ? 'Read Less' : 'Read More' }}
              </a>
            </h3>

            <!--
            <div class="text-muted"><i class="ion-ios-location-outline"></i> {{this.translate.currlang == 'hi' ? megaEventProgramDetail.ProgramAddressHindi : megaEventProgramDetail.ProgramAddress}},  &nbsp; &nbsp;<i class="fa fa-calendar"></i> {{this.translate.currlang == 'hi' ? megaEventProgramDetail.ProgramDateHindi : megaEventProgramDetail.ProgramDate}}</div> -->


            <div class="row">
              <div class="col-xl-12 d-flex align-items-center justify-content-between mb-4">
                <div class="text-muted"><i class="fa fa-calendar"></i> {{this.translate.currlang == 'hi' ? generalModel?.PressReleaseDateHindi
                  : generalModel?.PressreleaseDate | date : 'd MMM, y'}}</div>
                  <div class="d-flex align-items-center justify-content-end mb-2">
                    <a href="{{generalModel?.Attachments}}" class="gray-btn gray-orange px-3 py-2 text-white">
                      <i class="fa fa-file-pdf-o mr-1"></i>Download</a>
                      
                    <a (click)="onSocial(generalModel?.DepartmentTitle,'fb')" style="cursor: pointer;" class="px-3 py-2" id="facebook_social_id" target="_blank" >
                        <img src="https://www.pmindia.gov.in/wp-content/themes/pmindia2015/images/share-facebook.png" alt="facebook" title="facebook">
                    </a>

                    <!-- this.facebookService.share('https://cmo.rajasthan.gov.in'+router.url,generalModel.DepartmentTitle,generalModel.HomePageImageUrl); -->

                    <a (click)="onSocial(generalModel?.Description,'twiiter')" href="javascript:void(0);">
                      <img src="https://www.pmindia.gov.in/wp-content/themes/pmindia2015/images/share-twitter.png" alt="twitter" title="twitter">
                    </a>
                    <!-- <a *ngIf="isEnglish && megaEventProgramDetail.PdfEnglish != null && megaEventProgramDetail.PdfEnglish != ''"
                      href="{{environmentUrl}}{{megaEventProgramDetail.PdfEnglish}}" rel="noopener noreferrer"
                      target="_blank" title="PDF"> <i class="fa fa-file-pdf-o" aria-hidden="true"></i> Read more <span
                        class="fa fa-angle-right" aria-hidden="true"></span> </a>
                    <a *ngIf="!isEnglish && megaEventProgramDetail.Pdf != null && megaEventProgramDetail.Pdf != ''"
                      href="{{environmentUrl}}{{megaEventProgramDetail.Pdf}}" rel="noopener noreferrer" target="_blank"
                      title="PDF">
                      <i class="fa fa-file-pdf-o" aria-hidden="true"></i> Read more <span class="fa fa-angle-right"
                        aria-hidden="true"></span>
                    </a> -->
                  </div>
              </div>

            </div>
            <p [innerHTML]="generalModel?.GeneralDescription"></p>

            <!--<p></p>-->
            <!--<p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32. </p>-->
          </div>
        </div>

        <div class="col-xl-3  wow fadeInRight">
          <div class="program_detail_right">

            <!-- <div class="program_detail_right_box mb-4">
              <div class="section-header">
                <h2>{{'Photo_Gallery'| translate}}</h2>
                <div class="row photos">
                  <div class="col-sm-6 col-md-4 col-lg-4 item" *ngFor="let item of galleryDetail let i = index">
                    <a href="{{this.orignalImageUrl}}{{item.ImageName}}" data-lightbox="photos"><img class="img-fluid"
                        src="{{this.imageUrl}}{{item.ImageName}}"></a>
                  </div>

                </div>

              </div>
            </div> -->


            <!-- <div class="program_detail_right_box mb-4" *ngIf="videos.length>0">
              <div class="section-header">
                <h2>{{'Video_Gallery'| translate}}</h2>
              </div>
              <div class="row photos">
                <div class="col-sm-6 col-md-4 col-lg-4 item" *ngFor="let item of videos let i = index">
                  <a href="{{this.imageVideoUrl}}{{item.videoimage}}" data-lightbox="photos"><img class="img-fluid"
                      src="{{this.imageVideoUrl}}{{item.videoimage}}"></a>
                </div>

              </div>
            </div> -->

            <!-- <div class="program_detail_right_box mb-4" *ngIf="generalModel?.URL">
              <div class="section-header">
                <h2>{{'Video_Gallery'| translate}}</h2>
              </div>
              <div class="rounded">
                  <a [href]="generalModel.URL" target="_blank">
                    <div width="640" height="360" class="embed-responsive embed-responsive-21by9">
                        <video #videoPlayer tabindex="-1" (playing)="onMediaPlaying($event, false)"
                            id="my_video" (mouseover)="playVideo($event)" (mouseout)="pauseVideo($event)"
                            class="embed-responsive-item" width="100%" height="100%" controls preload="none"
                            data-setup='{ "aspectRatio":"100%:100%", "playbackRates": [1, 1.5, 2] }'
                            disablePictureInPicture
                            controlslist="nodownload noremoteplayback noplaybackrate foobar">
                            <source src="{{generalModel.URL}}"
                                type="video/{{generalModel.URL.split('.')[generalModel.URL.split('.').length-1]}}">
                        </video>
                    </div>
                </a>
              </div>
            </div> -->

            <div class="program_detail_right_box mb-4" *ngIf="generalModel?.ImageAttachments.length > 0">
              <div class="section-header">
                <h2>{{'Photo_Gallery'| translate}}</h2>
              </div>
              <h6 *ngIf="generalModel?.ImageAttachments.length == 0">No Photos Available</h6>
              <div class="row photos">
                <div class="col-sm-6 col-md-4 col-lg-4 item" *ngFor="let item of generalModel?.ImageAttachments let i = index">
                  <a data-bs-toggle="modal" data-bs-target="#galleryBlock" (click)="openCarousel(i)" href="javascript:void(0)"><img class="img-fluid"
                      src="{{item}}"
                      onError="this.src='../../assets/images/program-no-img.png'"></a>
                </div>
  
              </div>
            </div>
            
            <div class="program_detail_right_box mb-4" *ngIf="generalModel?.URL">
              <div class="">
                <h2 style="font-weight: 550;">{{'Video_Gallery'| translate}}</h2>
              </div>
              <div class="rounded ">
                <p *ngIf="IsLoading" class="text-left"><b>Loading...</b></p>
  
                <!-- <div class="col-sm-6 col-md-4 col-lg-4 item" *ngFor="let item of videos let i = index"> -->
                  <a [href]="generalModel.URL" target="_blank">
                    <div width="640" height="360" class="embed-responsive embed-responsive-21by9">
                        <video #videoPlayer tabindex="-1" (playing)="onMediaPlaying($event, false)"
                            id="my_video" (mouseover)="playVideo($event)" (mouseout)="pauseVideo($event)"
                            class="embed-responsive-item" width="100%" height="100%" controls preload="none"
                            data-setup='{ "aspectRatio":"100%:100%", "playbackRates": [1, 1.5, 2] }'
                            disablePictureInPicture
                            controlslist="nodownload noremoteplayback noplaybackrate foobar">
                            <source src="{{generalModel.URL}}"
                                type="video/{{generalModel.URL.split('.')[generalModel.URL.split('.').length-1]}}">
                        </video>
                    </div>
                </a>
                <!-- </div> -->
              </div>
            </div>
            

            <div class="program_detail_right_box mb-4" *ngIf="programPressRelease.length>0">
              <section class=" box-content">
                <div class=" press_box">
                  <div class="section-header">
                    <h2>
                      PRESS RELEASES
                    </h2>
                  </div>
                  <div class="press_releases">
                    <ul class="wow fadeInRight">
                      <li class="wow fadeInLeft" *ngFor="let program of programPressRelease">
                        <div class="press_releases_bx d-flex">
                          <div class="date align-self-center">
                            {{program?.ProgramDate | date : 'dd'}} <br>
                            <div style="font-size:13px;">{{program?.ProgramDate| date : 'MMMM'}}</div>
                          </div>
                          <div class="detail align-self-center">


                            <a *ngIf="isEnglish && program.PdfEnglish != null && program.PdfEnglish != ''"
                              href="{{environmentUrl}}{{program?.PdfEnglish}}" rel="noopener noreferrer"
                              target="_blank" title="PDF">

                              <p
                                *ngIf="(this.translate.currlang == 'hi' && program.ProgramSubjectHindi.length<=30 )||(this.translate.currlang != 'hi' && program.ProgramSubject.length<=30)">

                                {{this.translate.currlang == 'hi' ? program?.ProgramSubjectHindi :
                                program?.ProgramSubject}}

                              </p>
                              <p
                                *ngIf="(this.translate.currlang == 'hi' && program.ProgramSubjectHindi.length>30 )||(this.translate.currlang != 'hi' && program.ProgramSubject.length>30)">

                                {{(this.translate.currlang == 'hi' ? program.ProgramSubjectHindi :
                                program.ProgramSubject)|slice:0:30}}...

                              </p>

                              <!--{{this.translate.currlang == 'hi' ? program.ProgramSubjectHindi : program.ProgramSubject}}-->
                            </a>
                            <a *ngIf="!isEnglish && program.Pdf != null && program.Pdf != ''"
                              href="{{environmentUrl}}{{program.Pdf}}" rel="noopener noreferrer" target="_blank"
                              title="PDF">

                              <p
                                *ngIf="(this.translate.currlang == 'hi' && program.ProgramSubjectHindi.length<=30 )||(this.translate.currlang != 'hi' && program.ProgramSubject.length<=30)">

                                {{this.translate.currlang == 'hi' ? program.ProgramSubjectHindi :
                                program.ProgramSubject}}

                              </p>
                              <p
                                *ngIf="(this.translate.currlang == 'hi' && program.ProgramSubjectHindi.length>30 )||(this.translate.currlang != 'hi' && program.ProgramSubject.length>30)">

                                {{(this.translate.currlang == 'hi' ? program.ProgramSubjectHindi :
                                program.ProgramSubject)|slice:0:30}}...

                              </p>

                              <!--{{this.translate.currlang == 'hi' ? program.ProgramSubjectHindi : program.ProgramSubject}}-->
                            </a>
                            <span><i class="ion-ios-location-outline"></i>{{this.translate.currlang == 'hi' ?
                              program.ProgramAddress : program.ProgramAddress}} </span>
                            <span>
                              <i class="fa fa-calendar" aria-hidden="true"></i> &nbsp;{{program.ProgramDate}}
                            </span>
                          </div>
                        </div>
                      </li>

                    </ul>

                  </div>
                </div>
              </section>
            </div>

            <div class="program_detail_right_box mb-4" *ngIf="PressReleaseListModel!=undefined && PressReleaseListModel.length>0">
              <div class="section-header">
                <h2>{{'Latest_Mega_Event'| translate}}</h2>
              </div>
              <div class="media" *ngFor="let program of PressReleaseListModel let i = index">
                <!-- <div class="align-self-center">
                  <img class="mr-2" src="{{this.imageUrl}}{{program.ImageName}}"
                    onError="this.src='../../assets/images/program-no-img.png'" alt="">


                </div> -->
                <div class="media-body align-self-center">
                  <!--<h5 class="mt-0"><a href="javascript:void(0)">Card title</a></h5>-->
                  <a routerLink="/mega-event-detail/{{program.Id}}">
                    <div class="middle-content-date">
                      {{ translate.currlang=='en' ? (program.PressreleaseDate | date : 'd MMM, y') :
                      program.PressReleaseDateHindi }}
                    </div>

                    <div class="middle-content-info">{{program?.Description}}</div>

                    <!-- <p
                      *ngIf="(this.translate.currlang == 'hi' && program.Description.length<=50 )||(this.translate.currlang != 'hi' && program.Description.length<=50)">

                      {{this.translate.currlang == 'hi' ? program.Description : program.Description}}

                    </p>
                    <p
                      *ngIf="(this.translate.currlang == 'hi' && program.Description.length>50 )||(this.translate.currlang != 'hi' && program.Description.length>50)">
                      {{(this.translate.currlang == 'hi' ? program.Description : program.Description)|slice:0:50}}...
                    </p> -->
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div #carouselContainer class="modal fade galleryBlock" id="galleryBlock" tabindex="-1" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"></h5>
        <button type="button" class="close close-in" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" >
        <!-- <p *ngIf="isLoading" class="text-left"><b>Loading...</b></p> -->
        <div id="carouselExampleControls" class="carousel slide mb-2" data-bs-ride="carousel"
              *ngIf="carouselData.length>0">
              <div class="carousel-inner" *ngIf="isVisible">  
                <div class="carousel-item" *ngFor="let item of carouselData; let i = index" [ngClass]="item.active == true ? 'active' : '' ">
                  <img class="d-block w-100" src="{{item.image}}"
                    onError="this.src='../../assets/images/program-no-img.png'">
                </div>

              </div>
              <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-bs-slide="prev"> <span
                  class="carousel-control-prev-icon" aria-hidden="true"></span> <span class="sr-only">Previous</span>
              </a> <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span> <span class="sr-only">Next</span>
              </a>
              <div class="carousel-inner" *ngIf="carouselData.length==0">

                <img class="d-block w-100" src="../../assets/images/default_img1.png" />


              </div>

            </div>
            <div class="carousel-inner" *ngIf="carouselData.length==0">

              <img class="d-block w-100" src={{generalModel?.HomePageImageUrl}} />


            </div>
        <!-- <img class="d-block w-100" src="{{image}}" alt=""
          onError="this.src='../../assets/images/program-no-img.png'"> -->
      </div>

    </div>
  </div>
</div>
</main>

<div class="modal fade galleryBlock" id="HomePageImage" tabindex="-1" aria-labelledby="exampleModalLabel"
aria-hidden="true">
<div class="modal-dialog modal-lg">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabel"></h5>
      <button type="button" class="close close-in" data-bs-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" >
      <img class="d-block w-100" src="{{generalModel?.HomePageImageUrl}}" alt=""
        onError="this.src='../../assets/images/program-no-img.png'">
    </div>

  </div>
</div>
</div>
