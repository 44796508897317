<section class="program_list_detail page_search ">
  <div class="container">
    <div class="row">
      <div class="col-xl-3 col-sm-6  wow fadeInUp">
        <label>{{'Search_Keyword_txt'|translate}}:</label>
        <input [(ngModel)]="filterModel.Description" placeholder="{{'Search_Input_Hint_txt'|translate}}"
        class=" form-control" />
      </div>

      <div class="col-xl-3 col-sm-6  wow fadeInUp">
        <label>{{'Category_txt'|translate}}:</label>
        <select class="form-control" data-style="select-with-transition" name="Purpose" [(ngModel)]="filterModel.GroupType"
                tabindex="2">
          <!--(change)="onChange($event.target.value)"-->
          <!-- <option class="bs-title-option" value="">{{'Select' | translate}}</option> -->
          <option class="bs-title-option" value="All">{{'All_txt' |translate}}</option>
          <option class="bs-title-option" value="Event">{{'Nav_Program' |translate}}</option>
          <!--<option class="bs-title-option" value="Press Release">{{'Footer_News' |translate}}</option>-->
          <option class="bs-title-option" value="Press Release">{{'Press_ReleasesSubNav' |translate}}</option>
          <option class="bs-title-option" value="Message">{{'Footer_Messages' |translate}}</option>
        </select>

      </div>
      <!-- <div class="col-xl-3 col-sm-6  wow fadeInUp">
        <label>Level:</label>
        <select class=" form-control" [(ngModel)]="filterModel.PressReleaseLevelCode">
        <option value='0'>--Level--</option>
        <option *ngFor="let item of dDLList.RadioStateDistrict" [value]="item.Value">{{item.Text}}</option>
        </select>
      </div> -->
      <div class="col-xl-3 col-sm-6  wow fadeInUp">
        <label>{{'From_Date_txt'|translate}}:</label>
        <!-- <ng-datepicker [(ngModel)]="filterModel.FromDate" class="form-control w-100" [options]="options"></ng-datepicker> -->
      </div>
      <div class="col-xl-3 col-sm-6  wow fadeInUp">
        <label>{{'To_Date_txt'|translate}}:</label>
        <!-- <ng-datepicker [(ngModel)]="filterModel.ToDate" class="form-control w-100" [options]="options"></ng-datepicker> -->
      </div>

      <div class="col-xl-3 col-sm-6  wow fadeInUp">
        <label>&nbsp;</label>
        <button (click)="filterData()" class="gray-btn gray-orange btn-block btn-search  wow fadeInUp">{{'Search_btntxt' |translate}}</button>
      </div>

    </div>
  </div>
</section>
<section class="program_list message_cm">
  <div class="container">
    <div class="row">
      <section id="programmes">

        <div class="row">
          <div class="col-lg-4" *ngFor="let message of generalModel let i = index">
            <div class="box pbox wow  dis-table" [ngClass]="[i%3 <1 ? 'fadeInLeft' : i%3 <2? 'fadeInUp':i%3 <3? 'fadeInRight':'fadeInLeft' ]">
              <div class="icon align-self-center"> <img src="assets/img/Search_Icon.png" alt="Icon" class="img-fluid"> </div>
              <div class="programme-content align-self-center">
                <h4 class="title" *ngIf="(this.translate.currlang == 'hi' && message.Description.length<=50 )||(this.translate.currlang != 'hi' && message.Description.length<=50)">
                  {{this.translate.currlang == 'hi' ? message.Description : message.Description}}
                </h4>
                <h4 *ngIf="(this.translate.currlang == 'hi' && message.Description.length>50 )||(this.translate.currlang != 'hi' && message.Description.length>50)">
                  {{(this.translate.currlang == 'hi' ? message.Description : message.Description)|slice:0:45}}...
                </h4>
                <span><strong>{{'Posted_On'| translate}}:</strong> {{this.translate.currlang == 'hi' ? message.PressReleaseDateHindi : message.PressreleaseDate | date : 'd MMM, y'}} </span>
                <a routerLink="{{message.FrontRedirectPageName=='PRESS-RELEASE'?'/pressreleasedetail/':message.FrontRedirectPageName=='MESSAGE'?'/messageDetail/':'/Programdetail/'}}{{message.ProgramId}}" class="mr-auto">{{'Read_More'| translate}}</a>
              </div>
            </div>
          </div>
        </div>

      </section>
      <nav aria-label="Page navigation example" class="w-100 text-center mt-4" *ngIf="totalRecords > 9">

        <ul class="pagination d-inline-flex" *ngIf="pager.pages && pager.pages.length">
          <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
            <a class="page-link" (click)="GetPressReleaseData(1)">{{'First'| translate}}</a>
          </li>
          <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
            <a class="page-link" (click)="GetPressReleaseData(pager.currentPage - 1)">{{'Previous'| translate}}</a>
          </li>
          <li class="page-item" *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
            <a class="page-link" (click)="GetPressReleaseData(page)">{{page}}</a>
          </li>
          <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
            <a class="page-link" (click)="GetPressReleaseData(pager.currentPage + 1)">{{'Next'| translate}}</a>
          </li>
          <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
            <a class="page-link" (click)="GetPressReleaseData(pager.totalPages)">{{'Last'| translate}}</a>
          </li>
        </ul>

      </nav>
    </div>
  </div>

</section>

<section class="program_list message_cm" *ngIf="Messageprograms.length==0 && isShow">
  <div class="container">


    <div class="row">
      <section id="programmes">

        <div class="row">
          {{'No_Record_Found'| translate }}
        </div>
      </section>
    </div>
  </div>
</section>
