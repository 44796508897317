import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class TranslateService {

  data: any = {};
  public currlang: string;
  constructor(private http: HttpClient) {
    this.setLanguage();
  }
  setLanguage() {
    this.currlang = "en";
  }
  use(lang: string): Promise<{}> {
    if (lang == "") {
      lang = (localStorage.getItem('lang') == null || localStorage.getItem('lang') == "") ? "en" : localStorage.getItem('lang');
    }
    this.currlang = lang;
    localStorage.setItem('lang', this.currlang);
    return new Promise<{}>((resolve, reject) => {
      const langPath = `assets/i18n/${lang}.json`;
      
      const headers = { 'Cache-Control': 'no-cache', 'Pragma': 'no-cache' }
      this.http.get<{}>(langPath, { headers}).subscribe(
        translation => {
          this.data = Object.assign({}, translation || {});
          resolve(this.data);
        },
        error => {
          this.data = {};
          resolve(this.data);
        }
      );
    });
  }



 
}
