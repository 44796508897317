<div [ngClass]="!isCodeOfConduct ? 'bg-scroll pos-rel breadcrumbs-page-img' : 'bg-scroll pos-rel'">
    <div class="container">
        <h1>{{'Nav_QuotesDetail'| translate}}</h1>
    </div>
</div>
<main id="main">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
            <!-- <li class="breadcrumb-item"><a href="javascript:void(0)">{{'Media_CornerSubNav'| translate}}</a></li> -->
            <li class="breadcrumb-item" aria-current="page"><a routerLink="/awards">{{'Nav_Quotes'| translate}}</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{'Nav_QuotesDetail'| translate}}</li>
        </ol>
    </nav>
    <section class="program_list_detail">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 wow fadeInLeft">
                    <div class="program_detail_left">

                        <div id="carouselExampleControls" class="carousel slide mb-2" data-ride="carousel">
                            <div class="carousel-inner" *ngIf="model.ImageFiles.length>0">
                                <div class="carousel-item" [ngClass]="[i==0 ? 'active' : '' ]"
                                    *ngFor="let item of model.ImageFiles let i = index">
                                    <img class="d-block w-100" src="{{item}}" alt="item{{i}}"
                                        onError="this.src='../../assets/images/program-no-img.png'">
                                </div>
                            </div>
                            <a class="carousel-control-prev" href="#carouselExampleControls" role="button"
                                data-slide="prev"> <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="sr-only">Previous</span> </a>
                            <a class="carousel-control-next" href="#carouselExampleControls" role="button"
                                data-slide="next"> <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="sr-only">Next</span> </a>
                            <div class="carousel-inner" *ngIf="model.ImageFiles.length==0">

                                <img class="d-block w-100" src="../../assets/images/default_img1.png" />
                            </div>

                        </div>

                        <h3> {{this.translate.currlang == 'hi' ? model.AchievementHindi : model.Achievement}}</h3>
                        <div class="text-muted">{{'Date_txt' |translate}} : <i class="fa fa-calendar"></i>
                            {{(this.translate.currlang == 'hi' ? model.AchievementDateHindi : (model.AchievementDate |
                            date:DateFormat))}}</div>

                        <p class="text-muted" *ngIf="model.Description||undefined!=undefined">
                            {{'Description_txt'|translate}} :
                            {{this.translate.currlang!='hi'? model.Description : model.DescriptionHindi}}</p>

                        <p class="text-muted" *ngIf="model.PdfFIleName||undefined!=undefined"> {{'AttachedDocuments_txt'
                            |translate}} :
                            <button class="btn btn-danger btn-pdf" title="{{'Downloads_txt'|translate}}"
                                (click)="downloadPdf(model.PdfFIleName,model.Id)"> <i class="fas fa-file-pdf mr-1"></i>
                                {{'Downloads_txt'|translate}} </button>
                        </p>
                        <object *ngIf="model.PdfFIleName||'' != ''" width="100%" height="650px">
                            <iframe title="PDF" [src]='PDFFile' type="application/pdf" frameborder="0" width="100%"
                                height="650px"></iframe>
                        </object>
                    </div>
                </div>

                <!-- <div class="col-xl-3  wow fadeInRight">
                    <div class="program_detail_right">
                        <div class="program_detail_right_box mb-4"
                            *ngIf="awardsDetail.Image!=undefined && awardsDetail.Image.length>0">
                            <div class="section-header">
                                <h2>{{'Photo_Gallery'| translate}}</h2>
                            </div>
                            <div class="row photos">
                                <div class="col-sm-6 col-md-4 col-lg-4 item"
                                    *ngFor="let item of awardsDetail.Image let i = index">
                                    <a href="{{this.environmentUrlImage}}{{item}}" data-lightbox="photos"><img
                                            class="img-fluid" src="{{this.environmentUrlImage}}{{item}}"></a>
                                </div>
                            </div>
                        </div>
                        <div class="program_detail_right_box mb-4"
                            *ngIf="latestAwardModels!=undefined && latestAwardModels.length>0">
                            <div class="section-header">
                                <h2>{{'Latest_Award'| translate}}</h2>
                            </div>
                            <div class="media" *ngFor="let award of latestAwardModels let i = index">
                                <div class="align-self-center"> <img class="mr-2"
                                        src="{{this.environmentUrlImage}}{{award.Image[0]}}"
                                        onError="this.src='../../assets/images/program-no-img.png'" alt=""> </div>
                                <div class="media-body align-self-center">
                                    <a routerLink="/awarddetail/{{award.AwardId}}">
                                        <p
                                            *ngIf="(this.translate.currlang == 'hi' && award.SubjectHindi.length<=50 ) || (this.translate.currlang != 'hi' && award.Subject.length<=50)">
                                            {{this.translate.currlang == 'hi' ? award.SubjectHindi : award.Subject}}
                                        </p>
                                        <p
                                            *ngIf="(this.translate.currlang == 'hi' && award.SubjectHindi.length>50 )||(this.translate.currlang != 'hi' && award.Subject.length>50)">
                                            {{(this.translate.currlang == 'hi' ? award.SubjectHindi :
                                            award.Subject)|slice:0:50}}...
                                        </p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </section>
</main>