import { Injectable } from '@angular/core';

declare global {
  interface Window {
    fbAsyncInit: () => void;
    FB: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class FacebookService {

  constructor() {
    this.loadFbSdk();
  }

  loadFbSdk(): void {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId      : 'your-app-id', // Replace with your Facebook app id
        autoLogAppEvents : true,
        xfbml      : true,
        version    : 'v10.0'
      });
    };

    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }

  share(url: string, hashtag?: string,image?:any, title?: string): void {
    console.log(hashtag.replace(/\n/g, ""))
    window.FB.ui({
      method: 'share',
      href: url,
      hashtag: hashtag.replace(/\n/g, ""),
      // picture:image,
      // image: image
      // title: title,
    }, function(response){});
  }
}
