
export enum AchievementCategoryEnum {
  DepartmentalAchievements = "Departmental Achievements",
  DepartmentalAchievementsCode = "1",

  Video = "Video",
  VideoCode = "2",

  StateAwards = "Awards",
  StateAwardsCode = "3",

  Awards_ImageHeight = "300",
  Awards_ImageWidth = "500",

  UpcomingEvents = "Upcoming Events",
  UpcomingEventsCode = "4",
  Publications = "Publications",
  PublicationsCode = "5",
  PhotoGallery = "Photo Gallery",
  PhotoGalleryCode = "6",
  ExternalLink = "External Link",
  ExternalLinkCode = "7",
  NewsTicker = "News Ticker",
  NewsTickerCode = "8",

  BannerImage = "Banner Image",
  BannerImageCode = "9",
  Banner_ImageHeight = "500",
  Banner_ImageWidth = "1920",

  Posters = "Posters",
  PostersCode = 10,

  Audio = "Audio",
  AudioCode = "11",

  Advertisement = "Advertisement",
  AdvertisementCode = "12",

  ImportantDecisions = "Important Decisions",
  ImportantDecisionsCode = "13",

  CabinetDecisions = "Cabinet Decisions",
  CabinetDecisionsCode = "14",

  LetterstoCentralGovt = "Letters to Central Govt",
  LetterstoCentralGovtCode = "15",



  Website = "Website",
  WebsiteCode = "16",

  MobileApplication = "Mobile Application",
  MobileApplicationCode = "17",

  ProgramVideo = "Program Video",
  ProgramVideoCode = "18",

  CMSpeech = "Hon'ble CM Speech",
  CMSpeechCode = "19",

  ServicesOfferedbyDepartment = "Services Offered by Department",
  ServicesOfferedbyDepartmentCode = "20",

  GovernmentNewInitiatives = "Government - New Initiatives",
  GovernmentNewInitiativesCode = "21",

  CelebrationDays = "Celebration - Days",
  CelebrationDaysCode = "22",

  YearsAchievements = "2 years Achievements",
  YearsAchievementsCode = "23",

  Employment = "Employment",
  EmploymentCode = "24",

  DepartmentalPhotoGallery = "Departmental Photo Gallery",
  DepartmentalPhotoGalleryCode = "25",

  WebHomePageImage = "Web - Home Page Image",
  WebHomePageImageCode = "66",

  ExternalLinksFooter = "External Links Footer",
  ExternalLinksFooterCode = "70",

  ImportantWebLinks = "Important Web Links",
  ImportantWebLinksCode = "71",

  TollFreeNumber = "Toll Free Number",
  TollFreeNumberCode = "72",

  inauguration3Year = "JKP Inauguration - 3 years",
  inauguration3YearCode = "82",

  VirtualExhibition3Years = "Virtual Exhibition - 3 Years",
  VirtualExhibition3YearsCode = "83",

  SectorWiseAchievement3Years = "Sector Wise Achievement - 3 Years",
  SectorWiseAchievement3YearsCode = "84",

  ThreeYearsCompilation = "Three Years Compilation",
  ThreeYearsCompilationCode = "85",

  Budget = "Budget 2022-23",
  BudgetCode = "91",

  Quotes = "Quotes",
  QuotesCode = "127",
  // DemoQuotesCode = "112"

  FooterIconsFixOnAllWebsites = "120"
}
export enum RajAdvt_AchievementCategoryEnum {
  SidebarAllWebsites = "Sidebar All Websites",
  SidebarAllWebsitesCode = "73",

}

export enum JankalyanEntryTypeEnum {
  FooterNodalOfficerDetailEnglish = "Footer Nodal Officer Detail (English)",
  FooterNodalOfficerDetailEnglishCode = "40",

  CMVisionAndMission = "CM Vision & Mission",
  CMVisionAndMissionCode = "18",
  
  JankalyanCategory = "CM Vision & Mission",
  JankalyanCategoryCode = "4",

  WebsitePolicy = "Website Policy",
  WebsitePolicyCode = 83,

  ContentContributionModeration = "Content Contribution, Moderation and Approval Poli",
  ContentContributionModerationCode = 84,

  HyperlinkPolicy = "Hyperlink Policy",
  HyperlinkPolicyCode = 85,

  ContentReviewPolicy = "Content Review Policy",
  ContentReviewPolicyCode = 86,

  ContentArchivalPolicy = "Content Archival Policy",
  ContentArchivalPolicyCode = 87,

  TermsConditions = "Terms & Conditions",
  TermsConditionsCode = 88,

  AccessibilityStatement = "Accessibility Statement",
  AccessibilityStatementCode = 89,

  Disclaimer = "Disclaimer",
  DisclaimerCode = 90,

  PrivacyPolicy = "Privacy Policy",
  PrivacyPolicyCode = 91,

  ScreenReaderAccess = "Screen Reader Access",
  ScreenReaderAccessCode = 92,

  GeneralTermsAndConditions = "General Terms and Conditions",
  GeneralTermsAndConditionsCode = 93,

  CopyrightPolicy = "Copyright Policy",
  CopyrightPolicyCode = 94,
}

export enum JankalyanEntryCategoryTypeEnum {

  Introduction = "प्रस्तावना",
  IntroductionCode = "20",

  Parichay = "परिचय",
  ParichayCode = "21",

  Definitions = "परिभाषाएं",
  DefinitionsCode = "22",

  ObjectiveofTheScheme = "योजना का उदेद्ष्य",
  ObjectiveofTheSchemeCode = "23",

  PlanDetails = "योजना का विवरण",
  PlanDetailsCode = "24",

  PolicyYear = "पॉलिसी वर्ष",
  PolicyYearCode = "25",

  EligibilityUnderScheme = "योजनान्तर्गत पात्रता",
  EligibilityUnderSchemeCode = "26",

  BeneficiariesofTheScheme = "योजना के लाभार्थी",
  BeneficiariesofTheSchemeCode = "27",

  ItIsNecessaryToJoinThePlan = "योजना से जुड़ना जरूरी है",
  ItIsNecessaryToJoinThePlanCode = "28",

  UnderRegistrationProcess = "	योजनार्न्तगत पंजीकरण की प्रक्रिया",
  UnderRegistrationProcessCode = "29",

  PlannedPackage = "योजनार्न्तगत पैकेज",
  PlannedPackageCode = "30",

  ProfitTakingProcess = "योजनार्न्तगत लाभ लेने की प्रक्रिया",
  ProfitTakingProcessCode = "31",

  DisposalOfCircumvention = "	परिवेदना निस्तारण",
  DisposalOfCircumventionCode = "32",

  SpecialFeaturesOfThePlan = "योजना की खास बातें",
  SpecialFeaturesOfThePlanCode = "33",

  Contact = "संपर्क करे",
  ContactCode = "34",

  ImportantThingsRelatedToJanAadhaarCard = "जनआधार कार्ड से जुडी आवश्यक बातें",
  ImportantThingsRelatedToJanAadhaarCardCode = "35",

  RequiredDocuments = "आवश्यक दस्तावेज",
  RequiredDocumentsCode = "36",

  FooterNodalOfficerDetail = "Footer Nodal Officer Detail",
  FooterNodalOfficerDetailCode = "40",

  // FooterNodalOfficerDetailHindi = "Footer Nodal Officer Detail (Hindi)",
  // FooterNodalOfficerDetailHindiCode = "81",

  CopyRightDetail = "Copy Right Detail",
  CopyRightDetailCode = "41",

  GIGW_Guidelines = "GIGW Guidelines",
  GIGW_GuidelinesCode = "50",

  WebsitePolicy = "Website Policy",
  WebsitePolicyCode = "51",

  HospitalNotSignedTheMOU = "Hospital Not Signed The MOU",
  HospitalNotSignedTheMOUCode = "62",

}



export enum RajAdvt_AchievementSubCategoryEnum {
  SidebarAdd_PDF = "PDF",
  SidebarAdd_PDFCode = "283",

  SidebarAdd_URL = "URL",
  SidebarAdd_URLCode = "284",

  SidebarAdd_Image = "Image",
  SidebarAdd_ImageCode = "285",

  SidebarAdd_Data = "Data",
  SidebarAdd_DataCode = "286",

  SidebarAdd_Button = "Button",
  SidebarAdd_ButtonCode = "287",


  SidebarAdd_Facebook = "Facebook",
  SidebarAdd_FacebookCode = "288",

  SidebarAdd_Twitter = "Twitter",
  SidebarAdd_TwitterCode = "289",
}
