import { MediaService } from "./../services/media.services";
import { Component, Inject, OnInit, AfterViewInit } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { environment } from "../../environments/environment";
import { PagerService } from "../pager.service";
import { TranslateService } from "../translate.service";
import { GalleryDetailRequestModel } from "../Model/gallery-detail-model.model";
import {
  InAugrationListModel,
  PressReleaseFrontSearchModel,
} from "../Model/press-release-model.model";
import {
  DepartmentCodeEnum,
  PressReleaseCategoryTypeEnum,
  PressReleaseCMOPRCategoryEnum,
} from "../Enum/app-enums";
import { VIPCategoryEnum, VIPPersonCategoryEnum } from "../Enum/jankayan.enum";
import { NewsService } from "../services/news.services";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-gallery-detail",
  templateUrl: "./gallery-detail.component.html",
  styleUrls: ["./gallery-detail.component.css"],
  providers: [MediaService, NewsService],
})
export class GalleryDetailComponent implements OnInit {
  public programDetail: GalleryDetailModel;
  public galleryDetail: GalleryDetailModel[];
  display = "none";
  Url: any;
  id: string;
  programaddress: string;
  imageUrl = environment.imageUrl + "Program/Image/Thumbnail/Fr_";
  orgImageUrl = environment.imageUrl + "Program/Image/";
  pager: any = {};
  pagedItems: any[];
  totalPage: number;
  modelRequest: GalleryDetailRequestModel;

  indexModel = new PressReleaseFrontSearchModel();
  generalModel: InAugrationListModel;
  PressReleaseListModel: InAugrationListModel[];
  pressReleaseCategoryTypeEnum = PressReleaseCategoryTypeEnum;
  pressReleaseCMOPRCategoryEnum = PressReleaseCMOPRCategoryEnum;
  VIPCategoryEnum: VIPCategoryEnum;
  VIPPersonCategoryEnum: VIPPersonCategoryEnum;
  image: any;
  imageIndex: any;

  constructor(
    @Inject("BASE_URL") public baseUrl: string,
    private route: ActivatedRoute,
    private pagerService: PagerService,
    public translate: TranslateService,
    private mediaService: MediaService,
    public sanitizer: DomSanitizer,
    private newsService: NewsService
  ) {
    this.id = this.route.snapshot.paramMap.get("id");
    this.galleryDetail = [];
    this.pagedItems = [];
    this.modelRequest = new GalleryDetailRequestModel();

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.indexModel.EntryNumber = Number(params.get("id"));

      this.GetPressReleaseMegaEventDataById();
    });
  }
  setLang(lang: string) {
    this.translate.use(lang);
  }
  ngOnInit() {
    //this.getdata(this.id);
  }

  public getdata(id: string) {
    this.modelRequest.id = Number(id);
    this.mediaService.GalleryDetailsByIdLatest(this.modelRequest).subscribe(
      (success) => {
        if (success !== undefined && success !== null) {
          this.galleryDetail = success;
          this.totalPage = Number(this.galleryDetail.length);
          this.setPage(1);

          if (
            this.galleryDetail != null &&
            this.galleryDetail[0] != null &&
            this.galleryDetail[0] != undefined
          ) {
            this.programDetail = this.galleryDetail[0];
          }
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  openModalDialog(d: string) {
    this.display = "block";
    this.Url = d;
  }

  closeModalDialog() {
    this.display = "none";
  }

  setPage(page: number) {
    window.scroll(0, 0);
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    // get pager object from service
    this.pager = this.pagerService.getPager(this.galleryDetail.length, page);
    // get current page of items
    this.pagedItems = this.galleryDetail.slice(
      this.pager.startIndex,
      this.pager.endIndex + 1
    );
  }

  openImage(image: any) {
    this.image = image;
  }

  updateCarousel(index: number): void {
    this.imageIndex = index;
  }

  //Jankalyan APIS
  GetPressReleaseMegaEventDataById() {
    this.generalModel = undefined;
    let filterModel = new PressReleaseFrontSearchModel();
    filterModel.PageSize = 3;
    filterModel.IsBase64File = false;
    filterModel.EntryNumber = this.indexModel.EntryNumber;
    //filterModel.DepartmentCode = this.indexModel.DepartmentCode;

    this.newsService.GetPressReleaseList(filterModel).subscribe(
      (response) => {
        if (response.IsSuccess) {
          this.generalModel = response.Data.Data[0];
          console.log(this.generalModel, "generalModel");
          this.generalModel.GeneralDescription =
            this.sanitizer.bypassSecurityTrustHtml(
              this.generalModel.GeneralDescription
            );
        } else {
          this.generalModel = {} as InAugrationListModel;
        }
      },
      (error) => {
        this.generalModel = {} as InAugrationListModel;
        console.error(error);
      }
    );
  }
}

interface GalleryDetailModel {
  ProgramAddress: string;
  ProgramAddressHindi: string;
  ImageName: string;
  ProgramDate: string;
  ProgramSubcategory: string;
  ProgramSubcategoryhindi: string;
  ProgramSubjectHindi: string;
  ProgramSubject: string;
  ProgramDateHindi: string;
}
