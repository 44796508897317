export class EventRequestModel {
  page: number;
  text: string
  fromdate: string;
  todate: string;
  id: string;

}

export class EventLatestRequestModel {
  page: number;
  text: string;
  date: string;
  id: string;

}


export class ProgramMegaEventRequestModel
{
  page: number;
  text: string;
}
