import { EventService } from './../services/events.services';
import { Component, Inject, OnInit, AfterContentChecked } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { TranslateService } from '../translate.service';
import { environment } from '../../environments/environment';
import { MediaService } from '../services/media.services';
import { InAugrationListModel, PressReleaseFrontSearchModel, PressReleaseRequestModel } from '../Model/press-release-model.model';
import { ProgramDetailRequestModel } from '../Model/program-detail-model.model';
import { GalleryDetailRequestModel } from '../Model/gallery-detail-model.model';
import { NewsService } from '../services/news.services';
import { DomSanitizer } from '@angular/platform-browser';
import { DepartmentCodeEnum, PressReleaseCategoryTypeEnum, PressReleaseCMOPRCategoryEnum } from '../Enum/app-enums';
import { VIPCategoryEnum, VIPPersonCategoryEnum } from '../Enum/jankayan.enum';
import { FacebookService } from '../services/facebook.service';


@Component({
  selector: 'app-mega-event-detail',
  templateUrl: './mega-event-detail.component.html',
  styleUrls: ['./mega-event-detail.component.css'],
  providers: [EventService, MediaService, NewsService]

})


export class MegaEventDetailComponent implements OnInit, AfterContentChecked {
  megaEventProgramDetail: ProgramDetailModel;
  latestMegaEventProgramModels: LatestMegaEventProgramModel[];
  public programPressRelease: PressReleaseModel[];
  public galleryDetail: GalleryDetailModel[];
  public videos: VideoList[];
  id: string;
  isEnglish: boolean;

  imageVideoUrl = environment.imageUrl + 'Program/Video/VideoImages/App_';
  videoUrl = environment.imageUrl + 'Program/Video/';
  imageUrl = environment.imageUrl + 'Program/Image/Thumbnail/Fr_';
  orignalImageUrl = environment.imageUrl + 'Program/Image/';
  environmentUrl = environment.imageUrl + 'Program/Pdf/';
  totalPage: number;
  searchBox = '';
  date = '';
  currentPage = 1;
  modelRequest: PressReleaseRequestModel;
  modelGalleryDetailRequest: GalleryDetailRequestModel;
  modelProgramDetailRequest: ProgramDetailRequestModel;
  modelVideoRequest = new GalleryDetailRequestModel();

  indexModel = new PressReleaseFrontSearchModel();
  generalModel:InAugrationListModel;
  PressReleaseListModel: InAugrationListModel[];
  pressReleaseCategoryTypeEnum= PressReleaseCategoryTypeEnum;
  pressReleaseCMOPRCategoryEnum= PressReleaseCMOPRCategoryEnum;
  carouselData:any = [];
  isVisible = true;
  IsLoading = false;
  showFullText: boolean = false;

  constructor(
    private route: ActivatedRoute, 
    public router: Router,
    public facebookService:FacebookService, 
    public translate: TranslateService,
    private eventService: EventService, 
    private mediaService: MediaService,
    private newsService: NewsService,
    public sanitizer: DomSanitizer) {
    this.megaEventProgramDetail = new ProgramDetailModel();
    this.galleryDetail = [];
    this.programPressRelease = [];
    this.latestMegaEventProgramModels = [];
    this.videos = [];
    this.modelRequest = new PressReleaseRequestModel();
    this.modelProgramDetailRequest = new ProgramDetailRequestModel();
    this.modelGalleryDetailRequest = new GalleryDetailRequestModel();

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.indexModel.EntryNumber = Number(params.get("id"));
   
      this.GetPressReleaseMegaEventDataById(this.indexModel.EntryNumber);
      this.GetTopPressReleaseMegaEvent();
    });
  }
  setLang(lang: string) {
    this.translate.use(lang);
  }

  
  onSocial(title:any,type:any){
    // console.log(title.replace(/\n/g, " "))
    // title = title.replace(/\n/g, " ")
    if(type == 'twiiter') window.open(`https://twitter.com/intent/tweet?url=${environment.SOCIALMEDIA_SHARING_URL}${this.router.url}&text=${title}`,'popupWindow', 'width=500,height=600,scrollbars=yes');
    else window.open(`https://www.facebook.com/sharer/sharer.php?u=${environment.SOCIALMEDIA_SHARING_URL}${this.router.url}&hashtag=${title}`,'popupWindow', 'width=500,height=600,scrollbars=yes');
  }
  
  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get('id');
      this.getdata(this.id);
      this.getGalleryImagedata(this.id);
      this.getPressReleasedata(this.id);
      this.getLatestMegaEventProgram(this.currentPage, this.id);
      this.getVideoData(this.id);
    });
  }
  ngAfterContentChecked() {
    this.isEnglish = this.translate.currlang === 'en' ? true : false;
  }
  public getdata(id: string) {
    this.modelProgramDetailRequest.id = Number(id);
    this.eventService.GetProgramDetailByIdLatest(this.modelProgramDetailRequest).subscribe(success => {
      this.megaEventProgramDetail = success;
    }, error => {
      console.error(error);
    });
  }

  public getGalleryImagedata(id: string) {
    this.modelGalleryDetailRequest.id = Number(id);
    this.mediaService.GalleryDetailsByIdLatest(this.modelGalleryDetailRequest).subscribe(success => {
      if (success !== undefined && success !== null) {
        this.galleryDetail = success;
        this.totalPage = Number(this.galleryDetail.length);
        //this.setPage(1);
      }
    }, error => {
      console.error(error);
    });
  }

  public getLatestMegaEventProgram(page: number, id: string) {
    this.eventService.GetLatestMegaEventProgramList(page, this.searchBox, this.date, id).subscribe(success => {
      if (success !== undefined && success !== null) {
        this.latestMegaEventProgramModels = success;

      }
    }, error => {
      console.error(error);
    });
  }

  toggleText() {
    this.showFullText = !this.showFullText;
  }



  public getPressReleasedata(id: string) {
    this.modelRequest.id = Number(id);
    this.eventService.PressReleaseByIdLatest(this.modelRequest).subscribe(success => {
      if (success !== undefined && success !== null) {
        this.programPressRelease = success;

      }
    }, error => {
      console.error(error);
    });
  }


  public getVideoData(id: string) {
    this.modelVideoRequest.id = Number(id);
    this.mediaService.GetVideoListByProgramIdLatest(this.modelVideoRequest).subscribe(success => {
      if (success !== undefined && success !== null) {
        this.videos = success;
      }
    }, error => {
      console.error(error);
    });
  }

  public Redirecturl(id) {
    // alert(id);
    this.router.navigateByUrl('/mega-event-detail/' + id);
  }

  openCarousel(index:any){
    this.isVisible = false;
    setTimeout(() => this.isVisible = true, 0);
    this.carouselData.forEach((x:any,i:any)=>{
     if(index == i){
      x.active = true;
     }else{
      x.active = false;
     }
    })
    // console.log(this.carouselData,'this.carouselData')
  }

  onMediaPlaying(event, IsAudio: boolean) {
    // event.preventDefault();
    // if (this.currentPlayingVideo != undefined) {
    //   this.currentPlayingVideo.pause();
    // }
    // if (this.currentPlayingAudio != undefined) {
    //   this.currentPlayingAudio.pause();
    // }
    // if (IsAudio) {
    //   this.currentPlayingAudio = event.target;
    //   this.currentPlayingAudio.play();
    // }
    // else {
    //   this.currentPlayingVideo = event.target;
    //   this.currentPlayingVideo.play();
    // }
  }

  playVideo(event:any) {
    const video = event.target as HTMLVideoElement;
    if (video) {
      // console.log('Attempting to play video');
      if (video.paused) {
        video.play().then(() => {
          // console.log('Video is playing');
        }).catch(error => {
          console.error('Error playing video:', error);
        });
      } else {
        // console.log('Video is already playing');
      }
    } else {
      // console.error('Video element not found or not accessible');
    }
  }

  pauseVideo(event:any) {
    const video = event.target as HTMLVideoElement;
    if (video) {
      // console.log('Attempting to pause video');
      if (!video.paused) {
        video.pause();
        // console.log('Video is paused');
      } else {
        // console.log('Video is already paused');
      }
    } else {
      // console.error('Video element not found or not accessible');
    }
  }

  //Jankalyan APIS
  GetPressReleaseMegaEventDataById(id: number) {
    let filterModel = new PressReleaseFrontSearchModel();
    filterModel.IsBase64File = false;
    filterModel.EntryNumber = this.indexModel.EntryNumber;

    this.newsService.GetDIPRPressReleaseDetailById(id).subscribe((response) => {
      if (response.IsSuccess) {
        this.generalModel = response.Data[0];
        if(this.generalModel.HomePageImageUrl != "") this.generalModel.ImageAttachments.unshift(this.generalModel.HomePageImageUrl);
        this.generalModel.GeneralDescription = this.sanitizer.bypassSecurityTrustHtml(this.generalModel.GeneralDescription);
        this.generalModel.ImageAttachments.filter((x:any)=>{
          this.carouselData.push({ image: x, active: false })
        })
      }else{
        this.generalModel={} as InAugrationListModel;
      }
    }, (error) => {
      this.generalModel={} as InAugrationListModel;
      console.log(error);
    });
  }
  // GetPressReleaseMegaEventDataById() {
  //   this.generalModel=undefined;
  //   let filterModel = new PressReleaseFrontSearchModel();
  //   filterModel.PageSize = 3;
  //   filterModel.IsBase64File = false;
  //   filterModel.EntryNumber = this.indexModel.EntryNumber;
  //   //filterModel.DepartmentCode = this.indexModel.DepartmentCode;

  //   this.newsService.GetPressReleaseList(filterModel).subscribe((response) => {
  //     if (response.IsSuccess) {
  //       this.generalModel = response.Data.Data[0];
  //       this.generalModel.GeneralDescription = this.sanitizer.bypassSecurityTrustHtml(this.generalModel.GeneralDescription);
  //     }else{
  //       this.generalModel={} as InAugrationListModel;
  //     }
  //   }, (error) => {
  //     this.generalModel={} as InAugrationListModel;
  //     console.error(error);
  //   });
  // }

  GetTopPressReleaseMegaEvent() {
    this.PressReleaseListModel=undefined;
    let filterModel = new PressReleaseFrontSearchModel();
    filterModel.PageSize = 3;
    filterModel.OrderBy = 'CreatedDate';
    filterModel.OrderByAsc = 0;
    filterModel.IsBase64File = false;
    filterModel.DepartmentCode = DepartmentCodeEnum.CmoWebsiteCode;
    filterModel.CategoryCode = this.pressReleaseCategoryTypeEnum.PressReleaseCode;
    filterModel.VIPCategoryCode = VIPCategoryEnum.HCMCode;
    // filterModel.VIPPersonCode = VIPPersonCategoryEnum.ShriAshokGehlotCode;
    filterModel.VIPPersonCode = VIPPersonCategoryEnum.ShriBhajanLalSharmaCode;
    filterModel.CMOPRCategoryCode = this.pressReleaseCMOPRCategoryEnum.MegaEventCode;

    this.newsService.GetPressReleaseList(filterModel).subscribe((response) => {
      if (response.IsSuccess) {
        this.PressReleaseListModel = response.Data.Data;      
      }else{
        this.PressReleaseListModel=[];
      }
    }, (error) => {
      this.PressReleaseListModel=[];
      console.error(error);
    });
  }
}

export class LatestMegaEventProgramModel {
  ProgramId: number;
  ProgramCategoryId: number;
  ProgramSubCategoryId: number;
  ProgramDate: string;
  ProgramDistrictId: number;
  ProgramLocationId: number;
  ProgramAddress: string;
  ProgramAddressHindi: string;
  ProgramLanguage: string;
  ProgramSubject: string;
  ProgramSubjectHindi: string;
  ProgramSpeech: string;
  ProgramSpeechHindi: string;
  Keywords: string;
  KeywordsHindi: string;
  SpeechPdf: string;
  Pdf: string;
  IsActive: boolean;
  IsDeleted: boolean;
  ProgramStateId: number;
  programtime: string;
  UserId: string;
  IsShowLatest: string;
  KeyImage: string;
  FolderName: string;
  PopupImages: string;
  PdfEnglish: string;
  Status: string;
  District: string;
  ProgramCategory: string;
  ProgramCategoryhindi: string;
  ProgramSubCategoryhindi: string;
  ProgramSubCategory: string;
  ImageName: string;
  ProgramDateHindi: string;
  ProgramMainImage: string;
  TotalEvents: number;
}

export class ProgramDetailModel {
  ProgramId: number;
  ProgramCategoryId: number;
  ProgramSubCategoryId: number;
  ProgramDate: string;
  ProgramDistrictId: number;
  ProgramLocationId: number;
  ProgramAddress: string;
  ProgramAddressHindi: string;
  ProgramLanguage: string;
  ProgramSubject: string;
  ProgramSubjectHindi: string;
  ProgramSpeech: string;
  ProgramSpeechHindi: string;
  Keywords: string;
  KeywordsHindi: string;
  SpeechPdf: string;
  Pdf: string;
  IsActive: boolean;
  IsDeleted: boolean;
  ProgramStateId: number;
  ProgramTime: string;
  UserId: string;
  IsShowLatest: string;
  KeyImage: string;
  FolderName: string;
  PopupImages: string;
  PdfEnglish: string;
  Status: string;
  District: string;
  ProgramCategory: string;
  ProgramCategoryHindi: string;
  ProgramSubCategoryHindi: string;
  ProgramSubCategory: string;
  TotalImages: number;
  TotalAudio: number;
  ProgramDateHindi: string;
  TotalVideo: number;
  ProgramImage: string;
  ProgramMainImage: string;
}

interface GalleryDetailModel {

  ProgramAddress: string;
  ProgramAddressHindi: string;
  ImageName: string;
  ProgramDate: string;
  ProgramSubcategory: string;
  ProgramSubcategoryhindi: string;
  ProgramSubjecthindi: string;
  ProgramSubject: string;
  ProgramDateHindi: string;

}

export class PressReleaseModel {
  ProgramId: number;
  ProgramCategoryId: number;
  ProgramSubCategoryId: number;
  ProgramDate: string;
  ProgramDistrictId: number;
  ProgramLocationId: number;
  ProgramAddress: string;
  ProgramAddressHindi: string;
  ProgramLanguage: string;
  ProgramSubject: string;
  ProgramSubjectHindi: string;
  ProgramSpeech: string;
  ProgramSpeechHindi: string;
  Keywords: string;
  KeywordsHindi: string;
  SpeechPdf: string;
  Pdf: string;
  ProgramStateId: number;
  ProgramTime: string;
  UserId: string;
  IsShowLatest: string;
  KeyImage: string;
  FolderName: string;
  PdfEnglish: string;
  District: string;
  ProgramCategory: string;
  ProgramCategoryhindi: string;
  ProgramSubCategoryhindi: string;
  ProgramSubCategory: string;
  ProgramDateHindi: string;
  BannerImages: string;
}
export class VideoList {
  RowNumber: number;
  ProgramId: number;
  ProgramDate: string;
  video: string;
  videoimage: string;
  ProgramSubCategoryhindi: string;
  ProgramSubCategory: string;
  ProgramSubjectHindi: string;
  ProgramSubject: string;
  TotalVideos: number;
}

