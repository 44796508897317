import { environment } from '../../environments/environment';

export class AppSettings {

  // #region API End Points

  public static WEB_ENDPOINT = environment.WEB_ENDPOINT;
  public static API_ENDPOINT = environment.API_ENDPOINT;
  public static API_ENDPOINT_Jankalyan = environment.API_ENDPOINT_Jankalyan;
  public static API_ENDPOINT_RW_Jankalyan = environment.API_ENDPOINT_RW_Jankalyan;
  public static API_ENDPOINT_RajAdvt = environment.rajAdvtUrl;
  public static API_ENDPOINT_RajAdvtAPi = environment.rajAdvtApiUrl;
  public static API_ENDPOINT_CMRelief = environment.CM_Relief_Url;
  public static API_ENDPOINT_CMIS = environment.CMIS_Url;
  public static API_ENDPOINT_Raj_Sampark = environment.Raj_Sampark_Url;
  public static API_ENDPOINT_PHONE_BOOTH = environment.Phone_Booth_Url;

  // Events

  public static API_ALLPROGRAM_LIST_GET = 'Program/GetPrograms';
  public static API_PROGRAM_BANNER_IMAGE_LIST_GET = 'Program/GetProgramBannerList';
  public static API_PROGRAM_POPUP_IMAGE_LIST_GET = 'Program/GetProgramPopupImageList';

  public static API_ALLCALENDAREVENTSPROGRAM_LIST_GET = 'Program/GetCalendarEventPrograms';
  public static API_Base64_STRING_GET = 'Program/GetBase64strFromFilePath';
  public static API_GetFrontWebsiteUpdateInfo = "Program/GetFrontWebsiteUpdateInfo";
  public static API_PROGRAM_MegaEvent_LIST_GETLatest = 'Program/GetProgramMegaEventListLatest';
  public static API_LatestMegaEventProgran_List_Get = 'Program/GetLatestMegaEventProgramList';
  public static API_PROGRAM_LIST_Post = 'Program/GetProgramEventListingForFrontLatest';
  public static API_LATESTPROGRAM_LIST_POST = 'Program/GetLatestProgramEventListLatest';
  public static API_FILTERPROGRAM_LIST_POST = 'Program/GetFilterProgramListLatest';
  public static API_PROGRAM_DETAILS_POST = 'Program/GetProgramDetailLatest';
  public static API_PROGRAM_MegaEvent_LIST_POST = 'Program/GetProgramMegaEventListLatest';





  //KeyOfficial
  public static API_KEYOFFICIAL_LIST_GET = 'Program/GetKeyOfficial';

  //keyinitiatives
  public static API_KEYINITIATIVES_LIST_GET = 'Program/GetKeyInitiatives';

  //Minister
  public static API_STATEMINISTER_LIST_GET = 'Program/GetStateMinisters';
  public static API_CABINETMINISTER_LIST_GET = 'Program/GetCabinetMinisters';


  // WrieToCMO
  public static API_WRITETOCMO_POST = 'Home/WriteToCm';



  // Gallery
  public static API_GALLERY_PROGRAM_IMAGE_LIST_POST = 'Gallery/GetProgramImageListForFrontLatest';
  public static API_GALLERY_DETAILS_POST = 'Gallery/GetGalleryDetailLatest';
  public static API_GALLERY_LIST_POST = 'Gallery/GetProgramGalleryListForFrontLatest';







  // Speech
  public static API_SPEECH_LIST_GET = 'Speech/GetProgramSpeechListForFront';
  public static API_SPEECH_LIST_TOP2_GET = 'Speech/GetProgramSpeechListForFrontTop2';
  public static API_SPEECH_LISTING_GET = 'Speech/GetProgramspeechlistingForInnerPage';
  public static API_SPEECH_DETAILS_GET = 'Speech/GetSpeechProgramDetail';


  // Video
  public static API_VIDEO_LIST_GET = 'Video/GetProgramVideoListForFront';
  public static API_GET_ALL_VIDEO_LIST = 'Video/GetAllVideoList';
  public static API_GET_VIDEO_LIST_By_ProgramIdLatest = 'Video/GetVideoListByProgramIdLatest';



  // Achievement
  public static API_ACHIEVEMENT_LIST_GET = 'Achievement/GetActiveAchievements';
  public static API_ACHIEVEMENT_LIST_FRONT_GET = 'Achievement/GetAchievementsListForFront';
  public static API_ACHIEVEMENT_DETAILS_GET = 'Achievement/GetActiveAchievementsDetail';

  //Awards
  public static API_AWARDS_LIST_POST = 'Achievement/GetActiveAwardsListLatest';
  public static API_AWARDS_ACTIVE_DETAILS_POST = 'Achievement/GetActiveAwardDetailLatest';
  public static API_LATESTAWARDS_LIST_POST = 'Achievement/GetActiveLatestAwardsLatest';



  // Message
  public static API_MESSAGE_LIST_GET = 'Message/GetMessageList';
  public static API_MESSAGE_LIST_FRONT_GET = 'Message/GetProgramMessageListForFront';
  public static API_MESSAGE_DETAILS_LIST_GET = 'Message/GetMessageDetail';
  public static API_CMOMESSAGE_LIST_GET = 'Message/GetCmoMessageList';

  // News
  public static API_NEWS_NEWS_FRONT_GET = 'RecentNews/GetProgramNewsListForFront';
  public static API_NEWS_NEWS_GET = 'RecentNews/GetProgramNewsListForInner';
  public static API_PROGRAM_PRESSRELEASE_LIST_GET = 'Program/GetPressReleaseEvent';
  public static API_PROGRAM_PRESSRELEASEBYID_LIST_POST = 'Program/GetPressReleaseEventByIdLatest';
  public static API_NEWS_RECENT_NEWS_DETAILS_POST = 'RecentNews/GetProgramMessageDetailForInnerLatest';

  // Phone Booth
  public static API_PHONEBOOTH_LIST_EVETS = 'list_events/';
  public static API_PHONEBOOTH_IMAGES_BY_EVENTS = 'get_images_by_event/?event_name=';
  public static API_PHONEBOOTH_ADD_FACE = 'add_face/';
  public static API_PHONEBOOTH_SEARCH_FACE = 'search_faces/';
  public static API_PHONEBOOTH_DELETE_EVENT = 'delete_event/?event_name=';


  // #endregion

  // QuickLinks
  public static API_QuickLinks_POST = "Home/GetQuickLinksLatest"

  //Contact
  public static API_CONTACT_GET = 'Program/GetContactforHomePage';

  //Advertisement
  public static Api_Advertisement_Get = "https://jankalyan.rajasthan.gov.in/WEBAPI/WebServices/AdvertisementService/GetAllAdvertisementAndGovAchievment";


  static jankalyanApi = {
    award: {
      getAchievementListBySearchFilter: "/api/JankalyanHome/GetAchievementListBySearchFilter",
      GetAchievementDetailurl: '/api/JankalyanHome/GetById'
    },
    keyOfficial: {
      DptDepartmentContactUrl: '/PublicPortal/DepartmentWebsite/GetDepartmentContactDetail',
    },
    pressRelease: {
      PressReleaseListUrl: '/PublicPortal/DepartmentWebsite/GetPressReleaseByFilter',
      DiprPressReleaseListUrl: '/PublicPortal/DepartmentWebsite/GetDIPRPressReleaseByFilter',
      DIPRPressReleaseDetailUrl: "/PublicPortal/DepartmentWebsite/GetDIPRPressReleaseDetailById?id="
    },
    Achievement: {
      AchievementDepartmentCoutListUrl: 'api/JankalyanApp/GetDepartmentAchivement_DeptCatWiseCount/',
      AchievementDepartmentCoutWiseListUrl: 'api/JankalyanApp/GetDepartmentAchivement_DeptCatWiseList',
    },
    ContactUs: {
      DptDepartmentContactUrl: '/PublicPortal/DepartmentWebsite/GetDepartmentContactDetail',
    },
    Program: {
      GetPostersListurl: '/api/JankalyanHome/GetPostersList',
      GetAchievementCatDetailUrl: "/api/AchievementCategoryMaster/Get",
      GetAchievementSubCatDetailUrl: "/api/AchievementSubCategoryMaster/Get",
      GetSubmenuDetailUrl: '/PublicPortal/DepartmentWebsite/GetSubmenuDetail'
    },
    DepartmentProfile: {
      GetDepartmentProfileListurl: '/api/JankalyanHome/GetDepartmentProfileList',
      DepartmentDetailByDptCodeUrl: '/PublicPortal/DepartmentWebsite/GetDepartmentDetailByDptCode',
      AddPortalUserFeedbackCodeUrl: '/PublicPortal/DepartmentWebsite/AddPortalUserFeedback',
      SectorsUrl:"/api/CommonDropDown/AllDropDown"
    },
    CMProfile: {
      GetCMProfileDetailurl: 'api/JankalyanHome/GetCMProfile'
    },
    PhotoVideoUploader: {
      GetUploaderDataForFront: '/PublicPortal/DepartmentWebsite/GetUploaderDataForFront'
    },
    Menus: {
      MenuListForDepartmentUrl: '/PublicPortal/DepartmentWebsite/GetMenuListForDepartment'
    },
    FormerCM: {
      getAchievementListBySearchFilter: "/api/JankalyanHome/GetAchievementListBySearchFilter"
    },
    UpcomingCMEvents: {
      getAchievementListBySearchFilter: "/api/JankalyanHome/GetAchievementListBySearchFilter"
    },
    RegistrationUser:{
      RegisterUserUrl : "PublicPortal/AppUser/RegisterAppUser",
      RegisterUserV1Url : "PublicPortal/AppUser/RegisterAppUserV1",
      VerifyUserOtpUrl : "PublicPortal/AppUser/VerifyOtp",
      ResentUserOTPUrl : "PublicPortal/AppUser/ResentOTP"
    },
    CMLiveprogram:{
      GetLivePrograms : "/api/JankalyanHome/GetVideoList"
    },
    DepartmentPage: {
      DepartmentPageUrl : "/PublicPortal/DepartmentPages/GetDepartmentPageDetail"
    },
    VisitorCount:{
      VisitorCountUrl : "/PublicPortal/DepartmentWebsite/GetAndSetVisitorCount?DepartmentCode="
    },
    WebsitePolicies:{
      JankalyanCategoryDetailUrl : "/api/JANCategoryMaster/Get/",
      JankalyanEntryTypeListByCategoryUrl : "/api/JankalyanEntryTypeMaster/GetEntryTypeByCategory/"
    }
  }

  //#region <Common>
  static GetDDlUrl = 'api/CommonDropDown/AllDropDown?keys=';
  static PressReleaseDDLKey = "RadioStateDistrict";
  static DDLKeyForImportantDecision = 'ddlAdminDepartment,ddlDepartmentImportantDecision';
  //#endregion

  //#region  <InvitationRequest>
  static DDlKeyForHCMInvitation = "ddlDepartment,ddlDistrict,ddlDesignation,ddlHCMInvitationStatus,ddlMLAConstituency,ddlHCMInvitationPurpose,ddlHCMInvitationSenderType,ddlHCMInvitationMonth,ddlHCMInvitationInnerStatus,ddlHCMInvitationCategory,ddlHelipadMaster,ddlYear";
  static InvitationRequestAddUrl = "PublicPortal/HCMInvitation/Post";
  //#endregion

  //#region  <Ministers>
  static MLAConstituencyUrl ='/PublicPortal/DepartmentWebsite/GetMLAConstituency';
  //#endregion
}

