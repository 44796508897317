import { Component, OnInit } from '@angular/core';
import { TranslateService } from '../translate.service';

@Component({
  selector: 'app-chief-ministersof-raj',
  templateUrl: './chief-ministersof-raj.component.html',
  styleUrls: ['./chief-ministersof-raj.component.css']
})
export class ChiefMinistersofRajComponent implements OnInit {

  constructor(public translate: TranslateService) {
  }

  ngOnInit() {
  }

  setLang(lang: string) {
    this.translate.use(lang);
  }

}
