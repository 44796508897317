<!-- Rmove this class from div bcox it has cm sir image "breadcrumbs-page-img" -->

<div class="bg-scroll pos-rel breadcrumbs-page-img">
  <div class="container">
    <h1>{{'Achievement_Detail'| translate}}</h1>
  </div>
</div>
<main id="main">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
      <li class="breadcrumb-item"><a href="javascript:void(0)">{{'Media_CornerSubNav'| translate}}</a></li>
      <li class="breadcrumb-item"><a routerLink="/achivement-list">{{'Achievement'| translate}}</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{'Achievement_Detail'| translate}}</li>
    </ol>
  </nav>
  <div class="col-lg-12 mt-5">
    <h1 class="title text-center mb-1">
      <img src="assets/images/requered-doc.png" class="img-fluid" />
      <span *ngIf="this.achievementList?.length > 0">
        <h4>
          {{translate.currlang != 'hi' ?
          achievementDepartmentCategorymodel.CategoryName :
          achievementDepartmentCategorymodel.CategoryNameHindi }}
        </h4>
      </span>
    </h1>
  </div>

  <section class="program_list_detail">
    <div class="container">
      <div class="row">
        <div class="col-lg-12" *ngFor="let item of achievementList; let i=index">
          <div *ngIf="item.ImageFiles && item.ImageFiles.length>0" class="photo-gallery photo-gallery-new-design">
            <div class="d-flex flex-row flex-wrap my-flex-container justify-content-center photos">
              <div class="photo-gallery-bx align-items-center d-flex justify-content-center"
                *ngFor="let image of item.ImageFiles">
                <a [href]="image" onerror="this.src='assets/images/default.png'" data-lightbox="photos" title="">
                  <img class="img-fluid rounded" [src]="image" title="" alt=""
                    onerror="this.src='assets/images/default.png'">
                </a>
                <p class="mb-0 mt-2 w-100 d-block text-right text-muted" style="font-size: 12px;">
                  {{item.ImageFiles.length}} Image</p>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="m-0"> {{translate.currlang == 'hi' ? item.GeneralDetail : item.GeneralDetail }}</h4>
            <div>
              <div class="col-lg-12 right d-flex align-items-center">
                <div class="page-file-download page-file-download-pos-static ml-3" *ngIf="item?.PdfFIleName">
                  <a title="{{'ShowPDF_txt' | translate}}" href="{{ item?.PdfFIleName }}" target="_blank"
                    class="page-file-download-btn">
                    <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <hr>
  
          <div class="about-yajona-text">
            <ul>
              <li>
                <p style="text-align: justify !important"
                  [innerHTML]="translate.currlang == 'hi'  ? (item?.AchievementHindi) : item?.AchievementHindi">
                </p>
              </li>
  
            </ul>
          </div>
        </div>

        <!-- <div class="col-xl-9 wow fadeInLeft">
          <div class="program_detail_left">

            

            <div class="row" *ngIf="achivementDetail">
              <div class="col-sm-12">
                <h3> {{this.translate.currlang == 'hi' ? achivementDetail.AchievementHindi : achivementDetail.Achievement}}</h3>
                <div class="text-muted"><i class="fa fa-calendar"></i> {{this.translate.currlang == 'hi' ? achivementDetail.AchievementDateHindi : achivementDetail.AchievementDate}}</div>


                <div class="detail_icon">


                  <a *ngIf="achivementDetail.PdfFIleName != null && achivementDetail.PdfFIleName != ''" href="{{environmentUrl}}{{achivementDetail.PdfFIleName}}" rel="noopener noreferrer" target="_blank" title="PDF"> <i class="fa fa-file-pdf-o" aria-hidden="true"></i> Read more <span class="fa fa-angle-right" aria-hidden="true"></span> </a>

                </div>
              </div>
              <object *ngIf="achivementDetail.PdfFIleName||'' != ''" width="100%" height="650px">
                <iframe title="PDF" [src]='PDFFile' type="application/pdf" frameborder="0" width="100%" height="650px"></iframe>
              </object>
            </div>
          </div>
        </div> -->

        <!-- <div class="col-xl-3  wow fadeInRight">
          <div class="program_detail_right">


            <div class="program_detail_right_box mb-4" *ngIf="achivementLatest!=undefined && achivementLatest.length>0">
              <div class="section-header">
                <h2>{{'Latest_Achievement'| translate}}</h2>
              </div>
              <div class="media" *ngFor="let item of achivementLatest let i = index">
                <div class="align-self-center"> <img class="mr-2" src="assets/img/Achievement_Icon.png" onError="this.src='../../assets/images/program-no-img.png'" alt=""> </div>
                <div class="media-body align-self-center">
                  <a routerLink="/achivementdetail/{{item.AchievementId}}">
                    {{this.translate.currlang == 'hi' ? item.AchievementHindi : item.Achievement}}
                  </a>
                </div>
              </div>

            </div>


          </div>
        </div> -->
      </div>
    </div>
  </section>
</main>








