<div class="breadcrumbs-page-img">
    <div class="banner-hight-2">
        <h1>{{'Home_OUR_SPEECH'| translate}}</h1>
        <div class="inner-page-banner">
            <img src="assets/images/cmo-banner.png" alt="banner" class="cmo-banner">
        </div>
    </div>
</div>

<main id="main">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
            <!-- <li class="breadcrumb-item"><a href="javascript:void(0)">{{'Media_CornerSubNav'| translate}}</a></li> -->
            <li class="breadcrumb-item active" aria-current="page">{{translate.currlang == 'hi' ?
                pageTitle?.NameHindi : pageTitle?.NameEnglish}}</li>
        </ol>
    </nav>

    <!-- <section class="inner-p age page-about page-editor-entry-design" *ngIf="model">
        <div class="container">
            <div class="row">
                <br />
                <div *ngIf="model?.DescriptionEng" class="col-lg-12">
                    <div class="about-yajona-text">
                        <p style="text-align: justify !important" [innerHTML]="translate.currlang == 'hi'
                ? (model?.DescriptionHindi ? model?.DescriptionHindi : model?.DescriptionEng)
                      : model?.DescriptionEng"></p>
                    </div>
                </div>
            </div>
        </div>
    </section> -->

    <section class="profile-block"  *ngIf="model" [innerHTML]="translate.currlang == 'hi'
    ? (model?.DescriptionHindi ? model?.DescriptionHindi : model?.DescriptionEng)
          : model?.DescriptionEng">
    </section>
</main>