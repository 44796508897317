import { BaseService } from './../services/baseservice';
import { Dictionary } from './../common/dictionary';
import { Injectable } from '@angular/core';
import { AppSettings } from './../common/AppSettings';
import { Observable } from 'rxjs';

@Injectable()
export class ContactService {

  public constructor(private httpService: BaseService) {

  }

  GetContactForFront() {
    return this.httpService.get(AppSettings.API_ENDPOINT + AppSettings.API_CONTACT_GET);
  }

}
