import { EventService } from './../services/events.services';
import { Component, Inject, OnInit } from '@angular/core';
import { PagerService } from '../pager.service';
import { environment } from '../../environments/environment';
import { TranslateService } from '../translate.service';
import { timeInterval } from 'rxjs/operators';
import { InAugrationListModel, PressReleaseFrontSearchModel } from '../Model/press-release-model.model';
import { NewsService } from '../services/news.services';
import { DepartmentCodeEnum } from '../Enum/app-enums';

@Component({
  selector: 'app-home-press-release',
  templateUrl: './home-press-release.component.html',
  styleUrls: ['./home-press-release.component.css'],
  providers: [NewsService]
})
export class HomePressReleaseComponent implements OnInit {

  filterModel = new PressReleaseFrontSearchModel();
  generalModel: InAugrationListModel[];
  IsLoading = true;
  totalRecords = 0;
  categoryCode: number = 0;

  public programPressRelease: PressReleaseModel[] = [];
  public programPressReleaseHindi: PressReleaseModel[] = [];
  imageUrl = environment.imageUrl + 'Program/Image/Thumbnail/Fr_';
  constructor(@Inject('BASE_URL') public baseUrl: string,
    public readonly translate: TranslateService, private eventService: EventService,
    private newsService: NewsService) {
    //  this.programPressRelease = [];
  }
  ngOnInit() {
    //this.getData();
    this.GetPressReleaseData();
  
  }

  //setLang(lang: string) {
  //  debugger;
  //  this.translate.use(lang);
  //}
  // public getData() {
  //   this.eventService.GetPressReleaseList().subscribe(success => {
  //     for (var i = 0; i < 5; i++) {
  //      // let prs = new PressReleaseModel();
  //       this.programPressRelease.push(success[i]);
  //       this.programPressReleaseHindi.push(success[i]);
  //     }
     
  //   }, error => {
  //     console.error(error);
  //   });

  // }

    //Janklayan API
    GetPressReleaseData() {
      this.filterModel.IsBase64File = false;
      this.filterModel.CategoryCode = 36;
      this.filterModel.OrderBy = "PressreleaseDate";
      this.IsLoading = true;
      this.filterModel.DepartmentCode = DepartmentCodeEnum.CmoWebsiteCode;;
  
      this.newsService.GetPressReleaseList(this.filterModel).subscribe((response) => {
        if (response.IsSuccess) {
          this.generalModel = response.Data.Data as InAugrationListModel[];
          this.generalModel.map((item)=>{
            if(item.PressReleaseDateHindi){
              item.PressReleaseDateHindi = item.PressReleaseDateHindi.split('-') as any
            }
          })
          //   if (!this.filterModel.IsPostBack) {
          this.totalRecords = response.Data.TotalRecords;
          //   }
          // this.posterUpdatePageConfig();
        }
        this.IsLoading = false;
      }, (error) => {
        console.error(error);
      });
    }
}

export class PressReleaseModel {
  ProgramId: number;
  ProgramCategoryId: number;
  ProgramSubCategoryId: number;
  ProgramDate: string;
  ProgramDistrictId: number;
  ProgramLocationId: number;
  ProgramAddress: string;
  ProgramAddressHindi: string;
  ProgramLanguage: string;
  ProgramSubject: string;
  ProgramSubjectHindi: string;
  ProgramSpeech: string;
  ProgramSpeechHindi: string;
  Keywords: string;
  KeywordsHindi: string;
  SpeechPdf: string;
  Pdf: string;
  ProgramStateId: number;
  ProgramTime: string;
  UserId: string;
  IsShowLatest: string;
  KeyImage: string;
  FolderName: string;
  PdfEnglish: string;
  District: string;
  ProgramCategory: string;
  ProgramCategoryhindi: string;
  ProgramSubCategoryhindi: string;
  ProgramSubCategory: string;
  ProgramDateHindi: string;
  BannerImages: string;
}

