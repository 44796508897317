<!-- Rmove this class from div bcox it has cm sir image "breadcrumbs-page-img" -->
<div class="bg-scroll pos-rel breadcrumbs-page-img">
    <div class="container">
        <h1 *ngIf="pageTitle">{{ translate.currlang=='en' ? pageTitle?.NameEnglish : pageTitle?.NameHindi}}</h1>
        <h1 *ngIf="!pageTitle">{{'Photo_Gallery'| translate}}</h1>

    </div>
</div>
<main id="main">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0)">{{'Media_CornerSubNav'| translate}}</a></li>
            <li *ngIf="pageTitle" class="breadcrumb-item active" aria-current="page"> {{ translate.currlang=='en' ?
                pageTitle?.NameEnglish : pageTitle?.NameHindi}}</li>
            <li *ngIf="!pageTitle" class="breadcrumb-item active" aria-current="page">{{'Photo_Gallery'| translate}}
            </li>
        </ol>
    </nav>
    <section class="gallery_page">
        <div class="container">
            <div class="row mb-4">
                <div class='col-sm-6 col-xs-6 col-md-4 col-lg-3 mb-4' *ngFor="let items of posterModel let i = index">
                    <div class="card wow"
                        [ngClass]="[i%3 <1 ? 'fadeInLeft' : i%3 <2? 'fadeInUp':i%3 <3? 'fadeInRight':'fadeInLeft' ]">

                        <a *ngIf="!isShowDownload" [href]="items.ImagePath" data-lightbox="photos"
                            title="{{this.translate.currlang=='en'? items.Achievement:items.AchievementHindi}}">
                            <div class="program-img-bx">
                                <!-- <img src="{{items.ThumbnailPath}}" onError="this.src='../../assets/images/gallery-no-img.png'" alt="" class="img-fluid"> -->
                                <img src="{{items.ImagePath}}"
                                    onError="this.src='../../assets/images/gallery-no-img.png'" alt=""
                                    class="img-fluid">
                            </div>
                        </a>
                        <a *ngIf="isShowDownload"
                            title="{{this.translate.currlang=='en'? items.Achievement:items.AchievementHindi}}">
                            <div class="program-img-bx">
                                <img src="{{items.ImagePath}}"
                                    onError="this.src='../../assets/images/gallery-no-img.png'" alt=""
                                    class="img-fluid">
                            </div>
                        </a>
                        <button *ngIf="isShowDownload" (click)="getImagesToDownload(items.ImageFiles)"
                            class="btn-img-download" title="download image"> <i class="fa fa-download"></i></button>

                        <div class="gallery_caption">
                            <h3
                                *ngIf="(this.translate.currlang == 'hi' && items.Achievement.length<=50 )||(this.translate.currlang != 'hi' && items.AchievementHindi.length<=50)">
                                {{this.translate.currlang == 'hi' ? items.Achievement : items.AchievementHindi}}</h3>
                            <h3
                                *ngIf="(this.translate.currlang == 'hi' && items.Achievement.length>50 )||(this.translate.currlang != 'hi' && items.AchievementHindi.length>50)">
                                {{(this.translate.currlang == 'hi' ? items.Achievement :
                                items.AchievementHindi)|slice:0:50}}...</h3>
                            <!-- <div class="date">{{this.translate.currlang == 'hi' ? items.AchievementDateHindi :
                                items.AchievementDate | date : 'd MMM, y'}}</div> -->
                            <div class="date">{{this.translate.currlang == 'hi' ? (items.AchievementDateHindi ?
                                items.AchievementDateHindi :(items.ModifiedDate? (items.ModifiedDate | date : 'd MMM,
                                y') :(items.CreatedDate | date : 'd MMM, y')
                                ))
                                :
                                (items.AchievementDate ? (items.AchievementDate | date : 'd MMM, y')
                                :(items.ModifiedDate? (items.ModifiedDate | date : 'd MMM, y')
                                :(items.CreatedDate| date : 'd MMM, y') ) )}}</div>
                        </div>
                    </div>
                </div>
                <nav aria-label="Page navigation example" class="w-100 text-center mt-4" *ngIf="totalRecords > 16">
                    <ul class="pagination d-inline-flex" *ngIf="pager.pages && pager.pages.length">
                        <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                            <a class="page-link" (click)="GetPosterData(1)">{{'First'| translate}}</a>
                        </li>
                        <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                            <a class="page-link" (click)="GetPosterData(pager.currentPage - 1)">{{'Previous'|
                                translate}}</a>
                        </li>
                        <li class="page-item" *ngFor="let page of pager.pages"
                            [ngClass]="{active:pager.currentPage === page}">
                            <a class="page-link" (click)="GetPosterData(page)">{{page}}</a>
                        </li>
                        <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                            <a class="page-link" (click)="GetPosterData(pager.currentPage + 1)">{{'Next'|
                                translate}}</a>
                        </li>
                        <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                            <a class="page-link" (click)="GetPosterData(pager.totalPages)">{{'Last'| translate}}</a>
                        </li>
                    </ul>
                </nav>
                <!-- <ng-template #other_content>Record Not Found</ng-template> -->
            </div>
            <div class="row" *ngIf="posterModel && posterModel.length == 0">
                <div class="no-record">
                    {{'No_Record_Found'| translate}}
                </div>
            </div>
        </div>
    </section>
</main>