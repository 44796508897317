import { NewsService } from './../services/news.services';
import { Component, OnInit, Inject, AfterContentChecked } from '@angular/core';
import { TranslateService } from '../translate.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { EventService } from './../services/events.services';
import { MediaService } from '../services/media.services';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { RecentnewsRequestModel } from '../Model/recentnews-model.model';
import { GalleryDetailRequestModel } from '../Model/gallery-detail-model.model';
import { IndexModel } from '../Model/awards-request-model.model';
import { InAugrationListModel, PressReleaseFrontSearchModel, PressReleaseModel } from '../Model/press-release-model.model';
import { DepartmentCodeEnum, PressReleaseCategoryTypeEnum } from '../Enum/app-enums';
import { VIPCategoryEnum, VIPPersonCategoryEnum } from '../Enum/jankayan.enum';
import { CommonService } from '../services/common.service';
import { LookupEnum } from '../Enum/lookup-type.enum';


@Component({
  selector: 'app-recentnewsdetail',
  templateUrl: './recentnewsdetail.component.html',
  styleUrls: ['./recentnewsdetail.component.css'],
  providers: [NewsService, EventService, MediaService]
})
export class RecentnewsdetailComponent implements OnInit, AfterContentChecked {
  programDetail: RecentNewsDetailModel;
  //latestEventProgramModels: LatestEventPressReleaseModel[];
  public programPressRelease: PressReleaseModel[];
  public galleryDetail: GalleryDetailModel[];
  imageUrl = environment.imageUrl + 'Program/ProgramMainImage/';
  id: string;
  isEnglish: boolean;
  pdfRootPath: string = environment.imageUrl + 'Program/Pdf/';
  pdfPath_ENG: SafeResourceUrl;
  pdfPath_HIN: SafeResourceUrl;
  // pdfPath_ENG: string;
  // pdfPath_HIN: string;
  totalPage: number;
  searchBox = '';
  date = '';
  currentPage = 1;
  modelRequest: RecentnewsRequestModel;
  modelGalleryRequest: GalleryDetailRequestModel;
  generalModel:InAugrationListModel;
  PressReleaseListModel: InAugrationListModel[];
  indexModel = new PressReleaseFrontSearchModel();
  pageLink: string;
  IsLoading = true;
  // isCodeOfConduct: boolean = true;
  isCodeOfConduct: boolean = false;
  carouselData:any = [];
  isVisible = true;
  isSpecialrelease = false;
  galleryDetails:any=[]
  showFullText: boolean = false;

  constructor(@Inject('BASE_URL') public baseUrl: string,
    private route: ActivatedRoute, 
    private router: Router, 
    public translate: TranslateService, 
    private newsService: NewsService, 
    public sanitizer: DomSanitizer, 
    private eventService: EventService, 
    private mediaService: MediaService,
    public _commonService: CommonService) {
    this.isCodeOfConduct = this._commonService.GetIsCodeOfConduct();
    this.programDetail = new RecentNewsDetailModel();
    this.modelRequest = new RecentnewsRequestModel();
    this.modelGalleryRequest = new GalleryDetailRequestModel();
    //this.latestEventProgramModels = [];
    this.programPressRelease = [];
    this.galleryDetail = [];
  }
  setLang(lang: string) {
    this.translate.use(lang);
  }
  ngOnInit() {
    this.isSpecialrelease = this.router.url.includes("/special-release-detail");
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get('id');
      //this.getdata(this.id);
      this.getGalleryImagedata(this.id);
      //this.getPressReleasedata(this.id, 3);
      this.GetPressReleaseDataFromJankalyan();
      if(this.isSpecialrelease) this.GetsuccessStoriesdata();
      else this.GetTopPressReleaseFromJanklayan();
      // this.getLatestProgram(this.currentPage, this.id);
    });
  }

  openCarousel(index:any){
    this.isVisible = false;
    setTimeout(() => this.isVisible = true, 0);
    this.carouselData.forEach((x:any,i:any)=>{
     if(index == i){
      x.active = true;
     }else{
      x.active = false;
     }
    })
    // console.log(this.carouselData,'this.carouselData')
  }

  onSocial(title:any,type:any){
    console.log(title.replace(/\n/g, ""))
    title = title.replace(/\n/g, "")
    if(type == 'twiiter') window.open(`https://twitter.com/intent/tweet?url=${environment.SOCIALMEDIA_SHARING_URL}${this.router.url}&text=${title}`,'popupWindow', 'width=500,height=600,scrollbars=yes');
    else window.open(`https://www.facebook.com/sharer/sharer.php?u=${environment.SOCIALMEDIA_SHARING_URL}${this.router.url}&hashtag=${encodeURIComponent(title)}`,'popupWindow', 'width=500,height=600,scrollbars=yes');
  }

  toggleText() {
    this.showFullText = !this.showFullText;
  }

  
  ngAfterContentChecked() {
    this.isEnglish = this.translate.currlang === 'en' ? true : false;

  }

  GetPressReleaseDataFromJankalyan() {
    this.generalModel=undefined;
    let filterModel = new PressReleaseFrontSearchModel();
    filterModel.PageSize = 3;
    filterModel.IsBase64File = false;
    filterModel.EntryNumber = Number(this.id);

    this.newsService.GetPressReleaseList(filterModel).subscribe((response) => {
      if (response.IsSuccess) {
        this.generalModel = response.Data.Data[0];
        if(this.generalModel.HomePageImageUrl != "") this.generalModel.ImageAttachments.unshift(this.generalModel.HomePageImageUrl);
        this.generalModel.GeneralDescription = this.sanitizer.bypassSecurityTrustHtml(this.generalModel.GeneralDescription);
        this.generalModel.ImageAttachments.filter((x:any)=>{
          this.carouselData.push({ image: x, active: false })
        })
      }else{
        this.generalModel={} as InAugrationListModel;
      }
    }, (error) => {
      this.generalModel={} as InAugrationListModel;
      console.error(error);
    });
  }

  GetTopPressReleaseFromJanklayan() {
    this.PressReleaseListModel=undefined;
    let filterModel = new PressReleaseFrontSearchModel();
    filterModel.PageSize = 3;
    filterModel.OrderBy = 'CreatedDate';
    filterModel.OrderByAsc = 0;
    filterModel.IsBase64File = false;
    filterModel.CategoryCode = PressReleaseCategoryTypeEnum.PressReleaseCode;
    filterModel.DepartmentCode = DepartmentCodeEnum.CmoWebsiteCode
    filterModel.VIPCategoryCode = VIPCategoryEnum.HCMCode;
    // filterModel.VIPPersonCode = VIPPersonCategoryEnum.ShriAshokGehlotCode;
    filterModel.VIPPersonCode = VIPPersonCategoryEnum.ShriBhajanLalSharmaCode;
    this.IsLoading = true;

    this.newsService.GetPressReleaseList(filterModel).subscribe((response) => {
      if (response.IsSuccess) {
        this.PressReleaseListModel = response.Data.Data;       
      }else{
        this.PressReleaseListModel=[];
      }
      this.IsLoading = false;
    }, (error) => {
      this.PressReleaseListModel=[];
      console.error(error);
    });
  }

  GetsuccessStoriesdata() {
    // debugger;
    this.PressReleaseListModel=undefined;
    let filterModel = new PressReleaseFrontSearchModel();
    // filterModel.Page = page;
    filterModel.PageSize = 3;
    filterModel.IsBase64File = false;
    filterModel.OrderBy = "PressreleaseDate";
    // filterModel.VIPCategoryCode = VIPCategoryEnum.HCMCode;
    filterModel.PressReleaseLevelCode = LookupEnum.PressReleaseLevelCode_For_State;
    if (filterModel.DepartmentCode == 0 && filterModel.CategoryCode == 0 && filterModel.SubCategoryCode == 0) {
      filterModel.CategoryCode = PressReleaseCategoryTypeEnum.SpecialReleaseCode;
      // filterModel.VIPPersonCode = VIPPersonCategoryEnum.ShriBhajanLalSharmaCode;
      // filterModel.DepartmentCode = DepartmentCodeEnum.CmoWebsiteCode;
    }
    this.IsLoading = true;
    this.newsService.GetPressReleaseList(filterModel).subscribe((response) => {
      if (response.IsSuccess) {
        this.IsLoading = response.Data.Data.length >= 0 ? false : true;
        this.PressReleaseListModel = response.Data.Data
      }else{
        this.IsLoading = false;
      }
    }, (error) => {
      console.error(error);
    });
  }
  
  openAttachmentInNewTabl(url) {
    window.open(url, "_blank");
  }

  onMediaPlaying(event, IsAudio: boolean) {
    // event.preventDefault();
    // if (this.currentPlayingVideo != undefined) {
    //   this.currentPlayingVideo.pause();
    // }
    // if (this.currentPlayingAudio != undefined) {
    //   this.currentPlayingAudio.pause();
    // }
    // if (IsAudio) {
    //   this.currentPlayingAudio = event.target;
    //   this.currentPlayingAudio.play();
    // }
    // else {
    //   this.currentPlayingVideo = event.target;
    //   this.currentPlayingVideo.play();
    // }
  }

  playVideo(event:any) {
    const video = event.target as HTMLVideoElement;
    if (video) {
      // console.log('Attempting to play video');
      if (video.paused) {
        video.play().then(() => {
          // console.log('Video is playing');
        }).catch(error => {
          console.error('Error playing video:', error);
        });
      } else {
        // console.log('Video is already playing');
      }
    } else {
      // console.error('Video element not found or not accessible');
    }
  }

  pauseVideo(event:any) {
    const video = event.target as HTMLVideoElement;
    if (video) {
      // console.log('Attempting to pause video');
      if (!video.paused) {
        video.pause();
        // console.log('Video is paused');
      } else {
        // console.log('Video is already paused');
      }
    } else {
      // console.error('Video element not found or not accessible');
    }
  }


  public getdata(id: string) {
    
    this.modelRequest.id = Number(id);
    this.newsService.GetProgramMessageDetailForInnerLatest(this.modelRequest).subscribe(success => {
      if (success !== undefined && success !== null) {

        this.programDetail = success;

        if (this.programDetail.PdfEnglish != undefined) {
          this.pdfPath_ENG = this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfRootPath + this.programDetail.PdfEnglish);
        }

        if (this.programDetail.Pdf != undefined) {
          this.pdfPath_HIN = this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfRootPath + this.programDetail.Pdf);
        }
      }
    }, error => {
      console.error(error);
    });
  }



  public getGalleryImagedata(id: string) {
    this.modelGalleryRequest.id = Number(id);
    this.mediaService.GalleryDetailsByIdLatest(this.modelGalleryRequest).subscribe(success => {
      if (success !== undefined && success !== null) {
        this.galleryDetail = success;
        this.totalPage = Number(this.galleryDetail.length);
        //this.setPage(1);
      }
    }, error => {
      console.error(error);
    });
  }

  //public getLatestProgram(page: number, id: string) {
  //  this.eventService.GetLatestProgramEventList(page, this.searchBox, this.date,this.id).subscribe(success => {
  //    if (success !== undefined && success !== null) {
  //      this.latestEventProgramModels = success;

  //    }
  //  }, error => {
  //    console.error(error);
  //  });
  //}


  // public getPressReleasedata(id: string, records: number) {
  //   this.eventService.GetPressReleaseList().subscribe(success => {
  //     if (success !== undefined && success !== null) {
  //       // //;
  //       this.programPressRelease = success;
  //       this.programPressRelease.splice(this.programPressRelease.findIndex(x => x.ProgramId == Number(id)), 1);
  //       this.programPressRelease.splice(records, this.programPressRelease.length - records);
  //     }
  //   }, error => {
  //     console.error(error);
  //   });
  // }

  //public getBase64EnglishString(pdffilePath: string, isEng: boolean = true) {
  //  this.eventService.GetBase64StringbyPath(pdffilePath).subscribe(success => {

  //    if (success !== undefined && success !== null && success["file"] != '') {
  //      if (isEng) {
  //        this.pdfPath_ENG = this.sanitizer.bypassSecurityTrustResourceUrl(success["file"]);
  //      }
  //      else {
  //        this.pdfPath_HIN = this.sanitizer.bypassSecurityTrustResourceUrl(success["file"]);
  //      }
  //    }
  //    else {
  //      if (isEng) {
  //        this.pdfPath_ENG = null;
  //      }
  //      else {
  //        this.pdfPath_HIN = null;
  //      }
  //    }

  //  }, error => {
  //    console.error(error);
  //  });
  //}

}

class RecentNewsDetailModel {
  ProgramId: number;
  ProgramCategoryId: number;
  ProgramSubCategoryId: number;
  ProgramDate: string;
  ProgramDistrictId: number;
  ProgramLocationId: number;
  ProgramAddress: string;
  ProgramAddressHindi: string;
  ProgramLanguage: string;
  ProgramSubject: string;
  ProgramSubjectHindi: string;
  ProgramSpeech: string;
  ProgramSpeechHindi: string;
  Keywords: string;
  KeywordsHindi: string;
  SpeechPdf: string;
  Pdf: string;
  isactive: boolean;
  Isdeleted: boolean;
  programstateid: number;
  programtime: string;
  userid: string;
  IsShowLatest: string;
  KeyImage: string;
  foldername: string;
  PopupImages: string;
  PdfEnglish: string;
  status: string;
  district: string;
  ProgramCategory: string;
  ProgramCategoryhindi: string;
  ProgramSubCategoryhindi: string;
  ProgramSubCategory: string;
  TotalVideo: number;
}


interface GalleryDetailModel {

  ProgramAddress: string;
  ProgramAddressHindi: string;
  ImageName: string;
  ProgramDate: string;
  ProgramSubcategory: string;
  ProgramSubcategoryhindi: string;
  ProgramSubjecthindi: string;
  ProgramSubject: string;
  ProgramDateHindi: string;

}


