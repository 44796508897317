import { WriteToCmService } from "./../services/writetocm.services";
import {
  Component,
  OnInit,
  Inject,
  AfterViewChecked,
  ViewChild,
} from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, NgForm, Validators } from "@angular/forms";
import { TranslateService } from "../translate.service";
import { CommonService } from "../services/common.service";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { LoadingService } from "../services/loading.service";

@Component({
  selector: "app-writetocm",
  templateUrl: "./writeToCM.component.html",
  styleUrls: ["./writeToCM.component.css"],
  providers: [WriteToCmService,LoadingService],
})
export class WriteToCMComponent implements OnInit, AfterViewChecked {
  buttonText = "Hindi";
  writeToCmForm: NgForm;
  @ViewChild("writeToCmForm") currentForm: NgForm;
  isValid = false;
  isError = false;
  forceValidationCheck = false;
  model: WriteToCmModel;
  Purpose: PurposeDropDownModel[];
  msgEnglish: boolean;
  DepartmentList: DepartmentModel[];
  headers: HttpHeaders;
  isButtonDisabled: boolean = false;
  isResendOTP: boolean = true;
  uniqueID:any;
  resendOtpBool=false;
  PDFFile: SafeResourceUrl;
  selectedFile:any;
  captchaText: any = [];
  isCaptchaValidated: boolean = false;
  file:any;
  regerateCaptchBtn = false;
  registerData:any=[];
  // List of allowed file types
  allowedFileTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'application/x-rar-compressed', 'application/zip', 'audio/mpeg', 'video/mp4'];
  // Max file size in bytes (5MB)
  maxFileSize = 5 * 1024 * 1024;
  fileError = '';
  isfileValid = true;
  ValidateNumber: UntypedFormGroup = this.fb.group({
    Mobile: [
      undefined,
      Validators.compose([
        Validators.required,
        Validators.maxLength(12),
        Validators.minLength(10),
      ]),
    ],
  });
  OTPControl = new UntypedFormControl("", Validators.compose([
    Validators.required,
    Validators.maxLength(6),
    Validators.minLength(4),
  ]));
  FormGroup: UntypedFormGroup = this.fb.group({
    Name: [undefined, Validators.required],
    description: [undefined, Validators.required],
    file: [undefined],
    captcha: [undefined, Validators.required],
  });

  constructor(
    public http: HttpClient,
    @Inject("BASE_URL") public baseUrl: string,
    private writeToCmService: WriteToCmService,
    public translate: TranslateService,
    private fb: UntypedFormBuilder,
    public readonly _commonService: CommonService,
    public sanitizer: DomSanitizer,
    private loadingService: LoadingService
  ) {
    this.headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    this.model = new WriteToCmModel();
    this.Purpose = [];
    this.DepartmentList = [];
    this.msgEnglish = this.translate.currlang == "en" ? true : false;
    this.buttonText = this.translate.currlang == "en" ? "Hindi" : "अंग्रेज़ी";
  }

  ngOnInit() {
    // this.GetPurposeTypeList();
    // this.GetActiveDepartmentList();
    this.regerateCaptchBtn = false;
    document.getElementById('filedSet2').setAttribute('disabled','true');
    this.generateCaptcha();
  }

  ngAfterViewChecked() {
    // this.formChanged();
  }
  changeLanguage() {
    this.msgEnglish = !this.msgEnglish;
    this.buttonText = this.msgEnglish ? "Hindi" : "अंग्रेज़ी";
  }

  onGenerateOtp(isResend:any) {
    this.OTPControl = new UntypedFormControl("", Validators.compose([
      Validators.required,
      Validators.maxLength(6),
      Validators.minLength(4),
    ]));
    this.OTPControl.setValue("");
    this.isResendOTP = true;
    this.ValidateNumber.markAllAsTouched();
    if(this.ValidateNumber.valid){
      this.loadingService.show();  
      this.isButtonDisabled = true;
      this.writeToCmService.GenerateOTP(this.ValidateNumber.value.Mobile).subscribe((res:any)=>{
      this.loadingService.hide();
      if(res){
        this.uniqueID = res.UniqueID;
        this._commonService.alertMsg(
          "Success!",
          "OTP Sent successfully...!",
          "success"
        );
        if(!isResend){
          const element = document.getElementById("modalBtn") as HTMLElement;
          element.click();
        }
        setTimeout(() => {
          this.isResendOTP = false;
        }, 60000);
        this.isButtonDisabled = false;
      }else {
        this._commonService.alertMsg("Opps!", res.Message, "warning");
      }
      },err=>{
        this.loadingService.hide();
        this._commonService.alertMsg("Opps!", "Somethingn Went Wrong", "warning");
      })
    }
  }

  get CaptchaEntered() {
    return this.FormGroup.get('captcha');
  }

  onResendOTP(){
    this.resendOtpBool = true;
    this.onGenerateOtp(this.resendOtpBool);
  }

  onVerifyOTP(){
    this.OTPControl.markAllAsTouched();
    if (this.OTPControl.valid) {
      this.loadingService.show();
      this.isButtonDisabled = true;
      this.writeToCmService.ValidateOTP(this.ValidateNumber.value.Mobile,this.OTPControl.value,this.uniqueID).subscribe((res:any)=>{
        this.loadingService.hide();
        if(res.Valid_OTP){
          document.getElementById('filedSet1').setAttribute('disabled','true');
          document.getElementById('filedSet2').removeAttribute('disabled');
          this.regerateCaptchBtn = true;
          this.isButtonDisabled = false;
          this._commonService.alertMsg(
            "Success!",
            "OTP Verified successfully...!",
            "success"
          );
          const element = document.getElementById("closeBtn") as HTMLElement;
          element.click();
        } else {
          this.isButtonDisabled = false;
          this._commonService.alertMsg("Opps!", res.Message, "warning");
        }
      },err=>{
        this.loadingService.hide();
        this._commonService.alertMsg("Opps!", "Somethingn Went Wrong", "warning");
      })
    }
  }

  onFileSelected(event: any) {
    const fileInput = event.target as HTMLInputElement;
    this.selectedFile = fileInput.files[0].name
    const file: File = event.target.files[0];
    if (file) {
      // Check file type
      if (!this.allowedFileTypes.includes(file.type)) {
        this.fileError = 'Invalid file type. Allowed types: PDF, JPG, JPEG, WinRAR, WinZip, MP3, MP4.';
        this.isfileValid = false;
        return;
      }else{
        this.isfileValid = true;
      }
      // Check file size
      if (file.size > this.maxFileSize) {
        this.fileError = 'File size exceeds the 5MB limit.';
        this.isfileValid = false;
        return;
      }else{
        this.isfileValid = true;
      }    
    }   
    this.isfileValid = true;
    // if (fileInput.files && fileInput.files.length > 0) {
    //   this.selectedFile = fileInput.files[0];
    // }
    // if (event.target.files.item(0).type.match("application/pdf")) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        let base64Data = event.target.result as string;
    
        // Extract the base64 data by removing the data URL prefix
        const base64Index = base64Data.indexOf('base64,') + 'base64,'.length;
        base64Data = base64Data.substring(base64Index);
    
        this.PDFFile = this.sanitizer.bypassSecurityTrustResourceUrl(event.target.result);
        this.file = base64Data;
      };
      reader.readAsDataURL(event.target.files.item(0));
      // this.fileValidationMsg = "";
    // }
  }

  generateCaptcha() {
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890"
    const lengthOfCode = 1

    for (let i = 0; i < 5; i++) {
      let captchaChar = this.makeRandom(lengthOfCode, possible)
      this.captchaText[i] = captchaChar
    }
  }

  makeRandom(lengthOfCode: number, possible: string) {
    let text = "";
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  validateCaptcha() {
    let i = 0
    this.CaptchaEntered.setValue(this.CaptchaEntered.value.toLocaleUpperCase());
    for (i; i < 5; i++) {
      if (this.CaptchaEntered.value.charAt(i) != this.captchaText[i]) {
        this._commonService.alertMsg("Opps!", "Invalid Captcha Entered", "warning");
        this.CaptchaEntered.setValue('');
        return
      }
    }
    this.isCaptchaValidated = true;
  }

  onSaveForm(){
    this.FormGroup.markAllAsTouched();
    if (this.FormGroup.valid ) {
      this.validateCaptcha();
      if(this.isCaptchaValidated && this.isfileValid){
        this.loadingService.show();
        let data = {
          "name":this.FormGroup.value.Name,
          "mobileNo":this.ValidateNumber.value.Mobile,
          "description":this.FormGroup.value.description,
          "docName":this.selectedFile || "",
          "docByte":this.file || "",
          "DistrictId":"0"
        }
        this.writeToCmService.SaveWriteToCM(data).subscribe((res:any)=>{
          this.loadingService.hide();
          if(res[0].REG_URL != null || res[0].RequestNO != "Failure"){
            this.registerData = res[0];
            document.getElementById('filedSet2').setAttribute('disabled','true');
            this.regerateCaptchBtn = false;
              const msg = this.translate.currlang =='en'? `Your complaint request has been registered with the provisional complaint number ${res[0].RequestNO}. Contact Helpline 181 representative will contact you within 24 hours. - Rajasthan Contact` : `आपका शिकायत अनुरोध अस्थायी परिवाद क्रमांक ${res[0].RequestNO} के साथ पंजीकृत किया गया है| संपर्क हेल्पलाइन 181 प्रतिनिधि 24 घंटे के भीतर आपसे संपर्क करेंगे| - राजस्थान संपर्क`
              this._commonService.alertMsgForLong(
                "info",
                msg
              );
            // const element = document.getElementById("successModal") as HTMLElement;
            // element.click();
          }else{
            this._commonService.alertMsg("Opps!", "Somethingn Went Wrong", "warning");
          }
        },err=>{
          this.loadingService.hide();
          this._commonService.alertMsg("Opps!", "Somethingn Went Wrong", "warning");
        })
      }
    }
  }

  GetPurposeTypeList() {
    this.http
      .get<PurposeDropDownModel[]>(this.baseUrl + "api/Home/GetPurposeTypeList")
      .subscribe(
        (result) => {
          this.Purpose = result;
        },
        (error) => console.error(error)
      );
  }

  GetActiveDepartmentList() {
    this.http
      .get<DepartmentModel[]>(this.baseUrl + "api/Home/GetActiveDepartmentList")
      .subscribe(
        (result) => {
          this.DepartmentList = result;
        },
        (error) => console.error(error)
      );
  }

  formChanged() {
    if (this.currentForm === this.writeToCmForm) {
      return;
    }

    this.writeToCmForm = this.currentForm;
    if (this.writeToCmForm) {
      this.writeToCmForm.valueChanges.subscribe((data) =>
        this.onValueChanged(data)
      );
    }
  }
  onValueChanged(data?: any) {
    this.isValid = true;
    if (!this.writeToCmForm) {
      return;
    }
    const form = this.writeToCmForm.form;
    for (const field in this.formErrors) {
      this.formErrors[field] = "";
      const control = form.get(field);

      if (
        control &&
        (control.dirty || this.forceValidationCheck) &&
        !control.valid
      ) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + " ";
        }
      }
    }
  }
  onChange(selected: number) {
    if (selected > 0) {
      this.formErrors.Purpose = "";
      this.model.Type = selected;
    } else {
      this.formErrors.Purpose = "Please Select Purpose";
    }
  }

  numberOnly(value: any, isCommaOrDash: boolean = false): boolean {
    return this._commonService.numberOnly(value, isCommaOrDash);
  }

  onSubmit() {
    if (!this.writeToCmForm.valid) {
      this.forceValidationCheck = true;
      this.onValueChanged();
      if (this.model.Type === 0) {
        this.formErrors.Purpose = "Please Select Purpose";
      }
      return;
    }
    if (this.model.Type === 0) {
      this.formErrors.Purpose = "Please Select Purpose";
    }
    this.writeToCmService.WriteToCMO(this.model).subscribe(
      (success) => {
        if (success) {
          window.location.href = "/thankyou";
        } else {
          this.isError = true;
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  formErrors = {
    FullName: "",
    Email: "",
    Phone: "",
    Subject: "",
    Address: "",
    Purpose: "",
    Message: "",
  };
  validationMessages = {
    FullName: {
      required: "Name is required.",
      maxlength: "Name Id length should be maximum of 100 characters.",
    },
    Email: {
      required: "Email Id is required.",
      maxlength: "Email Id length should be maximum of 100 characters.",
      pattern: "Enter valid email id.",
    },
    Phone: {
      minlength: "Please enter a Vaild Number.",
      pattern: "Enter valid Phone No.",
    },

    Subject: {
      required: "Subject is required.",
      maxlength: "Subject length should be maximum of 100 characters.",
    },
    Organization: {
      maxlength: "Organization length should be maximum of 150 characters.",
    },
    Address: {
      required: "Address is required.",
      maxlength: "Address length should be maximum of 1000 characters.",
    },
    Message: {
      required: "Message is required.",
      maxlength: "Message length should be maximum of 100 characters.",
    },
  };
}

class PurposeDropDownModel {
  TypeId = 0;
  TypeName = "";
  TypeNameHindi = "";
}

class WriteToCmModel {
  Mode = 1;
  Rid = 0;
  Name = "";
  Email = "";
  Mobile = "";
  profession = "";
  Address = "";
  Subject = "";
  Message = "";
  SmsCode = "";
  Type = 0;
  FileName = "";
  DepartmentId = 0;
  Attch2 = "";
  Attch3 = "";
  IsAdmin: boolean;
  EmailDefault = "";
  Umobileno = "";
}

class FileDetailModel {
  ID: number;
  RepID: number;
  File: any;
  FileSize: number;
  FilePath: string;
  FileType: string;
  FileName: string;
}

class DepartmentModel {
  DepartmentId: number;
  DepartmentName: string;
  DepartmentnameHindi: string;
}
