import { Component, OnInit } from '@angular/core';
import { DepartmentService } from '../services/department.service';
import { DepartmentMenuListModel, GenerateSubMenuListForDepartmentModel } from '../Model/department-model.model';
import { TranslateService } from '../translate.service';
import { LookupEnum } from '../Enum/lookup-type.enum';
import { LoadingService } from '../services/loading.service';

@Component({
  selector: 'app-sitemap',
  templateUrl: './sitemap.component.html',
  styleUrls: ['./sitemap.component.css']
})
export class SitemapComponent implements OnInit {

  menuListModel: DepartmentMenuListModel = new DepartmentMenuListModel();
  enumdata = LookupEnum;

  constructor(private readonly departmentProfile: DepartmentService, public translate: TranslateService, private loadingService: LoadingService) { }

  ngOnInit() {
    this.loadingService.show();
    this.departmentProfile.getDeptMenuList().subscribe(data => {
      if (data) {
        this.loadingService.hide();
        this.menuListModel = data;
        this.menuListModel.MenuList.sort((a, b) => {
          if (a.DisplayOrder === null || a.DisplayOrder === undefined) return 1;
          if (b.DisplayOrder === null || b.DisplayOrder === undefined) return -1;
          return a.DisplayOrder - b.DisplayOrder;
        })
      }
    });
  }

  getSubMenuList(mainMenuCode: number): GenerateSubMenuListForDepartmentModel[] {
    return this.menuListModel.SubMenuList.filter(x => x.DepartmentMainMenuCode == mainMenuCode).sort((a, b) => {
      if (a.DisplayOrder === null || a.DisplayOrder === undefined) return 1;
      if (b.DisplayOrder === null || b.DisplayOrder === undefined) return -1;
      return a.DisplayOrder - b.DisplayOrder;
    });;
  }

  getSubSubMenuList(SubMenuCode) {
    return this.menuListModel.SubSubMenuList.filter(x => x.DepartmentSubMenuCode == SubMenuCode).sort((a, b) => {
      if (a.DisplayOrder === null || a.DisplayOrder === undefined) return 1;
      if (b.DisplayOrder === null || b.DisplayOrder === undefined) return -1;
      return a.DisplayOrder - b.DisplayOrder;
    });;
  }

}
