import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hcm-invitation',
  templateUrl: './hcm-invitation.component.html',
  styleUrls: ['./hcm-invitation.component.css']
})
export class HcmInvitationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
