import { MediaService } from './../services/media.services';
import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '../translate.service';

@Component({
  selector: 'app-home-mainpress',
  templateUrl: './home-mainpress.component.html',
  styleUrls: ['./home-mainpress.component.css'],
  providers: [MediaService]
})
export class HomeMainpressComponent implements OnInit {
  public speechprogramsMainpress: SpeechModel1[];
  constructor(public http: HttpClient, @Inject('BASE_URL') public baseUrl: string,
    private mediaService: MediaService) {
    this.speechprogramsMainpress = [];
  }

  ngOnInit() {
    //this.getdata();
  }

  public getdata() {
   
    this.mediaService.GetProgramSpeechListForFront().subscribe(success => {
      if (success !== undefined && success !== null) {
        this.speechprogramsMainpress = success;
      
      }
    });
    //this.mediaService.GetProgramSpeechListForFrontTop2().subscribe(success => {
    //  if (success !== undefined && success !== null) {
    //    this.Top2peechprograms = success;
    //  }
    //});
  }

}
interface SpeechModel1 {
  ProgramId: number;
  ProgramCategoryId: number;
  ProgramSubCategoryId: number;
  ProgramDate: string;
  ProgramDistrictId: number;
  ProgramLocationId: number;
  ProgramAddress: string;
  ProgramAddressHindi: string;
  ProgramLanguage: string;
  ProgramSubject: string;
  ProgramSubjectHindi: string;
  ProgramSpeech: string;
  ProgramSpeechHindi: string;
  Keywords: string;
  KeywordsHindi: string;
  SpeechPdf: string;
  Pdf: string;
  IsActive: boolean;
  IsDeleted: boolean;
  ProgramStateId: number;
  programtime: string;
  Userid: string;
  IsShowLatest: string;
  KeyImage: string;
  FolderName: string;
  PopupImages: string;
  PdfEnglish: string;
  Status: string;
  District: string;
  ProgramCategory: string;
  ProgramCategoryhindi: string;
  ProgramSubCategoryhindi: string;
  ProgramSubCategory: string;
}


