<!-- <div class="bg-scroll pos-rel breadcrumbs-page-img">
  <div class="container">
    <h1>{{'Photo_Gallery'| translate}}</h1>
  </div>
</div> -->
<div class="breadcrumbs-page-img">
  <div class="banner-hight-2">
    <h1>{{'Photo_Gallery'| translate}}</h1>
    <div class="inner-page-banner">
      <img src="assets/images/cmo-banner.png" alt="banner" class="cmo-banner">
    </div>
  </div>
</div>
<main id="main">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
      <li class="breadcrumb-item"><a href="javascript:void(0)">{{'Media_CornerSubNav'| translate}}</a></li>
      <li class="breadcrumb-item"><a routerLink="/gallery-list">{{'PhotosSubNav'| translate}}</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{'Photo_Gallery'| translate}}</li>
    </ol>
  </nav>
  <section class="gallery_page gallerydetail_page">
    <div class="container">
      <div class="col-xl-12 wow fadeInLeft">
        <div class=" program_detail_left">

          <div class="row" *ngIf="generalModel?.ImageAttachments.length>0">

            <div class="col-md-3 pb-5" [ngClass]="[i==0 ? 'active' : '' ]"
              *ngFor="let item of generalModel?.ImageAttachments let i = index">
              <a data-toggle="modal" class="g-photo" data-target="#galleryBlock" (click)="updateCarousel(i)"> <img class="d-block w-100 rounded"
                  src="{{item}}" alt="item{{i}}" height="300" width="400" onError="this.src='../../assets/images/program-no-img.png'"></a>
            </div>


            <!-- <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev"> <span
                class="carousel-control-prev-icon" aria-hidden="true"></span> <span class="sr-only">Previous</span> </a>
            <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next"> <span
                class="carousel-control-next-icon" aria-hidden="true"></span> <span class="sr-only">Next</span> </a> -->
            <!-- <div class="carousel-inner" *ngIf="generalModel.length==0">
              <img class="d-block w-100" src="../../assets/images/default_img1.png" />
            </div> -->
          </div>




          <!-- <h3> {{this.translate.currlang == 'hi' ? generalModel?.Description : generalModel?.Description}}</h3> -->

          <div class="d-flex align-items-center justify-content-between mb-2">
            <div class="text-muted"><i class="fa fa-calendar"></i> {{this.translate.currlang == 'hi' ?
              generalModel.PressReleaseDateHindi : generalModel?.PressreleaseDate | date : 'd MMM, y'}}</div>

            <!-- <a href="{{generalModel?.Attachments}}" class="gray-btn gray-orange px-3 py-2 text-white">
              <i class="fa fa-file-pdf-o mr-1"></i>Download</a> -->

          </div>

          <!-- <p [innerHTML]="generalModel?.GeneralDescription" ></p> -->
        </div>
      </div>
      <!-- <div class="row mb-4">
        <div class="gallery_detail_caption col-sm-12">
          <div class="date"><i class="fa fa-calendar"></i> {{this.translate.currlang == 'hi' ? generalModel.PressReleaseDateHindi : generalModel.PressreleaseDate | date : 'd MMM, y'}}</div>
          <h3>{{this.translate.currlang == 'hi' ? generalModel.Description : generalModel.Description}}</h3>
        </div>

        <div class='col-sm-4 col-xs-6 col-md-4 col-lg-3' *ngFor="let item of generalModel let i = index">
          <div class="card h-100 pos-rel justify-content-center align-items-center">
            <a href="{{item.ImageAttachments}}" data-lightbox="photos">
              <img class="img-fluid" src="{{item.ImageAttachments}}">
            </a>
          </div>
        </div>
      </div> -->
      <!-- row / end -->
    </div>
  </section>
  <div class="modal fade" id="myModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="pull-left">My Gallery Title</div>
          <button type="button" class="close" data-dismiss="modal" title="Close"> <span
              class="glyphicon glyphicon-remove"></span></button>
        </div>
        <div id="myGallery" class="carousel slide" data-interval="false">
          <div class="carousel-inner">

            <div class="item" [ngClass]="[i==0 ? 'active' : '' ]" *ngFor="let item of pagedItems let i = index">

              <img src="{{this.orgImageUrl}}{{item.ImageName}}" alt="item{{i}}">
              <div class="carousel-caption">
                <h3>Heading {{i}} </h3>
                <p>Slide {{i}} description.</p>
              </div>
            </div>
            <!--end carousel-inner-->
          </div>
          <!--Begin Previous and Next buttons-->
          <a class="left carousel-control" href="#myGallery" role="button" data-slide="prev"> <span
              class="glyphicon glyphicon-chevron-left"></span></a> <a class="right carousel-control" href="#myGallery"
            role="button" data-slide="next"> <span class="glyphicon glyphicon-chevron-right"></span></a>
          <!--end carousel-->
        </div>



        <div class="modal-footer">
          <div class="pull-left">
            <small>Photographs by</small>
          </div>
          <button class="btn-sm close" type="button" data-dismiss="modal">Close</button>
          <!--end modal-footer-->
        </div>
        <!--end modal-content-->
      </div>
      <!--end modal-dialoge-->
    </div>
    <!--end myModal-->>
  </div>
</main>







<!--<section class="inner-page-banner">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="page-title">{{'Home_OUR_GALLERY'| translate}}</div>
      </div>
    </div>
  </div>
</section>
<section class="program-detail gallery-detail  inner-page">
  <div class="container">
    <div class="row">

      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="pdf-bx">

          <div *ngIf="galleryDetail.length > 0" class="row  mg-btm-25">
            <div class=" col-xl-5">

              <img src="{{this.imageUrl}}{{galleryDetail[0].ImageName}}" onError="this.src='../../assets/imagesgallery-no-img.png'" class="img-fluid" alt="">
            </div>
            <div class="col-xl-7">
              <h3 class="">{{this.translate.currlang == 'hi' ? galleryDetail[0].ProgramSubCategoryHindi : galleryDetail[0].ProgramSubCategory}}</h3>
              <div class="city-name">{{this.translate.currlang == 'hi' ? galleryDetail[0].ProgramAddressHindi : galleryDetail[0].ProgramAddress}}</div>
              <span class="date">{{this.translate.currlang == 'hi' ? galleryDetail[0].ProgramDateHindi : galleryDetail[0].ProgramDate}}  </span>

              <p><strong>{{this.translate.currlang == 'hi' ? galleryDetail[0].ProgramSubjectHindi : galleryDetail[0].ProgramSubject}}</strong></p>
            </div>

          </div>

          <div class="row mt-3">
            <a *ngFor="let item of pagedItems" href="{{this.imageUrl}}{{item.ImageName}}" data-toggle="lightbox" data-gallery="gallery" class="col-md-4">
              <img src="{{this.imageUrl}}{{item.ImageName}}" class="img-fluid rounded">
            </a>
          </div>

          <div class="col-sm-12 text-center row mt-4" *ngIf="totalPage > 9">


            <ul class="pagination mx-auto text-center" *ngIf="pager.pages && pager.pages.length">
              <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                <a class="page-link" (click)="setPage(1)">{{'First'| translate}}</a>
              </li>
              <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                <a class="page-link" (click)="setPage(pager.currentPage - 1)">{{'Previous'| translate}}</a>
              </li>
              <li class="page-item" *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
                <a class="page-link" (click)="setPage(page)">{{page}}</a>
              </li>
              <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                <a class="page-link" (click)="setPage(pager.currentPage + 1)">{{'Next'| translate}}</a>
              </li>
              <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                <a class="page-link" (click)="setPage(pager.totalPages)">{{'Last'| translate}}</a>
              </li>
            </ul>

          </div>
        </div>
      </div>




    </div>
  </div>
</section>

<div class="backdrop" [ngStyle]="{'display':display}"></div>-->




<div class="modal fade galleryBlock" id="galleryBlock" tabindex="-1" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog mw-100 w-50">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close close-in" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div id="carouselExampleControls" class="carousel slide mb-2" data-ride="carousel"
              *ngIf="generalModel.ImageAttachments.length>0">
              <div class="carousel-inner">
                <div class="carousel-item" [ngClass]="[i==0 ? 'active' : '' ]"
                  *ngFor="let item of generalModel?.ImageAttachments let i = index">
                  <img class="d-block w-100" src="{{item}}" alt="item{{i}}"
                    onError="this.src='../../assets/images/program-no-img.png'">
                </div>

              </div>
              <a (click)="imageIndex=0" class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev"> <span
                  class="carousel-control-prev-icon" aria-hidden="true"></span> <span class="sr-only">Previous</span>
              </a> <a (click)="imageIndex=0" class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span> <span class="sr-only">Next</span>
              </a>
              <div class="carousel-inner" *ngIf="!generalModel">

                <img class="d-block w-100" src="../../assets/images/default_img1.png" />


              </div>

            </div>
        <!-- <img class="d-block w-100" src="{{image}}" alt=""
          onError="this.src='../../assets/images/program-no-img.png'"> -->
      </div>

    </div>
  </div>
</div>