import { Component, OnInit } from '@angular/core';
import { CMProfileModel } from '../Model/cm-profile.model';
import { NewsService } from '../services/news.services';
import { DepartmentCodeEnum } from '../Enum/app-enums';
import { EntryTypeEnum, JankalyanCategoryEnum } from '../Enum/jankayan.enum';
import { TranslateService } from '../translate.service';

@Component({
  selector: 'app-cm-profile',
  templateUrl: './cm-profile.component.html',
  styleUrls: ['./cm-profile.component.css'],
  providers:[NewsService]
})
export class CmProfileComponent implements OnInit {

  CMProfileModel = new CMProfileModel();
  CMProfileModelViewModel:any;
  constructor(private newsService: NewsService,public translate: TranslateService) { }

  ngOnInit() {
    this.GetCMProfile();
  }

  GetCMProfile() {
    this.CMProfileModel.IndexModel.PageSize = 1;
    this.CMProfileModel.DepartmentCode = DepartmentCodeEnum.CmoWebsiteCode;
    this.CMProfileModel.JankalyanCategoryCode = JankalyanCategoryEnum.CMProfileCode;
    this.CMProfileModel.EntryTypeCode = EntryTypeEnum.CMProfileCode

    this.newsService.GetCMProfile(this.CMProfileModel).subscribe((response: any) => {
      if (response.IsSuccess) {
        this.CMProfileModelViewModel = response.Data.Data[0];
        // console.log(this.CMProfileModelViewModel, "response")
      }
    });
  }
}
