<!-- 
<div class="bg-scroll pos-rel breadcrumbs-page-img">
  <div class="container">

    <h1> Write to the chief minister</h1>
  </div>
</div> -->
<div class="breadcrumbs-page-img">
    <div class="banner-hight-2">
        <h1>{{'Home_RECENT_NEWS'| translate}}</h1>
        <div class="inner-page-banner">
            <img src="assets/images/cmo-banner.png" alt="banner" class="cmo-banner">
        </div>
    </div>
</div>


<main id="main">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
            <li class="breadcrumb-item"><a href="javascrtipt:void(0)">{{'Citizen_CornerNav' | translate}}</a></li>
            <li class="breadcrumb-item active" aria-current="page">
                {{'WriteToCm_txt' | translate}}
            </li>
        </ol>
    </nav>
    <section class="main-section-img">
        <div class="container">
            <div class="bg-image">
                <div class="row mb-3">
                    <div class="col-lg-12">
                        <div class="cm-write-text">
                            <h1>{{'Write-to-CM_txt' | translate}}</h1>
                        </div>
                    </div>
                </div>
                <div class="row g-3">
                    <div class="col-lg-4 col-md-12 col-sm-12">
                        <fieldset id="filedSet1">
                            <form [formGroup]="ValidateNumber">
                                <div class="form-text-no">
                                    <h3>{{'MobileNo_txt' | translate}} *</h3>
                                    <input class="form-control" formControlName="Mobile"
                                        (keypress)="_commonService.numberOnly($event);"
                                        [ngClass]="{ 'is-invalid': ValidateNumber.controls.Mobile.errors && ValidateNumber.controls.Mobile.touched}">
                                    <div class="invalid-feedback"
                                        *ngIf="(ValidateNumber.get('Mobile')?.hasError('required') && ValidateNumber.get('Mobile')?.touched)">
                                        {{this.translate.currlang !='en'? 'कृपया '+('MobileNo_txt'|translate) +' लिखें'
                                        :
                                        'Please Enter '+ ('MobileNo_txt'|translate)}} </div>
                                    <div class="invalid-feedback"
                                        *ngIf="!ValidateNumber.get('Mobile')?.hasError('required') && ((ValidateNumber.get('Mobile')?.hasError('maxlength') || ValidateNumber.get('Mobile')?.hasError('minlength')) && ValidateNumber.get('Mobile')?.touched) || (ValidateNumber.get('Mobile')?.hasError('maxlength') || ValidateNumber.get('Mobile')?.hasError('minlength'))">
                                        {{this.translate.currlang !='en'? 'कृपया वैध '+('MobileNo_txt'|translate) +'
                                        दर्ज करें' : 'Please Enter valid '+ ('MobileNo_txt'|translate)}} </div>
                                    <button (click)="onGenerateOtp(false)" style="cursor: pointer;"
                                        [disabled]="isButtonDisabled">{{'GenerateOTP_txt' | translate}}</button>
                                </div>
                            </form>
                        </fieldset>
                    </div>
                    <div class="col-lg-7 col-md-12 col-sm-12">
                        <form [formGroup]="FormGroup">
                            <fieldset id="filedSet2">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">{{'Name_txt' | translate}} *</label>
                                    <div class="col-sm-10">
                                        <input class="form-control" formControlName="Name"
                                            [ngClass]="{ 'is-invalid': FormGroup.controls.Name.errors && FormGroup.controls.Name.touched}">
                                        <div class="invalid-feedback"
                                            *ngIf="(FormGroup.get('Name')?.hasError('required') && FormGroup.get('Name')?.touched)">
                                            {{translate.currlang =='en'? 'Please Enter '+ ('Name_txt'|translate) :
                                            'कृपया '+('Name_txt'|translate) +' लिखें'}} </div>
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">{{'Description_txt' | translate}}*</label>
                                    <div class="col-sm-10">
                                        <textarea class="form-control" formControlName="description"
                                            [ngClass]="{ 'is-invalid': FormGroup.controls.description.errors && FormGroup.controls.description.touched}"></textarea>
                                        <div class="invalid-feedback"
                                            *ngIf="(FormGroup.get('description')?.hasError('required') && FormGroup.get('description')?.touched)">
                                            {{translate.currlang =='en'? 'Please Enter '+ ('Description_txt'|translate)
                                            :
                                            'कृपया '+('Description_txt'|translate) +' लिखें'}} </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-3">
                                        <div>
                                            <p>{{'UploadDoc_txt' | translate}}</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="select-text mb-2">
                                            <input type="file" formControlName="file" value="फाइल चयन करें"
                                                (change)="onFileSelected($event)">
                                        </div>
                                        <div class="col-lg-12">
                                            <div>
                                                <div *ngIf="!isfileValid" style="color: red;">{{fileError}}</div>
                                                <p>( {{'DocType_txt' | translate}} )</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="row mt-3 align-items-center">
                                    <div class="col-lg-3">
                                        <div>
                                            <p>{{'CaptchaLabel' | translate}}</p>
                                        </div>
                                    </div>
                                    <div class="captcha-text col-lg-3">
                                        <div class="captcha-text1 " *ngFor="let captcha of captchaText">
                                            <b>{{captcha}}</b>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 control-bpx-2 m-2">
                                        <input class="form-control" formControlName="captcha"
                                            [ngClass]="{ 'is-invalid': FormGroup.controls.captcha.errors && FormGroup.controls.captcha.touched}"
                                            oninput="this.value=this.value.toUpperCase()" maxlength="5">
                                        <i *ngIf="regerateCaptchBtn" class="fa fa-refresh"
                                            (click)="generateCaptcha();"></i>
                                        <div class="invalid-feedback"
                                            *ngIf="(FormGroup.get('captcha')?.hasError('required') && FormGroup.get('captcha')?.touched)">
                                            {{translate.currlang =='en'? 'Please Enter '+ ('Captcha_txt'|translate) :
                                            'कृपया '+('Captcha_txt'|translate) +' लिखें'}}
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="btn-cancle">
                                            <button class="submit-text m-1" (click)="onSaveForm()"
                                                style="cursor: pointer;">{{'Submit_txt' | translate}}</button>
                                            <button class="cancel-text m-1" style="cursor: pointer;">{{'Cancel_txt' |
                                                translate}}</button>
                                        </div>
                                    </div>
                                </div>

                            </fieldset>
                        </form>

                    </div>

                </div>

            </div>
        </div>
    </section>
</main>

<!-- Modal Button -->
<button type="button" id="modalBtn" class="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#OTPReg">
    Launch demo modal
</button>

<!-- OTP form start -->
<div class="modal fade modal-dsign-sm modal-new-theme-design modal-send-otp" id="OTPReg" tabindex="-1" role="dialog"
    aria-labelledby="OTP-Reg" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{'VerifyOTP_txt'|translate}}</h5>
                <!-- <button type="button" id="closeBtn" class="close" data-bs-dismiss="modal" aria-label="Close"> <span
                        aria-hidden="true">&times;</span> </button> -->

                <button type="button" id="closeBtn" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="modal-send-otp-img text-center">
                    <img src="assets/images/mobile-otp.png" alt="Rajasthan State portal" />
                </div>
                <h5 class="text-center my-3"><strong>{{ValidateNumber.value.Mobile}}</strong> </h5>
                <div class="form-group otp-control d-flex position-relative mb-3 flex-wrap">
                    <input type="text" placeholder="{{'EnterOTP_txt' | translate}}" class="form-control"
                        [formControl]="OTPControl" [ngClass]="{ 'is-invalid': OTPControl.errors && OTPControl.touched}"
                        (keypress)="numberOnly($event)">

                    <div class="invalid-feedback" *ngIf="OTPControl?.hasError('required') && OTPControl.touched">
                        {{translate.currlang !='en'? 'कृपया '+('OTP_txt'|translate) +' लिखें' : 'Please Enter
                        '+('OTP_txt'|translate)}} </div>

                    <div class="invalid-feedback"
                        *ngIf="!FormGroup.get('OTPControl')?.hasError('required') && ((FormGroup.get('OTPControl')?.hasError('maxlength') || FormGroup.get('OTPControl')?.hasError('minlength')) && FormGroup.get('OTPControl')?.touched) || (FormGroup.get('OTPControl')?.hasError('maxlength') || FormGroup.get('OTPControl')?.hasError('minlength'))">
                        {{translate.currlang !='en'? 'कृपया '+('OTP_txt'|translate) +' लिखें' : 'Please Enter '+
                        ('OTP_txt'|translate)}} </div>
                </div>

                <div class="crl"></div>
                <div class="form-apply-online-btn col-md-12 border-0">
                    <button (click)="onVerifyOTP()" class="btn btn-success w-100 mb-1">
                        <i class="fa fa-check mr-1"></i> {{'VerifyOTP_txt' |
                        translate}}</button>

                    <button type="button" (click)="onResendOTP()" class="btn btn-default w-100 mt-1"
                        [disabled]="isResendOTP">
                        <i class="fa fa-undo mr-1"></i>
                        {{'ResendOTP_txt' |translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>

<app-loader></app-loader>
