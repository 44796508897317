<!-- Rmove this class from div bcox it has cm sir image "breadcrumbs-page-img" -->
<!-- <div class="bg-scroll pos-rel breadcrumbs-page-img">
    <div class="container">
        <h1 *ngIf="pageTitle">{{ translate.currlang=='en' ? pageTitle?.NameEnglish : pageTitle?.NameHindi}}</h1>
        <h1 *ngIf="!pageTitle">{{'Photo_Gallery'| translate}}</h1>

    </div>
</div> -->
<div class="breadcrumbs-page-img">
    <div class="banner-hight-2">
        <h1 *ngIf="pageTitle">{{ translate.currlang=='en' ? pageTitle?.NameEnglish : pageTitle?.NameHindi}}</h1>
        <h1 *ngIf="!pageTitle">{{'Photo_Gallery'| translate}}</h1>
      <div class="inner-page-banner">
        <img src="assets/images/cmo-banner.png" alt="banner" class="cmo-banner">
      </div>
    </div>
  </div>
<main id="main">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0)">{{'Media_CornerSubNav'| translate}}</a></li>
            <li *ngIf="pageTitle" class="breadcrumb-item active" aria-current="page"> {{ translate.currlang=='en' ?
                pageTitle?.NameEnglish : pageTitle?.NameHindi}}</li>
            <li *ngIf="!pageTitle && (this.actCode == 32031)" class="breadcrumb-item active" aria-current="page">
                {{'Photo_Gallery'|
                translate}}
            </li>
            <li *ngIf="!pageTitle && (this.actCode == 32174)" class="breadcrumb-item active" aria-current="page">
                {{'Nav_Speech'| translate}}</li>
        </ol>
    </nav>
    <section class="gallery_page">
        <div class="container">
            <div class="row" *ngIf="isShowPoster">
                <div class='col-sm-6 col-xs-6 col-md-4 col-lg-3 mb-4' *ngFor="let items of model let i = index">
                    <div class="card wow uploader-gallery-list-items"
                        [ngClass]="[i%3 <1 ? 'fadeInLeft' : i%3 <2? 'fadeInUp':i%3 <3? 'fadeInRight':'fadeInLeft' ]">
                        <span class="uploader-gallery-items-date">{{this.translate.currlang == 'hi' ?
                            items.DateHindi :
                            (items.Date | date : 'd MMM, y')}}</span>

                        <a class="img-loader" [href]="items.Images[0]" onerror="this.src='assets/images/default.png'"
                            attr.data-lightbox="images_{{i}}" title="{{items.Subject}}">
                            <img class="img-fluid rounded" [src]="items.Images[0]" title="{{items.Subject}}"
                                alt="{{items.Subject}}" onerror="this.src='assets/images/default.png'">
                            <!---Inner Section-->
                            <ul *ngIf="items.Images && items.Images.length>0" style="display: none;">
                                <ng-container *ngFor="let imgSrc of items.Images; let j=index">
                                    <li *ngIf="j>0">
                                        <a href="{{imgSrc}}" attr.data-lightbox="images_{{i}}"
                                            data-title="{{items.Subject}}">
                                            <img [src]="imgSrc" title="{{items.Subject}}" alt="{{items.Subject}}"
                                                onerror="this.src='assets/images/default.png';" />
                                        </a>
                                    </li>
                                </ng-container>

                            </ul>
                        </a>
                        <div class="gallery_caption">
                            <h3
                                *ngIf="(this.translate.currlang == 'hi' && items.Subject?.length<=50 )||(this.translate.currlang != 'hi' && items.Subject?.length<=50)">
                                {{this.translate.currlang == 'hi' ? items.Subject : items.Subject}}</h3>
                            <h3
                                *ngIf="(this.translate.currlang == 'hi' && items.Subject?.length>50 )||(this.translate.currlang != 'hi' && items.Subject?.length>50)">
                                {{(this.translate.currlang == 'hi' ? items?.Subject :
                                items?.Subject)|slice:0:50}}...</h3>
                            <div class="date">{{this.translate.currlang == 'hi' ? items.DateHindi : (items.Date |
                                date :
                                'd MMM, y')
                                }}</div>
                        </div>
                    </div>
                </div>
                <nav aria-label="Page navigation example" class="w-100 text-center mt-4" *ngIf="totalRecords > 9">
                    <ul class="pagination d-inline-flex" *ngIf="pager.pages && pager.pages.length">
                        <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                            <a class="page-link" (click)="getListData(1)">{{'First'| translate}}</a>
                        </li>
                        <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                            <a class="page-link" (click)="getListData(pager.currentPage - 1)">{{'Previous'|
                                translate}}</a>
                        </li>
                        <li class="page-item" *ngFor="let page of pager.pages"
                            [ngClass]="{active:pager.currentPage === page}">
                            <a class="page-link" (click)="getListData(page)">{{page}}</a>
                        </li>
                        <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                            <a class="page-link" (click)="getListData(pager.currentPage + 1)">{{'Next'|
                                translate}}</a>
                        </li>
                        <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                            <a class="page-link" (click)="getListData(pager.totalPages)">{{'Last'| translate}}</a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="row" *ngIf="isShowVideo">
                <div class='col-sm-6 col-xs-6 col-md-4 col-lg-3 mb-4' *ngFor="let items of model let i = index">
                    <div class="card wow uploader-gallery-list-items"
                        [ngClass]="[i%3 <1 ? 'fadeInLeft' : i%3 <2? 'fadeInUp':i%3 <3? 'fadeInRight':'fadeInLeft' ]">
                        <span class="uploader-gallery-items-date">{{this.translate.currlang == 'hi' ?
                            items.DateHindi :
                            (items.Date | date : 'd MMM, y')}}</span>

                        <a [href]="items.VideoPath" target="_blank">
                            <div class="embed-responsive embed-responsive-21by9">
                                <video #videoPlayer{{i}} tabindex="-1" (playing)="onMediaPlaying($event, false)"
                                    id="my_video_{{i}}" (mouseover)="playVideo($event)" (mouseout)="pauseVideo($event)"
                                    class="embed-responsive-item" width="100%" height="100%" controls preload="none"
                                    data-setup='{ "aspectRatio":"100%:100%", "playbackRates": [1, 1.5, 2] }'
                                    disablePictureInPicture
                                    controlslist="nodownload noremoteplayback noplaybackrate foobar">
                                    <source src="{{items.VideoPath}}"
                                        type="video/{{items.VideoPath.split('.')[items.VideoPath.split('.').length-1]}}">
                                </video>
                            </div>
                        </a>
                        <div class="gallery_caption">
                            <h3 *ngIf="(this.translate.currlang == 'hi' && items.Subject?.length<=50 )||(this.translate.currlang != 'hi' && items.Subject?.length<=50)">
                                {{this.translate.currlang == 'hi' ? items.Subject : items.Subject}}</h3>
                            <h3 *ngIf="(this.translate.currlang == 'hi' && items.Subject?.length>50 )||(this.translate.currlang != 'hi' && items.Subject?.length>50)">
                                {{(this.translate.currlang == 'hi' ? items?.Subject :
                                items?.Subject)|slice:0:50}}...</h3>
                            <div class="d-flex justify-content-between">
                                <div class="date">
                                    {{this.translate.currlang == 'hi' ? items.DateHindi : (items.Date | date : 'd MMM, y')}}
                                </div>
                                <!-- <div>
                                    <a [href]="items.VideoPath" target="_blank" download class="download-button">Download</a>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <nav aria-label="Page navigation example" class="w-100 text-center mt-4" *ngIf="totalRecords > 9">
                    <ul class="pagination d-inline-flex" *ngIf="pager.pages && pager.pages.length">
                        <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                            <a class="page-link" (click)="getListData(1)">{{'First'| translate}}</a>
                        </li>
                        <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                            <a class="page-link" (click)="getListData(pager.currentPage - 1)">{{'Previous'|
                                translate}}</a>
                        </li>
                        <li class="page-item" *ngFor="let page of pager.pages"
                            [ngClass]="{active:pager.currentPage === page}">
                            <a class="page-link" (click)="getListData(page)">{{page}}</a>
                        </li>
                        <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                            <a class="page-link" (click)="getListData(pager.currentPage + 1)">{{'Next'|
                                translate}}</a>
                        </li>
                        <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                            <a class="page-link" (click)="getListData(pager.totalPages)">{{'Last'| translate}}</a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="row" *ngIf="model && model.length == 0">
                <div class="no-record">
                    {{'No_Record_Found'| translate}}
                </div>
            </div>
        </div>
    </section>
</main>