

<div [ngClass]="!isCodeOfConduct ? 'bg-scroll pos-rel breadcrumbs-page-img' : 'bg-scroll pos-rel'">
  <div class="container">
    <h1>{{'Message_Detail'| translate}}</h1>
  </div>
</div>
<main id="main">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
      <li class="breadcrumb-item"><a href="javascript:void(0)">{{'Chief_MinisterNav'| translate}}</a></li>
      <li class="breadcrumb-item"><a routerLink="/messagefromcm"> {{'NavMessageFromCM'| translate}}</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{'Message_Detail'| translate}}</li>

    </ol>
  </nav>
  <section class="program_list_detail">
    <div class="container">
      <div class="row">
        <div class="col-xl-9 wow fadeInLeft">
          <div class="program_detail_left">

            <div id="carouselExampleControls" class="carousel slide mb-2" data-ride="carousel" *ngIf="generalModel.ImageAttachments.length>0">
              <div class="carousel-inner" *ngIf="generalModel">
                <div class="carousel-item" [ngClass]="[i==0 ? 'active' : '' ]" *ngFor="let item of generalModel let i = index">
                  <img class="d-block w-100" src="{{item.ImageAttachments}}" alt="item{{i}}">
                </div>

              </div>
              <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev"> <span class="carousel-control-prev-icon" aria-hidden="true"></span> <span class="sr-only">Previous</span> </a> <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next"> <span class="carousel-control-next-icon" aria-hidden="true"></span> <span class="sr-only">Next</span> </a>
              <div class="carousel-inner" *ngIf="!generalModel">

                <img class="d-block w-100" src="../../assets/images/default_img1.png" />
              </div>
            </div>
            <h3> {{this.translate.currlang == 'hi' ? generalModel.Description : generalModel.Description}}</h3>

            <div class="d-flex align-items-center justify-content-between mb-2">
              <div class="text-muted"><i class="fa fa-calendar"></i> {{this.translate.currlang == 'hi' ? generalModel.PressReleaseDateHindi : generalModel.PressreleaseDate | date : 'd MMM, y'}}</div>

              <a href="{{generalModel.Attachments}}" class="gray-btn gray-orange px-3 py-2 text-white">
                <i class="fa fa-file-pdf-o mr-1"></i>Download</a>

              </div>
              <p [innerHTML]="generalModel?.GeneralDescription"></p>

          </div>
        </div>

        <div class="col-xl-3  wow fadeInRight">
          <div class="program_detail_right">

            <!--<div class="program_detail_right_box mb-4">
              <div class="section-header">
                <h2>Latest Programmes</h2>
              </div>
              <div class="media" *ngFor="let program of latestEventProgramModels let i = index">
                <div class="align-self-center"> <img class="mr-2" src="{{this.imageUrl}}{{program.ProgramImage}}" onError="this.src='../../assets/images/program-no-img.png'" alt=""> </div>
                <div class="media-body align-self-center">
                  <a routerLink="/Programdetail/{{program.ProgramId}}">

                    {{this.translate.currlang == 'hi' ? program.ProgramSubjectHindi : program.ProgramSubject}}
                  </a>
                </div>
              </div>

            </div>-->
            <div class="program_detail_right_box mb-4">
              <div class="section-header">
                <h2>{{'Latest_Message'| translate}}</h2>
              </div>
              <div class="media" *ngFor="let msgItem of PressReleaseListModel let i = index">
                <div class="align-self-center"> <img class="mr-2" src="assets/img/Message_From_CM.png" onError="this.src='../../assets/images/program-no-img.png'" alt=""> </div>
                <div class="media-body align-self-center">
                  <a routerLink="/messageDetail/{{msgItem.Id}}">

                    {{this.translate.currlang == 'hi' ? msgItem.Description : msgItem.Description}}
                  </a>
                </div>
              </div>

            </div>

            <div class="program_detail_right_box mb-4" *ngIf="galleryDetail.length>0">
              <div class="section-header">
                <h2>Photo Gallery</h2>
              </div>
              <div class="row photos">
                <div class="col-sm-6 col-md-4 col-lg-4 item" *ngFor="let item of galleryDetail let i = index">
                  <a href="{{this.imageUrl}}{{item.ImageName}}" data-lightbox="photos"><img class="img-fluid" src="{{this.imageUrl}}{{item.ImageName}}"></a>
                </div>

              </div>
            </div>

            <div class="program_detail_right_box mb-4" *ngIf="programPressRelease.length>0">
              <section class=" box-content">
                <div class=" press_box">
                  <div class="section-header">
                    <h2>
                      PRESS RELEASES
                    </h2>
                  </div>

                  <div class="press_releases">
                    <ul class="wow fadeInRight">
                      <li class="wow fadeInLeft" *ngFor="let program of programPressRelease">
                        <div class="press_releases_bx d-flex">
                          <div class="date align-self-center">
                            {{program.ProgramDate | date : 'dd'}} <br>
                            <div style="font-size:13px;">{{program.ProgramDate| date : 'MMMM'}}</div>
                          </div>
                          <div class="detail align-self-center">
                            <a href="javascript:void(0)"> {{this.translate.currlang == 'hi' ? program.ProgramSubjectHindi : program.ProgramSubject}} </a> <span><i class="ion-ios-location-outline"></i>{{this.translate.currlang == 'hi' ? program.ProgramAddress : program.ProgramAddress}} </span> <span>
                              <i class="fa fa-calendar" aria-hidden="true"></i> &nbsp;{{program.ProgramDate}}
                            </span>
                          </div>
                        </div>
                      </li>

                    </ul>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>







<!--<section class="inner-page-banner">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="page-title">
          {{'Home_PROGRAMS'| translate}}
        </div>
      </div>
    </div>
  </div>
</section>
<section class="program-detail inner-page">
  <div class="container">
    <div class="row">
      <div class="col-md-12" align="right">

        <a routerLink="/messagelist" class="btn btn-primary">Back</a>
      </div>

      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="pdf-bx">

          <div class="row  mg-btm-25">
            <div class=" col-xl-5">

            </div>

            <div class="col-xl-7" *ngIf="messagedetail">

              <h3 class="">
                {{this.translate.currlang == 'hi' ? messagedetail.ProgramSubCategoryHindi : messagedetail.ProgramSubCategory}}

              </h3>
              <div class="city-name"> {{this.translate.currlang == 'hi' ? messagedetail.ProgramAddressHindi : messagedetail.ProgramAddress}} </div>
              <span class="date">

              </span>
              <div class="detail-ico">
                <a *ngIf="messagedetail.TotalVideo > 0" routerLink="javascript:void(0)" title="video"><i class="fas fa-video"></i></a>
                <a *ngIf="messagedetail.TotalImages > 0" routerLink="/gallerydetail/{{messagedetail.ProgramId}}" title="image"><i class="fas fa-image"></i></a>
                <a *ngIf="isEnglish && messagedetail.PdfEnglish != null && messagedetail.PdfEnglish != ''" routerLink="{{environmentUrl}}{{messagedetail.PdfEnglish}}" title="PDF"><i class="fas fa-newspaper"></i></a>
                <a *ngIf="!isEnglish && messagedetail.Pdf != null && messagedetail.Pdf != ''" routerLink="{{environmentUrl}}{{messagedetail.Pdf}}" title="PDF"><i class="fas fa-newspaper"></i></a>
              </div>
              <p><strong>{{this.translate.currlang == 'hi' ? messagedetail.ProgramSubjectHindi : messagedetail.ProgramSubject}}</strong></p>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>-->
