import { Injectable } from '@angular/core';
import { BaseService } from './baseservice';
import { AppSettings } from '../common/AppSettings';
import { Dictionary } from '../common/dictionary';
import { IndexModel } from '../Model/awards-request-model.model';

@Injectable({
  providedIn: 'root'
})
export class DepartmentPageDetailsService {

  constructor(
    private httpService: BaseService
  ) { }

  SectorsData(keys="ddlAllSectoralDepartment",userType=0,userid=0): any {
    return this.httpService.get(`${AppSettings.API_ENDPOINT_RW_Jankalyan}${AppSettings.jankalyanApi.DepartmentProfile.SectorsUrl}?keys=${keys}&userType${userType}&userid${userid}`);
  }

  GetDepartmentPageDetail(deptCode: number, lookupCode: number, pageCode: number): any {
    const param = new Dictionary<any>();
    param.Add("deptCode", deptCode);
    param.Add("lookupCode", lookupCode);
    param.Add("pageCode", pageCode);
    return this.httpService.get(`${AppSettings.API_ENDPOINT_RW_Jankalyan}${AppSettings.jankalyanApi.DepartmentPage.DepartmentPageUrl}`, param);
  }

  GetMLAConstituency(model: IndexModel): any {
    return this.httpService.post(`${AppSettings.API_ENDPOINT_RW_Jankalyan}${AppSettings.MLAConstituencyUrl}`, model);
  }
}
