import { MediaService } from './../services/media.services';
import { Component, Inject, OnInit, AfterContentChecked } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateService } from '../translate.service';
import { environment } from '../../environments/environment';
import { EventService } from './../services/events.services';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { EventLatestRequestModel } from '../Model/event.model';
import { GalleryDetailRequestModel } from '../Model/gallery-detail-model.model';
import { InAugrationListModel, PressReleaseFrontSearchModel, PressReleaseRequestModel } from '../Model/press-release-model.model';
import { NewsService } from '../services/news.services';
import { CommonService } from '../services/common.service';
@Component({
  selector: 'app-messagedetail',
  templateUrl: './messagedetail.component.html',
  styleUrls: ['./messagedetail.component.css'],
  providers: [MediaService, EventService, NewsService]
})
export class MessagedetailComponent implements OnInit, AfterContentChecked {
  public messagedetail: MessageDetailModel;

  public galleryDetail: GalleryDetailModel[];
  public programPressRelease: PressReleaseModel[];
  latestEventProgramModels: LatestEventProgramModel[];
  id: string;
  isEnglish: boolean;
  totalPage: number;
  environmentUrl = environment.imageUrl + 'Program/Pdf/';
  imageUrl = environment.imageUrl + 'Program/Image/Thumbnail/Fr_';
  searchBox = '';
  date = '';
  currentPage = 1;
  //PDFEnglishString: string;
  //PDFHindiString: string;

  PDFEnglishString: SafeResourceUrl;
  PDFHindiString: SafeResourceUrl;
  modelRequest: EventLatestRequestModel;
  modelGalleryRequest: GalleryDetailRequestModel;
  pesReqModel = new PressReleaseRequestModel();

  public messageListModel: MessageDetailModel[];

  //Jankalyan API
  indexModel = new PressReleaseFrontSearchModel();
  generalModel: InAugrationListModel;
  PressReleaseListModel: InAugrationListModel[];
  // isCodeOfConduct: boolean = true;
  isCodeOfConduct: boolean = false;

  constructor(public http: HttpClient, @Inject('BASE_URL')
  public baseUrl: string,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private mediaService: MediaService,
    private eventService: EventService,
    public sanitizer: DomSanitizer,
    private newsService: NewsService,
    public _commonService: CommonService) {
      
    this.isCodeOfConduct = this._commonService.GetIsCodeOfConduct();
    this.modelRequest = new EventLatestRequestModel();
    this.galleryDetail = [];
    this.programPressRelease = [];
    this.latestEventProgramModels = [];
    this.modelGalleryRequest = new GalleryDetailRequestModel();
  }
  setLang(lang: string) {
    this.translate.use(lang);
  }
  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      // this.id = params.get('id');
      this.indexModel.EntryNumber = Number(params.get('id'));
      this.GetPressReleaseData();
      this.GetTopPressRelease();
      //this.getdata(this.id);
      this.getPressReleasedata(this.id);
      this.getGalleryImagedata(this.id);
      //this.getLatestProgram(this.currentPage, this.id);
      //this.getMessageListdata();
    });
  }
  ngAfterContentChecked() {

    this.isEnglish = this.translate.currlang === 'en' ? true : false;

  }
  public getdata(id: string) {
    this.mediaService.GetMessageDetail(id).subscribe(success => {
      if (success !== undefined && success !== null) {
        this.messagedetail = success;
        if (this.messagedetail.PdfEnglish != undefined) {
          // this.getBase64EnglishString(this.environmentUrl + this.messagedetail.PdfEnglish, true);
          this.PDFEnglishString = this.sanitizer.bypassSecurityTrustResourceUrl(this.environmentUrl + this.messagedetail.PdfEnglish);
        }
        if (this.messagedetail.Pdf != undefined) {
          this.PDFHindiString = this.sanitizer.bypassSecurityTrustResourceUrl(this.environmentUrl + this.messagedetail.Pdf);
          //  this.getBase64EnglishString(this.environmentUrl + this.messagedetail.Pdf, false);
        }

      }
    }, error => {
      console.error(error);
    });

  }

  //public getBase64EnglishString(pdffilePath: string, isEng: boolean = true) {
  //  this.eventService.GetBase64StringbyPath(pdffilePath).subscribe(success => {
  //    if (success !== undefined && success !== null && success["file"] != '') {
  //      if (isEng) {
  //        this.PDFEnglishString = this.sanitizer.bypassSecurityTrustResourceUrl(success["file"]);
  //      }

  //      else {
  //        this.PDFHindiString = this.sanitizer.bypassSecurityTrustResourceUrl(success["file"]);
  //      }
  //    }
  //    else {
  //      if (isEng) {
  //        this.PDFEnglishString = null;
  //      }

  //      else {
  //        this.PDFHindiString = null;
  //      }
  //    }

  //  }, error => {
  //    console.error(error);
  //  });
  //}

  public getLatestProgram(page: number, id: string) {
    this.modelRequest.page = page;
    this.modelRequest.id = id;
    this.modelRequest.text = this.searchBox;
    this.modelRequest.date = this.date;
    this.eventService.GetLatestProgramEventListPost(this.modelRequest).subscribe(success => {
      if (success !== undefined && success !== null) {
        this.latestEventProgramModels = success;
      }
    }, error => {
      console.error(error);
    });
  }



  public getGalleryImagedata(id: string) {
    this.modelGalleryRequest.id = Number(id);
    this.mediaService.GalleryDetailsByIdLatest(this.modelGalleryRequest).subscribe(success => {
      if (success !== undefined && success !== null) {
        //  //;
        this.galleryDetail = success;
        this.totalPage = Number(this.galleryDetail.length);
        //this.setPage(1);
      }
    }, error => {
      console.error(error);
    });
  }

  public getPressReleasedata(id: string) {
    this.pesReqModel.id = Number(id);
    this.eventService.PressReleaseByIdLatest(this.pesReqModel).subscribe(success => {
      if (success !== undefined && success !== null) {
        this.programPressRelease = success;

      }
    }, error => {
      console.error(error);
    });
  }
  public getMessageListdata() {

    this.mediaService.GetProgramMessageListForFront(1, 3, Number(this.id)).subscribe(success => {

      if (success !== undefined && success !== null) {
        this.messageListModel = success;
      }
    }, error => {
      console.error(error);
    });
  }


  //Jankalyan API
  GetPressReleaseData() {
    this.generalModel = undefined;
    let filterModel = new PressReleaseFrontSearchModel();
    filterModel.PageSize = 3;
    filterModel.IsBase64File = false;
    filterModel.EntryNumber = this.indexModel.EntryNumber;
    this.newsService.GetPressReleaseList(filterModel).subscribe((response) => {
      if (response.IsSuccess) {
        this.generalModel = response.Data.Data[0];
        this.generalModel.GeneralDescription = this.sanitizer.bypassSecurityTrustHtml(this.generalModel.GeneralDescription);
      } else {
        this.generalModel = {} as InAugrationListModel;
      }
    }, (error) => {
      this.generalModel = {} as InAugrationListModel;
      console.error(error);
    });
  }

  
  GetTopPressRelease() {
    this.PressReleaseListModel=undefined;
    let filterModel = new PressReleaseFrontSearchModel();
    filterModel.PageSize = 3;
    filterModel.OrderBy = 'CreatedDate';
    filterModel.OrderByAsc = 0;
    filterModel.IsBase64File = false;
    // filterModel.DepartmentCode = this.indexModel.DepartmentCode;
    filterModel.DepartmentCode = 5;
    filterModel.CategoryCode = 36;
    filterModel.SubCategoryCode = 49;

    this.newsService.GetPressReleaseList(filterModel).subscribe((response) => {
      if (response.IsSuccess) {
        this.PressReleaseListModel = response.Data.Data;
      }else{
        this.PressReleaseListModel=[];
      }
    }, (error) => {
      this.PressReleaseListModel=[];
      console.error(error);
    });
  }
}


interface GalleryDetailModel {

  ProgramAddress: string;
  ProgramAddressHindi: string;
  ImageName: string;
  ProgramDate: string;
  ProgramSubcategory: string;
  ProgramSubcategoryhindi: string;
  ProgramSubjecthindi: string;
  ProgramSubject: string;
  ProgramDateHindi: string;

}


interface MessageDetailModel {
  ProgramId: number;
  ProgramCategoryId: number;
  ProgramSubCategoryId: number;
  ProgramDate: string;
  ProgramDistrictId: number;
  ProgramLocationId: number;
  ProgramAddress: string;
  ProgramAddressHindi: string;
  ProgramLanguage: string;
  ProgramSubject: string;
  ProgramSubjectHindi: string;
  ProgramSpeech: string;
  ProgramSpeechHindi: string;
  Keywords: string;
  KeywordsHindi: string;
  SpeechPdf: string;
  Pdf: string;
  isactive: boolean;
  Isdeleted: boolean;
  programstateid: number;
  programtime: string;
  userid: string;
  IsShowLatest: string;
  KeyImage: string;
  foldername: string;
  PopupImages: string;
  PdfEnglish: string;
  status: string;
  district: string;
  ProgramCategory: string;
  ProgramCategoryhindi: string;
  ProgramSubCategoryhindi: string;
  ProgramSubCategory: string;
  totalImages: number;
  totalAudio: number;
  totalVideo: number;
}


export class LatestEventProgramModel {
  ProgramId: number;
  ProgramCategoryId: number;
  ProgramSubCategoryId: number;
  ProgramDate: string;
  ProgramDistrictId: number;
  ProgramLocationId: number;
  ProgramAddress: string;
  ProgramAddressHindi: string;
  ProgramLanguage: string;
  programSubject: string;
  programSubjectHindi: string;
  ProgramSpeech: string;
  ProgramSpeechHindi: string;
  Keywords: string;
  KeywordsHindi: string;
  SpeechPdf: string;
  Pdf: string;
  IsActive: boolean;
  IsDeleted: boolean;
  ProgramStateId: number;
  ProgramTime: string;
  UserId: string;
  IsShowLatest: string;
  KeyImage: string;
  FolderName: string;
  PopupImages: string;
  PdfEnglish: string;
  Status: string;
  District: string;
  ProgramCategory: string;
  ProgramCategoryhindi: string;
  ProgramSubCategoryhindi: string;
  ProgramSubCategory: string;
  TotalEvents: number;
  ProgramDateHindi: string;
  BannerImages: string;
}

export class PressReleaseModel {
  ProgramId: number;
  ProgramCategoryId: number;
  ProgramSubCategoryId: number;
  ProgramDate: string;
  ProgramDistrictId: number;
  ProgramLocationId: number;
  ProgramAddress: string;
  ProgramAddressHindi: string;
  ProgramLanguage: string;
  ProgramSubject: string;
  ProgramSubjectHindi: string;
  ProgramSpeech: string;
  ProgramSpeechHindi: string;
  Keywords: string;
  KeywordsHindi: string;
  SpeechPdf: string;
  Pdf: string;
  ProgramStateId: number;
  ProgramTime: string;
  UserId: string;
  IsShowLatest: string;
  KeyImage: string;
  FolderName: string;
  PdfEnglish: string;
  District: string;
  ProgramCategory: string;
  ProgramCategoryhindi: string;
  ProgramSubCategoryhindi: string;
  ProgramSubCategory: string;
  ProgramDateHindi: string;
  BannerImages: string;
}


