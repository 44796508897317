export enum AddressType {
  Office,
  Telephone, Email
}

export enum RajAdvt_AchievementCategoryEnum {
  SidebarAllWebsites = "Sidebar All Websites",
  SidebarAllWebsitesCode = "73",

}

export enum RajAdvt_AchievementSubCategoryEnum {
  SidebarAdd_PDF = "PDF",
  SidebarAdd_PDFCode = 283,

  SidebarAdd_URL = "URL",
  SidebarAdd_URLCode = 284,

  SidebarAdd_Image = "Image",
  SidebarAdd_ImageCode = 285,

  SidebarAdd_Data = "Data",
  SidebarAdd_DataCode = 286,

  SidebarAdd_Button = "Button",
  SidebarAdd_ButtonCode = 287,
}

export enum DepartmentCodeEnum {
  CmoWebsiteCode = 980,
  JankalyanWebPortal = 982

}

export enum PressReleaseCategoryTypeEnum {

  SpecialRelease = "SpecialRelease",
  SpecialReleaseCode = 41,

  PressRelease = "PressRelease",
  PressReleaseCode = 36,
}

export enum PressReleaseCMOPRCategoryEnum {

  ImportantDecisionsOnFile = "ImportantDecisionsOnFile",
  ImportantDecisionsOnFileCode = 30712,

  MegaEvent = "MegaEvent",
  MegaEventCode = 31128,
}