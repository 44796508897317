<!-- <div class="bg-scroll pos-rel breadcrumbs-page-img">
  <div class="container">


    <h1>{{'Home_OUR_SPEECH'| translate}}</h1>

  </div>
</div> -->
<div class="breadcrumbs-page-img">
  <div class="banner-hight-2">
    <h1>{{'Home_OUR_SPEECH'| translate}}</h1>
    <div class="inner-page-banner">
      <img src="assets/images/cmo-banner.png" alt="banner" class="cmo-banner">
    </div>
  </div>
</div>
<main id="main">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
      <li class="breadcrumb-item"><a href="javascript:void(0)">{{'Media_CornerSubNav'| translate}}</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{'Nav_Speech'| translate}}</li>
    </ol>
  </nav>

  <section class="program_list">
    <div class="container">
      <div class="row">
        <div class="col-sm-6 col-lg-3 program_list_box" *ngFor="let item of cmSpeechModel; let i = index">
          <div class="card wow"
            [ngClass]="[i%3 <1 ? 'fadeInLeft' : i%3 <2? 'fadeInUp':i%3 <3? 'fadeInRight':'fadeInLeft' ]">
            <div class="program-img-bx ">
              <a routerLink="/speechdetail/{{item.Id}}">
                <img src="{{item.ThumbnailPath}}" onError="this.src='../../assets/images/program-no-img.png'"
                  class="card-img-top" alt="">
              </a>
            </div>
            <div class="card-body">
              <a routerLink="/speechdetail/{{item.Id}}">
                <p class="card-text"
                  *ngIf="(this.translate.currlang == 'hi' && item.Description?.length<=50 )||(this.translate.currlang != 'hi' && item.Description?.length<=50)">
                  {{this.translate.currlang == 'hi' ? item.Description : item.Description}}
                </p>
                <p class="card-text"
                  *ngIf="(this.translate.currlang == 'hi' && item.Description?.length>50 )||(this.translate.currlang != 'hi' && item.Description?.length>50)">
                  {{(this.translate.currlang == 'hi' ? item.Description :
                  item.Description)|slice:0:50}}...
                </p>
              </a>
              <a class="program_btn_more" routerLink="/speechdetail/{{item.Id}}">{{'Read_More'| translate}} </a>
            </div>

            <div class="card-footer">
              <small class="text-muted"><i class="fa fa-calendar"></i> {{this.translate.currlang == 'hi' ?
                item.DateHindi : item.Date | date : 'd MMM, y'}}</small>
            </div>
          </div>
        </div>

        <nav aria-label="Page navigation example" class="w-100 text-center mt-4" *ngIf="totalRecords > pageSize">

          <ul class="pagination d-inline-flex" *ngIf="pager.pages && pager.pages.length">
            <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
              <a class="page-link" (click)="GetCMSpeeches(1)">{{'First'| translate}}</a>
            </li>
            <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
              <a class="page-link" (click)="GetCMSpeeches(pager.currentPage - 1)">{{'Previous'| translate}}</a>
            </li>
            <li class="page-item" *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
              <a class="page-link" (click)="GetCMSpeeches(page)">{{page}}</a>
            </li>
            <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
              <a class="page-link" (click)="GetCMSpeeches(pager.currentPage + 1)">{{'Next'| translate}}</a>
            </li>
            <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
              <a class="page-link" (click)="GetCMSpeeches(pager.totalPages)">{{'Last'| translate}}</a>
            </li>
          </ul>
        </nav>
        <div class="col-lg-12" *ngIf="this.cmSpeechModel?.length<=0">
          <h3 class=" text-center">{{'No_Record_Found'|translate}}</h3>
        </div>
      </div>
    </div>
  </section>
</main>