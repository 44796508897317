<app-advertisment></app-advertisment>



<!-- 
<div id="carouselExampleCaptions" class="carousel slide carousel-home" data-bs-ride="carousel">
  <ol class="carousel-indicators">
    <li data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active"></li>
    <li data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"></li>
    <li data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"></li>
  </ol>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="assets/images/banner-1.jpg" alt="banner" class="d-block w-100">
      <div class="carousel-caption d-none d-md-block">
        <div class="banner-title">सुशासन दिवस पर प्रदेश को अग्रणी बनाने का संकल्प</div>
      </div>
    </div>
    <div class="carousel-item">
      <img src="assets/images/banner-1.jpg" alt="banner" class="d-block w-100">
      <div class="carousel-caption d-none d-md-block">
        <div class="banner-title">सुशासन दिवस पर प्रदेश को अग्रणी बनाने का संकल्प</div>
      </div>
    </div>
    <div class="carousel-item">
      <img src="assets/images/banner-1.jpg" alt="banner" class="d-block w-100">
      <div class="carousel-caption d-none d-md-block">
        <div class="banner-title">सुशासन दिवस पर प्रदेश को अग्रणी बनाने का संकल्प</div>
      </div>
    </div>
  </div>
</div> -->



<div id="carouselExampleControls" class="carousel slide " data-bs-ride="carousel">

  <!-- <ol class="carousel-indicators">
    <li data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active"></li>
    <li data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"></li>
    <li data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"></li>
  </ol> -->


  <div class="carousel-inner">
    <div class="carousel-item " data-bs-ride="carousel" data-bs-interval="5000"
      *ngFor="let item of bannerImagesModel; let k = index" [class.active]="k==0">
      <img alt="banner" class="d-block w-100" src="{{item.ImagePath}}"
        onerror="this.src='assets/images/banner-img.png'">

      <!-- <img alt="banner" class="d-block w-100" src="assets/images/banner-img.png"> -->

      <div class="carousel-caption d-md-block carousel-caption-in">
        <div class="banner-title header-banner">

          <h2> {{'Profile_CM_Name_txt' | translate}}</h2>
          <div class="sub-title title-m">{{"Profile_Hon'ble_Chief_Minister" | translate}}</div>
          <!-- <div class="banner-sub-img"><img alt="banner" class="cm-raj" src="assets/images/cm-rajsthan.png"></div> -->
          <!-- <div class="sub-banner-text">On 12 December 2023, Sh. Bhajan Lal Sharma was appointed as the 14th Chief
            Minister of Rajasthan by the Bharatiya Janata Party along with two deputy CM's, Diya Kumari and Prem Chand
            Bairwa. He was sworn in as Rajasthan’s new CM on 15 Dec 2023.</div> -->
          <div class="sub-banner-text"
            [innerHTML]="(this.translate.currlang == 'hi' ? CMProfileModelViewModel?.DetailsInHindi.length>700 ? CMProfileModelViewModel?.DetailsInHindi.substring(0,700) +'...' : CMProfileModelViewModel?.DetailsInHindi :
              CMProfileModelViewModel?.Details ? CMProfileModelViewModel?.Details.substring(0,700) +'...' : CMProfileModelViewModel?.Details)">
          </div>
          <div class="sub-banner-btn">
            <a href="javascript:void(0)" routerLink="/cm-profile">{{'Read_More' | translate}} <i
                class="fa fa-long-arrow-right" aria-hidden="true"></i></a>
            <a href="javascript:void(0)" routerLink="/former-cm-list"> {{'FormerCM_txt' | translate}} <i
                class="fa fa-long-arrow-right" aria-hidden="true"></i></a> <!-- (click)="scrollToTarget()"-->
          </div>
          
          <div class="stay wow fadeInLeft">
            <div [innerHTML]="translate.currlang == 'hi'  ? TaglineModel?.DetailsInHindi : TaglineModel?.Details"></div>
            <!-- <h3>{{'Slogan_txt' | translate}}</h3> -->
            <!-- <div class="stay-sub"> {{'SloganDescription_txt' | translate}}</div> -->
          </div>

        </div>
      </div>
    </div>
  </div>
  <a class="carousel-control-prev" style="width: 5%;" href="#carouselExampleControls" role="button" data-bs-slide="prev">
    <!-- <span class="carousel-control-prev-icon" aria-hidden="true"></span> -->
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" style="width: 5%;" href="#carouselExampleControls" role="button" data-bs-slide="next">
    <!-- <span class="carousel-control-next-icon" aria-hidden="true"></span> -->
    <span class="sr-only">Next</span>
  </a>
</div>

<!-- <section class="top_news"> -->
  <!-- <marquee onmouseover="this.stop();" onmouseout="this.start();" scrollamount="5"> -->
  <!-- <div class="container-fluid">
    <ul *ngIf="newsImagesModel?.length>0">
      <li *ngFor="let item of newsImagesModel;">
        <a href="{{item.Url}}" target="_blank"> {{translate.currlang=='hi'? item.AchievementHindi :
          item.Achievement}}</a>
      </li>
    </ul>
  </div> -->
  <!-- </marquee> -->
<!-- </section> -->


<section class="home-banner-slider header-fix-space">
  <div class="container-fluid p-0">
    <div class="row m-0">
      <div class="col-slider slider-main">
        <div id="carouselExampleControls" class="carousel slide  top_slider" data-bs-ride="carousel"
          *ngIf="!bannerImagesModel">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img
                src="https://jankalyanfile.rajasthan.gov.in//Content/UploadFolder/Advertisement/Achievements/2023/Oct/24597_ACH_e7eeeace-b115-4185-a6d0-31b77af430af.jpeg"
                onerror="this.src='../../assets/img/intro-carousel/1.jpg'" alt="First slide">
            </div>
            <div class="carousel-item">
              <img
                src="https://jankalyanfile.rajasthan.gov.in//Content/UploadFolder/Advertisement/Achievements/2023/Oct/24597_ACH_e7eeeace-b115-4185-a6d0-31b77af430af.jpeg"
                onerror="this.src='../../assets/img/intro-carousel/2.jpg'" alt="Second slide">
            </div>
            <div class="carousel-item">
              <img
                src="https://jankalyanfile.rajasthan.gov.in//Content/UploadFolder/Advertisement/Achievements/2023/Oct/24597_ACH_e7eeeace-b115-4185-a6d0-31b77af430af.jpeg"
                onerror="this.src='../../assets/img/intro-carousel/3.jpg'" alt="Third slide">
            </div>
          </div>
          <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>


      </div>



      <!-- <div class="col-advertisement">
        <iframe width="100%" height="100%" [src]="rajAdvtSideBarUrl | safeUrl"></iframe>
      </div> -->



    </div>
  </div>
</section>



<!-- <section class="homepage-about">
  <div class="homepage-about-wrapper">
  <div class="homepage-about-img"><img src="assets/img/bhajanlal-sir.png" alt="Shri Bhajanlal Sharma"></div>
  <div class="homepage-about-info">
    <p>“Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the  galley of type and scrambled...”</p>
    <div class="text-right mt-3">
    <strong class="d-block">Shri Bhajanlal Sharma</strong>
    <small>Hon'ble Chief Minister, Rajasthan</small>
  </div>
  </div>
</div>
</section> -->

<!-- Quick Links -->
<app-quick-link></app-quick-link>


<main id="main">

  <!--==========================
     About Section
   ============================-->
  <!--<app-cmo-message></app-cmo-message>-->
  <!--<section id="about" class="wow fadeInUp cmo-message">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 content">
          <div class="d-flex">
            <div class="cmo-img align-self-center"><img class="img-fluid" src="assets/img/cmo_message.png" alt=""></div>
            <div class="msg_text align-self-center">
              <h3>"So, this is a call to make Rajasthan Smart, Sustainable & Progressive & contribution of every single person counts in our effort for a Developed Rajasthan. "</h3>
              <div class="name">Bhajan Lal Sharma, <span>Chief Minister of Rajasthan</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>-->
  <!-- #about -->

  <app-program></app-program>

  <!--<app-recent-news></app-recent-news>-->
  <!--<app-relief-fund></app-relief-fund>-->

  <!-- <app-gallery></app-gallery> -->

  <!-- <app-photo-video-uploader-gallery-section></app-photo-video-uploader-gallery-section> -->

  <app-home-video></app-home-video>

  <app-home-mainpress></app-home-mainpress>

  <!--==========================
        Contact Section
      ============================-->
  <app-home-contact></app-home-contact>

  <!--<section id="contact" class="wow fadeInUp">
    <div class="container">
      <div class="section-header">
        <h2>Contact Us</h2>

      </div>

      <div class="row contact-info">
        <div class="col-md-4">
          <div class="contact-address">
            <i class="ion-ios-location-outline"></i>
            <h3>Address</h3>
            <address>
              Nodal Officer : <br>
              Shri Rajesh Saini,
              OIC (Computer Cell), <br>
              CMO Jaipur
            </address>
          </div>
        </div>

        <div class="col-md-4">
          <div class="contact-phone">
            <i class="ion-ios-telephone-outline"></i>
            <h3>Contact Number</h3>
            <p>
              <a href="tel:+91-141-2921063">
                +91-141-2921063
              </a>
            </p>
          </div>
        </div>

        <div class="col-md-4">
          <div class="contact-email">
            <i class="ion-ios-email-outline"></i>
            <h3>Email</h3>
            <p><a href="mailto:info@example.com">info.cmo@rajasthan.gov.in</a></p>
          </div>
        </div>

      </div>
    </div>

  </section>-->




</main>


<div class="modal fade" id="cmProfile" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{'CM_Profile_txt' | translate}}</h5>
        <button type="button" class="close close-in" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="text-2">
          <div [innerHTML]="(this.translate.currlang == 'hi' ? CMProfileModelViewModel?.DetailsInHindi :
          CMProfileModelViewModel?.Details)"></div>
        </div>
      </div>
    </div>
  </div>
</div>