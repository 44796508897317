<!--<section class="gallery">
  <div class="container">
    <div class="row">
      <div class="heading-title text-center col-sm-12">{{'Home_OUR_GALLERY'| translate}}</div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4" *ngFor="let program of galleryList">
        <div class="gallery-bx">
          <a routerLink="/gallerydetail/{{program.programId}}">
            <img src="http://cmoweb.projectstatus.in/Program/Image/Thumbnail/Fr_{{program.ImageName}}" alt="" class="img-fluid">
          </a>
          <div class="gallery-text">
            <h2>{{this.translate.currlang == 'hi' ? program.ProgramSubjectHindi : program.ProgramSubject}}</h2>
            <div class="date"><i class="fas fa-calendar-alt"></i> &nbsp;{{this.translate.currlang == 'hi' ? program.ProgramDateHindi : program.ProgramDate}}</div>
          </div>
        </div>
      </div>
      <div class="text-center col-sm-12"><a routerLink="/gallery-list" class=" btn btn-red mg-top-50 btn-round text-uppercase text-center">{{'View_More'| translate}}</a></div>
    </div>
  </div>
</section>-->


<section class="new_gallery wow fadeInUp ">
  <div class="container">
    <div class="section-header">
      <h2 class="m-0 p-0">{{'EVENTS'| translate}}</h2>
      <div class="section-header-action" *ngIf="generalModel?.length">
        <a routerLink="/mega-event-list" class="gray-btn gray-orange">{{'View_More'| translate}}</a>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row row-eq-height no-gutters">
      <div class='col-sm-4 col-12 col-md-4 col-lg-4' *ngFor="let program of generalModel; let i = index">
        <div class="card h-100 pos-rel justify-content-top align-items-top" *ngIf="i < 6">
          <a href="javascript:void(0)" data-toggle="modal" [attr.data-target]="'#Gal_'+ program.Id"
            class="new_gallery-items">
            <div class="new_gallery-items-img">
              <img class="img-fluid" alt="" src="{{program.HomePageImageUrl}}" />
            </div>
            <div class="new_gallery-items-info">
              <div
                *ngIf="(this.translate.currlang == 'hi' && program.Description.length<=80 )||(this.translate.currlang != 'hi' && program.Description.length<=80)">
                {{this.translate.currlang == 'hi' ? program.Description : program.Description}}
              </div>
              <div
                *ngIf="(this.translate.currlang == 'hi' && program.Description.length>80 )||(this.translate.currlang != 'hi' && program.Description.length>80)">
                {{(this.translate.currlang == 'hi' ? program.Description : program.Description)|
                slice:0:80}}...</div>
            </div>
          </a>
        </div>
      </div>
    </div>

    <div class="text-center" *ngIf="!generalModel?.length">
      <h3>Record Not Found</h3>
    </div>
  </div>

  <div class="modal new_gallery_slide  fade bd-example-modal-lg" [attr.id]="'Gal_'+prgn.ProgramId" tabindex="-1"
    role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" *ngFor="let prgn of galleryList">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span
              aria-hidden="true">&times;</span> </button>
          <div [attr.id]="'demo'+prgn.ProgramId" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item" *ngFor="let row of prgn.ProgramGallery let k = index" [class.active]="k==0">
                <!--<img class="img-fluid" alt="" src="http://cmoweb.projectstatus.in/Program/Image/Thumbnail/Fr_{{row.ImageName}}" />-->
                <img class="img-fluid" alt="" src="{{imageUrl}}{{row.ImageName}}" />

                <div class="carousel-caption">
                  <div class="title">{{this.translate.currlang == 'hi' ? row.ProgramSubjectHindi : row.ProgramSubject}}
                  </div>
                  <div class="location">{{this.translate.currlang == 'hi' ? row.ProgramAddressHindi :
                    row.ProgramAddress}}, <span class="date">{{this.translate.currlang == 'hi' ? row.ProgramDateHindi :
                      row.ProgramDate}}</span></div>
                </div>
              </div>
              <a class="carousel-control-prev" href="#demo{{prgn.ProgramId}}" data-slide="prev"> <span
                  class="carousel-control-prev-icon"></span> </a> <a class="carousel-control-next"
                href="#demo{{prgn.ProgramId}}" data-slide="next"> <span class="carousel-control-next-icon"></span> </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>