import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RequestAddModel } from '../../Model/hcm-invitation/request-add-model.model';
import { BaseService } from '../baseservice';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppSettings } from '../../common/AppSettings';

@Injectable({
  providedIn: 'root'
})

export class RequestService {
  private form: UntypedFormGroup;
  private model: RequestAddModel;
  constructor(
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _httpService: BaseService) {
    this.model = new RequestAddModel();
    this.form = this.initializeForm();
  }

  get Form() {
    return this.form;
  }
  get letterNo() {
    return this.Form.get('letterNo');
  }
  get senderName() {
    return this.Form.get('senderName');
  }
  get organizationName() {
    return this.Form.get('organizationName');
  }
  get senderTypeCode() {
    return this.Form.get('senderTypeCode');
  }
  get senderEmail() {
    return this.Form.get('senderEmail');
  }
  get senderDistrictCode() {
    return this.Form.get('senderDistrictCode');
  }
  get senderPhoneNo() {
    return this.Form.get('senderPhoneNo');
  }
  get senderAddress() {
    return this.Form.get('senderAddress');
  }
  get invitationCategoryCode() {
    return this.Form.get('invitationCategoryCode');
  }
  get subject() {
    return this.Form.get('subject');
  }
  get programDistrictCode() {
    return this.Form.get('programDistrictCode');
  }
  get programVenue() {
    return this.Form.get('programVenue');
  }
  get comments() {
    return this.Form.get('comments');
  }
  get vipCodes() {
    return this.Form.get('vipCodes');
  }
  get requestDateFrom() {
    return this.Form.get('requestDateFrom');
  }
  get requestDateTo() {
    return this.Form.get('requestDateTo');
  }
  get attachmentPath() {
    return this.Form.get('attachmentPath');
  }
  get attachmentBasePath() {
    return this.Form.get('attachmentBasePath');
  }
  
  public addRequest(model: RequestAddModel) {
    return this._httpService.post(AppSettings.API_ENDPOINT_RW_Jankalyan + AppSettings.InvitationRequestAddUrl, model)
      .pipe(
        map(response => {
          return response;
        })
      );
  }


  private initializeForm(): UntypedFormGroup {
    return this._formBuilder.group({
      senderName: [this.model.senderName, Validators.required],
      organizationName: [this.model.organizationName],
      senderTypeCode: [this.model.senderTypeCode, Validators.required],
      senderEmail: [this.model.senderEmail],
      senderDistrictCode: [this.model.senderDistrictCode, Validators.required],
      senderPhoneNo: [this.model.senderPhoneNo],
      senderAddress: [this.model.senderAddress, Validators.required],
      invitationCategoryCode: [this.model.invitationCategoryCode, Validators.required],
      subject: [this.model.subject, Validators.required],
      programDistrictCode: [this.model.programDistrictCode, Validators.required],
      programVenue: [this.model.programVenue],
      comments: [this.model.comments],
      requestDateFrom: [this.model.requestDateFrom],
      requestDateTo: [this.model.requestDateTo],
      attachmentBasePath: [this.model.attachmentBasePath],
      attachmentPath: [this.model.attachmentPath],
    });
  }
}
