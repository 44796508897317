
<section id="testimonials" class="wow fadeInUp">
  <div class="container">
    <div class="section-header">
      <h2>Latest News</h2>
    </div>
    <div id="ThumbnailCarousel" class="carousel slide col-xs-12" data-ride="carousel">
      <div class="carousel-inner">
        <div class="row carousel-item {{ i == 0 ? 'active' : '' }}" *ngFor="let chunkProducts of Newsprograms;let i =index;">

          <div class="col-md-4 col-sm-6" *ngFor="let slider of chunkProducts;">
            <div class="testimonial-item d-flex">
              <div class="date align-self-center">
                {{slider.ProgramDate | date : 'dd'}}  <br>
                <div style="font-size:13px;">{{slider.ProgramDate| date : 'MMMM'}}</div>
              </div>
              <div class="detail align-self-center">
                <a href="javascript:void(0)">{{this.translate.currlang == 'hi' ? slider.ProgramSubjectHindi:slider.ProgramSubject}}  </a> <span><i class="ion-ios-location-outline"></i> {{this.translate.currlang == 'hi' ? slider.ProgramAddressHindi:slider.ProgramAddress}} </span> <span>
                  <i class="fa fa-calendar" aria-hidden="true"></i> &nbsp;{{slider.ProgramDate }}
                </span>
              </div>
            </div>
          </div>
          <!--<div class="col-md-4 col-sm-6">
            <div class="testimonial-item d-flex wow fadeInUp">
              <div class="date align-self-center">
                09 <br>
                <div style="font-size:13px;">September</div>
              </div>
              <div class="detail align-self-center"> <a href="javascript:void(0)"> 5 crore cheque donated to Chief Minister's Relief Fund. </a> <span><i class="ion-ios-location-outline"></i> Jaipur</span> <span> <i class="fa fa-calendar" aria-hidden="true"></i> &nbsp;09/09/2019</span> </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6">
            <div class="testimonial-item d-flex wow fadeInUp">
              <div class="date align-self-center">
                09 <br>
                <div style="font-size:13px;">September</div>
              </div>
              <div class="detail align-self-center"> <a href="javascript:void(0)"> 5 crore cheque donated to Chief Minister's Relief Fund. </a> <span><i class="ion-ios-location-outline"></i> Jaipur</span> <span> <i class="fa fa-calendar" aria-hidden="true"></i> &nbsp;09/09/2019</span> </div>
            </div>
          </div>-->
        </div>
        <!--<div class="row carousel-item">
            <div class="col-md-4 col-sm-6">
              <div class="testimonial-item d-flex wow fadeInUp">
                <div class="date align-self-center">
                  09 <br>
                  <div style="font-size:13px;">September</div>
                </div>
                <div class="detail align-self-center"> <a href="javascript:void(0)"> 5 crore cheque donated to Chief Minister's Relief Fund. </a> <span><i class="ion-ios-location-outline"></i> Jaipur</span> <span> <i class="fa fa-calendar" aria-hidden="true"></i> &nbsp;09/09/2019</span> </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="testimonial-item d-flex wow fadeInUp">
                <div class="date align-self-center">
                  09 <br>
                  <div style="font-size:13px;">September</div>
                </div>
                <div class="detail align-self-center"> <a href="javascript:void(0)"> 5 crore cheque donated to Chief Minister's Relief Fund. </a> <span><i class="ion-ios-location-outline"></i> Jaipur</span> <span> <i class="fa fa-calendar" aria-hidden="true"></i> &nbsp;09/09/2019</span> </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="testimonial-item d-flex wow fadeInUp">
                <div class="date align-self-center">
                  09 <br>
                  <div style="font-size:13px;">September</div>
                </div>
                <div class="detail align-self-center"> <a href="javascript:void(0)"> 5 crore cheque donated to Chief Minister's Relief Fund. </a> <span><i class="ion-ios-location-outline"></i> Jaipur</span> <span> <i class="fa fa-calendar" aria-hidden="true"></i> &nbsp;09/09/2019</span> </div>
              </div>
            </div>
        </div>-->
      </div>
      <!--      <a class="carousel-control-prev" href="#ThumbnailCarousel" role="button" data-slide="prev"> <span class="carousel-control-prev-icon" aria-hidden="true"></span> <span class="sr-only">Previous</span> </a> <a class="carousel-control-next" href="#ThumbnailCarousel" role="button" data-slide="next"> <span class="carousel-control-next-icon" aria-hidden="true"></span> <span class="sr-only">Next</span> </a> -->
    </div>
  </div>
</section>
<!--<section class="recent-news">
  <div class="container">
    <div class="row" >
      <div class="heading-title text-center col-sm-12">{{'Home_RECENT_NEWS'| translate  }}
</div>


      <div  class="col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngFor="let news of Newsprograms">
        <div class="r-news-bx bx-shadow">
          <a routerLink="/pressreleasedetail/{{news.ProgramId}}">
            <h2 class="pro-title"> {{this.translate.currlang == 'hi' ? news.ProgramSubCategoryhindi: news.ProgramSubCategory}}:{{this.translate.currlang == 'hi' ? news.ProgramDateHindi : news.ProgramDate}} </h2>
          </a>

          <p>
            {{this.translate.currlang == 'hi' ? news.ProgramSubjectHindi:news.ProgramSubject}}
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="text-center col-sm-12"><a routerLink="/pressrelease-list" class=" btn btn-red mg-top-50 mg-btm-50 btn-round text-uppercase text-center">{{'View_More'| translate}}</a></div>
    </div>
  </div>
</section>-->
