import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { PagerService } from '../pager.service';
import { TranslateService } from '../translate.service';
import { MediaService } from './../services/media.services';
import { CMLiveProgram } from '../Model/cm-live-programs.model';
import { CmLiveProgramsService } from '../services/cm-live-programs.service';
import { CommonService } from '../services/common.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
@Component({
  selector: 'app-videogallery',
  templateUrl: './videogallery.component.html',
  styleUrls: ['./videogallery.component.css'],
  providers: [MediaService,CmLiveProgramsService,DatePipe]
})

export class VideogalleryComponent implements OnInit {

  public videos: VideoList[];
  display = 'none'; // default Variable
  Url: any;
  imageUrl = environment.imageUrl + 'Program/Video/VideoImages/App_';
  videoUrl = environment.imageUrl + 'Program/Video/';
  ngclass = 'mat-video-responsive';
  title: '';
  width = 600;
  height = 337.5;
  currentTime = 0;
  autoplay = false;
  preload = true;
  loop = false;
  quality = true;
  download = true;
  fullscreen = true;
  showFrameByFrame = false;
  keyboard = true;
  color = 'primary';
  spinner = 'spin';
  poster = 'assets/NASA.jpg';
  overlay = null;
  muted = false;
  // pager object
  pager: any = {};
  // paged items
  pagedItems: any[];
  currentPage = 1;
  totalPage: number;
  CMLiveProgramsModel = new CMLiveProgram();
  CMLiveProgramsData: any[];
  searchBox = '';
  pageSize = 9;
  today = new Date();
  fromDate:any;
  toDate:any;
  days = 10; // Days you want to subtract
  date = new Date();
  lastDate = new Date('2023-12-15') //new Date(this.date.getTime() - (this.days * 24 * 60 * 60 * 1000));
  searchText:any = '';
  totalRecords:any;
  isSpeeches = false;

  constructor(public http: HttpClient, @Inject('BASE_URL') public baseUrl: string, public readonly commonService: CommonService, private datePipe: DatePipe, private pagerService: PagerService, public translate: TranslateService, private mediaService: MediaService, private readonly cmLivePrograms: CmLiveProgramsService, private router: Router
  ) {
    this.videos = [];

  }
  ngOnInit() {
    this.isSpeeches = this.router.url.includes("/speeches");
    this.fromDate = this.datePipe.transform(this.lastDate,'yyyy-MM-dd')
    this.toDate = this.datePipe.transform(this.today,'yyyy-MM-dd');
    this.CMLiveProgram(this.currentPage,this.pageSize);
    // this.getdata(this.currentPage);
  }
  setLang(lang: string) {
    this.translate.use(lang);
  }

  onPageSize(evt:any){
    this.pageSize = evt.target.value
    this.CMLiveProgram(this.currentPage,this.pageSize)
  }

  onSearch(){
    this.CMLiveProgram(this.currentPage,this.pageSize)
  }

  public getdata(page: number) {

    this.mediaService.GetProgramAllVideoListForFront(page).subscribe(success => {
      if (success !== undefined && success !== null) {
      // ;
        this.videos = success;
        this.setPage(page,this.pageSize);
      }
    }, error => {
      console.error(error);
    });


  }
  CMLiveProgram(page: number,pagesize:any) {
    // this.CMLiveProgramsData = [];
    this.CMLiveProgramsModel.AdvanceSearchModel.CategoryCode = "126";
    this.CMLiveProgramsModel.AdvanceSearchModel.SubCategoryCode = 0;
    this.CMLiveProgramsModel.AdvanceSearchModel.DepartmentCode = "85";
    this.CMLiveProgramsModel.PageSize = pagesize;
    this.CMLiveProgramsModel.IsPostBack = false
    this.CMLiveProgramsModel.OrderByAsc = 0
    this.CMLiveProgramsModel.OrderBy = "AchievementDATE";
    this.CMLiveProgramsModel.ToDate = this.toDate;
    this.CMLiveProgramsModel.FromDate = this.fromDate;
    this.CMLiveProgramsModel.AdvanceSearchModel.Search = this.searchText;
    this.CMLiveProgramsModel.Page = page;
    this.cmLivePrograms.GetCMLivePrograms(this.CMLiveProgramsModel).subscribe((res: any) => {
      if (res.Data.Data.length) {
        this.CMLiveProgramsData = res.Data.Data;
        this.totalRecords = res.Data.TotalRecords
        this.CMLiveProgramsData.forEach((ele:any) => {
          const videourl = this.commonService.getYoutubeEmbedUrl(ele.YoutubeURL);
          ele.videoUrl = this.commonService.SafeUrl(videourl);
        });
        this.setPage(page,pagesize);
        console.log(this.totalRecords,"totalRecords",this.totalRecords)
      }
    })
  }
  openModalDialog(d: string) {
    this.display = 'block';
    this.Url = d.replace('.mp4', '.ogv');
    // Set block css
  }

  closeModalDialog() {
    this.display = 'none'; // set none css after close dialog
  }

  setPage(page: number, pagesize:any) {
    window.scroll(0, 0);
    this.currentPage = page;
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }

    // get pager object from service
    if (this.CMLiveProgramsData.length > 0) {
      this.pager = this.pagerService.getPager(Number(this.totalRecords), page, pagesize);
      // get current page of items
      this.pagedItems = this.videos;
    } else {
      this.pagedItems = [];
    }

  }

}

interface VideoList {
  RowNumber: number;
  ProgramId: number;
  ProgramDate: string;
  video: string;
  videoimage: string;
  ProgramSubCategoryhindi: string;
  ProgramSubCategory: string;
  ProgramSubjectHindi: string;
  ProgramSubject: string;
  TotalVideos: number;
}

