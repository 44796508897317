<section id="" class="box-content d-none">

  <div class="container">
    <div class="row press_box">
      <div
        [ngClass]="[speechprogramsMainpress.length!=0 ? 'col-12 col-md-6 col-lg-4 mb-4 mb-md-0' : 'col-12 col-md-6 col-lg-6 mb-4 mb-md-0' ]">
        <div class="section-header">
          <h2 class="m-0 p-0">{{'Press_ReleasesSubNav'| translate}}</h2>
          <div class="section-header-action">
            <a routerLink="/pressrelease-list" class="gray-btn gray-orange"> {{'View_More'| translate}}</a>
          </div>
        </div>

        <app-home-press-release></app-home-press-release>

      </div>

      <div [ngClass]="[speechprogramsMainpress.length!=0 ? 'col-12 col-md-6 col-lg-4' : 'col-12 col-md-6 col-lg-6' ]">
        <app-awards [homepage]="true"></app-awards>
      </div>
      <div class="col-12 col-md-6 col-lg-4" *ngIf="speechprogramsMainpress.length!=0">
        <div class="section-header">
          <h2>{{'Home_OUR_SPEECH'| translate}}</h2>
        </div>
        <div class="press_releases ps2">

          <app-home-speech></app-home-speech>

        </div>
      </div>
    </div>
  </div>
</section>