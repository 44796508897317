<div class="breadcrumbs-page-img">
    <div class="banner-hight-2">
        <h1>PhoneBooth</h1>
        <div class="inner-page-banner">
            <img src="assets/images/cmo-banner.png" alt="banner" class="cmo-banner">
        </div>
    </div>
</div>
<main id="main">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
            <li class="breadcrumb-item active" aria-current="page">Photo Booth</li>
        </ol>
    </nav>
  



    <section>
        <div class="photo-wrap">
            <div class="photo-booth-container">
                <h2>Moments With CM</h2>
                <p>Download your Photographs taken with CM</p>
                <form [formGroup]="uploadForm">
                    <div class="search-panel">
                        <div class="mb-12 mb-2">
                            <label for="formFile" class="form-label">Event Name</label>
                            <select class="form-control2 form-control" (change)="onFileSelect($event)"
                                formControlName="eventName" type="text" id="formFile">

                                <option value="">Select Event</option>
                                <option [value]="data" *ngFor="let data of events">{{data}}</option>
                            </select>
                            <div *ngIf="uploadForm.controls['eventName'].invalid && (uploadForm.controls['eventName'].dirty || uploadForm.controls['eventName'].touched)"
                                class="alert input_error">
                                <div *ngIf="uploadForm.controls['eventName'].errors.required">
                                    Please enter Event Name
                                </div>
                            </div>
                        </div>
                        <div class="mb-12">
                            <label for="formFile" class="form-label">Upload Photo</label>
                            <input class="form-control2 form-control" (change)="onFileSelect($event)" type="file"
                                id="formFile">
                            <!-- <div> -->
                            <div *ngIf="!isfileValid" style="color: red;" class="mt-2">{{fileError}}</div>
                            <p>( {{'DocType_txt2' | translate}} )</p>
                            <!-- </div> -->
                        </div>
                    </div>
                </form>
                <div class="divider">OR</div>
                <div class="capture-container">
                    <video #video autoplay muted playsinline></video>
                    <button (click)="captureImage()" class="use-camera">Use Camera</button>
                    <canvas #canvas></canvas>
                    <!-- <img *ngIf="capturedImage" [src]="capturedImage" alt="Captured Image"> -->
                </div>
                <!-- <a href="javascript:void(0)" class="use-camera">use camera</a> -->
                <div class="search-button-pannel"><a class="search-button" (click)="onSubmit2()">Search</a></div>
            </div>

            <div class="search-result-text" *ngIf="searchResult">
                <strong>Search Result </strong>
                <a href="javascript:void(0)" class="download" (click)="downloadImages()"><i class="fa fa-download"
                        aria-hidden="true"></i> DOWNLOAD ALL</a>
            </div>
            <div class="search-img-box" *ngIf="searchResult">
                <div class="image-holder" *ngFor="let image of searchedImages; let i=index">
                    <div class="icon-downlode-secation">
                        <i class="fa fa-download" aria-hidden="true" (click)="downloadImage(image)"></i>
                    </div>
                    <!-- <a [href]="fileName(image)" data-lightbox="gallery">
                        <img class="img-fluid" [src]="fileName(image)"
                            onError="this.src='../../assets/images/program-no-img.png'"></a> -->
                    <a style="cursor: pointer;" data-bs-toggle="modal" data-bs-target="#galleryBlock">
                        <!-- routerLink="/gallerydetail/{{gallery.Id}}"-->
                        <div class="program-img-bx program-section-image">
                            <img [src]="fileName(image)" onError="this.src='../../assets/images/gallery-no-img.png'"
                                alt="" class="img-fluid" onError="this.src='../../assets/images/program-no-img.png'">
                        </div>
                    </a>
                </div>
                <!-- <div class="image-holder">
                    <a href="#" class="download-icon"><i class="fa fa-download" aria-hidden="true"></i></a>
                    <div class="image-section"><img class="lightboxed" rel="group1" src="img/test1.png"
                            data-link="img/test1.png" alt="Image Alt"></div>
                </div> -->
            </div>
        </div>
    </section>
</main>
<!-- <div class="modal fade galleryBlock" id="HomePageImage" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel"></h5>
                <button type="button" class="close close-in" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="capture-container">
                </div>
            </div>

        </div>
    </div>
</div> -->
<div class="modal fade galleryBlock" id="galleryBlock" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
         
            <div class="modal-body">

                <button type="button" class="close close-in" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                
                <!-- <p *ngIf="IsLoading" class="text-left"><b>Loading...</b></p> -->
                <div id="carouselExampleControls" class="carousel slide mb-2" data-bs-ride="carousel"
                    *ngIf="searchedImages?.length>0">
                    <!-- *ngIf="searchedImages?.ImageAttachments.length>0 || !IsLoading"> -->
                    <div class="carousel-inner">
                        <div class="carousel-item" [ngClass]="[i==0 ? 'active' : '' ]"
                            *ngFor="let item of searchedImages let i = index">
                            <img class="d-block w-100 program-image-part2" [src]="fileName(item)" alt="item{{i}}"
                                onError="this.src='../../assets/images/program-no-img.png'">
                        </div>

                    </div>
                    <a (click)="imageIndex=0" class="carousel-control-prev" href="#carouselExampleControls"
                        role="button" data-bs-slide="prev"> <span class="carousel-control-prev-icon"
                            aria-hidden="true"></span> <span class="sr-only">Previous</span>
                    </a> <a (click)="imageIndex=0" class="carousel-control-next" href="#carouselExampleControls"
                        role="button" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span> <span
                            class="sr-only">Next</span>
                    </a>
                    <div class="carousel-inner" *ngIf="!searchedImages">

                        <img class="d-block w-100" src="../../assets/images/default_img1.png" />


                    </div>

                </div>
                <div class="carousel-inner" *ngIf="searchedImages?.length==0">

                    <!-- <img class="d-block w-100" src={{searchedImages.HomePageImageUrl}} /> -->


                </div>
            </div>

        </div>
    </div>
</div>