<!-- <section class="inner-page-banner">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="page-title">{{'MinisterList'| translate}}</div>
      </div>
    </div>
  </div>
</section> -->

<div class="breadcrumbs-page-img">
  <div class="banner-hight-2">
    <h1>{{'MinisterList'| translate}}</h1>
    <div class="inner-page-banner">
      <img src="assets/images/cmo-banner.png" alt="banner" class="cmo-banner">
    </div>
  </div>
</div>

<main>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
        <!-- <li class="breadcrumb-item"><a href="javascript:void(0)">{{'Media_CornerSubNav'| translate}}</a></li> -->
        <li  *ngIf="this.designationCode != 0" class="breadcrumb-item active" aria-current="page">{{translate.currlang == 'hi' ? model[0]?.DesignationHindi : model[0]?.DesignationName}}</li>
        <li  *ngIf="this.designationCode == 0" class="breadcrumb-item active" aria-current="page">{{"MLA_txt" | translate}}</li>
    </ol>
</nav>
<section *ngIf="this.designationCode != 0">
  <div class="container">
      <div class="row mt-5 mb-5">
          <div class="col-lg-6 col-md-6 col-sm-12 col-12 p-2" *ngFor="let item of model">
              <div style="background-image: url(assets/images/banner-2.png); background-size: 100% 100%; border-radius: 15px; padding: 15px; height: 100%;">
                  <div class="row g-5 text-lg-start text-center">
                      <div class="col-lg-auto">
                          <img [src]="item?.MLAPhoto" onError="this.src='../../assets/images/program-no-img.png'" alt="banner" class="cmo-governor" style="  width: 150px; border-radius: 15px; border: 5px solid #fff;">
                      </div>
                      <div class="col-lg">
                          <div class="">
                              <h2 style="margin: 0px;  padding-bottom: 0px; font-weight: 600; font-size: 30px;">{{translate.currlang == 'hi' ?
                                item?.MLANameHindi : item?.MLANameEng}}</h2>
                              <p style="margin: 0px; font-weight: 700;">{{translate.currlang == 'hi' ? item?.DesignationHindi : item?.DesignationName}}</p>
                              <p [innerHTML]="translate.currlang=='hi' ? item?.DesignationDisplayNameHindiHtml : item?.DesignationDisplayNameHtml" style="padding-bottom: 0px; margin-bottom: 0px; line-height: 18px; font-size: 15px;">
                              </p>
                              <p style=" margin: 0px;"><span style="font-weight: 700;">{{'AssemblyConstituency_txt'|translate}}</span> : {{translate.currlang == 'hi' ?
                                item?.NameHindi : item?.Name}}</p>
                                <p style="margin: 0px;">
                                  <a style="    margin-top: 8px;" class="d-block w-100 text-dark" *ngIf="item?.whatsappNumber" href="tel:{{ item?.whatsappNumber }}"><i class="fa fa-phone"></i>
                                  {{ item?.whatsappNumber}}
                                </a>
                                </p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>

<section *ngIf="this.designationCode == 0">
  <div class="container">
      <div class="row mt-5 mb-5 justify-content-center" *ngFor="let group of groupByDirectories">
          <div class="col-lg-12 col-md-12 col-sm-12 col-12 mla-page-title">
          {{translate.currlang=='hi'? group[1][0]?.DesignationHindi :group[0]}}
          </div>

          <div class="col-lg-6 col-md-12 col-sm-12 col-12 p-2" *ngFor="let item of group[1]">
              <div style="background-image: url(assets/images/banner-2.png); background-size: 100% 100%; border-radius: 15px; padding: 15px; height: 100%;">
                  <div class="row g-5 text-lg-start text-center">
                      <div class="col-lg-auto">
                          <img [src]="sanitizeImageUrl(item?.MLAPhoto)" onError="this.src='../../assets/images/program-no-img.png'" alt="banner" class="cmo-governor" style="  width: 150px; border-radius: 15px; border: 5px solid #fff;">
                      </div>
                      <div class="col-lg">
                          <div class="">
                              <h2 style="margin: 0px;  padding-bottom: 0px; font-weight: 600; font-size: 30px;">{{translate.currlang == 'hi' ?
                                item?.MLANameHindi : item?.MLANameEng}}</h2>
                              <p *ngIf="item.DesignationName && !item.DesignationName.includes('M L A') 
                              && !item?.DesignationName.includes('ble Chief Minister') && !item?.DesignationName.includes('Speaker')" style="margin: 0px; font-weight: 700;">{{translate.currlang == 'hi' ? item?.DesignationHindi : item?.DesignationName}}</p>
                              <p [innerHTML]="translate.currlang=='hi' ? item?.DesignationDisplayNameHindiHtml : item?.DesignationDisplayNameHtml" style="padding-bottom: 0px; margin-bottom: 0px; line-height: 18px; font-size: 15px;">
                              </p>
                              <p><span style="font-weight: 700;">{{'AssemblyConstituency_txt'|translate}}</span> : {{translate.currlang == 'hi' ?
                                item?.NameHindi : item?.Name}}</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>
</main>


<!-- <section class="gallery gallery-page inner-page">
  <div class="container">
    <div class="row">{{'MinisterList'| translate}}</div>
    <div class="row" *ngIf="ministerChiefMinisterprograms.length > 0">
      <table class="table table-hover table-bordered" width="100%">
        <thead>
          <tr>
            <th>{{'Sno'| translate}}</th>
            <th>{{'Minister_Name'| translate}}</th>
            <th>{{'Minister_DepartName'| translate}}</th>
            <th>{{'Minister_SecretariatRoomNoBuilding'| translate}}</th>
            <th>{{'Minister_Address'| translate}}</th>
            <th>
              {{'Minister_Office_Phone_No'| translate}}
            </th>
            <th>
              {{'Minister_Residence_Phone_No'| translate}}
            </th>
            <th> {{'Minister_Vidhan_Sabha'| translate}}</th>
          </tr>
        </thead>
        <tr><td colspan="8"><span>{{'Chief_Minister'| translate}}</span></td></tr>

        <tr *ngFor="let minister of ministerChiefMinisterprograms; let i = index">
          <td>{{i + 1}}</td>
          <td>{{this.translate.currlang == 'hi' ? minister.cabinetNameHindi : minister.cabinetName}}</td>
          <td>{{this.translate.currlang == 'hi' ? minister.cabinetDepartmentHindi : minister.cabinetDepartment}}</td>
          <td>{{this.translate.currlang == 'hi' ? minister.cabinetRoomHindi : minister.cabinetRoom}}</td>
          <td>{{this.translate.currlang == 'hi' ? minister.cabinetAddressHindi : minister.cabinetAddress}}</td>

          <td>{{minister.PhoneOffice!='' ? " +91(141)"+minister.phoneOffice:"" }}</td>
          <td>{{minister.phoneResidence!='' ? "+91(141)"+minister.phoneResidence:"" }}</td>
          <td>{{minister.phoneAssembly }}</td>
        </tr>
        <tr><td colspan="8">{{'Cabinet_Ministers'| translate}}</td></tr>
        <tr *ngFor="let cabinetminister of ministerCabinetMinistersprograms; let i = index">
          <td>{{i + 1}}</td>
          <td>{{this.translate.currlang == 'hi' ? cabinetminister.cabinetNameHindi : cabinetminister.cabinetName}}</td>
          <td>{{this.translate.currlang == 'hi' ? cabinetminister.cabinetDepartmentHindi : cabinetminister.cabinetDepartment}}</td>
          <td>{{this.translate.currlang == 'hi' ? cabinetminister.cabinetRoomHindi : cabinetminister.cabinetRoom}}</td>
          <td>{{this.translate.currlang == 'hi' ? cabinetminister.cabinetAddressHindi : cabinetminister.cabinetAddress}}</td>

          <td>{{cabinetminister.PhoneOffice!='' ? " +91(141)"+cabinetminister.phoneOffice:"" }}</td>
          <td>{{cabinetminister.phoneResidence!='' ? "+91(141)"+cabinetminister.phoneResidence:"" }}</td>
          <td>{{cabinetminister.phoneAssembly }}</td>
        </tr>

        <tr><td colspan="8">{{'State_Minister_Independent'| translate}}</td></tr>

        <tr *ngFor="let stateminister of ministerStateMinisterIndependentprograms; let i = index">
          <td>{{i + 1}}</td>
          <td>{{this.translate.currlang == 'hi' ? stateminister.cabinetNameHindi : stateminister.cabinetName}}</td>
          <td>{{this.translate.currlang == 'hi' ? stateminister.cabinetDepartmentHindi : stateminister.cabinetDepartment}}</td>
          <td>{{this.translate.currlang == 'hi' ? stateminister.cabinetRoomHindi : stateminister.cabinetRoom}}</td>
          <td>{{this.translate.currlang == 'hi' ? stateminister.cabinetAddressHindi : stateminister.cabinetAddress}}</td>

          <td>{{stateminister.PhoneOffice!='' ? " +91(141)"+stateminister.phoneOffice:"" }}</td>
          <td>{{stateminister.phoneResidence!='' ? "+91(141)"+stateminister.phoneResidence:"" }}</td>
          <td>{{stateminister.phoneAssembly }}</td>
        </tr>

      </table>
    </div>

    <div class="row" *ngIf="ministerChiefMinisterprograms.length == 0">
      <div class="no-record">
        {{'No_Record_Found'| translate}}
      </div>
    </div>
  </div>
</section> -->


