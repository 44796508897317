import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emailFormat'
})
export class EmailFormatPipe implements PipeTransform {

  transform(value: string): string {
    return value.replace(/@/g, '[at]').replace(/\./g, '[dot]');
  }

}
