

<!-- #CM Event -->
<section id="programmes" class="home_programmes ">
  <div class="container">

    <div class="row">
      <div class="col-md-12">
        <h2 class="page-title">{{'ProgrammesSubNav'| translate}}</h2>
        <div class="line"><img src="assets/images/line.png" alt="banner" class="line-m"> </div>

      </div>


    </div>
    <div class="clearfix"></div>


    <div class="row">


      <div class="col-md-6" *ngFor="let program of generalModel; let i = index">
        <div class="cm-programmes" *ngIf="i<4" routerLink="/Programdetail/{{program.Id}}" style="cursor: pointer;">
          <div class="programmes-photo">
            <img src="{{program.HomePageImageUrl}}" height="171px" width="500px" style="cursor: pointer;"
              onError="this.src='../../assets/images/program-no-img.png'" alt="Icon" class="">
          </div>
          <div class="programmes-content">
            <h3>
              {{(this.translate.currlang == 'hi' ? program.Description :
              program.Description) | slice:0:100}}...
            </h3>
            <div class="time-block"> <i class="fa fa-calendar"></i> {{this.translate.currlang == 'hi' ?
              program.PressReleaseDateHindi : program.PressreleaseDate | date : 'd MMM, y'}}</div>
            <div class="programmes-c">
              <div
                *ngIf="(this.translate.currlang == 'hi' && program.Description.length>50 )||(this.translate.currlang != 'hi' && program.Description.length>50)">
                {{(this.translate.currlang == 'hi' ? program.Description :
                program.Description) | slice:0:200}}...
              </div>
              <div
                *ngIf="(this.translate.currlang == 'hi' && program.Description.length<=50 )||(this.translate.currlang != 'hi' && program.Description.length<=50)">
                {{this.translate.currlang == 'hi' ? program.Description :
                program.Description}}
              </div>
              <!-- <a routerLink="/Programdetail/{{program.Id}}" class="mr-auto">{{'Read_More'| translate}}...</a> -->
            </div>


          </div>
        </div>
      </div>

      <div *ngIf="generalModel?.length>0" class="col-md-12 view-more-btn">

        <a routerLink="/program-list" class="btn view-more-btn-1">{{'View_More'| translate}}</a>

      </div>


      <div class="col-md-12 text-center" *ngIf="generalModel && generalModel.length == 0">
        <h3>Record Not Found</h3>
      </div>


    </div>

  </div>
</section>

<!-- #CM Achievments -->


<!-- #press release -->
<section id="programmes" class="home_programmes home_programmes2">
  <div class="container">

    <div class="row">
      <div class="col-md-12">
        <h2 class="page-title">{{'Press_ReleasesSubNav'| translate}}</h2>
        <div class="line"><img src="assets/images/line.png" alt="banner" class="line-m"> </div>

      </div>


    </div>
    <div class="clearfix"></div>


    <div class="row">


      <div class="col-md-6" *ngFor="let program of generalModelForPR; let i = index">
        <div class="cm-programmes" *ngIf="i<4" routerLink="/pressreleasedetail/{{program.Id}}" style="cursor: pointer;">
          <div class="programmes-photo">
            <img src="{{program.HomePageImageUrl}}" onerror="this.src='assets/img/Press_Release.png'" alt="Icon"
              class="press-icon2">
          </div>
          <div class="programmes-content" style="padding-top: 32px;">
            <h3>
              {{(this.translate.currlang == 'hi' ? program.Description :
              program.Description) | slice:0:100}}...
            </h3>
            <div class="time-block"> <i class="fa fa-calendar"></i> {{this.translate.currlang == 'hi' ?
              program.PressReleaseDateHindi : program.PressreleaseDate | date : 'd MMM, y'}}</div>
            <div class="programmes-c">
              <div
                *ngIf="(this.translate.currlang == 'hi' && program.Description.length>50 )||(this.translate.currlang != 'hi' && program.Description.length>50)">
                {{(this.translate.currlang == 'hi' ? program.Description :
                program.Description) | slice:0:200}}...
              </div>
              <div
                *ngIf="(this.translate.currlang == 'hi' && program.Description.length<=50 )||(this.translate.currlang != 'hi' && program.Description.length<=50)">
                {{this.translate.currlang == 'hi' ? program.Description :
                program.Description}}
              </div>
              <!-- <a routerLink="/pressreleasedetail/{{program.Id}}" class="mr-auto">{{'Read_More'| translate}}...</a> -->
            </div>


          </div>
        </div>
      </div>

      <div *ngIf="generalModelForPR?.length>0" class="col-md-12 view-more-btn">

        <a routerLink="/pressrelease-list" class="btn view-more-btn-1">{{'View_More'| translate}}</a>

      </div>


      <div class="col-md-12 text-center" *ngIf="generalModelForPR && generalModelForPR.length == 0">
        <h3>Record Not Found</h3>
      </div>


    </div>

  </div>
</section>

<!-- #CM Media Corner Sections -->
<section class="cm-profile-blog ">
  <div class="container">
    <div class="row g-2">
      <div class="col-lg-3 col-md-6 col-sm-6 col-12">
        <div class="cm-photo-blog-main">
          <div class="cm-photo-blog">
            <div class="cmo-photo-title"> <img src="assets/images/cm-icon1.png" alt="icon" class="cmo-icon1">
              {{'Photo_Gallery'| translate}}
              <span></span>
            </div>
            <a [routerLink]="['/gallery-list']" routerLinkActive="router-link-active"> <img src="assets/images/cm1.png"
                alt="banner" class="cm1-photo"></a>
          </div>
          <!-- <a href="/gallery-list" class="btn profile-btn"> {{'Photo_Gallery'| translate}} <i
              class="fa fa-long-arrow-right" aria-hidden="true"></i>
          </a> -->
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6 col-12">
        <div class="cm-photo-blog-main">
          <div class="cmo-photo-title"> <img src="assets/images/cm-icon2.png" alt="icon" class="cmo-icon1">
            {{'Nav_Quotes'| translate}}
          </div>
          <div class="cm-photo-blog">
            <a [routerLink]="['/quotes']" routerLinkActive="router-link-active"> <img src="assets/images/cm2.png"
                alt="banner" class="cm1-photo"></a>
          </div>
          <!-- <a href="/quotes" class="btn profile-btn"> {{'Nav_Quotes'| translate}} <i class="fa fa-long-arrow-right"
              aria-hidden="true"></i>
          </a> -->
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6 col-12">
        <div class="cm-photo-blog-main">
          <div class="cmo-photo-title"> <img src="assets/images/success.png" alt="icon" class="cmo-icon1">
            {{'Success_Stories_txt'| translate}}
          </div>

          <div class="cmo-photo-title"> <img src="assets/images/success.png" alt="icon" class="cmo-icon1">

          </div>
          <div class="cm-photo-blog">
            <a [routerLink]="['/special-release-list']" routerLinkActive="router-link-active"><img
                src="assets/images/cm3.png" alt="banner" class="cm1-photo">
            </a>
            <!-- <a href="/video-gallery"><img src="assets/images/cm3.png" alt="banner"
                class="cm1-photo">
          </a> -->
          </div>
          <!-- <a href="/pages/sm/uploader/VIDEO/gallery/0/980/0/32031" class="btn profile-btn"> {{'VideosSubNav'|
            translate}} <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
          </a> -->
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6 col-12">
        <div class="cm-photo-blog-main">
          <div class="cmo-photo-title"> <img src="assets/images/cm-icon4.png" alt="icon" class="cmo-icon1">
            {{'Nav_Speech'|
            translate}}

          </div>

          <div class="cm-photo-blog">
            <a [routerLink]="['/speeches']" routerLinkActive="router-link-active"> <img src="assets/images/cm5.png"
                alt="banner" class="cm1-photo">
            </a>
            <!-- <a href="/pages/sm/uploader/CMSPEECHES/gallery/0/980/0/32174"> <img src="assets/images/cm5.png" alt="banner"
              class="cm1-photo"> 
            </a> -->
          </div>
          <!-- <a href="/pages/sm/uploader/CMSPEECHES/gallery/0/980/0/32174" class="btn profile-btn">{{'Nav_Speech'|
            translate}} <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
          </a> -->
        </div>
      </div>
    </div>
  </div>

</section>


<section class="cm-governance">
  <div class="container">
    <div class="row">
      <div class="governance-blog-1 col-xl-4 col-md-12 col-sm-12 col-12 wow fadeInLeft">
        <h3>{{'Mega_Event_txt' | translate}}</h3>
        <div class="cm-event">
          <ul>
            <li *ngFor="let item of megaEvent;let i = index">
              <img *ngIf="i<4" routerLink="/mega-event-detail/{{item.Id}}" style="cursor: pointer;"
                src="{{item.HomePageImageUrl}}" alt="event" class="cm1-event" height="auto"
                onError="this.src='../../assets/images/program-no-img.png'">
            </li>
          </ul>
          <!-- <div class="col-md-12 m-3" *ngFor="let item of megaEvent;let i = index" >
            <img *ngIf="i<3" src="{{item.HomePageImageUrl}}" alt="event" class="cm1-event rounded" onError="this.src='../../assets/images/program-no-img.png'">
          </div> -->

          <div class="col-md-12 text-center" *ngIf="!megaEvent?.length">
            <h3>Record Not Found</h3>
          </div>

        </div>
        <div class="clearfix"></div>
        <div *ngIf="megaEvent?.length>0" class="cm-text-4 text-center col-md-12 justify-content-center">

          <a routerLink="/mega-event-list" class="btn view-more-btn-1">{{'View_More'| translate}}</a>

        </div>

      </div>

      <div class="governance-blog-2 col-xl-4 col-md-12 col-sm-12 col-12 wow fadeInLeft">
        <!-- <div class="cm-photo">
          <img src="assets/images/cm-photo.png" alt="banner" class="cm1-photo2">
        </div>
        <div class="cm-text">
          <div class="cm-text-2">{{'CM_Enq_txt' | translate}}</div>
          <div class="cm-text-3">{{'CM_Details_txt' | translate}}</div>
        </div> -->
        <!-- <div class="cm-text-4">
          <a data-toggle="modal" data-target="#cmProfile" class="btn view-more-btn-1">{{'Read_More'| translate}}</a>
        </div> -->

        <div class="governance-blog-pro">
          <h3>{{'Upcoming_Event_txt' | translate}} </h3>
          <!-- <div class="cm1-img2"> 
            <img src="assets/images/cm-2.png" alt="cm" class="cm1-img">
          </div> -->


          <!-- <div class="text-2">
            <div
              [innerHTML]="(this.translate.currlang == 'hi' ? visionMissionData.DetailsInHindi.length>1500 ? visionMissionData.DetailsInHindi.substring(0,1500) +'...' : visionMissionData.DetailsInHindi :
            visionMissionData.Details ? visionMissionData.Details.substring(0,1500) +'...' : visionMissionData.Details)">
              {{visionMissionData.DetailsInHindi}}
            </div>
          </div>
          <div class="text-btn-1"> <a data-toggle="modal" data-target="#cmVisionMission"
              class="btn view-more-btn-1">{{'Read_More'| translate}}</a> </div> -->
          <div class="upcoming-events-container">
            <div class="upcoming-event" *ngFor="let item of CMUpcomingEvtViewModal" (click)="onNavigate(item)">
              <div class="image-section"><img src="{{item.ImagePath}}" alt="event-image"></div>
              <div class="event-info-section">
                <h3>{{this.translate.currlang == 'hi' ? item.AchievementHindi : item.DescriptionHindi}}
                  <br>
                  <span>{{item?.SubjectHindi}}</span>
                </h3>
                <ng-container *ngIf="item.AchievementToDate; else other">
                  <div class="date"><i class="fa fa-calendar"></i>{{item.AchievementDate | date:'d'}} -
                    {{this.translate.currlang == 'hi' ? item.AchievementToDateHindi : item.AchievementToDate | date:'d
                    MMM, y'}}</div>
                </ng-container>
                <ng-template #other>
                  <div class="date"><i class="fa fa-calendar"></i>{{this.translate.currlang == 'hi' ?
                    item.AchievementDateHindi : item.AchievementDate | date:'d MMM, y'}}</div>
                </ng-template>
              </div>
            </div>
          </div>

        </div>

        <!-- <div class="former-cm" id="scrollTarget">
          <div class="formercm-text-1"><span>{{'Former_txt' | translate}}</span><br>
            {{'CM_txt' | translate}}</div>
          <div class="formercm-text-2">
            <ul>
              <li *ngFor="let item of FormerCMViewModel">
                <a href="{{item.Url}}" target="_blank">
                  <img src="{{item?.ImagePath}}" alt="photo" class="former-photo"
                    onError="this.src='../../assets/images/program-no-img.png'">
                </a>
              </li>
            </ul>
          </div>
        </div> -->
      </div>


      <!-- <div class="governance-blog-3 wow fadeInRight">
        <h3>{{'CM_Profile_txt' | translate}}</h3>
        <div class="text-2">
          <div
            [innerHTML]="(this.translate.currlang == 'hi' ? CMProfileModelViewModel?.DetailsInHindi.length>1500 ? CMProfileModelViewModel?.DetailsInHindi.substring(0,1500) +'...' : CMProfileModelViewModel?.DetailsInHindi :
            CMProfileModelViewModel?.Details ? CMProfileModelViewModel?.Details.substring(0,1500) +'...' : CMProfileModelViewModel?.Details)">
          </div>
        </div>
        <a data-toggle="modal" data-target="#cmProfile" class="btn view-more-btn-1">{{'Read_More'| translate}}</a>

        <div class="cm-photo-2"> <img src="assets/images/cmbhajansharma.png" alt="banner" class="governance-cm1">
        </div>
      </div>  -->


      <div class="governance-blog-1 col-xl-4 col-md-12 col-sm-12 col-12 wow fadeInLeft">
        <h3>{{'Social_txt' | translate}}
          <div class="social-m">
            <a (click)="showSocial('fb')" style="cursor: pointer;" [ngClass]="!showFb ? 'socialBtn' : ''"> <i
                class="fa fa-facebook" aria-hidden="true" (click)="showSocial('twitter')"></i> facebook</a>
            <a (click)="showSocial('twitter')" class="svg-img-icon" style="cursor: pointer;"
              [ngClass]="!showTwitter ? 'socialBtn' : ''"> <svg viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg">
                <path [ngStyle]="{fill:!showTwitter?'blueviolet':'#fff'}"
                  d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z">
                </path>
              </svg> Twitter</a>
          </div>
        </h3>


        <div class="text-2" *ngIf="showTwitter">
          <iframe
            src="https://syndication.twitter.com/srv/timeline-profile/screen-name/RajCMO?dnt=false&amp;embedId=twitter-widget-0&amp;features=eyJ0ZndfdGltZWxpbmVfbGlzdCI6eyJidWNrZXQiOltdLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X2ZvbGxvd2VyX2NvdW50X3N1bnNldCI6eyJidWNrZXQiOnRydWUsInZlcnNpb24iOm51bGx9LCJ0ZndfdHdlZXRfZWRpdF9iYWNrZW5kIjp7ImJ1Y2tldCI6Im9uIiwidmVyc2lvbiI6bnVsbH0sInRmd19yZWZzcmNfc2Vzc2lvbiI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9LCJ0ZndfZm9zbnJfc29mdF9pbnRlcnZlbnRpb25zX2VuYWJsZWQiOnsiYnVja2V0Ijoib24iLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X21peGVkX21lZGlhXzE1ODk3Ijp7ImJ1Y2tldCI6InRyZWF0bWVudCIsInZlcnNpb24iOm51bGx9LCJ0ZndfZXhwZXJpbWVudHNfY29va2llX2V4cGlyYXRpb24iOnsiYnVja2V0IjoxMjA5NjAwLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X3Nob3dfYmlyZHdhdGNoX3Bpdm90c19lbmFibGVkIjp7ImJ1Y2tldCI6Im9uIiwidmVyc2lvbiI6bnVsbH0sInRmd19kdXBsaWNhdGVfc2NyaWJlc190b19zZXR0aW5ncyI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9LCJ0ZndfdXNlX3Byb2ZpbGVfaW1hZ2Vfc2hhcGVfZW5hYmxlZCI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9LCJ0ZndfdmlkZW9faGxzX2R5bmFtaWNfbWFuaWZlc3RzXzE1MDgyIjp7ImJ1Y2tldCI6InRydWVfYml0cmF0ZSIsInZlcnNpb24iOm51bGx9LCJ0ZndfbGVnYWN5X3RpbWVsaW5lX3N1bnNldCI6eyJidWNrZXQiOnRydWUsInZlcnNpb24iOm51bGx9LCJ0ZndfdHdlZXRfZWRpdF9mcm9udGVuZCI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9fQ%3D%3D&amp;frame=false&amp;hideBorder=false&amp;hideFooter=false&amp;hideHeader=false&amp;hideScrollBar=false&amp;lang=en&amp;maxHeight=700px&amp;origin=https%3A%2F%2Fcm.odisha.gov.in%2F&amp;sessionId=21d24c2082d1961d70d7a0ce6f234d22f5d16982&amp;showHeader=true&amp;showReplies=false&amp;transparent=false&amp;widgetsVersion=2615f7e52b7e0%3A1702314776716"
            style="position: static; visibility: visible; width: 480px; height: 700px; display: block; flex-grow: 1; overflow-y: hidden;"
            scrolling="no" frameborder="0"></iframe>
          <script async="" src="//platform.twitter.com/widgets.js" charset="utf-8"></script>
        </div>

        <div class="text-2" *ngIf="showFb">
          <iframe id="facebook-iframe" name="f39762d522f678e41" data-testid="fb:page Facebook Social Plugin"
            title="fb:page Facebook Social Plugin" frameborder="0" allowtransparency="true" allowfullscreen="true"
            scrolling="no" allow="encrypted-media"
            src="https://www.facebook.com/v2.10/plugins/page.php?adapt_container_width=true&amp;app_id=&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Dfc494cc98720558fa%26domain%3Dcmo.rajasthan.gov.in%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fcmo.rajasthan.gov.in%252Ff36cc0813b2c57e97%26relation%3Dparent.parent&amp;container_width=&amp;height=700px&amp;hide_cover=true&amp;href=https%3A%2F%2Fwww.facebook.com%2FRajCMO&amp;locale=en_GB&amp;sdk=joey&amp;show_facepile=false&amp;small_header=true&amp;tabs=timeline&amp;width=480"
            style="border: none; visibility: visible; width: 100% !important; height: 700px; border-radius: .75rem !important;"
            class="rounded"></iframe>
          <!-- <script async="" src="//platform.twitter.com/widgets.js" charset="utf-8"></script> -->
        </div>



      </div>



    </div>
  </div>
</section>


<section class="relief-fund">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators indicators">
            <ng-container *ngFor="let item of CMRelifeData;index as i">
              <button type="button" data-bs-target="#carouselExampleInterval" [attr.data-bs-slide-to]="+'0'+i"
                [ngClass]="i==0?'active':''" [attr.aria-current]="i==0?true:''"
                [attr.aria-label]="'Slide '++(i+1)"></button>
            </ng-container>
            <!-- <button type="button" data-bs-target="#carouselExampleInterval" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleInterval" data-bs-slide-to="1" aria-label="Slide 2"></button> -->
            <!-- Add more buttons if you have more carousel items -->
          </div>
          <div class="carousel-inner">
            <div class="carousel-item" *ngFor="let item of CMRelifeData;index as i" [ngClass]="i == 0 ? 'active' : ''"
              data-bs-interval="5000">
              <div class="row g-2">
                <div class="col-md-6" *ngFor="let itm of item;index as j">
                  <div class="relief-block h-md-100">
                    <div class="relief-bloc1">
                      <span>{{'CM_Relief_Fund_txt' | translate}}</span>
                      <p>
                        {{itm['Report Name']}}
                      </p>
                    </div>
                    <div class="cm-relief-data d-block justify-content-between">
                      <div class="relief-bloc2" style="font-size: 21px;">
                        {{itm['Total Count'] | numberFormatter}}
                        <p style="font-size: 13px;">{{'No_Beneficiaries_txt' | translate}}</p>
                      </div>
                      <div class="relief-bloc2 relief-bloc3" style="font-size: 21px;">
                        {{itm['Total Amount'] | numberFormatter}}+
                        <p style="font-size: 13px;">{{'Total_Amount_Granted_txt' | translate}}</p>
                      </div>
                      <div class="clearfix"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="relief-4 col-md-6">
        <div class="donet">
          <h4>{{'Donate_CM_RF_txt' | translate}}</h4>
          <div class="donet-block">
            <div class="donet-block-1"><img alt="banner" class="qr-code" src="assets/images/q-r-code.png"></div>
            <div class="donet-block-2">Name - Rajasthan Chief Minister Relief Fund<br> Account No.- 51088903513<br>
              Type of Account: Saving Account<br>
              IFSC Code - SBIN0031031<br>
              Branch Name - State Bank of India Secretariat Jaipur.<br>
              UPI Id - 51088903513&#64;SBI </div>
          </div>
          <div class="crl"></div>
        </div>
      </div>
      <div class="crl"></div>
    </div>
  </div>
</section>


<div class="modal fade" id="cmVisionMission" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{'Vision&Mission_txt' | translate}}</h5>
        <button type="button" class="close close-in" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="text-2">
          <div [innerHTML]="(this.translate.currlang == 'hi' ? visionMissionData.DetailsInHindi :
          visionMissionData.Details)"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="cmProfile" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{'CM_Profile_txt' | translate}}</h5>
        <button type="button" class="close close-in" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="text-2">
          <div [innerHTML]="(this.translate.currlang == 'hi' ? CMProfileModelViewModel?.DetailsInHindi :
          CMProfileModelViewModel?.Details)"></div>
        </div>
      </div>
    </div>
  </div>
</div>