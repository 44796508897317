
import { BaseService } from './../services/baseservice';
import { Dictionary } from './../common/dictionary';
import { Injectable } from '@angular/core';
import { AppSettings } from './../common/AppSettings';
import { Observable } from 'rxjs';
import { IndexModel } from '../Model/awards-request-model.model';

@Injectable({
  providedIn: 'root'
})
export class KeyofficialsService {
  public constructor(private httpService: BaseService) {

  }
  GetKeyOfficial() {
    return this.httpService.get(AppSettings.API_ENDPOINT + AppSettings.API_KEYOFFICIAL_LIST_GET);
  }

  GetDepartmentontactDetail(model: IndexModel): any {
    return this.httpService.post(AppSettings.API_ENDPOINT_Jankalyan + AppSettings.jankalyanApi.keyOfficial.DptDepartmentContactUrl, model);
  }
 
}


