import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateService } from '../translate.service';
import { AchievementService } from '../services/Achievement.services';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { CommonService } from '../services/common.service';
import { AchievementViewModel } from '../Model/awards-request-model.model';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-quotes-detail',
  templateUrl: './quotes-detail.component.html',
  styleUrls: ['./quotes-detail.component.css'],
  providers: [AchievementService]
})
export class QuotesDetailComponent implements OnInit {

  DateFormat = environment.DateFormat;
  model: AchievementViewModel;
  recordId: number;
  PDFFile: SafeResourceUrl;
  id: string;
  isCodeOfConduct: boolean = false;

  constructor(
    private route: ActivatedRoute,
    public translate: TranslateService,
    private achievementService: AchievementService,
    public sanitizer: DomSanitizer,
    public _commonService: CommonService
  ) {
    this.isCodeOfConduct = this._commonService.GetIsCodeOfConduct();
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get('id');
      this.GetQuotesDetailById();
    });
  }

  GetQuotesDetailById() {
    this.recordId = Number(this.id);
    this.achievementService.GetDetailById(this.recordId).subscribe(responce => {
      if (responce.IsSuccess) {
        this.model = responce.Data;
        this.PDFFile = this.sanitizer.bypassSecurityTrustResourceUrl(this.model.PdfFIleName);
      }
    },
      error => { });
  }

  sanitizeImageUrl(imageUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }

  downloadPdf(url, name) {
    if (url || '' != '') {
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', url);
      link.setAttribute('download', name + ".pdf");
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }

  openInFullScreen(url) {
    if (url) {
      window.open(url, "_blank");
    }
  }

}
