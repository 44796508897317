
<section id="testimonials" class="wow fadeInUp">
  <div class="container">
    <div class="section-header">
      <h2>CM Relief Fund</h2>
      <p>Details of the assistance given under the CM Relief Fund</p>
    </div>


    <div id="ThumbnailCarousel" class="carousel slide col-xs-12" data-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div class="row">
            <div class="col-md-4 col-sm-6">
              <div class="testimonial-item">
                <a href="javascript:void(0)">
                  Assistance to victims of severe disease
                </a>
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="testimonial-item">
                <a href="javascript:void(0)">
                  Assistance to victims of severe disease
                </a>
              </div>
            </div>
            <div class="col-md-4">
              <div class="testimonial-item">
                <a href="javascript:void(0)">
                  General Help
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="row">
            <div class="col-md-4 col-sm-6">
              <div class="testimonial-item">
                <a href="javascript:void(0)">
                  Kargil Relief Fund
                </a>
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="testimonial-item">
                <a href="javascript:void(0)">
                  Orissa cyclone victims fund
                </a>
              </div>
            </div>
            <div class="col-md-4">
              <div class="testimonial-item">
                <a href="javascript:void(0)">
                  Gujarat earthquake victims fund
                </a>
              </div>
            </div>
          </div>
        </div>

      </div>
      <a class="carousel-control-prev" href="#ThumbnailCarousel" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#ThumbnailCarousel" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>




  </div>
</section>
