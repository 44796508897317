import { BaseService } from './../services/baseservice';
import { Dictionary } from './../common/dictionary';
import { Injectable } from '@angular/core';
import { AppSettings } from './../common/AppSettings';
import { Observable } from 'rxjs';

@Injectable()
export class CmoMessageService {
  public constructor(private httpService: BaseService) {

  }

  GetMessageList() {
    return this.httpService.get(AppSettings.API_ENDPOINT + AppSettings.API_CMOMESSAGE_LIST_GET);
  }
}
