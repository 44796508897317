import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { environment } from '../../environments/environment';
import { TranslateService } from '../translate.service';
import { AchievementService } from '../services/Achievement.services';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { AchievementViewModel } from '../Model/awards-request-model.model';
@Component({
  selector: 'app-speech-detail',
  templateUrl: './speech-detail.component.html',
  styleUrls: ['./speech-detail.component.css'],
  providers: [AchievementService]
})
export class SpeechDetailComponent implements OnInit {
  id: string;
  searchBox = '';
  date = '';
  totalPage: number;
  pdfUrl = environment.imageUrl + 'Program/speech/';
  imageUrl = environment.imageUrl + 'Program/Image/Thumbnail/Fr_';
  currentPage = 1;
  recordId: number;
  PDFFile: SafeResourceUrl;
  model: AchievementViewModel;
  DateFormat = environment.DateFormat;
  constructor(
    public http: HttpClient,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private achievementService: AchievementService,
    public sanitizer: DomSanitizer,
    @Inject('BASE_URL') public baseUrl: string,) {

    this.id = this.route.snapshot.paramMap.get('id');
  }
  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get('id');
      this.GetCMSpeechDetailById();
    });
  }

  GetCMSpeechDetailById() {
    this.recordId = Number(this.id);
    this.achievementService.GetDetailById(this.recordId).subscribe(responce => {
      if (responce.IsSuccess) {
        this.model = responce.Data;
        this.PDFFile = this.sanitizer.bypassSecurityTrustResourceUrl(this.model.PdfFIleName);
      }
    },
      error => { });
  }

  sanitizeImageUrl(imageUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }

  downloadPdf(url, name) {
    if (url || '' != '') {
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', url);
      link.setAttribute('download', name + ".pdf");
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }

  openInFullScreen(url) {
    if (url) {
      window.open(url, "_blank");
    }
  }

}
