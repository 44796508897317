<!-- <div #pageBody>
    <app-nav-menu (fontSize)="setFont($event)" *ngIf="isHomePage" ></app-nav-menu>
    <router-outlet *ngIf="dptModel"></router-outlet>
    <app-footer *ngIf="isHomePage"></app-footer>
</div> -->


<div #pageBody>
  <!-- <p>{{islogin}}</p> -->

  <ng-container *ngIf="!islogin && environment.LoginEnable">
    <div #skipperMainContent tabindex="-1">
      <router-outlet></router-outlet>
    </div>
  </ng-container>

  <ng-container *ngIf="islogin || !environment.LoginEnable">
    <app-nav-menu (fontSize)="setFont($event)" *ngIf="isHomePage && (appService.showHeader$ | async)" class="fix-hader"></app-nav-menu>
    <router-outlet *ngIf="dptModel"></router-outlet>
    <app-footer *ngIf="isHomePage && (appService.showFooter$ | async)"></app-footer>
  </ng-container>
</div>