<div class="breadcrumbs-page-img">
    <div class="banner-hight-2">
        <h1>{{'Sitemap_txt'|translate}}</h1>
        <div class="inner-page-banner">
            <img src="assets/images/cmo-banner.png" alt="banner" class="cmo-banner">
        </div>
    </div>
</div>

<app-loader></app-loader>

<main id="main">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
            <!-- <li class="breadcrumb-item"><a href="javascrtipt:void(0)"> Citizen Corner</a></li> -->
            <li class="breadcrumb-item active" aria-current="page">
                {{'Sitemap_txt'|translate}}
            </li>
        </ol>
    </nav>
    <section class="inner-page page-sitemap mb-4">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="item-list">
                        <ul class="navbar-nav-1 w-100 justify-content-center">
            
                          <li [ngClass]="{'dropdown1': item?.IsSubMenu}" *ngFor="let item of menuListModel?.MenuList">
                            <ul class="navbar-inner">
                              <li>
                                <a [ngClass]="{'dropdown-toggle1': item?.IsSubMenu}" [href]="item?.ExternalUrl" target="_blank"
                                  id="navbarDropdownMenuLink" aria-haspopup="true" aria-expanded="false"
                                  *ngIf="item?.IsExternalUrl">
                                  <strong>{{translate.currlang!='en'? item?.DisplayNameHindi:item?.DisplayNameEnglish}}</strong>
                                </a>
                                <a [ngClass]="{'dropdown-toggle1': item?.IsSubMenu}" [routerLink]="item?.InternalUrl"
                                  id="navbarDropdownMenuLink" aria-haspopup="true" aria-expanded="false"
                                  *ngIf="!item?.IsExternalUrl">
                                  <strong>{{translate.currlang!='en'? item?.DisplayNameHindi:item?.DisplayNameEnglish}}</strong>
                                </a>
                                <ul class="dropdown-menu1" aria-labelledby="navbarDropdownMenuLink" *ngIf="item?.IsSubMenu">
                                  <li class="dropdown-submenu" *ngFor="let subItem of getSubMenuList(item?.MainMenuCode)">
                                    <!-- {{ subItem | json }} -->
            
                                    <a class="dropdown-item1" tabindex="1" [ngClass]="{'dropdown-toggle': subItem?.IsSubMenu}"
                                      href="javascript:void(0)"
                                      *ngIf="!subItem.IsDisable && enumdata.HasSubMenu==subItem?.RedirectionManagementRadio">
                                      {{translate.currlang!='en' ? subItem?.DisplayNameHindi :
                                      subItem?.DisplayNameEnglish}}
                                    </a>
                                    <a class="dropdown-item1" tabindex="2" [ngClass]="{'dropdown-toggle': subItem?.IsSubMenu}"
                                      [routerLink]="subItem?.IsSubMenu?undefined:subItem?.Url"
                                      *ngIf="!subItem.IsDisable && enumdata.DataEnteredInScreens==subItem?.RedirectionManagementRadio">
                                      {{translate.currlang!='en' ? subItem?.DisplayNameHindi :
                                      subItem?.DisplayNameEnglish}}
                                    </a>
                                    <a class="dropdown-item1" tabindex="3" [ngClass]="{'dropdown-toggle': subItem?.IsSubMenu}"
                                      [href]="subItem?.IsSubMenu?undefined:subItem?.PDFAttachment" target="_blank"
                                      *ngIf="!subItem.IsDisable && enumdata.PDFFileUpload==subItem?.RedirectionManagementRadio">
                                      {{translate.currlang!='en' ? subItem?.DisplayNameHindi :
                                      subItem?.DisplayNameEnglish}}
                                    </a>
                                    <a class="dropdown-item1" tabindex="4" [ngClass]="{'dropdown-toggle': subItem?.IsSubMenu}"
                                      [href]="subItem?.IsSubMenu?undefined:subItem?.RedirectionURL" target="_blank"
                                      *ngIf="!subItem.IsDisable && enumdata.URLOfSameWebsiteOtherWebsite==subItem?.RedirectionManagementRadio">
                                      {{translate.currlang!='en' ? subItem?.DisplayNameHindi :
                                      subItem?.DisplayNameEnglish}}
                                    </a>
            
                                    <a class="dropdown-item1" tabindex="5" [ngClass]="{'dropdown-toggle': subItem?.IsSubMenu}"
                                      href="javascript:void(0);" *ngIf="subItem.IsDisable" style="cursor: not-allowed;">
                                      {{translate.currlang!='en' ? subItem?.DisplayNameHindi :
                                      subItem?.DisplayNameEnglish}}
                                    </a>
            
                                    <ul class="dropdown-menu1" *ngIf="subItem?.IsSubMenu">
                                      <li *ngFor="let subSubItem of getSubSubMenuList(subItem?.SubMenuCode)">
                                        <a class="dropdown-item" tabindex="5" [routerLink]="subSubItem?.Url"
                                          *ngIf="!subSubItem.IsDisable && enumdata.DataEnteredInScreens==subSubItem?.RedirectionManagementRadio">
                                          {{translate.currlang!='en' ? subSubItem?.DisplayNameHindi :
                                          subSubItem?.DisplayNameEnglish}}
                                        </a>
                                        <a class="dropdown-item1" tabindex="6" [href]="subSubItem?.PDFAttachment" target="_blank"
                                          *ngIf="!subSubItem.IsDisable && enumdata.PDFFileUpload==subSubItem?.RedirectionManagementRadio">
                                          {{translate.currlang!='en' ? subSubItem?.DisplayNameHindi :
                                          subSubItem?.DisplayNameEnglish}}
                                        </a>
                                        <a class="dropdown-item1" tabindex="7" [href]="subSubItem?.RedirectionURL" target="_blank"
                                          *ngIf="!subSubItem.IsDisable && enumdata.URLOfSameWebsiteOtherWebsite==subSubItem?.RedirectionManagementRadio">
                                          {{translate.currlang!='en' ? subSubItem?.DisplayNameHindi :
                                          subSubItem?.DisplayNameEnglish}}
                                        </a>
                                        <a class="dropdown-item1" tabindex="8" href="javascript:void(0);"
                                          *ngIf="subSubItem.IsDisable" style="cursor: not-allowed;">
                                          {{translate.currlang!='en' ? subSubItem?.DisplayNameHindi :
                                          subSubItem?.DisplayNameEnglish}}
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                        </ul>
            
            
                        <!-- <ul class="navbar-nav-1 w-100 justify-content-center">
                          <li>
                            <h5 class="nav-title">{{'CM_Office_txt'| translate}} </h5>
                            <ul class="navbar-inner">
                              <li> <a href="/home">{{'CM_OfficeNav'| translate}}</a></li>
                              <li> <a routerLink="/keyOfficial">{{'Key_OfficialsSubNav'| translate}} </a></li>
                              <li> <a
                                  href="https://jankalyanfile.rajasthan.gov.in//Content/UploadFolder/DepartmentMaster/980/2024/Feb/30409/9806e81372b-eb8b-4715-b9a9-7ea445c5fba4.pdf"
                                  target="_blank">{{'NavWork_Distribution'| translate}} </a></li>
                            </ul>
                          </li>
                          <li>
                            <h5 class="nav-title"> Coming Soon</h5>
                            <ul class="navbar-inner">
                              <li>
                                <a href="javascript:void(0)">{{'State_GovernmentSubNav'|
                                  translate}}
                                </a>
                              </li>
            
                              <li><a target="_blank"
                                  href="https://rajasthan.gov.in/pages/sm/department-page/142643/866">{{'NavGovernor'|
                                  translate}}</a></li>
                              <li><a target="_blank"
                                  href="https://rajasthan.gov.in/pages/sm/department-page/142677/876">{{'Chief_Minister'|
                                  translate}}</a></li>
                              <li><a target="_blank"
                                  href="https://rajasthan.gov.in/pages/sm/department-page/371534/2402">{{'Deputy_Chief_MinisterFooter'|
                                  translate}}</a></li>
                              <li><a target="_blank"
                                  href="https://rajasthan.gov.in/pages/sm/assembly-constituency/354894/0/1">{{'Cabinet_MinistersSubNav'|
                                  translate}}</a>
                              </li>
                              <li><a target="_blank"
                                  href=" https://rajasthan.gov.in/pages/sm/assembly-constituency/372005/0/457">{{'State_MinistersIndependantChargeSubNav'|
                                  translate}}</a>
                              </li>
                              <li><a target="_blank"
                                  href=" https://rajasthan.gov.in/pages/sm/assembly-constituency/354895/0/2">{{'State_MinistersSubNav'|
                                  translate}}</a>
                              </li>
                              <li><a target="_blank"
                                  href="https://rajasthan.gov.in/pages/sm/department-page/142678/877">{{'Chief_SecretarySubNav'|
                                  translate}} </a></li>
                            </ul>
                          </li>
                          <li>
                            <h5 class="nav-title">Coming Soon</h5>
                            <ul class="navbar-inner">
                              <li> <a href="javascript:void(0)">{{'Media_CornerSubNav'| translate}} </a></li>
                              <li> <a routerLink="/program-list">{{'ProgrammesSubNav'| translate}}</a> </li>
                              <li><a routerLink="/awards">{{'NavAwards'| translate}}</a> </li>
                              <li> <a routerLink="/pressrelease-list">{{'Press_ReleasesSubNav'| translate}}</a> </li>
                              <li> <a routerLink="/calendar">{{'CalendarSubNav'| translate}}</a> </li>
                              <li> <a routerLink="/gallery-list">{{'PhotosSubNav'| translate}} </a> </li>
                              <li> <a routerLink="/mega-event-list">{{'Mega_Event'| translate}}</a></li>
                            </ul>
                          </li>
                          <li>
                            <h5 class="nav-title">{{'Our_Goverment_txt'|
                              translate}} </h5>
                            <ul class="navbar-inner">
                              <li> <a target="_blank" href="https://rajasthan.gov.in">{{'NavState_Portal'|
                                  translate}}</a>
                              </li>
                              <li> <a target="_blank" href="https://assembly.rajasthan.gov.in/">{{'Rajasthan_Assembly_txt'|
                                  translate}}</a> </li>
                              <li> <a target="_blank" href="https://sampark.rajasthan.gov.in/">{{'Rajasthan_SamparkSubNav' |
                                  translate}}</a> </li>
                              <li> <a target="_blank" href="https://rti.rajasthan.gov.in/">{{'Right_To_InformationSubNav' |
                                  translate}}</a> </li>
                              <li>
                                <a target="_blank" href="https://cmrelief.rajasthan.gov.in/">{{'CM_Relief_FundFooter' |
                                  translate}} </a>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <h5 class="nav-title">{{'Contact_Us_txt' |
                              translate}} </h5>
                            <div class="text-nav">{{'Address_cm' | translate}}</div>
            
                          </li>
                        </ul> -->
                      </div>
                </div>
            </div>
        </div>
    </section>
</main>