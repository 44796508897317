import { Component, OnInit } from '@angular/core';
import { FormerCMModel, FormerCMViewModel } from '../Model/former-cm';
import { CategoryTypeEnum, EntryTypeEnum, JankalyanCategoryEnum, SubCategoryCodeTypeEnum } from '../Enum/jankayan.enum';
import { DepartmentCodeEnum } from '../Enum/app-enums';
import { NewsService } from '../services/news.services';
import { TranslateService } from '../translate.service';

@Component({
  selector: 'app-former-cm-list',
  templateUrl: './former-cm-list.component.html',
  styleUrls: ['./former-cm-list.component.css'],
  providers: [NewsService]
})
export class FormerCMListComponent implements OnInit {

  FormerCMModel = new FormerCMModel();
  FormerCMViewModel: FormerCMViewModel[];
  constructor(private newsService: NewsService, public translate: TranslateService) { }

  ngOnInit() {
    this.GetFormerCM();
  }

  GetFormerCM() {
    this.FormerCMModel.IndexModel.PageSize = 101;
    this.FormerCMModel.DepartmentCode = DepartmentCodeEnum.CmoWebsiteCode;
    this.FormerCMModel.JankalyanCategoryCode = JankalyanCategoryEnum.GOVTrackRecordCode;
    this.FormerCMModel.EntryTypeCode = EntryTypeEnum.GOVTrackRecordCode
    this.FormerCMModel.CategoryCode = CategoryTypeEnum.FormerCMCategoryCode
    this.FormerCMModel.SubCategoryCode = SubCategoryCodeTypeEnum.FormerCMSubCategoryCode
    this.FormerCMModel.IndexModel.OrderBy = 'Priority';
    this.FormerCMModel.IndexModel.OrderByAsc = 1;

    this.newsService.GovFormerCM(this.FormerCMModel).subscribe((response: any) => {
      if (response.IsSuccess) {
        console.log("response",response)
        this.FormerCMViewModel = response.Data.Data as FormerCMViewModel[];
      }
    });
  }
}
