import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppSettings } from '../common/AppSettings';
import { Dictionary } from '../common/dictionary';
import { DepartmentDetailModel, DepartmentMenuListModel, DepartmentProfileFilterModel } from '../Model/department-model.model';
import { BaseService } from './baseservice';


@Injectable({
  providedIn: 'root'
})
export class DepartmentService {

  public departmentModel: BehaviorSubject<DepartmentDetailModel> = new BehaviorSubject<DepartmentDetailModel>(undefined!);
  // private deptModel$: BehaviorSubject<DepartmentDetailModel> = new BehaviorSubject<DepartmentDetailModel>(null);
  private deptMenuList$: BehaviorSubject<DepartmentMenuListModel> = new BehaviorSubject<DepartmentMenuListModel>(null);

  constructor(private readonly _baseService: BaseService) { }

  GetList(model: DepartmentProfileFilterModel, excludeEntryType: number = 0, isBase64Files: boolean = false): Observable<any> {
    //return this._baseService.post(AppSettings.GetDepartmentProfileListurl + "?excludeEntryType=" + excludeEntryType + "&isBase64Files=" + isBase64Files, model);
    return this._baseService.post(AppSettings.API_ENDPOINT_Jankalyan + AppSettings.jankalyanApi.DepartmentProfile.GetDepartmentProfileListurl + "?excludeEntryType=" + excludeEntryType + "&isBase64Files=" + isBase64Files, model);
  }

  GetDepartmentDetailByDptCode(dptCode: number, isBannerRequired: boolean, isNewsTickerRequired: boolean, isExternalLinkRequired: boolean) {
    let params: Dictionary<any> = new Dictionary<any>();
    params.Add("dptCode", dptCode);
    params.Add("isBannerRequired", isBannerRequired);
    params.Add("isNewsTickerRequired", isNewsTickerRequired);
    params.Add("isExternalLinkRequired", isExternalLinkRequired);
    return this._baseService.get(AppSettings.API_ENDPOINT_Jankalyan + AppSettings.jankalyanApi.DepartmentProfile.DepartmentDetailByDptCodeUrl, params);
  }

  //#region Department Menu List

  getDeptMenuList(): Observable<DepartmentMenuListModel> {
    return this.deptMenuList$.asObservable();
  }

  setDeptMenuList(model: DepartmentMenuListModel) {
    this.deptMenuList$.next(model);
  }

  GetDepartmentMenuList(dptCode: number): any {
    let params = new Dictionary<any>();
    params.Add('dptCode', dptCode);
    return this._baseService.get(AppSettings.API_ENDPOINT_Jankalyan + AppSettings.jankalyanApi.Menus.MenuListForDepartmentUrl, params);
  }
  //#endregion

}
