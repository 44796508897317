<div class="bg-scroll pos-rel breadcrumbs-page-img">
  <div class="container">


    <h1>{{'Keyinitiatives'| translate}}</h1>

  </div>
</div>
<main id="main">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/home"><i class="ion-ios-home font-16"></i></a></li>
      <li class="breadcrumb-item" aria-current="page"><a href="javascript:void(0)">{{'CM_OfficeNav'| translate}}</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">{{'Keyinitiatives'| translate}}</li>
    </ol>
  </nav>
  <section class="program_list">
    <div class="container">

      <div class="row">
        <div class="card-deck w-100">
          <div class="col-sm-6 col-lg-3 program_list_box" *ngFor="let program of programKeyInitiatives; let i = index">


            <div class="card wow"
              [ngClass]="[i%3 <1 ? 'fadeInLeft' : i%3 <2? 'fadeInUp':i%3 <3? 'fadeInRight':'fadeInLeft' ]">
              <div class="program-img-bx ">
                <a routerLink="/Programdetail/{{program.ProgramId}}">
                  <img src="{{this.imageUrl}}{{program.KeyImage}}"
                    onError="this.src='../../assets/images/program-no-img.png'" class="card-img-top" alt="">
                </a>
              </div>

              <div class="card-body">
                <a routerLink="/Programdetail/{{program.ProgramId}}">
                  <p class="card-text"
                    *ngIf="(this.translate.currlang == 'hi' && program.ProgramSubjectHindi.length<=50 )||(this.translate.currlang != 'hi' && program.ProgramSubject.length<=50)">

                    {{this.translate.currlang == 'hi' ? program.ProgramSubjectHindi : program.ProgramSubject}}

                  </p>
                  <p class="card-text"
                    *ngIf="(this.translate.currlang == 'hi' && program.ProgramSubjectHindi.length>50 )||(this.translate.currlang != 'hi' && program.ProgramSubject.length>50)">

                    {{(this.translate.currlang == 'hi' ? program.ProgramSubjectHindi :
                    program.ProgramSubject)|slice:0:50}}...

                  </p>
                </a>
                <a class="program_btn_more" routerLink="/Programdetail/{{program.ProgramId}}">{{'Read_More'| translate}}
                </a>
              </div>
              <div class="card-footer">
                <small class="text-muted"><i class="fa fa-calendar"></i> {{this.translate.currlang == 'hi' ?
                  program.ProgramDateHindi : program.ProgramDate}}</small>
              </div>

            </div>
          </div>
        </div>


        <!--<nav aria-label="Page navigation example" class="w-100 text-center mt-4" *ngIf="totalPage > 9">

          <ul class="pagination d-inline-flex" *ngIf="pager.pages && pager.pages.length">
            <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
              <a class="page-link" (click)="getData(1)">{{'First'| translate}}</a>
            </li>
            <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
              <a class="page-link" (click)="getData(pager.currentPage - 1)">{{'Previous'| translate}}</a>
            </li>
            <li class="page-item" *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
              <a class="page-link" (click)="getData(page)">{{page}}</a>
            </li>
            <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
              <a class="page-link" (click)="getData(pager.currentPage + 1)">{{'Next'| translate}}</a>
            </li>
            <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
              <a class="page-link" (click)="getData(pager.totalPages)">{{'Last'| translate}}</a>
            </li>
          </ul>
        </nav>-->

      </div>


      <div class="row" *ngIf="programKeyInitiatives.length == 0">
        <div class="no-record">
          {{'No_Record_Found'| translate}}
        </div>
      </div>
    </div>

  </section>


</main>