<section class="inner-page-banner">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="page-title">
          Message
        </div>
      </div>
    </div>
  </div>
</section>
<section class="form-page inner-page">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div>
          Message
        </div>
        <div *ngFor="let item of list">
          <div *ngIf="isEnglish">
            Event: {{item.ProgramDate}}<br />
                                       <a routerLink="/messageDetail/{{item.ProgramId}}">{{item.ProgramSubject}}</a> 
          </div>
          <div *ngIf="!isEnglish">
            कार्यक्रम:  {{item.ProgramDate}}<br />
            {{item.ProgramSubjectHindi}}
          </div>
          <br />
        </div>
      </div>
      </div>
  </div>
</section>
