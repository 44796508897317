import { Component, OnInit } from "@angular/core";
import { PortalUserFeedbackAddRequestModel } from "../Model/suggestion-feedback.model";
import { DDLModel } from "../Model/common.model";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { TranslateService } from "../translate.service";
import { Router } from "@angular/router";
import { SuggestionFeedbackService } from "../services/suggestion-feedback.service";
import { AlertService } from "../services/alert.service";
import { CommonService } from "../services/common.service";
import { DepartmentCodeEnum } from "../Enum/app-enums";
import {
  UserRegistrationPostModel,
  UserResponseModel,
} from "../Model/user-registeration.model";
import { UserService } from "../services/user.service";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { LoadingService } from "../services/loading.service";
@Component({
  selector: "app-suggestion-feedback",
  templateUrl: "./suggestion-feedback.component.html",
  styleUrls: ["./suggestion-feedback.component.css"],
  providers: [SuggestionFeedbackService, AlertService],
})
export class SuggestionFeedbackComponent implements OnInit {
  dDLList = new DDLModel();
  suggestionFeedbackModel = new PortalUserFeedbackAddRequestModel();
  isResendOTP: boolean = true;
  sectorsData:any=[];
  selectedFile: File | null = null;
  PDFFile: SafeResourceUrl;

  FormGroup: UntypedFormGroup = this.fb.group({
    Name: [undefined, Validators.required],
    // Mobile: [
    //   undefined,
    //   Validators.compose([
    //     Validators.required,
    //     Validators.maxLength(12),
    //     Validators.minLength(10),
    //   ]),
    // ],
    Email: [undefined, Validators.email],
    Address: [undefined],
    SectorCode: ["", Validators.required],
    DepartmentCode: [""],
    Feedback: ["", Validators.required],
    pdf: [""]
  });

  constructor(
    private readonly fb: UntypedFormBuilder,
    public readonly translate: TranslateService,
    private readonly _router: Router,
    private readonly _suggestionFeedbac: SuggestionFeedbackService,
    private readonly _alertService: AlertService,
    public readonly _commonService: CommonService,
    private readonly _userService: UserService,
    public sanitizer: DomSanitizer,
    private loadingService: LoadingService
  ) {}

  signUpModel = new UserRegistrationPostModel();
  OTPControl = new UntypedFormControl("", Validators.compose([
    Validators.required,
    Validators.maxLength(6),
    Validators.minLength(4),
  ]));
  isButtonDisabled: boolean = false;
  showRegistration: boolean = true;
  showfeedback: boolean = false;

  get CaptchaEntered() {
    return this.signUpformGroup.get("CaptchaEntered");
  }

  signUpformGroup: UntypedFormGroup = this.fb.group({
    Mobile: [
      undefined,
      Validators.compose([
        Validators.required,
        Validators.maxLength(12),
        Validators.minLength(10),
      ]),
    ],
    Name: [undefined, Validators.required],
  });

  ngOnInit() {
    this.suggestionFeedbackModel.SectorCode = ""
    this._suggestionFeedbac.SectorsData("ddlAllSectoralDepartment",0,0).subscribe((res:any)=>{
      console.log(res,"RES----------__________----------");
      if(res.IsSuccess){   
        this.sectorsData = res.Data.ddlAllSectoralDepartment.filter((x:any)=>x.OtherData.AdmDepartmentCode == 49);   
        this.sectorsData.forEach((x:any)=>{
          x.Text2 = this.extractContent(x.Text);
          x.TextInHindi = this.extractContentInHindi(x.OtherData.DepartmentTitleHindi)
        })
      }

      console.log(this.sectorsData,"sectorsDatasectorsDatasectorsData")
    })
  }

  extractContent(text:any) {
    // Remove "Sector - " if it exists
    if (text.includes("Sector - ")) {
      text = text.replace("Sector - ", "");
    }

    if (text.includes("Sector-")) {
      text = text.replace("Sector-", "");
    }
  
    // Remove " Portal" if it exists
    if (text.includes(" Portal")) {
      text = text.replace(" Portal", "");
    }
  
    return text;
  }

  extractContentInHindi(text:any) {
    // Remove "सेक्टर - " if it exists
    if (text.includes("सेक्टर - ")) {
      text = text.replace("सेक्टर - ", "");
    }

    if (text.includes("सेक्‍टर - ")) {
      text = text.replace("सेक्‍टर - ", "");
    }

    if (text.includes("सेक्टर-")) {
      text = text.replace("सेक्टर-", "");
    }
  
    // Remove " पोर्टल" if it exists
    if (text.includes(" पोर्टल")) {
      text = text.replace(" पोर्टल", "");
    }
  
    return text;
  }

  onFileSelected(event: any) {
    // const fileInput = event.target as HTMLInputElement;
    // if (fileInput.files && fileInput.files.length > 0) {
    //   this.selectedFile = fileInput.files[0];
    // }
    if (event.target.files.item(0).type.match("application/pdf")) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.PDFFile = this.sanitizer.bypassSecurityTrustResourceUrl(event.target.result);
        this.suggestionFeedbackModel.AttachmentPath = event.target.result;
      };
      reader.readAsDataURL(event.target.files.item(0));
      // this.fileValidationMsg = "";
    }
    else {
      // this.fileValidationMsg = "only *pdf file accepted ";
    }
  }

  SaveClick() {
    this.FormGroup.markAllAsTouched();
    if (this.FormGroup.valid) {
      this.suggestionFeedbackModel.DepartmentCode = DepartmentCodeEnum.CmoWebsiteCode;
      this._suggestionFeedbac.AddSuggestionFeedbackData(this.suggestionFeedbackModel).subscribe((data) => {
            if (data.Result.IsSuccess) {
              this._commonService.alertMsg(
                "Success!",
                "Feedback saved successfully...!",
                "success"
              );
              this._router.navigate(["home"]);
            } else {
              this._commonService.alertMsg("Opps!", data.Message, "warning");
            }
          },
          (error) => {
            this._alertService.error(error.message);
          }
        );
    }
  }

  onGenerateOTP() {
    this.OTPControl = new UntypedFormControl("", Validators.compose([
      Validators.required,
      Validators.maxLength(6),
      Validators.minLength(4),
    ]));
    this.OTPControl.setValue("");
    this.isResendOTP = true;
    this.signUpformGroup.markAllAsTouched();
    if (this.signUpformGroup.valid) {
      this.isButtonDisabled = true;
      // this._app.showLoaderEvent(true);
      this.loadingService.show();
      this._userService.RegisterUser(this.signUpModel).subscribe((res) => {
        this.loadingService.hide();
        // this._app.showLoaderEvent(false);
        if (res.IsSuccess) {
          this.signUpModel.AppUserId = res.Data.AppUserId;
          this._commonService.alertMsg(
            "Success!",
            "OTP Sent successfully...!",
            "success"
          );
          const element = document.getElementById("modalBtn") as HTMLElement;
          element.click();
          setTimeout(() => {
            this.isResendOTP = false;
          }, 60000);
          this.isButtonDisabled = true;
        } else {
          this.isButtonDisabled = false;
          this._commonService.alertMsg("Opps!", res.Message, "warning");
        }
      },err=>{
        this.isButtonDisabled = false;
        this.loadingService.hide();
        this._commonService.alertMsg("Opps!", "Somethingn Went Wrong", "warning");
      });
    }
  }

  onVerifyOTP() {
    this.OTPControl.markAllAsTouched();
    if (this.OTPControl.valid) {
      this.isButtonDisabled = true;
      this.signUpModel.OTP = this.OTPControl.value;
      // this._app.showLoaderEvent(true);
      this.loadingService.show();  
      this._userService.VerifyOtp(this.signUpModel).subscribe((res) => {
        this.loadingService.hide();
        // this._app.showLoaderEvent(false);
        if (res.IsSuccess) {
          this.isButtonDisabled = false;
          let data = res.Data as UserResponseModel;

          this.signUpModel.AppUserId = data.AppUserId;
          this.suggestionFeedbackModel.AppUserId = data.AppUserId;
          this._commonService.alertMsg(
            "Success!",
            "OTP Verified successfully...!",
            "success"
          );
          const element = document.getElementById("closeBtn") as HTMLElement;
          element.click();
          this.showfeedback = true;
          this.showRegistration = false;
          // sessionStorage.setItem('IsVerified', 'true');
          // this._router.navigate(['pages/department-page/' + this.pageCode])
        } else {
          this.loadingService.hide();
          this.isButtonDisabled = false;
          this._commonService.alertMsg("Opps!", res.Message, "warning");
        }
      },err=>{
        this._commonService.alertMsg("Opps!", "Somethingn Went Wrong", "warning");
      });
    }
  }

  onResendOTP() {
    this.isButtonDisabled = true;
    // this._app.showLoaderEvent(true);
    this.loadingService.show();  
    this._userService.ResentOTP(this.signUpModel.Mobile,this.signUpModel.AppUserId).subscribe(res => {
      this.loadingService.hide();
      // this._app.showLoaderEvent(false);
      this.isButtonDisabled = false;
      if (res.IsSuccess) {
        this.isResendOTP = true;
        setTimeout(() => {
          this.isResendOTP = false;
        }, 30000);
        this.OTPControl.setValue(undefined);
        this._commonService.alertMsg("Success", "OTP Sent", "success");
      }else{
        this._commonService.alertMsg("Opps!", res.Message, "warning");
      }
    },err=>{
      this.loadingService.hide();
      this._commonService.alertMsg("Opps!", "Somethingn Went Wrong", "warning");
    });
  }

  onEnterPress() {
    this.onGenerateOTP();
  }

  numberOnly(value: any, isCommaOrDash: boolean = false): boolean {
    return this._commonService.numberOnly(value, isCommaOrDash);
  }
}
