import { Injectable } from '@angular/core';
import { BaseService } from './baseservice';
import { Dictionary } from '../common/dictionary';
import { AppSettings } from '../common/AppSettings';

@Injectable({
  providedIn: 'root'
})
export class WebsitePoliciesService {

  constructor(private _baseService: BaseService) { }

  GetJankalyanCategoryDetail(id: number) {
    return this._baseService.get(AppSettings.API_ENDPOINT_RW_Jankalyan + AppSettings.jankalyanApi.WebsitePolicies.JankalyanCategoryDetailUrl + id);
  }

  GetJankalyanEntryTypeListByCategory(catCode: number, deptCode: number = 0, isRecordExist: number = 0) {
    let params = new Dictionary<any>();
    params.Add('id', catCode);
    params.Add('deptCode', deptCode);
    params.Add('isRecordExist', isRecordExist);
    return this._baseService.get(AppSettings.API_ENDPOINT_RW_Jankalyan + AppSettings.jankalyanApi.WebsitePolicies.JankalyanEntryTypeListByCategoryUrl, params);
  }
}
