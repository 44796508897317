import { Component, Inject, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { TranslateService } from '../translate.service';
import { MediaService } from './../services/media.services';
import { ProgramImageRequestModel } from '../Model/filter-program-model.model';
import { InAugrationListModel, PressReleaseFrontSearchModel } from '../Model/press-release-model.model';
import { DepartmentCodeEnum, PressReleaseCategoryTypeEnum, PressReleaseCMOPRCategoryEnum } from '../Enum/app-enums';
import { NewsService } from '../services/news.services';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css'],
  providers: [MediaService,NewsService]
})
export class GalleryComponent implements OnInit {
  public galleryList: GalleryModel[];
  imageUrl = environment.imageUrl + 'Program/Image/';
  modelRequest: ProgramImageRequestModel;

  generalModel: InAugrationListModel[];
  filterModel = new PressReleaseFrontSearchModel();
  categoryCode: number = 0;
  totalRecords = 0;
  pagerConfig: any;
  pressReleaseCategoryTypeEnum= PressReleaseCategoryTypeEnum;
  pressReleaseCMOPRCategoryEnum= PressReleaseCMOPRCategoryEnum;

  constructor(@Inject('BASE_URL') public baseUrl: string,
    public translate: TranslateService, 
    private mediaService: MediaService,
    private newsService: NewsService) {
    this.modelRequest = new ProgramImageRequestModel();
  }


  ngOnInit() {
    //this.getData();
    this.GetPressReleaseMegaEventData();
  }
  setLang(lang: string) {
    this.translate.use(lang);
  }
  public getData() {
    this.modelRequest.isShowAll = false;
    this.mediaService.GetProgramImageListForFrontLatest(this.modelRequest).subscribe(success => {
      if (success !== undefined && success !== null) {
        this.galleryList = success;
      }
    }, error => {
      console.error(error);
    });
  }

//Mega Event from Jankalyan API
  GetPressReleaseMegaEventData() {
    this.generalModel=undefined;
    this.filterModel.IsBase64File = false;
    this.filterModel.DepartmentCode = DepartmentCodeEnum.CmoWebsiteCode;
    this.filterModel.CategoryCode = this.pressReleaseCategoryTypeEnum.PressReleaseCode;
    this.filterModel.CMOPRCategoryCode = this.pressReleaseCMOPRCategoryEnum.MegaEventCode;
    this.filterModel.OrderBy = "PressreleaseDate";

    this.newsService.GetPressReleaseList(this.filterModel).subscribe((response) => {
      if (response.IsSuccess) {
        this.generalModel = response.Data.Data as InAugrationListModel[];        
        this.totalRecords = response.Data.TotalRecords;        
      }else{
        this.generalModel=[]
      }
    }, (error) => {
      this.generalModel=[]
      console.error(error);
    });
  }

}

export class GalleryModel {
  ProgramId: number;
  ProgramCategoryId: number;
  ProgramSubCategoryId: number;
  ProgramDate: string;
  ProgramDistrictId: number;
  ProgramLocationId: number;
  ProgramAddress: string;
  ProgramAddressHindi: string;
  ProgramLanguage: string;
  ProgramSubject: string;
  ProgramSubjectHindi: string;
  ProgramSpeech: string;
  ProgramSpeechHindi: string;
  Keywords: string;
  KeywordsHindi: string;
  SpeechPdf: string;
  Pdf: string;
  IsActive: boolean;
  IsDeleted: boolean;
  ProgramStateId: number;
  programtime: string;
  UserId: string;
  IsShowLatest: string;
  KeyImage: string;
  FolderName: string;
  PopupImages: string;
  PdfEnglish: string;
  Status: string;
  District: string;
  ProgramCategory: string;
  ProgramCategoryhindi: string;
  ProgramSubCategoryhindi: string;
  ProgramSubCategory: string;
  ImageName: string;
  ProgramDateHindi: string;
  ProgramGallery: GalleryDetailModel[];

}
interface GalleryDetailModel {

  ProgramAddress: string;
  ProgramAddressHindi: string;
  ImageName: string;
  ProgramDate: string;
  ProgramSubcategory: string;
  ProgramSubcategoryhindi: string;
  ProgramSubjecthindi: string;
  ProgramSubject: string;
  ProgramDateHindi: string;

}

