import { Component, Inject, OnInit } from '@angular/core';
import { AchievementService } from '../services/Achievement.services';
import { PagerService } from '../pager.service';
import { TranslateService } from '../translate.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService } from '../services/common.service';
import { AchievementAllSearchModel, AchievementViewModel } from '../Model/awards-request-model.model';
import { AchievementCategoryEnum } from '../Enum/achievement-category.enum';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.css'],
  providers: [AchievementService]
})
export class QuotesComponent implements OnInit {

  filterModel: AchievementAllSearchModel = new AchievementAllSearchModel();
  pagedQuotesModel: AchievementViewModel[];
  quotesModel: AchievementViewModel[];
  isCodeOfConduct: boolean = false;
  totalRecords = 0;
  currentPage: number;
  pageSize = 12;
  pager: any = {};
  achievementEnum = AchievementCategoryEnum;
  rajAdvtSideBarUrl: String = environment.rajAdvtUrl + "#/side-bar";
  image:any;

  constructor(
    @Inject('BASE_URL') public baseUrl: string,
    private achievementService: AchievementService,
    private pagerService: PagerService,
    public readonly translate: TranslateService,
    public sanitizer: DomSanitizer,
    public _commonService: CommonService
  ) {
    this.isCodeOfConduct = this._commonService.GetIsCodeOfConduct();
    this.pagedQuotesModel = [];
    this.currentPage = 1;
  }

  ngOnInit() {
    // this.getQuotesData(this.currentPage);
    this.GetNotableQuotesData()
  }

  getQuotesData(page: number) {
    this.currentPage = page;
    this.filterModel.IndexModel.OrderBy = "AchievementDate";
    this.filterModel.IndexModel.OrderByAsc = 0;
    this.filterModel.IndexModel.Page = page;
    this.filterModel.IndexModel.PageSize = this.pageSize;
    this.filterModel.CategoryCode = this.achievementEnum.QuotesCode;
    this.achievementService.GetAchievementListBySearchFilterData(this.filterModel).subscribe(success => {
      if (success.IsSuccess) {
        this.quotesModel = success.Data.Data;
        if (!this.filterModel.IndexModel.IsPostBack) {
          this.totalRecords = success.Data.TotalRecords;
          this.setPage(page);
        }
      }
    }, error => {
      console.error(error);
    });
  }

  GetNotableQuotesData(){
    this.achievementService.GetNotableQuotes().subscribe((success:any) => {
      if (success.IsSuccess) {
        this.quotesModel = success.Data;
      }
    }, error => {
      console.error(error);
    });
  }

  setPage(page: number) {
    window.scroll(0, 0);
    this.currentPage = page;
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    this.pager = this.pagerService.getPager(this.totalRecords, page, this.pageSize);
    this.pagedQuotesModel = this.quotesModel
  }

}
