import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-deputy-cm',
  templateUrl: './deputy-cm.component.html',
  styleUrls: ['./deputy-cm.component.css']
})
export class DeputyCmComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
